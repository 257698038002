import React from "react";
import { XIcon, CircleIcon } from "lucide-react";
import moment from "moment";

const RebalanceTimeLineModal = ({
  closeRebalanceTimelineModal,
  strategyDetails,
}) => {
  const rebalanceTimelineData = strategyDetails?.model?.rebalanceHistory
    // Sort by date in ascending order (earliest to latest)
    .sort((a, b) => new Date(a.rebalanceDate) - new Date(b.rebalanceDate))
    // Map the sorted data to the required format
    .reverse()
    .map((rebalance, index, array) => {
      const date = moment(rebalance.rebalanceDate).format("MMM D, YYYY");
      const reversedIndex = array.length - index;
      return {
        text: `Rebalance ${reversedIndex}`,
        date: date,
        complete: true, // Assume all past rebalances are complete
      };
    });

  // Add the next review item at the beginning
  rebalanceTimelineData.unshift({
    text: "Next Rebalance",
    date:
      strategyDetails?.frequency === "Need Basis"
        ? "Need Basis"
        : moment(strategyDetails?.nextRebalanceDate).format("MMM D, YYYY"),
    complete: false, // Next review is pending
  });

  // Sample other historical events (hardcoded)
  rebalanceTimelineData.push({
    text: "Strategy Went Live",
    date: moment(strategyDetails?.created_at).format("MMM D, YYYY"),
    complete: true,
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 ">
      <div className="relative">
        <div
          className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={closeRebalanceTimelineModal}
        >
          <XIcon className="w-6 h-6 mr-2  " />
        </div>

        <div className="flex flex-col w-full lg:w-[620px] h-full  bg-white  border-[#000000]/20 rounded-md pt-6 overflow-hidden">
          <div className="flex  w-full pb-4 text-[18px] lg:text-[22px]  text-[#000000] font-sans font-bold px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            Rebalance Timeline
          </div>
          <div className="relative flex flex-col  space-y-[23px]  max-h-[440px]  overflow-y-auto px-[14px] py-10 lg:px-8 lg:py-12">
            {rebalanceTimelineData.map((item, index) => (
              <div
                className="z-10 group flex items-start justify-between  "
                key={index}
              >
                <div className="flex items-start space-x-3">
                  <div className="relative flex-shrink-0">
                    {item.complete ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6 rounded-full fill-green-600 text-white"
                      >
                        <circle cx="12" cy="12" r="12"></circle>
                        <path d="m9 12 2 2 4-4"></path>
                      </svg>
                    ) : (
                      <CircleIcon className="h-6 w-6 rounded-full fill-[#ffffff] text-gray-300" />
                    )}
                  </div>

                  <div className="flex-1">
                    <div className="text-sm font-poppins font-medium text-gray-800">
                      {item.text}
                    </div>
                  </div>
                </div>

                <div className="flex min-w-[110px]  justify-end lg:max-w-[180px] text-[14px] font-poppins font-medium text-[#000000]/60">
                  {item.date}
                </div>
              </div>
            ))}
            <div className="z-0 absolute left-[26px] lg:left-[43px] top-0 h-[calc(100%-44px)] w-px bg-[#000000]/20"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RebalanceTimeLineModal;
