import axios from "axios";
import server from "../utils/serverConfig";
import { encryptApiKey } from "../utils/cryptoUtils";

export function getStrategyDetails(modelSpecificStrategy) {
  const normalizedStrategyName = modelSpecificStrategy
    .replaceAll("_", " ")
    .toLowerCase();
  return axios.get(
    `${server.server.baseUrl}api/model-portfolio/portfolios/strategy/${normalizedStrategyName}`,
    {
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    }
  );
}

export function updateStrategySubscription(email, action, strategyDetails) {
  let payloadData = JSON.stringify({
    email: email,
    action: action,
  });
  return axios.put(
    `${server.server.baseUrl}api/model-portfolio/subscribe-strategy/${strategyDetails?._id}`,
    payloadData,
    {
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    }
  );
}

export function userInsertDoc(email, strategyDetails, investAmount, broker) {
  const insertDocPayload = {
    userEmail: email,
    model: strategyDetails?.model_name,
    advisor: strategyDetails?.advisor,
    model_id: strategyDetails?.model_Id,
    userBroker: broker ? broker : "",
    subscriptionAmountRaw: [
      {
        amount: investAmount,
        dateTime: new Date(),
      },
    ],
  };
  return axios.post(
    `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
    insertDocPayload,
    {
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    }
  );
}
