import React from "react";
import { Building2, TrendingUp as ArrowTrendingUp, TrendingDown as ArrowTrendingDown } from "lucide-react";

const PositionCard = ({
  qty,
  avgPrice,
  symbol,
  pnl,
  totalInvested,
  ltp,
  type,
}) => {
  return (
    <div className="bg-white border-b border-gray-200 p-4">
      {/* Header Row */}
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-500">Qty.</span>
            <span className="text-sm font-medium text-gray-900">{qty}</span>
          </div>
          <div className="h-4 w-px bg-gray-200" />
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-500">Avg.</span>
            <span className="text-sm font-medium text-gray-900">₹{avgPrice}</span>
          </div>
        </div>

        <span className={`inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium ${
          type?.toLowerCase() === "buy" 
            ? "bg-emerald-100 text-emerald-800"
            : "bg-red-100 text-red-800"
        } capitalize`}>
          {type?.toLowerCase()}
        </span>
      </div>

      {/* Stock Info Row */}
      <div className="flex items-center justify-between mb-3">
        <span className="text-base font-medium text-gray-900">{symbol}</span>
        <div className="flex items-center space-x-1">
          {pnl > 0 ? (
            <ArrowTrendingUp className="w-4 h-4 text-emerald-600" />
          ) : pnl < 0 ? (
            <ArrowTrendingDown className="w-4 h-4 text-red-600" />
          ) : null}
          <span className={`text-sm font-medium ${
            pnl > 0 
              ? "text-emerald-600" 
              : pnl < 0 
                ? "text-red-600" 
                : "text-gray-900"
          }`}>
            {pnl > 0
              ? `+₹${pnl.toFixed(2)}`
              : pnl < 0
                ? `-₹${Math.abs(pnl.toFixed(2))}`
                : "₹0.00"}
          </span>
        </div>
      </div>

      {/* Footer Row */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Building2 className="w-4 h-4 text-gray-400" />
          <span className="text-sm text-gray-500">NSE</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">LTP</span>
          <span className="text-sm font-medium text-gray-900">
            ₹{parseFloat(ltp).toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PositionCard;