import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ChevronRightIcon } from "lucide-react";
import formatCurrency from "../../utils/formatCurrency";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import server from "../../utils/serverConfig";
import MPF_1 from "../../assests/mpf_1.png";

import { encryptApiKey } from "../../utils/cryptoUtils";

const ModalPFCard = ({ userEmail, modelName, repair }) => {
  const navigate = useNavigate();

  const handleCardClick = (modelName) => {
    navigate(
      `/model-portfolio/subscribed/${modelName
        .toLowerCase()
        .replace(/ /g, "_")}`
    );
  };

  const [strategyDetails, setStrategyDetails] = useState(null);
  const hasFetchedStrategy = useRef(false);
  const getStrategyDetails = () => {
    if (modelName && !hasFetchedStrategy.current) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${modelName?.replaceAll(
            /_/g,
            " "
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        )
        .then((res) => {
          const portfolioData = res.data[0].originalData;
          setStrategyDetails(portfolioData);
          hasFetchedStrategy.current = true;
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    if (modelName && !hasFetchedStrategy.current) {
      getStrategyDetails();
    }
  }, [modelName]);

  const [subscriptionAmount, setSubscrptionAmount] = useState();
  const hasFetchedSubscriptionData = useRef(false);
  const getSubscriptionData = () => {
    let config = {
      method: "get",
      url: `${
        server.server.baseUrl
      }api/model-portfolio-db-update/subscription-raw-amount?email=${encodeURIComponent(
        userEmail
      )}&modelName=${encodeURIComponent(strategyDetails?.model_name)}`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setSubscrptionAmount(response.data.data);
        hasFetchedSubscriptionData.current = true;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userEmail && strategyDetails && !hasFetchedSubscriptionData.current) {
      getSubscriptionData();
    }
  }, [strategyDetails, userEmail]);

  const net_portfolio_updated = subscriptionAmount?.user_net_pf_model?.sort(
    (a, b) => new Date(b.execDate) - new Date(a.execDate)
  )[0];

  const { getLTPForSymbol } = useWebSocketCurrentPrice(
    net_portfolio_updated?.order_results
  );

  const totalInvested =
    net_portfolio_updated?.order_results?.reduce((total, stock) => {
      return total + parseFloat(stock?.averagePrice) * stock?.quantity;
    }, 0) || 0;

  const totalCurrent =
    net_portfolio_updated?.order_results?.reduce((total, stock) => {
      return (
        total + parseFloat(getLTPForSymbol(stock?.symbol)) * stock?.quantity
      );
    }, 0) || 0;

  const totalNetReturns =
    net_portfolio_updated?.order_results?.reduce((total, stock) => {
      return (
        total +
        (parseFloat(getLTPForSymbol(stock?.symbol)) -
          parseFloat(stock.averagePrice)) *
          stock.quantity
      );
    }, 0) || 0;

  const percentageReturns =
    totalInvested > 0
      ? ((totalNetReturns / totalInvested) * 100).toFixed(2)
      : 0;

  // Format the values with loading states
  const formattedCurrentValue =
    totalCurrent === 0
      ? "Fetching..."
      : `₹${formatCurrency(parseFloat(totalCurrent.toFixed(2)))}`;

  const formattedNetReturns =
    totalNetReturns === 0
      ? "Fetching..."
      : totalNetReturns > 0
      ? `+₹${formatCurrency(Math.abs(totalNetReturns.toFixed(2)))}`
      : `-₹${formatCurrency(Math.abs(totalNetReturns.toFixed(2)))}`;

  const formattedInvestedValue =
    totalInvested === 0
      ? "Fetching..."
      : `₹${formatCurrency(parseFloat(totalInvested.toFixed(2)))}`;

  return (
    <div
      className="group relative bg-gray-50 hover:bg-blue-50/80 transition-all duration-200 cursor-pointer"
      onClick={() => handleCardClick(modelName)}
    >
      {/* Desktop View */}
      <div className="hidden lg:grid md:grid grid-cols-7 gap-4 px-6 py-4">
        <div className="flex items-center space-x-4 col-span-2">
          <img src={MPF_1} alt="Portfolio" className="w-12 h-10" />
          <div className="flex flex-col">
            <div className="text-gray-900  font-medium text-sm sm:text-base capitalize">
              {modelName}
            </div>
            {repair && (
              <div className="flex items-center  space-x-1 px-2 py-0.5 bg-amber-100 text-amber-700 rounded text-xs font-medium">
                Repair
                <ChevronRightIcon className="h-3 w-3" />
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-gray-700  font-medium text-sm sm:text-base">
            {formattedInvestedValue}
          </p>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-gray-700  font-medium text-sm sm:text-base">
            {formattedCurrentValue}
          </p>
        </div>
        <div className="flex items-center justify-center">
          <p
            className={`font-medium text-sm sm:text-base ${
              totalNetReturns === 0
                ? "text-gray-700"
                : totalNetReturns > 0
                ? "text-emerald-600"
                : "text-red-600"
            }`}
          >
            {formattedNetReturns}
          </p>
        </div>
        <div className="flex items-center justify-center ">
          <p className="text-gray-700  font-medium text-sm sm:text-base">TBC</p>
        </div>
        <div className="flex items-center justify-center">
          <button
            className="px-4 py-1.5 text-sm  font-medium  text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-blue-50 hover:border-blue-200 hover:text-blue-600 transition-all"
            onClick={() => handleCardClick(modelName)}
          >
            View
          </button>
        </div>
      </div>

      {/* Mobile View */}
      <div
        className="md:hidden grid grid-cols-2 p-3"
        onClick={() => handleCardClick(modelName)}
      >
        <div className="flex items-center">
          <div className="text-gray-900">
            <div className="flex space-x-2">
              <img src={MPF_1} alt="Portfolio" className="w-8 h-8" />
              <div className="flex flex-col justify-center">
                <span className="text-xs font-medium  text-gray-900 line-clamp-1">
                  {modelName}
                </span>
                {repair && (
                  <div className="flex items-center  space-x-0.5 px-1.5 py-0.5 bg-amber-100 text-amber-700 rounded text-[10px] font-medium mt-0.5 w-fit">
                    Repair
                    <ChevronRightIcon className="h-2.5 w-2.5" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-center">
          <div className="flex items-baseline space-x-1">
            <span
              className={`text-xs font-medium ${
                totalNetReturns === 0
                  ? "text-gray-700"
                  : totalNetReturns > 0
                  ? "text-emerald-600"
                  : "text-red-600"
              }`}
            >
              {totalCurrent === 0 ? "Fetching..." : formattedCurrentValue}
            </span>
            {totalNetReturns !== 0 && (
              <span
                className={`text-[10px] font-medium ${
                  totalNetReturns > 0 ? "text-emerald-600" : "text-red-600"
                }`}
              >
                ({totalNetReturns > 0 ? "+" : ""}
                {Math.abs(percentageReturns)}%)
              </span>
            )}
          </div>
          <span className="text-[10px] text-gray-500">
            {totalInvested === 0
              ? "Fetching..."
              : `Inv: ${formattedInvestedValue}`}
          </span>
        </div>
      </div>

      {/* Hover Effect Indicator */}
      <div className="absolute inset-y-0 left-0 w-1 bg-blue-500 opacity-0 group-hover:opacity-100 transition-opacity" />
    </div>
  );
};

export default ModalPFCard;
