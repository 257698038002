import React, { useState, useEffect, useRef } from "react";
import { X } from "lucide-react";
import moment from "moment";
import server from "../../../utils/serverConfig";
import { encryptApiKey } from "../../../utils/cryptoUtils";

const TableHead = ({ isAllSelected, handleSelectAllChange }) => {
  return (
    <thead className="bg-gray-100 sticky top-0 z-10">
      <tr>
        <th className="py-3 px-4 border-b text-left font-poppins">
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
            className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
          />
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          ID
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Symbol
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Type
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Price
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Date
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Exchange
        </th>
        <th className="py-3 px-4 border-b text-left font-poppins text-black font-medium">
          Comments
        </th>
      </tr>
    </thead>
  );
};
export const PreviousEquityAdvices = ({ onClose, onSelect }) => {
  const [adviceData, setAdviceData] = useState([]);
  const [selectedAdvices, setSelectedAdvices] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  const [tabData, setTabData] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  });

  const fetchData = async (pageNumber = 1, limit = 20) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/reco/payload/advisor-name/${process.env.REACT_APP_ADVISOR_SPECIFIC_TAG}/${pageNumber}/${limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      const data = await response.json();
      setTabData({
        currentPage: data?.pagination?.currentPage || pageNumber,
        totalPages: data?.pagination?.totalPages || 1,
        limit: data?.pagination?.rowsPerPage,
      });
      setAdviceData(data.reco || []);
      if (data.status === 0) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getPageNumbers = () => {
    const pages = [];
    const { currentPage, totalPages } = tabData;
    const totalVisible = 10;

    if (totalPages <= totalVisible) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pages.push(1, "...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) pages.push("...", totalPages);
    }

    return pages;
  };

  // Function to navigate to a different page
  const goToPage = (page) => {
    if (page > 0 && page <= tabData.totalPages) {
      fetchData(page, tabData.limit);
    }
  };

  const handleCheckboxChange = (index) => {
    setSelectedAdvices((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(index)) {
        newSelected.delete(index);
      } else {
        newSelected.add(index);
      }
      setIsAllSelected(newSelected.size === adviceData.length); // Sync "Select All" checkbox
      return newSelected;
    });
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedAdvices(new Set()); // Deselect all
    } else {
      const allIndexes = new Set(adviceData.map((_, index) => index));
      setSelectedAdvices(allIndexes); // Select all
    }
    setIsAllSelected(!isAllSelected); // Toggle state
  };

  const handleSelectAdvices = () => {
    const selectedAdviceData = Array.from(selectedAdvices).map(
      (index) => adviceData[index]
    );
    onSelect(selectedAdviceData);
  };

  return (
    <div
      ref={modalRef}
      className="relative w-full max-w-[75rem] h-[88vh] bg-white rounded-xl shadow-xl overflow-hidden font-sans"
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-6 border-b bg-gray-50 sticky top-0 z-10">
          <h2 className="text-2xl font-bold text-gray-800">Previous Advices</h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-200 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {loading ? (
          <div className="flex-grow">
            <table className="min-w-full bg-white">
              <TableHead
                isAllSelected={isAllSelected}
                handleSelectAllChange={handleSelectAllChange}
              />
            </table>
            <div className="h-full w-full flex items-center justify-center">
              <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
            </div>
          </div>
        ) : adviceData.length > 0 ? (
          <div className="flex-grow overflow-auto">
            <table className="min-w-full bg-white">
              <TableHead
                isAllSelected={isAllSelected}
                handleSelectAllChange={handleSelectAllChange}
              />
              <tbody>
                {adviceData?.map((advice, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">
                      <input
                        type="checkbox"
                        checked={selectedAdvices.has(index)}
                        onChange={() => handleCheckboxChange(index)}
                        className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                      />
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.advice_reco_id}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.symbol}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.type}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.price_when_send_advice}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {moment(advice.date).format("Do MMM YYYY")}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.exchange}
                    </td>
                    <td className="py-2 px-4 border-b font-poppins text-black">
                      {advice.comments}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-center mt-2 space-x-2 pb-3">
              <button
                onClick={() => goToPage(tabData.currentPage - 1)}
                disabled={tabData.currentPage === 1}
                className="text-black px-3 py-1 border rounded disabled:opacity-50"
              >
                Previous
              </button>

              {getPageNumbers().map((page, index) =>
                page === "..." ? (
                  <span key={index} className="text-black px-3 py-1">
                    ...
                  </span>
                ) : (
                  <button
                    key={index}
                    onClick={() => goToPage(page)}
                    className={`px-3 py-1 border rounded text-black ${
                      tabData.currentPage === page
                        ? "bg-blue-500 text-white"
                        : ""
                    }`}
                  >
                    {page}
                  </button>
                )
              )}

              <button
                onClick={() => goToPage(tabData.currentPage + 1)}
                disabled={tabData.currentPage === tabData.totalPages}
                className="text-black px-3 py-1 border rounded disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div className="flex-grow overflow-auto">
            <table className="min-w-full bg-white">
              <TableHead
                isAllSelected={isAllSelected}
                handleSelectAllChange={handleSelectAllChange}
              />
            </table>
            <div className="h-full w-full flex items-center justify-center text-2xl font-bold text-gray-800">
              No Data Available
            </div>
          </div>
        )}

        <div className="p-6 border-t bg-gray-50 sticky bottom-0 z-10">
          <button
            onClick={handleSelectAdvices}
            className={`px-4 py-2 font-poppins rounded-lg float-right ${
              selectedAdvices.size === 0
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-black text-white"
            }`}
            disabled={selectedAdvices.size === 0}
          >
            Add Selected Advices
          </button>
        </div>
      </div>
    </div>
  );
};
