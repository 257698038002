import axios from "axios";
import server from "../utils/serverConfig";
import { loginConfirmedAction } from "../store/actions/AuthActions";

import { encryptApiKey } from "../utils/cryptoUtils";

// Function to handle login
export async function login(email) {
  try {
    const postData = { email };
    const response = await axios.post(
      `${server.server.baseUrl}api/admin/allowed/login`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      },
      postData
    );

    if (response.status === 200 && response.data) {
      // Assuming the response contains user details and a token
      return response.data;
    } else {
      throw new Error("Login failed");
    }
  } catch (error) {
    console.error("Login error:", error);
    throw error; // Rethrow error for handling in the calling function
  }
}

// Function to save token and user details in local storage
export function saveTokenInLocalStorage(userDetails, token) {
  localStorage.setItem("userDetails", JSON.stringify(userDetails));
  localStorage.setItem("authToken", token);
}

export function loadUserFromLocalStorage() {
  const userDetails = localStorage.getItem("userDetails");
  const token = localStorage.getItem("authToken");
  return {
    userDetails: userDetails ? JSON.parse(userDetails) : null,
    token: token || null,
  };
}

export const checkAutoLogin = (dispatch) => {
  const { userDetails, token } = loadUserFromLocalStorage();
  if (userDetails && token) {
    dispatch(loginConfirmedAction({ ...userDetails, token }));
  }
};
