import React from "react";
import { X, ChevronDown, ArrowUpDown, Calendar, DollarSign, Package2 } from "lucide-react";
import moment from "moment";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";


const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

// interface ViewTradeFailureModalsProps {
//   selectedTab: string;
//   setSelectedTab: (value: string) => void;
//   setTradeFailureModal: (value: boolean) => void;
//   filteredInconsistencyTrades: any[];
//   filteredFailuresTrades: any[];
//   filteredMissedTrades: any[];
//   selectedUser: string;
//   setSelectedUser: (value: string) => void;
//   userEmailCount: string;
//   setUserEmailCount: (value: string) => void;
//   advisorSpecifier: string;
// }

const ViewTradeFailureModals= ({
  selectedTab,
  setSelectedTab,
  setTradeFailureModal,
  filteredInconsistencyTrades,
  filteredFailuresTrades,
  filteredMissedTrades,
  selectedUser,
  setSelectedUser,
  userEmailCount,
  setUserEmailCount,
  advisorSpecifier,
}) => {
  const filteredTrades = filteredFailuresTrades?.filter(
    (trade) => trade.user_email === selectedUser
  );

  const handleSelectEmail = (value) => {
    setSelectedUser(value);
    const emailCount = filteredFailuresTrades?.filter(
      (trade) => trade.user_email === value
    ).length;
    setUserEmailCount(emailCount.toString());
  };

  const userEmailList = Array.from(
    new Set(filteredFailuresTrades?.map((trade) => trade.user_email))
  );

  const ShowTradeInconsistency = advisorSpecifier === "RIA";

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black/25 backdrop-blur-sm" />
      <div className="relative w-full max-w-4xl bg-white rounded-xl shadow-2xl">
        {/* Header */}
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-100">
          <h2 className="text-xl font-semibold text-gray-900">Trade Details</h2>
          <button
            onClick={() => setTradeFailureModal(false)}
            className="p-2 text-gray-400 hover:text-gray-500 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Tabs */}
        <div className="flex space-x-6 px-6 border-b border-gray-100">
          {filteredFailuresTrades?.length > 0 && (
            <button
              className={`py-4 relative ${
                selectedTab === "trade-failure"
                  ? "text-blue-600 font-semibold"
                  : "text-gray-500 hover:text-gray-900"
              }`}
              onClick={() => setSelectedTab("trade-failure")}
            >
              Trade Failures
              {selectedTab === "trade-failure" && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600" />
              )}
            </button>
          )}

          {ShowTradeInconsistency && filteredInconsistencyTrades?.length > 0 && (
            <button
              className={`py-4 relative ${
                selectedTab === "trade-inconsistency"
                  ? "text-blue-600 font-semibold"
                  : "text-gray-500 hover:text-gray-900"
              }`}
              onClick={() => setSelectedTab("trade-inconsistency")}
            >
              Trade Inconsistencies
              {selectedTab === "trade-inconsistency" && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600" />
              )}
            </button>
          )}

          {filteredMissedTrades?.length > 0 && (
            <button
              className={`py-4 relative ${
                selectedTab === "missed-trades"
                  ? "text-blue-600 font-semibold"
                  : "text-gray-500 hover:text-gray-900"
              }`}
              onClick={() => setSelectedTab("missed-trades")}
            >
              Missed Trades
              {selectedTab === "missed-trades" && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600" />
              )}
            </button>
          )}
        </div>

        {/* User Selection */}
        <div className="px-6 py-4 border-b border-gray-100">
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <span className="flex items-center justify-center w-6 h-6 rounded-full bg-red-100 text-red-600 text-sm font-medium">
                {userEmailCount}
              </span>
              <Select
                defaultValue={selectedUser}
                onValueChange={handleSelectEmail}
              >
                <SelectTrigger className="w-[320px] border-gray-200">
                  <SelectValue placeholder="Select User Email" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {userEmailList.map((email, i) => (
                      <SelectItem
                        key={i}
                        value={email}
                        className="py-2.5 text-sm font-medium"
                      >
                        {email}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="max-h-[400px] overflow-auto">
          <table className="w-full">
            <thead className="bg-gray-50 sticky top-0">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center space-x-2">
                    <Package2 className="w-4 h-4" />
                    <span>Stock Name</span>
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center space-x-2">
                    <ArrowUpDown className="w-4 h-4" />
                    <span>Quantity</span>
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center space-x-2">
                    <ChevronDown className="w-4 h-4" />
                    <span>Type</span>
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center space-x-2">
                    <DollarSign className="w-4 h-4" />
                    <span>Price</span>
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center space-x-2">
                    <Calendar className="w-4 h-4" />
                    <span>Date of Purchase</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {filteredTrades.map((trade, i) => (
                <tr key={i} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {trade.Symbol}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {trade.Quantity}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${
                        trade.Type?.toLowerCase() === "buy"
                          ? "bg-green-100 text-green-800"
                          : trade.Type?.toLowerCase() === "sell"
                          ? "bg-red-100 text-red-800"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {trade.Type?.toLowerCase()}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {trade.OrderType}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {moment(trade.purchaseDate).format("DD/MM/YYYY hh:mm A")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewTradeFailureModals;