import React, { useState } from "react";
import { XIcon } from "lucide-react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import LoadingSpinner from "../../components/LoadingSpinner";
import { calculateGSTComponents } from "../PlanSection/GstCalculator";

const style = {
  selected:
    "disabled:cursor-not-allowed px-4 py-2 text-base font-semibold text-black border-b-4 border-[#000000] cursor-pointer",
  unSelected:
    "disabled:cursor-not-allowed px-4 py-2 text-base text-[#000000]/50 font-normal cursor-pointer",
  inputBox:
    "mt-1 py-2 w-full flex items-center bg-gray-100 rounded-md text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal col-span-3 lg:col-span-5 px-4 lg:mr-4 ",
  selectDiv:
    "flex items-center px-2 py-2  text-sm hover:rounded-md mx-3 mb-1 text-black  hover:bg-[#000000] hover:text-white cursor-pointer",
  inputHeaderText: "text-[14px] font-normal text-[#000000]",
  selectPreferenceDiv:
    "flex flex-row justify-center border-[1px] border-[#D9D9D9CC]/80 rounded-[6px] py-3 items-center",
};

const AddWebinarModal = ({
  isValidData,
  setWebinarModal,
  webinarName,
  setWebinarName,
  amount,
  setAmount,
  webinarDate,
  setWebinarDate,
  webinarLink,
  setWebinarLink,
  webinarDescription,
  setWebinarDescription,
  handlePlanModal,
  setGstDetails,
  gstDetails,
  loading,
  time,
  setTime,
  amountWithOffer,
  setAmountWithOffer,
  selected,
  setSelected,
}) => {
  const handleDateChange = (date, dateString) => {
    setWebinarDate(dateString);
  };

  const handleInputChange = (e) => {
    const enteredValue = e.target.value;
    setAmount(enteredValue);

    if (!enteredValue) {
      setGstDetails({ totalAmount: 0 });
      return;
    }

    const numericAmount = parseFloat(enteredValue);
    if (!isNaN(numericAmount)) {
      setGstDetails(calculateGSTComponents(numericAmount));
    }
  };

  const handleOfferChange = (e) => {
    const enteredValue = e.target.value;
    setAmountWithOffer(enteredValue);

    if (!enteredValue) {
      setGstDetails({ totalAmount: 0 });
      return;
    }

    const numericAmount = parseFloat(enteredValue);
    if (!isNaN(numericAmount)) {
      setGstDetails(calculateGSTComponents(numericAmount));
    }
  };

  const formatTime = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours);
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
    return `${formattedHour}:${minutes} ${period} IST`;
  };

  // Get formatted time slot
  const selectedtime =
    time.startTime && time.endTime
      ? `${formatTime(time.startTime)} to ${formatTime(time.endTime)}`
      : "Select a time slot";

  const [customHour1, setCustomHour1] = useState("");
  const [customHour2, setCustomHour2] = useState("");

  const handleReminderChange = (event, setter, prevValue) => {
    const value = event.target.value;

    // Update the input field state
    setter(value);

    // Remove previous value from selected and add the new one
    setSelected((prev) => {
      const updatedSelection = prev.filter(
        (item) => item !== `${prevValue} Hours Ago`
      );
      return value
        ? [...updatedSelection, `${value} Hours Ago`]
        : updatedSelection;
    });
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 ">
      <div className="relative animate-modal">
        <div className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer ">
          <XIcon
            className="w-6 h-6 mr-2  "
            onClick={() => setWebinarModal(false)}
          />
        </div>

        <div className="flex flex-col w-[350px] h-[600px] lg:w-[820px] lg:h-full   bg-white  border-[#000000]/20 rounded-md pt-6 ">
          <div className="flex flex-col  w-full px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            <div className="pb-4 text-[18px] lg:text-[22px]  text-[#000000] font-sans font-bold ">
              Create New Webinar
            </div>
          </div>

          <div className="px-5 sm:px-8 overflow-auto custom-scroll  sm:w-full flex flex-col h-[500px] pt-4">
            <div className="w-full flex-wrap lg:flex-nowrap flex flex-row gap-3">
              <div className="w-full lg:w-[65%] space-y-4">
                <div>
                  <div className={style.inputHeaderText}>Name</div>
                  <input
                    type="text"
                    value={webinarName}
                    onChange={(e) => setWebinarName(e.target.value)}
                    className={`${style.inputBox}`}
                    placeholder="Enter Webinar Name"
                  />
                </div>
                <div>
                  <div className={style.inputHeaderText}>Webinar Link</div>
                  <input
                    type="text"
                    value={webinarLink}
                    onChange={(e) => setWebinarLink(e.target.value)}
                    className={`${style.inputBox}`}
                    placeholder="Enter Webinar Link here"
                  />
                </div>

                <div>
                  <div className={style.inputHeaderText}>Amount</div>
                  <div className="mt-1 flex flex-row items-center border border-[#000000]/20 rounded-md ">
                    <div className="text-base text-black px-3">₹</div>
                    <input
                      type="number"
                      value={amount}
                      onChange={handleInputChange}
                      className={
                        "px-3 py-2 w-full flex items-center bg-gray-100 rounded-r-md text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal"
                      }
                      placeholder="Enter Amount"
                    />
                  </div>
                  {process.env.REACT_APP_AdVISOR_GST_CONFIGURE === "true" &&
                    !amountWithOffer && (
                      <div className="mt-1 flex flex-row justify-between capitalize">
                        <div className={style.inputHeaderText}>
                          Amount With GST:{" "}
                          <span className="font-semibold">
                            ₹ {gstDetails.totalAmount.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  <div className={style.inputHeaderText}>Amount With Offer</div>
                  <div className="mt-1 flex flex-row items-center border border-[#000000]/20 rounded-md ">
                    <div className="text-base text-black px-3">₹</div>
                    <input
                      type="number"
                      value={amountWithOffer}
                      onChange={handleOfferChange}
                      className={
                        "px-3 py-2 w-full flex items-center bg-gray-100 rounded-r-md text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal"
                      }
                      placeholder="Enter Amount"
                    />
                  </div>
                  {process.env.REACT_APP_AdVISOR_GST_CONFIGURE === "true" &&
                    amountWithOffer && (
                      <div className="mt-1 flex flex-row justify-between capitalize">
                        <div className={style.inputHeaderText}>
                          Amount With GST:{" "}
                          <span className="font-semibold">
                            ₹ {gstDetails.totalAmount.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                {/* DatePicker Input */}
                <div>
                  <div className={style.inputHeaderText}>Date</div>
                  <DatePicker
                    value={webinarDate ? dayjs(webinarDate) : null}
                    onChange={handleDateChange}
                    className={`${style.inputBox} w-full`}
                    format="YYYY-MM-DD" // Customize the format if needed
                    placeholder="Select Date"
                  />
                </div>
              </div>
            </div>

            <div className="w-full pt-4">
              <div className={`pb-2 ${style.inputHeaderText}`}>
                Select Time Slot
              </div>

              <label>Start Time:</label>
              <select
                onChange={(e) =>
                  setTime({ ...time, startTime: e.target.value })
                }
                className="ml-2 border-2 py-2 px-3 rounded-md"
              >
                <option value="">Select Start Time</option>
                <option value="11:00">11:00 AM</option>
                <option value="12:00">12:00 PM</option>
                <option value="13:00">1:00 PM</option>
                <option value="14:00">2:00 PM</option>
                <option value="15:00">3:00 PM</option>
                <option value="16:00">4:00 PM</option>
                <option value="17:00">5:00 PM</option>
                <option value="18:00">6:00 PM</option>
                <option value="19:00">7:00 PM</option>
                <option value="20:00">8:00 PM</option>
                <option value="21:00">9:00 PM</option>
                <option value="22:00">10:00 PM</option>
              </select>

              <label style={{ marginLeft: "10px" }}>End Time:</label>
              <select
                onChange={(e) => setTime({ ...time, endTime: e.target.value })}
                className="ml-2 border-2 py-2 px-3 rounded-md"
              >
                <option value="">Select End Time</option>
                <option value="">Select Start Time</option>
                <option value="11:00">11:00 AM</option>
                <option value="12:00">12:00 PM</option>
                <option value="13:00">1:00 PM</option>
                <option value="14:00">2:00 PM</option>
                <option value="15:00">3:00 PM</option>
                <option value="16:00">4:00 PM</option>
                <option value="17:00">5:00 PM</option>
                <option value="18:00">6:00 PM</option>
                <option value="19:00">7:00 PM</option>
                <option value="20:00">8:00 PM</option>
                <option value="21:00">9:00 PM</option>
                <option value="22:00">10:00 PM</option>
              </select>

              <div className="mt-4 flex flex-row capitalize">
                Selected Time Slot:{" "}
                <span className="font-semibold ml-2">{selectedtime}</span>
              </div>
            </div>

            <div className={`pt-4 ${style.inputHeaderText}`}>
              Set two prefer time to send remainder to user
              <div className="flex flex-row items-center gap-3 pt-2">
                <div className="flex flex-row items-center">
                  <input
                    type="number"
                    placeholder="Enter Hours"
                    value={customHour1}
                    onChange={(e) =>
                      handleReminderChange(e, setCustomHour1, customHour1)
                    }
                    className="border-2 p-2 rounded-md bg-gray-100 text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal"
                  />
                  <div className="ml-3">Hours Ago</div>
                </div>
                <div className="flex flex-row items-center">
                  <input
                    type="number"
                    placeholder="Enter Hours"
                    value={customHour2}
                    onChange={(e) =>
                      handleReminderChange(e, setCustomHour2, customHour2)
                    }
                    className="border-2 p-2 rounded-md bg-gray-100 text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal"
                  />
                  <div className="ml-3">Hours Ago</div>
                </div>
              </div>
            </div>

            <div className="w-full pt-4">
              <div>
                <div className={style.inputHeaderText}>Description </div>
                <textarea
                  type="text"
                  value={webinarDescription}
                  id="Description"
                  onChange={(e) => setWebinarDescription(e.target.value)}
                  className={`h-24 ${style.inputBox}`}
                  placeholder="Describe your webinar details in 2-3 lines"
                />
              </div>
            </div>
          </div>

          <div className="py-4 flex flex-row justify-end items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
            <button
              disabled={!isValidData()}
              className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
              onClick={handlePlanModal}
            >
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[16px] font-medium text-[#ffffff] font-poppins ">
                  Continue
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWebinarModal;
