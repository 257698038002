// InfoHoverWrapper.jsx
import React from 'react';
import { Info } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../components/ui/tooltip';

const InfoHoverWrapper = ({ children, infoText }) => (
  <TooltipProvider>
    <div className="relative inline-block lg:mt-4 mt-2 p-4 rounded-md border border-gray-300 w-full"
         style={{
           background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)', // Professional greyish gradient
           boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
         }}
    >
      {children}

      {/* Tooltip and Info icon */}
      <Tooltip>
        <TooltipTrigger className="absolute top-2 right-2">
          <Info className="text-gray-600 hover:text-gray-800 transition-colors duration-200" size={20} />
        </TooltipTrigger>
        <TooltipContent>
          <p className="text-sm">{infoText}</p>
        </TooltipContent>
      </Tooltip>
    </div>
  </TooltipProvider>
);

export default InfoHoverWrapper;