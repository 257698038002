import { XIcon } from "lucide-react";
import React, { useState } from "react";

const UpdateCommentModal = ({ client, onClose, onUpdate }) => {
  const [editedClient, setEditedClient] = useState(client);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedClient((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateComment = () => {
    console.log("Updated Comment:", editedClient.comment);
    onUpdate(editedClient);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative">
        <div
          className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={onClose}
        >
          <XIcon className="w-6 h-6 mr-2" />
        </div>
        <div className="bg-white  rounded-lg w-[520px] px-8 py-8">
          <h3 className="text-lg font-semibold mb-4">
           Edit Comment for client {client.clientName}
          </h3>
          <textarea
            value={editedClient.comments}
            name="comments"
            onChange={(e) => handleChange(e)}
            className="w-full p-2 border rounded mb-4 text-[16px] font-medium font-poppins "
            rows="3"
          ></textarea>

          <div className="mt-1 text-[18px] font-normal font-poppins text-center ">
            <div className="grid grid-cols-2 gap-x-4 gap-y-2">
              <div
                onClick={onClose}
                className=" bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[16px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer"
              >
                Cancel
              </div>
              <div
                onClick={handleUpdateComment}
                className=" bg-[#000000] text-white text-[16px] font-medium font-poppins rounded-md px-8 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
              >
                Update Comment
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCommentModal;
