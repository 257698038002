import React, { useState } from "react";
import { XIcon } from "lucide-react";
import toast from "react-hot-toast";
import { motion, AnimatePresence } from "framer-motion";
import { ClientFormFields } from "./ClientFormFields";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { encryptApiKey } from "../../utils/cryptoUtils";
import {
  getStrategyDetails,
  updateStrategySubscription,
  userInsertDoc,
} from "../../services/ModelPFServices";

import {
  normalWhatsAppNotification,
  normalEmailNotification,
  getWhatsAppRebalanceNotification,
} from "../../services/WhatsAppAndEmailService";
const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;

const AddClientModal = ({
  userId,
  closeAddClientModal,
  setNewClientData,
  newClientData,
  setAddClientModal,
  getAllClientsData,
  groupName,
  creationDate,
  setCreationDate,
  setExpiryDate,
  expiryDate,
  dateOfBirth,
  setDateOfBirth,
  groupType,
}) => {
  const [isPanComplete, setIsPanComplete] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePanChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, "")
      .slice(0, 10);

    setNewClientData((prevData) => ({
      ...prevData,
      [name]: sanitizedValue,
    }));

    setIsPanComplete(sanitizedValue.length === 10);
  };

  const [loading, setLoading] = useState(false);
  const handleAddNewClient = async () => {
    setLoading(true);
    const newSubscription = {
      startDate: new Date(),
      plan: groupName, // Assuming the response contains a plan
      capital: newClientData.investAmount ? newClientData.investAmount : "", // Assuming the response contains capital
      charges: 0, // Assuming the response contains charges
      invoice: newClientData?.invoice ? newClientData?.invoice : "", // Assuming the response contains invoice
      expiry: new Date(expiryDate), // Assuming the response contains expiry date
    };
    const allData = {
      clientName: newClientData.clientName,
      email: newClientData.clientEmail,
      phone: newClientData.phone,
      countryCode: newClientData.countryCode,
      groups: ["All Client", groupName],
      location: newClientData.location,
      telegram: newClientData.telegram,
      pan: newClientData.pan,
      creationDate: new Date(creationDate).toISOString(),
      subscriptions: [
        {
          ...newSubscription, // Attach the new subscription here
        },
      ],
    };

    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify({
            userId,
            clientData: allData,
            DateofBirth: dateOfBirth || "",
            advisorName: advisorTag,
          }),
        }
      );

      if (response.ok) {
        if (groupType === "model portfolio") {
          const strategyResponse = await getStrategyDetails(groupName);
          const strategyDetails = strategyResponse?.data[0].originalData;

          // Subscribe user to strategy
          await updateStrategySubscription(
            newClientData.clientEmail,
            "subscribe",
            strategyDetails
          );

          // Insert user document
          await userInsertDoc(
            newClientData.clientEmail,
            strategyDetails,
            newClientData.investAmount
          );
          await normalWhatsAppNotification(
            newClientData.phone,
            newClientData.clientName,
            new Date(),
            new Date(expiryDate),
            newClientData.countryCode
          );
          await normalEmailNotification(
            newClientData.clientName,
            new Date(),
            new Date(expiryDate),
            newClientData.clientEmail,
            groupName,
            newClientData.pan
          );
          await getWhatsAppRebalanceNotification(
            strategyDetails,
            newClientData.clientEmail,
            newClientData.phone,
            newClientData.countryCode,
            newClientData.clientName
          );
        }

        setNewClientData({
          clientName: "",
          clientEmail: "",
          phone: "",
          countryCode: "",
          location: "",
          telegram: "",
          pan: "",
          investAmount: "",
        });
        setLoading(false);
        setAddClientModal(false);
        getAllClientsData(userId);
        toast.success("Client added successfully!", {
          duration: 3000,
          className: "bg-white text-gray-900 font-medium",
        });
      } else {
        throw new Error("Failed to add client");
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toast.error("Failed to add client", {
        duration: 3000,
        className: "bg-white text-red-600 font-medium",
      });
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      <div className="fixed inset-0 flex items-center justify-center bg-black/75 z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="relative w-full max-w-3xl mx-4"
        >
          <div className="relative bg-white rounded-lg shadow-xl max-h-[82vh] overflow-y-auto">
            <button
              onClick={closeAddClientModal}
              className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
            >
              <XIcon className="w-6 h-6 text-gray-500" />
            </button>

            <div className="px-6 py-4 border-b border-gray-200">
              <h2 className="text-xl font-semibold text-gray-900">
                Add New Client
              </h2>
            </div>

            <div className="max-h-[65vh] overflow-y-auto px-6 py-3">
              <ClientFormFields
                data={newClientData}
                onInputChange={handleInputChange}
                creationDate={creationDate}
                setCreationDate={setCreationDate}
                setExpiryDate={setExpiryDate}
                expiryDate={expiryDate}
                dateOfBirth={dateOfBirth}
                setDateOfBirth={setDateOfBirth}
                emailEdit={false}
                groupType={groupType}
                handlePanChange={handlePanChange}
                isPanComplete={isPanComplete}
              />
            </div>

            <div className="flex justify-end space-x-3 px-6 py-3 border-t border-gray-200">
              <button
                onClick={closeAddClientModal}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                onClick={handleAddNewClient}
                disabled={
                  groupType === "model portfolio"
                    ? !newClientData.phone ||
                      !newClientData.clientName ||
                      !newClientData.clientEmail ||
                      !newClientData.countryCode ||
                      !expiryDate ||
                      !newClientData.investAmount
                    : !newClientData.phone ||
                      !newClientData.clientName ||
                      !newClientData.clientEmail ||
                      !newClientData.countryCode ||
                      !expiryDate
                }
                className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed"
              >
                {loading === true ? <LoadingSpinner /> : "Add Client"}
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default AddClientModal;
