import React from "react";
import { XIcon, Plus } from "lucide-react";
import { encryptApiKey, decryptApiKey } from "../../../utils/cryptoUtils";

const apiKey = "test_api_key";
const apiSecret = "test_api_secret";

const encryptedKey = encryptApiKey(
  process.env.REACT_APP_AQ_KEYS,
  process.env.REACT_APP_AQ_SECRET
);

const SendAdviceTable = ({
  showQuantityColumn,
  handleAddAdviceEntry,
  adviceEntries,
  handleInputChange,
  isOpen,
  handleSymbolSelect,
  handleAdviceChange,
  getLTPForSymbol,
  handleRationaleChange,
  modalPosition,
  setModalPosition,
  CommentModal,
  activeCommentModal,
  setActiveCommentModal,
  handleRemoveAdviceEntry,
  handleCommentsChange,
  handleComments2Change,
  setPreviousEquityAdvicesPopup,
  setShowQuantityPopUp,
}) => {
  return (
    <div className="mt-4 p-4 border border-gray-200 rounded-lg">
      <div className="mb-4 flex flex-row items-center space-x-4 border-gray-200 ">
        <button
          onClick={handleAddAdviceEntry}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Entry
        </button>

        <button
          onClick={() => setPreviousEquityAdvicesPopup(true)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
        >
          Send Previous Entries
        </button>
        {/* 
        {showQuantityColumn ? (
          <button
            onClick={() => setShowQuantityPopUp(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
          >
            Add Quantity
          </button>
        ) : null}
         */}
      </div>
      <span className="text-sm text-gray-500">
        {adviceEntries.length} entr
        {adviceEntries.length === 1 ? "y" : "ies"}
      </span>
      <div className="overflow-x-auto custom-scroll rounded-lg border border-gray-200 shadow-sm">
        <table className="min-w-full divide-y divide-gray-200">
          <colgroup>
            <col className="w-[250px]" /> {/* Symbol - increased width */}
            <col className="w-[150px]" /> {/* Order Type */}
            <col className="w-[150px]" /> {/* Product Type */}
            <col className="w-[120px]" /> {/* Segment */}
            <col className="w-[180px]" /> {/* Type - increased width */}
            {showQuantityColumn && <col className="w-[100px]" />}{" "}
            {/* Quantity */}
            <col className="w-[100px]" /> {/* LTP */}
            <col className="w-[150px]" /> {/* Price */}
            <col className="w-[200px]" /> {/* Advised Range */}
            <col className="w-[200px]" /> {/* Comments */}
            <col className="w-[300px]" /> {/* Rationale */}
            <col className="w-[80px]" /> {/* Actions */}
          </colgroup>

          <thead className="bg-gray-50">
            <tr className="h-16">
              {" "}
              {/* Increased header height */}
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Symbol <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Order Type <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Product Type <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Segment <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type <span className="text-red-500">*</span>
              </th>
              {showQuantityColumn && (
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
              )}
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                LTP
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Stop Loss
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Profit Target
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Advised Range
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Rationale <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Comments
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Extended Comment
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {adviceEntries.map((entry, index) => (
              <tr
                key={entry.id}
                className="hover:bg-gray-50 transition-colors min-h-[120px]"
              >
                {" "}
                {/* Increased row height */}
                {/* Symbol */}
                <td className="px-4 py-4">
                  {" "}
                  {/* Increased cell padding */}
                  <div className="relative">
                    <input
                      type="text"
                      value={entry.inputValue}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      className="w-full min-w-[220px] px-3 py-2.5 border text-black border-gray-300 rounded-md"
                      placeholder="Enter symbol"
                    />
                    {entry.symbols.length > 0 && isOpen && (
                      <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
                        {entry.symbols.map((symbol, idx) => (
                          <div
                            key={idx}
                            onClick={() =>
                              handleSymbolSelect(
                                index,
                                symbol.symbol,
                                symbol.segment
                              )
                            }
                            className="px-4 py-2.5 text-black hover:bg-gray-100 cursor-pointer"
                          >
                            {symbol.symbol} - {symbol.segment}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </td>
                {/* Order Type */}
                <td className="px-4 py-4">
                  <select
                    value={entry.orderType}
                    onChange={(e) =>
                      handleAdviceChange(index, "orderType", e.target.value)
                    }
                    className="w-full min-w-[140px] font-poppins text-base px-3 py-2.5 border text-black border-gray-300 rounded-md"
                  >
                    {["MARKET", "LIMIT", "STOP"].map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>
                {/* Product Type */}
                <td className="px-4 py-4">
                  <select
                    value={entry.productType}
                    onChange={(e) =>
                      handleAdviceChange(index, "productType", e.target.value)
                    }
                    className="w-full min-w-[140px] font-poppins text-base px-3 py-2.5 border text-black border-gray-300 rounded-md"
                  >
                    {["DELIVERY", "INTRADAY"].map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>
                {/* Segment */}
                <td className="px-4 py-4">
                  <select
                    value={entry.segment}
                    onChange={(e) =>
                      handleAdviceChange(index, "segment", e.target.value)
                    }
                    className="w-full min-w-[120px] font-poppins text-base px-3 py-2.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="EQUITY">EQUITY</option>
                  </select>
                </td>
                {/* Type */}
                <td className="px-4 py-4">
                  <select
                    value={entry.type}
                    onChange={(e) =>
                      handleAdviceChange(index, "type", e.target.value)
                    }
                    className="w-full min-w-[150px] font-poppins text-base px-3 py-2.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select Type</option>
                    {["BUY", "SELL"].map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>
                {/* Quantity */}
                {showQuantityColumn && (
                  <td className="px-4 py-4 items-center ">
                    <input
                      type="number"
                      value={entry.quantity}
                      onChange={(e) =>
                        handleAdviceChange(index, "quantity", e.target.value)
                      }
                      className="w-full  font-poppins text-base px-3 py-2.5 border text-black border-gray-300 rounded-md"
                      min="1"
                    />
                  </td>
                )}
                {/* LTP */}
                <td className="px-4 py-4">
                  <div className="text-right text-black">
                    {entry.symbol && getLTPForSymbol(entry.symbol)}
                  </div>
                </td>
                {/* Price */}
                <td className="px-4 py-4 min-w-[150px]">
                  {" "}
                  {/* Added minimum width to td */}
                  {entry.orderType === "MARKET" ? (
                    <div className="text-right w-full max-w-[30px] text-black">
                      {entry.symbol && getLTPForSymbol(entry.symbol)}
                    </div>
                  ) : (
                    <input
                      type="number"
                      value={entry.price || ""}
                      onChange={(e) =>
                        handleAdviceChange(index, "price", e.target.value)
                      }
                      className="w-full min-w-[10px] px-3 py-2.5 border text-black border-gray-300 rounded-md"
                      step="0.01"
                    />
                  )}
                </td>
                {/* stop loss*/}
                <td className="px-4 py-4 min-w-[150px]">
                  <input
                    type="number"
                    value={entry.stopLoss || ""}
                    onChange={(e) =>
                      handleAdviceChange(index, "stopLoss", e.target.value)
                    }
                    className="w-full min-w-[10px] px-3 py-2.5 border text-black border-gray-300 rounded-md"
                    step="0.01"
                  />
                </td>
                {/* profit target*/}
                <td className="px-4 py-4 min-w-[150px]">
                  {" "}
                  <input
                    type="number"
                    value={entry.profitTarget || ""}
                    onChange={(e) =>
                      handleAdviceChange(index, "profitTarget", e.target.value)
                    }
                    className="w-full min-w-[10px] px-3 py-2.5 border text-black border-gray-300 rounded-md"
                    step="0.01"
                  />
                </td>
                {/* Advised Range */}
                <td className="px-4 py-4">
                  <div className="flex space-x-2">
                    <input
                      type="number"
                      value={entry.adviceLower || ""}
                      onChange={(e) =>
                        handleAdviceChange(index, "adviceLower", e.target.value)
                      }
                      className="w-24 px-3 py-2.5 border text-black border-gray-300 rounded-md"
                      placeholder="Low"
                    />
                    <span className="self-center">-</span>
                    <input
                      type="number"
                      value={entry.adviceHigher || ""}
                      onChange={(e) =>
                        handleAdviceChange(
                          index,
                          "adviceHigher",
                          e.target.value
                        )
                      }
                      className="w-24 px-3 py-2.5 border text-black border-gray-300 rounded-md"
                      placeholder="High"
                    />
                  </div>
                </td>
                {/* Rationale */}
                <td className="px-4 py-4">
                  <textarea
                    value={entry.rationale}
                    onChange={(e) =>
                      handleRationaleChange(index, e.target.value)
                    }
                    className="w-full min-w-[280px] font-poppins px-3 py-2.5 border text-black border-gray-300 rounded-md"
                    rows="3"
                    placeholder="Enter rationale for this trade..."
                  />
                </td>
                {/* Comments */}
                <td className="relative px-4 py-4">
                  <div className="relative w-full min-w-[250px]">
                    <textarea
                      value={entry.comments}
                      readOnly
                      placeholder="Click to edit comment"
                      onClick={(e) => {
                        const rect = e.target.getBoundingClientRect();
                        setModalPosition({
                          top: rect.top + window.scrollY,
                          left: rect.left + window.scrollX,
                        });
                        setActiveCommentModal({
                          index,
                          type: "comments",
                        });
                      }}
                      className="w-full px-3 py-2.5 border text-black border-gray-300 rounded-md pr-10"
                      rows={3}
                    />

                    <CommentModal
                      isOpen={
                        activeCommentModal?.index === index &&
                        activeCommentModal?.type === "comments"
                      }
                      onClose={() => setActiveCommentModal(null)}
                      initialValue={entry.comments}
                      onSave={(value) => handleCommentsChange(index, value)}
                      position={modalPosition}
                      maxLength={256}
                    />
                  </div>
                </td>
                {/* Comments2 */}
                <td className="px-4 py-4">
                  <div className="relative w-full min-w-[250px]">
                    <textarea
                      value={entry.comment2}
                      readOnly
                      placeholder="Click to edit extended comment"
                      onClick={(e) => {
                        const rect = e.target.getBoundingClientRect();
                        setModalPosition({
                          top: rect.top + window.scrollY,
                          left: rect.left + window.scrollX,
                        });
                        setActiveCommentModal({
                          index,
                          type: "comment2", // Changed this to differentiate from comments
                        });
                      }}
                      className="w-full min-w-[200px] px-3 py-2.5 border text-black border-gray-300 rounded-md"
                      rows="3"
                    />

                    <CommentModal
                      isOpen={
                        activeCommentModal?.index === index &&
                        activeCommentModal?.type === "comment2" // Match with the type above
                      }
                      onClose={() => setActiveCommentModal(null)}
                      initialValue={entry.comment2}
                      onSave={(value) => handleComments2Change(index, value)}
                      position={modalPosition}
                      maxLength={256}
                    />
                  </div>
                </td>
                {/* Actions */}
                <td className="px-4 py-4">
                  {adviceEntries.length > 1 && (
                    <button
                      onClick={() => handleRemoveAdviceEntry(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <XIcon className="h-5 w-5" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SendAdviceTable;
