import React from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

interface DatePickerProps {
  date?: Date;
  setDate: (date: Date) => void;
  placeholder?: string;
  className?: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  date,
  setDate,
  placeholder = "Pick a date",
  className = "",
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleSelect = (selectedDate: Date | undefined) => {
    if (selectedDate) {
      setDate(selectedDate);
      setIsOpen(false);
    }
  };

  return (
    <div className="relative">
      <div
        className={`relative flex items-center cursor-pointer ${className}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <input
          type="text"
          placeholder={placeholder}
          value={date ? format(date, 'PPP') : ''}
          readOnly
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent cursor-pointer"
        />
        <CalendarIcon className="absolute right-3 h-5 w-5 text-gray-400" />
      </div>

      {isOpen && (
        <div className="absolute z-50 mt-2 bg-white rounded-lg shadow-lg p-4 border border-gray-200">
          <DayPicker
            mode="single"
            selected={date}
            onSelect={handleSelect}
            initialFocus={true}
            className="p-0"
          />
        </div>
      )}
    </div>
  );
};