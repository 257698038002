import React, { useState, useEffect } from "react";
import { XIcon } from "lucide-react";
import { DatePicker, Checkbox, Switch } from "antd";
import dayjs from "dayjs";
import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  selected:
    "disabled:cursor-not-allowed px-4 py-2 text-base font-semibold text-black border-b-4 border-[#000000] cursor-pointer",
  unSelected:
    "disabled:cursor-not-allowed px-4 py-2 text-base text-[#000000]/50 font-normal cursor-pointer",
  inputBox:
    "mt-1 py-2 w-full flex items-center bg-gray-100 rounded-md text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal col-span-3 lg:col-span-5 px-4 lg:mr-4 ",
  selectDiv:
    "flex items-center px-2 py-2  text-sm hover:rounded-md mx-3 mb-1 text-black  hover:bg-[#000000] hover:text-white cursor-pointer",
  inputHeaderText: "text-[14px] font-normal text-[#000000]",
  selectPreferenceDiv:
    "flex flex-row justify-center border-[1px] border-[#D9D9D9CC]/80 rounded-[6px] py-3 items-center",
};

const frequencyOptions = [
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Half Yearly", value: "half-yearly" },
  { label: "Yearly", value: "yearly" },
];

const AddPlanModal = ({
  setOpenStrategyModal,
  selected,
  nextRebalanceDate,
  setNextRebalanceDate,
  frequency,
  setFrequency,
  selectHeader,
  setSelectHeader,
  strategyName,
  setStrategyName,
  planType,
  setPlanType,
  pricing,
  setPricing,
  minInvestmentAmount,
  setMinInvestmentAmount,
  maxNetWorth,
  setMaxNetWorth,
  isSIPEnabled,
  setIsSIPEnabled,
  overView,
  setOverView,
  isValidMeta,
  handlePlanModal,
  loading,
  isOneTime,
  setIsOneTime,
  oneTimeAmount,
  setOneTimeAmount,

  isValidity,
  setIsValidity,
  // validityMonth,
  //  setValidityMonth,
  //  validityDay,
  // setValidityDay,
  // validityYear,
  // setValidityYear,

  planDescription,
  setPlanDescription,

  validityDays,
  setValidityDays,
}) => {
  const handleDateChange = (date, dateString) => {
    setNextRebalanceDate(dateString);
  };

  const onChange = (checkedValues) => {
    setFrequency(checkedValues);
  };

  const handleOneTimeToggle = (checked) => {
    setIsOneTime(checked);
    if (checked) {
      setFrequency([]);
      setPricing({});
    }
  };

  useEffect(() => {
    if (handlePlanModal) {
      console.log("Form state after submission:", {
        strategyName,
        planType,
        isOneTime,
        oneTimeAmount,
        frequency,
        pricing,
        minInvestmentAmount,
        maxNetWorth,
        nextRebalanceDate,
        isSIPEnabled,
        overView,
        isValidity,
        // validityMonth,
        // validityDay,
        // validityYear,
        planDescription,
        setPlanDescription,
        validityDays,
        setValidityDays,
      });
    }
  }, [handlePlanModal]);

  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  // const days = Array.from({ length: 31 }, (_, i) => i + 1)
  // const currentYear = new Date().getFullYear()
  // const years = Array.from({ length: 10 }, (_, i) => currentYear + i)

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 ">
      <div className="relative animate-modal">
        <div className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer ">
          <XIcon
            className="w-6 h-6 mr-2  "
            onClick={() => setOpenStrategyModal(false)}
          />
        </div>

        <div className="flex flex-col w-[350px] h-[600px] lg:w-[820px] lg:h-full   bg-white  border-[#000000]/20 rounded-md pt-6 ">
          <div className="flex flex-col  w-full px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            <div className="pb-4 text-[18px] lg:text-[22px]  text-[#000000] font-sans font-bold ">
              {selected ? "Edit" : "Create New "} Plan
            </div>
            <div className="flex flex-wrap flex-row">
              <button
                className={
                  selectHeader === "Plan" ? style.selected : style.unSelected
                }
                onClick={() => setSelectHeader("Plan")}
                disabled={!isValidMeta()}
              >
                Plan Details
              </button>
            </div>
          </div>
          {
            <>
              <div className="px-5 sm:px-8 overflow-auto custom-scroll  sm:w-full flex flex-col h-[500px] pt-4">
                <div className="w-full flex-wrap lg:flex-nowrap flex flex-row gap-3">
                  <div className="w-full lg:w-[65%] space-y-4">
                    <div>
                      <div className={style.inputHeaderText}>Name</div>
                      <input
                        type="text"
                        value={strategyName}
                        onChange={(e) => setStrategyName(e.target.value)}
                        className={`${style.inputBox}`}
                        placeholder="Enter Plan Name"
                      />
                    </div>

                    {/* Plan Type Dropdown */}
                    <div>
                      <div className={style.inputHeaderText}>Plan Type</div>
                      <select
                        value={planType}
                        onChange={(e) => setPlanType(e.target.value)}
                        className={`${style.inputBox} w-full`}
                      >
                        <option value="">Select Plan Type</option>
                        <option value="bespoke">Bespoke</option>
                        <option value="model portfolio">Model portfolio</option>
                      </select>
                    </div>

                    <div className="flex items-center justify-between">
                      <span className={style.inputHeaderText}>
                        One-Time Payment
                      </span>
                      <Switch
                        checked={isOneTime}
                        onChange={handleOneTimeToggle}
                      />
                    </div>

                    {isOneTime ? (
                      <div>
                        <div className={style.inputHeaderText}>
                          One-Time Amount
                        </div>
                        <div className="mt-1 flex flex-row items-center border border-[#000000]/20 rounded-md ">
                          <div className="text-base text-black px-3">₹</div>
                          <input
                            type="number"
                            value={oneTimeAmount}
                            onChange={(e) => setOneTimeAmount(e.target.value)}
                            className={
                              "px-3 py-2 w-full flex items-center bg-gray-100 rounded-r-md text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal"
                            }
                            placeholder="Enter one-time amount"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* Radio Group for Frequency */}
                        <div className="mb-2">
                          <div className={style.inputHeaderText}>
                            Billing Frequency
                          </div>
                          <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-row sm:space-x-6">
                            <Checkbox.Group
                              options={frequencyOptions}
                              value={frequency}
                              onChange={onChange}
                              className="contents sm:flex sm:flex-row sm:space-x-6"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                          {frequency.map((item) => (
                            <div key={item}>
                              <div className={style.inputHeaderText}>
                                {item} Charge{" "}
                              </div>
                              <div className="mt-1 flex flex-row items-center border border-[#000000]/20 rounded-md ">
                                <div className="text-base text-black px-3">
                                  ₹
                                </div>
                                <input
                                  type="number"
                                  value={pricing[item]}
                                  onChange={(e) =>
                                    setPricing((prev) => ({
                                      ...prev,
                                      [item]: e.target.value,
                                    }))
                                  }
                                  className={
                                    "px-3 py-2 w-full flex items-center bg-gray-100 rounded-r-md text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal"
                                  }
                                  placeholder="Ex. 1,00,0"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    <div className="flex items-center pt-4 justify-between">
                      <span className={style.inputHeaderText}>Validity</span>
                      <Switch
                        checked={isValidity}
                        onChange={() => setIsValidity(!isValidity)}
                      />
                    </div>

                    {isValidity && (
                      <div>
                        <div className={style.inputHeaderText}>
                          Validity (days)
                        </div>
                        <input
                          id="validityDays"
                          type="number"
                          value={validityDays}
                          onChange={(e) => setValidityDays(e.target.value)}
                          className={`${style.inputBox}`}
                          placeholder="Enter number of days"
                        />
                      </div>
                    )}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                      <div>
                        <div className={style.inputHeaderText}>
                          Min. Investment Amount{" "}
                        </div>
                        <div className="mt-1 flex flex-row items-center border border-[#000000]/20 rounded-md ">
                          <div className="text-base text-black px-3">₹</div>
                          <input
                            type="number"
                            value={minInvestmentAmount}
                            onChange={(e) =>
                              setMinInvestmentAmount(e.target.value)
                            }
                            className={
                              "px-3 py-2 w-full flex items-center bg-gray-100 rounded-r-md text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal"
                            }
                            placeholder="Ex. 1,00,000"
                          />
                        </div>
                      </div>
                      <div>
                        <div className={style.inputHeaderText}>
                          Max. Investment Amount
                        </div>
                        <div className="mt-1 flex flex-row items-center border border-[#000000]/20 rounded-md ">
                          <div className="text-base text-black px-3">₹</div>
                          <input
                            type="number"
                            value={maxNetWorth}
                            onChange={(e) => setMaxNetWorth(e.target.value)}
                            className={
                              "px-3 py-2 w-full flex items-center bg-gray-100 rounded-r-md text-[12px] lg:text-[16px] text-[#000000] font-poppins font-medium placeholder:font-normal"
                            }
                            placeholder="Ex. 1,00,000"
                          />
                        </div>
                      </div>
                    </div>

                    {/* DatePicker Input */}
                    <div>
                      <div className={style.inputHeaderText}>Start Date</div>
                      <DatePicker
                        value={
                          nextRebalanceDate ? dayjs(nextRebalanceDate) : null
                        }
                        onChange={handleDateChange}
                        className={`${style.inputBox} w-full`}
                        format="YYYY-MM-DD" // Customize the format if needed
                        placeholder="Select Start Date"
                      />
                    </div>

                    {/* SIP Checkbox */}
                    {planType !== "bespoke" ? (
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={isSIPEnabled}
                          onChange={(e) => setIsSIPEnabled(e.target.checked)}
                          className="mr-2"
                        />
                        <span className={style.inputHeaderText}>
                          Enable SIP
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="w-full pt-4">
                  <div>
                    <div className={style.inputHeaderText}>
                      Plan Description{" "}
                    </div>
                    <textarea
                      type="text"
                      value={overView}
                      id="planDescription"
                      onChange={(e) => setOverView(e.target.value)}
                      className={`h-24 ${style.inputBox}`}
                      placeholder="Describe your strategy in 4-6 lines"
                    />
                  </div>
                </div>

                <div className="w-full pt-4"></div>
              </div>

              <div className="py-4 flex flex-row justify-end items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
                <button
                  disabled={!isValidMeta()}
                  className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-8 py-2 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
                  onClick={handlePlanModal}
                >
                  {loading === true ? (
                    <LoadingSpinner />
                  ) : (
                    <span className="text-[16px] font-medium text-[#ffffff] font-poppins ">
                      Continue
                    </span>
                  )}
                </button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default AddPlanModal;
