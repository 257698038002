import React from "react";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const apiKey = "test_api_key";
const apiSecret = "test_api_secret";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

export const ClientTableHeader = ({ isAllSelected, onSelectAll }) => (
  <thead className="bg-gradient-to-r from-gray-50 to-gray-100 sticky top-0 z-20">
    <tr>
      <th className="w-12 px-4 py-3">
        <input
          type="checkbox"
          className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          checked={isAllSelected}
          onChange={(e) => onSelectAll(e.target.checked)}
        />
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Client Email
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Client Name
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Country Code
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Phone
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Date of Birth
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Location
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Telegram Id
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        PAN
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        KYC validation status
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Status
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Plan Expiry Date
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
        Actions{" "}
      </th>
    </tr>
  </thead>
);
