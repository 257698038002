import React from "react";
import ViewTradeFailureModals from "./ViewTradeFailureModals";
import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { EyeIcon, MailIcon, MessageSquareMoreIcon } from "lucide-react";

const TradeFailureCard = ({
  userEmail,
  emailCount,
  tradeFailureModal,
  setTradeFailureModal,
  selectedTab,
  setSelectedTab,
  filteredInconsistencyTrades,
  filteredFailuresTrades,
  filteredMissedTrades,
  setSelectedUser,
  selectedUser,
  userEmailCount,
  setUserEmailCount,
  advisorSpecifier,
  setOpenEmailTemplate,
}) => {
  return (
    <>
      <div className="grid grid-cols-5 font-poppins hover:bg-[#000000]/5 w-full px-4 py-[10px] border-b-[1px] border-[#000000]/10 ">
        <div className="flex items-center text-xs sm:text-[15px] font-medium text-[#000000] capitalize col-span-1  overflow-auto">
          <div
            onClick={() => {
              setTradeFailureModal(true);
              setSelectedUser(userEmail);
              setUserEmailCount(emailCount);
            }}
            className="mr-1 group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]"
          >
            <EyeIcon className="h-5 w-5  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
          </div>
          {emailCount}
        </div>
        <span className="flex items-center text-xs sm:text-[15px]   font-medium text-[#000000] col-span-3">
          {userEmail}
        </span>
        <div className="flex  items-center col-span-1">
          {/* <span className="  group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]  ">
            <MessageSquareMoreIcon className="h-5 w-5  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
          </span> */}
          {/*<span className="  group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]  ">
            <FaTelegramPlane className="h-5 w-5  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
          </span>
         <span className="  group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]  ">
            <FaWhatsapp className="h-5 w-5  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
          </span> */}
          <span className="  group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]  ">
            <MailIcon
              className="h-5 w-5  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]"
              onClick={() => {
                setOpenEmailTemplate(true);
                setSelectedUser(userEmail);
              }}
            />
          </span>
        </div>
      </div>
      {tradeFailureModal && (
        <ViewTradeFailureModals
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTradeFailureModal={setTradeFailureModal}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
          userEmailCount={userEmailCount}
          setUserEmailCount={setUserEmailCount}
          filteredFailuresTrades={
            filteredFailuresTrades && filteredFailuresTrades
          }
          filteredInconsistencyTrades={
            filteredInconsistencyTrades && filteredInconsistencyTrades
          }
          filteredMissedTrades={filteredMissedTrades && filteredMissedTrades}
          advisorSpecifier={advisorSpecifier}
        />
      )}
    </>
  );
};

export default TradeFailureCard;
