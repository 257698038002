import React from "react";
import { DatePicker } from "../ui/date-picker";

const InputField = ({ label, id, name, type = "text", value, onChange, required = false, placeholder }) => (
  <div className="flex flex-col space-y-1">
    <label htmlFor={id} className="text-sm font-medium text-gray-700">
      {label}
      {required && <span className="ml-1 text-red-500">*</span>}
    </label>
    <input
      id={id}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
    />
  </div>
);

export const ClientFormFields = ({ data, onInputChange, creationDate, setCreationDate }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <InputField
      label="Client ID"
      id="clientId"
      name="clientId"
      value={data.clientId}
      onChange={onInputChange}
      required
      placeholder="Enter Client ID"
    />

    <InputField
      label="Email"
      id="clientEmail"
      name="clientEmail"
      type="email"
      value={data.clientEmail}
      onChange={onInputChange}
      required
      placeholder="Enter Email"
    />

    <InputField
      label="Name"
      id="clientName"
      name="clientName"
      value={data.clientName}
      onChange={onInputChange}
      required
      placeholder="Enter Name"
    />

    <InputField
      label="Phone"
      id="phone"
      name="phone"
      type="tel"
      value={data.phone}
      onChange={onInputChange}
      required
      placeholder="Enter Phone"
    />

    <InputField
      label="Location"
      id="location"
      name="location"
      value={data.location}
      onChange={onInputChange}
      placeholder="Enter Location"
    />

    <InputField
      label="Telegram"
      id="telegram"
      name="telegram"
      value={data.telegram}
      onChange={onInputChange}
      placeholder="Enter Telegram ID"
    />

    <InputField
      label="KYC"
      id="pan"
      name="pan"
      value={data.pan}
      onChange={onInputChange}
      placeholder="Enter KYC"
    />

    <div className="flex flex-col space-y-1">
      <label className="text-sm font-medium text-gray-700">
        Creation Date
        <span className="ml-1 text-red-500">*</span>
      </label>
      <DatePicker
        date={creationDate}
        setDate={setCreationDate}
        placeholder="Choose Creation Date"
        className="w-full"
      />
    </div>
  </div>
);