import React, { useState, useEffect } from "react";
import {
  ChevronLeftIcon,
  SendIcon,
  PlusIcon,
  InfoIcon,
  CircleIcon,
} from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ClientTableHeader } from "./ClientTableHeader";
import { ClientTableRow } from "./ClientTableRow";
import { SearchBar } from "./SearchBar";
import AddClientModal from "./AddClientModal";
import EditClientModal from "./EditClientModal";
import DeleteModal from "../GroupClientListSection/DeleteModal";
import SendAdviceModel from "../AdminHomeSection/SendAdviceModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import moment from "moment";
import server from "../../utils/serverConfig";
import { encryptApiKey } from "../../utils/cryptoUtils";
import axios from "axios";
import {
  getStrategyDetails,
  updateStrategySubscription,
} from "../../services/ModelPFServices";

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;

const ACCEPTABLE_DATE_FORMATS = [
  "D MMM YYYY, HH:mm:ss",
  "YYYY-MM-DDTHH:mm:ss.SSSZ",
];

const ClientListDetailsSection = ({
  userId,
  groupName,
  adminEmail,
  advisorName,
  email_password,
  allClientsDataByGroup,
  isClientDataLoading,
  getAllClientsData,
  adminRationale,
  advisorSpecifier,
}) => {
  const navigate = useNavigate();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(allClientsDataByGroup);
  const [addClientModal, setAddClientModal] = useState(false);
  const [sendAdviceModal, setSendAdviceModal] = useState(false);
  const [creationDate, setCreationDate] = useState(new Date().toISOString());

  const [expiryDate, setExpiryDate] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [clientToDelete, setClientToDelete] = useState(null);

  const [newClientData, setNewClientData] = useState({
    clientEmail: "",
    clientName: "",
    phone: "",
    location: "",
    telegram: "",
    pan: "",
    charges: "",
    investAmount: "",
    invoice: "",
  });

  const [deleteUserReason, setDeleteUserReason] = useState();
  const [groupType, setGroupType] = useState("");
  const getGroupType = async () => {
    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/all-groups`,
        {
          params: {
            advisor: advisorTag,
            groupName: groupName,
          },
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
      setGroupType(response?.data?.data?.groupType);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    if (groupName) {
      getGroupType();
    }
  }, []);

  useEffect(() => {
    setIsAllSelected(selectedRows.length === filteredData?.length);
  }, [selectedRows, filteredData]);

  const isSubscriptionValid = (subscriptions, groupName) => {
    if (!subscriptions || subscriptions.length === 0) return false;

    // Filter subscriptions matching the given group name
    const matchingPlanSubs = subscriptions.filter(
      (sub) => sub?.plan === groupName
    );
    if (matchingPlanSubs.length === 0) return false;

    // Filter out deleted subscriptions
    const activeSubscriptions = matchingPlanSubs.filter(
      (sub) => sub?.status !== "deleted"
    );
    if (activeSubscriptions.length === 0) return false;

    // Validate expiry dates
    const validSubscriptions = activeSubscriptions.filter((sub) =>
      sub.expiry
        ? moment(sub.expiry, ACCEPTABLE_DATE_FORMATS, true).isValid()
        : false
    );
    if (validSubscriptions.length === 0) return false;

    // Get the latest subscription
    const latestSub = validSubscriptions.sort(
      (a, b) =>
        moment(b.expiry, ACCEPTABLE_DATE_FORMATS) -
        moment(a.expiry, ACCEPTABLE_DATE_FORMATS)
    )[0];

    // Ensure the latest subscription is still active
    const expiryDate = moment(latestSub?.expiry, ACCEPTABLE_DATE_FORMATS);
    const today = moment();

    return expiryDate.isAfter(today);
  };

  const handleCheckboxChange = (item, isChecked) => {
    setSelectedRows((prev) => {
      if (isChecked) {
        // Only add if subscription is valid
        if (isSubscriptionValid(item.subscriptions, groupName)) {
          return [...prev, item];
        }
        // Show error toast if subscription is expired
        toast.error(`${item.clientName}'s subscription has expired`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
        });
        return prev;
      } else {
        return prev.filter((row) => row.email !== item.email);
      }
    });
  };

  const handleSelectAllChange = (isChecked) => {
    if (isChecked) {
      // Filter data to only include valid subscriptions
      const validSubscriptionClients = filteredData.filter((client) =>
        isSubscriptionValid(client.subscriptions, groupName)
      );

      setSelectedRows(validSubscriptionClients);

      // Show toast if some clients were skipped
      // const skippedCount =
      //   filteredData.length - validSubscriptionClients.length;
      // if (skippedCount > 0) {
      //   toast.error(
      //     `${skippedCount} client(s) with expired subscriptions were skipped`,
      //     {
      //       duration: 3000,
      //       style: {
      //         background: "white",
      //         color: "#1e293b",
      //         maxWidth: "500px",
      //         fontWeight: 600,
      //         fontSize: "13px",
      //         padding: "10px 20px",
      //       },
      //     }
      //   );
      // }
    } else {
      setSelectedRows([]);
    }
    setIsAllSelected(isChecked);
  };

  const handleEditClient = (client) => {
    const validSubscriptions = client?.subscriptions?.filter((sub) =>
      sub.expiry
        ? moment(sub.expiry, ACCEPTABLE_DATE_FORMATS, true).isValid()
        : false
    );
    if (validSubscriptions?.length === 0) return false;
    // Sort subscriptions by expiry date in descending order
    const latestSub = validSubscriptions?.sort(
      (a, b) =>
        moment(b.expiry, ACCEPTABLE_DATE_FORMATS) -
        moment(a.expiry, ACCEPTABLE_DATE_FORMATS)
    )[0];
    // Parse expiry date and compare with the current date
    setClientToEdit({
      ...client,
      clientEmail: client.email, // Map email to clientEmail for the form
      creationDate: new Date(client.creationDate), // Ensure date is a Date object
    });
    setDateOfBirth(client?.DateofBirth);
    setExpiryDate(latestSub?.expiry);
    setShowEditModal(true);
  };

  const handleDeleteClient = (client) => {
    setClientToDelete(client);
    setShowDeleteModal(true);
  };

  const [editLoading, setEditLoading] = useState(false);
  const handleUpdateClient = async (updatedClientData) => {
    setEditLoading(true);
    const newSubscription = {
      startDate: new Date(),
      plan: groupName, // Assuming the response contains a plan
      capital: "", // Assuming the response contains capital
      charges: 0, // Assuming the response contains charges
      invoice: "", // Assuming the response contains invoice
      expiry: new Date(expiryDate), // Assuming the response contains expiry date
    };
    const allData = {
      clientName: updatedClientData.clientName,
      email: updatedClientData.clientEmail,
      country_code: updatedClientData?.countryCode,
      phone: updatedClientData.phone,
      groups: ["All Client", groupName],
      location: updatedClientData.location,
      telegram: updatedClientData.telegram,
      pan: updatedClientData.pan,
      creationDate: new Date(creationDate),
      subscriptions: [
        {
          ...newSubscription, // Attach the new subscription here
        },
      ],
    };

    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify({
            userId,
            clientData: allData,
            DateofBirth: dateOfBirth || "",
            advisorName: advisorTag,
          }),
        }
      );

      if (response.ok) {
        setNewClientData({
          clientName: "",
          clientEmail: "",
          phone: "",
          location: "",
          telegram: "",
          pan: "",
        });

        setAddClientModal(false);
        setEditLoading(false);
        getAllClientsData(userId);
        toast.success("Client details updated successfully!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
        });
      } else {
        throw new Error("Failed to add client");
      }
    } catch (error) {
      console.error("Error adding client:", error);
      setEditLoading(false);
      toast.error("Failed to update client details", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
    }
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleConfirmDelete = async (client) => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/clientlistdatas/group`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify({
            email: client.email,
            groupToDelete: groupName,
            reason: deleteUserReason ? deleteUserReason : "NA",
            refund: 0,
          }),
        }
      );

      if (groupType === "model portfolio") {
        const strategyResponse = await getStrategyDetails(groupName);
        const strategyDetails = strategyResponse?.data[0].originalData;

        // UNSubscribe user to strategy
        await updateStrategySubscription(
          client.email,
          "unsubscribe",
          strategyDetails
        );
      }
      getAllClientsData(userId);
      setDeleteLoading(false);
      toast.success(`${client.clientName} has been deleted successfully`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting client:", error);
      toast.error("Failed to delete client", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
      setDeleteLoading(false);
      setShowDeleteModal(false);
    }
  };

  const handleSendAdviceFromClientList = () => {
    if (selectedRows.length === 0) {
      toast.error("Please select the user(s) to send advice to.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
      return;
    }
    setSendAdviceModal(true);
  };

  const itemsPerPage = 30; // Show 30 items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    // Filter and sort on the full data set
    const sortedData = [...(allClientsDataByGroup || [])].sort(
      (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
    );

    const filtered = sortedData.filter((user) =>
      user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to page 1 after every new search
  }, [searchQuery, allClientsDataByGroup]);

  useEffect(() => {
    // Apply pagination to the filtered data
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedData(filteredData.slice(startIndex, endIndex));
  }, [filteredData, currentPage]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
    if (totalPages <= maxVisiblePages + 4) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) pages.push(i);
      if (endPage < totalPages - 1) pages.push("...");
      pages.push(totalPages);
    }
    return pages;
  };

  // for kyc
  const getAllUserKyc = async () => {
    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}misc/kyc/kra/status/<advisor_name>/<email_id>`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
      console.log("res", response);
      getAllClientsData(userId);
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  useEffect(() => {
    getAllUserKyc();
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="w-full bg-[#F8F9FA] text-white"
    >
      <Toaster position="top-center" reverseOrder={true} />

      {/* Info Banner */}
      <div className="bg-[#131A29] text-white">
        <div className=" mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex items-center space-x-4">
            <InfoIcon className="h-6 w-6 text-gray-300" />
            <div>
              <h2 className="text-xl font-semibold">
                Client Management System
              </h2>
              <p className="text-gray-300 mt-1">
                Efficiently manage your client portfolio with comprehensive
                tools for client data management, group organization, and
                automated advice distribution.
              </p>
              <div className="flex gap-6 mt-4">
                <div className="flex items-center text-sm text-gray-300">
                  <CircleIcon className="h-2 w-2 mr-2 text-green-400" />
                  <span>
                    {filteredData?.filter((client) =>
                      isSubscriptionValid(client.subscriptions, groupName)
                    )?.length || 0}{" "}
                    Active Clients in Group
                  </span>
                </div>
                <div className="flex items-center text-sm text-gray-300">
                  <CircleIcon className="h-2 w-2 mr-2 text-yellow-400" />
                  <span>{selectedRows.length} Selected</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Header Section */}
      <div className="sticky top-0 z-50 bg-white/80 backdrop-blur-xl border-b border-gray-200 shadow-sm">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex flex-col space-y-4">
            {/* Back Button & Title */}
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => navigate(-1)}
                  className="inline-flex items-center text-gray-600 hover:text-gray-900 transition-colors"
                >
                  <ChevronLeftIcon className="h-5 w-5 mr-1" />
                  <span className="text-sm font-medium">Back to Home</span>
                </button>
                <h1 className="text-2xl font-bold text-gray-900 capitalize">
                  {groupName?.replaceAll(/_/g, " ")}
                </h1>
              </div>

              <div className="flex items-center space-x-3">
                <button
                  onClick={() => setAddClientModal(true)}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                >
                  <PlusIcon className="w-4 h-4 mr-2" />
                  Add Client
                </button>
                <button
                  onClick={handleSendAdviceFromClientList}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black hover:bg-gray-900 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
                >
                  <SendIcon className="w-4 h-4 mr-2" />
                  Send Advice
                </button>
              </div>
            </div>

            {/* Search Bar */}
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div className=" mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {isClientDataLoading ? (
          <div className="flex items-center justify-center h-96">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200">
            <div className="overflow-hidden">
              <div className="overflow-x-auto">
                <div className="overflow-y-auto max-h-[calc(100vh-320px)]">
                  <table className="min-w-full divide-y divide-gray-200">
                    <ClientTableHeader
                      isAllSelected={isAllSelected}
                      onSelectAll={handleSelectAllChange}
                    />
                    <tbody className="bg-white divide-y divide-gray-200">
                      {paginatedData?.map((item) => (
                        <ClientTableRow
                          key={item.clientId}
                          item={item}
                          isSelected={selectedRows.some(
                            (row) => row.email === item.email
                          )}
                          onCheckboxChange={handleCheckboxChange}
                          onEdit={() => handleEditClient(item)}
                          onDelete={() => handleDeleteClient(item)}
                          isSubscriptionValid={isSubscriptionValid}
                          groupName={groupName}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Pagination */}
            <div className="bg-gray-50 px-4 py-3 border-t border-gray-200">
              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => goToPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  Previous
                </button>

                <div className="flex items-center space-x-2">
                  {getPageNumbers().map((page, index) =>
                    page === "..." ? (
                      <span
                        key={index}
                        className="px-3 py-1.5 text-sm text-gray-600"
                      >
                        ...
                      </span>
                    ) : (
                      <button
                        key={index}
                        onClick={() => goToPage(page)}
                        className={`inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors ${
                          page === currentPage
                            ? "bg-gray-900 text-white"
                            : "text-gray-700 bg-white border border-gray-300 hover:bg-gray-50"
                        }`}
                      >
                        {page}
                      </button>
                    )
                  )}
                </div>

                <button
                  onClick={() => goToPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Modals */}
      {addClientModal && (
        <AddClientModal
          closeAddClientModal={() => setAddClientModal(false)}
          setNewClientData={setNewClientData}
          newClientData={newClientData}
          userId={userId}
          setAddClientModal={setAddClientModal}
          getAllClientsData={getAllClientsData}
          groupName={groupName}
          setCreationDate={setCreationDate}
          creationDate={creationDate}
          setExpiryDate={setExpiryDate}
          expiryDate={expiryDate}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          groupType={groupType}
        />
      )}

      {showEditModal && (
        <EditClientModal
          client={clientToEdit}
          onClose={() => setShowEditModal(false)}
          onUpdate={handleUpdateClient}
          setExpiryDate={setExpiryDate}
          expiryDate={expiryDate}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          editLoading={editLoading}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          client={clientToDelete}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleConfirmDelete}
          deleteUserReason={deleteUserReason}
          setDeleteUserReason={setDeleteUserReason}
          deleteLoading={deleteLoading}
        />
      )}

      {sendAdviceModal && (
        <SendAdviceModel
          fileName={groupName}
          closeSendAdviceModal={() => setSendAdviceModal(false)}
          data={selectedRows}
          adminEmail={adminEmail}
          advisorName={advisorName}
          email_password={email_password}
          setSendAdviceModal={setSendAdviceModal}
          setSelectedRows={setSelectedRows}
          adminRationale={adminRationale}
          advisorSpecifier={advisorSpecifier}
        />
      )}
    </motion.div>
  );
};

export default ClientListDetailsSection;
