import { CircleCheck } from "lucide-react";
import React from "react";

const BespokeCard = ({ ele, handlePricingCardClick }) => {
  const onCardClick = () => {
    handlePricingCardClick(ele);
  };

  return (
    <div
      className="cursor-pointer relative font-poppins w-[95%] h-[98%] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4"
      onClick={onCardClick}
    >
      <div className="flex items-start pl-8 p-4">
        <div className="overflow-hidden rounded-lg">
          <h2 className="text-2xl font-semibold text-[#000000] capitalize">
            {ele?.name}
            <span className="ml-3 text-[16px] font-normal text-[#95989C]">
              {ele?.duration
                ? `(${Math.round(ele.duration / 30)} month${
                    Math.round(ele.duration / 30) > 1 ? "s" : ""
                  })`
                : ""}
            </span>
          </h2>

          {ele?.frequency?.length !== 0 ? (
            <div className="flex items-baseline">
              <h2 className="font-poppins text-[40px] font-bold text-[#333333] leading-none">
                ₹
                {ele?.pricing?.monthly
                  ? ele.pricing.monthly
                  : ele?.pricing?.quarterly
                  ? ele.pricing.quarterly
                  : ele?.pricing?.halfYearly
                  ? ele.pricing.halfYearly
                  : ele?.pricing?.yearly
                  ? ele.pricing.yearly
                  : "N/A"}
              </h2>
              <p className="text-[16px] text-[#95989C] font-normal ml-1">
                {ele?.pricing?.monthly
                  ? "/month"
                  : ele?.pricing?.quarterly
                  ? "/quarter"
                  : ele?.pricing?.halfYearly
                  ? "/half-year"
                  : ele?.pricing?.yearly
                  ? "/year"
                  : ""}
              </p>
            </div>
          ) : (
            <div className="flex items-baseline">
              <h2 className="font-poppins text-[40px] font-bold text-[#333333] leading-none">
                ₹{ele?.amount}
              </h2>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col min-h-[220px] items-center pt-7 p-4 space-y-4 w-90">
        <div className="font-poppins text-[12.5px] flex flex-row items-start space-x-2 text-[#666666]">
          <CircleCheck
            size={20}
            className="text-white fill-[#16826C] shrink-0"
          />
          <span className="text-sm font-poppins text-left text-gray-600">
            {ele?.description || "No description available"}
          </span>
        </div>
        {/* Subscription Dates */}
        {ele?.subscription && (
          <div className="text-sm text-gray-600 mt-2">
            <p>
              Start Date of Subscription:{" "}
              {new Date(ele.subscription.start_date).toLocaleDateString()}
            </p>
            <p>
              End Date of Subscription:{" "}
              {new Date(ele.subscription.end_date).toLocaleDateString()}
            </p>
          </div>
        )}
      </div>

      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={(e) => {
              e.stopPropagation();
              handlePricingCardClick(ele);
            }}
            className={`w-[10
            rem] h-10 py-2 px-1 rounded-md ${
              ele?.subscription
                ? "bg-blue-500 text-white"
                : "bg-black text-white"
            } border-black border-[2px] text-xs font-medium hover:bg-white hover:text-black transition-all duration-150 ease-linear`}
          >
            {ele?.subscription ? "Renew Now" : "Subscribe Now"}
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handlePricingCardClick(ele);
            }}
            className={`w-[10rem] h-10 py-2 px-1 rounded-md ${
              ele?.subscription
                ? "bg-blue-500 text-white"
                : "bg-black text-white"
            } border-black border-[2px] text-xs font-medium hover:bg-white hover:text-black transition-all duration-150 ease-linear`}
          >
            {ele?.subscription ? "Renew Now" : "Move Plan"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BespokeCard;
