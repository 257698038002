"use client";

import { useState, useMemo } from "react";
import {
  XIcon,
  ChevronLeft,
  ChevronRight,
  ArrowLeft,
  AlertCircle,
} from "lucide-react";
import { FaCheckCircle, FaCamera } from "react-icons/fa";
import toast from "react-hot-toast";
import axios from "axios";
import dayjs from "dayjs";

import StockDistribution from "../StrategySection/StockDistribution";
import server from "../../utils/serverConfig";
import Preview from "../../assests/default_image.png";
import { DatePicker } from "../../components/ui/date-picker";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const style = {
  selected:
    "disabled:cursor-not-allowed px-4 py-2 text-base font-semibold text-black border-b-[3px] border-[#000000] cursor-pointer",
  unSelected:
    "disabled:cursor-not-allowed px-4 py-2 text-base text-[#000000]/50 font-normal cursor-pointer",
  inputBox:
    "w-full py-2 xl:px-6 md:py-2.5  bg-[#ffffff] text-[12px] md:text-[16px]  peer text-gray-900 placeholder-transparent font-poppins font-medium rounded-md  ring-1 hover:ring-1 ring-gray-200  hover:ring-[#D9D9D9] focus:outline-none focus:ring-1 focus:ring-[#D9D9D9]  transition ease-in duration-200 ",
  selectDiv:
    "flex items-center px-2 py-2  text-sm hover:rounded-md mx-3 mb-1 text-black  hover:bg-[#000000] hover:text-white cursor-pointer",
  inputHeaderText:
    "text-sm text-gray-900 text-left font-normal font-poppins sm:mb-[2px]",
  selectPreferenceDiv:
    "flex flex-row justify-center  rounded-[6px] py-3 items-center",
};

const FrequencyList = [
  { name: "Every Year" },
  { name: "Every Quarter" },
  { name: "Every Month" },
  { name: "Every Week" },
  { name: "Every Day" },
  { name: "Need Basis" },
];

const InvestmentStrategyList = [
  { name: "Asset Allocation" },
  { name: "Factor Investing" },
  { name: "Quantitative" },
  { name: "Technical" },
  { name: "Quality" },
  { name: "Growth" },
  { name: "Value" },
  { name: "Thematic" },
  { name: "Sector Tracker" },
  { name: "ESG" },
  { name: "Corporate Governance" },
  { name: "Quantamental" },
  { name: "Fundamental" },
  { name: "Momentum" },
  { name: "Goal Based" },
  { name: "Dividend" },
];

const MethodologyList = [
  { name: "Defining the universe", value: 1 },
  { name: "Research", value: 2 },
  { name: "Constituent Screening", value: 3 },
  { name: "Weighting", value: 4 },
  { name: "Rebalance", value: 5 },
  { name: "Asset Allocation", value: 6 },
];

const rationalePara =
  "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries.";

const EditNewStrategyModal = ({
  setOpenStrategyModal,
  getAllModalPortfolioList,
  createPlan,
  plan,
  loading,
  setLoading,
  initialData,
  onClose,
  setIsModalOpen,
}) => {
  const parseInvestmentStrategy = (strategy) => {
    if (!strategy) return [];
    if (Array.isArray(strategy)) return strategy;
    if (typeof strategy === "string") return strategy.split(",");
    return [];
  };

  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const [selectHeader, setSelectHeader] = useState("Meta");

  const [overView, setOverView] = useState(initialData?.overView || "");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(Preview);

  const [investmentStrategy, setInvestmentStrategy] = useState(
    parseInvestmentStrategy(initialData?.investmentStrategy)
  );

  const [rationaleText, setRationaleText] = useState(
    initialData?.whyThisStrategy || rationalePara
  );
  const [blogLink, setBlogLink] = useState(initialData?.blogLink || "");
  const [researchReport, setResearchReport] = useState(
    initialData?.researchReportLink || ""
  );
  const [definingUniverse, setDefiningUniverse] = useState(
    initialData?.definingUniverse || ""
  );
  const [researchOverView, setResearchOverView] = useState(
    initialData?.researchOverView || ""
  );
  const [constituentScreening, setConstitunetScreening] = useState(
    initialData?.constituentScreening || ""
  );
  const [weighting, setWeighting] = useState(initialData?.weighting || "");
  const [rebalanceMethodologyText, setRebalanceMethodologyText] = useState(
    initialData?.rebalanceMethodologyText || ""
  );
  const [assetAllocationText, setAssetAllocationText] = useState(
    initialData?.assetAllocationText || ""
  );
  const [frequency, setFrequency] = useState(initialData?.frequency || "");
  const [nextRebalanceDate, setNextRebalanceDate] = useState(
    initialData?.nextRebalanceDate ? dayjs(initialData.nextRebalanceDate) : null
  );

  const [selectMethodology, setSelectMethodology] = useState(1);
  const [adviceEntries, setAdviceEntries] = useState([
    {
      symbol: "",
      exchange: "",
      segment: "EQUITY",
      inputValue: "",
      symbols: [],
      value: "",
    },
  ]);

  const [minimumPortfolioAmount, setMinimumPortfolioAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [savebutton, setSavebutton] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [editableData, setEditableData] = useState({
    minInvestment: initialData?.minInvestment || "",
    maxNetWorth: initialData?.maxNetWorth || "",
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleLike = (itemId) => {
    const isLiked = investmentStrategy.includes(itemId);

    if (isLiked) {
      setInvestmentStrategy((prevLikes) =>
        prevLikes.filter((id) => id !== itemId)
      );
    } else {
      if (investmentStrategy.length < 3) {
        setInvestmentStrategy((prevLikes) => [...prevLikes, itemId]);
      } else {
        toast.error("You can only select up to 3 items!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    }
  };

  const isLiked = (itemId) => investmentStrategy.includes(itemId);

  const isFieldFilled = (methodologyValue) => {
    switch (methodologyValue) {
      case 1:
        return definingUniverse.trim().length > 0;
      case 2:
        return researchOverView.trim().length > 0;
      case 3:
        return constituentScreening.trim().length > 0;
      case 4:
        return weighting.trim().length > 0;
      case 5:
        return rebalanceMethodologyText.trim().length > 0;
      case 6:
        return assetAllocationText.trim().length > 0;
      default:
        return false;
    }
  };

  const calculateTotalAllocation = () => {
    return adviceEntries.reduce((total, entry) => {
      return total + (Number.parseFloat(entry.value) || 0);
    }, 0);
  };

  const totalAllocation = useMemo(
    () => calculateTotalAllocation(),
    [adviceEntries, adviceEntries.length]
  );

  const isValidMeta = () => overView;
  const isValidRationale = () => rationaleText;
  const isValidMethodology = () =>
    definingUniverse.trim() !== "" &&
    researchOverView.trim() !== "" &&
    constituentScreening.trim() !== "" &&
    weighting.trim() !== "" &&
    rebalanceMethodologyText.trim() !== "";
  const isValidFrequency = () => frequency && nextRebalanceDate;
  const isValidAdviceEntries = () =>
    adviceEntries.length > 0 && totalAllocation === 100;

 
  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = {
        advisor: advisorTag,
        model_name: initialData.model_name,
        minInvestment: editableData.minInvestment,
        maxNetWorth: editableData.maxNetWorth,
        overView,
        investmentStrategy: Array.isArray(investmentStrategy)
          ? investmentStrategy
          : investmentStrategy.join(","),
        whyThisStrategy: rationaleText,
        blogLink,
        researchReportLink: researchReport,
        definingUniverse,
        researchOverView,
        constituentScreening,
        weighting,
        rebalanceMethodologyText,
        assetAllocationText,
        frequency,
        image:'',

        nextRebalanceDate: nextRebalanceDate
          ? nextRebalanceDate.format("YYYY-MM-DD")
          : null,
          
      };

      const response = await axios.put(
        `${server.ccxtServer.baseUrl}comms/update_model_portfolio`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
  
      );
      if (image) {
        const formData = new FormData();
        formData.append('id', response.data?._id || initialData?._id);
        formData.append('image', image);
        
        await axios.post(`${server.server.baseUrl}api/model-portfolio/newPlanImage`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          }
        });
      }
      if (response.status === 200) {
        toast.success("Strategy updated successfully!");
        getAllModalPortfolioList();
        setOpenStrategyModal(false);
      } else {
        throw new Error("Failed to update strategy");
      }
    } catch (error) {
      console.error("Error updating strategy:", error);
      toast.error("Failed to update strategy. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleMinimumPortfolioAmount = () => {
    setIsLoading(true);
    const config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/minimum-portfolio-amount-new`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: {
        model_portfolio: adviceEntries.map((item) => ({
          symbol: item.symbol,
          value: Number.parseFloat(item.value / 100).toFixed(2),
          exchange: item.exchange,
        })),
      },
    };
  
    axios
      .request(config)
      .then((response) => {
        setMinimumPortfolioAmount(response.data);
        setSavebutton(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error calculating minimum portfolio amount!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setIsLoading(false);
      });
  };

  const handleInputChange = (field, value) => {
    setEditableData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const isFormValid = () => {
    return (
      totalAllocation === 100 &&
      adviceEntries.every(
        (entry) => entry.symbol && entry.value && entry.value > 0
      )
    );
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 ">
      <div className="relative animate-modal">
        <div className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer ">
          <XIcon
            className="w-6 h-6 mr-2  "
            onClick={() => setOpenStrategyModal(false)}
          />
        </div>

        <div className="flex flex-col w-full h-[600px] max-h-[600px] lg:w-[820px] lg:h-full   bg-white  border-[#000000]/20 rounded-md pt-6 overflow-hidden">
          <div className="flex flex-col  w-full px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            <div className="flex flex-row items-center pb-4 text-[18px] lg:text-[22px] text-[#000000] font-sans font-bold">
              <button
                onClick={() => {
                  onClose();
                  setIsModalOpen(true);
                }}
                className="mr-2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                <ArrowLeft className="h-6 w-6" />
              </button>
              <span>Edit Model Portfolio</span>
            </div>
            <div className="grid grid-cols-2 sm:flex sm:flex-row sm:flex-wrap gap-2 w-full">
              <button
                className={`px-2 py-1 text-xs sm:text-sm sm:px-3 sm:py-2 ${
                  selectHeader === "Meta" ? style.selected : style.unSelected
                }`}
                onClick={() => setSelectHeader("Meta")}
              >
                Meta
              </button>
              <button
                className={`px-2 py-1 text-xs sm:text-sm sm:px-3 sm:py-2 ${
                  selectHeader === "Rationale"
                    ? style.selected
                    : style.unSelected
                }`}
                disabled={!isValidMeta()}
                onClick={() => setSelectHeader("Rationale")}
              >
                Rationale
              </button>
              <button
                className={`px-2 py-1 text-xs sm:text-sm sm:px-3 sm:py-2 ${
                  selectHeader === "Methodology"
                    ? style.selected
                    : style.unSelected
                }`}
                onClick={() => setSelectHeader("Methodology")}
                disabled={!isValidRationale()}
              >
                Methodology
              </button>
              <button
                className={`px-4 py-2 text-[14px] font-medium text-gray-400 cursor-not-allowed opacity-50 ${
                  selectHeader === "Stock Distribution"
                    ? style.selected
                    : style.unSelected
                }`}
                // onClick={() => setSelectHeader("Stock Distribution")}
                disabled
              >
                Stock Distribution
              </button>
              <button
                className={`px-4 py-2 text-[14px] font-medium text-gray-400 cursor-not-allowed opacity-50 ${
                  selectHeader === "Rebalance"
                    ? style.selected
                    : style.unSelected
                }`}
                // onClick={() => setSelectHeader("Rebalance")}
                disabled
              >
                Rebalance
              </button>
            </div>
          </div>

          {selectHeader === "Meta" && (
            <div className="px-8 w-full flex flex-col  overflow-auto custom-scroll pt-4">
              <div className="w-full flex-wrap lg:flex-nowrap flex flex-row gap-3">
                <div className="w-full lg:w-[65%] space-y-4">
                  <div>
                    <div className="flex items-center">
                      <div className={style.inputHeaderText}>Name </div>
                      <span className="text-red-500 ml-1">*</span>
                    </div>{" "}
                    <input
                      type="text"
                      value={initialData.model_name}
                      readOnly
                      className={`${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal bg-gray-100`}
                    />
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                    <div>
                      <div className="flex items-center">
                        <div className={style.inputHeaderText}>
                          Min. Investment Amount{" "}
                        </div>
                        <span className="text-red-500 ml-1">*</span>
                      </div>
                      <div className="relative mt-1 flex flex-row items-center ">
                        <div className="absolute rounded-l-md text-base text-black font-poppins  py-[10px]  px-2">
                          ₹
                        </div>
                        <input
                          type="number"
                          value={editableData.minInvestment}
                          onChange={(e) =>
                            handleInputChange("minInvestment", e.target.value)
                          }
                          className={`${style.inputBox}  pl-7 pr-3 sm:pl-8 xl:px-0 xl:pl-[44px] placeholder:text-gray-400 placeholder:font-normal`}
                          placeholder="Ex. 1,00,000"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center">
                        <div className={style.inputHeaderText}>
                          Max Networth
                        </div>
                        <span className="text-red-500 ml-1">*</span>
                      </div>
                      <div className="relative mt-1 flex flex-row items-center">
                        <div className="absolute rounded-l-md text-base text-black font-poppins  py-[10px]  px-2">
                          ₹
                        </div>
                        <input
                          type="number"
                          value={editableData.maxNetWorth}
                          onChange={(e) =>
                            handleInputChange("maxNetWorth", e.target.value)
                          }
                          className={`${style.inputBox} pl-7 pr-3 sm:pl-8 xl:px-0 xl:pl-[44px] placeholder:text-gray-400 placeholder:font-normal`}
                          placeholder="Ex. 1,00,000"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative lg:ml-6 w-full lg:w-[30%] bg-[#F9F9F9] border-[1px] border-[#000000]/10 rounded-md h-[100px] lg:h-auto">
                  {preview && (
                    <div className="h-full  flex flex-row justify-center">
                      <img
                        src={preview || "/placeholder.svg"}
                        alt="Image Preview"
                        className="image-preview"
                      />
                    </div>
                  )}
                  <label
                    htmlFor="imageUpload"
                    className=" w-[150px] mx-auto bg-[#f5f5f5] text-[14px] font-poppins border-[1px] border-[#000000]/20 absolute right-0 left-0 bottom-2 mt-3  py-1 flex flex-row justify-center items-center cursor-pointer  rounded-[6px]"
                  >
                    <FaCamera className="mr-2 font-semibold" />
                    <span>{image ? image.name : "Choose Image"}</span>
                  </label>
                  <input
                    id="imageUpload"
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>

              <div className="w-full pt-4 ">
                <div>
                  <div
                    className={`${style.inputHeaderText} flex justify-between pr-1`}
                  >
                    <span>
                      Overview <span className="text-red-500 ml-1">*</span>
                    </span>
                    <span>150</span>
                  </div>
                  <textarea
                    value={overView}
                    onChange={(e) => setOverView(e.target.value)}
                    className={`h-16 ${style.inputBox}`}
                    placeholder="Describe your strategy in 4-6 lines"
                    maxLength={150}
                  />
                </div>
              </div>
              <div className="w-full pt-4 ">
                <div>
                  <div className={`text-base ${style.inputHeaderText}`}>
                    Investment Strategy (Select upto 3)<span style={{ color: 'red' }}>*</span>
                  </div>
                  <div className="flex flex-wrap my-4 gap-x-3 gap-y-3 ">
                    {InvestmentStrategyList.map((ele, i) => (
                      <div
                        className={
                          isLiked(ele.name)
                            ? `text-[10px] sm:text-[12px] px-2 sm:px-3 group  py-1 sm:py-1.5 font-medium cursor-pointer group border-[1px] border-[#D9D9D9CC]/80  ${style.selectPreferenceDiv} shadow-md hover:shadow-2xl hover:scale-105`
                            : `bg-[#F7F7F7] text-[10px] sm:text-[12px] px-2 sm:px-3 group  py-1 sm:py-1.5 font-medium cursor-pointer group   ${style.selectPreferenceDiv} hover:scale-105`
                        }
                        key={i}
                        onClick={() => handleLike(ele.name)}
                      >
                        {isLiked(ele.name) ? (
                          <FaCheckCircle
                            size={16}
                            color="#18B13F"
                            className={`mr-1 sm:mr-1.5`}
                            onClick={() => handleLike(ele.name)}
                          />
                        ) : (
                          <FaCheckCircle
                            size={16}
                            className={`mr-1 sm:mr-1.5  text-[#000000]/10`}
                            onClick={() => handleLike(ele.name)}
                          />
                        )}
                        {ele.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectHeader === "Rationale" && (
            <div className="px-8 w-full h-auto md:h-[500px] pt-8 space-y-3 md:space-y-8">
              <div>
                <div className={style.inputHeaderText}>
                  Rationale <span className="text-red-500 ml-1">*</span>
                </div>
                <textarea
                  value={rationaleText}
                  onChange={(e) => setRationaleText(e.target.value)}
                  className={`h-20 md:h-40 ${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
                  placeholder="Rationale"
                />
              </div>
              <div>
                <div className={style.inputHeaderText}>
                  Blog Link (optional)
                </div>
                <input
                  type="text"
                  value={blogLink}
                  onChange={(e) => setBlogLink(e.target.value)}
                  className={`${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
                  placeholder="Enter the link to your blog here"
                />
              </div>
              <div>
                <div className={style.inputHeaderText}>
                  Research Point (optional)
                </div>
                <input
                  type="text"
                  value={researchReport}
                  onChange={(e) => setResearchReport(e.target.value)}
                  className={`${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
                  placeholder="Enter the link to your research point here"
                />
              </div>
            </div>
          )}

          {selectHeader === "Methodology" && (
            <div className="px-4 sm:px-8 w-full flex flex-wrap md:flex-nowrap flex-row h-auto lg:h-[500px] overflow-auto custom-scroll pt-8">
              <div className="flex flex-col w-full">
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={() =>
                      setSelectMethodology((prev) => Math.max(1, prev - 1))
                    }
                    disabled={selectMethodology === 1}
                    className="p-2 disabled:opacity-50"
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                  <h3 className="text-lg font-semibold">
                    {MethodologyList[selectMethodology - 1].name}
                  </h3>
                  <button
                    onClick={() =>
                      setSelectMethodology((prev) =>
                        Math.min(MethodologyList.length, prev + 1)
                      )
                    }
                    disabled={selectMethodology === MethodologyList.length}
                    className="p-2 disabled:opacity-50"
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>
                </div>
                <div className="flex items-center space-x-2 bg-blue-50 p-1 rounded-md mb-4">
                  <AlertCircle className="h-4 w-4 text-blue-500" />
                  <p className="text-sm text-blue-700 font-poppins">
                    Please complete all the given Methodology to proceed.
                  </p>
                </div>
                {selectMethodology === 1 && (
                  <textarea
                    value={definingUniverse}
                    onChange={(e) => setDefiningUniverse(e.target.value)}
                    className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                    placeholder="Defining the universe in 2-3 lines"
                    maxLength={1000}
                  />
                )}
                {selectMethodology === 2 && (
                  <textarea
                    value={researchOverView}
                    onChange={(e) => setResearchOverView(e.target.value)}
                    className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                    placeholder="Research Overview"
                  />
                )}
                {selectMethodology === 3 && (
                  <textarea
                    value={constituentScreening}
                    onChange={(e) => setConstitunetScreening(e.target.value)}
                    className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                    placeholder="Constituent Screening Details"
                  />
                )}
                {selectMethodology === 4 && (
                  <textarea
                    value={weighting}
                    onChange={(e) => setWeighting(e.target.value)}
                    className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                    placeholder="Weighting Methodology"
                  />
                )}
                {selectMethodology === 5 && (
                  <textarea
                    value={rebalanceMethodologyText}
                    onChange={(e) =>
                      setRebalanceMethodologyText(e.target.value)
                    }
                    className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                    placeholder="Rebalance Methodology"
                  />
                )}
                {selectMethodology === 6 && (
                  <textarea
                    value={assetAllocationText}
                    onChange={(e) => setAssetAllocationText(e.target.value)}
                    className="h-60 w-full p-4 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400"
                    placeholder="Asset Allocation Strategy"
                  />
                )}
              </div>
            </div>
          )}

          {selectHeader === "Stock Distribution" && (
            <div className="px-8 w-full flex flex-col h-[500px] overflow-auto custom-scroll pt-4">
              <StockDistribution
                setAdviceEntries={setAdviceEntries}
                adviceEntries={adviceEntries}
                minimumPortfolioAmount={minimumPortfolioAmount}
                setSavebutton={setSavebutton}
              />
            </div>
          )}

          {selectHeader === "Rebalance" && (
            <div className="py-8 px-10 w-full flex flex-col h-[500px] overflow-auto custom-scroll">
              <div className="mt-2 flex flex-col space-y-10">
                <div>
                  <div className={style.inputHeaderText}>Frequency</div>
                  <div className="mt-2 relative">
                    <div className="w-full md:w-[350px]">
                      <Select
                        value={frequency}
                        onValueChange={(value) => setFrequency(value)}
                      >
                        <SelectTrigger className="py-2.5 bg-[#ffffff]  text-[16px] font-poppins font-medium border-none ring-1 hover:ring-1 ring-gray-200  hover:ring-[#D9D9D9] focus:outline-none focus:ring-1 focus:ring-[#D9D9D9]">
                          <SelectValue placeholder={"Select Frequency"} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {FrequencyList?.map((option, index) => (
                              <SelectItem
                                key={index}
                                value={option.name}
                                className="font-poppins font-medium capitalize text-[14px]"
                              >
                                {option.name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={style.inputHeaderText}>
                    Next Rebalance Date
                  </div>
                  <div className="mt-2 w-full md:w-[350px] text-[12px] md:text-[16px]">
                    <DatePicker
                      className="px-2 py-2 md:px-4 md:py-3 bg-[#ffffff]"
                      date={nextRebalanceDate}
                      setDate={setNextRebalanceDate}
                      placeholder={"Choose Rebalance Date"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="py-4 flex flex-row justify-end items-center px-4 lg:px-8 shadow-[4px_0px_6px_0px_rgba(0,0,0,0.06)]">
            <button
              className="px-8 py-2 ml-4 bg-black text-white text-base font-semibold rounded-md cursor-pointer"
              onClick={handleSave}
            >
              Save Changes
            </button>
            {selectHeader === "Stock Distribution" && !savebutton ? (
    <button
      onClick={handleMinimumPortfolioAmount}
      disabled={!isFormValid() || isLoadingSave}
      className="min-w-[180px] px-8 py-2 ml-4 bg-blue-900 hover:bg-blue-800 text-white disabled:bg-gray-400"
    >
      {isLoadingSave ? (
        <>
          <svg
            className="h-6 w-6 mr-2 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Calculating...
        </>
      ) : (
        "Calculate Min. Investment Value"
      )}
    </button>
  ) : (
    selectHeader !== "Rebalance" && (
      <button
        className="px-8 py-2 ml-4 bg-blue-600 text-white text-base font-semibold rounded-md cursor-pointer"
        onClick={() => {
          switch (selectHeader) {
            case "Meta":
              setSelectHeader("Rationale");
              break;
            case "Rationale":
              setSelectHeader("Methodology");
              break;
            case "Methodology":
              setSelectHeader("Stock Distribution");
              break;
            case "Stock Distribution":
              setSelectHeader("Rebalance");
              break;
            default:
              break;
          }
        }}
      >
        Continue
      </button>
    )
  )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNewStrategyModal;
