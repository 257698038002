import React, { useState, useEffect } from "react";
import AdminDashboardLayout from "../AdminDashboardLayout";
import AdminSettings from "./AdminSettings";
import { getAdminDetails } from "../CommonApiCall/getAdminDetails";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const apiKey = "test_api_key";
const apiSecret = "test_api_secret";

const AdminSettingsPage = () => {
  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const adminEmail = adminDetails ? adminDetails.email : null;

  const [data, setData] = useState();

  useEffect(() => {
    if (adminEmail) {
      getAdminDetails(adminEmail)
        .then((fetchedData) => {
          setData(fetchedData);
        })
        .catch((err) => console.log(err));
    }
  }, [adminEmail]);

  const userId = data && data?._id;

  return (
    <AdminDashboardLayout>
      <AdminSettings
        userId={userId}
        adminEmail={adminEmail}
        adminData={data && data}
      />
    </AdminDashboardLayout>
  );
};

export default AdminSettingsPage;
