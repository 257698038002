// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumbs_strategiescontainerBreadcrumb__yocHE {
  padding-top: 100px !important;
}

.Breadcrumbs_strategiescontainer__kxsIT,
.Breadcrumbs_strategiescontainerBreadcrumb__yocHE {
  width: 1280px;
  max-width: 1280px;
  margin: auto !important;
  padding-left: 10px;
}

.Breadcrumbs_linkStyle__cEHeH {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 30px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  padding-right: 15px !important;
}

.Breadcrumbs_lastLink__Qok5L > a {
  color: #000000;
  cursor: default;
}

.Breadcrumbs_lastLink__Qok5L > img {
  display: none !important;
}

.Breadcrumbs_lastLink__Qok5L:hover {
  color: #000000;
}

.Breadcrumbs_breadcrumbWrap__Qu5pL {
  display: flex;
  flex: 1 1;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 700px) and (min-width: 100px) {
  .Breadcrumbs_strategiescontainerBreadcrumb__yocHE {
    width: 100% !important;
    margin: auto !important;
    padding-left: 20px;
    padding-top: 100px !important;
  }

  .Breadcrumbs_strategiescontainer__kxsIT {
    padding-top: 20px;
  }

  .Breadcrumbs_strategiescontainerBreadcrumb__yocHE {
    padding-bottom: 0 !important;
    padding-top: 60px;
  }

  .Breadcrumbs_containerPadding__Af3BB {
    padding-right: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/Breadcrumbs.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;;EAEE,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,iBAAiB;EACjB,gDAAgD;EAChD,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,6BAA6B;EAC/B;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,4BAA4B;IAC5B,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".strategiescontainerBreadcrumb {\n  padding-top: 100px !important;\n}\n\n.strategiescontainer,\n.strategiescontainerBreadcrumb {\n  width: 1280px;\n  max-width: 1280px;\n  margin: auto !important;\n  padding-left: 10px;\n}\n\n.linkStyle {\n  text-transform: capitalize;\n  font-size: 14px;\n  line-height: 30px;\n  font-family: \"Poppins\", \"Work Sans\", \"Helvetica\";\n  padding-right: 15px !important;\n}\n\n.lastLink > a {\n  color: #000000;\n  cursor: default;\n}\n\n.lastLink > img {\n  display: none !important;\n}\n\n.lastLink:hover {\n  color: #000000;\n}\n\n.breadcrumbWrap {\n  display: flex;\n  flex: 1;\n  width: 100%;\n  align-items: center;\n}\n\n@media screen and (max-width: 700px) and (min-width: 100px) {\n  .strategiescontainerBreadcrumb {\n    width: 100% !important;\n    margin: auto !important;\n    padding-left: 20px;\n    padding-top: 100px !important;\n  }\n\n  .strategiescontainer {\n    padding-top: 20px;\n  }\n\n  .strategiescontainerBreadcrumb {\n    padding-bottom: 0 !important;\n    padding-top: 60px;\n  }\n\n  .containerPadding {\n    padding-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"strategiescontainerBreadcrumb": `Breadcrumbs_strategiescontainerBreadcrumb__yocHE`,
	"strategiescontainer": `Breadcrumbs_strategiescontainer__kxsIT`,
	"linkStyle": `Breadcrumbs_linkStyle__cEHeH`,
	"lastLink": `Breadcrumbs_lastLink__Qok5L`,
	"breadcrumbWrap": `Breadcrumbs_breadcrumbWrap__Qu5pL`,
	"containerPadding": `Breadcrumbs_containerPadding__Af3BB`
};
export default ___CSS_LOADER_EXPORT___;
