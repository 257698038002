import React from "react";
import TradeHistory from "./TradeHistory";
import DashboardLayout from "../RootSection/DashboardLayout";

const TradeHistoryHomePage = () => {
  return (
    <DashboardLayout>
      <TradeHistory />
    </DashboardLayout>
  );
};

export default TradeHistoryHomePage;
