import React from "react";
import EmptyState from "../../assests/empty_state.svg"

const NoTradeFailureModal = () => {
  return (
    <div className="flex flex-col items-center justify-center  h-[330px] w-[55%] bg-[#ffffff]  rounded-lg">
      <div className="w-full flex flex-row justify-center ">
        <img src={EmptyState} alt="Empty State" width={180} className="rounded-md" />
      </div>
      <h3 className="text-[22px] text-[#000000] font-sans font-semibold">
        No Trade Failure & Inconsistency
      </h3>
      <p className="text-[15px] text-center  text-[#000000]/60 font-poppins font-normal px-[80px">
        All trades processed successfully with no Trade Failures &
        Inconsistencies.
      </p>
    </div>
  );
};

export default NoTradeFailureModal;
