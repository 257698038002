import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
const termsData = [
  {
    heading: "Acceptance of Terms",
    text: (
      <>
        <p>
          1.1. By accessing or using the Service, you acknowledge that you have
          read, understood, and agree to be bound by these Terms.
        </p>
        <p>
          1.2. We may update these Terms from time to time. The revised Terms
          will be effective immediately upon posting. Your continued use of the
          Service after any such changes constitutes your acceptance of the new
          Terms.
        </p>
      </>
    ),
  },
    {
    heading: "Contact us",
    text: (
      <>
        <p>
          Registered Address:
        </p>
      </>
    ),
  },
    {
    heading: "Refunds",
    text: (
      <>
        <p>
          2.1. {whiteLabelText} is a sebi registered research analyst and this site is designed to
          enable clients to receive investment recommendations and
            facilitates them to execute these recommendations in their broker.
        </p>
        <p>
          2.2. The service is non refundable and non transferable and will comply with any local laws.
        </p>
      </>
    ),
  },
  {
    heading: "Description of Service",
    text: (
      <>
        <p>
          2.1. {whiteLabelText} is a sebi registered research analyst and this site is designed to
          enable clients to receive investment recommendations and
            facilitates them to execute these recommendations in their broker.
        </p>
        <p>
          2.2. The Service is currently in use in India. As such, it may
          contain minor issues that could affect its performance. We make no
          guarantees about the availability, reliability, or accuracy of the
          Service.
        </p>
      </>
    ),
  },
  {
    heading: "User Responsibilities",
    text: (
      <>
        <p>
          3.1. You agree to use the Service only for lawful purposes and in
          accordance with these Terms.
        </p>
        <p>
          3.2. You must be at least 18 years old or have the consent of a parent
          or guardian to use the Service.
        </p>
        <p>
          3.3. You are responsible for maintaining the confidentiality of your
          account information, including your username and password, and for all
          activities that occur under your account.
        </p>
        <p>
          3.4. You agree to notify us immediately of any unauthorized use of
          your account or any other breach of security.
        </p>
      </>
    ),
  },
  {
    heading: "Subscription and Payment",
    text: (
      <>
        <p>
          4.1. The Service is offered on a subscription basis. Subscription fees
          and payment terms will be specified at the time of purchase.
        </p>
        <p>
          4.2. All payments are due in advance and are non-refundable except as
          required by applicable law.
        </p>
        <p>
          4.3. We reserve the right to change our fees at any time. Any changes
          will be communicated to you in advance.
        </p>
      </>
    ),
  },
  {
    heading: "Limitation of Liability",
    text: (
      <>
        <p>
          5.1. To the fullest extent permitted by law, {whiteLabelText} will not be liable
          for any indirect, incidental, special, consequential, or punitive
          damages, including but not limited to loss of profits, data, or other
          intangible losses, arising out of or in connection with your use of
          the Service.
        </p>
        <p>
          5.2. Our total liability to you for any claims arising out of or in
          connection with these Terms or your use of the Service will be limited
          to the lesser of (i) the amount of monthly fees paid by you for the
          Service during the month in which the claim arose, or (ii) INR
          one thousand.
        </p>
        <p>
          5.3. We disclaim all warranties, whether express or implied, including
          but not limited to warranties of merchantability, fitness for a
          particular purpose, and non-infringement.
        </p>
      </>
    ),
  },
  {
    heading: "Indemnification",
    text: (
      <>
        <p>
          6.1. You agree to indemnify, defend, and hold harmless {whiteLabelText}, its
          affiliates, officers, directors, employees, and agents from and
          against any claims, liabilities, damages, losses, or expenses,
          including reasonable attorneys’ fees, arising out of or in connection
          with your use of the Service, your violation of these Terms, or your
          infringement of any rights of another party.
        </p>
      </>
    ),
  },
  {
    heading: "Intellectual Property",
    text: (
      <>
        <p>
          7.1. All content, trademarks, and other intellectual property
          associated with the Service are owned by {whiteLabelText} or its licensors. You
          may not use any of our intellectual property without our prior written
          consent.
        </p>
        <p>
          7.2. You retain ownership of any data you input into the Service, but
          you grant us a license to use, reproduce, and analyze such data as
          necessary to provide and improve the Service.
        </p>
      </>
    ),
  },
  {
    heading: "Privacy",
    text: (
      <>
        <p>
          8.1. Your use of the Service is also governed by our Privacy Policy,
          which describes how we collect, use, and protect your personal
          information.
        </p>
        <p>
          8.2. By using the Service, you consent to the collection and use of
          your information in accordance with our Privacy Policy.
        </p>
      </>
    ),
  },
  {
    heading: "Termination",
    text: (
      <>
        <p>
          9.1. We reserve the right to suspend or terminate your access to the
          Service at our sole discretion, with or without cause, and with or
          without notice.
        </p>
        <p>
          9.2. You may terminate your subscription at any time by following the
          instructions provided in the Service. No refunds will be issued for
          any unused portion of the subscription term.
        </p>
      </>
    ),
  },
  {
    heading: "Governing Law and Dispute Resolution",
    text: (
      <>
        <p>
          10.1. These Terms are governed by and construed in accordance with the
          laws of India.
        </p>
        <p>
          10.2. Any disputes arising out of or in connection with these Terms or
          your use of the Service will be subject to the exclusive jurisdiction
          of the courts located in Bengaluru, India.
        </p>
      </>
    ),
  },
  {
    heading: "Miscellaneous",
    text: (
      <>
        <p>
          11.1.
          <strong>Severability</strong>: If any provision of these Terms is
          found to be invalid or unenforceable, the remaining provisions will
          continue in full force and effect.
        </p>
        <p>
          11.2.
          <strong>No Waiver</strong>: Our failure to enforce any right or
          provision of these Terms will not be deemed a waiver of such right or
          provision.
        </p>
        <p>
          11.3.
          <strong>Assignment</strong>: We may assign these Terms, in whole or in
          part, at any time without notice to you. You may not assign these
          Terms without our prior written consent.
        </p>
        <p>
          11.4.
          <strong> Entire Agreement</strong>: These Terms constitute the entire
          agreement between you and us regarding your use of the Service and
          supersede all prior or contemporaneous agreements and understandings.
        </p>
        <p>
          11.5.
          <strong>Contact Us</strong>: If you have any questions about these
          Terms, please contact us at{" "}
          <a
            href={`mailto:${process.env.REACT_APP_CONTACT_ADVISOR_EMAIL}`}
            target="_blank"
            className="text-blue-500 underline"
            rel="noreferrer"
          >
            {process.env.REACT_APP_CONTACT_ADVISOR_EMAIL}
          </a>
        </p>
      </>
    ),
  },
];

const TermsAndConditionsByAlpha = () => {
  return (
    <div className="flex flex-col relative  w-full lg:min-h-screen  bg-[#f9f9f9] ">
      <div className="border-b-[1px] border-[#000000]/10 px-[20px]  sm:px-[30px] py-[14px] lg:px-20 lg:py-3 flex items-center justify-between">
        <h2 className=" font-sans text-[18px] sm:text-[24px] md:text-[28px] font-bold md:leading-[60px] text-left ">
          Terms & Conditions
        </h2>
      </div>
      <div className="mt-6 space-y-3 list-decimal list-inside px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
        <p className="font-poppins text-[16px] font-normal leading-[28px] text-left mt-2">
          Last Updated: 1 Oct 2024
        </p>
        <p className="font-poppins text-[16px] font-normal leading-[28px] text-left mt-2">
          Welcome to {whiteLabelText}, which has been licensed the right to
          limited use of the software product developed by AlphaQuark (part of
          Arpint Private Ltd) (“Arpint”, “we”, “our”, or “us”). By accessing or
          using our software and services (collectively, the “Service”), you
          agree to comply with and be bound by the following terms and
          conditions (the “Terms”).
        </p>
      </div>

      <ul className="mt-6 space-y-6 list-decimal list-inside px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
        {termsData.map((term, index) => (
          <li key={index} className="font-semibold text-[#161515CC]">
            <span className="font-sans text-[18px] font-bold leading-[40px] text-left text-[#161515CC]">
              {term.heading}
            </span>
            <p className="font-poppins text-[16px] font-normal leading-[28px] text-left mt-2">
              {term.text}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TermsAndConditionsByAlpha;
