"use client"

import { Info } from "lucide-react"
import moment from "moment"

const BespokeCard = ({ ele, handlePricingCardClick }) => {
  const onCardClick = () => {
    handlePricingCardClick(ele)
  }

  const isExpired = moment(ele?.subscription?.end_date).unix() * 1000 < new Date().getTime()

  // Helper function to determine pricing display
  const getPriceDisplay = () => {
    if (ele?.pricing?.monthly) return { price: ele.pricing.monthly, period: "/month" }
    if (ele?.pricing?.quarterly) return { price: ele.pricing.quarterly, period: "/quarter" }
    if (ele?.pricing?.halfYearly) return { price: ele.pricing.halfYearly, period: "/half-year" }
    if (ele?.pricing?.yearly) return { price: ele.pricing.yearly, period: "/year" }
    return { price: ele?.amount || "N/A", period: "" }
  }

  const { price, period } = getPriceDisplay()
  const months = ele?.duration ? Math.round(ele.duration / 30) : 0

  return (
    <div
      className="flex flex-col cursor-pointer w-full sm:w-[95%] rounded-xl bg-white border transition-all duration-300 hover:shadow-lg pb-5"
      style={{
        borderColor: ele?.subscription ? (!isExpired ? "#2E7D32" : "#BDBDBD") : "#e5e7eb",
        borderWidth: "1px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
      }}
      onClick={onCardClick}
    >
      {/* Subscription Status Banner */}
      {ele?.subscription && (
        <div
          className="py-2.5 px-4 font-medium tracking-wide rounded-t-xl text-center text-sm uppercase w-full mb-2"
          style={{
            background: !isExpired ? "#2E7D32" : "#BDBDBD",
            color: !isExpired ? "white" : "black",
          }}
        >
          <span className="font-semibold tracking-wider">{isExpired ? "SUBSCRIPTION EXPIRED" : "SUBSCRIBED"}</span>
          <span className="block font-normal text-xs mt-0.5">
            ({moment(ele.subscription.start_date).format("DD-MMM-YYYY")} to{" "}
            {moment(ele.subscription.end_date).format("DD-MMM-YYYY")})
          </span>
        </div>
      )}

      {/* Card Header with Name and Price */}
      <div className="flex flex-col px-6 pt-4 pb-2">
  <div className="mb-3 w-full">
    <h2 className="text-xl sm:text-2xl font-bold text-gray-900 capitalize flex flex-wrap items-center">
      <span className="truncate max-w-[99%]">{ele?.name}</span>
      {months > 0 && (
        <span className="ml-2 text-sm font-normal text-gray-500 bg-gray-100 px-2 py-0.5 rounded-full whitespace-nowrap">
          {months} month{months > 1 ? "s" : ""}
        </span>
      )}
    </h2>
  </div>

        {/* Price Display */}
        <div className="flex items-baseline mb-4">
          <span className="text-3xl sm:text-4xl font-bold text-gray-800 leading-none">₹{price}</span>
          {period && <span className="text-base text-gray-500 font-medium ml-1">{period}</span>}
        </div>
      </div>

      {/* Description Section */}
      <div className="flex flex-col px-6 py-2 border-t border-gray-100">
        <div className="flex items-start space-x-3">
          <Info size={18} className="text-gray-400 mt-0.5 flex-shrink-0" />
          <p className="text-sm text-gray-600 leading-relaxed">{ele?.description || "No description available"}</p>
        </div>
      </div>

      {/* Action Button */}
      <div className="px-6 mt-auto pt-4">
        <button
          onClick={(e) => {
            e.stopPropagation()
            handlePricingCardClick(ele)
          }}
          className={`w-full py-3 px-4 rounded-lg font-medium text-sm transition-all duration-200 ${
            ele?.subscription ? "bg-black hover:bg-blue-700 text-white" : "bg-[#2056DF] hover:bg-gray-800 text-white"
          }`}
          style={{ boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
        >
          {ele?.subscription ? "Renew Now" : "Subscribe Now"}
        </button>
      </div>
    </div>
  )
}

export default BespokeCard

