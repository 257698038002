import React, { useState, useEffect } from "react";
import {
  X,
  ChevronDown,
  ChevronUp,
  Phone,
  User,
  MessageCircle,
  Search,
} from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import TelegramSteps from "../RootSection/TelegramSteps";
import server from "../../utils/serverConfig";
import { CountryCode } from "../../utils/CountryCode";
import axios from "axios";
import { encryptApiKey } from "../../utils/cryptoUtils";

interface UpdateUserDetailsProps {
  uid: string;
  setUpdateUserDetails: (show: boolean) => void;
  userEmail: string;
  advisorName: string;
}

const UpdateUserDetails: React.FC<UpdateUserDetailsProps> = ({
  uid,
  setUpdateUserDetails,
  userEmail,
  advisorName,
}) => {
  const showTelegram = process.env.REACT_APP_TELEGRAM === "1";
  const [phoneNumber, setPhoneNumber] = useState("");
  const [telegramId, setTelegramId] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const calculateProfileCompletion = () => {
    let completed = 0;
    let total = showTelegram ? 4 : 3;

    if (userEmail) completed++;
    if (userName) completed++;
    if (phoneNumber) completed++;
    if (showTelegram && telegramId) completed++;

    return Math.round((completed / total) * 100);
  };

  const updateUser = async () => {
    if (
      phoneNumber.length !== 9 &&
      phoneNumber.length !== 10 &&
      phoneNumber.length !== 11
    ) {
      toast.error("Phone number must be between 9 and 11 numbers", {
        duration: 5000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "14px",
          padding: "10px 20px",
        },
      });
      return;
    }

    setLoading(true);
    try {
      const formattedPhone = phoneNumber.startsWith(countryCode)
        ? phoneNumber
        : countryCode + phoneNumber;

      const response = await axios.put(
        `${server.server.baseUrl}api/user/update-profile`,
        {
          email: userEmail,
          advisorName,
          phoneNumber: formattedPhone,
          countryCode,
          telegramId: showTelegram ? telegramId : "",
          userName,
          profileCompletion: calculateProfileCompletion(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          duration: 5000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "14px",
            padding: "10px 20px",
          },
        });
        setShowSuccessMsg(true);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showSuccessMsg) {
      const timer = setTimeout(() => {
        setShowSuccessMsg(false);
        setUpdateUserDetails(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMsg, setUpdateUserDetails]);

  const filteredCountryCodes = CountryCode.filter((ele) =>
    `${ele.value} ${ele.label}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const progressPercentage = calculateProfileCompletion();

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/75 z-50 p-4">
      <Toaster position="top-center" reverseOrder={false} />

      <div className="relative bg-white w-full max-w-4xl rounded-xl shadow-2xl overflow-hidden">
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">
              Update Profile
            </h2>
            <button
              onClick={() => setUpdateUserDetails(false)}
              className="p-2 text-gray-400 hover:text-gray-500 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-6">
          {/* Left Column - Form */}
          <div className="space-y-6">
            {/* Progress Bar */}
            <div className="space-y-2">
              <div className="flex items-center justify-between text-sm">
                <span className="font-medium text-gray-700">
                  Profile Completion
                </span>
                <span className="font-medium text-gray-900">
                  {progressPercentage}%
                </span>
              </div>
              <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                <div
                  className="h-full bg-blue-600 rounded-full transition-all duration-500"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
            </div>

            {/* Name Input */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Full Name
              </label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                />
              </div>
            </div>

            {/* Phone Number Input */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <div className="flex space-x-2">
                <div className="relative">
                  <button
                    onClick={() => setShowCountryCode(!showCountryCode)}
                    className="w-24 px-3 py-2 border border-gray-200 rounded-lg flex items-center justify-between hover:bg-gray-50 transition-colors"
                  >
                    <span className="font-medium text-gray-900">
                      {countryCode}
                    </span>
                    {showCountryCode ? (
                      <ChevronUp className="w-4 h-4 text-gray-500" />
                    ) : (
                      <ChevronDown className="w-4 h-4 text-gray-500" />
                    )}
                  </button>

                  {/* Country Code Dropdown */}
                  {showCountryCode && (
                    <div className="absolute z-10 w-64 mt-2 bg-white rounded-lg shadow-lg border border-gray-200">
                      <div className="p-2 border-b border-gray-200">
                        <div className="relative">
                          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                          <input
                            type="text"
                            placeholder="Search countries..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full pl-9 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          />
                        </div>
                      </div>
                      <div className="max-h-60 overflow-y-auto">
                        {filteredCountryCodes.map((country, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              setCountryCode(country.value);
                              setShowCountryCode(false);
                            }}
                            className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-2"
                          >
                            <span className="font-medium text-gray-900">
                              {country.value}
                            </span>
                            <span className="text-gray-500">
                              {country.label}
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="relative flex-1">
                  <Phone className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type="number"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                  />
                </div>
              </div>
            </div>

            {/* Telegram Input */}
            {showTelegram && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Telegram Username
                </label>
                <div className="relative">
                  <MessageCircle className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Enter your telegram username"
                    value={telegramId}
                    onChange={(e) => setTelegramId(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
                  />
                </div>
              </div>
            )}

            {/* Submit Button */}
            <button
              onClick={updateUser}
              disabled={
                loading || !phoneNumber || (showTelegram && !telegramId)
              }
              className="w-full py-2.5 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              {loading ? (
                <div className="flex items-center justify-center space-x-2">
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  <span>Updating...</span>
                </div>
              ) : (
                "Update Profile"
              )}
            </button>
          </div>

          {/* Right Column - Telegram Steps */}
          {showTelegram && (
            <div className="border-t lg:border-t-0 lg:border-l border-gray-200 pt-6 lg:pt-0 lg:pl-6">
              <TelegramSteps />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateUserDetails;
