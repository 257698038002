import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { XIcon } from "lucide-react";
import { io } from "socket.io-client";

import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";

import LoadingSpinner from "../../components/LoadingSpinner";
import server from "../../utils/serverConfig";

const UpdateRebalanceModal = ({
  userEmail,
  openRebalanceModal,
  setOpenRebalanceModal,
  data,
  calculatedPortfolioData,
  broker,
  apiKey,
  jwtToken,
  secretKey,
  clientCode,
  sid,
  serverId,
  viewToken,
  setOpenSucessModal,
  setOrderPlacementResponse,
  modelPortfolioModelId,
  modelPortfolioRepairTrades,
  getRebalanceRepair,
  storeModalName,
  getModelPortfolioStrategyDetails,
}) => {
  const appURL = process.env.REACT_APP_URL;
  const ccxtUrl = process.env.REACT_APP_CCXT_SERVER_WEBSOCKET_URL;
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const isDesktop = useMediaQuery("(min-width: 830px)");
  const [loading, setLoading] = useState();

  const checkValidApiAnSecret = (apiKey) => {
    const bytesKey = CryptoJS.AES.decrypt(apiKey, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const filteredData = data.filter(
    (item) => item.model_name === storeModalName
  );

  // Now, let's find the matching repair trade
  const matchingRepairTrade =
    modelPortfolioRepairTrades &&
    modelPortfolioRepairTrades?.find(
      (trade) => trade.modelId === modelPortfolioModelId
    );

  const repairStatus =
    matchingRepairTrade &&
    matchingRepairTrade.failedTrades &&
    matchingRepairTrade.failedTrades.length > 0;

  // Check if modelPortfolioRepairTrades exists and has trades
  let dataArray = [];

  if (repairStatus) {
    dataArray = matchingRepairTrade.failedTrades.map((trade) => ({
      symbol: trade.advSymbol,
      qty: parseInt(trade.advQTY, 10),
      orderType: trade.transactionType.toUpperCase(),
      exchange: trade.advExchange,
    }));
  } else if (calculatedPortfolioData && calculatedPortfolioData?.length !== 0) {
    dataArray =
      calculatedPortfolioData?.length !== 0
        ? [
            ...calculatedPortfolioData?.buy.map((item) => ({
              symbol: item.symbol,
              qty: item.quantity, // Adjust according to the API response
              orderType: "BUY",
              exchange: item.exchange, // Use directly from the API response
            })),
            ...calculatedPortfolioData?.sell.map((item) => ({
              symbol: item.symbol,
              qty: item.quantity, // Adjust according to the API response
              orderType: "SELL",
              exchange: item.exchange, // Based on your condition
            })),
          ]
        : [];
  }

  const [ltp, setLtp] = useState([]);
  const socketRef = useRef(null);
  const subscribedSymbolsRef = useRef(new Set());
  const failedSubscriptionsRef = useRef({});
  const MAX_RETRY_ATTEMPTS = 3;

  useEffect(() => {
    socketRef.current = io("wss://ccxt.alphaquark.in", {
      transports: ["websocket"],
      query: { EIO: "4" },
    });

    socketRef.current.on("market_data", (data) => {
      setLtp((prev) => {
        const index = prev.findIndex(
          (item) => item.tradingSymbol === data.stockSymbol
        );
        if (index !== -1) {
          const newLtp = [...prev];
          newLtp[index] = {
            ...newLtp[index],
            lastPrice: data.last_traded_price,
          };
          return newLtp;
        } else {
          return [
            ...prev,
            {
              tradingSymbol: data.stockSymbol,
              lastPrice: data.last_traded_price,
            },
          ];
        }
      });
    });

    return () => {
      if (socketRef.current) socketRef.current.disconnect();
    };
  }, []);

  const getCurrentPrice = useCallback(() => {
    if (!dataArray || dataArray.length === 0) return;

    const symbolsToSubscribe = dataArray.filter(
      (trade) =>
        !subscribedSymbolsRef.current.has(trade.symbol) &&
        (!failedSubscriptionsRef.current[trade.symbol] ||
          failedSubscriptionsRef.current[trade.symbol] < MAX_RETRY_ATTEMPTS)
    );

    symbolsToSubscribe.forEach((trade) => {
      const data = { symbol: trade.symbol, exchange: trade.exchange };

      axios
        .post(`${ccxtUrl}websocket/subscribe`, data)
        .then(() => {
          subscribedSymbolsRef.current.add(trade.symbol);
          delete failedSubscriptionsRef.current[trade.symbol];
        })
        .catch((error) => {
          console.error(`Error subscribing to ${trade.symbol}:`, error);
          failedSubscriptionsRef.current[trade.symbol] =
            (failedSubscriptionsRef.current[trade.symbol] || 0) + 1;
        });
    });
  }, [dataArray]);

  useEffect(() => {
    if (dataArray && dataArray.length > 0) {
      getCurrentPrice();
    }
  }, [dataArray, getCurrentPrice]);

  const getLTPForSymbol = useCallback(
    (symbol) => {
      const ltpItem = ltp.find((item) => item.tradingSymbol === symbol);
      return ltpItem ? ltpItem.lastPrice : null;
    },
    [ltp]
  );

  const totalInvestmentValue = dataArray
    .filter((item) => item.orderType === "BUY")
    .reduce((total, item) => {
      const currentPrice = getLTPForSymbol(item.symbol);
      const investment = item.qty * currentPrice;
      return total + investment;
    }, 0);

  const convertResponse = (dataArray) => {
    return dataArray.map((item) => {
      return {
        transactionType: item.orderType,
        exchange: item.exchange,
        segment: "EQUITY",
        productType: "DELIVERY",
        orderType: "MARKET",
        price: 0,
        tradingSymbol: item.symbol,
        quantity: item.qty,
        priority: 0,
        user_broker: broker,
      };
    });
  };

  const stockDetails = convertResponse(dataArray);

  const placeOrder = () => {
    setLoading(true);

    const matchingRepairTrade = Array.isArray(modelPortfolioRepairTrades)
      ? modelPortfolioRepairTrades.find(
          (trade) => trade.model_id === modelPortfolioModelId
        )
      : modelPortfolioRepairTrades?.model_id === modelPortfolioModelId
      ? modelPortfolioRepairTrades
      : null;

    const getBasePayload = () => ({
      user_broker: broker,
      user_email: userEmail,
      trades: stockDetails,
      model_id: modelPortfolioModelId,
    });

    const getBrokerSpecificPayload = () => {
      switch (broker) {
        case "IIFL Securities":
          return { clientCode };
        case "ICICI Direct":
        case "Upstox":
          return {
            apiKey: checkValidApiAnSecret(apiKey),
            secretKey: checkValidApiAnSecret(secretKey),
            [broker === "Upstox" ? "accessToken" : "sessionToken"]: jwtToken,
          };
        case "Angel One":
          return { apiKey, jwtToken };
        case "Hdfc Securities":
          return {
            apiKey: checkValidApiAnSecret(apiKey),
            accessToken: jwtToken,
          };
        case "Dhan":
          return {
            clientId: clientCode,
            accessToken: jwtToken,
          };
        case "Kotak":
          return {
            consumerKey: checkValidApiAnSecret(apiKey),
            consumerSecret: checkValidApiAnSecret(secretKey),
            accessToken: jwtToken,
            viewToken: viewToken,
            sid: sid,
            serverId: serverId,
          };
        default:
          return {};
      }
    };

    const getAdditionalPayload = () => {
      if (matchingRepairTrade) {
        return {
          modelName: matchingRepairTrade.modelName,
          advisor: matchingRepairTrade.advisorName,
          unique_id: matchingRepairTrade?.uniqueId,
        };
      } else {
        return {
          modelName: filteredData[0]["model_name"],
          advisor: filteredData[0]["advisor"],
          unique_id: calculatedPortfolioData?.uniqueId,
        };
      }
    };

    const payload = {
      ...getBasePayload(),
      ...getBrokerSpecificPayload(),
      ...getAdditionalPayload(),
    };

    const config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(payload),
    };

    axios
      .request(config)
      .then((response) => {
        setOrderPlacementResponse(response.data.results);
        getRebalanceRepair();
        const updateData = {
          modelId: modelPortfolioModelId,
          orderResults: response.data.results,
          userEmail: userEmail,
          modelName: filteredData[0]["model_name"],
        };

        return axios.post(
          `${server.server.baseUrl}api/model-portfolio-db-update`,
          updateData
        );
      })
      .then(() => {
        setLoading(false);
        setOpenSucessModal(true);
        setOpenRebalanceModal(false);
        getModelPortfolioStrategyDetails();
      })
      .catch((error) => {
        console.error("Error in placeOrder:", error);
        setLoading(false);
        // Consider adding error handling here, e.g., showing an error modal
      });
  };

  const handleClose = () => {
    getModelPortfolioStrategyDetails();
    setOpenRebalanceModal(false);
  };

  const getAdditionalPayload = () => {
    if (matchingRepairTrade) {
      return {
        modelName: matchingRepairTrade.modelName,
        advisor: matchingRepairTrade.advisorName,
        unique_id: matchingRepairTrade?.uniqueId,
        model_id: modelPortfolioModelId,
        broker: broker,
      };
    } else {
      return {
        modelName: filteredData[0]["model_name"],
        advisor: filteredData[0]["advisor"],
        unique_id: calculatedPortfolioData?.uniqueId,
        model_id: modelPortfolioModelId,
        broker: broker,
      };
    }
  };
  const additionalPayload = getAdditionalPayload();
  const handleZerodhaRedirect = async () => {
    setLoading(true);
    localStorage.setItem(
      "additionalPayload",
      JSON.stringify(additionalPayload)
    );

    const apiKey = zerodhaApiKey;
    const basket = stockDetails.map((stock) => {
      let baseOrder = {
        variety: "regular",
        tradingsymbol: stock.tradingSymbol,
        exchange: stock.exchange,
        transaction_type: stock.transactionType,
        order_type: stock.orderType,
        quantity: stock.quantity,
        readonly: false,
      };

      // Get the LTP for the current stock
      const ltp = getLTPForSymbol(stock.tradingSymbol);

      // If LTP is available and not '-', use it as the price
      if (ltp !== "-") {
        baseOrder.price = parseFloat(ltp);
      }

      // If it's a LIMIT order, use the LTP as the price
      if (stock.orderType === "LIMIT") {
        if (ltp !== "-") {
          baseOrder.price = parseFloat(ltp);
          baseOrder.variety = "regular";
        } else {
          baseOrder.variety = "regular";
          baseOrder.price = stock.limitPrice || 0; // Use limitPrice if available, or set to 0
        }
      }

      if (stock.quantity > 100) {
        baseOrder.readonly = true;
      }

      return baseOrder;
    });

    const form = document.createElement("form");
    form.method = "POST";

    form.action = `https://kite.zerodha.com/connect/basket`;

    // form.target = "_blank";

    const apiKeyInput = document.createElement("input");
    apiKeyInput.type = "hidden";
    apiKeyInput.name = "api_key";
    apiKeyInput.value = apiKey;

    const dataInput = document.createElement("input");
    dataInput.type = "hidden";
    dataInput.name = "data";
    dataInput.value = JSON.stringify(basket);

    const redirectParams = document.createElement("input");
    redirectParams.type = "hidden";
    redirectParams.name = "redirect_params";
    redirectParams.value = `${appURL},rebalance=true`;

    form.appendChild(apiKeyInput);
    form.appendChild(dataInput);
    form.appendChild(redirectParams);

    document.body.appendChild(form);

    const currentISTDateTime = new Date();
    try {
      // Update the database with the current IST date-time
      await axios
        .post(
          `${server.server.baseUrl}api/zerodha/model-portfolio/update-reco-with-zerodha-model-pf`,
          {
            stockDetails: stockDetails,
            leaving_datetime: currentISTDateTime,
            email: userEmail,
            trade_given_by: "demoadvisor@alphaquark.in",
          }
        )
        .then((res) => {
          const allStockDetails = res?.data?.data;
          const filteredStockDetails = allStockDetails.map((detail) => ({
            user_email: detail.user_email,
            trade_given_by: detail.trade_given_by,
            tradingSymbol: detail.Symbol,
            transactionType: detail.Type,
            exchange: detail.Exchange,
            segment: detail.Segment,
            productType: detail.ProductType,
            orderType: detail.OrderType,
            price: detail.Price,
            quantity: detail.Quantity,
            priority: detail.Priority,
            tradeId: detail.tradeId,
            user_broker: "Zerodha", // Manually adding this field
          }));

          setLoading(false);
          localStorage.setItem(
            "stockDetailsZerodhaOrder",
            JSON.stringify(filteredStockDetails)
          );
        })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
        });
      // Submit the form after the database is updated
      form.submit();
      setLoading(false);
    } catch (error) {
      console.error("Failed to update trade recommendation:", error);
      setLoading(false);
    }
  };

  // if (isDesktop) {
  return (
    <Dialog open={openRebalanceModal} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[90vw] md:max-w-[70vw] lg:max-w-[50vw] xl:max-w-[35vw] w-full p-0">
        <div className="flex flex-col w-full rounded-lg bg-white">
          <div className="px-4 sm:px-6 py-4 flex flex-col space-y-3 shadow-md">
            <div className="text-[22px] text-black font-bold leading-[40px] font-sans">
              {storeModalName} Rebalance
            </div>
          </div>
          <div className="">
            <div className=" w-full border-t-[1px]   border-[#000000]/10 h-[380px] overflow-auto custom-scroll">
              {dataArray?.length !== 0 ? (
                <table className={`w-full  h-[80vh] sm:h-auto`}>
                  <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                    <tr className="border-b-[1px]   border-[#000000]/10">
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                        Stocks
                      </th>

                      <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Current Price (₹)
                      </th>

                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Quantity
                      </th>
                      {/* <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Order Type
                        </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {dataArray?.map((item, i) => {
                      const currentLTP = getLTPForSymbol(item.symbol);

                      return (
                        <tr
                          className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                          key={i}
                        >
                          <td className="text-[14px] sm:text-sm  text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 ">
                            <div className="flex flex-col items-start">
                              <span>{item.symbol}</span>

                              <span
                                className={`ml-1 ${
                                  item?.orderType?.toLowerCase() === "buy"
                                    ? "text-[#16A085] text-[14px] font-poppins font-semibold text-center  capitalize"
                                    : item?.orderType?.toLowerCase() === "sell"
                                    ? "text-[#EA2D3F] text-[14px] font-poppins font-semibold text-center  capitalize"
                                    : "text-[#000000]/80 text-[14px] font-poppins font-semibold text-center capitalize"
                                }`}
                              >
                                {item.orderType?.toLowerCase()}
                              </span>
                            </div>
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-normal text-center py-3 px-5 ">
                            ₹ {currentLTP}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 ">
                            {item.qty}
                          </td>
                          {/* <td
                              className={
                                item?.orderType?.toLowerCase() === "buy"
                                  ? "text-[#16A085] text-[14px] font-poppins font-semibold text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                  : item?.orderType?.toLowerCase() === "sell"
                                  ? "text-[#EA2D3F] text-[14px] font-poppins font-semibold text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                  : "text-[#000000]/80 text-[15px] font-poppins font-semibold text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                              }
                            >
                              {item.orderType?.toLowerCase()}
                            </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="pt-12 space-y-4">
                  <div className="w-full flex flex-row justify-center text-xl font-semibold text-center items-center">
                    Something Went Wrong
                  </div>
                  <div className="w-full flex flex-row justify-center text-base text-[#000000]/50 font-semibold text-center items-center">
                    We ran into an issue with your broker. Please try again
                    later in sometime.
                  </div>
                </div>
              )}
            </div>
          </div>
          {dataArray?.length !== 0 ? (
            <DialogFooter className="flex flex-row sm:flex-row sm:justify-between px-4 sm:px-6 py-4 border-t border-gray-200">
              <div className="flex flex-col items-start mb-2 sm:mb-0">
                <div className=" leading-[22px] font-poppins text-sm font-medium text-gray-700">
                  Total Amount Required :
                </div>
                <div className="text-2xl font-poppins font-semibold text-gray-900 ">
                  ₹ {totalInvestmentValue.toFixed(2)}
                </div>
              </div>
              {broker === "Zerodha" ? (
                <button
                  className="w-40 h-12 flex items-center justify-center bg-black text-white text-base font-medium rounded-md font-poppins"
                  onClick={handleZerodhaRedirect}
                >
                  {loading === true ? (
                    <LoadingSpinner />
                  ) : (
                    <span className="text-[18px] font-medium text-[#ffffff] font-poppins ">
                      Place Order
                    </span>
                  )}
                </button>
              ) : (
                <button
                  className="w-36 h-10 ml-auto sm:ml-0 flex items-center justify-center bg-black text-white text-base font-medium rounded-md font-poppins"
                  onClick={placeOrder}
                >
                  {loading === true ? (
                    <LoadingSpinner />
                  ) : (
                    <span className="text-[15px]">Place Order</span>
                  )}
                </button>
              )}
            </DialogFooter>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
};
//   return (
//     <Drawer open={openRebalanceModal} onOpenChange={setOpenRebalanceModal}>
//       <DrawerContent className="focus:outline-none max-h-[550px] md:max-h-[690px]">
//         <div
//           onClick={() => {
//             setOpenRebalanceModal(false);
//           }}
//           className="absolute top-4 right-3 flex items-center justify-center "
//         >
//           <XIcon className="h-5 w-5 text-gray-900" />
//         </div>
//         <div className="pt-4 h-full ">
//           <div className="px-6 flex flex-row justify-between items-center ">
//             <div className="text-xl text-black font-semibold leading-[40px] font-sans">
//               {storeModalName} Rebalance
//             </div>
//           </div>
//           {dataArray?.length !== 0 ? (
//             <div className=" w-full h-[290px]  border-t-[1px] border-[#000000]/10 mt-2 overflow-auto custom-scroll">
//               {dataArray.map((ele, i) => {
//                 const currentLTP = getLTPForSymbol(ele.symbol);

//                 return (
//                   <div
//                     className="flex items-center  border-b-[1px] border-[#000000]/10  py-4 px-4 bg-white font-poppins"
//                     key={i}
//                   >
//                     <div className="flex flex-1 items-start flex-col space-y-[6px] overflow-hidden">
//                       <div className="text-[14px] truncate w-full   text-[#000000]/80 font-poppins font-semibold">
//                         {ele.symbol}
//                       </div>
//                       <div
//                         className={`${
//                           ele.orderType?.toLowerCase() === "buy"
//                             ? "bg-[#16A085]/10 text-[#16A085] "
//                             : "bg-[#EA2D3F]/10 text-[#EA2D3F]"
//                         } flex items-center px-2 py-[1px] rounded-[4px]`}
//                       >
//                         <span className=" text-[14px] lg:text-[12px]  px-1 py-0  font-poppins font-medium capitalize">
//                           {ele.orderType?.toLowerCase()}
//                         </span>
//                       </div>
//                     </div>

//                     <div className="flex items-center justify-between pr-4 text-[14px] text-[#000000] font-poppins font-medium">
//                       ₹ {currentLTP}
//                     </div>

//                     <div className="flex pl-4 flex-col items-end space-y-[6px]">
//                       <div className="text-[14px]  text-[#000000] font-poppins font-medium">
//                         {ele.qty}
//                       </div>
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>
//           ) : (
//             <div className="w-full h-[290px] pt-12 space-y-4 px-4">
//               <div className="w-full flex flex-row justify-center text-xl font-semibold text-center items-center">
//                 Something Went Wrong
//               </div>
//               <div className="w-full flex flex-row justify-center text-base text-[#000000]/50 font-semibold text-center items-center">
//                 We ran into an issue with your broker. Please try again later in
//                 sometime.
//               </div>
//             </div>
//           )}
//         </div>
//         {dataArray?.length !== 0 ? (
//           <DrawerFooter className="pt-2 flex flex-col  w-full shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
//             <div className="flex  items-center justify-between space-x-4">
//               <div className="flex flex-col items-start ">
//                 <div className=" min-w-[110px] text-[10px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[24px] font-sans">
//                   Total Amount Required :
//                 </div>
//                 <div className="py-1 text-[18px] text-[#000000]/80 font-semibold   rounded-[4px]">
//                   ₹ {totalInvestmentValue.toFixed(2)}
//                 </div>
//               </div>

//               <button
//                 className=" w-full px-8 py-3 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
//                 onClick={placeOrder}
//               >
//                 {loading === true ? (
//                   <LoadingSpinner />
//                 ) : (
//                   <span className="text-[18px] font-semibold text-[#ffffff] font-poopins ">
//                     Place Order
//                   </span>
//                 )}
//               </button>
//             </div>
//           </DrawerFooter>
//         ) : null}
//       </DrawerContent>
//     </Drawer>
//   );
// };

export default UpdateRebalanceModal;
