import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import moment from "moment";
import FormatDateTime from "../../utils/formatDateTime";
import AddWebinarModal from "./AddWebinarModal";
import server from "../../utils/serverConfig";
import { calculateGSTComponents } from "../PlanSection/GstCalculator";
import PlanCard from "../PlanSection/PlanCard";
import { getAllPlanGroupsForAdvisor } from "../../services/PlanGroupService";
import { encryptApiKey } from "../../utils/cryptoUtils";

const style = {
  selected:
    "flex items-center text-[18px]  font-sans text-black font-bold lg:leading-[42px] cursor-pointer",
  unselected:
    "flex items-center text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;

const WebinarPage = ({ adminEmail, userId }) => {
  const [allWebinarList, setAllWebinarList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getAllWebinarList = () => {
    if (advisorTag) {
      Promise.all([
        axios.get(
          `${server.server.baseUrl}api/admin/plan/${adminEmail}/advisor`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        ),
        getAllPlanGroupsForAdvisor(adminEmail),
      ])
        .then((values) => {
          const plansMap = values[0].data.data.reduce((map, obj) => {
            if (obj.type === "webinar") {
              map[obj._id] = obj;
            }
            return map;
          }, {});

          const planGroups = values[1].data.data;

          for (const planGroup of planGroups) {
            for (const planId of planGroup.plans) {
              plansMap[planId]["planGroup"] = planGroup.groupId;
            }
          }

          setAllWebinarList(
            Object.values(plansMap).sort((p1, p2) =>
              p1.planGroup?.localeCompare(p2.planGroup)
            )
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);

          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    getAllWebinarList();
  }, [advisorTag]);

  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [webinarModal, setWebinarModal] = useState(false);
  const [webinarName, setWebinarName] = useState("");
  const [webinarLink, setWebinarLink] = useState("");
  const [webinarDescription, setWebinarDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [amountWithOffer, setAmountWithOffer] = useState("");
  const [amountWithGST, setAmountWithGST] = useState(null);
  const [webinarDate, setWebinarDate] = useState("");
  const [time, setTime] = useState({ startTime: "", endTime: "" });
  const [gstDetails, setGstDetails] = useState({
    totalAmount: 0,
  });

  const isValidData = () => {
    return (
      webinarName.trim() !== "" && // Webinar Name must not be empty
      webinarLink.trim() !== "" &&
      time.startTime.trim() !== "" && // Start Time must not be empty
      time.endTime.trim() !== "" && // End Time must not be empty
      amount > 0 && // Amount must be greater than 0
      webinarDate.trim() !== "" // Webinar Date must not be empty
    );
  };

  const handleAddGroupName = async () => {
    const groupData = {
      userId,
      creationDate: FormatDateTime(new Date()),
      groupName: webinarName?.toLowerCase()?.replace(/ /g, "_"),
      groupType: "webinar",
      advisor: process.env.REACT_APP_ADVISOR_SPECIFIC_TAG,
    };

    try {
      const response = await fetch(`${server.server.baseUrl}api/add-group`, {
        method: "POST",
        body: JSON.stringify(groupData),
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    if (amount && amountWithOffer) {
      setAmountWithGST(
        calculateGSTComponents(parseFloat(amountWithOffer)).totalAmount.toFixed(
          2
        )
      );
    } else if (amount) {
      setAmountWithGST(
        calculateGSTComponents(parseFloat(amount)).totalAmount.toFixed(2)
      );
    }
  }, [amount, amountWithOffer]);

  const handleSubmit = () => {
    // First, validate the form before submitting
    if (!isValidData()) {
      toast.error("Please fill out all required fields correctly.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      return;
    }
    setLoading(true);

    const startDateTime = moment(
      `${webinarDate} ${time.startTime}`,
      "YYYY-MM-DD HH:mm"
    ).toISOString();
    const formDataObj = {
      userId: userId,
      name: webinarName,
      description: webinarDescription,
      type: "webinar",
      start_date: startDateTime,
      time: time,
      email: adminEmail,
      advisor: advisorTag,
      amount:
        process.env.REACT_APP_AdVISOR_GST_CONFIGURE === "true"
          ? amountWithGST
          : amountWithOffer
          ? amountWithOffer
          : amount,
      amountWithoutGst: amount,
      webinarRemainder: selected,
      amountWithOffer: amountWithOffer ? amount : "",
    };

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/admin/plan/new/single-payment`,
      data: formDataObj,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then(async (response) => {
        // getAllModalPortfolioList();
        setLoading(false);

        await handleAddGroupName();
        toast.success(`${webinarName} has been created successfully `, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });

        getAllWebinarList();

        setTimeout(() => {
          setWebinarModal(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in creating webinar plan!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const handlePlanView = (plan) => {
    // navigate(`/admin/admin-plan/${plan._id}`);
    // console.log("Viewing plan:", plan);
  };

  const handlePlanEdit = (plan) => {
    // setSelected(plan);
    // setOpenPlanModal(true);
  };

  console.log("selected", selected);
  return (
    <div className="min-h-screen w-full items-center justify-center bg-[#F9F9F9] ">
      <Toaster position="top-center" reverseOrder={true} />
      <div className="py-4 px-4 flex flex-row  justify-between border-b border-[#000000]/20 h-auto lg:h-[80px] font-poppins">
        <div className="flex flex-row space-x-6 lg:space-x-8 py-2 ">
          <div
            className={`
              ${style.selected} flex items-center justify-center text-[18px] md:text-[22px] lg:px-10`}
          >
            Webinar List
          </div>
        </div>

        {allWebinarList.length !== 0 ? (
          <div
            className={`
              text-[15px] md:text-lg font-semibold  px-6 text-white bg-black flex items-center justify-center rounded-md cursor-pointer`}
            onClick={() => setWebinarModal(true)}
          >
            Create Webinar
          </div>
        ) : null}
      </div>
      <div className="flex-grow overflow-auto pb-4  md:px-4 lg:px-[50px]">
        {allWebinarList.length !== 0 ? (
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pl-1  lg:pt-6 pb-10"
            >
              {allWebinarList.length !== 0 &&
                allWebinarList
                  ?.filter((ele) => ele?.name !== "priorRecommendationPlan")
                  ?.map((ele, i) => {
                    return (
                      <PlanCard
                        key={i}
                        plan={ele}
                        bespokePlans={allWebinarList.filter(
                          (plan) => plan.type === "webinar"
                        )}
                        planGroups=""
                        onView={handlePlanView}
                        onEdit={handlePlanEdit}
                      />
                    );
                  })}
            </motion.div>
          </AnimatePresence>
        ) : (
          <div className="flex flex-row justify-center min-h-screen">
            {isLoading ? (
              <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
                <svg
                  className="h-10 w-10 text-[#000000] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              <AnimatePresence mode="wait">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex flex-col items-center justify-center space-y-6 w-full relative  bg-[#f9f9f9]"
                >
                  <div
                    className="text-lg bg-black text-white px-24 py-3 rounded-md font-bold cursor-pointer"
                    onClick={() => setWebinarModal(true)}
                  >
                    Create Webinar
                  </div>
                </motion.div>
              </AnimatePresence>
            )}
          </div>
        )}
      </div>

      {webinarModal === true && (
        <AddWebinarModal
          isValidData={isValidData}
          setWebinarModal={setWebinarModal}
          webinarName={webinarName}
          setWebinarName={setWebinarName}
          amount={amount}
          setAmount={setAmount}
          webinarDate={webinarDate}
          setWebinarDate={setWebinarDate}
          webinarLink={webinarLink}
          setWebinarLink={setWebinarLink}
          webinarDescription={webinarDescription}
          setWebinarDescription={setWebinarDescription}
          amountWithGST={amountWithGST}
          setAmountWithGST={setAmountWithGST}
          setGstDetails={setGstDetails}
          gstDetails={gstDetails}
          loading={loading}
          handlePlanModal={handleSubmit}
          time={time}
          setTime={setTime}
          amountWithOffer={amountWithOffer}
          setAmountWithOffer={setAmountWithOffer}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </div>
  );
};

export default WebinarPage;
