import React from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Toaster } from "react-hot-toast";
import { ScanEyeIcon } from "lucide-react";
import GroupCard from "./GroupCard";
import FormatDateTime from "../../utils/formatDateTime";
import moment from "moment";

const GroupListBlock = ({
  userId,
  allClientsData = [],
  allGroupsData = [],
  adminEmail,
  advisorName,
  email_password,
  adminRationale,
  advisorSpecifier,
}) => {
  const csvHeaders = [
    { label: "client_id", key: "client_id" },
    { label: "client_name", key: "client_name" },
    { label: "email", key: "email" },
    { label: "phone", key: "phone" },
    { label: "groups", key: "groups" },
    { label: "location", key: "location" },
    { label: "telegram", key: "telegram" },
    { label: "pan", key: "pan" },
    { label: "comments", key: "comments" },
    { label: "subscription_id", key: "subscription_id" },
    { label: "start_date", key: "start_date" },
    { label: "plan", key: "plan" },
    { label: "capital", key: "capital" },
    { label: "charges", key: "charges" },
    { label: "invoice", key: "invoice" },
    { label: "expiry", key: "expiry" },
    { label: "acc_creation_date", key: "acc_creation_date" },
    { label: "updated_at", key: "updated_at" },
  ];

  const flattenClientsData = (clients = []) => {
    if (!Array.isArray(clients) || clients.length === 0) return [];

    return clients.flatMap((client) => {
      if (!client || !Array.isArray(client.subscriptions)) {
        return [{
          client_id: client?.clientId || '',
          client_name: client?.clientName || '',
          email: client?.email || '',
          phone: client?.phone || '',
          groups: client?.groups || [],
          location: client?.location || '',
          telegram: client?.telegram || '',
          pan: client?.pan || '',
          comments: client?.comments || '',
          subscription_id: '',
          start_date: '',
          plan: '',
          capital: '',
          charges: '',
          invoice: '',
          expiry: '',
          acc_creation_date: client?.creationDate ? FormatDateTime(client.creationDate) : '',
          updated_at: client?.updatedAt ? FormatDateTime(client.updatedAt) : ''
        }];
      }

      if (client.subscriptions.length === 0) {
        return [{
          client_id: client.clientId,
          client_name: client.clientName,
          email: client.email,
          phone: client.phone,
          groups: client.groups || [],
          location: client.location,
          telegram: client.telegram,
          pan: client.pan,
          comments: client.comments,
          subscription_id: '',
          start_date: '',
          plan: '',
          capital: '',
          charges: '',
          invoice: '',
          expiry: '',
          acc_creation_date: FormatDateTime(client.creationDate),
          updated_at: FormatDateTime(client.updatedAt)
        }];
      }

      return client.subscriptions.map((subscription) => ({
        client_id: client.clientId,
        client_name: client.clientName,
        email: client.email,
        phone: client.phone,
        groups: client.groups || [],
        location: client.location,
        telegram: client.telegram,
        pan: client.pan,
        comments: client.comments,
        subscription_id: subscription?.subId || '',
        start_date: subscription?.startDate || '',
        plan: subscription?.plan || '',
        capital: subscription?.capital || '',
        charges: subscription?.charges || '',
        invoice: subscription?.invoice || '',
        expiry: subscription?.expiry || '',
        acc_creation_date: FormatDateTime(client.creationDate),
        updated_at: FormatDateTime(client.updatedAt)
      }));
    });
  };

  const combinedClientDataOfAllGroups = flattenClientsData(allClientsData);

  return (
    <div className="flex flex-col h-[330px] w-full lg:w-[45%] bg-[#ffffff] rounded-lg">
      <Toaster position="top-center" reverseOrder={true} />
      <div className="w-full flex justify-between items-center px-6 h-[50px] rounded-t-lg">
        <div className="text-[#000000] text-[18px] font-sans font-bold">
          Send Advice To Groups
        </div>
        <div className="flex space-x-2.5">
          <Link
            to="/admin/send-recommendation-status"
            className="group relative px-1 flex bg-black text-white rounded-[4px] justify-center items-center cursor-pointer"
          >
            <ScanEyeIcon strokeWidth={2} className="h-5 w-5" />
            <div className="absolute hidden group-hover:flex min-w-[120px] -left-[45px] -top-[30px] items-center justify-center px-2 py-1 rounded-[4px] text-[10px] font-medium bg-black text-white">
              All Sent Advices
              <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-black rotate-45"></div>
            </div>
          </Link>

          <CSVLink
            data={combinedClientDataOfAllGroups}
            headers={csvHeaders}
            filename={`combined_client_list_${moment().format("YYYYMMDD_HHmmss")}.csv`}
            className="group relative px-1 flex text-white rounded-[4px] justify-center items-center cursor-pointer"
          >
            <div className="w-6 h-6 bg-green-600 rounded flex items-center justify-center">
              <span className="text-xs font-bold">CSV</span>
            </div>
            <div className="absolute hidden group-hover:flex min-w-[220px] -left-[95px] -top-[30px] items-center justify-center px-2 py-1 rounded-[4px] text-[10px] font-medium bg-black text-white">
              Download Combined Client List CSV
              <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-black rotate-45"></div>
            </div>
          </CSVLink>
        </div>
      </div>

      <div className="w-full flex flex-col">
        <div className="w-full grid grid-cols-2 items-center px-6 h-[50px] border-b-[1px] border-[#000000]/10">
          <div className="text-[#000000]/60 text-[14px] font-poppins font-medium">
            List Name
          </div>
          <div className="text-center text-[#000000]/60 text-[14px] font-poppins font-medium">
            Actions
          </div>
        </div>
        <div className="flex flex-col w-full h-[calc(330px-110px)]">
          <div className="flex flex-col w-full overflow-auto custom-scroll">
            {allGroupsData?.map((ele, i) => (
              <GroupCard
                key={ele?._id}
                csvHeaders={csvHeaders}
                groupName={ele.groupName}
                allClientDataByGroup={allClientsData.filter((item) =>
                  (item.groups || []).includes(ele.groupName)
                )}
                adminEmail={adminEmail}
                advisorName={advisorName}
                userId={userId}
                email_password={email_password}
                adminRationale={adminRationale}
                advisorSpecifier={advisorSpecifier}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupListBlock;