import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import HeaderSection from "./HeaderSection";
import PortfolioSection from "./PortfolioSection";
import SubscriptionSection from "./SubscriptionSection";
import AboutSection from "./AboutSection";
import ProcessSection from "./ProcessSection";
import FaqSection from "./FaqSection";
import Footer from "./Footer";

import toast, { Toaster } from "react-hot-toast";
import { Loader2 } from "lucide-react";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import server from "../utils/serverConfig";
import { auth } from "../firebase";
import FormatDateTime from "../utils/formatDateTime";
import PlanSubscribeModal from "./PlanSubscribeModal";
import PaymentSuccessModal from "./PaymentSuccessModal";
import BrokerSection from "./BrokerSection";

const LandingPage = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const [allPricingPlan, setAllPricingPlan] = useState([]);
  const [allPortfolioPlan, setAllPortfolioPlan] = useState([]);
  const [planDescription, setPlanDescription] = useState("");

  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const razorPayKey = process.env.REACT_APP_RAZORPAY_LIVE_API_KEY;
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const appUrl = process.env.REACT_APP_URL;

  const getAllPortfolioPlans = () => {
    if (userEmail) {
      let config = {
        method: "get",
        url: `${server.server.baseUrl}api/admin/plan/${advisorTag}/model portfolio/${userEmail}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setAllPortfolioPlan(response.data);
          if (response.data && response.data.length > 0) {
            setPlanDescription(response.data[0].description || "");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let config = {
        method: "get",
        url: `${server.server.baseUrl}api/all-plans/specific-advisor/plan/model portfolio/${advisorTag}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setAllPortfolioPlan(response.data);
          if (response.data && response.data.length > 0) {
            setPlanDescription(response.data[0].description || "");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getAllPlans = () => {
    if (userEmail) {
      let config = {
        method: "get",
        url: `${server.server.baseUrl}api/admin/plan/${advisorTag}/bespoke/${userEmail}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setAllPricingPlan(response.data);
          if (response.data && response.data.length > 0) {
            setPlanDescription(response.data[0].description || "");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let config = {
        method: "get",
        url: `${server.server.baseUrl}api/all-plans/specific-advisor/plan/bespoke/${advisorTag}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setAllPricingPlan(response.data);
          if (response.data && response.data.length > 0) {
            setPlanDescription(response.data[0].description || "");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getAllPortfolioPlans();
    getAllPlans();
  }, []);

  const [paymentModal, setPaymentModal] = useState(false);
  const [specificPlan, setSpecificPlan] = useState();

  const handlePricingCardClick = (modelName) => {
    setPaymentModal(true);
    setSpecificPlan(modelName);
    setPlanDescription(modelName.description || "");
  };

  const [strategyDetails, setStrategyDetails] = useState(null);

  const getStrategyDetails = () => {
    if (specificPlan) {
      axios
        .get(
          `${server.server.baseUrl}api/admin/plan/landing/subscription/detail/specific/${specificPlan?._id}`
        )
        .then((res) => {
          setStrategyDetails(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (specificPlan) {
      getStrategyDetails();
    }
  }, [specificPlan]);

  const [selectedCard, setSelectedCard] = useState(null);
  const [invetAmount, setInvestAmount] = useState();
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [panNumber, setPanNumber] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [birthDate, setBirthDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPostPaymentProcessing, setIsPostPaymentProcessing] = useState(false);
  const formattedName = specificPlan?.name
    ? specificPlan.name.includes(" ") // Check if there are spaces
      ? specificPlan.name.toLowerCase().replace(/\s+/g, "_") // If spaces, replace them
      : specificPlan.name.toLowerCase() // If no spaces, just lowercase
    : "";

  const handleCardClick = (item) => {
    setSelectedCard(item); // Set the selected card
    // Call your subscribe function
  };

  const handleOk = () => {
    let sip_amount;
    if (strategyDetails?.frequency?.length !== 0) {
      subscribeToPlan(selectedCard, sip_amount);
    } else {
      handleSinglePayment(strategyDetails?.amount);
    }
  };

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  async function subscribeToPlan(frequency, sip_amount) {
    try {
      setLoading(true);
      // Fetch subscription details from the backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription`,
        {
          plan_id: specificPlan?._id,
          frequency,
          user_email: userEmail,
          panNumber: panNumber,
          mobileNumber: mobileNumber,
          name: name,

          countryCode: countryCode,
          sip_amount,
        }
      );
      setLoading(false);
      setPaymentModal(false);
      const subscriptionData = response.data.data;

      // console.log(subscriptionData, "subscriptionData");

      if (subscriptionData.razorpay_subscription_id) {
        // Initialize Razorpay with the subscription details
        const options = {
          key: razorPayKey, // Your Razorpay Key ID
          subscription_id: subscriptionData.razorpay_subscription_id, // The subscription ID from Razorpay
          name: subscriptionData.plan_id.name, // Plan or product name
          description: subscriptionData.razorpay_subscription_id, // Description of the plan
          amount: subscriptionData.amount, // Amount in smallest unit (paise for INR)
          currency: "INR", // Currency (e.g., INR)
          handler: function (response) {
            // This will execute after successful payment
            // console.log("Razorpay Payment ID:", response.razorpay_payment_id);
            // console.log(
            //   "Razorpay Subscription ID:",
            //   response.razorpay_subscription_id
            // );
            // console.log("Razorpay Signature:", response.razorpay_signature);

            // Now, you can call the backend to save the subscription status and other details
            setIsPostPaymentProcessing(true);
            completeSubscription(response);
          },
          modal: {
            ondismiss: function () {
              alert("Payment was not completed. Please try again.");
            },
          },
          prefill: {
            name: "", // User's name
            email: userEmail, // User's email
          },
          theme: {
            color: "#F37254",
          },
        };

        // Initialize and open the Razorpay Checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching subscription data");
        setLoading(true);
      }
    } catch (error) {
      console.error("Error subscribing to plan:", error);
      setLoading(true);
    }
  }

  const logPayment = async (type, data) => {
    try {
      await axios.post(`${server.server.baseUrl}api/log-payment`, {
        type,
        data,
      });
    } catch (error) {
      console.error("Failed to log payment:", error);
    }
  };
  // Function to handle the backend call after successful payment
  async function completeSubscription(paymentDetails) {
    try {
      // Send payment details to the backend to finalize the subscription
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/complete-payment`,
        paymentDetails
      );

      const data = response.data;
      setIsPostPaymentProcessing(false);
      await logPayment("SUBSCRIPTION_PAYMENT_SUCCESS", {
        subscriptionId: data.subscription.razorpay_subscription_id,
        amount: specificPlan?.amount,
        clientName: name,
        email: userEmail || email,
        plan: formattedName,
        planType: specificPlan?.frequency,
        duration:
          specificPlan?.frequency === "monthly"
            ? "30"
            : specificPlan?.frequency === "quarterly"
            ? "90"
            : "365",
      });
      setPaymentSuccess(true);

      // Send notifications
      try {
        await sendNotifications({
          email: userEmail || email,
          phoneNumber: mobileNumber,
          countryCode: countryCode || "+91",
          planDetails: {
            isRenewal: false,
            duration:
              specificPlan?.frequency === "monthly"
                ? "30"
                : specificPlan?.frequency === "quarterly"
                ? "90"
                : "365",
            name: specificPlan?.name,
            amount: specificPlan?.amount,
          },
          userName: name,
          advisorName: whiteLabelText,
          tradingPlatform: "supported-broker", // Add this parameter
        });
      } catch (notificationError) {
        console.error("Notifications failed:", notificationError);
      }

      const newSubscription = {
        subId: uuidv4().slice(0, 10),
        startDate: FormatDateTime(new Date()),
        plan: formattedName || "", // Assuming the response contains a plan
        capital: data.subscription.capital || 0, // Assuming the response contains capital
        charges: data.subscription.amount || 0, // Assuming the response contains charges
        invoice: data.subscription.razorpay_subscription_id || "", // Assuming the response contains invoice
        expiry: FormatDateTime(new Date(data.expiry)), // Assuming the response contains expiry date
      };
      const newClientData = {
        clientId: uuidv4().slice(0, 7), // Generate a new client ID
        clientName: data.subscription.name || "", // Assuming the response contains a client name
        country_code: countryCode || "+91", // Here too it uses state or defaults to "+91"
        email: data.subscription.user_email || "", // Assuming the response contains an email
        phone: data.subscription.mobileNumber || "", // Assuming the response contains a phone number
        groups: [`All Client`, formattedName], // Add formatted name dynamically
        location: data.location || "", // Assuming the response contains a location
        telegram: data.telegram || "", // Assuming the response contains a Telegram ID
        pan: data.pan || "", // Assuming the response contains a PAN number
        creationDate: FormatDateTime(new Date()), // Current date
        comments: data.comments || "", // Assuming the response contains comments
        subscriptions: [
          {
            ...newSubscription, // Attach the new subscription here
          },
        ],
      };

      try {
        // Send a POST request to add the new client
        const response = await fetch(
          `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: specificPlan?.adminId,
              DateofBirth: birthDate || "",
              advisorName: advisorTag,
              clientData: newClientData,
            }),
          }
        );

        const result = await response.json();
        setInvestAmount();
        setEmail(null);
        await logPayment("SUBSCRIPTION_CLIENT_ADDED", {
          clientId: newClientData.clientId,
          clientName: newClientData.clientName,
          plan: formattedName,
          subscriptionId: newSubscription.subId,
          subscriptionDetails: {
            startDate: newSubscription.startDate,
            expiry: newSubscription.expiry,
            amount: newSubscription.charges,
          },
        });
      } catch (error) {
        console.error("Error adding client:", error);
        await logPayment("SUBSCRIPTION_CLIENT_ADD_ERROR", {
          error: error.message,
          clientName: data.subscription.name,
          email: data.subscription.user_email,
        });
      }
    } catch (error) {
      console.error("Error completing subscription:", error);
      await logPayment("SUBSCRIPTION_PAYMENT_FAILURE", {
        error: error.message,
        clientName: name,
        email: userEmail || email,
        amount: specificPlan?.amount,
        plan: formattedName,
        planType: specificPlan?.frequency,
      });
    }
  }

  async function handleSinglePayment(amount) {
    await logPayment("PAYMENT_INITIATED", {
      amount: specificPlan?.amount,
      clientName: name,
      email: email,
      plan: formattedName,
      phoneNumber: mobileNumber,
      panNumber: panNumber,
      countryCode: countryCode || "+91",
    });
    try {
      setLoading(true);
      // Fetch one-time payment details from the backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription`,
        {
          plan_id: specificPlan?._id,
          user_email: email,
          panNumber: panNumber,
          mobileNumber: mobileNumber,
          name: name,
          countryCode: countryCode,
          amount,
        }
      );
      setLoading(false);
      setPaymentModal(false);
      const paymentData = response.data.data;

      if (paymentData.razorpay_order_id) {
        // Initialize Razorpay with the order details
        const options = {
          key: razorPayKey,
          order_id: paymentData.razorpay_order_id,
          name: paymentData.plan_id.name,
          description: paymentData.razorpay_order_id,
          amount: paymentData.amount,
          currency: "INR",
          handler: async function (response) {
            await logPayment("COMPLETE_PAYMENT_INITIATED", {
              amount: specificPlan?.amount,
              clientName: name,
              email: email,
              plan: formattedName,
              phoneNumber: mobileNumber,
              panNumber: panNumber,
              countryCode: countryCode || "+91",
            });
            setIsPostPaymentProcessing(true);
            await completeSinglePayment(response);
          },
          modal: {
            ondismiss: async function () {
              toast.error("Payment was not completed. Please try again.", {
                duration: 3000,
                style: {
                  background: "white",
                  color: "#1e293b",
                  maxWidth: "500px",
                  fontWeight: 600,
                  fontSize: "13px",
                  padding: "10px 20px",
                },
                iconTheme: {
                  primary: "#e43d3d",
                  secondary: "#FFFAEE",
                },
              });
              await logPayment("PAYMENT_NOT_COMPLETED", {
                amount: specificPlan?.amount,
                clientName: name,
                email: email,
                plan: formattedName,
                phoneNumber: mobileNumber,
                panNumber: panNumber,
                countryCode: countryCode || "+91",
              });
            },
          },
          prefill: {
            name: "",
            email: email,
          },
          theme: {
            color: "#F37254",
          },
        };

        // Initialize and open the Razorpay Checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching one-time payment data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating one-time payment:", error);
      setLoading(false);
    }
  }

  // First, add these notification functions at the top of the file after the imports
  // Email notification function using the correct API
  const sendEmailNotification = async (
    email,
    planDetails,
    userName,
    advisorName,
    tradingPlatform,
    data
  ) => {
    try {
      const latestPayment = data.subscription.payment_history.sort(
        (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
      )[0];
      const emailData = [
        {
          template_name: "new_plan",
          template_body_values: [
            userName || email,
            `${
              latestPayment.payment_type === "extension" &&
              latestPayment.previous_end_date
                ? latestPayment.previous_end_date
                : latestPayment.payment_date
            }`,
            `${latestPayment.new_end_date}`,
          ],
          trade_given_by: advisorName,
          recipient_email: email,
          plan_name: planDetails.name,
        },
      ];

      const emailResponse = await axios.post(
        `${server.ccxtServer.baseUrl}comms/email/send-template-messages/supported-broker`,
        emailData
      );

      if (emailResponse.data.status !== 0) {
        console.error("Email sending failed:", emailResponse.data);
      }

      return emailResponse;
    } catch (error) {
      console.error("Email notification error:", {
        message: error.message,
        response: error.response?.data,
        statusCode: error.response?.status,
        email: email,
      });
      // Don't throw error to prevent disrupting the main flow
    }
  };

  // Example usage in your WhatsApp notification function
  const sendWhatsAppNotification = async (
    phoneNumber,
    countryCode,
    userName,
    email,
    advisorName,
    data
  ) => {
    const latestPayment = data.subscription.payment_history.sort(
      (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
    )[0];

    try {
      const formattedPhone = phoneNumber.toString().replace(/\D/g, "");
      let formattedCountryCode = countryCode.startsWith("+")
        ? countryCode
        : `+${countryCode}`;

      const getAdvisorCodomain = (advisor) => {
        if (advisor === "AlphaQuark") return "prod";
        if (advisor === "AlphaQuarkTest") return "test";
        return advisor.toLowerCase();
      };

      if (latestPayment?.payment_type === "extension") {
        const templateResponse = await axios.post(
          `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
          {
            phone_number: formattedPhone,
            template_name: "new_plan",
            template_body_values: [
              userName || email,
              latestPayment?.payment_type === "extension"
                ? "renewed"
                : "subscribed",
              `${
                latestPayment?.payment_type === "extension" &&
                latestPayment?.previous_end_date
                  ? latestPayment?.previous_end_date
                  : latestPayment?.payment_date
              }`,
              `${latestPayment.new_end_date}`,
              advisorName,
            ],
            template_button_values: [getAdvisorCodomain(advisorName)],
            template_header_values: [
              latestPayment?.payment_type === "extension"
                ? "Renewed"
                : "Subscribed",
            ],
            country_code: countryCode,
            callback_data: "Standard Callback",
            language_code: "en",
          }
        );
      } else {
        const trackUserResponse = await axios.post(
          `${server.ccxtServer.baseUrl}comms/whatsapp/track-user`,
          {
            phone_number: formattedPhone,
            country_code: formattedCountryCode,
            user_traits: {
              name: userName || email,
              email: email,
              advisor: advisorName,
              advisor_codomain: getAdvisorCodomain(advisorName),
              whatsapp_opted_in: true,
            },
            tags: [advisorName, "All tags"],
          }
        );

        if (trackUserResponse.data.result.result === true) {
          console.log("Sending WhatsApp template message...");
          const templateResponse = await axios.post(
            `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
            {
              phone_number: formattedPhone,
              template_name: "new_plan",
              template_body_values: [
                userName || email,
                latestPayment.payment_type === "extension"
                  ? "renewed"
                  : "subscribed",
                `${
                  latestPayment.payment_type === "extension" &&
                  latestPayment.previous_end_date
                    ? latestPayment.previous_end_date
                    : latestPayment.payment_date
                }`,
                `${latestPayment.new_end_date}`,
                advisorName,
              ],
              template_button_values: [getAdvisorCodomain(advisorName)],
              template_header_values: [
                latestPayment.payment_type === "extension"
                  ? "Renewed"
                  : "Subscribed",
              ],
              country_code: countryCode,
              callback_data: "Standard Callback",
              language_code: "en",
            }
          );
        }
      }
    } catch (error) {
      console.error("WhatsApp notification error:", {
        message: error.message,
        response: error.response?.data,
        statusCode: error.response?.status,
        phoneNumber,
        countryCode,
      });
    }
  };

  const sendNotifications = async (notificationData) => {
    const {
      email,
      phoneNumber,
      countryCode,
      planDetails,
      userName,
      advisorName,
      tradingPlatform,
      data,
    } = notificationData;

    try {
      // Send email first
      await sendEmailNotification(
        email,
        planDetails,
        userName,
        advisorName,
        tradingPlatform,
        data
      );

      // Then send WhatsApp notification
      await sendWhatsAppNotification(
        phoneNumber,
        countryCode,
        planDetails,
        userName,
        email,
        advisorName,
        data
      );
    } catch (error) {
      console.error("Notification error:", error);
    }
  };

  async function completeSinglePayment(paymentDetails) {
    try {
      // Send payment details to backend to finalize payment
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription/complete-one-time-payment`,
        {
          ...paymentDetails,
        }
      );

      const data = response.data;
      setIsPostPaymentProcessing(false);

      // Log successful payment first
      await logPayment("PAYMENT_SUCCESS", {
        orderId: data.subscription.razorpay_order_id,
        amount: specificPlan?.amount,
        clientName: name,
        email: email,
        plan: formattedName,
        phoneNumber: mobileNumber,
        panNumber: panNumber,
        countryCode: countryCode || "+91",
      });
      setPaymentSuccess(true);

      // Create subscription and client data objects
      const newSubscription = {
        subId: uuidv4().slice(0, 10),
        startDate: FormatDateTime(new Date()),
        plan: formattedName || "",
        capital: invetAmount || 0,
        charges: specificPlan?.amount || 0,
        invoice: data.subscription.razorpay_order_id || "",
        expiry: FormatDateTime(new Date(data.subscription.end_date)),
        userDetails: {
          name: name,
          phoneNumber: mobileNumber,
          panNumber: panNumber,
          countryCode: countryCode || "+91",
        },
      };

      const newClientData = {
        clientId: uuidv4().slice(0, 7),
        clientName: name || "",
        email: email || "",
        phone: mobileNumber || "",
        groups: [`All Client`, formattedName],
        location: data.location || "",
        telegram: data.telegram || "",
        pan: panNumber || "",
        country_code: countryCode || "+91",
        creationDate: FormatDateTime(new Date()),
        comments: data.comments || "",
        subscriptions: [
          {
            ...newSubscription,
          },
        ],
      };

      // Since payment succeeded, try both client addition and notifications independently
      const sendClientAndNotifications = async () => {
        let clientAdded = false;
        let notificationsSent = false;

        // Try to add client
        try {
          const clientResponse = await fetch(
            `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: specificPlan?.adminId,
                DateofBirth: birthDate || "",
                advisorName: advisorTag,
                clientData: newClientData,
              }),
            }
          );

          const result = await clientResponse.json();
          await logPayment("CLIENT_ADDED", {
            clientId: newClientData.clientId,
            clientName: name,
            plan: formattedName,
            subscriptionDetails: newSubscription,
          });
          clientAdded = true;
        } catch (clientError) {
          console.error("Error adding client:", clientError);
          await logPayment("CLIENT_ADD_FAILURE", {
            error: clientError.message,
            clientName: name,
            email: email,
          });
          // Don't throw error - continue to notifications
        }

        // Try to send notifications regardless of client addition status
        try {
          await sendNotifications({
            email: email,
            phoneNumber: mobileNumber,
            countryCode: countryCode || "+91",
            planDetails: {
              isRenewal: false,
              duration: specificPlan?.duration || "30",
              name: specificPlan?.name,
              amount: specificPlan?.amount,
            },
            userName: name,
            advisorName: whiteLabelText,
            tradingPlatform: "supported-broker",
            data: data,
          });
          notificationsSent = true;
        } catch (notificationError) {
          console.error("Notification sending failed:", notificationError);
          await logPayment("NOTIFICATION_FAILURE", {
            error: notificationError.message,
            clientName: name,
            email: email,
            type: "notification",
          });
          // Don't throw error
        }

        // Log final status
        await logPayment("PROCESS_COMPLETION_STATUS", {
          paymentSuccess: true,
          clientAdded,
          notificationsSent,
          clientName: name,
          email: email,
        });

        // Clean up form state regardless of outcomes
        setName(null);
        setEmail(null);
        setPanNumber(null);
        setMobileNumber(null);

        return { clientAdded, notificationsSent };
      };

      // Execute both processes
      const results = await sendClientAndNotifications();

      // If both failed, might want to show a warning to the user
      if (!results.clientAdded && !results.notificationsSent) {
        console.warn(
          "Payment successful but both client addition and notifications failed"
        );
        // Could add user feedback here if needed
      }
    } catch (error) {
      // This catch block only handles payment failures
      await logPayment("PAYMENT_FAILURE", {
        error: error.message,
        clientName: name,
        email: email,
        amount: specificPlan?.amount,
      });
      console.error("Error completing one-time payment:", error);
      throw error;
    }
  }
  const [countryCode, setCountryCode] = useState("+91");

  return (
    <div>
      <Navbar />
      <main className="w-full max-w-full overflow-x-hidden mt-12 md:mt-16 ">
        <HeaderSection id="headerSection" />
        <PortfolioSection
          id="modelPortfolio"
          allPricingPlan={allPortfolioPlan}
          handlePricingCardClick={handlePricingCardClick}
          planDescription={planDescription}
        />

        <SubscriptionSection
          id="subscriptionSection"
          allPricingPlan={allPricingPlan}
          handlePricingCardClick={handlePricingCardClick}
          planDescription={planDescription}
        />
        {/*<BrokerSection/>*/}

        <AboutSection id="aboutSection" />
        <ProcessSection id="processSection" />
        <FaqSection id="faqSection" />
      </main>
      <Footer />
      {paymentModal === true ? (
        <PlanSubscribeModal
          userEmail={email}
          strategyDetails={strategyDetails}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          handleCardClick={handleCardClick}
          selectedCard={selectedCard}
          handleOk={handleOk}
          loading={loading}
          invetAmount={invetAmount}
          setInvestAmount={setInvestAmount}
          setEmail={setEmail}
          name={name}
          setName={setName}
          panNumber={panNumber}
          setPanNumber={setPanNumber}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          birthDate={birthDate}
          setBirthDate={setBirthDate}
          setCountryCode={setCountryCode}
          countryCode={countryCode}
        />
      ) : null}
      {isPostPaymentProcessing && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl flex flex-col items-center space-y-4">
            <Loader2 className="h-20 w-20 text-blue-500 animate-spin" />

            <p className="text-sm text-gray-500">
              Please wait while we confirm your subscription...
            </p>
          </div>
        </div>
      )}
      {paymentSuccess === true ? (
        <PaymentSuccessModal
          specificPlanDetails={specificPlan}
          setPaymentSuccess={setPaymentSuccess}
          setSelectedCard={setSelectedCard}
        />
      ) : null}
    </div>
  );
};

export default LandingPage;
