import { useRef } from "react";

const BillDeskPayment = ({ value, rValue }) => {
  const formRef = useRef(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit(); // Manually submit the form on button click
    }
  };

  return (
    <div className="flex flex-row justify-center mt-16">
      <form
        ref={formRef}
        name="sdklaunch"
        action="https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk"
        method="POST"
      >
        <input type="hidden" name="bdorderid" value={value} />
        <input type="hidden" name="merchantid" value="BDUAT2K478" />
        <input type="hidden" name="rdata" value={rValue} />
        <button
          type="button"
          onClick={handleSubmit}
          className="w-full py-3 px-4 bg-[#2056DF] text-white text-sm font-semibold rounded-lg hover:bg-[#2056DF] transition-all duration-300 ease-in-out hover:scale-105"
        >
          Complete your Payment
        </button>
      </form>
    </div>
  );
};

export default BillDeskPayment;
