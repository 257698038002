import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import CheckedIcon from "../../assests/checked.svg";
import Cross from "../../assests/cross.png";
import { Pencil, X } from "lucide-react";
import axios from "axios";
import server from "../../utils/serverConfig";
import { Toaster } from "react-hot-toast";
import moment from "moment";
import ConnectBroker from "../LivePortfolioSection/connectBroker";
import { motion, AnimatePresence } from "framer-motion";
import { encryptApiKey } from "../../utils/cryptoUtils";

const style = {
  mainDiv: "flex items-center space-x-5 lg:space-x-10 w-full",
  disabledInputBox:
    "w-[60%] text-[15px] flex flex-row justify-between items-center  font-medium text-[#000000] py-4  border-b-[1px] border-[#000000]/10",
  leftEntitykey:
    "w-[40%] text-[16px]  font-normal text-[#000000] py-4 lg:px-6",

  firstHeading: "text-sm text-gray-900 text-left font-normal",
  inputBox:
    "w-full px-6 py-2.5  text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-normal peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
  mainDivMobile: "flex items-end w-full py-3",
  leftEntitykeyMobile:
    "flex items-baseline w-[40%] text-[14px]  font-normal text-[#000000] py-2 px-3",
  disabledInputBoxMobile:
    "flex flex-row justify-between items-center w-[60%] text-[16px]  font-medium text-[#000000] py-2 px-6 border-b-[1px] border-[#000000]/10",

  selectBroker:
    "flex items-center px-2 py-2  rounded-lg mx-3 md-3 text-black-500  hover:bg-black/10 hover:text-black-500  ease-linear duration-150 cursor-pointer",
};

const Subscription = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState(null);
  const getUserDetails = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      })
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const [broker, setBroker] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [my2pin, setMy2Pin] = useState("");

  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
      setClientCode(userDetails.clientCode);
      setMy2Pin(userDetails.my2Pin || "");
    }
  }, [userDetails]);

  const [brokerStatus, setBrokerStatus] = useState(
    userDetails ? userDetails.connect_broker_status : null
  );
  useEffect(() => {
    if (userDetails && userDetails.user_broker !== undefined) {
      setBrokerStatus(userDetails && userDetails.connect_broker_status);
    }
  }, [userDetails, brokerStatus]);

  const [brokerModel, setBrokerModel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const [showMenu, setShowMenu] = useState(false);
  const [showEditMenu, setshowEditMenu] = useState(false);

  const handleSkipClick = () => {
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const handleEditMenu = () => {
    setshowEditMenu(true);
  };

  const handleCloseEditMenu = () => {
    setshowEditMenu(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="flex flex-col relative  w-full lg:min-h-screen  bg-[#ffffff] ">
          <Toaster position="top-center" reverseOrder={true} />
          {/* <div className="text-xl font-bold ">Subscription</div> */}
          <div className="h-full w-full   lg:w-[600px] mx-auto ">
            <AnimatePresence mode="wait">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="flex flex-col justify-center space-y-8 h-full px-4 lg:px-0"
              >
                {brokerStatus === "connected" ? (
                  <div className="flex flex-col justify-center items-center space-y-6">
                    <img
                      src={CheckedIcon}
                      alt="Check Icon"
                      width={60}
                      className="rounded-2xl"
                    />
                    <div className="flex flex-col items-center justify-center">
                      <h3 className="text-[#000000]/80 text-[24px] font-semibold">
                        Broker connected
                      </h3>
                      <span className="text-center text-[#000000]/60  text-[16px]  font-medium">
                        Your broker has been successfully connected, no action
                        due now. Wait for advisor to send investment
                        recommendation (see advice section). If you have not
                        subscribed to a plan yet, please go to pricing section
                        or connect with your advisor
                      </span>
                    </div>
                  </div>
                ) : brokerStatus === null ? (
                  <div className="flex flex-col justify-center items-center space-y-6">
                    <img
                      src={Cross}
                      alt="Cross Icon"
                      width={60}
                      className="rounded-2xl"
                    />
                    <div className="flex flex-col items-center justify-center">
                      <h3 className="text-[#000000]/80 text-[24px] font-semibold font-sans">
                        Broker not connected
                      </h3>
                      <span className="text-center text-[#000000]/60  text-[16px]  font-normal">
                        Connect your broker by clicking on the link below
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="py-4 text-3xl font-semibold font-sans flex items-center justify-center  h-[60px]">
                    <svg
                      className="h-10 w-10 text-[#000000] animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                )}

                {brokerStatus === "connected" ? (
                  <div className="relative flex  bg-[#f5f5f5]/70 rounded-[10px] lg:rounded-[20px] w-full min-h-[200px] z-0 overflow-hidden px-[14px] lg:px-[40px] py-[10px]">
                    {/* <div className="absolute left-0 top-0 w-[40%] bg-[#000000]/5  h-[400px] -z-10"></div> */}

                    <div className=" flex flex-col items-center justify-center w-full bg-transparent">
                      {/* <div className="absolute w-[40%] bg-[#F5F5F5B2] h-[400px]"></div> */}
                      {/* <div className="absolute left-0 w-[40%] bg-[#f8f8f8] h-[400px] z-10 rounded-tl-[26px] rounded-bl-[26px]"></div> */}

                      <div className={style.mainDiv}>
                        <span className={`${style.leftEntitykey} text-sm font-medium text-gray-900> `} >
                          Created Date :
                        </span>

                        <div className={style.disabledInputBox}>
                          {moment(userDetails?.created_at).format(
                            "Do MMM YYYY"
                          )}
                        </div>
                      </div>

                      <div className={style.mainDiv}>
                        <span className={`${style.leftEntitykey} text-sm font-medium text-gray-900`}>Broker :</span>

                        <div className={style.disabledInputBox}>
                          <input
                            placeholder="Broker"
                            type="text"
                            value={broker}
                            disabled
                            className="w-full bg-transparent "
                          />
                          <Pencil
                            className="cursor-pointer h-5 w-5"
                            onClick={() => {
                              setBrokerModel(true);
                              setBroker("");
                            }}
                          />
                        </div>
                      </div>
                      {userDetails &&
                        userDetails.user_broker === "IIFL Securities" && (
                          <div className={style.mainDiv}>
                            <span className={style.leftEntitykey}>
                              Client Code :
                            </span>
                            <div className={style.disabledInputBox}>
                              <input
                                placeholder="Client Code"
                                type="text"
                                value={clientCode}
                                disabled
                                className="mt-2 w-full rounded-md "
                              />
                            </div>
                          </div>
                        )}
                      {userDetails &&
                        userDetails.user_broker === "IIFL Securities" && (
                          <div className={style.mainDiv}>
                            <span className={style.leftEntitykey}>
                              My2Pin :
                            </span>
                            <div className={style.disabledInputBox}>
                              <input
                                placeholder="My2Pin"
                                type="number"
                                value={my2pin}
                                disabled
                                className="mt-2 w-full rounded-md "
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-full">
                    <button
                      onClick={() => setBrokerModel(true)}
                      className=" py-2 px-6 bg-black text-[18px] lg:text-lg  text-white font-medium rounded-md cursor-pointer"
                    >
                      Connect Broker
                    </button>
                  </div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>

          {brokerModel === true ? (
            <ConnectBroker
              uid={userDetails && userDetails._id}
              userDetails={userDetails && userDetails}
              setBrokerStatus={setBrokerStatus}
              setBrokerModel={setBrokerModel}
              setBroker={setBroker}
              broker={broker}
              brokerModel={brokerModel}
              getUserDetails={getUserDetails}
            />
          ) : null}
          {showMenu && (
            <div className="fixed inset-0 flex items-center justify-center p-4 sm:p-0 ">
              <div className="w-full max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden ">
                <div className="p-4 sm:p-6 flex flex-col items-center">
                  <div className="mb-4">
                    {/* <img
                src={''}
                width={100}
                height={100}
                className="w-16 h-16 sm:w-24 sm:h-24 object-cover"
              /> */}
                  </div>
                  <div className="flex flex-row items-end">
                    <button
                      onClick={handleCloseMenu}
                      className="pl-96 mt-[-10px] text-gray-500 hover:text-gray-700"
                      aria-label="Close"
                    >
                      <X className="h-6 w-6" />
                    </button>
                  </div>
                  <p className="text-center text-base sm:text-lg font-semibold mb-4 px-2">
                    Are you sure you want to skip 24 Sep&apos;24 installment?
                  </p>

                  <div className="w-full bg-gray-100 p-2 rounded-md mb-4">
                    <p className="text-xs sm:text-sm text-center text-gray-600">
                      Your next due date will be 24 Oct&apos;24
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 w-full">
                    <button className="w-full sm:flex-1 py-3 rounded-md font-semibold text-gray-500 border border-gray-300 bg-transparent hover:bg-gray-100">
                      Keep investing
                    </button>
                    <button className="w-full sm:flex-1 py-3 rounded-md font-semibold bg-black text-white">
                      Skip anyway
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Subscription;
