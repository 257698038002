import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import {
  Download,
  FileText,
  ChevronLeft,
  ChevronRight,
  Eye,
  X,
} from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import DashboardLayout from "../RootSection/DashboardLayout";
import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import { encryptApiKey } from "../../utils/cryptoUtils";

const style = {};
const PreviousOrders = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [previousOrders, setPreviousOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 6;

  const [pdfUrl, setPdfUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const getPreviousOrders = () => {
    setLoading(true);
    axios
      // .get(`${server.ccxtServer.baseUrl}comms/get-invoices/2015ravimishra@gmail.com`)
      .get(`${server.ccxtServer.baseUrl}comms/get-invoices/${userEmail}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      })
      .then((response) => {
        setPreviousOrders(response.data.invoices || []);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPreviousOrders();
  }, [userEmail]);

  // console.log("previousOrders", previousOrders);

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = previousOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const totalPages = Math.max(
    1,
    Math.ceil(previousOrders.length / ordersPerPage)
  );

  const handleDownload = (order) => {
    const linkSource = `data:application/pdf;base64,${order.pdf_bytes}`;
    const downloadLink = document.createElement("a");
    const fileName = `invoice_${order.invoice_data.invoice_number}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  // const handleView = (order) => {
  //   if (!order.pdf_bytes) {
  //     console.error("PDF data is missing.");
  //     return;
  //   }

  //   try {
  //     // Decode base64 string into a binary array
  //     const binaryString = atob(order.pdf_bytes);
  //     const binaryLength = binaryString.length;
  //     const bytes = new Uint8Array(binaryLength);

  //     for (let i = 0; i < binaryLength; i++) {
  //       bytes[i] = binaryString.charCodeAt(i);
  //     }

  //     // Create a Blob from the binary data
  //     const blob = new Blob([bytes], { type: 'application/pdf' });

  //     // Generate a URL for the Blob
  //     const blobUrl = URL.createObjectURL(blob);

  //     // Open the Blob URL in a new tab
  //     window.open(blobUrl, '_blank');
  //   } catch (error) {
  //     console.error("Error rendering PDF:", error);
  //   }
  // };

  const handleView = (order) => {
    if (!order.pdf_bytes) {
      console.error("PDF data is missing.");
      return;
    }

    try {
      const binaryString = atob(order.pdf_bytes);
      const binaryLength = binaryString.length;
      const bytes = new Uint8Array(binaryLength);

      for (let i = 0; i < binaryLength; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);

      if (window.innerWidth < 768) {
        // For mobile, open in a new tab
        window.open(blobUrl, "_blank");
      } else {
        // For desktop, show in modal
        setPdfUrl(blobUrl);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error rendering PDF:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setPdfUrl(null);
  };

  return (
    <DashboardLayout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-8 text-[#18181B]">My Invoices</h1>

        {/* Desktop View */}
        <div className="hidden md:block">
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            {loading === true ? (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 w-[15%] text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {loading && <Skeleton />}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 w-[25%] text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {loading && <Skeleton />}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 w-[25%] text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {loading && <Skeleton />}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 w-[30%] text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {loading && <Skeleton />}
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 w-[5%] text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {loading && <Skeleton />}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      {loading && <Skeleton />}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      {loading && <Skeleton />}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      {loading && <Skeleton />}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      {loading && <Skeleton />}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      {loading && <Skeleton />}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : previousOrders.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 w-[15%] text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Plan Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 w-[25%] text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 w-[25%] text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date of Purchase
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 w-[30%] text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Invoice download
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 w-[5%] text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentOrders.map((order, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <FileText className="w-5 h-5 mr-3 text-[#18181B]" />
                          <span className="font-medium text-[#18181B] ">
                            {order.invoice_data.item_description}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <span className="text-base text-[#18181B]  ">
                          ₹{order.invoice_data.item_amount}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <span className="text-base text-[#18181B] ">
                          {order.invoice_data.invoice_date}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        <div className="flex justify-center space-x-2">
                          <button
                            onClick={() => handleDownload(order)}
                            className="inline-flex items-center justify-center px-4 py-2 bg-[#2056DF] text-white rounded-md hover:bg-gray-800 transition-colors duration-150"
                          >
                            <Download className="w-4 h-4 mr-2" />
                            <span>Download</span>
                          </button>

                          {/* <button 
                            onClick={() => handleView(order)}
                            className="inline-flex items-center justify-center px-4 py-2  text-gray-700"
                          >
                            <Eye />
                    
                          </button> */}
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        <button
                          onClick={() => handleView(order)}
                          className="inline-flex items-center justify-center px-4 py-2  text-gray-700"
                        >
                          <Eye />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex flex-col items-center justify-center text-center p-8 h-[400px]">
                <FileText className="w-16 h-16 mx-auto text-gray-400 mb-4" />
                <p className="text-2xl text-gray-600 mb-2  font-semibold">
                  No previous orders found
                </p>
                <p className="text-gray-600  font-semibold">
                  Your invoice history will appear here once you make a
                  purchase.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Mobile View */}
        <div className="md:hidden space-y-4">
          {currentOrders.map((order, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-4">
              <div className="flex items-center mb-4">
                <FileText className="w-5 h-5 mr-3 text-[#18181B]" />
                <span className="text-lg font-medium text-[#18181B]">
                  {order.invoice_data.item_description}
                </span>
              </div>
              <div className="space-y-2 mb-4">
                <p className="text-[#18181B] text-base font-normal ">
                  <span className="text-base  font-medium">
                    Date of Purchase:{" "}
                  </span>
                  {order.invoice_data.invoice_date}
                </p>
                <p className="text-[#18181B] text-base font-normal ">
                  <span className="text-base  font-medium">
                    Price:{" "}
                  </span>
                  ₹{order.invoice_data.item_amount}
                </p>
              </div>
              <div className="flex ">
                <button
                  onClick={() => handleDownload(order)}
                  className="w-full flex items-center justify-center px-4 py-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors duration-150"
                >
                  <Download className="w-4 h-4 mr-2" />
                  <span className="">Download</span>
                </button>
                <button
                  onClick={() => handleView(order)}
                  className="inline-flex items-center justify-center px-4 py-2  text-gray-700"
                >
                  <Eye />
                </button>
              </div>
            </div>
          ))}
          {previousOrders.length === 0 && (
            <div className="flex flex-col items-center justify-center text-center bg-gray-50 rounded-xl shadow-sm p-8">
              <FileText className="w-16 h-16 mx-auto text-gray-400 mb-4" />
              <p className="text-2xl text-gray-600 mb-2  font-semibold">
                No previous orders found
              </p>
              <p className="text-gray-600  font-semibold">
                Your invoice history will appear here once you make a purchase.
              </p>
            </div>
          )}
        </div>

        {/* Pagination */}
        {previousOrders.length > 0 && (
          <div className="flex justify-center items-center mt-8">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="mr-2 p-2 rounded-full bg-gray-200 text-gray-600 disabled:opacity-50"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <span className="mx-4 ">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="ml-2 p-2 rounded-full bg-gray-200 text-gray-600 disabled:opacity-50"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
        )}

        {showModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white w-full max-w-5xl h-[90%] rounded-lg shadow-lg flex flex-col">
              <div className="flex justify-between items-center p-4 border-b">
                <h2 className="text-xl font-bold">Invoice Preview</h2>
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              <div className="flex-grow">
                <iframe
                  src={pdfUrl}
                  className="w-full h-full"
                  title="Invoice PDF"
                ></iframe>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default PreviousOrders;
