import axios from "axios";
import CryptoJS from "crypto-js";

import server from "../utils/serverConfig";
import { encryptApiKey } from "../utils/cryptoUtils";

const checkValidApiAnSecret = (details) => {
  try {
    const bytesKey = CryptoJS.AES.decrypt(details, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8); // Convert to UTF-8 string

    if (Key) {
      return Key;
    } else {
      throw new Error("Decryption failed or invalid key.");
    }
  } catch (error) {
    console.error("Error during decryption:", error.message);
    return null;
  }
};

export const fetchFunds = async (
  broker,
  clientCode,
  apiKey,
  jwtToken,
  secretKey,
  sid,
  serverId
) => {
  let data, url;
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const angelOneApiKey = process.env.REACT_APP_ANGEL_ONE_API_KEY;
  switch (broker) {
    case "IIFL Securities":
      if (!clientCode) return;
      data = JSON.stringify({ accessToken: jwtToken });
      url = `${server.ccxtServer.baseUrl}iifl/funds`;
      break;
    case "ICICI Direct":
      if (!apiKey || !jwtToken || !secretKey) return;
      data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        accessToken: jwtToken,
        secretKey: checkValidApiAnSecret(secretKey),
      });
      url = `${server.ccxtServer.baseUrl}icici/funds`;
      break;
    case "Upstox":
      if (!apiKey || !jwtToken || !secretKey) return;
      data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        accessToken: jwtToken,
        apiSecret: checkValidApiAnSecret(secretKey),
      });
      url = `${server.ccxtServer.baseUrl}upstox/funds`;
      break;
    case "Angel One":
      if (!jwtToken) return;
      data = JSON.stringify({
        apiKey: angelOneApiKey,
        accessToken: jwtToken,
      });
      url = `${server.ccxtServer.baseUrl}angelone/funds`;
      break;
    case "Zerodha":
      if (!jwtToken) return;
      data = JSON.stringify({
        apiKey: zerodhaApiKey,
        accessToken: jwtToken,
      });
      url = `${server.ccxtServer.baseUrl}zerodha/funds`;
      break;
    case "Hdfc Securities":
      if (!apiKey || !jwtToken) return;
      data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        accessToken: jwtToken,
      });
      url = `${server.ccxtServer.baseUrl}hdfc/funds`;
      break;
    case "Kotak":
      if (!jwtToken || !apiKey || !secretKey || !sid) return;
      data = JSON.stringify({
        consumerKey: checkValidApiAnSecret(apiKey),
        consumerSecret: checkValidApiAnSecret(secretKey),
        accessToken: jwtToken,
        sid,
        serverId: serverId ? serverId : "",
      });
      url = `${server.ccxtServer.baseUrl}kotak/funds`;
      break;
    case "Dhan":
      if (!clientCode || !jwtToken) return;
      data = JSON.stringify({
        clientId: clientCode,
        accessToken: jwtToken,
      });
      url = `${server.ccxtServer.baseUrl}dhan/funds`;
      break;
    case "AliceBlue":
      if (!clientCode || !jwtToken || !apiKey) return;
      data = JSON.stringify({
        clientId: clientCode,
        apiKey: apiKey,
        accessToken: jwtToken,
      });
      url = `${server.ccxtServer.baseUrl}aliceblue/funds`;
      break;
    case "Fyers":
      if (!jwtToken) return;
      data = JSON.stringify({
        clientId: clientCode,
        accessToken: jwtToken,
      });
      url = `${server.ccxtServer.baseUrl}fyers/funds`;
      break;
    default:
      return; // If the broker is not recognized
  }

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    });

    return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};
