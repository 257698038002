export const TelegramData = [
  {
    link: "https://www.youtube.com/watch?v=S-F8R3ord3k",
    videoId: "S-F8R3ord3k",
    linkContain: "Steps to Get Telegram App ID:",
    step1: "1. Log in to your Telegram account via Desktop or Mobile App.",
    step2: `2. Click on the menu icon located at the top left corner and select "Settings."`,
    step3: `3. In the "My Account" menu, click on "t.me/username."`,
    step4: `4. Fill in username of your choosing, that is available.`,
    step5: `5. Click on "Save" to set your username.`,
    step6: `6. Provide this username in your telegram ID section on this page and save.`,
  },
];

export const IciciData = [
  {
    link: "https://www.youtube.com/watch?v=S-F8R3ord3k",
    videoId: "S-F8R3ord3k",
    linkContain: "Steps to Get Telegram App ID:",
    step1: "1. Log in to your Telegram account via Desktop or Mobile App.",
    step2: `2. Click on the menu icon located at the top left corner and select "Settings."`,
    step3: `3. In the "My Account" menu, click on "t.me/username."`,
    step4: `4. Fill in username of your choosing, that is available.`,
    step5: `5. Click on "Save" to set your username.`,
    step6: `6. Provide this username in your telegram ID section on this page and save.`,
  },
];

export const UpstoxData = [
  {
    link: "https://www.youtube.com/watch?v=S-F8R3ord3k",
    videoId: "S-F8R3ord3k",
    linkContain: "Steps to Get Telegram App ID:",
    step1: "1. Log in to your Telegram account via Desktop or Mobile App.",
    step2: `2. Click on the menu icon located at the top left corner and select "Settings."`,
    step3: `3. In the "My Account" menu, click on "t.me/username."`,
    step4: `4. Fill in username of your choosing, that is available.`,
    step5: `5. Click on "Save" to set your username.`,
    step6: `6. Provide this username in your telegram ID section on this page and save.`,
  },
];
