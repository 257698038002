import React from "react";
import breadcrumbsStyles from "./Breadcrumbs.module.css";
import caretRight from "../../assests/caretRight.svg";
import { Link } from "react-router-dom";

function Breadcrumb(props) {
  const breadcrumbsList = props.breadcrumbsList;

  return (
    <div className={`flex items-center text-center`}>
      {breadcrumbsList.map((breadcrumb, i) => (
        <span
          className={`${
            i === breadcrumbsList.length - 1
              ? `flex cursor-auto ${breadcrumbsStyles.lastLink}`
              : `flex cursor-pointer`
          } ${breadcrumbsStyles.linkStyle}`}
          key={i}
        >
          <Link className="pr-3" to={breadcrumb.link}>
            {breadcrumb.title === "bnb" ? "Binance Coin" : breadcrumb.title}
          </Link>
          <img src={caretRight} width={6} height={6} alt="Back Arrow" />
        </span>
      ))}
    </div>
  );
}

export default Breadcrumb;
