import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import axios from "axios";
import CryptoJS from "crypto-js";
import toast, { Toaster } from "react-hot-toast";
import {
  EyeIcon,
  EyeOffIcon,
  ArrowLeft,
  X,
  Search,
  ChevronLeft,
} from "lucide-react";
import YouTube from "react-youtube";
import { ChevronDown, ChevronUp, Check, ClipboardList } from "lucide-react";

import IfflLogo from "../../assests/iifl_logo.png";
import AngelOneLogo from "../../assests/AngleLogo.png";
import Zerodha from "../../assests/Zerodha.png";
import Icici from "../../assests/icici.png";
import Upstox from "../../assests/upstox.svg";
import Kotak from "../../assests/kotak.png";
import Hdfc from "../../assests/hdfc_securities.png";
import Dhan from "../../assests/dhan.png";

import AliceBlue from "../../assests/aliceblue.png";
import Fyers from "../../assests/fyers.png";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import server from "../../utils/serverConfig";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import LoadingSpinner from "../../components/LoadingSpinner";
import { motion, AnimatePresence } from "framer-motion";
import Checked from "../../assests/checked.svg";
import { encryptApiKey } from "../../utils/cryptoUtils";
import KotakConnection from "../BrokerConnection/Kotak/KotakConnection";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9]  focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full",
};

const initialState = {
  broker: "",
  clientCode: "",
  apiKey: "",
  secretKey: "",
  password: "",
  showApiKey: false,
  showSecretKey: false,
  showPassword: false,
  inputValue: "",
  jwtToken: "",
  isOpen: false,
  selectMethod: "Mobile",
  mobileNumber: "",
  panCard: "",
  mpin: "",
  otp: "",
  totp: "",
  openOtpBox: false,
  storeResponse: null,
  qrValue: "",
  loading: false,
  isLoading: false,
  zerodhaLoading: false,
  fyersLoading: false,
};

const ConnectBroker = ({
  uid,
  setBrokerModel,
  broker: initialBroker,
  setBroker,
  brokerModel,
  getUserDetails,
  setWithoutBroker,
  handleAcceptRebalance,
  withoutBrokerLoading,
  setSelectNonBroker,
  withoutBrokerModal,
}) => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [showLetUsKnow, setShowLetUsKnow] = useState(false);

  const [brokers, setBrokers] = useState([]);
  const [filteredBrokers, setFilteredBrokers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBrokerName, setSelectedBrokerName] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    fetch("/allBrokers.json")
      .then((response) => response.text())
      .then((text) => {
        const cleanedText = text.replace(/^\uFEFF/, "");
        return JSON.parse(cleanedText);
      })
      .then((data) => {
        if (data && data?.Broker) {
          // Extract unique names only
          const uniqueBrokers = Array.from(
            new Set(data?.Broker?.map((broker) => broker?.Name))
          ).map((name) => {
            return data?.Broker?.find((broker) => broker?.Name === name);
          });
          setBrokers(uniqueBrokers);
          setFilteredBrokers(uniqueBrokers);
        } else {
          throw new Error("Invalid data format");
        }
      })
      .catch((error) => {
        console.error("Error fetching brokers:", error);
        setError(error?.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = brokers.filter((broker) =>
      broker.Name.toLowerCase().startsWith(term)
    );
    setFilteredBrokers(filtered);
  };

  const handleSelectBroker = (brokerName) => {
    setSelectedBrokerName((prevSelectedBroker) =>
      prevSelectedBroker === brokerName ? null : brokerName
    );
  };

  const [findBrokerLoader, setFindBrokerLoader] = useState(false);
  const handleBrokerSubmit = () => {
    if (selectedBrokerName) {
      setFindBrokerLoader(true);
      axios
        .put(
          `${server.ccxtServer.baseUrl}comms/unavailable-broker/save`,
          {
            userEmail: userEmail,
            broker: selectedBrokerName,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        )
        .then((res) => {
          setShowSuccess(true);
          setFindBrokerLoader(false);
        })
        .catch((err) => {
          setFindBrokerLoader(false);
        });
    }
  };
  const resetStates = () => {
    setShowLetUsKnow(false);
    setShowSuccess(false);
    setSelectedBrokerName(null);
    setSearchTerm("");
    setFilteredBrokers(brokers);
  };

  const [state, setState] = useState(initialState);
  const appURL = process.env.REACT_APP_URL;
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const angelOneApiKey = process.env.REACT_APP_ANGEL_ONE_API_KEY;
  const brokerConnectRedirectURL =
    process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL;
  const playerRef = useRef(null);
  const opts = {
    height: "300",
    width: "630",
    playerVars: {
      autoplay: 0,
    },
  };

  const handlePlayerReady = (event) => {
    // Pause the video when the player is ready
    event.target.pauseVideo();
  };

  const resetState = () => {
    setState(initialState);
    setBroker(initialBroker);
  };

  useEffect(() => {
    if (!brokerModel) {
      resetState();
    }
  }, [brokerModel, initialBroker]);

  const toggleOpen = () =>
    setState((prev) => ({ ...prev, isOpen: !prev.isOpen }));

  const storeClientCode = () => {
    setState((prev) => ({ ...prev, clientCode: prev.inputValue }));
  };

  const handleSubmit = () => {
    setState((prev) => ({ ...prev, loading: true }));
    let data = JSON.stringify(
      state.broker === "Dhan"
        ? {
            uid: uid,
            user_broker: state.broker,
            clientCode: state.clientCode,
            jwtToken: state.jwtToken,
          }
        : state.broker === "AliceBlue"
        ? {
            uid: uid,
            user_broker: state.broker,
            clientCode: state.clientCode,
            apiKey: state.apiKey,
          }
        : {
            uid: uid,
            user_broker: state.broker,
            clientCode: state.clientCode,
            qrValue: state.qrValue,
            apiKey: state.apiKey,
            password: state.password,
          }
    );

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/user/connect-broker`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        // making new API call to update all model PF brokers
        let newBrokerData = {
          user_email: userEmail,
          user_broker: state.broker,
        };
        let A1_broker = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/change_broker_model_pf`,
          data: JSON.stringify(newBrokerData),
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        };
        console.log("called the function and received: ", A1_broker);

        setState((prev) => ({ ...prev, loading: false, isLoading: true }));
        getUserDetails();
      })
      .catch((error) => {
        console.log(error);
        setState((prev) => ({ ...prev, loading: false }));
        getUserDetails();
        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  useEffect(() => {
    if (state.isLoading) {
      const timer = setTimeout(() => {
        toast.success("Your broker has been successfully Connected.", {
          duration: 4000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setBrokerModel(false); // Close the modal after 3 seconds
        setState((prev) => ({ ...prev, isLoading: false }));
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [state.isLoading]);

  const checkValidApiAnSecret = (details) => {
    const bytesKey = CryptoJS.AES.encrypt(details, "ApiKeySecret");
    const Key = bytesKey.toString();
    if (Key) {
      return Key;
    }
  };

  const updateSecretKey = () => {
    setState((prev) => ({ ...prev, loading: true }));
    if (state.broker === "Upstox") {
      let data = JSON.stringify({
        uid: uid,
        apiKey: checkValidApiAnSecret(state.apiKey),
        secretKey: checkValidApiAnSecret(state.secretKey),
        redirect_uri: brokerConnectRedirectURL,
      });
      let config = {
        method: "post",
        url: `${server.server.baseUrl}api/upstox/update-key`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };
      axios
        .request(config)
        .then((response) => {
          setState((prev) => ({ ...prev, loading: false }));
          if (response) {
            window.location.href = response.data.response;
          }
        })
        .catch((error) => {
          console.log(error);
          setState((prev) => ({ ...prev, loading: false }));
          toast.error("Incorrect credential.Please try again", {
            duration: 5000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
    } else if (state.broker === "Hdfc Securities") {
      let data = JSON.stringify({
        uid: uid,
        apiKey: checkValidApiAnSecret(state.apiKey),
        secretKey: checkValidApiAnSecret(state.secretKey),
      });
      let config = {
        method: "post",
        url: `${server.server.baseUrl}api/hdfc/update-key`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };
      axios
        .request(config)
        .then((response) => {
          setState((prev) => ({ ...prev, loading: false }));
          if (response) {
            window.location.href = response.data.response;
          }
        })
        .catch((error) => {
          console.log(error);
          setState((prev) => ({ ...prev, loading: false }));
          toast.error("Incorrect credential.Please try again", {
            duration: 5000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
    } else {
      let data = JSON.stringify({
        uid: uid,
        user_broker: state.broker,
        apiKey: checkValidApiAnSecret(state.apiKey),
        secretKey: checkValidApiAnSecret(state.secretKey),
      });
      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/icici/update-key`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };
      axios
        .request(config)
        .then((response) => {
          setState((prev) => ({ ...prev, loading: false }));
          window.location.href = `https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(
            state.apiKey
          )}`;
        })
        .catch((error) => {
          console.log(error);
          setState((prev) => ({ ...prev, loading: false }));
        });
    }
  };

  const connectZerodha = () => {
    setState((prev) => ({ ...prev, zerodhaLoading: true }));
    let data = JSON.stringify({
      apiKey: zerodhaApiKey,
      site: `${process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL.replace(
        "https://",
        ""
      )}`,
    });

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}zerodha/login-url`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setState((prev) => ({ ...prev, zerodhaLoading: false }));
        window.location.href = `${response.data}`;
      })
      .catch((error) => {
        setState((prev) => ({ ...prev, zerodhaLoading: false }));
        console.log(error);
      });
  };

  const connectFyers = () => {
    setState((prev) => ({ ...prev, loading: true }));
    let data = JSON.stringify({
      uid: uid,
      redirect_url: brokerConnectRedirectURL,
      clientCode: state.clientCode,
      secretKey: checkValidApiAnSecret(state.secretKey),
    });

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/fyers/update-key`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setState((prev) => ({ ...prev, loading: false }));
        window.location.href = `${response.data.response}`;
      })
      .catch((error) => {
        setState((prev) => ({ ...prev, loading: false }));
        console.log(error);
      });
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success("Copied to clipboard!", {
          duration: 4000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      },
      () => {
        toast.error("Failed to copy text", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    );
  };

  const handleContinueWithoutBroker = () => {
    axios
      .put(
        `${server.ccxtServer.baseUrl}comms/no-broker-required/save`,
        {
          userEmail: userEmail,
          noBrokerRequired: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      )
      .then((res) => {
        setBrokerModel(false);
        setWithoutBroker(true);
        handleAcceptRebalance();
        setSelectNonBroker(true);
      })
      .catch((err) => {
        toast.error("Something went wrong. Please try again.", {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  const [withoutBrokerLoader, setWithoutBrokerLoader] = useState(false);
  const handleContinueWithoutBrokerSave = () => {
    setWithoutBrokerLoader(true);
    axios
      .put(
        `${server.ccxtServer.baseUrl}comms/no-broker-required/save`,
        {
          userEmail: userEmail,
          noBrokerRequired: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      )
      .then((res) => {
        setWithoutBrokerLoader(false);
        getUserDetails();
        setBrokerModel(false);
        toast.success("Your preference has been stored successfully.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
            fontFamily: "Poppins",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      })
      .catch((err) => {
        setWithoutBrokerLoader(false);
        toast.error("Something went wrong. Please try again.", {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  const connectIIFL = () => {
    window.location.href = `https://markets.iiflcapital.com/?v=1&appkey=nHjYctmzvrHrYWA&redirect_url=${process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL.replace(
      "https://",
      ""
    )}`;
  };

  //  IIFL postback params
  useEffect(() => {
    const handleIIFLLogin = async () => {
      const params = new URLSearchParams(window.location.search);
      const authCode = params.get("authcode");
      const clientId = params.get("clientid");

      if (!authCode || !clientId) return;

      setIsLoading(true);
      try {
        const response = await axios.post(
          `${server.ccxtServer.baseUrl}/iifl/login/client`,
          {
            auth_token: authCode,
            client_code: clientId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );

        const accessToken = response.data.sessionToken;

        localStorage.setItem("iiflAccessToken", accessToken);
        localStorage.setItem("iiflClientCode", clientId);

        setState((prev) => ({
          ...prev,
          iiflClientCode: clientId,
          accessToken: accessToken,
          brokerName: "iifl",
        }));

        toast.success("Successfully connected to IIFL");
        setBrokerModel(false);
      } catch (error) {
        console.error("IIFL Login failed:", error);
        toast.error("Failed to connect with IIFL");
      } finally {
        setIsLoading(false);
      }
    };

    handleIIFLLogin();
  }, [location]);

  // if (isDesktop) {
  return (
    <>
      <Dialog open={brokerModel} onOpenChange={setBrokerModel}>
        <DialogContent className="w-auto flex justify-center animate-modal backdrop-filter: blur(0)">
          {!state.broker && !state.clientCode ? (
            <AnimatePresence mode="wait">
              {!showLetUsKnow ? (
                <motion.div
                  key="brokerSelection"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="relative  min-w-[340px]  rounded-[12px] py-6 ">
                    <div className="flex flex-col space-y-3 px-0 sm:px-6 items-center justify-center text-black text-center mb-4">
                      <span className="text-black text-center text-[20px] font-bold ">
                        Select your broker for connection
                      </span>
                      <span className="text-[#000000]/70  text-[14px] text-center leading-[20px] font-medium">
                        For seamless execution post your approval, please
                        connect your broker
                      </span>
                    </div>
                    <div className="grid grid-cols-4 h-[230px] custom-scroll overflow-y-auto gap-2 px-0 sm:px-4">
                      <div className="w-full">
                        <div
                          onClick={connectIIFL}
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer will-change-transform"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={IfflLogo}
                              alt="IIFL Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            {state.iiflLoading ? (
                              <LoadingSpinner />
                            ) : (
                              "IIFL Securities"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <a
                          href={`https://smartapi.angelbroking.com/publisher-login?api_key=${angelOneApiKey}`}
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={AngelOneLogo}
                              alt="AngelOne Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            AngelOne
                          </span>
                        </a>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={connectZerodha}
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Zerodha}
                              alt="Zerodha"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            {state.zerodhaLoading ? (
                              <LoadingSpinner />
                            ) : (
                              "Zerodha"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              broker: "ICICI Direct",
                            }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Icici}
                              alt="ICICI Direct"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            ICICI
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({ ...prev, broker: "Upstox" }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Upstox}
                              alt="Upstox Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            Upstox
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({ ...prev, broker: "Kotak" }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Kotak}
                              alt="Kotak Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            Kotak
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              broker: "Hdfc Securities",
                            }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Hdfc}
                              alt="Hdfc Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            HDFC
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({ ...prev, broker: "Dhan" }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Dhan}
                              alt="Dhan Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            Dhan
                          </span>
                        </div>
                      </div>

                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              broker: "AliceBlue",
                            }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={AliceBlue}
                              alt="aliceBlue Logo"
                              className="w-10 h-14 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            AliceBlue
                          </span>
                        </div>
                      </div>

                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              broker: "Fyers",
                            }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Fyers}
                              alt="fyers Logo"
                              className="w-10 h-10 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            Fyers
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="w-full px-0 sm:px-4 pt-5">
                      {/*<div className="group flex flex-col space-y-2 bg-gray-50 text-gray-800 border border-gray-200 p-4 rounded-lg cursor-pointer shadow-sm hover:shadow-md transition-all duration-200">*/}
                      {/*  <div className="flex flex-row justify-between items-center">*/}
                      {/*    <span className="text-gray-800 group-hover:text-gray-900 text-[14px] font-semibold ">*/}
                      {/*      Can't find <br className="sm:hidden" />*/}
                      {/*      your broker?*/}
                      {/*    </span>*/}
                      {/*    <button*/}
                      {/*      onClick={() => setShowLetUsKnow(true)}*/}
                      {/*      className="flex items-center text-[12px] font-medium border-none text-[#55A7F1] "*/}
                      {/*    >*/}
                      {/*      Nothing to worry*/}
                      {/*      <ChevronRight className="ml-1 h-4 w-4" />*/}
                      {/*    </button>*/}
                      {/*  </div>*/}
                      {/*  <span className="text-gray-600 group-hover:text-gray-700 text-[12px] font-medium ">*/}
                      {/*    You will continue to receive recommendations from your*/}
                      {/*    advisor on your email and/or whatsapp/telegram. As we*/}
                      {/*    add more brokers, you can visit the page and add your*/}
                      {/*    broker later.*/}
                      {/*  </span>*/}
                      {/*</div>*/}
                    </div>
                    {withoutBrokerModal === true ? (
                      <div
                        className="flex flex-row justify-end text-center itmes-center w-full px-0 sm:px-4 pt-5 cursor-pointer"
                        onClick={handleContinueWithoutBroker}
                      >
                        <div className="group flex flex-col space-y-2 bg-gray-50 text-gray-800 border border-gray-200 p-4 rounded-lg cursor-pointer shadow-sm hover:shadow-md transition-all duration-200">
                          <div className="flex flex-row justify-between items-center">
                            <span className="text-gray-800 group-hover:text-gray-900 text-[8px] font-semibold ">
                              {withoutBrokerLoading === true ? (
                                <LoadingSpinner />
                              ) : (
                                " Continue without connecting broker"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="flex flex-row justify-end text-center items-center w-full px-0 sm:px-4 pt-5 cursor-pointer"
                        onClick={handleContinueWithoutBrokerSave}
                      >
                        <div className="group flex flex-col space-y-2 bg-gray-50 text-gray-800 border border-gray-200 p-4 rounded-lg cursor-pointer shadow-sm hover:shadow-md transition-all duration-200">
                          <div className="flex flex-row justify-between items-center">
                            <span className="text-gray-800 group-hover:text-gray-900 text-[12px] font-medium ">
                              {withoutBrokerLoader === true ? (
                                <LoadingSpinner />
                              ) : (
                                " Continue without connecting broker"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key="letUsKnow"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="flex flex-col px-0 sm:px-6 items-center justify-center text-black text-center w-[300px]  sm:w-[720px] h-[465px]">
                    {!showSuccess && (
                      <button
                        onClick={() => setShowLetUsKnow(false)}
                        className="self-start flex items-center text-sm font-semibold text-gray-600 transition-colors"
                      >
                        <ChevronLeft className="mr-1" size={22} />
                      </button>
                    )}

                    {showSuccess ? (
                      <div className="flex flex-col items-center justify-center h-full">
                        <div className=" flex items-center justify-center mb-1">
                          <img src={Checked} className="w-12 h-12 " />
                        </div>
                        <h2 className="text-[20px] mb-5 font-bold  text-black">
                          Thank You!
                        </h2>
                        <p className="text-center  text-gray-600 text-sm mb-1 max-w-[400px]">
                          Although your broker is not supported for direct trade
                          execution, do not worry.
                          <span>
                            You will be receiving advice on your whatsapp and
                            gmail.{" "}
                          </span>
                        </p>
                        {/* <button
                        onClick={resetStates}
                        className=" py-2 px-6 mt-2 bg-black text-[18px] lg:text-base  text-white font-medium rounded-md cursor-pointer"
                      >
                        Back to Broker Selection
                      </button> */}
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-col items-center  mt-0">
                          <h2 className="text-[20px] mb-1 font-bold  text-black">
                            Find Your Broker
                          </h2>
                          <p className="text-center  text-gray-600 text-sm mb-4 ">
                            We're expanding our supported brokers. Help us by
                            searching for yours.
                          </p>
                        </div>
                        <div className="relative mb-3 w-full">
                          <input
                            type="text"
                            placeholder="Search for your broker"
                            className="w-full p-2 pl-8 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300 text-sm "
                            onChange={handleSearch}
                            value={searchTerm}
                          />
                          <Search
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
                            size={16}
                          />
                        </div>
                        <div className="w-full h-[300px] sm:h-[500px]  overflow-y-auto font-medium border border-gray-200 rounded-md bg-white mb-6 relative no-scrollbar">
                          {isLoading ? (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-black"></div>
                            </div>
                          ) : error ? (
                            <p className="text-center py-8  text-red-500">
                              {error}
                            </p>
                          ) : filteredBrokers.length === 0 ? (
                            <p className="text-center py-8  text-gray-600">
                              No brokers found
                            </p>
                          ) : (
                            <ul className="w-full">
                              {filteredBrokers.map((broker, i) => (
                                <li
                                  key={i}
                                  className={`px-4 py-2 text-left hover:bg-gray-100 transition-colors duration-150 cursor-pointer ${
                                    selectedBrokerName === broker.Name
                                      ? "bg-gray-200"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleSelectBroker(broker.Name)
                                  }
                                >
                                  <div className="flex justify-between items-center">
                                    <p className=" text-sm text-gray-700">
                                      {broker.Name}
                                    </p>
                                    {selectedBrokerName === broker.Name && (
                                      <Check
                                        size={16}
                                        className="text-green-500"
                                      />
                                    )}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <div className="w-full flex justify-end">
                          <button
                            onClick={handleBrokerSubmit}
                            disabled={!selectedBrokerName}
                            className="w-full sm:w-1/4 px-4 py-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors duration-200 text-sm  font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            {findBrokerLoader === true ? (
                              <LoadingSpinner />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          ) : state.broker === "Dhan" ? (
            <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8 no-scrollbar">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400 ease-linear duration-150 cursor-pointer"
                onClick={() => setState((prev) => ({ ...prev, broker: "" }))}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex">Back</div>
              </div>
              <div className="mt-6 px-0 sm:px-[28px] h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div className="mb-8 rounded-xl">
                  <YouTube
                    videoId={`MhAfqNQKSrQ`}
                    className={`videoIframe rounded-xl`}
                    opts={opts}
                    onReady={handlePlayerReady}
                  />
                </div>
                {/* <div
                  className={`mb-2 text-lg text-black font-semibold  leading-[22px]`}
                >
                  Steps to get the Client Code and Access Token for Dhan:
                </div> */}

                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold">
                    Steps to Obtain API and Secret Key for Dhan:
                  </h2>
                  <button
                    onClick={toggleOpen}
                    className="focus:outline-none lg:hidden "
                  >
                    <div className="mr-4 mb-5">
                      {state.isOpen ? (
                        <ChevronUp size={20} strokeWidth={3} />
                      ) : (
                        <ChevronDown size={20} strokeWidth={3} />
                      )}
                    </div>
                  </button>
                </div>
                <div
                  className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                >
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>1.</div>{" "}
                    <div className="ml-3 mr-2">
                      {" "}
                      Go to
                      {/* <div className="inline-flex items-center flex-wrap">
                      <a
                        href="http://login.dhan.co"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 text-[#0000FF] "
                      >
                        http://login.dhan.co

                      </a>
                      <img
                        src={Copy}
                        alt="Copy"
                        className="w-4 h-4 cursor-pointer mr-2"
                        onClick={() =>
                          handleCopy(
                            ` http://login.dhan.co
`
                          )
                        }
                      />
                      {" "}
                      and log in using the QR code option.

                    </div> */}
                    </div>
                  </div>
                  <div className="inline-flex flex-col items-start ml-4 text-white font-normal ">
                    <div className="inline-flex items-center rounded-lg overflow-hidden ml-1 bg-blue-100">
                      <div className="px-1 pl-2 py-0.5 rounded-md overflow-x-auto text-sm ">
                        <a
                          href="http://login.dhan.co"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500"
                        >
                          http://login.dhan.co
                        </a>
                      </div>
                      <div
                        className="px-1 "
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content="COPY"
                      >
                        <ClipboardList
                          className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                          onClick={() => handleCopy("http://login.dhan.co")}
                        />
                      </div>
                    </div>
                    <Tooltip id="copy-tooltip" />
                    <span className="ml-1 text-black font-normal ">
                      and log in using the QR code option.
                    </span>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>2.</div>{" "}
                    <div className="ml-2 items-center">
                      {" "}
                      Open this in desktop view ,unavailable in app
                    </div>
                  </div>

                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>3.</div>{" "}
                    <div className="ml-2">
                      Click on your profile picture and choose "My Profile on
                      Dhan". Under the Profile details, you'll find the "Client
                      ID".
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>4.</div>{" "}
                    <div className="ml-2">
                      Then, select "Dhan HQ Trading APIs" from the menu.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>5.</div>{" "}
                    <div className="ml-2 items-center">
                      {" "}
                      To generate an access token, click on "+ New Token," enter
                      a name for your app, set the validity to 30 days, and
                      click "Generate Token."
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>6.</div>{" "}
                    <div className="ml-2 items-center">
                      {" "}
                      Copy the access token and paste it into the designated
                      field.
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 md:flex flex-col space-y-4 px-2 sm:px-12">
                <div className="text-2xl sm:text-3xl font-bold">
                  Connect {state.broker}
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-base sm:text-lg font-semibold ">
                    Client Id :{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="Client Code"
                      type="text"
                      value={state.clientCode}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          clientCode: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-base sm:text-lg font-semibold ">
                    Access Token:{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="Access Token"
                      value={state.jwtToken}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          jwtToken: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.clientCode || !state.jwtToken}
                    onClick={handleSubmit}
                  >
                    {state.loading ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker === "AliceBlue" ? (
            <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8 no-scrollbar">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400 ease-linear duration-150 cursor-pointer"
                onClick={() => setState((prev) => ({ ...prev, broker: "" }))}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex">Back</div>
              </div>
              <div className="mt-6 px-0 sm:px-[28px] h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                >
                  <div className="pb-8 px-0 sm:px-10 rounded-xl pt-8">
                    <YouTube
                      videoId={`YaONYqsiwGQ`}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                      className="videoIframe rounded-xl "
                    />

                    <div className="flex items-center justify-between mb-4 mt-8 ">
                      <h2 className="text-lg font-semibold ">
                        Steps to get the User ID and Api Key for Alice Blue:
                      </h2>
                      <button
                        onClick={toggleOpen}
                        className="focus:outline-none lg:hidden "
                      >
                        <div className="mr-4 mb-6">
                          {state.isOpen ? (
                            <ChevronUp size={20} strokeWidth={3} />
                          ) : (
                            <ChevronDown size={20} strokeWidth={3} />
                          )}
                        </div>
                      </button>
                    </div>
                    <div
                      className={`lg:block ${
                        state.isOpen ? "block" : "hidden"
                      }`}
                    >
                      <div className="flex flex-row text-[15px]  mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                        <div>1.</div> <div className="ml-2"> Log in to</div>
                      </div>

                      <div className="inline-flex flex-col items-start ml-2 text-white font-normal ">
                        <div className="inline-flex items-center rounded-lg overflow-hidden ml-1 bg-blue-100">
                          <div className="pl-2 py-0.5 rounded-md overflow-x-auto text-sm ">
                            <a
                              href={`https://ant.aliceblueonline.com/`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500"
                            >
                              ant.aliceblueonline.com/apps
                            </a>
                          </div>
                          <div
                            className="px-1 "
                            data-tooltip-id="copy-tooltip"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy("ant.aliceblueonline.com/apps")
                              }
                            />
                          </div>
                        </div>
                        <Tooltip id="copy-tooltip" />
                        <span className="ml-1 text-black font-normal ">
                          with your phone number, password, and TOTP or mobile
                          OTP.
                        </span>
                      </div>

                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                        <div>2.</div>{" "}
                        <div className="ml-2">
                          If prompted with a Risk Disclosure pop-up, click
                          "Proceed."
                        </div>
                      </div>
                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                        <div>3.</div>{" "}
                        <div className="ml-2">
                          In the "Apps" tab, select "API Key," click "Copy," and
                          paste it on your platform. Note: This key is valid for
                          24 hours, so generate a new one daily.
                        </div>
                      </div>

                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                        <div>4.</div>{" "}
                        <div className="ml-2">
                          For your User ID, click the profile icon, go to "Your
                          Profile/Settings," and copy the client ID under your
                          name. Paste it onto your platform.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 md:flex flex-col space-y-4 px-0 sm:px-12">
                <div className="text-3xl font-bold">Connect {state.broker}</div>
                <div className="text-lg font-normal">
                  Please login to your AliceBlue account(logout if already
                  logged in and Login again) before attempting to connect.
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold ">
                    User Id :{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="User Id"
                      type="text"
                      value={state.clientCode}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          clientCode: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold ">
                    Api Key:{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="Api Key"
                      value={state.apiKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          apiKey: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.clientCode || !state.apiKey}
                    onClick={handleSubmit}
                  >
                    {state.loading ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker === "Fyers" ? (
            <div className="relative bg-[#ffffff] w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6 lg:grid-cols-2 rounded-[10px] lg:py-8">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400 ease-linear duration-150 cursor-pointer"
                onClick={() => setState((prev) => ({ ...prev, broker: "" }))}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex">Back</div>
              </div>
              <div className="mt-6 px-[28px] h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                >
                  <div className="pb-8 px-0 sm:px-10 rounded-xl pt-8">
                    <YouTube
                      videoId={`blhTiePBIg0`}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                      className="videoIframe rounded-xl "
                    />

                    <div className="flex items-center justify-between mb-4 mt-8 ">
                      <h2 className="text-lg font-semibold ">
                        Fyers: How to get App ID & Secret ID
                      </h2>
                      <button
                        onClick={toggleOpen}
                        className="focus:outline-none lg:hidden "
                      >
                        <div className="mr-4 mb-6">
                          {state.isOpen ? (
                            <ChevronUp size={20} strokeWidth={3} />
                          ) : (
                            <ChevronDown size={20} strokeWidth={3} />
                          )}
                        </div>
                      </button>
                    </div>
                    <div
                      className={`lg:block ${
                        state.isOpen ? "block" : "hidden"
                      }`}
                    >
                      <div className="flex flex-row text-[15px]  mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                        <div>1.</div> <div className="ml-2"> Visit</div>
                      </div>

                      <div className="inline-flex flex-col items-start ml-2 text-white font-normal ">
                        <div className="inline-flex items-center  rounded-lg overflow-hidden ml-1 bg-blue-100">
                          <div className="pl-2 py-0.5 rounded-md overflow-x-auto text-sm ">
                            <a
                              href="https://myapi.fyers.in/dashboard"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500"
                            >
                              https://myapi.fyers.in/dashboard
                            </a>
                          </div>
                          <div
                            className="px-1 "
                            data-tooltip-id="copy-tooltip"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy("https://myapi.fyers.in/dashboard")
                              }
                            />
                          </div>
                        </div>
                        <Tooltip id="copy-tooltip" />
                      </div>

                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                        <div>2.</div>{" "}
                        <div className="ml-2">
                          Log in using your phone number, enter the TOTP, and
                          your 4-digit PIN.
                        </div>
                      </div>
                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                        <div>3.</div>{" "}
                        <div className="ml-2">
                          Click on the "Create App" button. Provide an app name,
                          paste the redirect URL as specified in the
                          instructions, add a description, and delete the
                          webhook. Grant all app permissions and check the box
                          to accept the API Usage Terms and Conditions. Finally,
                          click on "Create App."
                        </div>
                      </div>

                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                        <div>4.</div>{" "}
                        <div className="ml-2">
                          Scroll down to find the newly created app. Copy the
                          App ID and Secret ID and paste them into your
                          platform.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 md:flex flex-col space-y-4 px-12">
                <div className="text-3xl font-bold">Connect {state.broker}</div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold ">
                    App Id :{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="app id"
                      type="text"
                      value={state.clientCode}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          clientCode: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold ">
                    Secret Id:{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="secret id"
                      value={state.secretKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          secretKey: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.clientCode || !state.secretKey}
                    onClick={connectFyers}
                  >
                    {state.loading ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker === "ICICI Direct" ||
            state.broker === "Upstox" ||
            state.broker === "Hdfc Securities" ? (
            <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8 no-scrollbar">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setState((prev) => ({ ...prev, broker: "" }));
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex  ">Back</div>
              </div>
              {state.broker === "Upstox" ? (
                <div className="pb-8 px-0 sm:px-10 rounded-xl pt-8">
                  <YouTube
                    videoId={`yfTXrjl0k3E`}
                    opts={opts}
                    onReady={handlePlayerReady}
                    ref={playerRef}
                    className="videoIframe rounded-xl "
                  />

                  {/* <div
                    className={`mb-2 mt-8  text-lg text-black font-semibold  leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for Upstox :
                  </div> */}

                  <div className="flex items-center justify-between mb-4 mt-8 ">
                    <h2 className="text-lg font-semibold ">
                      Steps to Obtain API and Secret Key for Upstox :
                    </h2>
                    <button
                      onClick={toggleOpen}
                      className="focus:outline-none lg:hidden "
                    >
                      <div className="mr-4 mb-6">
                        {state.isOpen ? (
                          <ChevronUp size={20} strokeWidth={3} />
                        ) : (
                          <ChevronDown size={20} strokeWidth={3} />
                        )}
                      </div>
                    </button>
                  </div>
                  <div
                    className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                  >
                    <div className="flex flex-row text-[15px]  mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>1.</div>{" "}
                      <div className="ml-2">
                        {" "}
                        Visit
                        {/* <div className=" inline-flex items-center flex-wrap">
                        <a
                          href="https://shorturl.at/plWYJ"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-2 text-[#0000FF] "
                        >
                          https://shorturl.at/plWYJ

                        </a>
                        <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4 mr-2 cursor-pointer"
                          onClick={() =>
                            handleCopy(
                              `https://shorturl.at/plWYJ`
                            )
                          }
                        />
                      </div> */}
                      </div>
                    </div>

                    <div className="inline-flex flex-col items-start ml-2 text-white font-normal ">
                      <div className="inline-flex items-center border rounded-lg overflow-hidden ml-1 bg-blue-100">
                        <div className="pl-2 py-0.5 rounded-md overflow-x-auto text-sm ">
                          <a
                            href="https://shorturl.at/plWYJ"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500"
                          >
                            https://shorturl.at/plWYJ
                          </a>
                        </div>
                        <div
                          className="px-1 "
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content="COPY"
                        >
                          <ClipboardList
                            className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                            onClick={() =>
                              handleCopy("https://shorturl.at/plWYJ")
                            }
                          />
                        </div>
                      </div>
                      <Tooltip id="copy-tooltip" />
                      <span className="ml-1 text-black font-normal ">
                        and log in using your phone number. Verify your identity
                        with the OTP and continue.
                      </span>
                    </div>

                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>2.</div>{" "}
                      <div className="ml-2">
                        Enter your 6-digit PIN and continue.
                      </div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>3.</div>{" "}
                      <div className="ml-2">
                        Click on the "New App" button. Fill in the "App Name"
                        field with "{whiteLabelText}" or a name of your choice.
                        Enter the "Redirect URL" as
                        <span className="inline-flex items-center gap-2 px-2  text-[15px] text-blue-500 bg-blue-100 rounded-lg">
                          {brokerConnectRedirectURL}
                          {/* <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4 ml-2 cursor-pointer"
                          onClick={() =>
                            handleCopy(`${brokerConnectRedirectURL}`)
                          }
                        /> */}
                          <div
                            className="flex items-center  border-gray-700 pl-2 flex-shrink-0"
                            data-tooltip-id="copy-tooltip-1"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy(brokerConnectRedirectURL)
                              }
                            />
                          </div>
                          <Tooltip id="copy-tooltip-1" />
                        </span>
                      </div>
                    </div>
                    <div className="text-[15px] mb-0.5 ml-5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal ">
                      You can skip the Postback URL and Description as they are
                      optional. Accept the Terms & Conditions and click on the
                      "Continue" button. Please ensure that the "Redirect URL"
                      is entered correctly as mentioned above.
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>4.</div>{" "}
                      <div className="ml-2">
                        Review the details (make sure you don't have more than 2
                        apps) and click on the "Confirm Plan" button. Your API
                        is now ready! Click on the "Done" button.
                      </div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>5.</div>{" "}
                      <div className="ml-2">
                        Click on the newly created app, copy your API and Secret
                        Key, and enter these details on the designated screen.
                      </div>
                    </div>
                  </div>
                </div>
              ) : state.broker === "Hdfc Securities" ? (
                <div className="mt-6 px-0 sm:px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                  <div className="mb-8 rounded-xl ">
                    <YouTube
                      videoId={`iziwR2zLLvk`}
                      className={`videoIframe rounded-xl `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>

                  {/* <div
                    className={`mb-2 text-lg text-black font-semibold  leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for Hdfc Securities:
                  </div> */}

                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-lg font-semibold">
                      Steps to Obtain API and Secret Key for {state.broker}:
                    </h2>
                    <button
                      onClick={toggleOpen}
                      className="focus:outline-none lg:hidden "
                    >
                      <div className="mr-4 mb-5">
                        {state.isOpen ? (
                          <ChevronUp size={20} strokeWidth={3} />
                        ) : (
                          <ChevronDown size={20} strokeWidth={3} />
                        )}
                      </div>
                    </button>
                  </div>
                  <div
                    className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                  >
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>1.</div>{" "}
                      <div className="ml-3">
                        {" "}
                        Go to
                        {/* <div className="inline-flex items-center flex-wrap">
                        <a
                          href="https://developer.hdfcsec.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-2 text-[#0000FF] "
                        >
                          https://developer.hdfcsec.com/


                        </a>
                        <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4 cursor-pointer"
                          onClick={() =>
                            handleCopy(
                              ` https://developer.hdfcsec.com/`
                            )
                          }
                        />

                      </div> */}
                      </div>
                    </div>

                    <div className="inline-flex flex-col items-start ml-4 text-white font-normal ">
                      <div className="inline-flex items-center border rounded-lg overflow-hidden ml-1 bg-blue-100">
                        <div className="pl-2 pr-1 py-0.5 rounded-md overflow-x-auto text-sm ">
                          <a
                            href="https://developer.hdfcsec.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500"
                          >
                            https://developer.hdfcsec.com/
                          </a>
                        </div>
                        <div
                          className="px-1  border-gray-500"
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content="COPY"
                        >
                          <ClipboardList
                            className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                            onClick={() =>
                              handleCopy("https://developer.hdfcsec.com/")
                            }
                          />
                        </div>
                      </div>
                      <Tooltip id="copy-tooltip" />
                    </div>

                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>2.</div>{" "}
                      <div className="ml-2">
                        Log in with your ID, password, and OTP.
                      </div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>3.</div>{" "}
                      <div className="ml-2">Accept the *Risk Disclosure*.</div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>4.</div>{" "}
                      <div className="ml-2 items-center">
                        {" "}
                        Click *Create* to make a new app. Enter app name,
                        redirect URL :
                        <span className="px-2 text-blue-500 text-sm py-0.5 rounded-lg bg-blue-100 inline-flex items-center ">
                          {brokerConnectRedirectURL}{" "}
                          <div
                            className="px-1  border-gray-700 flex-shrink-0"
                            data-tooltip-id="copy-tooltip-1"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 ml-1 cursor-pointer text-blue-500 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy(`${brokerConnectRedirectURL}`)
                              }
                            />
                          </div>
                        </span>
                        <Tooltip id="copy-tooltip-1" />
                      </div>
                    </div>
                    <span className="text-black font-normal  ml-5">
                      and description, then click *Create*.
                    </span>

                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>5.</div>{" "}
                      <div className="ml-2">
                        Copy the *API* and *Secret Key* and paste them into the{" "}
                        {whiteLabelText} platform to connect your broker.
                      </div>
                    </div>
                  </div>
                </div>
              ) : state.broker === "Kotak" ? (
                <div className="mt-6 px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                  <div className="mb-8 rounded-xl ">
                    <YouTube
                      videoId={`Qsv9hRR6eXw`}
                      className={`videoIframe rounded-xl `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>

                  <div
                    className={`mb-2 text-lg text-black font-semibold  leading-[22px]`}
                  >
                    Steps to get the API and Secret Key for Kotak:
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>1.</div>{" "}
                    <div className="ml-2">
                      {" "}
                      Visit Kotak Securities API Portal -
                      <a
                        href="https://napi.kotaksecurities.com/devportal/apis"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 text-[#0000FF]"
                      >
                        https://napi.kotaksecurities.com/devportal/apis
                      </a>
                      .
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>2.</div>{" "}
                    <div className="ml-2">
                      2. Sign in to your account or create one.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>3.</div>{" "}
                    <div className="ml-2">
                      After logging in, click on *Applications* in the header,
                      then click *Add New Application*. Fill in the required
                      details and save.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>4.</div>{" "}
                    <div className="ml-2">
                      In your newly created application, go to the
                      *Subscriptions* tab and subscribe to all listed APIs
                      (Brokerage, HSNEOAPI, Login, MasterscripFiles, Portfolio,
                      WebSocket).
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                    <div>5.</div>{" "}
                    <div className="ml-2">
                      5. Navigate to the *Production Keys* tab and click
                      *Generate Keys* to obtain your API & Secret Key.
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-6 px-0 sm:px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                  <div className="mb-8 rounded-xl ">
                    <YouTube
                      videoId={`XFLjL8hOctI`}
                      className={`videoIframe rounded-xl `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>

                  {/* <div
                    className={`mb-2 text-lg text-black font-semibold  leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for ICICI:
                  </div> */}
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-lg font-semibold">
                      Steps to Obtain API and Secret Key for {state.broker}:
                    </h2>
                    <button
                      onClick={toggleOpen}
                      className="focus:outline-none lg:hidden "
                    >
                      <div className="mr-4 mb-5">
                        {state.isOpen ? (
                          <ChevronUp size={20} strokeWidth={3} />
                        ) : (
                          <ChevronDown size={20} strokeWidth={3} />
                        )}
                      </div>
                    </button>
                  </div>
                  <div
                    className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                  >
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>1.</div>{" "}
                      <div className="ml-2 inline-flex items-center flex-wrap">
                        {" "}
                        Visit
                        {/* <div className="inline-flex items-center flex-wrap">
                        <a
                          href="https://api.icicidirect.com/apiuser/home"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-2 text-[#0000FF]"
                        >
                          https://api.icicidirect.com/apiuser/home
                        </a>
                        <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4  cursor-pointer"
                          onClick={() =>
                            handleCopy(
                              `https://api.icicidirect.com/apiuser/home`
                            )
                          }
                        />
                      </div> */}
                      </div>
                    </div>
                    <div className="inline-flex flex-col items-start ml-2 text-white font-normal ">
                      <div className="inline-flex items-center border  rounded-lg overflow-hidden ml-2  bg-blue-100">
                        <div className="pl-3 py-0.5 rounded-md overflow-x-auto text-sm ">
                          <a
                            href="https://api.icicidirect.com/apiuser/home"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500"
                          >
                            https://api.icicidirect.com/apiuser/home{" "}
                          </a>
                        </div>
                        <div
                          className="px-1 "
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content="COPY"
                        >
                          <ClipboardList
                            className="w-4 h-4 cursor-pointer text-blue-400 hover:text-white transition-colors"
                            onClick={() =>
                              handleCopy(
                                "https://api.icicidirect.com/apiuser/home"
                              )
                            }
                          />
                        </div>
                      </div>
                      <Tooltip id="copy-tooltip" />
                    </div>

                    <div className="fex flex-col ml-4 text-black font-normal ">
                      and log in using your username and password. Verify your
                      identity with the OTP and submit.
                    </div>

                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>2.</div>{" "}
                      <div className="ml-2 inline-flex items-center flex-wrap">
                        Click on the "Register an App" tab, then fill in the
                        "App Name" field with "{whiteLabelText}" or a name of
                        your choice. Enter the "Redirect URL" as
                        <span className="sm:pl-3 py-0.5 text-blue-500 rounded-lg text-sm bg-blue-100 inline-flex items-center">
                          {server.ccxtServer.baseUrl}icici/auth-callback/
                          {appURL}{" "}
                          {/* <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4 ml-2 cursor-pointer"
                          onClick={() =>
                            handleCopy(
                              `${server.ccxtServer.baseUrl}icici/auth-callback/${appURL}`
                            )
                          }
                        /> */}
                          <div
                            className="px-1  flex-shrink-0"
                            data-tooltip-id="copy-tooltip-1"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 cursor-pointer text-blue-400 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy(
                                  `${server.ccxtServer.baseUrl}icici/auth-callback/${appURL}`
                                )
                              }
                            />
                          </div>
                          <Tooltip id="copy-tooltip-1" />
                        </span>
                        and click "Submit". Please ensure that "redirect URL"
                        entered correctly as mentioned above.
                      </div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                      <div>3.</div>{" "}
                      <div className="ml-2">
                        Navigate to the "View Apps" tab and copy your API and
                        Secret Key- enter these details in the screen.
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-10 md:flex flex-col space-y-4 px-2 sm:px-12 ">
                <div className="text-2xl sm:text-3xl font-bold">
                  Connect {state.broker}
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-base sm:text-lg  font-semibold ">
                    Api Key :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      placeholder="Api Key"
                      type={state.showApiKey ? "text" : "password"}
                      value={state.apiKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          apiKey: e.target.value,
                        }))
                      }
                      className={`${style.inputBox}`}
                    />
                    {state.showApiKey ? (
                      <EyeIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showApiKey: false }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showApiKey: true }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    )}
                  </div>
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-base sm:text-lg font-semibold ">
                    Secret Key :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      placeholder="Secret Key"
                      type={state.showSecretKey ? "text" : "password"}
                      value={state.secretKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          secretKey: e.target.value,
                        }))
                      }
                      className={`${style.inputBox}`}
                    />
                    {state.showSecretKey ? (
                      <EyeIcon
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            showSecretKey: false,
                          }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showSecretKey: true }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.apiKey || !state.secretKey}
                    onClick={updateSecretKey}
                  >
                    {state.loading === true ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker === "Kotak" ? (
            <KotakConnection
              state={state}
              setState={setState}
              uid={uid}
              setBrokerModel={setBrokerModel}
              style={style}
              getUserDetails={getUserDetails}
              checkValidApiAnSecret={checkValidApiAnSecret}
              toggleOpen={toggleOpen}
              handleCopy={handleCopy}
              handlePlayerReady={handlePlayerReady}
              opts={opts}
              playerRef={playerRef}
            />
          ) : state.broker && !state.clientCode ? (
            <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8 no-scrollbar">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setState((prev) => ({ ...prev, broker: "", clientCode: "" }));
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex ">Back</div>
              </div>

              <div className="mt-4 px-0 sm:px-[28px]  md:h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                {/* <div
                  className={`mb-2 text-lg text-black font-semibold  leading-[22px]`}
                >
                  Step-by-Step Guide:
                </div> */}

                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold">
                    Step-by-Step Guide:{" "}
                  </h2>
                  <button
                    onClick={toggleOpen}
                    className="focus:outline-none lg:hidden "
                  >
                    <div className="mr-28 mb-0">
                      {state.isOpen ? (
                        <ChevronUp size={20} strokeWidth={3} />
                      ) : (
                        <ChevronDown size={20} strokeWidth={3} />
                      )}
                    </div>
                  </button>
                </div>
                <div
                  className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                >
                  <div className="text-lg mb-0.5 leading-[22px] md:text-base md:leading-[36px] text-black font-medium  ">
                    1. First enter your unique client code in the designated
                    field.
                  </div>
                </div>
              </div>

              <div className="mt-10 md:flex flex-col space-y-4 px-1 sm:px-12 ">
                <div className="text-[20px] font-semibold ">
                  Connect {state.broker}
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-[16px] font-semibold ">
                    Client Code :{" "}
                  </div>
                  <div className={style.inputStartDiv}>
                    <input
                      id="clientCode"
                      type="text"
                      placeholder="Client Code"
                      className={`${style.inputBox}`}
                      value={state.inputValue}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          inputValue: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    onClick={storeClientCode}
                    disabled={!state.inputValue}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker && state.clientCode ? (
            <div className="relative bg-[#ffffff] w-full md:min-w-[90vw] h-[560px] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    clientCode: "",
                    apiKey: "",
                    qrValue: "",
                  }));
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex ">Back</div>
              </div>

              <div className="mt-6 px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`mb-2 text-lg text-black font-semibold  leading-[22px]`}
                >
                  Step-by-Step Guide:
                </div>
                <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                  <div>&#x2022;</div>{" "}
                  <div className="ml-2">
                    {" "}
                    In the next field, enter your My2PIN, which is your date of
                    birth. Ensure you use the correct format as specified (e.g.,
                    YYYYMMDD).
                  </div>
                </div>
                <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal  ">
                  <div>&#x2022;</div>{" "}
                  <div className="ml-2">
                    Type in your account password. Remember, this password is
                    only used for verification purposes and is not stored in the
                    backend, hence you will be prompted for password again once
                    the password token validity expires.
                  </div>
                </div>
              </div>

              <div className="mt-10 md:flex flex-col space-y-4 px-12 ">
                <div className="text-3xl font-bold">Connect {state.broker}</div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold ">
                    My2Pin :{" "}
                  </div>
                  <div className={style.inputStartDiv}>
                    <input
                      type="number"
                      placeholder="Enter your my2pin"
                      className={`${style.inputBox}`}
                      value={state.apiKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          apiKey: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold ">
                    Password :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      id="pass"
                      placeholder="Password"
                      type={state.showPassword ? "text" : "password"}
                      value={state.password}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          password: e.target.value,
                        }))
                      }
                      className={`${style.inputBox}`}
                    />
                    {state.showPassword ? (
                      <EyeIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showPassword: false }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showPassword: true }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.apiKey || !state.password}
                    onClick={handleSubmit}
                  >
                    {state.loading === true ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConnectBroker;
