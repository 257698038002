import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { EyeIcon, EyeOffIcon, Mail } from "lucide-react";
import server from "../utils/serverConfig";
import { useNavigate } from "react-router-dom";
import { encryptApiKey } from "../utils/cryptoUtils";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
};

const SignInEmail = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [errorShow, setErrorShow] = useState(false);
  const [error, setError] = useState();

  const [loading, setLoading] = useState(false);

  const signInWithEmail = () => {
    setLoading(true);
    if (!email || !password) {
      setErrorShow(true);
      setError("Email and Password are required.");
      setLoading(false);
    } else {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((response) => {
          if (response) {
            axios
              .post(
                `${server.server.baseUrl}api/user/`,
                {
                  email: response.user.email,
                  name: response.user.email.split("@")[0],
                  firebaseId: response.user.uid,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "X-Advisor-Subdomain": process.env.REACT_APP_URL,
                    "aq-encrypted-key": encryptApiKey(
                      process.env.REACT_APP_AQ_KEYS,
                      process.env.REACT_APP_AQ_SECRET
                    ),
                  },
                }
              )
              .then((result) => {
                setLoading(false);
                const endLinks = ["/pricing", "/subscriptions"];
                navigate(
                  endLinks.includes(window.location.pathname)
                    ? window.location.pathname
                    : "/stock-recommendation"
                );
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          setErrorShow(true);
          setError("Invalid Credentials. Please Try again");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 3000);
    }
  }, [errorShow]);
  return (
    <div className="space-y-4 w-full bg-white relative rounded-lg  font-Poppins px-[20px] sm:px-[40px] lg:px-[50px]">
      <div className={style.inputStartDiv}>
        <input
          id="email"
          type="email"
          placeholder="Enter your email"
          className={`${style.inputBox}`}
          // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label
          htmlFor="email"
          className={`${style.firstHeading} ${style.labelFloat}`}
        >
          Enter your email
        </label>
      </div>
      <div className={style.inputStartDiv}>
        <input
          id="password"
          type={showPassword ? "text" : "password"}
          placeholder="Enter your password"
          className={`${style.inputBox}`}
          // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label
          htmlFor="password"
          className={`${style.firstHeading} ${style.labelFloat}`}
        >
          Enter your password
        </label>
        <div
          className="absolute top-[26px] right-0 flex items-center pr-3 cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <EyeOffIcon className="h-5 w-5 text-gray-500" />
          ) : (
            <EyeIcon className="h-5 w-5 text-gray-500" />
          )}
        </div>
      </div>

      <div className="w-full flex justify-start pb-2 mt-6 mb-2 ">
        <div
          className="ml-1 flex text-[15px] font-poppins flex-row items-center justify-center rounded-lg  text-[#000000]/60 font-medium cursor-pointer"
          onClick={() => props.setShowForgotPassord(true)}
        >
          Forgot your password?
        </div>
      </div>
      <div>
        {" "}
        <button
          onClick={signInWithEmail}
          disabled={!email || !password}
        className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed flex flex-row items-center justify-center py-2 w-full mb-3 rounded-lg bg-[#2056DF] text-white font-medium hover:bg-black/90 ease-linear duration-150"
        >
          <div className="ml-4 text-white font-medium text-lg font-poppins ">
            {loading === true ? (
              <span className="flex flex-row justify-center ">
                <svg
                  className="h-7 w-7 text-[#ffffff] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            ) : (
              <div className="flex flex-row items-center justify-center">
                <Mail size={25} className="mr-2" />
                Sign In with Email
              </div>
            )}
          </div>
        </button>
      </div>

      {errorShow === true ? (
        <div className="w-96 bg-white flex p-2 mt-2">
          <div className="text-[#D1433A] text-base font-bold -mt-0.5">
            {error ? error : ""}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SignInEmail;
