import React, { useState, useEffect } from "react";
import axios from "axios";
import { XIcon, TriangleAlert, Trash2 } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import server from "../../utils/serverConfig";
import { encryptApiKey } from "../../utils/cryptoUtils";

const style = {
  tableHead: "py-3 px-4 border-b text-left font-semibold text-gray-600 text-sm",
};

const StopLossProfitTarget = () => {
  const [allAlertNames, setAllAlertNames] = useState([]);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tabData, setTabData] = useState({ currentPage: 1, totalPages: 1 });

  const getData = () => {
    setLoading(true);
    axios
      .post(
        `${server.ccxtServer.baseUrl}misc/alert-list`,
        {
          advisor: process.env.REACT_APP_ADVISOR_SPECIFIC_TAG,
          page: tabData.currentPage, // Dynamically use the current page
          limit: 13,
          sortBy: "create_ts",
          ascending: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      )
      .then((response) => {
        setAllAlertNames(response?.data?.data?.alerts);
        setTabData({
          currentPage: response?.data?.data?.pagination?.currentPage || 1,
          totalPages: response?.data?.data?.pagination?.totalPages || 1,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
        setLoading(false);
      });
  };

  // Fetch data when currentPage changes
  useEffect(() => {
    getData();
  }, [tabData.currentPage]);

  // Pagination Controls
  const getPageNumbers = () => {
    const pages = [];
    const { currentPage, totalPages } = tabData;
    const totalVisible = 10;

    if (totalPages <= totalVisible) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pages.push(1, "...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) pages.push("...", totalPages);
    }

    return pages;
  };

  const goToPage = (page) => {
    if (page > 0 && page <= tabData.totalPages) {
      setTabData((prev) => ({ ...prev, currentPage: page }));
    }
  };

  const fetchAlertDetails = (alertName) => {
    setSelectedAlert(alertName);
    axios
      .post(
        `${server.ccxtServer.baseUrl}misc/alert-docs`,
        {
          advisor: process.env.REACT_APP_ADVISOR_SPECIFIC_TAG,
          alertName: alertName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      )
      .then((response) => {
        setModalData(response?.data?.alertDocs);
        setIsModalOpen(true); // Open modal after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching alert details", error);
      });
  };

  const DeletePriceAlert = (ele) => {
    axios
      .post(
        `${server.ccxtServer.baseUrl}misc/delete-price-alert`,
        {
          advisor: process.env.REACT_APP_ADVISOR_SPECIFIC_TAG,
          alertName: ele?.alertName,
          alertId: ele?.alertId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      )
      .then((response) => {
        getData();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error updating Stop Loss & Profit Target", error);
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col relative  w-full max-h-[calc(100vh-60px)] md:min-h-screen  bg-white">
      <div className="flex flex-col  w-full min-h-screen  bg-white ">
        {/* Header */}
        <div className="flex flex-row bg-[#2056DF] text-white px-6 py-4 w-full justify-between">
          <div>
            <h2 className="text-xl font-semibold mb-2">
              Alert Advice Management System
            </h2>
            <p className="text-blue-100 text-sm">
              Track and manage investment recommendations across multiple
              channels. Monitor delivery status, resend communications, and
              ensure all clients receive timely updates.
            </p>
          </div>
          {/*<button*/}
          {/*  // onClick={() => setAlertModal(true)}*/}
          {/*  className="px-4 py-1 bg-yellow-500 h-[40px] text-white rounded-md  hover:bg-yellow-600"*/}
          {/*>*/}
          {/*  Alert Me*/}
          {/*</button>*/}
        </div>
        {loading === true ? (
          <div className="px-12 py-2 bg-gray-50 hover:bg-gray-50 my-2">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="mb-2">
                <Skeleton width={180} height={25} />
              </div>
            ))}
          </div>
        ) : allAlertNames?.length !== 0 ? (
          <div className="overflow-auto">
            {allAlertNames?.map((ele, i) => {
              return (
                <div
                  key={i}
                  className="px-12 flex flex-row justify-between py-2 bg-gray-50 hover:bg-gray-50 my-2 items-center"
                >
                  <div
                    className="text-base font-medium cursor-pointer"
                    onClick={() => fetchAlertDetails(ele.alertName)}
                  >
                    {ele.alertName}
                  </div>

                  <div>
                    <Trash2
                      className="text-red-500 cursor-pointer"
                      onClick={() => DeletePriceAlert(ele)}
                    />
                  </div>
                </div>
              );
            })}
            {tabData.totalPages > 1 && (
              <div className="mt-6 flex justify-center items-center pb-3 space-x-1">
                <button
                  onClick={() => goToPage(tabData.currentPage - 1)}
                  disabled={tabData.currentPage === 1}
                  className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
                >
                  Previous
                </button>
                {getPageNumbers().map((page, index) =>
                  page === "..." ? (
                    <span key={index} className="px-3 py-1 text-gray-600">
                      ...
                    </span>
                  ) : (
                    <button
                      key={index}
                      onClick={() => goToPage(page)}
                      className={`px-3 py-1 text-sm rounded ${
                        page === tabData.currentPage
                          ? "bg-blue-500 text-white"
                          : "bg-gray-300"
                      }`}
                    >
                      {page}
                    </button>
                  )
                )}
                <button
                  onClick={() => goToPage(tabData.currentPage + 1)}
                  disabled={tabData.currentPage === tabData.totalPages}
                  className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col space-y-4 lg:space-y-6 lg:pt-[20px] items-center justify-center h-[calc(100vh-240px)] lg:h-full">
            <div className="flex items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
              <TriangleAlert className="w-[50px] h-[50px] lg:w-[60px] lg:h-[60px]" />
            </div>
            <div className="flex flex-col space-y-3 lg:space-y-3 items-center">
              <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                No Alert Available
              </div>
              <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal  px-[10px] lg:px-[60px]">
                No alerts have been set by you yet
              </div>
            </div>
          </div>
        )}

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">
                  Details for {selectedAlert}
                </h2>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="p-1 hover:bg-gray-100 rounded"
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>
              <div className="h-full overflow-y-auto">
                <table className="w-full">
                  <thead className="bg-gray-100 sticky top-0 z-10">
                    <tr>
                      <th className={style.tableHead}>Symbol</th>
                      <th className={style.tableHead}>Exchange</th>
                      <th className={style.tableHead}>Status</th>
                      <th className={style.tableHead}>Stop Loss</th>
                      <th className={style.tableHead}>Profit Target</th>
                    </tr>
                  </thead>
                  {modalData ? (
                    <tbody className="bg-white divide-y divide-gray-200">
                      {modalData?.map((ele, i) => {
                        return (
                          <tr
                            key={i}
                            className={` ${"bg-white hover:bg-gray-50"}`}
                          >
                            <td className="py-3 px-4 border-b ">
                              {ele?.stock}
                            </td>
                            <td className="py-3 px-4 border-b ">
                              {ele?.exchange}
                            </td>
                            <td className="py-3 px-4 border-b  capitalize">
                              {ele?.state}
                            </td>
                            <td className="py-3 px-4 border-b ">
                              {ele?.priceMovement === "ltp_crossing_down"
                                ? ele?.priceLevel
                                : ele?.price2Movement === "ltp_crossing_down"
                                ? ele?.price2Level
                                : "-"}
                            </td>
                            <td className="py-3 px-4 border-b ">
                              {ele?.priceMovement === "ltp_crossing_up"
                                ? ele?.priceLevel
                                : ele?.price1Movement === "ltp_crossing_up"
                                ? ele?.price1Level
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <p>Loading...</p>
                  )}
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StopLossProfitTarget;
