import * as React from "react";
import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";
import { XIcon } from "lucide-react";

export function TokenExpireModal({
  children,
  openTokenExpireModel,
  setOpenTokenExpireModel,
}) {
  const isDesktop = useMediaQuery("(min-width: 830px)");

  if (isDesktop) {
    return (
      <Dialog
        open={openTokenExpireModel}
        onOpenChange={setOpenTokenExpireModel}
      >
        <DialogContent className=" max-w-[520px] px-[40px] animate-modal">
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={openTokenExpireModel} onOpenChange={setOpenTokenExpireModel}>
      <DrawerContent className="focus:outline-none max-h-[500px] md:min-h-[300px] w-full animate-modal">
        <div
          onClick={() => setOpenTokenExpireModel(false)}
          className="absolute top-4 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>
        <div className="p-4 h-full w-full overflow-y-auto">{children}</div>
      </DrawerContent>
    </Drawer>
  );
}
