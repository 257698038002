"use client";

import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ChevronDownIcon, ChevronUpIcon, LineChart } from "lucide-react";
import server from "../../utils/serverConfig";
import { encryptApiKey } from "../../utils/cryptoUtils";
import { Chart, registerables } from "chart.js";

import { ModifyInvestmentModal } from "../Strategy/ModifyInvestmentModal";

Chart.register(...registerables);

const Card = forwardRef(
  (
    { ele, handlePricingCardClick, globalConsent, handleGlobalConsent },
    ref
  ) => {
    const navigate = useNavigate();
    const { headerRef, statsRef, infoRef, buttonRef } = ref;

    const [isConsentPopupOpen, setIsConsentPopupOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [selectedPricing, setSelectedPricing] = useState("monthly");
    const [showPerformancePopup, setShowPerformancePopup] = useState(false);
    const [modifyInvestmentModal, setModifyInvestmentModal] = useState(false);

    const handleCAGRClick = (e) => {
      e.stopPropagation();
      if (!globalConsent) {
        setIsConsentPopupOpen(true);
      }
    };

    const handleConsentAccept = () => {
      handleGlobalConsent();
      setIsConsentPopupOpen(false);
    };

    // const handleCardClick = () => {
    //   if (!ele?.subscription) {
    //     handlePricingCardClick(ele);
    //   }
    // };
    const handleCardClick = () => {
      // Updated handleCardClick function
      if (!ele?.subscription) {
        handlePricingCardClick(ele);
      }
      setShowPerformancePopup(false);
    };
    // Calculate months from days
    const calculateMonths = (days) => {
      if (!days) return 12;
      return Math.round(days / 30);
    };

    // Get available pricing options
    const getPricingOptions = () => {
      if (ele?.amount) {
        return [
          {
            label: `${calculateMonths(ele.duration)} months`,
            value: ele.amount,
          },
        ];
      }

      const options = [];
      if (ele?.pricing?.monthly)
        options.push({
          period: "monthly",
          label: "Monthly",
          value: ele.pricing.monthly,
        });
      if (ele?.pricing?.quarterly)
        options.push({
          period: "quarterly",
          label: "Quarterly",
          value: ele.pricing.quarterly,
        });
      if (ele?.pricing?.["half-yearly"])
        options.push({
          period: "half-yearly",
          label: "6 Months",
          value: ele.pricing["half-yearly"],
        });
      if (ele?.pricing?.yearly)
        options.push({
          period: "yearly",
          label: "Yearly",
          value: ele.pricing.yearly,
        });

      return options;
    };

    const pricingOptions = getPricingOptions();
    const currentPrice =
      ele?.amount ||
      pricingOptions.find((opt) => opt.period === selectedPricing)?.value ||
      0;
    const originalPrice = currentPrice ? Math.round(currentPrice / 0.81) : 0;
    const discount =
      originalPrice && currentPrice
        ? Math.round(((originalPrice - currentPrice) / originalPrice) * 100)
        : 0;
    const descriptionLines = ele?.description
      ?.split("-")
      ?.map((point) => point.trim());

    const handleClosePerformance = () => {
      setShowPerformancePopup(false);
    };
    const handlePerformanceClick = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setShowPerformancePopup(true);
    };

    const handlePopupClick = (event) => {
      event.stopPropagation();
    };
    const PerformancePopup = ({ isOpen, onClose, children }) => {
      if (!isOpen) return null;

      return (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="bg-white rounded-lg p-4"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {children}
          </div>
        </div>
      );
    };
    const IndexSelector = ({ selectedIndex, onIndexChange }) => {
      const indices = [{ symbol: "^NSEI", name: "Nifty 50" }];

      return (
        <div className="mb-4 flex justify-end items-center">
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Compare with Index
            </label>
            <select
              value={selectedIndex}
              onChange={(e) => onIndexChange(e.target.value)}
              className="mt-1 block w-[200px] border-2 border-gray-300 pl-3 pr-10 py-2 text-base 
            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
            sm:text-sm rounded-md shadow-sm hover:border-gray-400"
            >
              {indices?.map((index) => (
                <option
                  key={index.symbol}
                  value={index.symbol}
                  className="border-b border-gray-200 py-2"
                >
                  {index.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    };

    const PerformanceChart = ({ modelName }) => {
      const chartRef = useRef(null);
      const [selectedIndex, setSelectedIndex] = useState("^NSEI");
      const [portfolioData, setPortfolioData] = useState([]);
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState(null);

      const fetchIndexData = async () => {
        try {
          const endDate = new Date().toISOString().split("T")[0];
          const startDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)
            .toISOString()
            .split("T")[0];

          const response = await fetch(
            `${server.ccxtServer.baseUrl}misc/data-fetcher?symbol=${selectedIndex}&start_date=${startDate}&end_date=${endDate}`,
            {
              headers: {
                "X-Advisor-Subdomain": process.env.REACT_APP_URL,
                "aq-encrypted-key": encryptApiKey(
                  process.env.REACT_APP_AQ_KEYS,
                  process.env.REACT_APP_AQ_SECRET
                ),
              },
            }
          );
          const data = await response.json();
          return data.data;
        } catch (error) {
          console.error("Error fetching index data:", error);
          return [];
        }
      };

      const fetchPortfolioData = async () => {
        try {
          const response = await fetch(
            `${server.ccxtServer.baseUrl}rebalance/get-portfolio-performance`,
            {
              method: "POST",
              body: JSON.stringify({ advisor: "AlphaQuarkTest", modelName }),

              headers: {
                "Content-Type": "application/json",
                "X-Advisor-Subdomain": process.env.REACT_APP_URL,
                "aq-encrypted-key": encryptApiKey(
                  process.env.REACT_APP_AQ_KEYS,
                  process.env.REACT_APP_AQ_SECRET
                ),
              },
            }
          );
          if (!response.ok) {
            console.error(`API error: ${response.status}`);
            return [];
          }

          const data = await response.json();

          if (
            data.status === 0 &&
            data.message === "No performance data found."
          ) {
            console.info("No performance data available for this portfolio");
            return [];
          }

          return data.data || [];
        } catch (error) {
          console.error("Error fetching portfolio data:", error);
          return [];
        }
      };
      useEffect(() => {
        const loadData = async () => {
          setLoading(true);
          const [portfolio, indexData] = await Promise.all([
            fetchPortfolioData(),
            fetchIndexData(),
          ]);

          // Find earliest common date
          const portfolioDates = portfolio?.map(
            (p) => new Date(p.date).toISOString().split("T")[0]
          );
          const indexDates = indexData?.map(
            (n) => new Date(n.Date).toISOString().split("T")[0]
          );
          const startDate = portfolioDates[0];
          console.log("indexData0", indexData);
          // Get initial values
          const firstPortfolioValue =
            portfolio?.find(
              (p) => new Date(p.date).toISOString().split("T")[0] === startDate
            )?.value || 100;

          const firstIndexValue =
            indexData?.find(
              (n) => new Date(n.Date).toISOString().split("T")[0] === startDate
            )?.Close || 100;
          const alignedData = portfolio
            ?.map((p) => {
              const pDate = new Date(p.date).toISOString().split("T")[0];
              const matchingIndex = indexData?.find(
                (n) => new Date(n.Date).toISOString().split("T")[0] === pDate
              );

              return {
                date: pDate,
                portfolioValue: (p.value / firstPortfolioValue) * 100,
                indexValue: matchingIndex
                  ? (matchingIndex.Close / firstIndexValue) * 100
                  : null,
                actualIndexValue: matchingIndex?.Close || null,
              };
            })
            .filter((d) => d.indexValue !== null);

          console.log(
            "Compared Performance Data:",
            alignedData?.map((d) => ({
              date: d.date,
              portfolioValue: d.portfolioValue,
              niftyValue: d.indexValue,
              actualNiftyValue: d.actualIndexValue,
            }))
          );
          const sortedData = [...alignedData].sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
          setPortfolioData(sortedData);
          setLoading(false);
        };

        loadData();
      }, [selectedIndex, modelName]);

      useEffect(() => {
        if (!portfolioData.length) return;
        const ctx = chartRef.current?.getContext("2d");
        if (!ctx) return;

        const portfolioValues = portfolioData.map((d) => d.portfolioValue);
        const indexValues = portfolioData.map((d) => d.actualIndexValue);

        const normalizedPortfolioData = portfolioValues.map((value, index) =>
          index === 0 ? 100 : (value / portfolioValues[0]) * 100
        );

        const normalizedNiftyData = indexValues.map((value, index) =>
          index === 0 ? 100 : (value / indexValues[0]) * 100
        );

        const chart = new Chart(ctx, {
          type: "line",
          data: {
            labels: portfolioData?.map((d) =>
              new Date(d.date).toLocaleDateString()
            ),
            datasets: [
              {
                label: "Portfolio Performance",
                data: normalizedPortfolioData,
                borderColor: "#0070D0",
                backgroundColor: "rgba(0, 112, 208, 0.1)",
                yAxisID: "y",
                fill: true,
              },
              {
                label: "Nifty 50",
                data: normalizedNiftyData,
                borderColor: "#FF6B6B",
                yAxisID: "y",
                originalData: portfolioData.map(
                  (item) => item.actualIndexValue
                ),
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            interaction: {
              mode: "index",
              intersect: false,
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const label = context.dataset.label || "";

                    if (label === "Nifty 50") {
                      const normalizedValue = context.raw;
                      const originalValue =
                        context.dataset.originalData[context.dataIndex];
                      return `${label}: ${normalizedValue.toFixed(
                        2
                      )} (${originalValue.toFixed(2)})`;
                    } else {
                      return `${label}: ${context.formattedValue}`;
                    }
                  },
                },
              },
            },
            scales: {
              y: {
                display: true,
                beginAtZero: false,
                title: {
                  display: true,
                  text: "Performance (Base 100)",
                },
                min: (context) => {
                  const values = context.chart.data.datasets.flatMap(
                    (dataset) => dataset.data
                  );
                  const minValue = Math.min(
                    ...values.filter((v) => v !== null && v !== undefined)
                  );
                  return Math.min(Math.floor(minValue * 0.95), 90);
                },
                max: (context) => {
                  const values = context.chart.data.datasets.flatMap(
                    (dataset) => dataset.data
                  );
                  const maxValue = Math.max(
                    ...values.filter((v) => v !== null && v !== undefined)
                  );
                  return Math.max(Math.ceil(maxValue * 1.05), 102);
                },
              },
              x: {
                display: true,
              },
            },
          },
        });

        return () => chart.destroy();
      }, [portfolioData]);

      if (error) {
        return (
          <div className="flex items-center justify-center text-gray-500">
            {error}
          </div>
        );
      }

      if (loading) {
        return (
          <div className="flex items-center justify-center h-64">
            <div className="w-20 h-20 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
          </div>
        );
      }

      if (!portfolioData.length) {
        return (
          <div className="flex items-center justify-center h-60 text-black text-2xl font-semibold">
            No data available to display
          </div>
        );
      }

      return (
        <div className="w-full">
          {portfolioData.length > 0 ? (
            <>
              <IndexSelector
                selectedIndex={selectedIndex}
                onIndexChange={setSelectedIndex}
              />
              <div style={{ height: "600px" }}>
                <canvas ref={chartRef} />
              </div>
            </>
          ) : (
            <div
              style={{ height: "400px" }}
              className="flex flex-col items-center justify-center"
            >
              <svg
                className="w-16 h-16 text-gray-300 mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p className="text-gray-500 text-center font-medium">
                {error || "No performance data available for this portfolio"}
              </p>
              <p className="text-gray-400 text-sm text-center mt-2">
                Performance data will appear as the portfolio matures
              </p>
            </div>
          )}
        </div>
      );
    };

    const handleViewCardClick = (modelName) => {
      navigate(
        `/model-portfolio/subscribed/${modelName
          .toLowerCase()
          .replace(/ /g, "_")}`
      );
    };
    return (
      <div className="w-[360px] bg-white rounded-xl shadow-lg overflow-hidden flex flex-col h-full">
        {/* Header with Price */}
        <div
          ref={headerRef}
          className="p-6 cursor-pointer hover:bg-gray-50 transition-colors flex-grow"
          onClick={handleCardClick}
        >
          <div className="flex items-baseline gap-2 mb-4">
            <h2 className="text-2xl font-bold text-gray-900">{ele?.name}</h2>
          </div>

          {/* Pricing Options */}
          {pricingOptions.length > 1 ? (
            <div className="mb-4 h-[50px]">
              <div className="flex gap-2 mb-3">
                {pricingOptions?.map((option) => (
                  <button
                    key={option.period}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedPricing(option.period);
                    }}
                    className={`px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
                      selectedPricing === option.period
                        ? "bg-blue-100 text-blue-600"
                        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
              <div className="flex items-baseline gap-2">
                <span className="text-lg text-gray-500 line-through">
                  ₹{originalPrice?.toLocaleString()}
                </span>
                <span className="text-3xl font-bold">
                  ₹{currentPrice?.toLocaleString()}
                </span>
                <span className="text-sm font-medium text-green-600 bg-green-50 px-2 py-1 rounded">
                  Save {discount}%
                </span>
              </div>
            </div>
          ) : (
            <div className="flex items-baseline gap-2 mb-6">
              <span className="text-lg text-gray-500 line-through">
                ₹{originalPrice?.toLocaleString()}
              </span>
              <span className="text-3xl font-bold">
                ₹{currentPrice?.toLocaleString()}
              </span>
              <span className="text-sm font-medium text-green-600 bg-green-50 px-2 py-1 rounded">
                Save {discount}%
              </span>
            </div>
          )}

          {/* Stats Section */}
          <div
            ref={statsRef}
            className="grid grid-cols-3 gap-4 mb-6 p-4 bg-gray-50 rounded-lg"
          >
            <div>
              <div className="text-sm text-gray-500">CAGR</div>
              <p
                className={`text-sm font-semibold text-green-600 ${
                  !globalConsent ? "blur-sm" : ""
                } cursor-pointer`}
                onClick={handleCAGRClick}
              >
                New Portfolio
              </p>
            </div>
            <div>
              <div className="text-sm text-gray-500">2Y Returns</div>
              <p
                className={`text-sm font-semibold text-green-600 ${
                  !globalConsent ? "blur-sm" : ""
                } cursor-pointer`}
                onClick={handleCAGRClick}
              >
                New Portfolio
              </p>
            </div>
            <div>
              <div className="text-sm text-gray-500">Min. Investment</div>
              <p className="text-sm font-semibold">
                ₹{ele?.minInvestment?.toLocaleString()}
              </p>
            </div>
          </div>
          <button
            onClick={handlePerformanceClick}
            className="flex text-left items-center mb-1  font-semibold  text-[#2056DF] text-sm hover:text-blue-700"
          >
            <span className="flex gap-1">
              <LineChart className=" font-semibold" size={16} />
              <span>View Performance</span>
            </span>
          </button>
          {showPerformancePopup && (
            <div
              onClick={handlePopupClick}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <div className="bg-white p-6 rounded-lg shadow-xl max-w-[900px] w-full max-h-[90vh] ">
                <div className="flex justify-between mb-4">
                  <h3 className="text-lg font-semibold">
                    Model portfolio performance <br /> ({ele?.name})
                  </h3>
                  <button
                    onClick={handleClosePerformance}
                    className="cursor-pointer text-gray-500 hover:text-gray-700"
                  >
                    ✕
                  </button>
                </div>

                <div className="mt-4" style={{ height: "700px" }}>
                  <PerformanceChart modelName={ele?.name} />
                </div>
              </div>
            </div>
          )}
          {/* Description List */}
          <div className="mb-6 h-[80px] overflow-auto">
            <ul className="space-y-2">
              {descriptionLines
                ?.slice(0, showMore ? descriptionLines.length : 7)
                ?.map((point, index) => (
                  <li
                    key={index}
                    className="text-gray-600 flex items-start gap-2"
                  >
                    <span className="text-gray-400 mt-1">-</span>
                    <span>{point}</span>
                  </li>
                ))}
            </ul>
            {descriptionLines?.length > 7 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMore(!showMore);
                }}
                className="mt-2 text-blue-600 text-sm font-medium hover:underline"
              >
                {showMore ? "Show Less" : "Show More"}
              </button>
            )}
          </div>

          {/* Expandable Content */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
            className="w-full flex items-center justify-center py-2 text-sm text-gray-600 hover:bg-gray-50"
          >
            {isExpanded ? (
              <>
                Show Less <ChevronUpIcon className="ml-1 w-4 h-4" />
              </>
            ) : (
              <>
                Show More <ChevronDownIcon className="ml-1 w-4 h-4" />
              </>
            )}
          </button>

          {isExpanded && (
            <div className="mt-4 space-y-6">
              <div>
                <h3 className="text-sm font-semibold mb-2">Key Features</h3>
                <ul className="space-y-2">
                  <li className="text-gray-600 flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                    Professionally managed portfolio
                  </li>
                  <li className="text-gray-600 flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                    Regular portfolio rebalancing
                  </li>
                  <li className="text-gray-600 flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                    Optimal stock allocation and monitoring
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-sm font-semibold mb-2">
                  Additional Benefits
                </h3>
                <p className="text-sm text-gray-600">
                  Get alerts via email/WhatsApp and update portfolio in your
                  broker with a mouse click.
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Action Button */}
        <div
          ref={buttonRef}
          className="p-6 bg-gray-50 flex flex-row gap-1  mt-auto"
        >
          <button
            onClick={() => handlePricingCardClick(ele)}
            className="w-full py-3 px-4 bg-[#2056DF] text-white text-sm font-semibold rounded-lg hover:bg-blue-700 transition-colors"
          >
            Invest Now
          </button>
          {ele?.subscription && (
            <button
              onClick={() => handleViewCardClick(ele?.name)}
              className="w-full py-3 px-4 bg-green-600 text-white text-sm font-semibold rounded-lg hover:bg-green-700 transition-colors"
            >
              View
            </button>
          )}
        </div>

        {/* Consent Popup */}
        {isConsentPopupOpen && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-40 z-50" />
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-xl font-bold mb-4">Consent Required</h2>
                <p className="mb-6">
                  By clicking to view performance, I understand that historical
                  performance data is NOT a promise of future returns. For new
                  portfolio, data would be limited and will not show CAGR.
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
                    onClick={() => setIsConsentPopupOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-[#2056DF] text-white rounded hover:bg-blue-700 transition-colors"
                    onClick={handleConsentAccept}
                  >
                    I Agree
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {modifyInvestmentModal && (
          <ModifyInvestmentModal
            modifyInvestmentModal={modifyInvestmentModal}
            setModifyInvestmentModal={setModifyInvestmentModal}
            strategyDetails={ele}
            userEmail={ele.userEmail}
            getStrategyDetails={() => {}}
            amount={ele.amount}
            latestRebalance={ele.latestRebalance}
            userBroker={ele.userBroker}
          />
        )}
      </div>
    );
  }
);

const ModelPFCardDetailsSection = ({
  allPricingPlan = [],
  handlePricingCardClick,
}) => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [globalConsent, setGlobalConsent] = useState(false);
  // Comment: Changed rowRefs to cardRefs
  const cardRefs = useRef([]);

  const handleGlobalConsent = () => {
    setGlobalConsent(true);
  };

  useEffect(() => {
    if (allPricingPlan.length > 0) {
      setPricingPlans(allPricingPlan);
      setIsLoading(false);
      setShowSkeleton(false); // Stop showing the skeleton when data is available
    } else {
      const timer = setTimeout(() => {
        setShowSkeleton(false); // Hide skeleton after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [allPricingPlan]);

  useEffect(() => {
    if (!isLoading) {
      adjustCardHeights();
    }
  }, [isLoading, pricingPlans]);

  // Comment: Updated adjustCardHeights function
  const adjustCardHeights = () => {
    const rows = [];
    let currentRow = [];
    cardRefs.current.forEach((card, index) => {
      if (card) {
        currentRow.push(card);
        if ((index + 1) % 3 === 0 || index === cardRefs.current.length - 1) {
          rows.push(currentRow);
          currentRow = [];
        }
      }
    });

    rows.forEach((row) => {
      const sections = ["headerRef", "statsRef", "infoRef", "buttonRef"];
      sections.forEach((section) => {
        const maxHeight = Math.max(
          ...row?.map((card) => card[section]?.offsetHeight || 0)
        );
        row.forEach((card) => {
          if (card[section]) {
            card[section].style.height = `${maxHeight}px`;
          }
        });
      });
    });
  };

  const SkeletonCard = () => (
    <div className="w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] max-w-[350px] relative  min-h-[200px] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4">
      <div className="flex items-start pl-7 pt-2 pb-7 md:items-center">
        <div className="overflow-hidden rounded-lg">
          <Skeleton width={128} height={25} />
          <Skeleton width={100} height={30} />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-[80%] border-t-[1px] border-b-[1px] border-[#000000]/10"></div>
      </div>
      <div className="flex flex-col items-center pt-7 p-4 space-y-4 w-90">
        <div className=" text-[12.5px] min-h-[220px] flex flex-col items-left justify-start space-y-2 text-[#666666]">
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-center align">
          <Skeleton width={280} height={44} />
        </div>
      </div>
    </div>
  );

  if (!showSkeleton && allPricingPlan.length === 0) {
    return null; // Return null after 3 seconds if no data
  }
  return (
    <div className="pb-32">
      <div className="mx-auto px-4 sm:px-20 pt-10 md:pt-14 lg:pt-14">
        <div className="flex flex-wrap justify-center gap-6">
          {isLoading
            ? Array(3)
                .fill(0)
                ?.map((_, i) => <SkeletonCard key={i} />)
            : pricingPlans?.map((ele, i) => (
                // Comment: Added ref to Card component
                <Card
                  key={i}
                  ele={ele}
                  handlePricingCardClick={handlePricingCardClick}
                  globalConsent={globalConsent}
                  handleGlobalConsent={handleGlobalConsent}
                  ref={(el) => {
                    cardRefs.current[i] = el;
                  }}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default ModelPFCardDetailsSection;
