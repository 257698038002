import React from "react";
import { useNavigate } from "react-router-dom";

import MPF_1 from "../../assests/mpf_1.png";
import { ChevronRightIcon } from "lucide-react";

const ModalPFCard = ({ modalPFDark, modelName, repair }) => {
  const navigate = useNavigate();

  const handleCardClick = (modelName) => {
    navigate(
      `/model-portfolio/subscribed/${modelName
        .toLowerCase()
        .replace(/ /g, "_")}`
    );
  };
  return (
    <div
      className="md:bg-[#000000]/10 md:hover:bg-[#000000]/20 w-full md:shadow-[3px_5px_20px_0px_rgba(0,0,0,0.06)] px-0 md:px-8 lg:px-10
    "
    >
      <div className="hidden lg:grid md:grid grid-cols-7 gap-4 overflow-y-scroll no-scrollbar py-5">
        <div className="flex items-center space-x-4 col-span-2">
          <img src={MPF_1} alt="Portfolio" className="w-12 h-10" />
          <div className="flex flex-col">
            <div className="text-white font-medium font-poppins text-sm sm:text-base capitalize">
              {modelName}
            </div>
            {repair && (
              <div className="w-[55px] flex items-center justify-center font-medium font-poppins rounded-[3px] bg-[#DE8846] text-[10px] text-[#ffffff]">
                Repair
                <ChevronRightIcon className="text-white h-3 w-3" />
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-white lg:pl-10 font-medium font-poppins text-sm sm:text-base">
            ₹7,50,000
          </p>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-white lg:pl-9 font-medium font-poppins text-sm sm:text-base">
            ₹16,00,000
          </p>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-white lg:pl-20 font-medium font-poppins text-sm sm:text-base">
            120%
          </p>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-[#7CFA8B] lg:pl-12 font-semibold font-poppins text-sm sm:text-base">
            +25%
          </p>
        </div>
        <div className="flex items-center  lg:pl-12">
          <button
            className="text-white border border-[#ffffff]/40 rounded-[4px] px-4 py-1 font-medium font-poppins text-sm sm:text-base cursor-pointer"
            onClick={() => handleCardClick(modelName)}
          >
            View
          </button>
        </div>
      </div>

      <div
        className="md:hidden grid grid-cols-2 py-2 px-3  w-full "
        onClick={() => handleCardClick(modelName)}
      >
        <div className="flex items-center ">
          <div className="text-white font-poppins text-sm capitalize">
            <div className="flex space-x-1 col-span-2 ">
              <img src={MPF_1} alt="Portfolio" className="w-[40px] h-[38px]" />
              <div className="flex flex-col pl-1">
                <span className="text-[13px] text-white font-normal">
                  {modelName}
                </span>
                {repair && (
                  <div className="w-[55px] flex items-center justify-center font-medium font-poppins rounded-[3px] bg-[#DE8846] text-[10px] text-[#ffffff]">
                    Repair
                    <ChevronRightIcon className="text-white h-3 w-3" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-end pt-1.5 leading-5">
            <span className="text-[#57FB6D] font-medium font-poppins text-[13px] ">
              ₹16,00,000
            </span>
            <p className="text-[#57FB6D] font-medium font-poppins text-[13px]">
              (+120%)
            </p>
          </div>

          <div className="flex  justify-end leading-6">
            <span className="text-[#FFFFFF] font-normal font-poppins text-[13px] ">
              ₹7,50,000
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPFCard;
