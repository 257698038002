import React from "react";
import { Building2, TrendingUp, TrendingDown } from "lucide-react";

const HoldingCard = ({ qty, avgPrice, symbol, pnl, totalInvested, ltp }) => {
  const percentageChange = pnl ? (pnl / totalInvested) * 100 : 0;

  return (
    <div className="bg-white border-b border-gray-200 p-4">
      {/* Header Row */}
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-500">Qty.</span>
            <span className="text-sm font-medium text-gray-900">{qty}</span>
          </div>
          <div className="h-4 w-px bg-gray-200" />
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-500">Avg.</span>
            <span className="text-sm font-medium text-gray-900">₹{avgPrice}</span>
          </div>
        </div>

        <div className="flex items-center space-x-1">
          {percentageChange > 0 ? (
            <TrendingUp className="w-4 h-4 text-emerald-600" />
          ) : percentageChange < 0 ? (
            <TrendingDown className="w-4 h-4 text-red-600" />
          ) : null}
          <span className={`text-sm font-medium ${
            percentageChange > 0 
              ? "text-emerald-600" 
              : percentageChange < 0 
                ? "text-red-600" 
                : "text-gray-600"
          }`}>
            {percentageChange > 0
              ? `+${Math.abs(percentageChange).toFixed(2)}%`
              : percentageChange < 0
                ? `-${Math.abs(percentageChange).toFixed(2)}%`
                : "-"}
          </span>
        </div>
      </div>

      {/* Stock Info Row */}
      <div className="flex items-center justify-between mb-3">
        <span className="text-base font-medium text-gray-900">{symbol}</span>
        <span className={`text-sm font-medium ${
          pnl > 0 
            ? "text-emerald-600" 
            : pnl < 0 
              ? "text-red-600" 
              : "text-gray-900"
        }`}>
          {pnl > 0
            ? `+₹${pnl.toFixed(2)}`
            : pnl < 0
              ? `-₹${Math.abs(pnl.toFixed(2))}`
              : "₹0.00"}
        </span>
      </div>

      {/* Footer Row */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-500">Invested</span>
            <span className="text-sm font-medium text-gray-900">
              ₹{totalInvested ? totalInvested.toFixed(2) : "0.00"}
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">LTP</span>
          <span className="text-sm font-medium text-gray-900">
            ₹{ltp ? ltp.toFixed(2) : "0.00"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HoldingCard;