// src/components/ui/tooltip.jsx
import React from 'react';

export const Tooltip = ({ children }) => (
  <div className="relative group">
    {children}
  </div>
);

export const TooltipTrigger = ({ children, className }) => (
  <div className={`cursor-pointer ${className}`}>
    {children}
  </div>
);

export const TooltipContent = ({ children }) => (
  <div className="absolute z-10 hidden group-hover:block p-2 bg-gray-800 text-white text-xs rounded-md -translate-x-1/2 left-1/2 transform -bottom-8">
    {children}
  </div>
);

export const TooltipProvider = ({ children }) => (
  <div>
    {children}
  </div>
);
