import React, { useState, useEffect } from "react";
import PlanCard from "./PlanCard";
import AddPlanModal from "./AddPlanModal";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import server from "../../utils/serverConfig";
import AddNewStrategyModal from "../StrategySection/AddNewStrategyModal";
import FormatDateTime from "../../utils/formatDateTime";
import { motion, AnimatePresence } from "framer-motion";

const style = {
  selected:
    "flex items-center text-[18px]  font-sans text-black font-bold lg:leading-[42px] cursor-pointer",
  unselected:
    "flex items-center text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const PlanList = ({ advisorName, adminEmail, userId }) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const [openStrategyModal, setOpenStrategyModal] = useState(false);
  const [openPlanModel, setOpenPlanModal] = useState(false);
  const [selected, setSelected] = useState();
  const [allModalPortfolioList, setAllModalPortdfolioList] = useState([]);

  const getAllModalPortfolioList = () => {
    if (advisorName) {
      axios
        .get(`${server.server.baseUrl}api/admin/plan/${adminEmail}/advisor`)
        .then((res) => {
          setAllModalPortdfolioList(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getAllModalPortfolioList();
  }, [advisorName]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [selectHeader, setSelectHeader] = useState("Plan");
  const [strategyName, setStrategyName] = useState("");
  const [minInvestmentAmount, setMinInvestmentAmount] = useState();
  const [maxNetWorth, setMaxNetWorth] = useState();
  const [overView, setOverView] = useState("");
  const [planType, setPlanType] = useState("");
  const [percent, setPercent] = useState();
  const [isSIPEnabled, setIsSIPEnabled] = useState(false);
  const [duration, setDuration] = useState("");
  const [charge, setCharge] = useState("");
  const [frequency, setFrequency] = useState([]);
  const [isOneTime, setIsOneTime] = useState(true);
  const [oneTimeAmount, setOneTimeAmount] = useState("");

  const [isValidity, setIsValidity] = useState(true);

  const [planDescription, setPlanDescription] = useState("");
  const [validityDays, setValidityDays] = useState("");

  const [pricing, setPricing] = useState({
    monthly: null,
    quarterly: null,
    "half-yearly": null,
    yearly: null,
  });
  const [nextRebalanceDate, setNextRebalanceDate] = useState();

  const isValidMeta = () => {
    // Common checks regardless of whether isOneTime is true or false
    const commonValidations =
      strategyName && // Strategy Name must not be empty
      minInvestmentAmount > 0 &&
      maxNetWorth && // Min Investment Amount must be greater than 0
      maxNetWorth > 0 && // Max Net Worth must be greater than 0
      overView && // Overview must not be empty
      planType &&
      (!isValidity || (validityDays && validityDays > 0));

    // console.log("maxNetWorth",maxNetWorth);

    if (isOneTime) {
      // const validityValidation = !isValidity || (validityDays && validityDays > 0);
      // When isOneTime is true, only oneTimeAmount is required
      return commonValidations && oneTimeAmount > 0 && validityDays > 0;
    } else {
      // When isOneTime is false, pricing and frequency must be validated
      return (
        commonValidations && pricing && frequency.length > 0 // Uncomment this if you want to validate charge as well
      );
    }
  };

  useEffect(() => {
    if (!selected) return;
    setStrategyName(selected.name);
    setMinInvestmentAmount(selected.minInvestment);
    setMaxNetWorth(selected.maxInvestment);
    setOverView(selected.description);
    setPlanType(selected.type);
    setPercent(selected.percent);
    setIsSIPEnabled(selected.isSIPEnabled);
    setDuration(selected.duration);
    setCharge(selected.charges);
    setFrequency(selected.frequency);
    setNextRebalanceDate(selected.start_date);
    setValidityDays(selected.validityDays || "");
  }, [selected]);

  const handleAddGroupName = async () => {
    const groupData = {
      userId,
      creationDate: FormatDateTime(new Date()),
      groupName: strategyName?.toLowerCase()?.replace(/ /g, "_"),
    };

    try {
      const response = await fetch(`${server.server.baseUrl}api/add-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(groupData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const [loading, setLoading] = useState(false);
  const [continueLoader, setContinueLoader] = useState(false);
  const handleSubmit = () => {
    // First, validate the form before submitting
    if (!isValidMeta()) {
      toast.error("Please fill out all required fields correctly.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      return;
    }
    setLoading(true);

    const formDataObj = {
      userId: userId,
      name: strategyName,
      minInvestment: minInvestmentAmount,
      maxNetWorth: maxNetWorth,
      description: overView,
      frequency,
      charges: charge,
      type: planType,
      isSIPEnabled: isSIPEnabled,
      duration: isOneTime && isValidity ? validityDays : null,
      start_date: nextRebalanceDate,
      email: adminEmail,
      advisor: advisorName,
      percent,
      pricing,
      amount: isOneTime === true ? oneTimeAmount : null,
      isOneTime,
      planDescription: planDescription,
    };

    let config = {
      method: selected ? "put" : "post",
      url:
        !selected && isOneTime === false
          ? `${server.server.baseUrl}api/admin/plan/new`
          : !selected && isOneTime === true
          ? `${server.server.baseUrl}api/admin/plan/new/single-payment`
          : `${server.server.baseUrl}api/admin/plan/${selected._id}`,
      data: formDataObj,
    };

    axios
      .request(config)
      .then(async (response) => {
        // getAllModalPortfolioList();
        setLoading(false);
        if (selected?.name == strategyName) {
        } else {
          await handleAddGroupName();
        }
        if (planType === "bespoke") {
          toast.success(response.data.message, {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          });
          setContinueLoader(false);
        } else {
          toast.success(
            `Model Portfolio has been created successfully ${strategyName}`,
            {
              duration: 3000,
              style: {
                background: "white",
                color: "#1e293b",
                maxWidth: "500px",
                fontWeight: 600,
                fontSize: "13px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#16a085",
                secondary: "#FFFAEE",
              },
            }
          );
        }

        getAllModalPortfolioList();

        setTimeout(() => {
          setOpenPlanModal(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in creating plan!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const modelStrategyData = {
    strategyName: strategyName,
    minInvestment: minInvestmentAmount,
    maxNetWorth: maxNetWorth,
  };

  const handlePlanModal = async () => {
    setContinueLoader(true);
    if (planType !== "bespoke") {
      const payload = {
        name: strategyName,
        advisor: advisorName,
      };

      try {
        const response = await fetch(
          `${server.server.baseUrl}api/all-plans/check-plan`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("data", data);
        if (data.exists === false) {
          localStorage.setItem("planName", JSON.stringify(modelStrategyData));
          setOpenStrategyModal(true);
          setOpenPlanModal(false);
          setContinueLoader(false);
        } else {
          setContinueLoader(false);
          toast.error(
            `A plan with the name "${strategyName}" already exists.`,
            {
              duration: 3000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
        }
      } catch (error) {
        setContinueLoader(false);
        console.error("There was a problem with the fetch operation:", error);
      }
    } else {
      try {
        const payload = {
          name: strategyName,
          advisor: advisorName,
        };
        const response = await fetch(
          `${server.server.baseUrl}api/all-plans/check-plan`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (data.exists === false) {
          handleSubmit();
        } else {
          setContinueLoader(false);
          toast.error(
            `A plan with the name "${strategyName}" already exists for this advisor.`,
            {
              duration: 3000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen w-full items-center justify-center bg-[#F9F9F9] ">
      <Toaster position="top-center" reverseOrder={true} />
      <div className="py-4 px-4 flex flex-row  justify-between border-b border-[#000000]/20 h-auto lg:h-[80px] font-poppins">
        <div className="flex flex-row space-x-6 lg:space-x-8 py-2 ">
          <div
            className={`
              ${style.selected} flex items-center justify-center text-[18px] md:text-[22px] lg:px-10`}
          >
            Plan List
          </div>
        </div>
        {allModalPortfolioList.length !== 0 ? (
          <div
            className={`
              text-[15px] md:text-lg font-semibold  px-6 text-white bg-black flex items-center justify-center rounded-md cursor-pointer`}
            onClick={() => setOpenPlanModal(true)}
          >
            Create Plan
          </div>
        ) : null}
      </div>
      <div className="flex-grow overflow-auto pb-4  md:px-4 lg:px-[50px]">
        {allModalPortfolioList.length !== 0 ? (
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pl-1  lg:pt-6 pb-10"
            >
              {allModalPortfolioList.length !== 0 &&
                allModalPortfolioList.map((ele, i) => {
                  return (
                    <PlanCard
                      fileName={ele.name}
                      key={i}
                      overView={ele?.description}
                      ele={ele}
                      onEdit={(data) => {
                        setSelected(ele);
                        setOpenPlanModal(true);
                        setPlanDescription(ele.planDescription || "");
                      }}
                    />
                  );
                })}
            </motion.div>
          </AnimatePresence>
        ) : (
          <div className="flex flex-row justify-center min-h-screen">
            {isLoading ? (
              <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
                <svg
                  className="h-10 w-10 text-[#000000] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              <AnimatePresence mode="wait">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex flex-col items-center justify-center space-y-6 w-full relative  bg-[#f9f9f9]"
                >
                  <div
                    className="text-lg bg-black text-white px-24 py-3 rounded-md font-bold cursor-pointer"
                    onClick={() => setOpenPlanModal(true)}
                  >
                    Create Plan
                  </div>
                </motion.div>
              </AnimatePresence>
            )}
          </div>
        )}
      </div>
      {openPlanModel && (
        <AddPlanModal
          setOpenPlanModal={setOpenPlanModal}
          setOpenStrategyModal={setOpenPlanModal}
          getAllModalPortfolioList={getAllModalPortfolioList}
          selected={selected}
          nextRebalanceDate={nextRebalanceDate}
          setNextRebalanceDate={setNextRebalanceDate}
          frequency={frequency}
          setFrequency={setFrequency}
          selectHeader={selectHeader}
          setSelectHeader={setSelectHeader}
          isValidMeta={isValidMeta}
          strategyName={strategyName}
          setStrategyName={setStrategyName}
          planType={planType}
          setPlanType={setPlanType}
          pricing={pricing}
          setPricing={setPricing}
          minInvestmentAmount={minInvestmentAmount}
          setMinInvestmentAmount={setMinInvestmentAmount}
          maxNetWorth={maxNetWorth}
          setMaxNetWorth={setMaxNetWorth}
          isSIPEnabled={isSIPEnabled}
          setIsSIPEnabled={setIsSIPEnabled}
          overView={overView}
          setOverView={setOverView}
          handlePlanModal={handlePlanModal}
          loading={loading}
          isOneTime={isOneTime}
          setIsOneTime={setIsOneTime}
          oneTimeAmount={oneTimeAmount}
          setOneTimeAmount={setOneTimeAmount}
          isValidity={isValidity}
          setIsValidity={setIsValidity}
          // validityMonth={validityMonth}
          // setValidityMonth={setValidityMonth}
          // validityDay={validityDay}
          // setValidityDay={setValidityDay}
          // validityYear={validityYear}
          // setValidityYear={setValidityYear}

          planDescription={planDescription}
          setPlanDescription={setPlanDescription}
          validityDays={validityDays}
          setValidityDays={setValidityDays}
        />
      )}

      {openStrategyModal && (
        <AddNewStrategyModal
          setOpenStrategyModal={setOpenStrategyModal}
          getAllModalPortfolioList={getAllModalPortfolioList}
          createPlan={handleSubmit}
          plan={true}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </div>
  );
};

export default PlanList;
