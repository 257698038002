import React, { useState } from 'react';
import { AlertTriangle, X } from 'lucide-react';

const RejectedTradesInfoBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <>
    {/* <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 mx-4 lg:mx-[50px] shadow-md relative">
      <div className="flex items-center">
        <AlertTriangle className="h-6 w-6 mr-2 text-blue-500" />
        <p className="font-medium">Important Information</p>
        <button
          onClick={() => setIsVisible(false)}
          className="absolute top-2 right-2 text-blue-500 hover:text-blue-700"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
      <p className="mt-2">
        These are rejected trades for bespoke advices. For portfolio-specific rejected orders,
        please view and execute them from the Advice section (look for cards with yellow highlight
        and click on "Repair").
      </p>
    </div> */}


    </>
  );
};

export default RejectedTradesInfoBanner;