import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { EyeIcon, EyeOffIcon, Info } from "lucide-react";

import server from "../../utils/serverConfig";
import { TokenExpireModal } from "./TokenExpireModal";
import LoadingSpinner from "../../components/LoadingSpinner";

import { encryptApiKey } from "../../utils/cryptoUtils";

const style = {
  selected:
    "flex items-center text-[12px] leading-[14px] lg:text-[16px]   text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[12px] leading-[14px] font-medium  lg:text-[16px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  disabled:bg-gray-200",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
};

const TokenExpireBrokarModal = ({
  openTokenExpireModel,
  setOpenTokenExpireModel,
  userId,
  apiKey,
  secretKey,
  checkValidApiAnSecret,
  clientCode,
  my2pin,
  mobileNumber,
  getUserDetails,
  broker,
}) => {
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const angelOneApiKey = process.env.REACT_APP_ANGEL_ONE_API_KEY;
  const brokerConnectRedirectURL =
    process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL;

  const [loginLoading, setLoginLoading] = useState(false);
  const [aliceApiKey, setAliceApiKey] = useState("");
  // user login

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const handleIiflLogin = () => {
    setLoginLoading(true);
    if (broker === "AliceBlue") {
      let data = JSON.stringify({
        uid: userId,
        user_broker: broker,
        clientCode: clientCode,
        apiKey: aliceApiKey,
      });

      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/user/connect-broker`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };

      axios
        .request(config)
        .then((response) => {
          setLoginLoading(false);
          getUserDetails();
          toast.success("You have been successfully logged in to Alice Blue", {
            duration: 5000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          });
          setOpenTokenExpireModel(false);
        })
        .catch((error) => {
          const result = error.response.data.response;
          console.log("result", result);
          setLoginLoading(false);
          toast.error(`Something went wrong. Invalid Credentials!`, {
            duration: 5000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
    } else {
      window.location.href = `https://markets.iiflcapital.com/?v=1&appkey=nHjYctmzvrHrYWA&redirect_url=${process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL.replace(
        "https://",
        ""
      )}`;
    }
  };

  const [mpin, setMpin] = useState("");
  const [totp, setTotp] = useState("");

  const handleKotakLogin = () => {
    setLoginLoading(true);
    let data = {
      uid: userId,
      apiKey: apiKey,
      secretKey: secretKey,
      mobileNumber: "+91" + mobileNumber,
      mpin: mpin,
      ucc: clientCode,
      totp: totp,
    };

    // Conditionally add optional fields

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/kotak/connect-broker`,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLoginLoading(false);
        toast.success("You have been successfully logged in to your broker.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        getUserDetails();
        setOpenTokenExpireModel(false);
      })
      .catch((error) => {
        console.log(error);
        setLoginLoading(false);
        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };
  useEffect(() => {
    if (showSuccessMsg) {
      setShowSuccessMsg(true);
      const toRef = setTimeout(() => {
        setShowSuccessMsg(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showSuccessMsg]);

  const [zerodhaLoading, setZerodhaLoading] = useState(false);
  const connectZerodha = () => {
    setZerodhaLoading(true);
    let data = JSON.stringify({
      apiKey: zerodhaApiKey,
      site: `${process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL.replace(
        "https://",
        ""
      )}`,
    });

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}zerodha/login-url`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setZerodhaLoading(false);
        window.location.href = `${response.data}`;
      })
      .catch((error) => {
        setZerodhaLoading(false);
        console.log(error);
      });
  };

  //Fyers login
  // Handle Fyers login
  const handleFyersLogin = () => {
    setLoginLoading(true);

    let data = JSON.stringify({
      redirectUrl: brokerConnectRedirectURL,
      clientId: clientCode,
      clientSecret: checkValidApiAnSecret(secretKey),
    });

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}fyers/login-url`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLoginLoading(false);
        if (response) {
          window.location.href = response.data;
        }

        setOpenTokenExpireModel(false);
      })
      .catch((error) => {
        const result = error?.response?.data?.response;
        setLoginLoading(false);
        toast.error(`${result.message}`, {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };
  const handleHdfcLogin = () => {
    setLoginLoading(true);
    let data = JSON.stringify({
      apiKey: checkValidApiAnSecret(apiKey),
    });

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}hdfc/login-url`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("res", response);
        setLoginLoading(false);
        if (response) {
          window.location.href = response.data;
        }
        setOpenTokenExpireModel(false);
      })
      .catch((error) => {
        const result = error?.response?.data?.response;
        setLoginLoading(false);
        toast.error(`${result.message}`, {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success("Copied to clipboard!", {
          duration: 4000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      },
      () => {
        toast.error("Failed to copy text", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    );
  };

  const [iframeSrc, setIframeSrc] = useState("");
  const handleConnect = () => {
    window.open("https://ant.aliceblueonline.com/", "_blank");
  };

  return (
    <TokenExpireModal
      openTokenExpireModel={openTokenExpireModel}
      setOpenTokenExpireModel={setOpenTokenExpireModel}
    >
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="flex items-center justify-center rounded-full p-2 ">
          <Info className="text-[#000000]/50 w-16 h-16 " />
        </div>
      </div>
      {broker === "Zerodha" ? (
        <div className="flex space-x-4 items-center justify-center  text-black text-center text-2xl font-bold mt-4 mb-4">
          <span className="text-[20px] text-[#000000] font-medium  text-center">
            Connecting to broker. click on `Login to Kite Web also?` to kite web
            for ease of next order placement
          </span>
        </div>
      ) : (
        <div className="flex space-x-4 items-center justify-center  text-black text-center text-2xl font-bold mt-4 mb-4">
          <span className="text-[20px] text-[#000000] font-medium  text-center">
            Please login to your broker to continue investments
          </span>
        </div>
      )}

      <div className="md:flex flex-col space-y-2">
        {broker === "AliceBlue" ? (
          <div className="md:flex flex-col space-y-2 pb-6">
            <div className="">
              Please login to your AliceBlue account(logout if already logged in
              and Login again) before attempting to connect.
            </div>
            <div>
              <button
                onClick={() =>
                  window.open("https://ant.aliceblueonline.com/", "_blank")
                }
                className="border-2 border-[#000000]/40 rounded-md px-6 py-2"
              >
                Continue to Alice Blue
              </button>
            </div>
            <div className={style.inputStartDiv}>
              <input
                id="clientCode"
                placeholder="Client Code"
                type="text"
                value={clientCode}
                disabled
                className={`${style.inputBox}`}
              />
              <label
                htmlFor="clientCode"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                Client Id
              </label>
            </div>
            <div className={style.inputStartDiv}>
              <input
                id="my2pin"
                placeholder="My2Pin"
                type="text"
                value={my2pin}
                onChange={(e) => setAliceApiKey(e.target.value)}
                className={`${style.inputBox}`}
              />
              <label
                htmlFor="my2pin"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                Api Key
              </label>
            </div>
          </div>
        ) : null}
      </div>

      {broker === "IIFL Securities" ? (
        <button
          className="w-full mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff] py-3 px-4 font-bold text-lg  rounded-lg cursor-pointer"
          onClick={handleIiflLogin}
        >
          {loginLoading ? (
            <LoadingSpinner />
          ) : (
            <span className="text-[16px] text-white font-semibold ">
              Login to {broker || ""}
            </span>
          )}
        </button>
      ) : broker === "AliceBlue" ? (
        <button
          className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed w-full mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff] py-3 px-4 font-bold text-lg  rounded-lg cursor-pointer"
          onClick={handleIiflLogin}
          disabled={!aliceApiKey}
        >
          {loginLoading ? (
            <LoadingSpinner />
          ) : (
            <span className="text-[16px] text-white font-semibold ">
              Login to {broker || ""}
            </span>
          )}
        </button>
      ) : broker === "ICICI Direct" ? (
        <a
          href={`https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(
            checkValidApiAnSecret(apiKey || "")
          )}`}
          className="block w-full mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff] py-3 px-4 font-bold text-lg  rounded-lg cursor-pointer"
        >
          <span className="text-[16px] text-white font-semibold ">
            Login to {broker || ""}
          </span>
        </a>
      ) : broker === "Upstox" ? (
        <a
          href={`https://api.upstox.com/v2/login/authorization/dialog?response_type=code&client_id=${checkValidApiAnSecret(
            apiKey
          )}&redirect_uri=${brokerConnectRedirectURL}&state=ccxt`}
          className="block w-full mt-6 lg:mt-0 text-center bg-[#2056DF] text-[#ffffff] py-3 px-4 font-bold text-lg  rounded-lg cursor-pointer"
        >
          <span className="text-[16px] text-white font-semibold ">
            Login to {broker || ""}
          </span>
        </a>
      ) : broker === "Zerodha" ? (
        <button
          className="w-full mt-6 lg:mt-0 text-center bg-[#2056DF] text-[#ffffff] py-3 px-4 font-bold text-lg  rounded-lg cursor-pointer"
          onClick={connectZerodha}
        >
          {zerodhaLoading ? (
            <LoadingSpinner />
          ) : (
            <span className="text-[16px] text-white font-semibold ">
              Login to {broker || ""}
            </span>
          )}
        </button>
      ) : broker === "Kotak" ? (
        <div className="md:flex flex-col space-y-3">
          <div className={style.inputStartDiv}>
            <input
              id="clientCode"
              placeholder="Client Code"
              type="text"
              value={mobileNumber}
              disabled
              className={`${style.inputBox}`}
            />
            <label
              htmlFor="clientCode"
              className={`${style.firstHeading} ${style.labelFloat}`}
            >
              MobileNumber
            </label>
          </div>
          <div className={`${style.inputStartDiv}`}>
            <input
              id="clientCode"
              placeholder="Client Code"
              type="text"
              value={clientCode}
              disabled
              className={`${style.inputBox}`}
            />
            <label
              htmlFor="clientCode"
              className={`${style.firstHeading} ${style.labelFloat}`}
            >
              Ucc
            </label>
          </div>
          <div className={style.inputStartDiv}>
            <input
              id="mpin"
              placeholder="Mpin"
              type="text"
              value={mpin}
              onChange={(e) => setMpin(e.target.value)}
              className={`${style.inputBox}`}
            />
            <label
              htmlFor="clientCode"
              className={`${style.firstHeading} ${style.labelFloat}`}
            >
              Mpin
            </label>
          </div>
          <div className={style.inputStartDiv}>
            <input
              id="totp"
              placeholder="Totp"
              type="number"
              value={totp}
              onChange={(e) => setTotp(e.target.value)}
              className={`${style.inputBox}`}
            />
            <label
              htmlFor="totp"
              className={`${style.firstHeading} ${style.labelFloat}`}
            >
              Totp
            </label>
          </div>
          <button
            className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed w-full mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff] py-3 px-4 font-bold text-lg  rounded-lg cursor-pointer"
            onClick={handleKotakLogin}
          >
            {loginLoading ? (
              <LoadingSpinner />
            ) : (
              <span className="text-[16px] text-white font-semibold ">
                Login to {broker || ""}
              </span>
            )}
          </button>
        </div>
      ) : broker === "Fyers" ? (
        <button
          className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed w-full mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff] py-3 px-4 font-bold text-lg  rounded-lg cursor-pointer"
          onClick={handleFyersLogin}
        >
          {loginLoading ? (
            <LoadingSpinner />
          ) : (
            <span className="text-[16px] text-white font-semibold ">
              Login to {broker || ""}
            </span>
          )}
        </button>
      ) : broker === "Hdfc Securities" ? (
        <button
          className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed w-full mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff] py-3 px-4 font-bold text-lg  rounded-lg cursor-pointer"
          onClick={handleHdfcLogin}
        >
          {loginLoading ? (
            <LoadingSpinner />
          ) : (
            <span className="text-[16px] text-white font-semibold ">
              Login to {broker || ""}
            </span>
          )}
        </button>
      ) : (
        <div className="mt-6 lg:mt-0 text-center bg-[#2056DF] text-[#ffffff] py-3 px-4 font-bold  text-lg  rounded-lg cursor-pointer">
          <a
            href={`https://smartapi.angelbroking.com/publisher-login?api_key=${angelOneApiKey}`}
            className="block w-full  lg:mt-0 text-center bg-[#2056DF] text-[#ffffff] px-4 font-bold text-lg  rounded-lg cursor-pointer"
          >
            <span className="text-[16px] text-white font-semibold ">
              Login to {broker || ""}
            </span>
          </a>
        </div>
      )}
    </TokenExpireModal>
  );
};

export default TokenExpireBrokarModal;
