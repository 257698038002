import { Info } from "lucide-react";
import React from "react";

const PortfolioCard = ({
  ele,
  server,
  Alpha100,
  Meter,
  handlePerformanceClick,
  handleSubscriptionClick,
}) => {
  // Prevent event propagation
  const onPerformanceClick = (e) => {
    e.stopPropagation();
    handlePerformanceClick(ele?.name, e);
  };

  const onSubscriptionClick = (e) => {
    e.stopPropagation();
    handleSubscriptionClick(ele, e);
  };

  return (
    <div className="relative font-poppins w-full min-h-[320px] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4 flex flex-col">
      {/* Header Section */}
      <div className="flex items-start min-h-[70px] gap-x-3 p-4">
        <div className="overflow-hidden rounded-[4px] flex-shrink-0">
          <img
            src={ele?.image ? `${server.server.baseUrl}${ele.image}` : Alpha100}
            alt={ele.model_name}
            className="object-cover w-[49.18px] h-[48px]"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-[#000000]">{ele?.name}</h2>
          <p className="mt-1 text-[10px] md:text-[10px] text-[#95989C] font-medium font-poppins text-left">
            {ele.description?.split(" ").slice(0, 10).join(" ")}
            {/* <span className="text-[#55A7F1] ml-1">Read More</span> */}
          </p>
        </div>
      </div>

      {/* Stats Section */}
      <div className="flex w-full mt-[6px] border-t-[1px] border-b-[1px] border-[#000000]/10">
        <div className="flex flex-col justify-center items-center w-1/3 py-1.5 relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10" />
          <div className="text-xs text-left text-[#000000]/70 font-normal font-poppins capitalize">
            CAGR
          </div>
          <p className="text-[13px] lg:text-[16px] text-[#16A085] font-poppins font-bold">
            +{ele?.cagr || "75.2"}%
          </p>
        </div>

        <div className="flex flex-col justify-center items-center w-1/3 py-1.5 relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10" />
          <div className="text-xs text-left text-[#000000]/70 font-normal font-poppins">
            2Y Returns
          </div>
          <p className="text-[13px] lg:text-[16px] text-[#16A085] font-poppins font-bold">
            +{ele?.returns_2y || "775.2"}%
          </p>
        </div>

        <div className="flex flex-col justify-center items-center w-1/3 py-1.5">
          <div className="text-xs text-left text-[#000000]/70 font-normal font-poppins">
            Min. investment
          </div>
          <p className="text-[13px] lg:text-[16px] text-[#000000] font-poppins font-medium">
            ₹{ele?.minInvestment || "1000"}
          </p>
        </div>
      </div>

      {/* Info Section */}
      <div className="flex flex-col flex-grow">
        <div className="flex items-start justify-between px-4 py-2">
          <div className="flex items-center">
            <Info size={12} />
            <p className="text-[12px] lg:text-[12px] px-2 lg:px-2 py-0 font-medium">
              Monthly Fees: ₹{ele?.monthlyFees || "250"}
            </p>
          </div>
          <div className="flex items-center py-[1px] rounded-md">
            <img src={Meter} alt="risk-meter" className="w-4 h-4" />
            <span className="text-[12px] lg:text-[12px] px-2 py-0 font-medium">
              Subject to market risk
            </span>
          </div>
        </div>

        {/* Subscription Dates */}
        {ele?.subscription && (
          <div className="px-4 text-xs text-gray-600 space-y-1">
            <p>
              Start Date of Subscription:{" "}
              {new Date(ele?.subscription?.start_date).toLocaleDateString()}
            </p>
            <p>
              End Date of Subscription:{" "}
              {new Date(ele?.subscription?.end_date).toLocaleDateString()}
            </p>
          </div>
        )}
      </div>

      {/* Buttons Section - Fixed at Bottom */}
      <div className="px-4 mt-2">
        <div className="flex space-x-2 w-full">
          <button
            onClick={onPerformanceClick}
            className="w-full py-2.5 px-1 font-poppins font-medium rounded-md bg-white border-[#000000]/20 border-[2px] text-black text-[13px] md:text-[13px] lg:text-[13px] xxl:text-[15px] hover:bg-gray-50 transition-colors"
          >
            Performance
          </button>

          <button
            onClick={onSubscriptionClick}
            className={`w-full py-2.5 px-1 font-poppins font-medium rounded-md text-white text-[13px] md:text-[13px] lg:text-[13px] xxl:text-[15px] transition-colors ${
              ele?.subscription
                ? "bg-blue-600 hover:bg-blue-700"
                : "bg-black hover:bg-gray-800"
            }`}
          >
            {ele?.subscription ? "Renew Now" : "Invest Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
