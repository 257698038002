import React from "react";
import Step1 from "../../assests/step-1.svg";
import Step2 from "../../assests/step-2.svg";
import Step3 from "../../assests/step-3.svg";
import Step4 from "../../assests/step-4.svg";
import Step5 from "../../assests/step-5.svg";
import Arrow from "../../assests/arrow.svg";

const StepGuideScreen = () => {
  return (
    <div className="flex flex-col space-y-10 relative  w-full   bg-[#f9f9f9] py-6 px-4 lg:py-[20px] lg:px-[80px] overflow-y-auto">
      <div className="min-w-full flex flex-col space-y-4 items-center justify-center">
        <h2 className="text-[#000000] text-center text-[26px] leading-[30px] lg:text-[32px] font-medium font-poppins lg:leading-[40px]">
          No Recommendations at the Moment
        </h2>
        <p className="text-[#000000]/80 text-[16px] font-normal font-poppins leading-[22px]">
          Stay Ready for the Next Opportunity !!
        </p>
      </div>
      <div className="min-w-full flex  flex-col   bg-[#16A085]/5 px-6 py-8 lg:px-10 rounded-lg ">
        <p className="text-center lg:text-left text-[#000000]/80 text-[16px] font-medium font-poppins leading-[22px]">
          Maximize Your Investment Success. Steps to Follows :
        </p>

        <div className=" flex flex-col   justify-between h-full space-y-6  py-6">
          <div className="flex flex-col  space-y-8 lg:space-y-0  lg:items-center  lg:flex-row lg:justify-evenly lg:space-x-4 ">
            {/* Card 1  */}
            <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between lg:space-y-4 lg:w-[250px] ">
              <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                  01
                </div>
                <img
                  src={Step1}
                  alt="Step 1"
                  className=" w-[60px] lg:w-[60px]"
                />
              </div>

              <div className="flex flex-col lg:items-center justify-center space-y-2">
                <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                  Review Buy Advice
                </h3>
                <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                  Assess each recommendation from your advisor.
                </p>
              </div>
            </div>

            {/* Arrow  */}
            <img
              src={Arrow}
              alt="Arrow"
              width={40}
              className=" rotate-90 hidden lg:block lg:rotate-0"
            />

            <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />

            {/* Card 2  */}
            <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between lg:space-y-4 lg:w-[250px]   ">
              <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                  02
                </div>
                <img
                  src={Step2}
                  alt="Step 2"
                  className="w-[40px] lg:w-[60px]"
                />
              </div>
              <div className="flex flex-col lg:items-center justify-center space-y-2">
                <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                  Decide and Place Orders
                </h3>
                <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                  Choose which advices to accept and execute the buy orders.
                </p>
              </div>
            </div>

            {/* Arrow  */}
            <img
              src={Arrow}
              alt="Arrow"
              width={40}
              className=" rotate-90 hidden lg:block lg:rotate-0"
            />

            <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />

            {/* Card 3  */}

            <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between lg:space-y-4 lg:w-[250px]   ">
              <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                  03
                </div>
                <img
                  src={Step3}
                  alt="Step 3"
                  className="w-[40px] lg:w-[60px]"
                />
              </div>

              <div className="flex flex-col lg:items-center justify-center space-y-2">
                <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                  Hold Stocks
                </h3>
                <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                  Keep the purchased stocks in your portfolio.
                </p>
              </div>
            </div>

            <img
              src={Arrow}
              alt="Arrow"
              width={40}
              className=" rotate-90 hidden  lg:rotate-0 lg:hidden"
            />
            <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />
          </div>

          <div className="flex flex-col  space-y-8 lg:space-y-0  lg:items-center lg:flex-row lg:justify-evenly lg:space-x-4  lg:px-[60px]">
            {/* Card 4  */}
            <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between lg:space-y-4 lg:w-[250px]   ">
              <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                  04
                </div>
                <img
                  src={Step4}
                  alt="Step 4"
                  className="w-[40px] lg:w-[60px]"
                />
              </div>

              <div className="flex flex-col lg:items-center justify-center space-y-2">
                <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                  Monitor for Sell Advice
                </h3>
                <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                  Wait for the sell advices from your advisor.
                </p>
              </div>
            </div>

            {/* Arrow  */}
            <img
              src={Arrow}
              alt="Arrow"
              width={40}
              className="rotate-90 hidden lg:block lg:rotate-0"
            />
            <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />

            {/* Card 5  */}
            <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between lg:space-y-4 lg:w-[250px]   ">
              <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                  05
                </div>
                <img
                  src={Step5}
                  alt="Step 5"
                  className="w-[40px] lg:w-[50px]"
                />
              </div>
              <div className="flex flex-col lg:items-center justify-center space-y-2">
                <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                  Book Profit/Loss
                </h3>
                <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                  Sell stocks based on the advisor's recommendations and current
                  market conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepGuideScreen;
