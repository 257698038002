import React from "react";
import { Eye, Mail, AlertCircle } from "lucide-react";
import ViewTradeFailureModals from "./ViewTradeFailureModals";

const TradeFailureCard = ({
  userEmail,
  emailCount,
  tradeFailureModal,
  setTradeFailureModal,
  selectedTab,
  setSelectedTab,
  filteredInconsistencyTrades,
  filteredFailuresTrades,
  filteredMissedTrades,
  setSelectedUser,
  selectedUser,
  userEmailCount,
  setUserEmailCount,
  advisorSpecifier,
  setOpenEmailTemplate,
}) => {
  return (
    <>
      <div className="group relative bg-white transition-all duration-200 hover:bg-gray-50 ">
        <div className="flex items-center px-6 py-4 border-b border-gray-100">
          {/* Issue Count Section */}
          <div className="w-1/5 flex items-center space-x-3">
            <button
              onClick={() => {
                setTradeFailureModal(true);
                setSelectedUser(userEmail);
                setUserEmailCount(emailCount);
              }}
              className="group/btn relative flex items-center justify-center w-8 h-8 rounded-lg bg-gray-50 hover:bg-blue-50 transition-colors"
            >
              <Eye className="w-4 h-4 text-gray-600 group-hover/btn:text-blue-600" />
              <div className="absolute hidden group-hover/btn:block w-24 px-2 py-1 -top-8 left-1/2 -translate-x-1/2 text-xs font-medium text-white bg-gray-900 rounded">
                View Details
                <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-2 h-2 bg-gray-900 rotate-45" />
              </div>
            </button>
            <div className="flex items-center space-x-2">
              <AlertCircle className="w-4 h-4 text-amber-500" />
              <span className="text-sm font-medium text-gray-900">
                {emailCount}
              </span>
            </div>
          </div>

          {/* Email Section */}
          <div className="w-3/5">
            <span className="text-sm font-medium text-gray-900">
              {userEmail}
            </span>
          </div>

          {/* Actions Section */}
          <div className="w-1/5 flex justify-end">
            <button
              onClick={() => {
                setOpenEmailTemplate(true);
                setSelectedUser(userEmail);
              }}
              className="group/mail relative flex items-center justify-center w-8 h-8 rounded-lg bg-gray-50 hover:bg-blue-50 transition-colors"
            >
              <Mail className="w-4 h-4 text-gray-600 group-hover/mail:text-blue-600" />
              <div className="absolute hidden group-hover/mail:block w-24 px-2 py-1 -top-8 left-1/2 -translate-x-1/2 text-xs font-medium text-white bg-gray-900 rounded">
                Send Email
                <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-2 h-2 bg-gray-900 rotate-45" />
              </div>
            </button>
          </div>
        </div>

        {/* Hover Info */}
        <div className="absolute inset-y-0 left-0 w-1 bg-amber-500 opacity-0 group-hover:opacity-100 transition-opacity" />
      </div>

      {/* Modal */}
      {tradeFailureModal && (
        <ViewTradeFailureModals
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTradeFailureModal={setTradeFailureModal}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
          userEmailCount={userEmailCount}
          setUserEmailCount={setUserEmailCount}
          filteredFailuresTrades={filteredFailuresTrades}
          filteredInconsistencyTrades={filteredInconsistencyTrades}
          filteredMissedTrades={filteredMissedTrades}
          advisorSpecifier={advisorSpecifier}
        />
      )}
    </>
  );
};

export default TradeFailureCard;
