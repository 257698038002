import React, { useState, useEffect, useMemo } from "react";
import VolatilityIcon from "../../assests/volatility.svg";
import Alpha100 from "../../assests/alpha-100.png";
import { Pie, PieChart } from "recharts";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import moment from "moment";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";

import {
  CandlestickChartIcon,
  Share2Icon,
  TimerIcon,
  TrendingUpIcon,
} from "lucide-react";
import RebalanceModal from "./RebalanceModal";
import RebalanceTimeLineModal from "./RebalanceTimeLineModal";
import server from "../../utils/serverConfig";
import Breadcrumb from "../../components/Breadcrumbs/Breadcrumbs";

const style = {
  selected:
    "flex items-center text-[16px] md:text-[18px]  font-sans text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[16px] md:text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const StrategyDetails = ({ fileName }) => {
  const [selectedDataTab, setSelectedDataTab] = useState("distribution");
  const [showRebalanceModal, setShowRebalanceModal] = useState(false);
  const [showRebalanceTimelineModal, setShowRebalanceTimelineModal] =
    useState(false);

  const openRebalanceModal = () => {
    setShowRebalanceModal(true);
  };

  const closeRebalanceModal = () => {
    setShowRebalanceModal(false);
  };

  const openRebalanceTimelineModal = () => {
    setShowRebalanceTimelineModal(true);
  };

  const closeRebalanceTimelineModal = () => {
    setShowRebalanceTimelineModal(false);
  };

  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);
  const getStrategyDetails = () => {
    if (fileName) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${fileName?.replaceAll(
            /_/g,
            " "
          )}`
        )
        .then((res) => {
          const portfolioData = res.data[0].originalData;
          setStrategyDetails(portfolioData);
          if (
            portfolioData &&
            portfolioData.model &&
            portfolioData.model.rebalanceHistory.length > 0
          ) {
            const latest = portfolioData.model.rebalanceHistory.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            )[0];
            setLatestRebalance(latest);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  const coinBreadcrumbsList = [
    { title: "All Strategy", link: "/admin/admin-strategy" },
    { title: `${fileName}`, link: "" },
  ];

  const colorPalette = [
    "#EAE7DC",
    "#F5F3F4",
    "#D4ECDD",
    "#FFDDC1",
    "#F8E9A1",
    "#B2C9AB",
    "#FFC8A2",
    "#F6BD60",
    "#CB997E",
    "#A5A58D",
    "#B7CADB",
    "#E2F0CB",
    "#C1D37F",
    "#FFEBBB",
    "#D3C4C4",
    "#D4A5A5",
    "#FFF3E2",
    "#F7B7A3",
    "#EFD6AC",
    "#FAE3D9",
    "#BCD4DE",
    "#B7B6C1",
    "#F9F3DF",
    "#E5D9B6",
    "#D5C6E0",
    "#FFE5D9",
    "#A5C4D4",
    "#F8EAD1",
    "#FDE8D7",
    "#DFD3C3",
  ];

  // Prepare chart data, config, and color mapping
  const { chartData, chartConfig, colorMap } = useMemo(() => {
    const colorMap = {};
    const data =
      latestRebalance?.adviceEntries?.map((entry, index) => {
        const color = colorPalette[index % colorPalette.length]; // Cycle through colors
        colorMap[entry.symbol] = color;
        return {
          shares: entry.symbol,
          value: entry.value * 100,
          fill: color,
        };
      }) || [];

    const config = {
      value: {
        label: "Equity Distribution",
      },
      ...data.reduce((acc, entry) => {
        acc[entry.shares] = {
          label: entry.shares,
          color: entry.fill,
        };
        return acc;
      }, {}),
    };

    return { chartData: data, chartConfig: config, colorMap };
  }, [latestRebalance]);

  return (
    <div className="w-full flex flex-col max-h-[calc(100vh-60px)]  lg:min-h-screen  bg-[#F9F9F9] ">
      {/* Bottom Fixed Add New rebalance Button for Mobile View  */}
      <Toaster position="top-center" reverseOrder={true} />
      <div className="w-full grid grid-cols-12 h-[calc(100vh-120px)] overflow-y-auto lg:min-h-full">
        {/* Left  Side Strategy Details Part */}
        <div className="col-span-12 px-4 lg:col-span-8 xl:col-span-9 lg:px-[60px]">
          <div className="flex flex-col space-y-6 py-6 ">
            <Breadcrumb breadcrumbsList={coinBreadcrumbsList} />
            <div className="flex space-x-6">
              {strategyDetails?.image ? (
                <div className="flex items-center justify-center">
                  <img
                    src={`${server.server.baseUrl}${strategyDetails.image}`}
                    alt={strategyDetails?.modal_name}
                    className="h-[50px] w-[50px] rounded-md"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src={Alpha100}
                    alt="Alpha 100 Strategy"
                    className="h-[50px] w-[50px] rounded-md"
                  />
                </div>
              )}

              <div className="flex justify-center flex-col">
                <div className="flex items-center space-x-4">
                  <h3 className="text-[18px] font-sans font-bold capitalize text-[#000000]">
                    {fileName?.replaceAll(/_/g, " ")}
                  </h3>
                </div>
              </div>
            </div>

            <p className="text-[14px] font-normal font-poppins text-[#000000]/60">
              {strategyDetails?.overView}
            </p>
          </div>

          <div className="flex flex-col space-y-6 pb-8 lg:hidden">
            <div className="flex justify-evenly  divide-x-2 bg-[#E4FDD5]/50 ">
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  CAGR
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +186%
                </p>
              </div>

              <div className="flex flex-col space-y-1 items-center  justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  Returns (2 yrs)
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +400%
                </p>
              </div>
            </div>

            <div className="w-full flex flex-col ">
              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img
                    src={VolatilityIcon}
                    alt="Volatility"
                    className="h-[26px] w-[26px]"
                  />
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Volatility :
                  <p className="ml-2 text-[14px] text-[#000000] font-poppins font-medium ">
                    Low
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <div className="flex items-center justify-center text-[12px] pt-0.5 rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[24px] w-[24px] ">
                  10
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Strategy Score
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <TrendingUpIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Rebalance :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    {strategyDetails?.frequency}
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <TimerIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Duration :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    {strategyDetails?.frequency === "Every Week" ||
                    strategyDetails?.frequency === "Need Basis" ||
                    strategyDetails?.frequency === "Every Day"
                      ? "Short"
                      : strategyDetails?.frequency === "Every Month"
                      ? "Medium"
                      : strategyDetails?.frequency === "Every Quarter" ||
                        (strategyDetails?.frequency === "Every Year" &&
                          "Long")}{" "}
                    Terms
                  </p>
                </div>
              </div>

              <div className="flex items-center border-none py-4 space-x-3">
                <CandlestickChartIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  No. of Rebalances :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    {strategyDetails?.model?.rebalanceHistory?.length}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <div className="flex items-center  justify-between border-b-[1px] border-[#000000]/10">
              <div className="flex space-x-3   md:space-x-6 lg:space-x-6 ">
                <div
                  className={`
                ${
                  selectedDataTab === "distribution"
                    ? style.selected
                    : style.unselected
                } flex items-center justify-center md:w-[120px] py-2 lg:py-1 text-xs sm:text-sm md:text-base`}
                  onClick={() => {
                    setSelectedDataTab("distribution");
                  }}
                >
                  Distribution
                </div>
                <div
                  className={`
                ${
                  selectedDataTab === "whyStrategy"
                    ? style.selected
                    : style.unselected
                } flex items-center justify-center  py-2 lg:py-1 text-xs sm:text-sm md:text-base`}
                  onClick={() => {
                    setSelectedDataTab("whyStrategy");
                  }}
                >
                  Why this Strategy?
                </div>
                <div
                  className={`
                ${
                  selectedDataTab === "methodology"
                    ? style.selected
                    : style.unselected
                } flex items-center justify-center md:w-[120px] py-2 lg:py-1 text-xs sm:text-sm md:text-base`}
                  onClick={() => {
                    setSelectedDataTab("methodology");
                  }}
                >
                  Methodology
                </div>
              </div>
              <Share2Icon className="h-5 w-5 text-[#000000] " />
            </div>
          </div>
          {selectedDataTab === "distribution" && (
            <div className="flex flex-col space-y-6 py-6">
              <div className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                Check Latest Rebalance Updates issued by the Manager.
                <span
                  onClick={openRebalanceTimelineModal}
                  className="ml-2 cursor-pointer text-[14px] text-blue-500 font-normal font-poppins underline"
                >
                  View Rebalance History
                </span>
              </div>

              <div className="grid grid-cols-2 gap-y-5 md:gap-y-0  md:grid-cols-3 lg:w-[656px] lg:divide-x-2 divide-[#000000]/10 bg-[#f1f0f0] rounded-[4px] py-4 lg:px-0">
                <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                  <h4 className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                    Last Rebalance
                  </h4>
                  <p className="text-[16px] text-[#000000] font-medium font-poppins">
                    {moment(strategyDetails?.last_updated).format(
                      "MMM DD, YYYY"
                    )}
                  </p>
                </div>
                <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                  <h4 className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                    Next Rebalance
                  </h4>
                  <p className="text-[16px] text-[#000000] font-medium font-poppins">
                    {moment(strategyDetails?.nextRebalanceDate).format(
                      "MMM DD, YYYY"
                    )}
                  </p>
                </div>
                <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                  <h4 className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                    Rebalance
                  </h4>
                  <p className="text-[16px] text-[#000000] font-medium font-poppins">
                    {strategyDetails?.frequency}
                  </p>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row lg:space-x-8 ">
                <div className="flex flex-col lg:w-[400px]">
                  <div className="grid grid-cols-5 border-b-[1px] border-[#000000]/10 py-3 px-4 ">
                    <div className="col-span-3 text-[14px] text-[#000000]/60 font-normal font-poppins">
                      Equity
                    </div>
                    <div className="flex justify-end col-span-2 text-[14px] text-[#000000]/60 font-normal font-poppins">
                      Weightage (%)
                    </div>
                  </div>
                  {latestRebalance?.adviceEntries?.map((ele, i) => {
                    return (
                      <div
                        className="relative grid grid-cols-5 border-b-[1px] border-b-[#000000]/10 border-l-[4px] py-4 px-8"
                        style={{ borderLeftColor: colorMap[ele.symbol] }}
                        key={i}
                      >
                        <div className="col-span-3 text-[15px] text-[#000000]/85 font-normal font-poppins">
                          {ele.symbol}
                        </div>
                        <div className="flex justify-end col-span-2 text-[15px] text-[#000000]/85 font-normal font-poppins">
                          {parseFloat(ele.value * 100).toFixed(2)}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <ChartContainer
                  config={chartConfig}
                  className="mx-auto aspect-square max-h-[300px] h-[280px] "
                >
                  <PieChart>
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <Pie data={chartData} dataKey="value" nameKey="shares" />
                  </PieChart>
                </ChartContainer>
              </div>
            </div>
          )}
          {selectedDataTab === "whyStrategy" && (
            <div className="flex flex-col space-y-6 py-6 ">
              <p className="text-[14px] font-normal font-poppins text-[#000000]/60">
                {strategyDetails?.whyThisStrategy}
              </p>
            </div>
          )}
          {selectedDataTab === "methodology" && (
            <div className="flex flex-col space-y-6 py-6 ">
              <div>
                <p className="text-lg font-medium">Defining the universe</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-[#000000]/60">
                  {strategyDetails?.definingUniverse}
                </p>
              </div>
              <div>
                <p className="text-lg font-medium">Research</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-[#000000]/60">
                  {strategyDetails?.researchOverView}
                </p>
              </div>
              <div>
                <p className="text-lg font-medium">Constituent Screening</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-[#000000]/60">
                  {strategyDetails?.constituentScreening}
                </p>
              </div>
              <div>
                <p className="text-lg font-medium">Weighting</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-[#000000]/60">
                  {strategyDetails?.weighting}
                </p>
              </div>
              <div>
                <p className="text-lg font-medium">Rebalance</p>
                <p className="mt-2 text-[14px] font-normal font-poppins text-[#000000]/60">
                  {strategyDetails?.rebalanceMethodologyText}
                </p>
              </div>
              {strategyDetails?.assetAllocationText !== "" ? (
                <div>
                  <p className="text-lg font-medium">Asset Allocation</p>
                  <p className="mt-2 text-[14px] font-normal font-poppins text-[#000000]/60">
                    {strategyDetails?.assetAllocationText}
                  </p>
                </div>
              ) : null}
            </div>
          )}
        </div>

        {/* Right Side Metrics Part */}

        <div className="hidden lg:block xl:col-span-3 lg:col-span-4">
          <div className="flex flex-col space-y-6 px-6 py-10">
            <button
              onClick={openRebalanceModal}
              className="w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6"
            >
              Add New Rebalance
            </button>

            <div className="flex justify-evenly  divide-x-2 bg-[#E4FDD5]/50 ">
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  CAGR
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +186%
                </p>
              </div>

              <div className="flex flex-col space-y-1 items-center  justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  Returns (2 yrs)
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +400%
                </p>
              </div>
            </div>

            <div className="w-full flex flex-col py-6">
              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img
                    src={VolatilityIcon}
                    alt="Volatility"
                    className="h-[26px] w-[26px]"
                  />
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Volatility :
                  <p className="ml-2 text-[14px] text-[#000000] font-poppins font-medium ">
                    Low
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <div className="flex items-center justify-center text-[14px] pt-0.5 rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[30px] w-[30px] ">
                  10
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Strategy Score
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <TrendingUpIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Rebalance :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    {strategyDetails?.frequency}
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <TimerIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Duration :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    {strategyDetails?.frequency === "Every Week" ||
                    strategyDetails?.frequency === "Need Basis" ||
                    strategyDetails?.frequency === "Every Day"
                      ? "Short"
                      : strategyDetails?.frequency === "Every Month"
                      ? "Medium"
                      : strategyDetails?.frequency === "Every Quarter" ||
                        (strategyDetails?.frequency === "Every Year" && "Long")}
                    Terms
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <CandlestickChartIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  No. of Rebalances :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    {strategyDetails?.model?.rebalanceHistory?.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* RebalanceModal  */}
        {showRebalanceModal && (
          <RebalanceModal
            closeRebalanceModal={closeRebalanceModal}
            fileName={fileName}
            data={latestRebalance?.adviceEntries}
            strategyDetails={strategyDetails}
            server={server}
            getStrategyDetails={getStrategyDetails}
          />
        )}
        {showRebalanceTimelineModal && (
          <RebalanceTimeLineModal
            closeRebalanceTimelineModal={closeRebalanceTimelineModal}
            strategyDetails={strategyDetails}
          />
        )}
      </div>

      <div className="lg:hidden flex items-center w-full h-[80px] shadow-[0px_-4px_4px_0px_#0000000f] px-4">
        <button
          onClick={openRebalanceModal}
          className=" w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6"
        >
          Add New Rebalance
        </button>
      </div>
    </div>
  );
};

export default StrategyDetails;
