import React, { useState } from "react";
import { XIcon } from "lucide-react";
import axios from "axios";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import toast from "react-hot-toast";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const SendEmailTemplate = ({
  whiteLabelText,
  setOpenEmailTemplate,
  selectedUser,
}) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      // Fetch subscription details from the backend
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/send-email`,
        
        {
          body: `<p>${message}</p>`,
          advisorName: whiteLabelText,
          recipient: selectedUser,
          subject: subject,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          }
        },
      );
      setOpenEmailTemplate(false);
      setLoading(false);
      const data = response.data;
      toast.success("Mail has been sent successfully", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#16a085",
          secondary: "#FFFAEE",
        },
      });
    } catch (error) {
      setLoading(false);
      toast.error("Error in sending Email !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50 ">
      <div className="relative overflow-auto animate-modal">
        <div
          className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={() => setOpenEmailTemplate(false)}
        >
          <XIcon className="w-6 h-6 mr-2" />
        </div>
        <div className="px-8 w-[750px]  py-8 bg-white  border-[#000000]/20 rounded-md">
          <div className="space-y-6">
            <div className="space-y-2">
              <label className="block text-sm font-normal text-gray-700">
                <div className="flex items-center font-semibold font-poppins">
                  Email<span className="text-red-500">*</span>
                </div>
              </label>
              <input
                type="email"
                value={selectedUser}
                disabled
                placeholder="Enter your Email"
                className="w-full h-12 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
              />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-normal text-gray-700">
                <div className="flex items-center font-semibold font-poppins">
                  Subject<span className="text-red-500">*</span>
                </div>
              </label>
              <input
                text="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Enter your Email"
                className="w-full h-12 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
              />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-normal text-gray-700">
                <div className="flex items-center font-semibold font-poppins">
                  Message<span className="text-red-500">*</span>
                </div>
              </label>
              <textarea
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter your Email"
                className="w-full h-32 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
              />
            </div>
          </div>

          <div className="mt-4 flex flex-row justify-end">
            <button
              className={`${
                subject && message
                  ? "bg-black cursor-pointer"
                  : "bg-gray-300 cursor-not-allowed"
              } text-white w-32 h-10 rounded-md`}
              onClick={handleSubmit}
              disabled={!subject || !message}
            >
              {loading === true ? <LoadingSpinner /> : "Send"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendEmailTemplate;
