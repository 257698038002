import React from "react";
import DashboardLayout from "../RootSection/DashboardLayout";
import PricingPage from "./PricingPage";

const Pricing = () => {
  return (
    <DashboardLayout>
      <PricingPage />
    </DashboardLayout>
  );
};

export default Pricing;
