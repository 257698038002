import { useState } from "react";
import { RotateCw, Eye, CheckCircle2, Link } from "lucide-react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import server from "../../utils/serverConfig";
import FormatDateTime from "../../utils/formatDateTime";
import LoadingSpinner from "../../components/LoadingSpinner";
import { encryptApiKey } from "../../utils/cryptoUtils";

const StatusIndicator = ({ count, total, onClick = null }) => {
  const percentage = (count / total) * 100;
  const isComplete = percentage === 100;

  return (
    <button
      onClick={onClick}
      className={`w-8 h-8 rounded-full flex items-center justify-center transition-colors ${
        onClick ? "hover:bg-gray-100" : ""
      } ${
        isComplete
          ? "text-emerald-500"
          : percentage > 0
          ? "text-blue-500"
          : "text-gray-300"
      }`}
    >
      {isComplete ? (
        <CheckCircle2 className="w-5 h-5" />
      ) : (
        <Eye className="w-5 h-5" />
      )}
    </button>
  );
};

export const TableRow = ({
  item,
  setNotificationModal,
  setSelectedItem,
  handleResendAdvice,
  loadingAdviceId,
  advisorSpecifier,
}) => {
  const isLoading = loadingAdviceId === item.adviseId;
  const metrics = item?.metricDetails;

  const [showLinkPopup, setShowLinkPopup] = useState(false);
  const [uploadedLink, setUploadedLink] = useState("");
  const [adviceId, setAdviceId] = useState("");
  const [storeResearchLink, setStoreResearchLink] = useState({});
  const [loadingLinks, setLoadingLinks] = useState({});

  const handleStatusClick = () => {
    if (!item.action) {
      setSelectedItem(item);
      setNotificationModal(true);
    }
  };

  const handleAddLink = (id) => {
    setShowLinkPopup(true);
    setAdviceId(id);
  };

  const [loading, setLoading] = useState(false);
  const handleLinkSubmit = async (adviceId, link) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/research-report-link/${adviceId}`,
        {
          link: link,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
      setLoading(false);
      setShowLinkPopup(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  };

  const getResearchReportLink = async (adviceId) => {
    try {
      const response = await axios.get(
        `${server.ccxtServer.baseUrl}comms/research-report-link/${adviceId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      // Assuming the API response contains the link
      const fetchedLink = response.data.link;

      // Store the fetched link temporarily
      setStoreResearchLink((prevLinks) => ({
        ...prevLinks,
        [adviceId]: fetchedLink,
      }));
    } catch (error) {
      console.error("Error fetching research report link:", error);
    } finally {
      setLoadingLinks((prev) => ({ ...prev, [adviceId]: false })); // Stop loading
    }
  };

  return (
    <>
      <tr className="hover:bg-gray-50 transition-colors">
        <td className="px-4 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
          {FormatDateTime(item.date)}
        </td>
        <td className="px-4 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
          {item.Symbol}
        </td>
        {advisorSpecifier === "RIA" && (
          <td className="px-4 py-3 text-sm font-medium text-gray-900 whitespace-nowrap text-center">
            {item.symbolDetails.quantity}
          </td>
        )}
        <td className="px-4 py-3 w-16">
          <div className="flex justify-center">
            <StatusIndicator
              count={metrics?.reco_save_count || 0}
              total={metrics?.reco_save_count || 1}
              onClick={handleStatusClick}
            />
          </div>
        </td>
        <td className="px-4 py-3 w-16">
          <div className="flex justify-center">
            <StatusIndicator
              count={metrics?.sent_whatsapp_count || 0}
              total={metrics?.reco_save_count || 1}
              onClick={handleStatusClick}
            />
          </div>
        </td>
        <td className="px-4 py-3 w-16">
          <div className="flex justify-center">
            <StatusIndicator
              count={metrics?.total_sent_mails || 0}
              total={metrics?.reco_save_count || 1}
              onClick={handleStatusClick}
            />
          </div>
        </td>
        <td className="px-4 py-3 w-24">
          <div className="flex justify-center">
            {item.action ? (
              <span className="text-sm font-medium text-emerald-600 whitespace-nowrap">
                Complete
              </span>
            ) : (
              <button
                onClick={() =>
                  handleResendAdvice(
                    item.unsentEmails,
                    item.symbolDetails,
                    item.metricDetails
                  )
                }
                disabled={isLoading}
                className="inline-flex items-center px-2.5 py-1.5 text-sm font-medium text-white bg-[#2D87D6] rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors whitespace-nowrap"
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <RotateCw className="w-4 h-4 mr-1.5" />
                    Resend
                  </>
                )}
              </button>
            )}
          </div>
        </td>
        <td className="px-1 py-3 w-10">
          <div className="flex items-center justify-center">
            <button
              onClick={() => handleAddLink(item?.adviseId)}
              className="inline-flex items-center px-2.5 py-1.5 text-sm font-medium text-white bg-[#2D87D6] rounded-md hover:bg-gray-800 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
            >
              <Link className="w-4 h-4 mr-1.5" />
              Add
            </button>
          </div>
        </td>

        <td className="px-4 py-3 w-fit">
          <div className="flex">
            {storeResearchLink[item.adviseId] ? (
              <a
                href={
                  storeResearchLink[item.adviseId].startsWith("http")
                    ? storeResearchLink[item.adviseId]
                    : `https://${storeResearchLink[item.adviseId]}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 underline"
              >
                View Link
              </a>
            ) : loadingLinks[item.adviseId] ? (
              <div className="flex items-center">
                <Skeleton width={30} height={5} />
              </div>
            ) : (
              <button
                onClick={() => getResearchReportLink(item.adviseId)}
                className="text-black"
              >
                Fetch Link
              </button>
            )}
          </div>
        </td>
      </tr>
      {showLinkPopup && (
        <tr>
          <td colSpan="9">
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-6 w-96">
                <h2 className="text-xl font-semibold mb-4">Add Link</h2>

                <input
                  type="url"
                  name="link"
                  placeholder="Enter link here"
                  value={uploadedLink}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  onChange={(e) => setUploadedLink(e.target.value)}
                />
                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setShowLinkPopup(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleLinkSubmit(adviceId, uploadedLink)}
                    disabled={!uploadedLink}
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:cursor-not-allowed"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
