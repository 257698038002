import React from "react";
// import { X, Plus } from "lucide-react";

const EmailPopUp = ({ isOpen, onClose, emails }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-96">
        <h2 className="text-xl font-bold mb-4">Manage Emails</h2>
        <div className="space-y-4">
          {emails?.map((email, index) => (
            <div key={index} className="flex items-center justify-between">
              <span>{email.email}</span>
              {/* 
              <button
                onClick={() => onRemoveEmail(email.email)}
                className="text-red-500 hover:text-red-700"
              >
                <X className="h-4 w-4" />
              </button>
              */}
            </div>
          ))}
        </div>
        <button
          onClick={onClose}
          className="mt-4 w-full bg-gray-200 text-gray-800 py-2 rounded hover:bg-gray-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default EmailPopUp;
