import React, { useState } from "react";
import { XIcon, RotateCw, CircleX } from "lucide-react";
// import toast from "react-hot-toast";
import Logo from "../../assests/Logo.jpg";
import Whatsapp from "../../assests/whatsapp.png";
import Mail from "../../assests/Email.svg";
import { motion, AnimatePresence } from "framer-motion";
import ProgressCircle from "./ProgressCircle";
import LoadingSpinner from "../../components/LoadingSpinner";
// import { DatePicker } from "../../components/ui/date-picker";
// import server from "../../utils/serverConfig";

const SendNotificationModal = ({
  setNotificationModal,
  selectedItem,
  handleResendAdvice,
  loading,
}) => {
  const [selectedTab, setSelectedTab] = useState("platform");

  const getTabSpecificData = () => {
    if (selectedTab === "whatsapp") {
      return selectedItem?.metricDetails?.unsent_whatsapp_emails || [];
    } else if (selectedTab === "gmail") {
      return selectedItem?.metricDetails?.unsupported_email_count > 0
        ? Array.from(
            { length: selectedItem?.metricDetails?.unsupported_email_count },
            (_, i) => `Unsupported email #${i + 1}` // Placeholder data
          )
        : [];
    }
    return [];
  };

  // Data for the current tab
  const tabData = getTabSpecificData();

  return (
    <AnimatePresence>
      <div className="fixed inset-0 flex items-center justify-center bg-black/75 z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="relative w-full max-w-3xl mx-4"
        >
          <div className="relative bg-white rounded-lg shadow-xl overflow-hidden">
            <button
              onClick={() => setNotificationModal(false)}
              className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
            >
              <XIcon className="w-6 h-6 text-gray-500" />
            </button>

            <div className="px-6 py-4 border-b border-gray-200">
              <h2 className="text-xl font-semibold text-gray-900">
                Advice Sent Status
              </h2>
            </div>

            <div className="bg-white flex flex-row px-8 shadow-lg">
              <div
                className={
                  selectedTab === "platform"
                    ? "px-6 py-3 flex flex-row items-center cursor-pointer border-b-4 border-black"
                    : "px-6 py-3 flex flex-row items-center cursor-pointer border-b-4 border-white"
                }
                onClick={() => setSelectedTab("platform")}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  className="w-8 h-8 rounded-md mr-2"
                />
                <div className="w-32 py-1.5 flex justify-center rounded-full border-2 border-gray-400 text-center items-center">
                  <ProgressCircle percentage={selectedItem?.platformProgress} />
                  <span className="text-sm text-gray-800">
                    {" "}
                    {selectedItem?.metricDetails?.reco_save_count}/
                    {selectedItem?.metricDetails?.reco_save_count}{" "}
                  </span>
                </div>
              </div>
              <div
                className={
                  selectedTab === "whatsapp"
                    ? "px-6 py-3 flex flex-row items-center cursor-pointer border-b-4 border-black"
                    : "px-6 py-3 flex flex-row items-center cursor-pointer border-b-4 border-white"
                }
                onClick={() => setSelectedTab("whatsapp")}
              >
                <img
                  src={Whatsapp}
                  alt="whatsapp"
                  className="w-10 h-8 rounded-md mr-2"
                />
                <div className="w-32 py-1.5 flex justify-center rounded-full border-2 border-gray-400 text-center items-center">
                  <ProgressCircle percentage={selectedItem?.progress} />
                  <span className="text-sm text-gray-800">
                    {selectedItem?.metricDetails?.sent_whatsapp_count}/
                    {selectedItem?.metricDetails?.reco_save_count}{" "}
                  </span>
                </div>
              </div>
              <div
                className={
                  selectedTab === "gmail"
                    ? "px-6 py-3 flex flex-row items-center cursor-pointer border-b-4 border-black"
                    : "px-6 py-3 flex flex-row items-center cursor-pointer border-b-4 border-white"
                }
                onClick={() => setSelectedTab("gmail")}
              >
                <img
                  src={Mail}
                  alt="Mail"
                  className="w-8 h-8 rounded-md mr-2"
                />
                <div className="w-32 py-1.5 flex justify-center rounded-full border-2 border-gray-400 text-center items-center">
                  <ProgressCircle percentage={selectedItem?.emailProgress} />
                  <span className="text-sm text-gray-800">
                    {selectedItem?.metricDetails?.total_sent_mails}/
                    {selectedItem?.metricDetails?.reco_save_count}{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className="px-8 mt-12">
              {tabData?.length > 0 ? (
                tabData.map((email, i) => (
                  <div
                    key={i}
                    className={`flex flex-row items-center text-base py-2 ${
                      i !== tabData.length - 1
                        ? "border-b-2 border-gray-400"
                        : ""
                    }`}
                  >
                    <CircleX className="w-5 h-5 mr-3 text-red-500" />
                    {email}
                  </div>
                ))
              ) : (
                <div className="text-gray-500">No data available.</div>
              )}
            </div>
            {tabData?.length > 0 && (
              <div className="p-6">
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={() => setNotificationModal(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                  <button
                    className="flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed"
                    onClick={() =>
                      handleResendAdvice(
                        selectedItem.unsentEmails,
                        selectedItem.symbolDetails,
                        selectedItem.metricDetails
                      )
                    }
                  >
                    {loading === true ? (
                      <LoadingSpinner />
                    ) : (
                      <span className="flex flex-row items-center">
                        {" "}
                        <RotateCw className="h-4 w-4 mr-2" />
                        Send Again
                      </span>
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default SendNotificationModal;
