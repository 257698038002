import { DownloadIcon, EyeIcon } from "lucide-react";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import SendAdviceModel from "./SendAdviceModal";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const encryptedKey = encryptApiKey(
  process.env.REACT_APP_AQ_KEYS,
  process.env.REACT_APP_AQ_SECRET
);

const ACCEPTABLE_DATE_FORMATS = [
  "D MMM YYYY, HH:mm:ss",
  "YYYY-MM-DDTHH:mm:ss.SSSZ",
];

const GroupCard = ({
  csvHeaders,
  groupName,
  allClientDataByGroup,
  adminEmail,
  advisorName,
  email_password,
  adminRationale,
  advisorSpecifier,
}) => {
  const navigate = useNavigate();
  const [sendAdviceModal, setSendAdviceModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredData, setFilteredData] = useState(allClientDataByGroup);
  const [searchQuery, setSearchQuery] = useState("");

  const handleViewFile = (groupName) => {
    navigate(`/admin/client-list/${groupName.toLowerCase()}`);
  };

  const openSendAdviceModal = () => {
    // Filter clients based on subscription validity
    const validClients = allClientDataByGroup.filter(
      (client) => client.isSubscriptionValid
    );
    setSendAdviceModal(true);
    setSelectedRows(validClients);
  };

  const closeSendAdviceModal = () => {
    setSendAdviceModal(false);
  };

  useEffect(() => {
    // Filter and sort on the full data set
    const sortedData = [...(allClientDataByGroup || [])].sort(
      (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
    );

    const filtered = sortedData.filter((user) =>
      user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredData(filtered);
  }, [searchQuery, allClientDataByGroup]);

  const isSubscriptionValid = (subscriptions, groupName) => {
    if (!subscriptions || subscriptions.length === 0) return false;

    // Filter subscriptions matching the given group name
    const matchingPlanSubs = subscriptions.filter(
      (sub) => sub?.plan === groupName
    );
    if (matchingPlanSubs.length === 0) return false;

    // Filter out deleted subscriptions
    const activeSubscriptions = matchingPlanSubs.filter(
      (sub) => sub?.status !== "deleted"
    );
    if (activeSubscriptions.length === 0) return false;

    // Validate expiry dates
    const validSubscriptions = activeSubscriptions.filter((sub) =>
      sub.expiry
        ? moment(sub.expiry, ACCEPTABLE_DATE_FORMATS, true).isValid()
        : false
    );
    if (validSubscriptions.length === 0) return false;

    // Get the latest subscription
    const latestSub = validSubscriptions.sort(
      (a, b) =>
        moment(b.expiry, ACCEPTABLE_DATE_FORMATS) -
        moment(a.expiry, ACCEPTABLE_DATE_FORMATS)
    )[0];

    // Ensure the latest subscription is still active
    const expiryDate = moment(latestSub?.expiry, ACCEPTABLE_DATE_FORMATS);
    const today = moment();

    return expiryDate.isAfter(today);
  };

  return (
    <>
      <div className="group flex items-center justify-between px-6 py-4 hover:bg-gray-50 transition-all duration-200 ease-in-out border-b border-gray-100">
        {/* List Name */}
        <div className="flex-1">
          <h3 className="text-sm font-medium text-gray-900 capitalize">
            {groupName?.replace(/_/g, " ")}
          </h3>
        </div>

        {/* Active Members Count */}
        <div className="flex items-center justify-center w-32">
          <span className="text-sm text-gray-600 font-medium">
            {filteredData?.filter((client) =>
              isSubscriptionValid(client.subscriptions, groupName)
            )?.length || 0}
          </span>
        </div>

        {/* Actions */}
        <div className="flex items-center justify-end space-x-3">
          {/* Download CSV */}
          <CSVLink
            data={allClientDataByGroup}
            headers={csvHeaders}
            filename={`${groupName
              .toLowerCase()
              .replace(/ /g, "_")}_${moment().format("YYYYMMDD_HHmmss")}.csv`}
            className="relative group/tooltip"
          >
            <button className="p-2 text-gray-600 hover:text-[#2056DF] hover:bg-blue-50 rounded-lg transition-all duration-200">
              <DownloadIcon className="w-4 h-4" />
              <span className="absolute hidden g mb-2 px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded">
                Download CSV
              </span>
            </button>
          </CSVLink>

          {/* View Details */}
          <button
            onClick={() => handleViewFile(groupName)}
            className="relative group/tooltip p-2  text-gray-600 hover:text-[#2056DF] hover:bg-blue-50 rounded-lg transition-all duration-200"
          >
            <EyeIcon className="w-4 h-4" />
            <span className="absolute w-28 hidden group-hover/tooltip:block bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded">
              View Details
            </span>
          </button>

          {/* Send Button */}
          <button
            onClick={openSendAdviceModal}
            className="px-4 py-2 text-sm z-100   font-medium text-white bg-[#2056DF] rounded-lg hover:bg-[#0060B0] transition-all duration-200 shadow-sm hover:shadow disabled:cursor-not-allowed disabled:bg-gray-400 "
            disabled={
              filteredData?.filter((client) =>
                isSubscriptionValid(client.subscriptions, groupName)
              )?.length === 0
            }
          >
            Send
          </button>
        </div>
      </div>

      {sendAdviceModal === true ? (
        <SendAdviceModel
          fileName={groupName}
          closeSendAdviceModal={closeSendAdviceModal}
          data={allClientDataByGroup}
          adminEmail={adminEmail}
          advisorName={advisorName}
          email_password={email_password}
          setSendAdviceModal={setSendAdviceModal}
          setSelectedRows={setSelectedRows}
          adminRationale={adminRationale}
          advisorSpecifier={advisorSpecifier}
        />
      ) : null}
    </>
  );
};

export default GroupCard;
