"use client";

import { XIcon } from "lucide-react";
import React, { useState } from "react";

const EditModal = ({ client, onClose, onUpdate }) => {
  const [editedClient, setEditedClient] = useState(client);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedClient((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = () => {
    console.log("Updated Client Details:", editedClient);
    onUpdate(editedClient);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative">
        <div
          className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={onClose}
        >
          <XIcon className="w-6 h-6 mr-2" />
        </div>
        <div className="bg-white  rounded-lg w-[650px] px-8 py-8">
          <h3 className="text-lg font-semibold mb-4">Edit Client Details</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 font-poppins">
            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Client Name
                <span className="ml-1 text-red-500 text-[14px]">*</span>
              </span>
              <input
                type="text"
                name="clientName"
                value={editedClient.clientName}
                onChange={handleChange}
                placeholder="Client Name"
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Email Id
                <span className="ml-1 text-red-500 text-[14px]">*</span>
              </span>
              <input
                type="email"
                name="email"
                value={editedClient.email}
                onChange={handleChange}
                placeholder="Email Id"
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Phone No.
                <span className="ml-1 text-red-500 text-[14px]">*</span>
              </span>
              <input
                type="text"
                name="phone"
                value={editedClient.phone}
                onChange={handleChange}
                placeholder="Phone No."
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Location
              </span>
              <input
                type="text"
                name="location"
                value={editedClient.location}
                onChange={handleChange}
                placeholder="Location"
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Telegram Id
              </span>
              <input
                type="text"
                name="telegram"
                value={editedClient.telegram}
                onChange={handleChange}
                placeholder="Telegram Id"
                className="p-2 border rounded font-medium"
              />
            </label>

            <label className="flex flex-col space-y-1">
              <span className="text-[12px] font-medium text-[#000000]/50">
                KYC (PAN)
              </span>
              <input
                type="text"
                name="pan"
                value={editedClient.pan}
                onChange={handleChange}
                placeholder="KYC (PAN)"
                className="p-2 border rounded font-medium"
              />
            </label>
            <label  className="flex flex-col space-y-1 col-span-2">
              <span className="text-[12px] font-medium text-[#000000]/50">
                Comments
              </span>
              <textarea
                value={editedClient.comments}
                name="comments"
                onChange={(e) => handleChange(e)}
                className="w-full p-2 border rounded mb-4 text-[16px] font-medium font-poppins "
                rows="3"
              ></textarea>
            </label>
          </div>

          <div className="mt-8 text-[18px] font-normal font-poppins text-center ">
            <div className="grid grid-cols-2 gap-x-4 gap-y-2">
              <div
                onClick={onClose}
                className=" bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[16px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer"
              >
                Cancel
              </div>
              <button
                disabled={!editedClient.email || !editedClient.clientName || !editedClient.phone}
                onClick={handleUpdate}
                className="disabled:bg-opacity-50 disabled:cursor-not-allowed bg-[#000000] text-white text-[16px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
              >
                Update Client Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
