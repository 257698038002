import React, { useState, useRef } from "react";
import YouTube from "react-youtube";
import { TelegramData } from "../LivePortfolioSection/Data";
import { ChevronDown, ChevronUp } from "lucide-react";

const TelegramSteps = () => {
  const playerRef = useRef(null);
  const showTelegram = process.env.REACT_APP_TELEGRAM === "1";

  const opts = {
    height: "300",
    width: "100%",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      rel: 0,
    },
  };

  const handlePlayerReady = (event) => {
    event.target.pauseVideo();
  };

  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleIndex = (index) => {
    setOpenIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  if (!showTelegram) {
    return null;
  }

  return (
    <div className="relative w-full">
      <div
        className="w-full overflow-auto max-h-[calc(100vh-12rem)] space-y-4 custom-scrollbar"
        style={{
          scrollbarWidth: 'thin',
          scrollbarColor: '#E5E7EB transparent'
        }}
      >
        <style jsx global>{`
          .custom-scrollbar::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
          .custom-scrollbar::-webkit-scrollbar-track {
            background: transparent;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #E5E7EB;
            border-radius: 3px;
          }
          .custom-scrollbar::-webkit-scrollbar-corner {
            background: transparent;
          }
          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #E5E7EB transparent;
            padding-right: 1rem;
            padding-bottom: 1rem;
          }
          @media (max-width: 768px) {
            .custom-scrollbar {
              max-height: calc(100vh - 16rem) !important;
            }
          }
          
          /* For Firefox */
          * {
            scrollbar-width: thin;
            scrollbar-color: #E5E7EB transparent;
          }
        `}</style>
        <div className="min-w-[300px]"> {/* Ensures minimum width for content */}
          {TelegramData.map((ele, i) => {
            const isOpen = openIndexes.includes(i);
            return (
              <div
                key={i}
                className="bg-white rounded-lg overflow-hidden shadow-sm border border-gray-100 mb-4"
              >
                <div className="space-y-4">
                  {ele.videoId && (
                    <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-t-lg">
                      <YouTube
                        videoId={ele.videoId}
                        className="absolute top-0 left-0 w-full h-full"
                        opts={opts}
                        onReady={handlePlayerReady}
                        ref={playerRef}
                      />
                    </div>
                  )}

                  <button
                    className="w-full flex items-center justify-between px-4 py-3 text-base font-medium text-gray-900 hover:bg-gray-50 transition-colors"
                    onClick={() => toggleIndex(i)}
                  >
                    <span className="text-left flex-1 mr-2">{ele.linkContain}</span>
                    <span className="flex-shrink-0">
                      {isOpen ? (
                        <ChevronUp className="h-5 w-5 text-gray-500" />
                      ) : (
                        <ChevronDown className="h-5 w-5 text-gray-500" />
                      )}
                    </span>
                  </button>

                  <div
                    className={`px-4 pb-4 space-y-2 text-gray-600 ${
                      isOpen ? "block" : "hidden"
                    }`}
                  >
                    {[ele.step1, ele.step2, ele.step3, ele.step4, ele.step5, ele.step6, ele.step7]
                      .filter(Boolean)
                      .map((step, index) => (
                        <p key={index} className="text-sm leading-relaxed">
                          {step}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TelegramSteps;