import React from "react";
import { XIcon, CheckCircle } from "lucide-react";
import Checked from "../assests/checked.svg";
import { encryptApiKey, decryptApiKey } from "../utils/cryptoUtils";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

const digioCheck = process.env.REACT_APP_DIGIO_CHECK;

const PaymentSuccessModal = ({
  setPaymentSuccess,
  setSelectedCard,
  specificPlanDetails,
  setOpenSubscribeModel,
  setDigioModalOpen,
  openDigioModal,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center  p-4 overflow-y-auto z-[1000]">
      <div className="bg-white w-full max-w-[622px] rounded-md overflow-hidden flex flex-col z-100  mt-12">
        <div className="flex justify-between items-center px-4 sm:pl-8 sm:pr-4 py-4 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]">
          <h2 className="font-['Montserrat'] text-lg sm:text-[20px] font-bold leading-[22px] text-left">
            Investment in {specificPlanDetails?.name}
          </h2>
          <button
            onClick={() => {
              if (digioCheck === "afterPayment") {
                if (window.location.pathname === "/") {
                  setPaymentSuccess(false);
                  setSelectedCard();
                  openDigioModal();
                } else {
                  setPaymentSuccess(false);
                  setSelectedCard();
                  setDigioModalOpen(true);
                  if (specificPlanDetails?.type === "model portfolio") {
                    setOpenSubscribeModel(true);
                  }
                }
              } else {
                if (window.location.pathname === "/") {
                  setPaymentSuccess(false);
                  setSelectedCard();
                } else {
                  setPaymentSuccess(false);
                  setSelectedCard();
                  if (specificPlanDetails?.type === "model portfolio") {
                    setOpenSubscribeModel(true);
                  }
                }
              }
            }}
            className="text-gray-300 hover:text-gray-700"
          >
            <XIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="flex-1 flex flex-col items-center justify-center text-center px-4 py-6 sm:py-8">
          <img
            src={Checked}
            alt="Success"
            className="w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] mb-4"
          />
          <h3 className="font-poppins text-xl sm:text-2xl font-semibold mb-2 w-full max-w-[318px]">
            Payment Successful!
          </h3>
          <p className="font-poppins text-[#666666] text-base font-normal leading-6 w-full max-w-[400px]">
            Thank you for subscribing.
            <br />
            You will soon receive an email with the next steps!
          </p>

          <div className="w-full max-w-[500px] max-h-[280px] overflow-y-auto mt-6 sm:mt-8 ">
            <div className="flex items-center justify-center mb-6">
              <div className="flex-grow h-px bg-gray-300"></div>
              <span className="px-4 font-poppins text-gray-500 text-sm">
                IMPORTANT POINTS TO NOTE
              </span>
              <div className="flex-grow h-px bg-gray-300"></div>
            </div>

            <ul className="space-y-3 sm:space-y-4 text-left   sm:max-h-none sm:overflow-y-visible">
              <li className="flex items-start">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                <span className="font-poppins text-[#666666] text-sm">
                  You will receive an email with onboarding details and payment
                  receipt within 6-12 hours.
                </span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                <span className="font-poppins text-[#666666] text-sm">
                  The advisor sends recommendations based on market conditions.
                  There may be gaps of several days or weeks depending on market
                  conditions and opportunities
                </span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                <span className="font-poppins text-[#666666] text-sm">
                  When recommendations are sent, you'll receive them via
                  WhatsApp, email, and the investor portal (link provided in the
                  email). You won’t be added to a WhatsApp group;
                  recommendations will be sent directly to you.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessModal;
