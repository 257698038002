import React from "react";
import formatCurrency from "../../utils/formatCurrency";
import { Info } from "lucide-react";

const BespokePortfolioAccountData = ({ style, data }) => {
  const calculateTotals = (data) => {
    const totals = data.reduce(
      (acc, item) => {
        const { avgPrice, ltp, quantity } = item.bespoke_holding;

        const investedValue = quantity * avgPrice;
        const currentValue = quantity * ltp;
        const netReturns = currentValue - investedValue;

        acc.totalInvested += investedValue;
        acc.totalCurrent += currentValue;
        acc.totalNetReturns += netReturns;

        return acc;
      },
      { totalInvested: 0, totalCurrent: 0, totalNetReturns: 0 }
    );

    totals.netReturnsPercentage =
      totals.totalInvested > 0
        ? (totals.totalNetReturns / totals.totalInvested) * 100
        : 0;

    // Format values to 2 decimal places
    return {
      totalInvested: totals.totalInvested.toFixed(2),
      totalCurrent: totals.totalCurrent.toFixed(2),
      totalNetReturns: totals.totalNetReturns.toFixed(2),
      netReturnsPercentage: totals.netReturnsPercentage.toFixed(2),
    };
  };

  const result = calculateTotals(data);

  return (
    <div className="relative px-2 lg:px-0 py-4 lg:py-0 mt-8 lg:mt-4">
      {/* Section Header */}
      <div className="flex items-center justify-between mb-4 px-2">
        <div className="flex items-center space-x-2">
          <h2 className="text-lg font-semibold text-gray-900">Bespoke Portfolio Performance</h2>
          <div className="group relative">
            <Info className="w-5 h-5 text-gray-400 hover:text-gray-600 cursor-help" />
            <div className="hidden group-hover:block absolute left-0 top-full mt-2 w-64 p-3 bg-white rounded-lg shadow-xl text-sm text-gray-600 z-10">
              Performance metrics for your personalized investment portfolio based on advisor recommendations
            </div>
          </div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-2 lg:flex lg:gap-x-4">
        {/* Total Invested */}
        <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white w-full px-4 py-2 lg:px-6 lg:py-4">
          <div className={style.analyticsHead}>Total Invested</div>
          <div className={`${style.analyticsValue} text-xs sm:text-sm md:text-[18px] lg:text-[26px]`}>
            {result?.totalInvested
              ? `₹${formatCurrency(parseInt(result?.totalInvested))}`
              : 0}
          </div>
        </div>

        {/* Total Current */}
        <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white w-full px-4 py-2 lg:px-6 lg:py-4">
          <div className={style.analyticsHead}>Total Current</div>
          <div className={`${style.analyticsValue} text-xs sm:text-sm md:text-[18px] lg:text-[26px]`}>
            {result?.totalCurrent
              ? `₹${formatCurrency(parseInt(result?.totalCurrent))}`
              : 0}
          </div>
        </div>

        {/* Absolute Returns */}
        <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white w-full px-4 py-2 lg:px-6 lg:py-4">
          <div className={style.analyticsHead}>Absolute Returns</div>
          {result?.totalNetReturns > 0 ? (
            <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
              +₹{formatCurrency(Math.abs(result?.totalNetReturns))}
            </div>
          ) : result?.totalNetReturns < 0 ? (
            <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
              -₹{formatCurrency(Math.abs(result?.totalNetReturns))}
            </div>
          ) : (
            <div className="text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
              0
            </div>
          )}
        </div>

        {/* Returns Percentage */}
        <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white w-full px-4 py-2 lg:px-6 lg:py-4">
          <div className={style.analyticsHead}>Returns Percentage</div>
          {result?.netReturnsPercentage > 0 ? (
            <div className="text-[#16A085] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
              +{Math.abs(result?.netReturnsPercentage)}%
            </div>
          ) : result?.netReturnsPercentage < 0 ? (
            <div className="text-[#E43D3D] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
              -{Math.abs(result?.netReturnsPercentage)}%
            </div>
          ) : (
            <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
              0
              <sub className="text-[12px] text-[#000000] font-semibold font-poppins mt-1 px-[4px] py-[2px] rounded-md ml-1">
                0.00%
              </sub>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BespokePortfolioAccountData;