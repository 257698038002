import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { InfoIcon } from "lucide-react";
import toast from "react-hot-toast";

import DeleteModal from "./DeleteModal";
import FormatDateTime from "../../utils/formatDateTime";
import server from "../../utils/serverConfig";
import EditClientModal from "../ClientListDetailsSection/EditClientModal";
import AddClientForm from "./AddClientForm";
import ClientListDetailsTableHeader from "./ClientListDetaisTable/ClientListDetailsTableHeader";
import ClientListDetailsTableBody from "./ClientListDetaisTable/ClientListDetailsTableBody";
import { encryptApiKey } from "../../utils/cryptoUtils";

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;

const ClientListNewTable = ({
  userId,
  setIsDataFetching,
  allClientsData,
  allGroupsData,
  searchQuery,
  getAllClientsData,
}) => {
  const [data, setData] = useState(allClientsData);
  const [startDate, setStartDate] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [planName, setPlanName] = useState();
  const allGroupsName =
    allGroupsData && allGroupsData?.flatMap((item) => item.groupName);

  const [expandedRows, setExpandedRows] = useState({});

  const [dropdownOpen, setDropdownOpen] = useState({});
  const [newClientVisible, setNewClientVisible] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const [showAddClientForm, setShowAddClientForm] = useState(false);

  const [newClient, setNewClient] = useState({
    clientName: "",
    email: "",
    phone: "",
    groups: ["All Client"],
    location: "",
    telegram: "",
    pan: "",
    creationDate: FormatDateTime(new Date()),
    comments: "",
  });

  const [newSubscription, setNewSubscription] = useState({
    startDate: FormatDateTime(new Date()),
    plan: "",
    capital: 0,
    charges: 0,
    invoice: 0,
    expiry: FormatDateTime(new Date()),
  });

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [showSubscriptionDeleteModal, setShowSubscriptionDeleteModal] =
    useState(false);
  const [showSubscriptionEditModal, setShowSubscriptionEditModal] =
    useState(false);

  const [clientToEdit, setClientToEdit] = useState(null);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [subscriptionClientId, setSubscriptionClientId] = useState(null);
  const [tableIndex, setTableIndex] = useState(null);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);
  const [newSubRow, setNewSubRow] = useState(false);

  const [deleteUserReason, setDeleteUserReason] = useState();
  const dropdownRef = useRef(null);

  useEffect(() => {
    setData(allClientsData);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [allClientsData]);

  // pagination start
  const itemsPerPage = 11; // Show 30 items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  useEffect(() => {
    const filteredAndSortedData = data
      .filter((user) =>
        user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by latest

    setFilteredData(filteredAndSortedData);
    setCurrentPage(1); // Reset to page 1 after every new search
  }, [searchQuery, data]);
  useEffect(() => {
    // Apply pagination to the filtered data
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedData(filteredData.slice(startIndex, endIndex));
  }, [filteredData, currentPage]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
    if (totalPages <= maxVisiblePages + 4) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) pages.push(i);
      if (endPage < totalPages - 1) pages.push("...");
      pages.push(totalPages);
    }
    return pages;
  };

  const toggleRow = (index) => {
    setExpandedRows((prev) => {
      const newExpandedRows = {};

      // Expand the current row or collapse if it's already expanded
      if (prev[index]) {
        // Collapse the currently expanded row
        return newExpandedRows;
      } else {
        // Expand the new row and collapse any previously expanded row
        newExpandedRows[index] = true;
        return newExpandedRows;
      }
    });
  };

  const toggleDropdown = (index) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleGroupChange = (group) => {
    setNewClient((prevClient) => {
      let updatedGroups;

      if (group === "All Client") {
        // If "All Client" is being toggled, do nothing (it should always be present)
        return prevClient;
      } else {
        const isGroupPresent = prevClient.groups.includes(group);
        if (isGroupPresent) {
          // Remove the group if it's already present
          updatedGroups = prevClient.groups.filter((g) => g !== group);
        } else {
          // Add the group if it's not present
          updatedGroups = [...prevClient.groups, group];
        }
      }

      // Ensure "All Client" is always included
      if (!updatedGroups.includes("All Client")) {
        updatedGroups.unshift("All Client");
      }

      return { ...prevClient, groups: updatedGroups };
    });
  };

  const handleAddRow = () => {
    setShowAddClientForm(true);
  };

  const handleAddSubRow = (index) => {
    setNewSubRow(true);
    setExpandedRows((prev) => ({
      ...prev,
      [index]: 1,
    }));
  };

  const handleCancel = () => {
    setNewClientVisible(false);
  };

  const handleCancelSubRow = (index) => {
    setNewSubRow(false);
    // setExpandedRows((prev) => ({
    //   ...prev,
    //   [index]: false,
    // }));
  };

  const handleEditClientModal = (client) => {
    setShowEditModal(true);
    setClientToEdit(client);
  };

  const handleDeleteModal = (client) => {
    setShowDeleteModal(true);
    setClientToDelete(client);
  };

  const handleCommentUpdateModal = (client) => {
    setShowSendMessageModal(true);
    setClientToEdit(client);
  };

  const handleAddClient = async () => {
    const newClientData = {
      ...newClient,
      subscriptions: [], // You can modify this as per your need
      groups: newClient.groups,
    };

    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify({
            userId: userId,
            clientData: newClientData,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client added successfully:", result);

        // Reset the newClient form
        setNewClient({
          clientName: "",
          email: "",
          phone: "",
          groups: ["All Client"],
          location: "",
          telegram: "",
          pan: "",
          creationDate: FormatDateTime(new Date()),
          comments: "",
        });

        setNewClientVisible(false);
        setIsDataFetching(true);
        getAllClientsData(userId);
        toast.success(
          `New Client ${newClientData.clientName} added successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to add client :", result.message);
        toast.error(`Failed to add new client ${newClientData.clientName} !`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toast.error(`Error in adding new client ${newClientData.clientName} !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const [editLoading, setEditLoading] = useState(false);
  const handleUpdateClient = async (updatedClientData) => {
    setEditLoading(true);
    const newSubscription = {
      startDate: new Date(startDate),
      plan: planName, // Assuming the response contains a plan
      capital: "", // Assuming the response contains capital
      charges: 0, // Assuming the response contains charges
      invoice: "", // Assuming the response contains invoice
      expiry: new Date(expiryDate), // Assuming the response contains expiry date
    };
    const allData = {
      clientName: updatedClientData.clientName,
      email: updatedClientData.clientEmail,
      phone: updatedClientData.phone,
      groups: ["All Client", planName],
      location: updatedClientData.location,
      telegram: updatedClientData.telegram,
      pan: updatedClientData.pan,
      country_code: updatedClientData?.countryCode,
      creationDate: new Date(),
      subscriptions: [
        {
          ...newSubscription, // Attach the new subscription here
        },
      ],
    };

    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify({
            userId,
            clientData: allData,
            DateofBirth: dateOfBirth || "",
            advisorName: advisorTag,
          }),
        }
      );

      if (response.ok) {
        setEditLoading(false);
        getAllClientsData(userId);
        toast.success("Client details updated successfully!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
        });
      } else {
        throw new Error("Failed to add client");
      }
    } catch (error) {
      console.error("Error adding client:", error);
      setEditLoading(false);
      toast.error("Failed to update client details", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
    }
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDeleteClient = async (client, selectedGroup) => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/clientlistdatas/group`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify({
            email: client.email,
            groupToDelete: selectedGroup,
            reason: deleteUserReason ? deleteUserReason : "NA",
            refund: 0,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        getAllClientsData(userId);
        setShowDeleteModal(false);
        setDeleteLoading(false);
        toast.success(
          ` ${client.clientName} have been removed successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to remove client:", result.message);
        getAllClientsData(userId);
        toast.error(
          `Failed to remove ${client.clientName} from client list !`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
        setShowDeleteModal(false);
        setDeleteLoading(false);
      }
    } catch (error) {
      console.error("Error removing client:", error);
      toast.error(`Error in deleting ${client.clientName} from client list !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleDeleteSubscriptionModal = (index, client, subId) => {
    setShowSubscriptionDeleteModal(true);

    const subData = client.subscriptions.filter((item) => item.subId === subId);
    setSubscriptionToDelete(subData[0]);
    setSubscriptionClientId(client.clientId);
    setTableIndex(index);
  };

  const handleEditSubscriptionModal = (client, planName) => {
    // Find subscription by plan name
    const subData = client?.subscriptions?.find(
      (item) => item?.plan === planName
    );

    if (subData) {
      setExpiryDate(subData?.expiry);
      setStartDate(subData?.startDate);
      setPlanName(planName);
    } else {
      console.warn("No matching subscription found for plan:", planName);
    }
    setClientToEdit({
      ...client,
      clientEmail: client.email, // Map email to clientEmail for the form
      creationDate: new Date(client.creationDate), // Ensure date is a Date object
    });

    setShowSubscriptionEditModal(true);
  };

  const handleSubscriptionChange = (e) => {
    const { name, value } = e.target;

    setNewSubscription((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddPlan = async (index, clientId) => {
    // console.log("New SUB -----", newSubscription);
    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify({
            clientId: clientId,
            newSubscription: newSubscription,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("newSubscription added successfully:", result);

        setNewSubscription({
          startDate: FormatDateTime(new Date()),
          plan: "",
          capital: 0,
          charges: 0,
          invoice: 0,
          expiry: FormatDateTime(new Date()),
        });
        getAllClientsData(userId);
        setNewSubRow(false);

        toast.success(
          `Subscription plan ${newSubscription?.plan} for client ${data[index]?.clientName} added successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to add subscription:", result.message);
        toast.error(
          `Failed to add subscription for client ${data[index]?.clientName}`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      }
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription details !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleDeleteSubscription = async (clientId, subId) => {
    const client = allClientsData.filter((item) => item.clientId === clientId);
    const sub = client[0].subscriptions.filter((item) => item.subId === subId);

    try {
      const response = await fetch(
        `${server.server.baseUrl}api/delete-subscriptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify({
            clientId: clientId,
            subId: subId,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        getAllClientsData(userId);
        toast.success(
          `Subscription plan ${sub[0].plan} for ${client[0].clientName} deleted successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        toast.error(`Failed to delete subscription ${sub[0].plan} !`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      toast.error(`Error in deleting subscription  ${sub[0].plan} !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="relative w-full h-full max-h-[calc(100vh-225px)] lg:max-h-[calc(100vh-50px)] overflow-auto mb-4"
    >
      {/* Info Banner */}
      <div className="bg-[#2056DF] text-white mb-6 shadow-lg">
        <div className="max-w-full px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center space-x-3">
            <InfoIcon className="h-5 w-5 text-gray-300" />
            <div>
              <h2 className="text-2xl font-semibold">
                Client Management System
              </h2>
              <p className="text-lg text-white/90 mt-2 ">
                Efficiently manage your client portfolio with comprehensive
                tools for client data management, organization of clients into
                groups for advice generation, etc.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto max-w-full">
        <div>
          <div className="overflow-y-auto">
            <table className="min-w-full  table-fixed">
              <ClientListDetailsTableHeader handleAddRow={handleAddRow} />
              <ClientListDetailsTableBody
                dropdownOpen={dropdownOpen}
                filteredData={filteredData}
                handleCancelSubRow={handleCancelSubRow}
                handleDeleteSubscriptionModal={handleDeleteSubscriptionModal}
                handleEditSubscriptionModal={handleEditSubscriptionModal}
                handleAddPlan={handleAddPlan}
                newSubscription={newSubscription}
                handleSubscriptionChange={handleSubscriptionChange}
                newSubRow={newSubRow}
                expandedRows={expandedRows}
                handleDeleteModal={handleDeleteModal}
                handleAddSubRow={handleAddSubRow}
                toggleRow={toggleRow}
                paginatedData={paginatedData}
                handleCancel={handleCancel}
                handleAddClient={handleAddClient}
                handleInputChange={handleInputChange}
                newClient={newClient}
                handleGroupChange={handleGroupChange}
                allGroupsName={allGroupsName}
                toggleDropdown={toggleDropdown}
                dropdownRef={dropdownRef}
                newClientVisible={newClientVisible}
              />
            </table>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center p-4 space-x-1">
        {/* Previous Button */}
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
        >
          Previous
        </button>

        {/* Page Numbers */}
        {getPageNumbers().map((page, index) =>
          page === "..." ? (
            <span key={index} className="px-3 py-1 text-gray-600">
              ...
            </span>
          ) : (
            <button
              key={index}
              onClick={() => goToPage(page)}
              className={`px-3 py-1 text-sm rounded ${
                page === currentPage ? "bg-[#2056DF] text-white" : "bg-gray-300"
              }`}
            >
              {page}
            </button>
          )
        )}

        {/* Next Button */}
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {showEditModal && (
        <EditClientModal
          client={clientToEdit}
          onClose={() => setShowEditModal(false)}
          onUpdate={handleUpdateClient}
          setExpiryDate={setExpiryDate}
          expiryDate={expiryDate}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          editLoading={editLoading}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          client={clientToDelete}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleDeleteClient}
          deleteUserReason={deleteUserReason}
          setDeleteUserReason={setDeleteUserReason}
        />
      )}

      {showSubscriptionDeleteModal && (
        <DeleteModal
          client={subscriptionToDelete}
          onClose={() => setShowSubscriptionDeleteModal(false)}
          onDelete={handleDeleteClient}
          deleteUserReason={deleteUserReason}
          setDeleteUserReason={setDeleteUserReason}
          deleteLoading={deleteLoading}
        />
      )}

      {showSubscriptionEditModal && (
        <EditClientModal
          client={clientToEdit}
          onClose={() => setShowSubscriptionEditModal(false)}
          onUpdate={handleUpdateClient}
          setExpiryDate={setExpiryDate}
          expiryDate={expiryDate}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          editLoading={editLoading}
          startDate={startDate}
          setStartDate={setStartDate}
        />
      )}

      {showAddClientForm && (
        <AddClientForm
          existingGroups={allGroupsName || []}
          onClose={() => setShowAddClientForm(false)}
          userId={userId}
          setIsDataFetching={setIsDataFetching}
          getAllClientsData={getAllClientsData}
        />
      )}
    </motion.div>
  );
};

export default ClientListNewTable;
