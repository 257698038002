import React, { useState, useCallback, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import moment from "moment";
import server from "../../utils/serverConfig";
import { XIcon, Plus, Trash2Icon } from "lucide-react";
import { debounce } from "lodash";
import { io } from "socket.io-client";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import LoadingSpinner from "../../components/LoadingSpinner";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full py-1.5 px-2 bg-white text-gray-900 placeholder:text-gray-400 placeholder:font-normal   font-medium rounded-md ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9]",

  selectDiv:
    "flex flex-row justify-between items-center mx-2 px-2 py-2 rounded-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-black transition ease-in duration-200 cursor-pointer",
  inputStartDiv: " relative w-full   ",
};
const StockDistributionTable = ({
  handleRemoveAdviceEntry,
  handleAddAdviceEntry,
  adviceEntries,
  handleInputChange,
  handleSymbolSelect,
  handleAdviceChange,
  handleIncrement,
  handleDecrement,
  minimumPortfolioAmount,
  isOpen,
  dropdownRef,
}) => {
  return (
    <div className=" h-full ">
      <div className="border-t-[1px] border-[#000000]/10   max-w-[100vw] h-[350px] md:h-[440px] overflow-auto custom-scroll bg-[#ffffff]">
        <table className="w-full">
          <thead className="bg-[#f5f5f5] text-sm text-left sticky top-0 z-50">
            <tr className="border-b-[1px]   border-[#000000]/10">
              <th className=" text-[12px]  md:text-[13px] border-l-[1px] border-r-[1px] border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-1 px-2">
                <div className="flex items-center justify-center bg-black w-[25px] h-[25px] mx-auto rounded-[4px]">
                  <Plus
                    className="cursor-pointer text-white  w-[20px] h-[20px] "
                    strokeWidth={3}
                    onClick={handleAddAdviceEntry}
                  />
                </div>
              </th>
              <th className="text-[12px] md:text-[13px] border-r-[1px] text-center  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-1">
                Symbol
                <span className=" text-red-500 text-[12px] md:text-[14px]">
                  *
                </span>
              </th>

              <th className="text-[12px] md:text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 text-center font-poppins font-medium py-3 px-1">
                Allocation (in%)
                <span className=" text-red-500 text-[12px] md:text-[14px]">
                  *
                </span>
              </th>

              <th className="text-[12px] md:text-[13px] min-w-[130px] border-r-[1px]  border-[#000000]/10 text-center text-[#000000]/80 font-poppins font-medium py-3 px-1">
                Min. Investment Value
                <span className=" text-red-500 text-[12px] md:text-[14px]">
                  *
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {adviceEntries?.map((entry, index) => {
              return (
                <tr
                  key={entry.id}
                  className="relative border-b-[1px]   border-[#000000]/10"
                >
                  {adviceEntries.length > 1 ? (
                    <td
                      onClick={() => handleRemoveAdviceEntry(index)}
                      className="cursor-pointer  text-[12px] md:text-[14px] border-l-[1px] border-r-[1px] border-[#000000]/10 text-[#000000]/60 font-poppins font-medium px-1 py-2 "
                    >
                      <div className="flex items-center justify-center">
                        <Trash2Icon className="w-4 h-4 hover:text-[#ff0000]" />
                      </div>
                    </td>
                  ) : (
                    <td className="border-l-[1px] border-r-[1px] cursor-pointer "></td>
                  )}
                  <td className="text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                    <div className="">
                      <input
                        type="text"
                        placeholder="Enter Stock Symbol "
                        className={`${style.inputBox} min-w-[100px]`}
                        value={entry.inputValue}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        autoComplete="off"
                      />

                      {entry?.symbols?.length > 0 && (
                        <div className="w-[300px] absolute left-[48px] right-0 top-[50px] mt-1 bg-white custom-scroll flex flex-col rounded-lg pt-2 pb-2 z-50 box-border border-2 border-solid border-[ rgba(185, 192, 187, 0.7)] max-h-48 overflow-y-auto">
                          {entry.symbols.map((ele, idx) => {
                            return (
                              <div
                                key={idx}
                                onClick={() => {
                                  handleSymbolSelect(
                                    index,
                                    ele.symbol,
                                    ele.segment
                                  );
                                }}
                                className={`pl-3 medium ${style.selectDiv}`}
                              >
                                <span> {ele.symbol}</span>
                                <span> {ele.segment}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {/* {entry.symbols.length === 0 && 
                      entry.inputValue.length > 4 &&
                      (
                          <div
                            ref={dropdownRef}
                            className="w-[300px] absolute left-[48px] right-0 top-[50px] mt-1 bg-white custom-scroll flex flex-col rounded-lg pt-2 pb-2 z-50 box-border border-2 border-solid border-[ rgba(185, 192, 187, 0.7)] max-h-48 overflow-y-auto"
                          >
                            <div className={`pl-3 medium ${style.selectDiv}`}>
                              <span>Symbol Incorrect or Unavailable</span>
                            </div>
                          </div>
                        )} */}
                    </div>
                  </td>

                  <td className=" text-[12px] md:text-[14px] border-r-[1px]  text-left  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                    <div className="flex  flex-row items-center justify-between  text-[12px] text-[#000000]/80 font-poppins font-semibold px-1 lg:px-2">
                      <button
                        className=" text-[12px] lg:text-[16px]  cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
                        onClick={() => handleDecrement(index)}
                      >
                        -
                      </button>

                      <input
                        type="number"
                        className="flex flex-1 items-center justify-center bg-transparent w-full  lg:w-[45px]  h-[20px] font-poppins text-[12px] lg:text-[15px] mx-1 text-center rounded"
                        value={entry?.value > 0 ? entry?.value : 0}
                        onChange={(e) =>
                          handleAdviceChange(
                            index,
                            "value",
                            parseInt(e.target.value)
                          )
                        }
                        placeholder="Value"
                      />
                      <button
                        className=" text-[12px] lg:text-[16px]  cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
                        onClick={() => handleIncrement(index)}
                      >
                        +
                      </button>
                    </div>
                  </td>

                  <td className=" text-[12px] mr-1 md:text-[14px] border-r-[1px]  text-right  border-[#000000]/10 text-[#000000] font-poppins font-medium px-2 py-2">
                    ₹
                    {entry?.symbol &&
                    minimumPortfolioAmount?.min_investment_value[entry.symbol]
                      ? parseFloat(
                          minimumPortfolioAmount?.min_investment_value[
                            entry.symbol
                          ]
                        ).toFixed(2)
                      : 0}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StockDistributionTable;
