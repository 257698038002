import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import server from "../../utils/serverConfig";
import { motion, AnimatePresence } from "framer-motion"

const AdminSettings = ({ adminEmail, closeSettingsModal, initialTab }) => {
  const [activeTab, setActiveTab] = useState(initialTab || "disclaimer");
  const [disclaimer, setDisclaimer] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [rationale, setRationale] = useState("");
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSettings();
  }, [adminEmail]);

  const fetchSettings = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${server.server.baseUrl}api/admin/${adminEmail}`
      );
      if (response.ok) {
        const { success, data } = await response.json();
        if (success) {
          setDisclaimer(data.disclaimer || "");
          setTermsAndConditions(data.termsAndConditions || "");
          setRationale(data.rationale || "");
        } else {
          throw new Error("Failed to fetch settings");
        }
      } else {
        throw new Error("Failed to fetch settings");
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
      showNotification(false, "Failed to load settings. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showNotification = (success, message) => {
    toast[success ? "success" : "error"](message, {
      duration: 3000,
      position: "top-center",
    });
  };

  const handleSave = async () => {
    setSaving(true);
    const payload = {
      email: adminEmail,
      disclaimer,
      termsAndConditions,
      rationale,
    };

    try {
      const response = await fetch(
        `${server.server.baseUrl}api/update-terms-conditions`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (response.ok && data.status === 200) {
        showNotification(true, "Settings updated successfully");
        closeSettingsModal();
      } else {
        throw new Error(data.message || "Failed to update settings");
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      showNotification(false, `Failed to update settings: ${error.message}`);
    } finally {
      setSaving(false);
    }
  };

  const TabButton = ({ name, label }) => (
    <button
      className={`px-4 py-2 font-medium text-sm transition-all duration-300 ease-in-out ${
        activeTab === name
          ? "text-black border-b-2 border-black"
          : "text-gray-500 hover:text-black"
      }`}
      onClick={() => setActiveTab(name)}
    >
      {label}
    </button>
  );

  const InfoIcon = ({ content }) => (
    <div className="group relative inline-block ml-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 text-gray-400 cursor-help"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        />
      </svg>
      <div className="opacity-0 w-48 bg-black text-white text-xs rounded py-2 px-3 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 group-hover:opacity-100 transition-opacity duration-300">
        {content}
        <svg
          className="absolute text-black h-2 w-full left-0 top-full"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
        >
          <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
        </svg>
      </div>
    </div>
  );

  if (loading) {
    return <div>Loading...</div>;
  }
  const handleCloseModal = (e) => {
    e.stopPropagation();
    console.log("Closing settings modal");
    // closeSettingsModal();
  };
  return (
    <AnimatePresence mode="wait">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1}}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}


      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      onClick={handleCloseModal}
    >
      <div
        className="relative top-20 mx-auto p-5 border w-full max-w-2xl shadow-lg rounded-md bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <Toaster />
        <h2 className="text-2xl font-semibold mb-4">Admin Settings</h2>

        <div className="flex mb-4">
          <TabButton name="disclaimer" label="Disclaimer" />
          <TabButton name="terms" label="Terms & Conditions" />
          <TabButton name="rationale" label="Rationale" />
        </div>

        <div className="mb-4">
          {activeTab === "disclaimer" && (
            <>
              <h3 className="text-lg font-semibold mb-2 flex items-center">
                Disclaimer
                <InfoIcon content="Legal statement to limit liability and provide information to users." />
              </h3>
              <textarea
                value={disclaimer}
                onChange={(e) => setDisclaimer(e.target.value)}
                className="w-full h-64 p-2 border rounded"
                placeholder="Enter disclaimer..."
              />
            </>
          )}
          {activeTab === "terms" && (
            <>
              <h3 className="text-lg font-semibold mb-2 flex items-center">
                Terms & Conditions
                <InfoIcon content="Rules and guidelines for using the service or product." />
              </h3>
              <textarea
                value={termsAndConditions}
                onChange={(e) => setTermsAndConditions(e.target.value)}
                className="w-full h-64 p-2 border rounded"
                placeholder="Enter terms and conditions..."
              />
            </>
          )}
          {activeTab === "rationale" && (
            <>
              <h3 className="text-lg font-semibold mb-2 flex items-center">
                Rationale
                <InfoIcon content="Explanation of the reasoning behind decisions or policies." />
              </h3>
              <textarea
                value={rationale}
                onChange={(e) => setRationale(e.target.value)}
                className="w-full h-64 p-2 border rounded"
                placeholder="Enter rationale..."
              />
            </>
          )}
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            disabled={saving}
            className="px-4 py-2 bg-blue-500 text-white rounded mr-2 hover:bg-blue-600 transition-colors disabled:bg-blue-300"
          >
            {saving ? "Saving..." : "Save"}
          </button>
          <a href="/admin">
            <button
              onClick={handleCloseModal}
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 transition-colors"
            >
              Close
            </button>
          </a>
        </div>
      </div>
     </motion.div>
      </AnimatePresence>  
      
    );
};

export default AdminSettings;
