import React from "react";
import formatCurrency from "../../utils/formatCurrency";

const ModalPortfolioAccountData = ({
  style,
  totalInvested = 1000000,
  totalCurrent = 2000000,
  totalNetReturns = 100,
  netReturnsPercentage = 60,
}) => {
  return (
    <>
      <div className=" relative px-2 lg:px-0 py-4 lg:py-0 mt-8 lg:mt-4 ">
        <div className=" grid grid-cols-2  gap-x-3 gap-y-3 lg:flex lg:gap-x-4 ">
          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white  w-full px-4 py-2  lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>Invested Amount</div>
            <div
              className={`${style.analyticsValue} text-xs sm:text-sm md:text-[18px]  lg:text-[26px]`}
            >
              {totalInvested
                ? `₹${formatCurrency(parseInt(totalInvested))}`
                : "-"}
            </div>
          </div>
          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white  w-full px-4 py-2  lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>Current Amount</div>
            <div
              className={`${style.analyticsValue} text-xs sm:text-sm md:text-[18px]  lg:text-[26px]`}
            >
              {totalCurrent
                ? `₹${formatCurrency(parseInt(totalCurrent))}`
                : "-"}
            </div>
          </div>

          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white  w-full px-4 py-2  lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>Absolute Returns</div>
            {totalNetReturns > 0 ? (
              <div className={`flex items-center`}>
                <div className="text-[#000000] text-xs sm:text-sm md:text-[18px]  lg:text-[26px]  font-semibold font-poppins">
                  {formatCurrency(Math.abs(totalNetReturns))}%
                </div>
                <sub className="text-[10px]  text-[#16A085] font-semibold font-poppins mt-1 px-[4px] py-[2px] rounded-md ml-1">
                  {Math.abs(netReturnsPercentage)}%
                </sub>
              </div>
            ) : totalNetReturns < 0 ? (
              <div className={`flex items-center`}>
                <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                  {formatCurrency(Math.abs(totalNetReturns))}%
                </div>
                <sub className="text-[12px]  text-[#E43D3D] font-semibold font-poppins mt-1 px-[4px] py-[2px]  rounded-md ml-1">
                  {Math.abs(netReturnsPercentage)}%
                </sub>
              </div>
            ) : (
              <div className="text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                {totalInvested ? formatCurrency(totalNetReturns) : "-"}%
                <sub className="text-sm font-semibold pt-4 ml-2">
                  {totalInvested && netReturnsPercentage ? (
                    <span
                      className={
                        totalInvested.totalnetReturnsPercentage > 0
                          ? "text-[#16A085]"
                          : "text-[#E43D3D]"
                      }
                    >
                      {netReturnsPercentage}%
                    </span>
                  ) : (
                    <span className="text-[#000000]/80">0.00%</span>
                  )}
                </sub>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white  w-full px-4 py-2  lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>Net Returns</div>
            {totalNetReturns > 0 ? (
              <div className={`flex items-center`}>
                <div className="text-[#000000] text-xs sm:text-sm md:text-[18px]  lg:text-[26px]  font-semibold font-poppins">
                  {formatCurrency(Math.abs(totalNetReturns))}%
                </div>
                <sub className="text-[10px]  text-[#16A085] font-semibold font-poppins mt-1 px-[4px] py-[2px] rounded-md ml-1">
                  {Math.abs(netReturnsPercentage)}%
                </sub>
              </div>
            ) : totalNetReturns < 0 ? (
              <div className={`flex items-center`}>
                <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                  {formatCurrency(Math.abs(totalNetReturns))}%
                </div>
                <sub className="text-[12px]  text-[#E43D3D] font-semibold font-poppins mt-1 px-[4px] py-[2px]  rounded-md ml-1">
                  {Math.abs(netReturnsPercentage)}%
                </sub>
              </div>
            ) : (
              <div className="text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                {totalInvested ? formatCurrency(totalNetReturns) : "-"}%
                <sub className="text-sm font-semibold pt-4 ml-2">
                  {totalInvested && netReturnsPercentage ? (
                    <span
                      className={
                        totalInvested.totalnetReturnsPercentage > 0
                          ? "text-[#16A085]"
                          : "text-[#E43D3D]"
                      }
                    >
                      {netReturnsPercentage}%
                    </span>
                  ) : (
                    <span className="text-[#000000]/80">0.00%</span>
                  )}
                </sub>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalPortfolioAccountData;
