import React from "react";
import Angleone from "../assests/AngleLogo.png";
import IIFL from "../assests/iifl_logo.png";
import Zerodha from "../assests/Zerodha.png";
import ICICI from "../assests/icici.png";
import Upstox from "../assests/upstox.svg";
import Kotak from "../assests/kotak.png";
import HDFC from "../assests/hdfc_securities.png";
import Dhan from "../assests/dhan.png";
import AliceBlue from "../assests/aliceblue.png";
import Fyers from "../assests/fyers.png";

const BrokerSection = () => {
  return (
    <section className="font-poppins pb-20 sm:pb-32 px-4 sm:px-6 lg:px-8">
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-gray-800">Our Trusted Brokers</h2>
      <p className="text-sm sm:text-base mb-8 text-gray-600">We partner with industry leaders to provide you the best options</p>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 sm:gap-6">
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={Angleone} alt="Angle One logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">Angle One</h3>
        </div>
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={IIFL} alt="IIFL logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">IIFL</h3>
        </div>
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={Zerodha} alt="Zerodha logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">Zerodha</h3>
        </div>
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={ICICI} alt="ICICI Direct logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">ICICI Direct</h3>
        </div>
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={Upstox} alt="Upstox logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">Upstox</h3>
        </div>
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={Kotak} alt="Kotak Securities logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">Kotak Securities</h3>
        </div>
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={HDFC} alt="HDFC Securities logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">HDFC Securities</h3>
        </div>
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={Dhan} alt="Dhan logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">Dhan</h3>
        </div>
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={AliceBlue} alt="Alice Blue logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">Alice Blue</h3>
        </div>
        <div className="flex items-center space-x-2 p-2 rounded-full bg-[#F9F9F8] w-full">
          <div className="w-8 h-8 relative flex-shrink-0">
            <img src={Fyers} alt="Fyers logo" className="w-full h-full object-contain" />
          </div>
          <h3 className="text-xs sm:text-sm font-bold text-gray-700">Fyers</h3>
        </div>
      </div>
      <p className="mt-10 sm:mt-14 text-gray-600 text-xs sm:text-sm">
        These are our current broker partners. We're continuously expanding our network to serve you better.
      </p>
    </div>
  </section>

)
}

export default BrokerSection