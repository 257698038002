import React, { useState, useCallback, useMemo } from "react";

import toast from "react-hot-toast";
import axios from "axios";
import server from "../../utils/serverConfig";
import { XIcon, PlusCircle } from "lucide-react";
import { debounce } from "lodash";
import NewPushStockRebalanceCard from "../StrategySection/NewPushStockRebalanceCard";
import Button from "react-bootstrap/Button";
import { Card } from "antd";
import { CardHeader, CardTitle } from "react-bootstrap";

import { encryptApiKey } from "../../utils/cryptoUtils";

const style = {
  inputBox:
    "w-full py-2 xl:px-6 md:py-2.5  bg-[#ffffff] text-[12px] md:text-[16px]  peer text-gray-900 placeholder-transparent font-poppins font-medium rounded-md  ring-1 hover:ring-1 ring-gray-200  hover:ring-[#D9D9D9] focus:outline-none focus:ring-1 focus:ring-[#D9D9D9]  transition ease-in duration-200 ",
};
const RebalanceModal = ({
  closeRebalanceModal,
  fileName,
  data,
  strategyDetails,
  getStrategyDetails,
}) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const [adviceEntries, setAdviceEntries] = useState(data);
  const [saveButton, setSaveButton] = useState(false);

  const handleAddAdviceEntry = () => {
    const newEntry = {
      symbol: "",
      exchange: "",
      segment: "EQUITY",
      inputValue: "",
      symbols: [],
      value: "",
      isNewAdded: true,
    };
    setAdviceEntries((prevEntries) => {
      const updatedEntries = [newEntry, ...prevEntries];
      return updatedEntries;
    });
    setSaveButton(false);
  };

  const handleRemoveAdviceEntry = (index) => {
    setAdviceEntries((prevEntries) => {
      if (prevEntries.length <= 0) {
        return prevEntries;
      }
      const updatedEntries = prevEntries.filter((_, i) => i !== index);

      return updatedEntries;
    });
    setSaveButton(false);
  };

  const handleAdviceChange = (index, field, value) => {
    setAdviceEntries((prevEntries) => {
      const updatedEntries = prevEntries.map((entry, i) => {
        if (i === index) {
          const isNewValue = entry.isNewAdded || entry[field] !== value / 100;
          return {
            ...entry,
            [field]: isNewValue ? value : value / 100,
            isNewAdded: true,
          };
        }
        return entry;
      });

      return updatedEntries;
    });
  };

  const handleSymbolSelect = (index, symbol, exchange) => {
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? {
            ...entry,
            symbol: symbol,
            symbols: [],
            inputValue: symbol,
            exchange: exchange,
          }
        : entry
    );
    setAdviceEntries(updatedEntries);
  };

  const handleIncrement = (index) => {
    setAdviceEntries((prevEntries) => {
      const updatedEntries = [...prevEntries];
      const entry = updatedEntries[index];
      const currentValue = parseFloat(entry.value) || 0;
      const incrementValue = entry.isNewAdded ? 1 : 0.01;
      updatedEntries[index] = {
        ...entry,
        value: (currentValue + incrementValue).toString(),
      };
      return updatedEntries;
    });
    setSaveButton(false);
  };

  const handleDecrement = (index) => {
    setAdviceEntries((prevEntries) => {
      const updatedEntries = [...prevEntries];
      const entry = updatedEntries[index];
      const currentValue = parseFloat(entry.value) || 0;
      const decrementValue = entry.isNewAdded ? 1 : 0.01;
      updatedEntries[index] = {
        ...entry,
        value: Math.max(0, currentValue - decrementValue).toString(),
      };
      return updatedEntries;
    });
    setSaveButton(false);
  };

  const fetchSymbols = async (index, inputValue) => {
    const data = JSON.stringify({
      symbol: inputValue,
      indices: false,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${server.ccxtServer.baseUrl}angelone/equity/symbol-search`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, symbols: response.data.match } : entry
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetchSymbols = useCallback(
    debounce((index, value) => {
      fetchSymbols(index, value);
    }, 300),
    []
  );

  const handleInputChange = (index, value) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, inputValue: value, symbol: value } : entry
      )
    );

    if (value.length >= 3) {
      debouncedFetchSymbols(index, value);
    }
    setSaveButton(false);
  };

  const calculateTotalAllocation = () => {
    return adviceEntries?.reduce((total, entry) => {
      // Only multiply by 100 if the entry is not new
      const entryValue = entry?.isNewAdded
        ? parseFloat(entry.value)
        : parseFloat(entry.value * 100);
      return total + (entryValue || 0);
    }, 0);
  };

  const totalAllocation = useMemo(
    () => calculateTotalAllocation(),
    [adviceEntries]
  );

  const isFormValid = () => {
    return (
      totalAllocation === 100 &&
      adviceEntries.every(
        (entry) => entry.symbol && entry.value && entry.value > 0
      )
    );
  };

  let convertedData = { model_portfolio: [] };

  adviceEntries?.forEach((item) => {
    const symbol = item?.symbol;
    let value = item?.isNewAdded
      ? parseFloat(item?.value / 100).toFixed(2)
      : parseFloat(item?.value).toFixed(2);
    const exchange = item.exchange;

    convertedData.model_portfolio.push({ symbol, value, exchange });
  });

  const [minimumPortfolioAmount, setMinimumPortfolioAmount] = useState();
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const handleMinimumPortfolioAmount = () => {
    setIsLoadingSave(true);
    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/minimum-portfolio-amount-new`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: convertedData,
    };

    axios
      .request(config)
      .then((response) => {
        setMinimumPortfolioAmount(response.data);
        setSaveButton(true);
        setIsLoadingSave(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsLoadingSave(false);
      });
  };

  const strategyId = strategyDetails?._id;
  const modelName = fileName; // removing regex for passing correct variable.

  const transformedAdviceEntries = adviceEntries?.map((entry) => ({
    ...entry,
    value: entry.isNewAdded ? entry.value / 100 : entry.value,
  }));
  const newAdviceEntries = transformedAdviceEntries;
  const totalInvestmentValue =
    minimumPortfolioAmount?.min_investment_amount.toFixed(2);

  const [isLoading, setIsLoading] = useState(false);

  function getNextRebalanceDate(frequency) {
    const today = new Date();
    let nextRebalance = new Date(today); // Start with today's date

    switch (frequency) {
      case "Every Day":
        nextRebalance.setDate(today.getDate() + 1);
        break;

      case "Every Week":
        nextRebalance.setDate(today.getDate() + 7);
        break;

      case "Every Month":
        nextRebalance.setMonth(today.getMonth() + 1);
        break;

      case "Every Quarter":
        nextRebalance.setMonth(today.getMonth() + 3);
        break;

      case "Every Year":
        nextRebalance.setFullYear(today.getFullYear() + 1);
        break;

      case "Need Basis":
        // If it's need basis, randomly add 2 or 3 days
        const randomDays = Math.floor(Math.random() * 2) + 10; // Will give either 2 or 3
        nextRebalance.setDate(today.getDate() + randomDays);
        break;

      default:
        console.log("Unknown frequency.");
        return null;
    }

    return nextRebalance;
  }

  const nextRebalanceDate = getNextRebalanceDate(strategyDetails?.frequency);
  const [researchReport, setResearchReport] = useState("");

  const handleModelPortfolio = (
    id,
    modelName,
    newAdviceEntries,
    totalInvestmentvalue
  ) => {
    setIsLoading(true);

    const filteredAdviceEntries = newAdviceEntries.map(
      ({ isNewAdded, ...rest }) => rest
    );

    const data = JSON.stringify({
      modelName: strategyDetails?.model_name,
      newAdviceEntries: filteredAdviceEntries,
      totalInvestmentvalue: totalInvestmentvalue,
      nextRebalanceDate: nextRebalanceDate,
    });

    let config = {
      method: "put",
      url: `${server.ccxtServer.baseUrl}rebalance/next-push/update-strategy/${id}`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        let whatsappData = JSON.stringify({
          advisor: advisorTag,
          modelName: strategyDetails?.model_name,
          planName: modelName,
        });

        let whatsappConfig = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}comms/new-rebalance-push`,
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          data: whatsappData,
        };

        await axios.request(whatsappConfig);

        console.log("res", response);

        if (researchReport) {
          const researchReportPayload = {
            link: researchReport,
            advisor: advisorTag,
            model_name: response?.data?.newModelId,
          };
          let config2 = {
            method: "post",
            url: `${server.ccxtServer.baseUrl}comms/mpf-research-report/${response?.data?.newModelId}`,
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
            data: researchReportPayload, // Send the JSON object directly
          };
          axios
            .request(config2)
            .then((response) => {
              console.log("res", response);
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
        setIsLoading(false);
        toast.success("Strategy updated successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });

        setTimeout(() => {
          getStrategyDetails();
          closeRebalanceModal();
        }, 3000);
      })
      .catch((error) => {
        console.error("Error updating strategy:", error);
        toast.error("Error in updating strategy!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setIsLoading(false);
        getStrategyDetails();
      });
  };

  const handleUpdateStrategy = () => {
    handleModelPortfolio(
      strategyId,
      modelName,
      newAdviceEntries,
      totalInvestmentValue
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/60 z-50 rounded-md">
      <div className="relative bg-white rounded-lg w-full max-w-4xl max-h-[95vh] flex flex-col">
        {/* Close Button */}
        <button
          onClick={closeRebalanceModal}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <XIcon className="h-6 w-6" />
        </button>

        {/* Header */}
        <div className="border-b p-6">
          <h2 className="text-xl font-bold">Add New Rebalance</h2>
        </div>

        {/* Table Header */}
        <div className="sticky top-0 bg-white z-10 grid grid-cols-8 lg:grid-cols-10 gap-4 p-4 border-b">
          <div className="col-span-1 flex justify-center">
            <button
              onClick={handleAddAdviceEntry}
              className="h-8 w-8 text-emerald-600 hover:text-emerald-700 hover:bg-emerald-50 rounded-full flex items-center justify-center"
            >
              <PlusCircle className="h-5 w-5" />
            </button>
          </div>
          <div className="col-span-3 lg:col-span-4 font-medium text-sm text-gray-600">
            Stock Name
          </div>
          <div className="col-span-2 font-medium text-sm text-gray-600 text-center">
            Allocation (%)
          </div>
          <div className="col-span-2 lg:col-span-3 font-medium text-sm text-gray-600 text-center">
            Investment Value
          </div>
        </div>

        {/* Stocks List */}
        <div className="flex-1 overflow-auto px-4">
          <div className="space-y-3 py-4">
            {adviceEntries?.map((entry, index) => (
              <NewPushStockRebalanceCard
                key={index}
                handleRemoveAdviceEntry={handleRemoveAdviceEntry}
                handleAddAdviceEntry={handleAddAdviceEntry}
                adviceEntries={adviceEntries}
                index={index}
                handleInputChange={handleInputChange}
                handleSymbolSelect={handleSymbolSelect}
                entry={entry}
                handleAdviceChange={handleAdviceChange}
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
                minimumPortfolioAmount={minimumPortfolioAmount}
              />
            ))}
          </div>
        </div>

        {/* Summary and Actions */}
        <div className="border-t bg-gray-50 rounded-b-md">
          {totalAllocation > 100 && (
            <div className="px-6 pt-4 text-sm font-medium text-red-600">
              Total stock allocation cannot exceed 100%.
            </div>
          )}

          <div className="grid grid-cols-3 gap-4 px-4 py-2 border-b">
            <Card className="bg-white">
              <CardHeader className="px-4 py-2">
                <CardTitle className="text-sm text-gray-600">
                  Total Stocks
                </CardTitle>
                <div className="text-lg font-semibold">
                  {adviceEntries?.length}
                </div>
              </CardHeader>
            </Card>

            <Card className="bg-white">
              <CardHeader className="px-4 py-2">
                <CardTitle className="text-sm text-gray-600">
                  Total Allocation
                </CardTitle>
                <div className="text-lg font-semibold">
                  {parseFloat(calculateTotalAllocation()).toFixed(2)}%
                </div>
              </CardHeader>
            </Card>

            <Card className="bg-white">
              <CardHeader className="px-4 py-2">
                <CardTitle className="text-sm text-gray-600">
                  Total Investment
                </CardTitle>
                <div className="text-lg font-semibold">
                  ₹
                  {minimumPortfolioAmount?.min_investment_amount?.toFixed(2) ||
                    "0.00"}
                </div>
              </CardHeader>
            </Card>
          </div>

          <div className="px-4 py-2">
            <div className="text-sm text-gray-900 text-left font-normal font-poppins sm:mb-[2px]">
              Research Report (optional)
            </div>

            <input
              type="text"
              value={researchReport}
              onChange={(e) => setResearchReport(e.target.value)}
              className={`${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
              placeholder="Enter the link to your research point here"
            />
          </div>

          <div className="flex justify-end gap-3 p-4">
            <Button
              variant="outline"
              onClick={closeRebalanceModal}
              className="min-w-[100px]"
            >
              Cancel
            </Button>

            {saveButton ? (
              <Button
                onClick={handleUpdateStrategy}
                disabled={isLoading}
                className="py-3 min-w-[180px] bg-blue-900 hover:bg-blue-800 text-white disabled:bg-gray-400 rounded-md"
              >
                {isLoading ? (
                  <div className="w-full flex flex-row items-center justify-center">
                    <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
                  </div>
                ) : (
                  "Push Rebalance"
                )}
              </Button>
            ) : (
              <Button
                onClick={handleMinimumPortfolioAmount}
                disabled={!isFormValid() || isLoadingSave}
                className="py-3 min-w-[180px] bg-blue-900 hover:bg-blue-800 text-white disabled:bg-gray-400 rounded-md"
              >
                {isLoadingSave ? (
                  <div className="w-full flex flex-row items-center justify-center">
                    <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
                  </div>
                ) : (
                  "Calculate"
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RebalanceModal;
