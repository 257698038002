import CryptoJS from "crypto-js";
import sign from "jwt-encode";
import { jwtDecode } from "jwt-decode";

const SALT = "alphaquark_static_salt";
const ITERATIONS = 10000;
const KEY_LENGTH = 32;
const TOKEN_EXPIRY_SECONDS = 10;

function generateKeyFromSecret(apiSecret) {
  try {
    const keyBuffer = CryptoJS.PBKDF2(apiSecret, SALT, {
      keySize: KEY_LENGTH / 4,
      iterations: ITERATIONS,
      hasher: CryptoJS.algo.SHA256,
    });

    return keyBuffer.toString(CryptoJS.enc.Hex);
  } catch (error) {
    console.error("Key generation failed:", error);
    throw error;
  }
}

function generateToken(apiKey, apiSecret) {
  try {
    const key = CryptoJS.enc.Hex.parse(generateKeyFromSecret(apiSecret));
    const iv = CryptoJS.lib.WordArray.random(16);

    const encrypted = CryptoJS.AES.encrypt(apiKey, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const combined = CryptoJS.lib.WordArray.create()
      .concat(iv)
      .concat(encrypted.ciphertext);

    return combined.toString(CryptoJS.enc.Base64);
  } catch (error) {
    console.error("Encryption failed:", error);
    throw error;
  }
}

function decryptApiKey(encryptedKey, apiSecret) {
  try {
    const key = CryptoJS.enc.Hex.parse(generateKeyFromSecret(apiSecret));
    const combined = CryptoJS.enc.Base64.parse(encryptedKey);

    const iv = CryptoJS.lib.WordArray.create(combined.words.slice(0, 4));
    const ciphertext = CryptoJS.lib.WordArray.create(combined.words.slice(4));

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
}

function nowTimeIST() {
  const now = new Date();
  const istOffset = 5.5 * 60 * 60 * 1000;
  return new Date(now.getTime() + istOffset);
}

function encryptApiKey(apiKey, secretKey) {
  try {
    const payload = {
      apiKey: apiKey,
      exp: Math.floor(
        (nowTimeIST().getTime() + TOKEN_EXPIRY_SECONDS * 1000) / 1000
      ),
      iat: Math.floor(nowTimeIST().getTime() / 1000),
    };

    return sign(payload, secretKey);
  } catch (error) {
    console.error("Token generation failed:", error);
    return null;
  }
}

function verifyToken(token, secretKey) {
  try {
    const decoded = jwtDecode(token);

    const currentTime = Math.floor(nowTimeIST().getTime() / 1000);
    if (decoded.exp && decoded.exp < currentTime) {
      return [
        false,
        {
          error: "Token has expired",
          status: 1,
        },
      ];
    }

    return [true, decoded];
  } catch (error) {
    return [
      false,
      {
        error: "Invalid token",
        status: 1,
      },
    ];
  }
}

export { encryptApiKey, decryptApiKey, generateToken, verifyToken };
