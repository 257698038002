import React from "react";
import DatePicker from "../ui/date-picker";
import Tooltip from "../../components/Tooltip";

const InputField = ({
  label,
  id,
  name,
  type = "text",
  value,
  onChange,
  required = false,
  placeholder,
  disabled = false,
}) => (
  <div className="flex flex-col space-y-1">
    <label htmlFor={id} className="text-sm font-medium text-gray-700">
      {label}
      {required && <span className="ml-1 text-red-500">*</span>}
    </label>
    <input
      id={id}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      // className={`px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
      //   disabled ? "bg-gray-100 cursor-not-allowed" : "bg-white"
      // }`}
      className="text-black px-3 py-2 border border-gray-300 rounded-md shadow-sm"
    />
  </div>
);

const SelectField = ({
  label,
  id,
  name,
  value,
  onChange,
  options,
  required = false,
  placeholder,
}) => (
  <div className="flex flex-col space-y-1">
    <label htmlFor={id} className="text-sm font-medium text-gray-700">
      {label}
      {required && <span className="ml-1 text-red-500">*</span>}
    </label>
    <select
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className="text-black px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
    >
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export const ClientFormFields = ({
  data,
  groupType,
  onInputChange,
  setExpiryDate,
  expiryDate,
  dateOfBirth,
  setDateOfBirth,
  emailEdit = false,
  handlePanChange,
  isPanComplete,
}) => {
  const kycStatusOptions = [
    { value: "Verified", label: "Verified" },
    { value: "Unverified", label: "Unverified" },
  ];

  return (
    <div className="relative grid grid-cols-1 md:grid-cols-2 gap-6">
      <InputField
        label="Email"
        id="clientEmail"
        name="clientEmail"
        type="email"
        value={data.clientEmail}
        onChange={onInputChange}
        required
        placeholder="Enter Email"
        disabled={emailEdit}
        className="text-black"
      />

      <InputField
        label="Name"
        id="clientName"
        name="clientName"
        value={data.clientName}
        onChange={onInputChange}
        required
        placeholder="Enter Name"
        className="text-black"
      />

      <InputField
        label="Country Code"
        id="countryCode"
        name="countryCode"
        type="number"
        value={data.countryCode}
        onChange={onInputChange}
        required
        placeholder="e.g. 91"
        className="text-black"
      />

      <InputField
        label="Phone"
        id="phone"
        name="phone"
        type="tel"
        value={data.phone}
        onChange={onInputChange}
        required
        placeholder="Enter Phone"
        className="text-black"
      />

      {process.env.REACT_APP_TELEGRAM_REQUIRED === "true" ? (
        <Tooltip
          content={
            <ul className="list-disc pl-4">
              <li>
                Open the Telegram app and go to the search icon on the top
                right.
              </li>
              <li>Type "@userinfobot" in the search bar.</li>
              <li>
                Click or tap on the "@userinfobot" profile that appears in the
                search results.
              </li>
              <li>
                In the chat with @userinfobot, select "Start" at the bottom of
                the chat.
              </li>
              <li>The bot will then display your User ID.</li>
            </ul>
          }
        >
          <InputField
            label="Telegram ID"
            id="telegram"
            name="telegram"
            value={data.telegram}
            onChange={onInputChange}
            placeholder="Enter Telegram ID"
            className="text-black"
          />
        </Tooltip>
      ) : null}
      <div className="flex flex-col space-y-1">
        <label className="text-sm font-medium text-gray-700">
          Date of Birth
        </label>
        <DatePicker
          date={dateOfBirth}
          setDate={setDateOfBirth}
          placeholder="Choose Date of Birth"
          className="w-full text-black"
        />
      </div>

      <InputField
        label="Location"
        id="location"
        name="location"
        value={data.location}
        onChange={onInputChange}
        placeholder="Enter Location"
        className="text-black"
      />

      <Tooltip content="PAN Card number is compulsory as per SEBI Regulations. It is mandatory for us to upload it on SEBI Portal.">
        <InputField
          label="PAN/KYC"
          id="pan"
          name="pan"
          value={data.pan}
          onChange={handlePanChange}
          placeholder="Enter PAN/KYC Number"
          className="text-black"
        />
      </Tooltip>
      {groupType === "model portfolio" ? (
        <InputField
          label="Invest Amount"
          id="investAmount"
          name="investAmount"
          type="number"
          required
          value={data.investAmount}
          onChange={onInputChange}
          placeholder="Enter Invest Amount"
          className="text-black"
        />
      ) : null}
      {groupType === "model portfolio" ? (
        <InputField
          label="Invoice"
          id="invoice"
          name="invoice"
          value={data.invoice}
          onChange={onInputChange}
          placeholder="Enter Inovice Data"
          className="text-black"
        />
      ) : null}

      <SelectField
        label="KYC Validation Status"
        id="kycStatus"
        name="kycStatus"
        value={data.kycStatus}
        onChange={onInputChange}
        options={kycStatusOptions}
        placeholder="Select KYC status"
        className="text-black"
      />

      <div className="flex flex-col space-y-1">
        <label className="text-sm font-medium text-gray-700">
          Plan Expiry Date
          <span className="ml-1 text-red-500">*</span>
        </label>
        <DatePicker
          date={expiryDate}
          setDate={setExpiryDate}
          placeholder="Choose Plan Expiry Date"
          className="w-full text-black"
          expirySet={true}
        />
      </div>
    </div>
  );
};

export default ClientFormFields;
