import React, { useState } from "react";
import { Link } from "react-scroll";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import Logo from "./assests/Logo.jpg";
import { auth } from "../firebase";

const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
const Navbar = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClick = () => {
    navigate("/user");
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 shadow bg-[#0F0F0F]">
      <div className="container px-4 sm:px-6 py-4 mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <a href="/">
              <img
                className="w-auto h-6 sm:h-8 rounded-md"
                src={Logo}
                alt="Logo"
                width={32}
                height={32}
              />
            </a>
            <a
              href="/"
              className="font-montserrat text-white text-sm sm:text-base lg:text-xl ml-2"
            >
              {whiteLabelText}
            </a>
          </div>

          <div className="hidden lg:flex lg:items-center">
            <div className="flex items-center">
              {/* <Link
                to="modelPortfolio"
                smooth={true}
                duration={500}
                className="font-Poppins text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1 mx-2"
              >
                Model Portfolios
              </Link> */}
              <Link
                to="subscriptionSection"
                smooth={true}
                duration={800}
                className="font-Poppins text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1 mx-2"
              >
                Plans
              </Link>
              <Link
                to="aboutSection"
                smooth={true}
                duration={1000}
                className="font-Poppins text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1 mx-2"
              >
                About Advisor
              </Link>
              <Link
                to="faqSection"
                smooth={true}
                duration={1000}
                className="font-Poppins text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1 mx-2"
              >
                FAQ
              </Link>
            </div>
            <div className="ml-4">
              <button
                className="px-4 py-2 text-sm font-poppins rounded-md font-medium tracking-wide text-black capitalize transform bg-white transition-all duration-200 ease-in-out hover:bg-gray-200"
                onClick={handleClick}
              >
                {userEmail ? "Go to Dashboard" : "Login/Register"}
              </button>
            </div>
          </div>

          <div className="lg:hidden">
            <button
              type="button"
              onClick={toggleMenu}
              className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              aria-label="toggle menu"
            >
              {!isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="lg:hidden mt-4">
            <div className="flex flex-col space-y-2">
              <Link
                to="modelPortfolio"
                smooth={true}
                duration={500}
                className="font-Poppins text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1"
                onClick={toggleMenu}
              >
                Model Portfolios
              </Link>
              <Link
                to="subscriptionSection"
                smooth={true}
                duration={800}
                className="font-Poppins text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1"
                onClick={toggleMenu}
              >
                Plans
              </Link>
              <Link
                to="aboutSection"
                smooth={true}
                duration={1000}
                className="font-Poppins text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1"
                onClick={toggleMenu}
              >
                About Advisor
              </Link>
              <Link
                to="faqSection"
                smooth={true}
                duration={1000}
                className="font-Poppins text-white cursor-pointer transition-colors hover:text-gray-400 duration-300 rounded-md px-2 py-1"
                onClick={toggleMenu}
              >
                FAQ
              </Link>
              <button
                className="px-4 py-2 text-sm font-poppins rounded-md font-medium tracking-wide text-black capitalize transform bg-white transition-all duration-200 ease-in-out hover:bg-gray-200"
                onClick={() => {
                  handleClick()
                  toggleMenu()
                }}
              >
                {userEmail ? "Go to Dashboard" : "Login/Register"}
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
