import { CircleMinusIcon } from "lucide-react";
import React from "react";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full py-2 px-2 bg-white text-gray-900 placeholder:text-gray-400 placeholder:font-normal   font-medium rounded-md mt-1 ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9]",

  selectDiv:
    "flex flex-row justify-between items-center mx-2 px-2 py-2 rounded-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-black transition ease-in duration-200 cursor-pointer",
  inputStartDiv: " relative w-full",
};
const StockRebalanceCard = ({
  handleRemoveAdviceEntry,
  handleInputChange,
  handleSymbolSelect,
  handleAdviceChange,
  handleIncrement,
  handleDecrement,
  entry,
  index,
  minimumPortfolioAmount,
}) => {
  return (
    <div className="w-full grid grid-cols-8 lg:grid-cols-10 min-h-[60px] border-[1px] border-[#000000]/15 rounded-md lg:px-2 py-2">
      <div className="flex items-center justify-center  col-span-1">
        <CircleMinusIcon
          className="w-4 h-4 lg:w-6 lg:h-6  text-rose-600 cursor-pointer"
          onClick={() => handleRemoveAdviceEntry(index)}
        />
      </div>
      <div className="w-full relative flex items-center bg-gray-100 rounded-md text-[12px] lg:text-[15px] text-[#000000] font-poppins font-medium col-span-3 lg:col-span-5 px-4 lg:mr-4 ">
        <input
          type="text"
          placeholder="Symbol"
          value={entry.inputValue}
          onChange={(e) => handleInputChange(index, e.target.value)}
          autoComplete="off"
          className="bg-transparent"
        />
        {entry?.symbols?.length > 0 && (
          <div className="w-full absolute left-0 right-0 top-10 mt-1 bg-white flex flex-col rounded-lg pt-2 pb-2 z-10 box-border border-2 border-solid border-[ rgba(185, 192, 187, 0.7)] max-h-48 overflow-y-auto">
            {entry?.symbols?.map((ele, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    handleSymbolSelect(index, ele.symbol, ele.segment);
                  }}
                  className={`pl-3 medium ${style.selectDiv}`}
                >
                  <span> {ele.symbol}</span>
                  <span> {ele.segment}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="flex items-center justify-center bg-gray-100 rounded-md text-[15px] text-[#000000] font-poppins font-medium col-span-2 lg:col-span-2 mx-2 ">
        <div className="flex  flex-row items-center justify-between  text-[12px] text-[#000000]/80 font-poppins font-semibold px-1 lg:px-2">
          <button
            className=" text-[12px] lg:text-[16px]  cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
            onClick={() => handleDecrement(index)}
          >
            -
          </button>

          <input
            type="number"
            className="flex flex-1 items-center justify-center bg-transparent w-full  lg:w-[45px]  h-[20px] font-poppins text-[12px] lg:text-[15px] mx-1 text-center rounded"
            value={entry?.value > 0 ? entry?.value : 0}
            onChange={(e) =>
              handleAdviceChange(index, "value", parseInt(e.target.value))
            }
            placeholder="Value"
          />
          <button
            className=" text-[12px] lg:text-[16px]  cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
            onClick={() => handleIncrement(index)}
          >
            +
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center bg-gray-100 rounded-md text-[12px] lg:text-[15px] text-[#000000] font-poppins font-medium col-span-2 lg:col-span-2 mx-2 ">
        ₹
        {entry?.symbol &&
        minimumPortfolioAmount?.min_investment_value[entry.symbol]
          ? parseFloat(
              minimumPortfolioAmount?.min_investment_value[entry.symbol]
            ).toFixed(2)
          : 0}
      </div>
    </div>
  );
};

export default StockRebalanceCard;
