import React from "react";
import Subscription from "./subscription";
import DashboardLayout from "../RootSection/DashboardLayout";

const SubscriptionPage = () => {
  return (
    <DashboardLayout>
      <Subscription />
    </DashboardLayout>
  );
};

export default SubscriptionPage;
