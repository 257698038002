import React from "react";

import DashboardLayout from "../RootSection/DashboardLayout";
import HomePage from "./Home";
const LivePortfolioSection = () => {
  return (
    <DashboardLayout>
      <HomePage />
    </DashboardLayout>
  );
};

export default LivePortfolioSection;
