import React from "react";
import { XIcon, AlertTriangleIcon } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200",
  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  inputStartDiv: "relative w-full  ",
};

const ResearchReportModal = ({
  researchReport,
  setResearchReport,
  setResearchReportModal,
  researchLoading,
  updateResearchReportLink,
}) => {
  return (
    <AnimatePresence>
      <div className="fixed inset-0 flex items-center justify-center bg-black/75 p-4 z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.2 }}
          className="relative w-full max-w-md"
        >
          <div className="relative bg-white rounded-xl shadow-xl overflow-hidden">
            {/* Close Button */}
            <button
              onClick={() => setResearchReportModal(false)}
              className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
              aria-label="Close modal"
            >
              <XIcon className="w-5 h-5 text-gray-500" />
            </button>

            {/* Modal Content */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 text-center mb-2">
                Update Research Report Link
              </h3>

              <div className={`my-3 w-full ${style.inputStartDiv}`}>
                <input
                  type="text"
                  placeholder="Research Report Link"
                  value={researchReport}
                  className={`w-full ${style.inputBox}`}
                  onChange={(e) => setResearchReport(e.target.value)}
                />
              </div>

              <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 mt-3">
                <button
                  onClick={() => setResearchReportModal(false)}
                  className="flex-1 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={updateResearchReportLink}
                  disabled={!researchReport}
                  className={`flex-1 px-4 py-2 text-sm font-medium text-white rounded-lg transition ${
                    !researchReport
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-gradient-to-r from-blue-600 to-blue-700 hover:to-blue-800"
                  }`}
                >
                  {researchLoading ? <LoadingSpinner /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default ResearchReportModal;
