import React, { useEffect, useCallback } from "react";
import { Loader2 } from "lucide-react";

// Add these imports to your LandingPage.js file:
// import DigioModal from './DigioModal';

const DigioModal = ({ authenticationUrl, onClose, onSuccess, onError }) => {
  const handleDigioMessage = useCallback(
    (event) => {
      // Verify message origin for security
      if (event.origin !== "https://ext.digio.in") return;

      try {
        const { status, id } = event.data;

        switch (status) {
          case "success":
            onSuccess?.(id);
            break;
          case "error":
            onError?.(event.data);
            break;
          case "closed":
            onClose?.();
            break;
          default:
            console.log("Unhandled Digio event:", event.data);
        }
      } catch (err) {
        console.error("Error processing Digio message:", err);
        onError?.(err);
      }
    },
    [onSuccess, onError, onClose]
  );

  useEffect(() => {
    // Add event listener for postMessage from Digio iframe
    window.addEventListener("message", handleDigioMessage);

    return () => {
      window.removeEventListener("message", handleDigioMessage);
    };
  }, [handleDigioMessage]);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="relative w-full h-5/6 max-w-6xl bg-white rounded-lg overflow-hidden">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 z-10"
          aria-label="Close"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {authenticationUrl ? (
          <iframe
            src={authenticationUrl}
            className="w-full h-full border-0"
            title="Digio Authentication"
            allow="camera"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DigioModal;
