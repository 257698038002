import React from "react";
import {
  XIcon,
  CheckIcon,
  PauseIcon,
  BanIcon,
  CandlestickChartIcon,
} from "lucide-react";
import FormatDateTime from "../../utils/formatDateTime";
import { motion, AnimatePresence } from "framer-motion";

const PlaceOrders = ({ executedTrades }) => {
  const sortedTrades = [...executedTrades].sort((a, b) => {
    const dateA = new Date(a.exitDate || a.purchaseDate);
    const dateB = new Date(b.exitDate || b.purchaseDate);

    // Check if either date is invalid
    if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
    if (isNaN(dateA.getTime())) return 1;
    if (isNaN(dateB.getTime())) return -1;

    return dateB - dateA; // Sort in descending order (latest date first)
  });

  const getStatusIcon = (status) => {
    const statusLower = status?.toLowerCase();
    if (
      statusLower === "complete" ||
      statusLower === "executed" ||
      statusLower === "placed"
    ) {
      return <CheckIcon className="w-4 h-4 text-emerald-600" />;
    }
    if (
      statusLower === "open" ||
      statusLower === "requested" ||
      statusLower === "ordered"
    ) {
      return <PauseIcon className="w-4 h-4 text-amber-600" />;
    }
    if (statusLower === "cancelled") {
      return <BanIcon className="w-4 h-4 text-gray-600" />;
    }
    if (statusLower === "rejected") {
      return <XIcon className="w-4 h-4 text-red-600" />;
    }
    return null;
  };

  const getStatusStyles = (status) => {
    const statusLower = status?.toLowerCase();
    if (
      statusLower === "complete" ||
      statusLower === "executed" ||
      statusLower === "placed"
    ) {
      return "bg-emerald-50 text-emerald-700 border-emerald-200";
    }
    if (
      statusLower === "open" ||
      statusLower === "requested" ||
      statusLower === "ordered"
    ) {
      return "bg-amber-50 text-amber-700 border-amber-200";
    }
    if (statusLower === "cancelled") {
      return "bg-gray-50 text-gray-700 border-gray-200";
    }
    if (statusLower === "rejected") {
      return "bg-red-50 text-red-700 border-red-200";
    }
    return "bg-gray-50 text-gray-700 border-gray-200";
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {sortedTrades.length !== 0 ? (
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="relative w-full h-[calc(100vh-110px)] lg:h-[calc(100vh-100px)] overflow-auto custom-scroll"
          >
            <table className="w-full">
              <thead className="bg-white text-sm text-left sticky top-0 z-0 shadow-sm">
                <tr>
                  <th className="whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Time of Purchase
                  </th>
                  <th className="whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Stock Symbol
                  </th>
                  <th className="whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                    Qty
                  </th>
                  <th className="whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                    Price
                  </th>
                  <th className="whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                    Type
                  </th>
                  <th className="whitespace-nowrap px-6 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {sortedTrades.map((trade, i) => (
                  <tr key={i} className="hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                      {trade?.Type === "BUY"
                        ? FormatDateTime(trade?.purchaseDate)
                        : trade?.exitDate
                        ? FormatDateTime(trade?.exitDate)
                        : FormatDateTime(trade?.purchaseDate)}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                      {trade?.Symbol}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 text-right">
                      {trade?.tradedQty === 0 ? "-" : trade?.tradedQty}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-900 text-right">
                      {trade?.Type === "SELL"
                        ? trade?.exitPrice === undefined ||
                          trade?.exitPrice === null ||
                          trade?.exitPrice === 0
                          ? "-"
                          : trade?.exitPrice
                        : trade?.Type === "BUY"
                        ? trade?.tradedPrice === undefined ||
                          trade?.tradedPrice === null ||
                          trade?.tradedPrice === 0
                          ? "-"
                          : trade?.tradedPrice
                        : "-"}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex justify-center">
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-[13px] font-medium capitalize ${
                            trade?.Type?.toLowerCase() === "buy"
                              ? "bg-emerald-50 text-emerald-700"
                              : "bg-red-50 text-red-700"
                          }`}
                        >
                          {trade?.Type?.toLowerCase()}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex justify-center">
                        <div
                          className={`inline-flex items-center px-3 py-1 rounded-lg text-[13px] font-medium border ${getStatusStyles(
                            trade?.trade_place_status
                          )}`}
                        >
                          {getStatusIcon(trade?.trade_place_status)}
                          <span className="ml-1.5 capitalize">
                            {trade?.trade_place_status?.toLowerCase()}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </motion.div>
        </AnimatePresence>
      ) : (
        <div className="h-[calc(100vh-110px)] lg:h-[calc(100vh-100px)] flex flex-col items-center justify-center bg-white">
          <div className="inline-flex items-center px-4 py-2 rounded-lg border bg-gray-50 text-gray-700 border-gray-200 mb-4">
            <CandlestickChartIcon className="w-5 h-5 mr-2" />
            <span className="text-sm font-medium">No Orders</span>
          </div>
          <p className="text-gray-500 text-center max-w-md text-sm">
            Orders that are placed will appear here
          </p>
        </div>
      )}
    </div>
  );
};

export default PlaceOrders;
