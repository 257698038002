import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { XIcon, AlertCircle } from "lucide-react";
import toast from "react-hot-toast";
import axios from "axios";
import moment from "moment";
import server from "../../utils/serverConfig";
import { debounce } from "lodash";
import LoadingSpinner from "../../components/LoadingSpinner";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import EmojiPicker from "emoji-picker-react";
import CommentModal from "./CommentModal";
import { PreviousEquityAdvices } from "./previousAdvices/previousEquityAdvices";
import SendAdviceTable from "./SendAdvices/SendAdviceTable";
import DerivativeSendAdviceTable from "./SendAdvices/DerivativeSendAdviceTable";
import BasketDerivativeAdviceTable from "./SendAdvices/BasketDerivativeSendAdviceTable";
import QuantityUpdate from "./SendAdvices/QuantityUpdate";
import PreviousBasketAdvices from "./previousAdvices/previousBasketAdvices";
import { PreviousDerivativeAdvices } from "./previousAdvices/previousDerivativeAdvices";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const ACCEPTABLE_DATE_FORMATS = [
  "D MMM YYYY, HH:mm:ss",
  "YYYY-MM-DDTHH:mm:ss.SSSZ",
];
const showDerivativeTabForAdviosr = process.env.REACT_APP_DERIVATIVE_STATUS;

const SendAdviceModel = ({
  fileName,
  closeSendAdviceModal,
  data,
  adminEmail,
  advisorName,
  setSendAdviceModal,
  setSelectedRows,
  adminRationale,
  advisorSpecifier,
}) => {
  const showQuantityColumn = advisorSpecifier === "RIA";
  const [filteredClients, setFilteredClients] = useState([]);
  const [showQuantityPopUp, setShowQuantityPopUp] = useState(false);
  const [showPreviousEquityAdvicesPopup, setPreviousEquityAdvicesPopup] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("equity");

  const [activeDerivativesTab, setActiveDerivativesTab] = useState("entry");
  const [selectedPreviousAdvices, setSelectedPreviousAdvices] = useState([]);

  const [adviceEntries, setAdviceEntries] = useState([
    {
      id: Date.now(),
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: advisorSpecifier === "RIA" ? 1 : "",
      segment: "EQUITY",
      price: 0,
      stopLoss: 0,
      profitTarget: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      comments: "",
      comment2: "",

      advisorType: advisorSpecifier,
      price_when_send_advice: 0,
      rationale: adminRationale
        ? adminRationale
        : "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries.",
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddAdviceEntry = () => {
    const newEntry = {
      id: Date.now(),
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: advisorSpecifier === "RIA" ? 1 : "",
      segment: "EQUITY",
      price: 0,
      stopLoss: 0,
      profitTarget: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      comments: "",
      comment2: "",

      advisorType: advisorSpecifier,
      price_when_send_advice: 0,
      rationale:
        adminRationale ||
        "This recommendation is based on comprehensive analysis...",
    };

    setAdviceEntries([...adviceEntries, newEntry]);
  };

  const handlePreviousAdviceSelection = (selectedAdvices) => {
    setSelectedPreviousAdvices(selectedAdvices);
    setPreviousEquityAdvicesPopup(false);

    // Map selected previous advices to new entries
    const newEntries = selectedAdvices.map((advice) => ({
      id: Date.now() + Math.random(),
      symbol: advice.symbol,
      exchange: advice.exchange,
      orderType: advice.orderType || "MARKET",
      productType: advice.productType || "DELIVERY",
      quantity: advisorSpecifier === "RIA" ? 1 : "",
      segment: advice.segment || "EQUITY",
      price: advice.price || 0,
      type: advice.type,
      adviceLower: advice.adviceLower || 0,
      adviceHigher: advice.adviceHigher || 0,
      inputValue: advice.symbol,
      symbols: [],
      comments: advice.comments || "",
      comment2: advice.comment2 || "",
      advisorType: advisorSpecifier,
      price_when_send_advice: advice.price_when_send_advice || 0,
      rationale:
        advice.rationale ||
        adminRationale ||
        "This recommendation is based on a comprehensive analysis...",
    }));

    setAdviceEntries((prevEntries) => {
      if (prevEntries.length === 1 && !prevEntries[0].symbol) {
        return newEntries;
      }
      return [...prevEntries, ...newEntries];
    });
  };

  const handleRemoveAdviceEntry = (index) => {
    const updatedEntries = adviceEntries.filter((_, i) => i !== index);
    setAdviceEntries(updatedEntries);
    // Also remove from selectedSymbols
    setSelectedSymbols((prev) => prev.filter((item) => item.index !== index));
  };

  const numericFields = ["price", "anotherNumericField"]; // Add all numeric field names here

  const handleAdviceChange = (index, field, value) => {
    let formattedValue = value;
    // Format value if the field is numeric
    if (numericFields?.includes(field)) {
      // Allow only valid numbers with up to two decimal places
      if (value) {
        const [integerPart, decimalPart] = value.split(".");
        if (decimalPart && decimalPart.length > 2) {
          formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
        } else {
          formattedValue = value;
        }
      }
    }

    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index ? { ...entry, [field]: formattedValue } : entry
    );
    setAdviceEntries(updatedEntries);
  };

  // Derivative add entry
  const [adviceDerivativesEntries, setAdviceDerivativesEntries] = useState([
    {
      id: Date.now(),
      symbol: "",
      foType: "OPTIONS",
      expiry: "",
      strike: "",
      optionType: "",
      lots: "",
      order: "MARKET",
      price: 0,
        // ADDED: stopLoss and profitTarget fields 
        stopLoss: 0,
        profitTarget: 0,
      rationale: "",
      comments: "",
      extendedComment: "",
      strikes: [],
      optionTypes: [],
      symbols: [],
    },
  ]);

  const [adviceBasketEntries, setAdviceBasketEntries] = useState([
    {
      id: Date.now(),
      symbol: "",
      foType: "OPTIONS",
      expiry: "",
      strike: "",
      OrderType: "",
      lots: "",
      order: "MARKET",
      price: 0,
       // ADDED: stopLoss and profitTarget fields for basket
      stopLoss: 0,
      profitTarget: 0,
      commonBasketName: "",
      commonRationale: "",
      commonComment: "",
      commonExtendedComment: "",
      symbols: [],
      strikes: [],
      optionTypes: [],
      priority: 0,
    },
  ]);

  // handle adding a new derivatives entry
  const handleAddDerivativesEntry = () => {
    const newEntry = {
      id: Date.now(),
      symbol: "",
      foType: "OPTIONS",
      expiry: "",
      strike: "",
      optionType: "",
      orderType: "",
      productType: "",
      lots: " ",
      order: "MARKET",
      price: 0,
      // ADDED: stopLoss and profitTarget fields for new derivatives entry
      stopLoss: 0,
      profitTarget: 0,

      basketName: "",
      rationale: "",
      comments: "",
      comments2: "",
    };
    setAdviceDerivativesEntries([...adviceDerivativesEntries, newEntry]);
  };

  //  handle changes in derivatives entries
  const handleDerivativeChange = (index, field, value) => {
    setAdviceDerivativesEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, [field]: value } : entry
      )
    );

    // If the field is 'foType', reset the optionType and strike
    if (field === "foType") {
      setAdviceDerivativesEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index
            ? { ...entry, optionType: "", strike: "", lots: "" }
            : entry
        )
      );
    }

    // If changing lotMultiplier, update the total quantity
    if (field === "lotMultiplier") {
      setAdviceDerivativesEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, lotMultiplier: value } : entry
        )
      );
    }
  };

  const isValid = adviceEntries.every((entry) => {
    const isPriceRequired =
      entry.orderType === "LIMIT" || entry.orderType === "STOP";
    return (
      entry.symbol &&
      entry.exchange &&
      entry.orderType &&
      entry.productType &&
      entry.segment &&
      entry.type &&
      entry.rationale && // Add rationale validation
      (!isPriceRequired || entry.price)
    );
  });

  // At the top of your component, keep track of selected symbols
  const [selectedSymbols, setSelectedSymbols] = useState([]);

  // Use the websocket hook with selectedSymbols instead of adviceEntries
  const { getLTPForSymbol } = useWebSocketCurrentPrice(selectedSymbols);

  const handleSymbolSelect = (index, symbol, exchange) => {
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? {
            ...entry,
            symbol: symbol,
            symbols: [],
            inputValue: symbol,
            exchange: exchange,
          }
        : entry
    );
    setAdviceEntries(updatedEntries);
    setIsOpen(false);
    setSelectedSymbols((prev) => {
      // Remove any existing entry for this index
      const filtered = prev.filter((item) => item.index !== index);
      // Add the new symbol
      return [
        ...filtered,
        {
          index,
          symbol,
          exchange,
        },
      ];
    });
  };

  const fetchSymbols = async (index, inputValue) => {
    const data = JSON.stringify({
      symbol: inputValue,
      indices: false,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${server.ccxtServer.baseUrl}angelone/equity/symbol-search`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, symbols: response.data.match } : entry
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetchSymbols = useCallback(
    debounce((index, value) => {
      fetchSymbols(index, value);
    }, 300),
    []
  );

  const handleInputChange = (index, value) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, inputValue: value, symbol: value } : entry
      )
    );

    if (value.length >= 2) {
      debouncedFetchSymbols(index, value);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleRationaleChange = (index, rationaleMsg) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, rationale: rationaleMsg } : entry
      )
    );
  };

  const handleCommentsChange = (index, commentsMsg) => {
    if (commentsMsg.length <= 256) {
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, comments: commentsMsg } : entry
        )
      );
    } else {
      // Optionally, you could handle this case (e.g., showing a message or preventing further input)

      toast.error("Comment exceeds the maximum length of 256 characters", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleComments2Change = (index, commentsMsg) => {
    if (commentsMsg.length <= 256) {
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, comment2: commentsMsg } : entry
        )
      );
    } else {
      toast.error(
        "Extended comment exceeds the maximum length of 256 characters",
        {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
  };

  const todaydate = new Date();

  const isSubscriptionValid = (subscriptions, groupName) => {
    if (!subscriptions || subscriptions.length === 0) return false;

    // Filter subscriptions matching the given group name
    const matchingPlanSubs = subscriptions.filter(
      (sub) => sub?.plan === groupName
    );
    if (matchingPlanSubs.length === 0) return false;

    // Filter out deleted subscriptions
    const activeSubscriptions = matchingPlanSubs.filter(
      (sub) => sub?.status !== "deleted"
    );
    if (activeSubscriptions.length === 0) return false;

    // Validate expiry dates
    const validSubscriptions = activeSubscriptions.filter((sub) =>
      sub.expiry
        ? moment(sub.expiry, ACCEPTABLE_DATE_FORMATS, true).isValid()
        : false
    );
    if (validSubscriptions.length === 0) return false;

    // Get the latest subscription
    const latestSub = validSubscriptions.sort(
      (a, b) =>
        moment(b.expiry, ACCEPTABLE_DATE_FORMATS) -
        moment(a.expiry, ACCEPTABLE_DATE_FORMATS)
    )[0];

    // Ensure the latest subscription is still active
    const expiryDate = moment(latestSub?.expiry, ACCEPTABLE_DATE_FORMATS);
    const today = moment();

    return expiryDate.isAfter(today);
  };

  useEffect(() => {
    const validClients = data.filter((client) =>
      isSubscriptionValid(client.subscriptions, fileName)
    );
    setFilteredClients(validClients);
  }, []);

  const updateStopLossandProfitTarget = (payload) => {
    axios
      .post(`${server.ccxtServer.baseUrl}misc/insert-price-alert`, payload, {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      })
      .then((response) => {
        // console.log("Stop Loss & Profit Target updated successfully", response);
      })
      .catch((error) => {
        // console.error("Error updating Stop Loss & Profit Target", error);
      });
  };
  const handleSendAdvice = () => {
    setLoading(true);
    const uniqueSymbols = [
      ...new Set(adviceEntries.map((entry) => entry.symbol)),
    ];

    uniqueSymbols.forEach((symbol) => {
      const matchingEntries = adviceEntries.filter(
        (entry) => entry.symbol === symbol
      );

      matchingEntries.forEach((entry) => {
        let stopLoss = entry.stopLoss > 0 ? entry.stopLoss : 0;
        let profitTarget = entry.profitTarget > 0 ? entry.profitTarget : 0;

        if (stopLoss > 0 && profitTarget > 0) {
          updateStopLossandProfitTarget({
            advisor: advisorName,
            alertName: entry.symbol.replace("-EQ", "") + "_OCO_Alert",
            stock: entry.symbol,
            exchange: entry.exchange,
            type: "oco",
            price1Level: profitTarget,
            price1Movement:
              entry.type === "BUY" ? "ltp_crossing_up" : "ltp_crossing_down",
            price2Level: stopLoss,
            price2Movement:
              entry.type === "BUY" ? "ltp_crossing_down" : "ltp_crossing_up",
            notifyEmail: "yes",
            notifyWhatsapp: "yes",
            validity: entry.productType === "INTRADAY" ? "eod" : "gtt",
          });
        } else if (stopLoss > 0) {
          updateStopLossandProfitTarget({
            advisor: advisorName,
            alertName: entry.symbol.replace("-EQ", "") + "_OCO_ALERT",
            stock: entry.symbol,
            exchange: entry.exchange,
            type: "oco",
            price1Level:
              entry.type === "BUY"
                ? getLTPForSymbol(entry?.symbol) * 100
                : "0.1",
            price1Movement:
              entry.type === "BUY" ? "ltp_crossing_up" : "ltp_crossing_down",
            price2Level: stopLoss,
            price2Movement:
              entry.type === "BUY" ? "ltp_crossing_down" : "ltp_crossing_up",
            notifyEmail: "yes",
            notifyWhatsapp: "yes",
            validity: entry.productType === "INTRADAY" ? "eod" : "gtt",
          });
        } else if (profitTarget > 0) {
          updateStopLossandProfitTarget({
            advisor: advisorName,
            alertName: entry.symbol.replace("-EQ", "") + "_OCO_ALERT",
            stock: entry.symbol,
            exchange: entry.exchange,
            type: "oco",
            price1Level: profitTarget,
            price1Movement:
              entry.type === "BUY" ? "ltp_crossing_up" : "ltp_crossing_down",
            price2Level:
              entry.type === "BUY"
                ? "0.1"
                : getLTPForSymbol(entry?.symbol) * 100,
            price2Movement:
              entry.type === "BUY" ? "ltp_crossing_down" : "ltp_crossing_up",
            notifyEmail: "yes",
            notifyWhatsapp: "yes",
            validity: entry.productType === "INTRADAY" ? "eod" : "gtt",
          });
        }
      });
    });

    const adviceData = filteredClients.flatMap((user) =>
      adviceEntries.map((entry) => {
        // Format country code - ensure it starts with '+'
        let formattedCountryCode = String(user?.country_code || "+91").trim();
        formattedCountryCode =
          formattedCountryCode.charAt(0) === "+"
            ? formattedCountryCode
            : "+" + formattedCountryCode;

        const userQuantity =
          entry.quantities?.[user.email] || entry.quantity || 1;

        return {
          email: user.email,
          userName: user?.clientName,
          phoneNumber: user?.phone,
          country_code: formattedCountryCode, // Use formatted country code
          telegramId: user?.telegram,
          trade_given_by: adminEmail,
          trade_given_by_fileName: fileName,
          advisor_name: advisorName,
          Symbol: entry.symbol,
          Exchange: entry.exchange,
          Type: entry.type,
          OrderType: entry.orderType,
          ProductType: entry.productType,
          Segment: entry.segment,
          Price: entry.orderType === "MARKET" ? 0 : entry.price,
          stopLoss: entry.stopLoss > 0 ? entry.stopLoss : null,
          profitTarget: entry.profitTarget > 0 ? entry.profitTarget : null,
          date: moment(todaydate).format(),
          Quantity: userQuantity,
          Advised_Range_Lower: entry.adviceLower,
          Advised_Range_Higher: entry.adviceHigher,
          rationale: entry?.rationale,
          comments: entry?.comments,
          comment2: entry?.comment2,
          advisorType: advisorSpecifier,
          price_when_send_advice: getLTPForSymbol(entry?.symbol),
          group: fileName,
        };
      })
    );
    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}comms/send-reco`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        // processClientsAndSendNotifications();
        toast.success("Advice has been sent successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });

        setTimeout(() => {
          setAdviceEntries([
            {
              symbol: "",
              exchange: "NSE",
              orderType: "MARKET",
              productType: "DELIVERY",
              quantity: 1,
              segment: "EQUITY",
              price: 0,
              stopLoss: 0,
              profitTarget: 0,
              type: "",
              adviceLower: 0,
              adviceHigher: 0,
              inputValue: "",
              symbols: [],
            },
          ]);
          setSendAdviceModal(false);
          setSelectedRows([]);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in sending Advice !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const sendNotifications = async (fcmTokens, notificationData) => {
    console.log("Notification Data:", notificationData[0]); // Log notificationData
    for (let token of fcmTokens) {
      try {
        const response = await fetch(
          `${server.server.baseUrl}api/notifications/send`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
            body: JSON.stringify({
              fcmToken: token,
              title: notificationData[0].Symbol, // Customizing the title
              body: "stock Advice", // Customizing the body
              notificationType: notificationData[0].notificationType, // Including notification type
              Symbol: notificationData[0].Symbol, // Including symbol
              Type: notificationData[0].Type, // Including types
              Price: notificationData[0].Price, // Including price
            }),
          }
        );

        const result = await response.json();
        console.log(`Notification sent to ${token}:`, result);
      } catch (error) {
        console.error(`Error sending notification to ${token}:`, error);
      }
    }
  };

  // Main function to process client data and send notifications

  const fetchUserDetailsByEmail = async (email) => {
    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/user/getUser/${email}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
      console.log("response:", response.data);
      return response.data; // Assuming the user data is returned in the response
    } catch (error) {
      console.error(
        `Error fetching user details for ${email}:`,
        error.response.data || error.message
      );
      return null; // Return null or an appropriate value if the user is not found
    }
  };

  const processClientsAndSendNotifications = async (notificationData) => {
    let fcmTokens = [];
    // Iterate through each user in the data array
    for (const user of data) {
      const { email } = user; // Destructure to get the email
      console.log("user:", email);
      if (!email) {
        console.warn("User does not have an email:", user);
        continue; // Skip this user if there is no email
      }

      try {
        const userDetails = await fetchUserDetailsByEmail(email); // Fetch user details by email
        console.log("userDetails:", userDetails);
        if (userDetails && userDetails.User.fcm_token) {
          console.log("FCM token:", userDetails.User.fcm_token);
          fcmTokens.push(userDetails.User.fcm_token); // Collect valid FCM tokens
        } else {
          console.warn(`No valid FCM token found for user: ${email}`);
        }
      } catch (error) {
        console.error(`Error fetching user details for ${email}:, error`);
      }
    }

    // Check if we have any tokens to send notifications
    if (fcmTokens.length > 0) {
      await sendNotifications(fcmTokens, notificationData); // Pass fcmTokens and notificationData to sendNotifications
    } else {
      console.log("No valid FCM tokens found for sending notifications.");
    }
  };
  const formatToTwoDecimals = (value) => {
    // Remove any non-digit and non-decimal point characters
    let formatted = value.replace(/[^\d.]/g, "");

    // Ensure only one decimal point
    const parts = formatted.split(".");
    if (parts.length > 2) {
      formatted = parts[0] + "." + parts.slice(1).join("");
    }

    // Limit to two decimal places
    if (parts.length > 1) {
      formatted = parts[0] + "." + parts[1].slice(0, 2);
    }

    return formatted;
  };

  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const emojiPickerRef = useRef(null);

  const handleEmojiClick = (emojiObject, index, type) => {
    const updatedEntries = [...adviceEntries];
    if (type === "comment") {
      updatedEntries[index].comments += emojiObject.emoji;
    } else if (type === "comment2") {
      updatedEntries[index].comment2 += emojiObject.emoji;
    }
    setAdviceEntries(updatedEntries);
    // Close the emoji picker after selecting an emoji
    setActiveEmojiPicker(null);
  };

  const handleClickOutsideEmoji = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      !event.target.closest("button")
    ) {
      setActiveEmojiPicker(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideEmoji);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideEmoji);
    };
  }, []);

  useEffect(() => {
    const handleDoubleClick = () => {
      setActiveEmojiPicker(null);
    };

    document.addEventListener("dblclick", handleDoubleClick);
    return () => {
      document.removeEventListener("dblclick", handleDoubleClick);
    };
  }, []);

  const [activeCommentModal, setActiveCommentModal] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const fetchDerivativesSymbols = async (index, inputValue, type) => {
    if (inputValue.length < 3) return;

    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/fno/search`,
        {
          symbol: inputValue,
          type: type || "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
      const symbols = response.data.match || [];

      const strikes = [...new Set(symbols.map((sym) => sym.strike))]
        .filter(Boolean)
        .sort((a, b) => a - b);
      const optionTypes = [
        ...new Set(symbols.map((sym) => sym.optionType)),
      ].filter(Boolean);

      setAdviceDerivativesEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index
            ? {
                ...entry,
                symbols: symbols,
                strikes: strikes,
                optionTypes: optionTypes,
              }
            : entry
        )
      );
    } catch (error) {
      console.error("Error fetching derivatives symbols:", error);
    }
  };

  const debouncedFetchDerivativesSymbols = useCallback(
    debounce((index, value, type) => {
      fetchDerivativesSymbols(index, value, type);
    }, 300),
    []
  );

  const handleDerivativesInputChange = (index, value) => {
    setAdviceDerivativesEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index
          ? { ...entry, searchSymbol: value, symbol: value, showDropdown: true }
          : entry
      )
    );

    if (value.length >= 3) {
      const currentType = adviceDerivativesEntries[index].foType;
      debouncedFetchDerivativesSymbols(index, value, currentType);
    }
  };

  const { getLTPForSymbol: getLTPForSymbol2 } =
    useWebSocketCurrentPrice(selectedSymbols);

  const handleDerivativesSymbolSelect = (
    index,
    symbol,
    lotsize,
    strike,
    exchange,
    optionType
  ) => {
    setAdviceDerivativesEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index
          ? {
              ...entry,
              symbol: symbol.symbol,
              searchSymbol: symbol.searchSymbol,
              strike: strike,
              optionType: optionType, // Set the optionType
              lots: symbol.lotsize,
              exchange: exchange,
              strikes: prevEntries[i].strikes || [], // Preserve the strikes array
              symbols: [],
              showDropdown: false,
            }
          : entry
      )
    );
    setSelectedSymbols((prev) => {
      // Remove any existing entry for this index
      const filtered = prev.filter((item) => item.index !== index);

      // Add the new symbol
      return [
        ...filtered,
        {
          index,
          symbol: symbol.symbol,
          exchange: symbol.exchange,
        },
      ];
    });
    fetchDerivativeSymbols(index, symbol.searchSymbol, "OPTIONS");
  };

  const isDerivativesValid = (entries) => {
    if (activeDerivativesTab === "basketEntry") {
      return entries.every(
        (entry) =>
          entry.foType &&
          entry.symbol &&
          entry.orderType &&
          entry.strike &&
          entry.optionType &&
          entry.orderType &&
          entry.lots &&
          entries[0].commonRationale // Check if common rationale is filled
      );
    } else {
      return entries.every((entry) => {
        return (
          entry.foType &&
          entry.symbol &&
          entry.orderType &&
          entry.rationale &&
          (entry.foType === "OPTIONS" ? entry.strike : true) &&
          entry.lots
          // &&
          // entry.price
        );
      });
    }
  };

  const handleSendDerivativesAdvice = () => {
    setLoading(true);

    let adviceData;

    if (activeDerivativesTab === "basketEntry") {
      // Sort basket entries by priority
      const sortedEntries = [...adviceBasketEntries].sort(
        (a, b) => a.priority - b.priority
      );

      adviceData = filteredClients.flatMap((user) =>
        sortedEntries.map((entry) => {
          const calculatedLots = entry.lotMultiplier && entry.lots ? entry.lotMultiplier * entry.lots : entry.lots || 1

          const matchedSymbol = entry.symbols.find(
            (s) =>
              s.searchSymbol === entry.searchSymbol && s.optionType === entry.optionType && s.strike === entry.strike,
          )?.symbol

          return {
            email: user.email,
            userName: user?.clientName,
            phoneNumber: user?.phone,
            country_code:
              String(user?.country_code || "+91")
                .trim()
                .charAt(0) === "+"
                ? String(user?.country_code || "+91").trim()
                : "+" + String(user?.country_code || "+91").trim(),
            telegramId: user?.telegram,
            trade_given_by: adminEmail,
            trade_given_by_fileName: fileName,
            advisor_name: advisorName,
            Symbol: entry.symbols.find(
              (s) =>
                s.searchSymbol === entry.searchSymbol && s.optionType === entry.optionType && s.strike === entry.strike,
            )?.symbol,
            Strike: entry.strike,
            Type: entry.orderType,
            OrderType: entry.order || "MARKET",
            Quantity: entry.lotMultiplier,
            OptionType: entry.optionType,
            // OrderType: entry.order,
            ProductType: entry.productType || "CARRYFORWARD",
            searchSymbol: entry.searchSymbol,
            Segment: entry.foType,
            Exchange: entry.exchange,
            Lots: calculatedLots,
            Price: entry.order === "MARKET" ? 0 : getLTPForSymbol2(entry?.symbol),
            // ADDED: stopLoss and profitTarget fields for basket in payload
            stopLoss: entry.stopLoss > 0 ? entry.stopLoss : null,
            profitTarget: entry.profitTarget > 0 ? entry.profitTarget : null,
            price_when_send_advice: getLTPForSymbol2(matchedSymbol ? matchedSymbol.symbol : entry.symbol),
            date: moment(new Date()).format(),
            rationale: entry.commonRationale,
            comments: entry.commonComment,
            comment2: entry.commonExtendedComment,
            advisorType: advisorSpecifier,
            group: fileName,
            basketName: entry.commonBasketName,
            Basket: true,
            priority: entry.priority,
          }
        }),
      )

      // ADDED: Process stopLoss and profitTarget for basket entries
      sortedEntries.forEach((entry) => {
        const stopLoss = entry.stopLoss > 0 ? entry.stopLoss : 0
        const profitTarget = entry.profitTarget > 0 ? entry.profitTarget : 0

        if (stopLoss > 0 || profitTarget > 0) {
          const matchedSymbol = entry.symbols.find(
            (s) =>
              s.searchSymbol === entry.searchSymbol && s.optionType === entry.optionType && s.strike === entry.strike,
          )

          if (matchedSymbol) {
            updateStopLossandProfitTarget({
              advisor: advisorName,
              alertName: matchedSymbol.symbol.replace("-EQ", "") + "_OCO_Alert",
              stock: matchedSymbol.symbol,
              exchange: entry.exchange,
              type: "oco",
              price1Level:
                profitTarget > 0
                  ? profitTarget
                  : entry.orderType === "BUY"
                    ? getLTPForSymbol2(matchedSymbol.symbol) * 100
                    : "0.1",
              price1Movement: entry.orderType === "BUY" ? "ltp_crossing_up" : "ltp_crossing_down",
              price2Level:
                stopLoss > 0
                  ? stopLoss
                  : entry.orderType === "BUY"
                    ? "0.1"
                    : getLTPForSymbol2(matchedSymbol.symbol) * 100,
              price2Movement: entry.orderType === "BUY" ? "ltp_crossing_down" : "ltp_crossing_up",
              notifyEmail: "yes",
              notifyWhatsapp: "yes",
              validity: entry.productType === "INTRADAY" ? "eod" : "gtt",
            })
          }
        }
      })
    } else {
      // Updated logic for non-basket entries
      adviceData = filteredClients.flatMap((user) =>
        adviceDerivativesEntries.map((entry) => {
          const calculatedLots = entry.lotMultiplier && entry.lots ? entry.lotMultiplier * entry.lots : entry.lots || 1

          const matchedSymbol = entry.symbols.find(
            (s) =>
              s.searchSymbol === entry.searchSymbol && s.optionType === entry.optionType && s.strike === entry.strike,
          )?.symbol

          console.log("matchedSymbol", matchedSymbol)

          return {
            email: user.email,
            userName: user?.clientName,
            phoneNumber: user?.phone,
            country_code:
              String(user?.country_code || "+91")
                .trim()
                .charAt(0) === "+"
                ? String(user?.country_code || "+91").trim()
                : "+" + String(user?.country_code || "+91").trim(),
            telegramId: user?.telegram,
            trade_given_by: adminEmail,
            trade_given_by_fileName: fileName,
            advisor_name: advisorName,

            Symbol: entry.symbols.find(
              (s) =>
                s.searchSymbol === entry.searchSymbol && s.optionType === entry.optionType && s.strike === entry.strike,
            )?.symbol,
            Strike: entry.strike,
            Type: entry.orderType,
            Segment: entry.foType,
            Exchange: entry.exchange,
            OptionType: entry.optionType,
            Quantity: entry.lotMultiplier,
            OrderType: entry.order || "MARKET",
            ProductType: entry.productType || "CARRYFORWARD",
            searchSymbol: entry.searchSymbol,
            Lots: calculatedLots,
            // ADDED: stopLoss and profitTarget fields for derivatives in payload
            stopLoss: entry.stopLoss > 0 ? entry.stopLoss : null,
            profitTarget: entry.profitTarget > 0 ? entry.profitTarget : null,
            price_when_send_advice: getLTPForSymbol2(matchedSymbol ? matchedSymbol.symbol : entry.symbol),

            date: moment(new Date()).format(),
            rationale: entry.rationale,
            comments: entry.comments,
            comment2: entry.extendedComment,
            advisorType: advisorSpecifier,
            group: fileName,
            Basket: false,
          }
        }),
      )

      // ADDED: Process stopLoss and profitTarget for derivative entries
      adviceDerivativesEntries.forEach((entry) => {
        const stopLoss = entry.stopLoss > 0 ? entry.stopLoss : 0
        const profitTarget = entry.profitTarget > 0 ? entry.profitTarget : 0

        if (stopLoss > 0 || profitTarget > 0) {
          const matchedSymbol = entry.symbols.find(
            (s) =>
              s.searchSymbol === entry.searchSymbol && s.optionType === entry.optionType && s.strike === entry.strike,
          )

          if (matchedSymbol) {
            updateStopLossandProfitTarget({
              advisor: advisorName,
              alertName: matchedSymbol.symbol.replace("-EQ", "") + "_OCO_Alert",
              stock: matchedSymbol.symbol,
              exchange: entry.exchange,
              type: "oco",
              price1Level:
                profitTarget > 0
                  ? profitTarget
                  : entry.orderType === "BUY"
                    ? getLTPForSymbol2(matchedSymbol.symbol) * 100
                    : "0.1",
              price1Movement: entry.orderType === "BUY" ? "ltp_crossing_up" : "ltp_crossing_down",
              price2Level:
                stopLoss > 0
                  ? stopLoss
                  : entry.orderType === "BUY"
                    ? "0.1"
                    : getLTPForSymbol2(matchedSymbol.symbol) * 100,
              price2Movement: entry.orderType === "BUY" ? "ltp_crossing_down" : "ltp_crossing_up",
              notifyEmail: "yes",
              notifyWhatsapp: "yes",
              validity: entry.productType === "INTRADAY" ? "eod" : "gtt",
            })
          }
        }
      })
    }

    const config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}comms/send-reco`, // Assuming server is defined elsewhere
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(process.env.REACT_APP_AQ_KEYS, process.env.REACT_APP_AQ_SECRET),
      },
      data: adviceData,
    }

    axios
      .request(config)
      .then((response) => {
        setLoading(false)
        toast.success(
          activeDerivativesTab === "basketEntry"
            ? "Basket advice has been sent successfully"
            : "Derivatives advice has been sent successfully",
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          },
        )
        setTimeout(() => {
          if (activeDerivativesTab === "basketEntry") {
            setAdviceBasketEntries([
              {
                id: Date.now(),
                symbol: "",
                foType: "",
                expiry: "",
                strike: "",
                optionType: "",
                lots: 1,
                price: 0,
                // ADDED: stopLoss and profitTarget fields for basket reset
                stopLoss: 0,
                profitTarget: 0,
                commonBasketName: "",
                commonRationale: "",
                commonComment: "",
                commonExtendedComment: "",
                priority: 0,
              },
            ])
          } else {
            setAdviceDerivativesEntries([
              {
                id: Date.now(),
                symbol: "",
                foType: "",
                expiry: "",
                strike: "",
                optionType: "",
                lots: 1,
                price: 0,
                // ADDED: stopLoss and profitTarget fields for derivatives reset
                stopLoss: 0,
                profitTarget: 0,
                rationale: "",
                comments: "",
                comments2: "",
              },
            ])
          }
          setSendAdviceModal(false)
          setSelectedRows([])
        }, 2000)
      })
      .catch((error) => {
        console.log(error)
        toast.error(
          activeDerivativesTab === "basketEntry"
            ? "Error in sending Basket Advice!"
            : "Error in sending Derivatives Advice!",
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          },
        )
        setLoading(false)
      })
  }

  const handleAddBasketEntry = () => {
    const newEntry = {
      id: Date.now(),
      symbol: "",
      foType: "OPTIONS",
      expiry: "",
      strike: "",
      optionType: "",
      lots: " ",
      order: "MARKET",
      price: 0,
        // ADDED: stopLoss and profitTarget fields for new basket entry
        stopLoss: 0,
        profitTarget: 0,
      commonBasketName: adviceBasketEntries[0].commonBasketName,
      commonRationale: adviceBasketEntries[0].commonRationale,
      commonComment: adviceBasketEntries[0].commonComment,
      commonExtendedComment: adviceBasketEntries[0].commonExtendedComment,
      optionTypes: [],
      priority: 0,
    };
    setAdviceBasketEntries([...adviceBasketEntries, newEntry]);
  };
  // console.log("adviceBasketEntries", adviceBasketEntries);

  const handleBasketSymbolSelect = (index, symbol) => {
    setAdviceBasketEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index
          ? {
              ...entry,
              symbol: symbol.symbol,
              searchSymbol: symbol.searchSymbol,
              selectedSymbol: symbol.symbol.split(" ")[0],

              strike: symbol.strike,
              optionType: symbol.optionType,
              lots: symbol.lotsize,
              exchange: symbol.exchange,
              symbols: [],
              strikes: [symbol.strike],
              optionTypes: [symbol.optionType],
              fullSymbolObject: symbol,
              showBasketDropdown: false,
            }
          : entry
      )
    );
    setSelectedSymbols((prev) => {
      // Remove any existing entry for this index
      const filtered = prev.filter((item) => item.index !== index);

      // Add the new symbol
      return [
        ...filtered,
        {
          index,
          symbol: symbol.symbol,
          exchange: symbol.exchange,
        },
      ];
    });
    fetchBasketSymbols(index, symbol.searchSymbol, "OPTIONS");
  };
  const debouncedFetchBasketSymbols = useCallback(
    debounce((index, value, type) => {
      fetchBasketSymbols(index, value, type);
    }, 300),
    []
  );
  //  Added new function to fetch symbols for basket entry
  const fetchBasketSymbols = async (index, inputValue, type) => {
    if (inputValue.length < 3) return;

    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/fno/search`,
        {
          symbol: inputValue,
          type: type || "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      const symbols = response.data.match || [];

      setAdviceBasketEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index
            ? {
                ...entry,
                symbols: symbols,
                strikes:
                  type === "OPTIONS"
                    ? [...new Set(symbols.map((sym) => sym.strike))]
                        .filter(Boolean)
                        .sort((a, b) => a - b)
                    : [],
                optionTypes:
                  type === "OPTIONS"
                    ? [...new Set(symbols.map((sym) => sym.optionType))].filter(
                        Boolean
                      )
                    : [],
              }
            : entry
        )
      );
    } catch (error) {
      console.error("Error fetching basket symbols:", error);
    }
  };

  const fetchDerivativeSymbols = async (index, inputValue, type) => {
    if (inputValue.length < 3) return;

    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/fno/search`,
        {
          symbol: inputValue,
          type: type || "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      const symbols = response.data.match || [];
      console.log("symbols", symbols);

      setAdviceDerivativesEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index
            ? {
                ...entry,
                symbols: symbols,
              }
            : entry
        )
      );
    } catch (error) {
      console.error("Error fetching symbols:", error);
    }
  };

  // Updated handleBasketChange function to handle symbol input
  const handleBasketChange = (index, field, value) => {
    setAdviceBasketEntries((prevEntries) =>
      prevEntries.map((entry, i) => {
        if (i === index) {
          const updatedEntry = {
            ...entry,
            [field]: value,
            showBasketDropdown: true,
          };

          // Reset related fields when changing segment type
          if (field === "foType") {
            updatedEntry.strike = "";
            updatedEntry.optionType = "";
            updatedEntry.symbol = "";
            updatedEntry.searchSymbol = "";
            updatedEntry.symbols = [];
            updatedEntry.strikes = [];
            updatedEntry.optionTypes = [];
            updatedEntry.order = "";
            updatedEntry.productType = "";
            updatedEntry.orderType = "";
          }

          // Handle priority changes
          if (field === "priority") {
            const newPriority = parseInt(value, 10);
            if (!isNaN(newPriority) && newPriority > 0) {
              updatedEntry.priority = newPriority;
            }
          }

          if (
            field === "strike" ||
            field === "optionType" ||
            field === "lotMultiplier" ||
            field === "orderType" ||
            field === "order" ||
            field === "productType" ||
            field === "priority"
          ) {
            updatedEntry.showBasketDropdown = false;
          }

          return updatedEntry;
        }
        return entry;
      })
    );
    // Fetch symbols if necessary
    if (field === "foType" || (field === "searchSymbol" && value.length >= 3)) {
      const currentType =
        field === "foType" ? value : adviceBasketEntries[index].foType;
      const searchValue = field === "searchSymbol" ? value : "";
      debouncedFetchBasketSymbols(index, searchValue, currentType);
    }
  };

  const handleCommonFieldChange = (field, value) => {
    setAdviceBasketEntries((prevEntries) =>
      prevEntries.map((entry) => ({
        ...entry,
        [field]: value,
      }))
    );
  };

  const handleRemoveDerivativesEntry = (index) => {
    const updatedEntries = adviceDerivativesEntries.filter(
      (_, i) => i !== index
    );
    setAdviceDerivativesEntries(updatedEntries);
  };

  const handleRemoveBasketEntry = (index) => {
    const updatedEntries = adviceBasketEntries.filter((_, i) => i !== index);
    setAdviceBasketEntries(updatedEntries);
  };

  //previous Advices for Derivatives and Equity
  const [showPreviousAdvices, setShowPreviousAdvices] = useState(false);
  const [showPreviousBasketAdvices, setShowPreviousBasketAdvices] =
    useState(false);

  const handleSelectPreviousAdvices = (selectedAdvices) => {
    // Map the selected advices to match the format of adviceDerivativesEntries
    const formattedAdvices = selectedAdvices.map((advice) => ({
      id: Date.now() + Math.random(), // Generate a unique id
      foType: advice.foType,
      searchSymbol: advice.searchSymbol,
      strike: advice.strike,
      lotMultiplier: advice.lotMultiplier || 1,
      lots: advice.lots,
      optionType: advice.optionType,
      orderType: advice.orderType,
      order: advice.order,
      productType: advice.productType,
     // ADDED: Include stopLoss and profitTarget when selecting previous advices
     stopLoss: advice.stopLoss || 0,
     profitTarget: advice.profitTarget || 0,
      rationale: advice.rationale,
      comments: advice.comments,
      extendedComment: advice.extendedComment,
    }));

    setAdviceDerivativesEntries(formattedAdvices);
    setShowPreviousAdvices(false);
  };

  const handlePreviousBasketAdviceSelection = (selectedAdvices) => {
    // Map selected previous advices to the format expected by adviceBasketEntries
    const newEntries = selectedAdvices.map((advice) => ({
      id: Date.now() + Math.random(),
      foType: advice.foType,
      searchSymbol: advice.symbol,
      symbol: advice.symbol,
      strike: advice.strike,
      lotMultiplier: advice.lotMultiplier || 1,
      lots: advice.lots,
      optionType: advice.optionType,
      orderType: advice.orderType,
      order: advice.order || "MARKET",
      productType: advice.productType || "CARRYFORWARD",
     // ADDED: Include stopLoss and profitTarget when selecting previous basket advices
     stopLoss: advice.stopLoss || 0,
     profitTarget: advice.profitTarget || 0,
      priority: advice.priority || 0,
      symbols: [],
      strikes: [advice.strike],
      optionTypes: [advice.optionType],
    }));

    if (newEntries.length > 0) {
      setAdviceBasketEntries((prevEntries) => [
        {
          ...prevEntries[0],
          commonBasketName: selectedAdvices[0].basketName,
          commonRationale: selectedAdvices[0].rationale,
          commonComment: selectedAdvices[0].comments,
          commonExtendedComment: selectedAdvices[0].comment2,
        },
        ...newEntries,
      ]);
    }

    setShowPreviousBasketAdvices(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/75 backdrop-blur-sm"
        onClick={(e) => e.target === e.currentTarget && closeSendAdviceModal()}
      >
        {!showPreviousEquityAdvicesPopup &&
          !showQuantityPopUp &&
          !showPreviousAdvices &&
          !showPreviousBasketAdvices && (
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className="relative w-full max-w-[87rem] max-h-[90vh] bg-white rounded-xl shadow-xl overflow-hidden"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Header */}

              <div className="sticky top-0 z-10 bg-white/80 backdrop-blur-md border-b border-gray-200">
                <div className="px-6 py-4 flex items-center justify-between">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-900">
                      Send Advice to {fileName?.replaceAll(/_/g, " ")}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      {/* Configure and send advice to {data.length} selected client
                  {data.length !== 1 ? "s" : ""} */}
                      Configure and send advice to {filteredClients.length}{" "}
                      valid client
                      {filteredClients.length !== 1 ? "s" : ""} with active
                      subscriptions
                    </p>
                  </div>
                  <button
                    onClick={closeSendAdviceModal}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                  >
                    <XIcon className="w-5 h-5 text-gray-500" />
                  </button>
                </div>
              </div>

              {/* <div className="inline-block font-poppins text-base text-gray-700 px-3 py-1 border border-gray-300 rounded-md shadow-sm mt-4 mb-2 ml-8">
            <span>Basket Entry</span>
          </div> 

            {/* Table Section */}
              <div
                className="overflow-auto custom-scroll px-6 py-2"
                style={{ maxHeight: "calc(92vh - 180px)" }}
              >
                <button
                  onClick={() => setActiveTab("equity")}
                  className={`px-4 py-2 text-poppins text-base font-medium ${
                    activeTab === "equity"
                      ? "text-black border-b-2 border-black"
                      : "text-gray-500 hover:text-black"
                  }`}
                >
                  Equity
                </button>

                {showDerivativeTabForAdviosr === "true" ? (
                  <button
                    onClick={() => setActiveTab("derivatives")}
                    className={`px-4 py-2 text-poppins text-base font-medium ${
                      activeTab === "derivatives"
                        ? "text-black border-b-2 border-black"
                        : "text-gray-500 hover:text-black"
                    }`}
                  >
                    Derivatives
                  </button>
                ) : null}

                <div className="space-y-1 border-gray-200 " />

                {/* Main Section Code */}

                {activeTab === "equity" && (
                  <SendAdviceTable
                    showQuantityColumn={showQuantityColumn}
                    adviceEntries={adviceEntries}
                    handleInputChange={handleInputChange}
                    isOpen={isOpen}
                    handleSymbolSelect={handleSymbolSelect}
                    handleAdviceChange={handleAdviceChange}
                    getLTPForSymbol={getLTPForSymbol}
                    handleRationaleChange={handleRationaleChange}
                    modalPosition={modalPosition}
                    setModalPosition={setModalPosition}
                    CommentModal={CommentModal}
                    activeCommentModal={activeCommentModal}
                    setActiveCommentModal={setActiveCommentModal}
                    handleAddAdviceEntry={handleAddAdviceEntry}
                    handleRemoveAdviceEntry={handleRemoveAdviceEntry}
                    handleCommentsChange={handleCommentsChange}
                    handleComments2Change={handleComments2Change}
                    setPreviousEquityAdvicesPopup={
                      setPreviousEquityAdvicesPopup
                    }
                    setShowQuantityPopUp={setShowQuantityPopUp}
                  />
                )}

                {activeEmojiPicker && (
                  <div
                    ref={emojiPickerRef}
                    className="absolute z-20"
                    style={{
                      right:
                        activeEmojiPicker.type === "comment"
                          ? "120px"
                          : "395px",
                      bottom: "20px",
                    }}
                  >
                    <EmojiPicker
                      onEmojiClick={(emojiObject) =>
                        handleEmojiClick(
                          emojiObject,
                          activeEmojiPicker.index,
                          activeEmojiPicker.type
                        )
                      }
                      width={280}
                      height={350}
                    />
                  </div>
                )}

                {/* Derivatives Entry Code */}
                {activeTab === "derivatives" && (
                  <div className="mt-4 p-4 border border-gray-200 rounded-lg">
                    <div className="flex space-x-4 mb-4">
                      <button
                        onClick={() => setActiveDerivativesTab("entry")}
                        className={`px-4 py-2 text-poppins text-base font-medium ${
                          activeDerivativesTab === "entry"
                            ? "text-black border-b-2 border-black"
                            : "text-gray-500 hover:text-black"
                        }`}
                      >
                        Add Entry
                      </button>
                      <button
                        onClick={() => setActiveDerivativesTab("basketEntry")}
                        className={`px-4 py-2 text-poppins text-base font-medium ${
                          activeDerivativesTab === "basketEntry"
                            ? "text-black border-b-2 border-black"
                            : "text-gray-500 hover:text-black"
                        }`}
                      >
                        Basket Entry
                      </button>
                    </div>

                    {activeDerivativesTab === "entry" && (
                      <DerivativeSendAdviceTable
                        handleAddDerivativesEntry={handleAddDerivativesEntry}
                        adviceDerivativesEntries={adviceDerivativesEntries}
                        setAdviceDerivativesEntries={
                          setAdviceDerivativesEntries
                        }
                        handleDerivativeChange={handleDerivativeChange}
                        handleDerivativesInputChange={
                          handleDerivativesInputChange
                        }
                        handleDerivativesSymbolSelect={
                          handleDerivativesSymbolSelect
                        }
                        handleRemoveDerivativesEntry={
                          handleRemoveDerivativesEntry
                        }
                        setShowPreviousAdvices={setShowPreviousAdvices}
                      />
                    )}

                    {activeDerivativesTab === "basketEntry" && (
                      <BasketDerivativeAdviceTable
                        adviceBasketEntries={adviceBasketEntries}
                        setAdviceBasketEntries={setAdviceBasketEntries}
                        handleAddBasketEntry={handleAddBasketEntry}
                        handleBasketSymbolSelect={handleBasketSymbolSelect}
                        handleCommonFieldChange={handleCommonFieldChange}
                        showQuantityColumn={showQuantityColumn}
                        handleBasketChange={handleBasketChange}
                        handleRemoveBasketEntry={handleRemoveBasketEntry}
                        setShowPreviousBasketAdvices={
                          setShowPreviousBasketAdvices
                        }
                        showPreviousBasketAdvices={showPreviousBasketAdvices}
                      />
                    )}
                  </div>
                )}
              </div>

              {/* Footer - Equity */}
              <div className="sticky bottom-0 z-10 bg-gray-50 px-6 py-4 border-t border-gray-200">
                <div className="flex flex-col sm:flex-row items-center justify-between">
                  {activeTab === "equity" && (
                    <>
                      {!isValid && (
                        <div className="flex items-center text-red-500">
                          <AlertCircle className="w-4 h-4 mr-2" />
                          <span className="text-sm">
                            Please fill in all required fields
                          </span>
                        </div>
                      )}
                      <div className="flex space-x-3">
                        <button
                          onClick={closeSendAdviceModal}
                          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleSendAdvice}
                          disabled={!isValid || loading}
                          className="inline-flex items-center px-4 py-2  text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
                        >
                          {loading ? (
                            <span className="flex items-center">
                              <LoadingSpinner className="w-4 h-4 mr-2" />
                              Sending...
                            </span>
                          ) : (
                            "Send Advice"
                          )}
                        </button>
                      </div>
                    </>
                  )}

                  {activeTab === "derivatives" && (
                    <>
                      {activeDerivativesTab === "entry" && (
                        <>
                          {!isDerivativesValid(adviceDerivativesEntries) && (
                            <div className="flex items-center text-red-500">
                              <AlertCircle className="w-4 h-4 mr-2" />
                              <span className="text-sm">
                                Please fill in all required fields
                              </span>
                            </div>
                          )}
                          <div className="flex space-x-3">
                            <button
                              onClick={closeSendAdviceModal}
                              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleSendDerivativesAdvice}
                              disabled={
                                !isDerivativesValid(adviceDerivativesEntries) ||
                                loading
                              }
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
                            >
                              {loading ? (
                                <span className="flex items-center">
                                  <LoadingSpinner className="w-4 h-4 mr-2" />
                                  Sending...
                                </span>
                              ) : (
                                "Send Derivatives Advice"
                              )}
                            </button>
                          </div>
                        </>
                      )}
                      {activeDerivativesTab === "basketEntry" && (
                        <>
                          {!isDerivativesValid(adviceBasketEntries) && (
                            <div className="flex items-center text-red-500">
                              <AlertCircle className="w-4 h-4 mr-2" />
                              <span className="text-sm">
                                Please fill in all required fields{" "}
                              </span>
                            </div>
                          )}
                          <div className="flex space-x-3">
                            <button
                              onClick={closeSendAdviceModal}
                              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleSendDerivativesAdvice}
                              disabled={
                                !isDerivativesValid(adviceBasketEntries) ||
                                loading
                              }
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
                            >
                              {loading ? (
                                <span className="flex items-center">
                                  <LoadingSpinner className="w-4 h-4 mr-2" />
                                  Sending...
                                </span>
                              ) : (
                                "Send Basket Advice"
                              )}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </motion.div>
          )}

        {showPreviousEquityAdvicesPopup && (
          <PreviousEquityAdvices
            onClose={() => setPreviousEquityAdvicesPopup(false)}
            onSelect={handlePreviousAdviceSelection}
          />
        )}
        {showQuantityPopUp && (
          <QuantityUpdate
            showQuantityPopUp={showQuantityPopUp}
            onClose={() => setShowQuantityPopUp(false)}
            data={data}
            adviceEntries={adviceEntries}
            setAdviceEntries={setAdviceEntries}
          />
        )}

        {showPreviousAdvices && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <PreviousDerivativeAdvices
              onClose={() => setShowPreviousAdvices(false)}
              onSelect={handleSelectPreviousAdvices}
            />
          </div>
        )}

        {showPreviousBasketAdvices && (
          <PreviousBasketAdvices
            onClose={() => setShowPreviousBasketAdvices(false)}
            onSelect={handlePreviousBasketAdviceSelection}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
};
export default SendAdviceModel;
