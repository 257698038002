import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  ChevronLeftIcon,
  SendIcon,
  PlusIcon,
  SearchIcon,
  UsersIcon,
  InfoIcon,
  CircleIcon,
} from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ClientTableHeader } from "./ClientTableHeader";
import { ClientTableRow } from "./ClientTableRow";
import { SearchBar } from "./SearchBar";
import AddClientModal from "./AddClientModal";
import EditClientModal from "./EditClientModal";
import DeleteModal from "../GroupClientListSection/DeleteModal";
import SendAdviceModel from "../AdminHomeSection/SendAdviceModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import moment from "moment";

const ccxtUrl = process.env.REACT_APP_CCXT_SERVER_API_URL;
const serverUrl = process.env.REACT_APP_NODE_SERVER_API_URL;

const server = {
  ccxtServer: {
    baseUrl: ccxtUrl,
  },
  server: {
    baseUrl: serverUrl,
  },
  // server: {
  //   baseUrl: `http://localhost:8002/`,
  // },
};

const ClientListDetailsSection = ({
  userId,
  groupName,
  adminEmail,
  advisorName,
  email_password,
  allClientsDataByGroup,
  isClientDataLoading,
  getAllClientsData,
  adminRationale,
  advisorSpecifier,
  onGroupSelect,
  selectedGroup,
  groups,
}) => {
  const navigate = useNavigate();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(allClientsDataByGroup);
  const [addClientModal, setAddClientModal] = useState(false);
  const [sendAdviceModal, setSendAdviceModal] = useState(false);
  const [creationDate, setCreationDate] = useState(new Date().toISOString());
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [newClientData, setNewClientData] = useState({
    clientId: uuidv4().slice(0, 7),
    clientEmail: "",
    clientName: "",
    phone: "",
    location: "",
    telegram: "",
    pan: "",
  });

  useEffect(() => {
    setIsAllSelected(selectedRows.length === filteredData?.length);
  }, [selectedRows, filteredData]);

  const isSubscriptionValid = (subscriptions) => {
    if (!subscriptions || subscriptions?.length === 0) return false;

    // Filter out subscriptions that don't have a valid expiry field
    const validSubscriptions = subscriptions?.filter((sub) =>
      sub.expiry
        ? moment(sub.expiry, "D MMM YYYY, HH:mm:ss", true).isValid()
        : false
    );

    if (validSubscriptions?.length === 0) return false;

    // Sort subscriptions by expiry date in descending order
    const latestSub = validSubscriptions?.sort(
      (a, b) =>
        moment(b.expiry, "D MMM YYYY, HH:mm:ss") -
        moment(a.expiry, "D MMM YYYY, HH:mm:ss")
    )[0];

    // Parse expiry date and compare with the current date
    const expiryDate = moment(latestSub?.expiry, "D MMM YYYY, HH:mm:ss");
    const today = moment();

    // Check if the subscription is still valid
    return expiryDate.isAfter(today);
  };


  // const isSubscriptionValid = (subscriptions) => {
  //   if (!subscriptions || !Array.isArray(subscriptions) || subscriptions.length === 0) {
  //     return false;
  //   }

  //   try {
  //     // Get current date
  //     const today = new Date();

  //     // Find the latest expiry date among all subscriptions
  //     const latestExpiry = subscriptions.reduce((latestDate, subscription) => {
  //       // Parse the expiry date string
  //       // Handle both formats: "7 Dec 2024, 22:43:00" and "8 Apr 2025 at 18:19:30"
  //       const expiryStr = subscription.expiry.replace(' at ', ', ');
  //       const expiryDate = new Date(expiryStr);

  //       // If this expiry is later than our current latest, use it
  //       return expiryDate > latestDate ? expiryDate : latestDate;
  //     }, new Date(0));

  //     // Check if the latest expiry is in the future
  //     return latestExpiry > today;

  //   } catch (error) {
  //     console.error('Error in subscription validation:', error);
  //     return false;
  //   }
  // };




  const handleCheckboxChange = (item, isChecked) => {
    setSelectedRows((prev) => {
      if (isChecked) {
        // Only add if subscription is valid
        if (isSubscriptionValid(item.subscriptions)) {
          return [...prev, item];
        }
        // Show error toast if subscription is expired
        toast.error(`${item.clientName}'s subscription has expired`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
        });
        return prev;
      } else {
        return prev.filter((row) => row.email !== item.email);
      }
    });
  };

  const handleSelectAllChange = (isChecked) => {
    if (isChecked) {
      // Filter data to only include valid subscriptions
      const validSubscriptionClients = filteredData.filter((client) =>
        isSubscriptionValid(client.subscriptions)
      );

      setSelectedRows(validSubscriptionClients);

      // Show toast if some clients were skipped
      // const skippedCount =
      //   filteredData.length - validSubscriptionClients.length;
      // if (skippedCount > 0) {
      //   toast.error(
      //     `${skippedCount} client(s) with expired subscriptions were skipped`,
      //     {
      //       duration: 3000,
      //       style: {
      //         background: "white",
      //         color: "#1e293b",
      //         maxWidth: "500px",
      //         fontWeight: 600,
      //         fontSize: "13px",
      //         padding: "10px 20px",
      //       },
      //     }
      //   );
      // }
    } else {
      setSelectedRows([]);
    }
    setIsAllSelected(isChecked);
  };

  // Helper to get latest subscription data for a client
  const getLatestSubscription = (subscriptions) => {
    if (!subscriptions || subscriptions.length === 0) return null;

    return [...subscriptions].sort(
      (a, b) => new Date(b.expiry) - new Date(a.expiry)
    )[0];
  };

  const handleEditClient = (client) => {
    setClientToEdit({
      ...client,
      clientEmail: client.email, // Map email to clientEmail for the form
      creationDate: new Date(client.creationDate), // Ensure date is a Date object
    });
    setShowEditModal(true);
  };

  const handleDeleteClient = (client) => {
    setClientToDelete(client);
    setShowDeleteModal(true);
  };

  const handleUpdateClient = async (updatedClientData) => {
    try {
      const response = await fetch(
        `${server.server.baseUrl}api/edit-client-from-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: updatedClientData.clientId,
            clientName: updatedClientData.clientName,
            email: updatedClientData.email,
            phone: updatedClientData.phone,
            groups: updatedClientData.groups,
            location: updatedClientData.location,
            telegram: updatedClientData.telegram,
            pan: updatedClientData.pan,
            comments: updatedClientData.comments,
          }),
        }
      );

      if (response.ok) {
        getAllClientsData(userId);
        toast.success("Client details updated successfully!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
        });
      } else {
        throw new Error("Failed to update client");
      }
    } catch (error) {
      console.error("Error updating client:", error);
      toast.error("Failed to update client details", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
    }
  };

  const handleConfirmDelete = async (client) => {
    try {
      const response = await fetch(
        `${server.server.baseUrl}api/delete-client-from-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: client.clientId,
          }),
        }
      );

      if (response.ok) {
        getAllClientsData(userId);
        toast.success(`${client.clientName} has been deleted successfully`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
        });
      } else {
        throw new Error("Failed to delete client");
      }
    } catch (error) {
      console.error("Error deleting client:", error);
      toast.error("Failed to delete client", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
    }
  };

  const handleSendAdviceFromClientList = () => {
    if (selectedRows.length === 0) {
      toast.error("Please select the user(s) to send advice to.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
      return;
    }
    setSendAdviceModal(true);
  };

  const itemsPerPage = 30; // Show 30 items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    // Filter and sort on the full data set
    const sortedData = [...(allClientsDataByGroup || [])].sort(
      (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
    );

    const filtered = sortedData.filter((user) =>
      user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to page 1 after every new search
  }, [searchQuery, allClientsDataByGroup]);

  useEffect(() => {
    // Apply pagination to the filtered data
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedData(filteredData.slice(startIndex, endIndex));
  }, [filteredData, currentPage]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
    if (totalPages <= maxVisiblePages + 4) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
      pages.push(1);
      if (startPage > 2) pages.push("...");
      for (let i = startPage; i <= endPage; i++) pages.push(i);
      if (endPage < totalPages - 1) pages.push("...");
      pages.push(totalPages);
    }
    return pages;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="relative min-h-screen w-full bg-gradient-to-b from-gray-50 to-white"
    >
      <Toaster position="top-center" reverseOrder={true} />

      {/* Info Banner */}
      <div className="bg-gradient-to-r from-gray-900 to-gray-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center space-x-3">
            <InfoIcon className="h-5 w-5 text-gray-300" />
            <div>
              <h2 className="text-sm font-semibold">
                Client Management System
              </h2>
              <p className="text-xs text-gray-300 mt-0.5">
                Efficiently manage your client portfolio with comprehensive
                tools for client data management, group organization, and
                automated advice distribution.
              </p>
              <div className="flex gap-4 mt-3">
                <div className="flex items-center text-xs text-gray-300">
                  <CircleIcon className="h-2 w-2 mr-1 text-green-400" />
                  <span>{filteredData?.filter(client => isSubscriptionValid(client.subscriptions))?.length || 0} Active Clients in Group</span>
                </div>
                {/* <div className="flex items-center text-xs text-gray-300">
                  <CircleIcon className="h-2 w-2 mr-1 text-blue-400" />
                  <span>{selectedRows.length} Selected</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Header Section */}
      <div className="sticky top-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-100 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-[85rem] mx-auto mt-1">
            <div
              onClick={() => navigate(-1)}
              className="inline-flex items-center text-gray-600 hover:text-gray-900 transition-colors cursor-pointer"
            >
              <ChevronLeftIcon className="h-4 w-4 mr-1" />
              <span className="text-sm font-medium">Back to Home</span>
            </div>

            <div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div className="flex flex-col space-y-2">
                <h1 className="text-2xl font-bold text-gray-900 capitalize">
                  {groupName?.replaceAll(/_/g, " ")}
                </h1>
              </div>

              <div className="flex flex-col sm:flex-row gap-3">
                <button
                  onClick={() => setAddClientModal(true)}
                  className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all"
                >
                  <PlusIcon className="w-4 h-4 mr-2" />
                  Add Client
                </button>
                <button
                  onClick={handleSendAdviceFromClientList}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-all"
                >
                  <SendIcon className="w-4 h-4 mr-2" />
                  Send Advice
                </button>
              </div>
            </div>

            <div className="mt-4">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mt-4">
          <div className="max-w-[85rem] mx-auto ">
            {isClientDataLoading ? (
              <div className="flex items-center justify-center h-64">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-sm">
                <div className="overflow-y-auto max-h-[calc(84vh-200px)] rounded-lg border border-gray-200 shadow-sm">
                  <table className="min-w-full divide-y divide-gray-200">
                    <ClientTableHeader
                      isAllSelected={isAllSelected}
                      onSelectAll={handleSelectAllChange}
                    />
                    <tbody className="bg-white divide-y divide-gray-200">
                      {paginatedData?.map((item) => (
                        <ClientTableRow
                          key={item.clientId}
                          item={item}
                          isSelected={selectedRows.some(
                            (row) => row.email === item.email
                          )}
                          onCheckboxChange={handleCheckboxChange}
                          onEdit={() => handleEditClient(item)}
                          onDelete={() => handleDeleteClient(item)}
                          isSubscriptionValid={isSubscriptionValid}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-center items-center p-4 space-x-1">
                  {/* Previous Button */}
                  <button
                    onClick={() => goToPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
                  >
                    Previous
                  </button>

                  {/* Page Numbers */}
                  {getPageNumbers().map((page, index) =>
                    page === "..." ? (
                      <span key={index} className="px-3 py-1 text-gray-600">
                        ...
                      </span>
                    ) : (
                      <button
                        key={index}
                        onClick={() => goToPage(page)}
                        className={`px-3 py-1 text-sm rounded ${
                          page === currentPage
                            ? "bg-blue-500 text-white"
                            : "bg-gray-300"
                        }`}
                      >
                        {page}
                      </button>
                    )
                  )}

                  {/* Next Button */}
                  <button
                    onClick={() => goToPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modals */}
      {addClientModal && (
        <AddClientModal
          closeAddClientModal={() => setAddClientModal(false)}
          setNewClientData={setNewClientData}
          newClientData={newClientData}
          userId={userId}
          setAddClientModal={setAddClientModal}
          getAllClientsData={getAllClientsData}
          groupName={groupName}
          setCreationDate={setCreationDate}
          creationDate={creationDate}
        />
      )}

      {showEditModal && (
        <EditClientModal
          client={clientToEdit}
          onClose={() => setShowEditModal(false)}
          onUpdate={handleUpdateClient}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          client={clientToDelete}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleConfirmDelete}
        />
      )}

      {sendAdviceModal && (
        <SendAdviceModel
          fileName={groupName}
          closeSendAdviceModal={() => setSendAdviceModal(false)}
          data={selectedRows}
          adminEmail={adminEmail}
          advisorName={advisorName}
          email_password={email_password}
          setSendAdviceModal={setSendAdviceModal}
          setSelectedRows={setSelectedRows}
          adminRationale={adminRationale}
          advisorSpecifier={advisorSpecifier}
        />
      )}
    </motion.div>
  );
};

export default ClientListDetailsSection;
