import React from "react";
import { Edit2Icon, Trash2Icon } from "lucide-react";
import FormatDateTime from "../../utils/formatDateTime";

export const ClientTableRow = ({
  item,
  isSelected,
  onCheckboxChange,
  onEdit,
  onDelete,
  isSubscriptionValid,
}) => {
  return (
    <tr
      className={
        isSubscriptionValid(item?.subscriptions) === false
          ? "bg-gray-300 transition-colors"
          : "hover:bg-gray-50 transition-colors"
      }
    >
      <td className="px-4 py-3 whitespace-nowrap">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={(e) => onCheckboxChange(item, e.target.checked)}
          className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        {item.clientId}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.email}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.clientName}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.phone}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.location}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.telegram || "-"}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.pan || "-"}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {isSubscriptionValid(item?.subscriptions) === true
          ? "Active"
          : "Expired"}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {FormatDateTime(item.creationDate)}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        <div className="flex items-center space-x-3">
          <button
            onClick={() => onEdit(item)}
            className="text-gray-600 hover:text-gray-900 transition-colors"
            aria-label="Edit client"
          >
            <Edit2Icon className="h-4 w-4" />
          </button>
          <button
            onClick={() => onDelete(item)}
            className="text-gray-600 hover:text-red-600 transition-colors"
            aria-label="Delete client"
          >
            <Trash2Icon className="h-4 w-4" />
          </button>
        </div>
      </td>
    </tr>
  );
};
