import React, { useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import axios from "axios";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import server from "../../utils/serverConfig";
import { useNavigate } from "react-router-dom";
import { Mail, Eye, EyeOff } from "lucide-react";
import { saveTokenInLocalStorage } from "../../services/AuthService";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5 bg-white text-lg peer text-gray-900 placeholder-transparent font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] transition ease-in duration-200",
  labelFloat:
    "absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  inputStartDiv: "relative w-full",
};

const AdminSignUpEmail = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkEmailAllowed = async (email) => {
    try {
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/allowed/login`,
        { email }
      );
      return response.status === 200;
    } catch (error) {
      return false;
    }
  };

  const signUpWithEmail = async () => {
    setLoading(true);

    try {
      // First check if email is allowed
      const isAllowed = await checkEmailAllowed(email);

      if (!isAllowed) {
        setLoading(false);
        toast.error(
          'This email is not authorized. Please contact admin@alphaquark.in for advisor onboarding.',
          {
            duration: 6000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "560px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
        return;
      }

      const auth = getAuth();
      const response = await createUserWithEmailAndPassword(auth, email, password);
      const userDetails = response?._tokenResponse;
      const token = response.user?.accessToken;

      if (token) {
        saveTokenInLocalStorage(userDetails, token);
        dispatch(loginConfirmedAction({ ...userDetails, token }));
        navigate("/admin");
      }
    } catch (err) {
      setLoading(false);
      if (err.code === 'auth/email-already-in-use') {
        toast.error(
          'This email is already registered. Please sign in instead.',
          {
            duration: 5000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "560px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        toast.error(
          'Sign-up failed. Please try again.',
          {
            duration: 5000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "560px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      }
    }
  };

  return (
    <div className="w-full bg-white p-4 relative rounded-lg font-Poppins">
      <button
        onClick={() => props.setShowSignUp(false)}
        className="h-10 w-10 flex flex-row justify-center items-center rounded-full text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400 ease-linear duration-150 cursor-pointer"
      >
        <div>
          <MdOutlineArrowBack size={25} />
        </div>
        <div className="hidden md:flex">Back</div>
      </button>

      <div className="py-8">
        {/* Info Message */}
        <div className="mb-6 px-4 py-3 bg-gray-50 rounded-lg text-sm text-gray-600">
          Note: Sign-up is restricted to authorized advisors only. Please contact{" "}
          <a href="mailto:hello@alphaquark.in" className="text-blue-600 hover:text-blue-700 font-medium">
            hello@alphaquark.in
          </a>{" "}
          for onboarding.
        </div>

        <div className={style.inputStartDiv}>
          <input
            id="email"
            type="email"
            placeholder="Enter your email"
            className={`${style.inputBox}`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label
            htmlFor="email"
            className={`${style.firstHeading} ${style.labelFloat}`}
          >
            Enter your email
          </label>
        </div>
        <div className={style.inputStartDiv}>
          <input
            id="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            className={`${style.inputBox} pr-12`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label
            htmlFor="password"
            className={`${style.firstHeading} ${style.labelFloat}`}
          >
            Enter your password
          </label>
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-4 top-[26px] text-gray-500 hover:text-gray-700"
          >
            {showPassword ? (
              <EyeOff className="w-5 h-5" />
            ) : (
              <Eye className="w-5 h-5" />
            )}
          </button>
        </div>

        <div className="mt-8">
          <button
            onClick={signUpWithEmail}
            disabled={!email || !password || loading}
            className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed flex flex-row items-center justify-center py-3 w-full mb-3 rounded-lg bg-black/100 text-white font-medium hover:bg-black/90 ease-linear duration-150"
          >
            <div className="text-white font-medium text-lg font-poppins">
              {loading ? (
                <span className="flex flex-row justify-center">
                  <svg
                    className="h-10 w-10 text-[#ffffff] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </span>
              ) : (
                <div className="flex flex-row items-center justify-center">
                  <Mail size={25} className="mr-2" />
                  Sign up with Email
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminSignUpEmail;