import React from "react";

import DashboardLayout from "../RootSection/DashboardLayout";
import StrategyList from "./StrategyList";

const AllStrategyListHomePage = () => {
  return (
    <DashboardLayout>
      <StrategyList />
    </DashboardLayout>
  );
};

export default AllStrategyListHomePage;
