import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
} from "../actions/AuthActions";

const initialState = {
  auth: {
    email: "",
    token: "",
    localId: "",
    expiresIn: "",
    refreshToken: "",
  },
  errorMessage: "",
  successMessage: "",
  showLoading: true,
};

export function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_CONFIRMED_ACTION:
      return {
        ...state,
        auth: action.payload,
        errorMessage: "",
        successMessage: "Login Successfully Completed",
        showLoading: false,
      };
    case LOGOUT_ACTION:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
        auth: {
          email: "",
          token: "",
          localId: "",
          expiresIn: "",
          refreshToken: "",
        },
      };
    case LOGIN_FAILED_ACTION:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: "",
        showLoading: false,
      };
    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
}

export const isAuthenticated = (state) => !!state.auth.token;
