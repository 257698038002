// import { useState, useRef } from "react"
// import {
//   ArrowUp,
//   ArrowDown,
//   Target,
//   Clock,
//   FileText,
//   Landmark,
//   BarChart2,
//   Download,
//   Edit,
//   Save,
//   X,
//   Search,
//   Plus,
//   Loader2,
// } from "lucide-react"
// import { Line } from "react-chartjs-2"
// import server from "../../src/utils/serverConfig"
// import { jsPDF } from "jspdf"
// import html2canvas from "html2canvas"
//
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js"
//
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
//
// export default function Report() {
//   const [stockData, setStockData] = useState({
//     ticker: "",
//     analyst_recommendations: {
//       buy: 0,
//       hold: 0,
//       sell: 0,
//     },
//     company_info: {
//       country: "",
//       employees: 0,
//       founded: "",
//       industry: "",
//       name: "",
//       sector: "",
//       ticker: "",
//       website: "",
//     },
//     key_metrics: {
//       dividend_yield: 0,
//       market_cap: 0,
//       market_cap_in_crores: 0,
//       pe_ratio: 0,
//       profit_margin: 0,
//       return_on_assets: 0,
//       return_on_equity: 0,
//     },
//     price_data: {
//       current_price: 0,
//       price_change: 0,
//       price_change_percent: 0,
//       price_history: {
//         close: [],
//         dates: [],
//         volume: [],
//       },
//     },
//     insights: {
//       executive_summary: null,
//       investment_thesis: null,
//       risk_analysis: null,
//     },
//   })
//
//   const [isEditing, setIsEditing] = useState(false)
//   const [summaryText, setSummaryText] = useState("")
//   const [isLoading, setIsLoading] = useState(false)
//   const [error, setError] = useState("")
//   const textareaRef = useRef(null)
//   const reportRef = useRef(null)
//
//   // Section-specific loading states
//   const [loadingStates, setLoadingStates] = useState({
//     summary: false,
//     priceChart: false,
//     keyMetrics: false,
//     investmentThesis: false,
//     riskFactors: false,
//     analystConsensus: false,
//     companyOverview: false,
//   })
//
//   // New state for ticker search functionality
//   const [searchTicker, setSearchTicker] = useState("")
//   const [isSearching, setIsSearching] = useState(false)
//   const [searchResults, setSearchResults] = useState([])
//   const [isLoadingSearch, setIsLoadingSearch] = useState(false)
//   const [dataFetched, setDataFetched] = useState(false)
//
//   // Format market cap for display
//   const formatMarketCap = (value) => {
//     if (!value) return "N/A"
//
//     if (value >= 10000000000) {
//       return `${(value / 10000000000).toFixed(2)}L Cr`
//     } else if (value >= 100000000) {
//       return `${(value / 10000000).toFixed(2)} Cr`
//     } else {
//       return value.toLocaleString()
//     }
//   }
//
//   // Replace the EmptyState component with a centered ticker selection box
//   const EmptyState = () => (
//     <div className="flex flex-col items-center justify-center py-12 text-center min-h-[80vh]">
//       <div className="bg-white rounded-lg p-6 w-full max-w-md shadow-lg border border-gray-200">
//         <h3 className="text-xl font-bold mb-4">Stock Financial Report</h3>
//         <p className="text-gray-500 mb-6">Enter a stock ticker or company name to view the financial report.</p>
//
//         <div className="flex mb-4">
//           <input
//             type="text"
//             value={searchTicker}
//             onChange={(e) => setSearchTicker(e.target.value)}
//             placeholder="Enter company name or ticker"
//             className="flex-1 p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-primary"
//           />
//           <button
//             onClick={() => handleSearchTickers()}
//             className="bg-primary text-white px-4 py-2 rounded-r-md hover:bg-primary/90"
//           >
//             <Search size={20} />
//           </button>
//         </div>
//
//         {isLoadingSearch ? (
//           <div className="py-4 text-center">
//             <Loader2 className="h-6 w-6 animate-spin mx-auto text-primary" />
//             <p className="mt-2 text-sm text-gray-500">Searching...</p>
//           </div>
//         ) : (
//           <div className="max-h-60 overflow-y-auto">
//             {/* Using mock data for demonstration */}
//             {mockSearchResults.length > 0 ? (
//               <ul className="divide-y divide-gray-200">
//                 {mockSearchResults.map((result) => (
//                   <li
//                     key={result.ticker}
//                     className="py-2 px-1 hover:bg-gray-100 cursor-pointer"
//                     onClick={() => selectTicker(result.ticker, result.name)}
//                   >
//                     <div className="font-medium">{result.name}</div>
//                     <div className="text-sm text-gray-500">{result.ticker}</div>
//                   </li>
//                 ))}
//               </ul>
//             ) : searchTicker ? (
//               <div className="py-4 text-center text-gray-500">No results found</div>
//             ) : null}
//           </div>
//         )}
//       </div>
//     </div>
//   )
//
//   // Update the fetchData function to set all loading states to true at once
//   const fetchData = async (ticker) => {
//     if (!ticker) return
//
//     try {
//       // Set all section loading states to true at once
//       setLoadingStates({
//         summary: true,
//         priceChart: true,
//         keyMetrics: true,
//         investmentThesis: true,
//         riskFactors: true,
//         analystConsensus: true,
//         companyOverview: true,
//       })
//       setIsLoading(true)
//
//       // Fetch company insights data for executive summary
//       const insightsResponse = await fetch(`${server.ccxtServer.baseUrl}misc/company-insights/${ticker}?use_groq=True`)
//       const insightsResult = await insightsResponse.json()
//
//       // Fetch financial data from the new API endpoint
//       const financialDataResponse = await fetch(
//         `${server.ccxtServer.baseUrl}misc/financial-data/${ticker}?use_groq=True`,
//       )
//       const financialDataResult = await financialDataResponse.json()
//
//       // Update with company insights data for executive summary
//       if (insightsResult.status === "success" && insightsResult.data) {
//         if (insightsResult.data.insights && insightsResult.data.insights.executive_summary) {
//           setSummaryText(insightsResult.data.insights.executive_summary)
//           setStockData((prevData) => ({
//             ...prevData,
//             insights: {
//               ...prevData.insights,
//               executive_summary: insightsResult.data.insights.executive_summary,
//               investment_thesis: insightsResult.data.insights.investment_thesis || null,
//               risk_analysis: insightsResult.data.insights.risk_analysis || null,
//             },
//           }))
//         } else {
//           setSummaryText("No executive summary available for this stock.")
//         }
//       } else {
//         setSummaryText("No executive summary available for this stock.")
//       }
//
//       // Update with financial data from the new API
//       if (financialDataResult.status === "success" && financialDataResult.data) {
//         setStockData((prevData) => ({
//           ...prevData,
//           analyst_recommendations: financialDataResult.data.analyst_recommendations,
//           company_info: financialDataResult.data.company_info,
//           key_metrics: financialDataResult.data.key_metrics,
//           price_data: financialDataResult.data.price_data,
//           ticker: financialDataResult.data.company_info.ticker,
//         }))
//       }
//
//       setDataFetched(true)
//
//       // Simulate staggered loading completion for different sections
//       // This creates a more realistic loading experience where different sections
//       // complete at different times
//       setTimeout(() => {
//         setLoadingStates((prev) => ({ ...prev, summary: false }))
//       }, 1000)
//
//       setTimeout(() => {
//         setLoadingStates((prev) => ({ ...prev, keyMetrics: false }))
//       }, 1500)
//
//       setTimeout(() => {
//         setLoadingStates((prev) => ({ ...prev, priceChart: false }))
//       }, 2000)
//
//       setTimeout(() => {
//         setLoadingStates((prev) => ({ ...prev, investmentThesis: false, riskFactors: false }))
//       }, 2500)
//
//       setTimeout(() => {
//         setLoadingStates((prev) => ({ ...prev, analystConsensus: false, companyOverview: false }))
//       }, 3000)
//     } catch (err) {
//       console.error("Error fetching data:", err)
//       setError("Failed to load report data")
//       setSummaryText("No executive summary available for this stock.")
//
//       // Reset all loading states on error
//       setLoadingStates({
//         summary: false,
//         priceChart: false,
//         keyMetrics: false,
//         investmentThesis: false,
//         riskFactors: false,
//         analystConsensus: false,
//         companyOverview: false,
//       })
//     } finally {
//       setIsLoading(false)
//     }
//   }
//
//   // Update the selectTicker function to show the report immediately with loaders
//   const selectTicker = async (ticker, name) => {
//     setStockData({
//       ...stockData,
//       ticker: ticker,
//       company_info: {
//         ...stockData.company_info,
//         name: name,
//         ticker: ticker,
//       },
//     })
//     setIsSearching(false)
//     setSearchTicker("")
//     setSearchResults([])
//     setDataFetched(true) // Set to true immediately to show the report with loaders
//
//     // Fetch data for the selected ticker
//     fetchData(ticker)
//   }
//
//   const handleSearchTickers = async () => {
//     if (!searchTicker.trim()) return
//
//     try {
//       setIsLoadingSearch(true)
//       // Replace with your actual API endpoint for searching tickers
//       const response = await fetch(
//         `${server.ccxtServer.baseUrl}/search/tickers?query=${encodeURIComponent(searchTicker)}`,
//       )
//       const data = await response.json()
//
//       if (data.status === "success" && data.data) {
//         setSearchResults(data.data.slice(0, 5)) // Limit to top 5 results
//       } else {
//         setSearchResults([])
//       }
//     } catch (err) {
//       console.error("Error searching tickers:", err)
//       setSearchResults([])
//     } finally {
//       setIsLoadingSearch(false)
//     }
//   }
//
//   // Mock search results for demo purposes
//   const mockSearchResults = [
//     { ticker: "HDFC.NS", name: "HDFC Bank Ltd" },
//     { ticker: "ITC.NS", name: "ITC Limited" },
//     { ticker: "INFY.NS", name: "Infosys Ltd" },
//     { ticker: "ICICIBANK.NS", name: "ICICI Bank Ltd" },
//     { ticker: "RELIANCE.NS", name: "Reliance Industries Ltd" },
//   ]
//
//   const handleDownloadPDF = async () => {
//     if (!reportRef.current) return
//
//     try {
//       // Create a clone of the report element to modify for PDF
//       const reportClone = reportRef.current.cloneNode(true)
//
//       // Remove the fixed buttons from the clone
//       const buttonsContainer = reportClone.querySelector(".fixed.top-4.right-4")
//       if (buttonsContainer) {
//         buttonsContainer.remove()
//       }
//
//       // Remove any edit buttons
//       const editButtons = reportClone.querySelectorAll("button")
//       editButtons.forEach((button) => {
//         // Check if the button contains edit-related text or icons
//         if (
//           button.textContent.includes("Edit") ||
//           button.textContent.includes("Save") ||
//           button.textContent.includes("Cancel")
//         ) {
//           button.remove()
//         }
//       })
//
//       // Set styles to ensure full content is visible for capture
//       reportClone.style.position = "absolute"
//       reportClone.style.left = "-9999px"
//       reportClone.style.width = `${reportRef.current.offsetWidth}px`
//       reportClone.style.height = "auto"
//       reportClone.style.transform = "none"
//       reportClone.style.overflow = "visible"
//
//       document.body.appendChild(reportClone)
//
//       // Create a PDF with multiple pages if needed
//       const pdf = new jsPDF("p", "mm", "a4")
//       const pdfWidth = pdf.internal.pageSize.getWidth()
//       const pdfHeight = pdf.internal.pageSize.getHeight()
//
//       // Add title and date to first page
//       pdf.setFontSize(18)
//       pdf.text(`${stockData.company_info.name} - Financial Report`, 14, 15)
//       pdf.setFontSize(10)
//       pdf.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, 22)
//
//       // Get all sections to capture them individually
//       const sections = reportClone.querySelectorAll(".mb-8, .mt-8")
//       let currentY = 30 // Start position after the title
//
//       // Capture and add each section to the PDF
//       for (let i = 0; i < sections.length; i++) {
//         const section = sections[i]
//
//         // Skip empty sections
//         if (!section.offsetHeight) continue
//
//         const canvas = await html2canvas(section, {
//           scale: 2,
//           useCORS: true,
//           logging: false,
//           allowTaint: true,
//         })
//
//         const imgData = canvas.toDataURL("image/png")
//         const imgWidth = pdfWidth - 20 // Add some margin
//         const imgHeight = (canvas.height * imgWidth) / canvas.width
//
//         // Check if this section needs to start on a new page
//         if (currentY + imgHeight > pdfHeight - 10) {
//           pdf.addPage()
//           currentY = 15 // Reset Y position on new page
//         }
//
//         // Add the section image to the PDF
//         pdf.addImage(imgData, "PNG", 10, currentY, imgWidth, imgHeight)
//         currentY += imgHeight + 10 // Add some space between sections
//       }
//
//       // Remove the clone from the document
//       document.body.removeChild(reportClone)
//
//       pdf.save(`${stockData.ticker}_financial_report.pdf`)
//     } catch (error) {
//       console.error("Error generating PDF:", error)
//       alert("There was an error generating the PDF. Please try again.")
//     }
//   }
//
//
//   const handleEditToggle = () => {
//     setIsEditing(!isEditing)
//     if (!isEditing && textareaRef.current) {
//       // Focus the textarea when entering edit mode
//       setTimeout(() => textareaRef.current.focus(), 0)
//     }
//   }
//
//   const handleSaveSummary = () => {
//     setIsEditing(false)
//     // Update the stockData with the edited summary
//     setStockData((prevData) => ({
//       ...prevData,
//       insights: {
//         ...prevData.insights,
//         executive_summary: summaryText,
//       },
//     }))
//     console.log("Saved summary:", summaryText)
//   }
//
//   const handleCancelEdit = () => {
//     // Revert to the original summary from stockData
//     if (stockData.insights && stockData.insights.executive_summary) {
//       setSummaryText(stockData.insights.executive_summary)
//     } else {
//       setSummaryText("No executive summary available for this stock.")
//     }
//     setIsEditing(false)
//   }
//
//   // Prepare chart data from price history
//   const chartData = {
//     labels: stockData.price_data.price_history.dates.map((date) => (date ? date.substring(5) : "")), // Format: "2024-04" -> "04"
//     datasets: [
//       {
//         label: "Stock Price (₹)",
//         data: stockData.price_data.price_history.close,
//         borderColor: "rgb(53, 162, 235)",
//         backgroundColor: "rgba(53, 162, 235, 0.5)",
//         tension: 0.3,
//       },
//     ],
//   }
//
//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//       title: {
//         display: true,
//         text: "12 Month Price Movement",
//       },
//     },
//   }
//
//   // Format risk analysis for display
//   const renderRiskAnalysis = () => {
//     if (!stockData.insights || !stockData.insights.risk_analysis) return null
//
//     // If risk_analysis is an array, render as list items
//     if (Array.isArray(stockData.insights.risk_analysis)) {
//       return (
//         <ul className="list-disc list-inside text-gray-700 space-y-3">
//           {stockData.insights.risk_analysis.map((risk, index) => (
//             <li key={index}>{risk}</li>
//           ))}
//         </ul>
//       )
//     }
//
//     // Otherwise render as paragraph
//     return <p className="text-gray-700">{stockData.insights.risk_analysis}</p>
//   }
//
//   // Loading indicator component
//   const LoadingIndicator = () => (
//     <div className="flex justify-center items-center py-8">
//       <Loader2 className="h-8 w-8 animate-spin text-primary" />
//     </div>
//   )
//
//   // Remove the ticker search modal since we now have the inline search
//   // Replace the entire return statement with this updated version
//   return (
//     <div className="min-h-screen bg-white p-6 font-serif" ref={reportRef}>
//       {!dataFetched ? (
//         <EmptyState />
//       ) : (
//         <div className="max-w-6xl mx-auto">
//           <div className="fixed top-4 right-4 z-10 flex space-x-2">
//             <button
//               onClick={() => {
//                 setIsSearching(true)
//                 setDataFetched(false) // Reset to show the search box again
//               }}
//               className="flex items-center bg-primary border-e-zinc-600 text-black px-4 py-2 rounded-md shadow-md hover:bg-primary/90 transition-colors"
//             >
//               <Plus size={16} className="mr-2" />
//               Change Ticker
//             </button>
//             <button
//               onClick={handleDownloadPDF}
//               className="flex items-center bg-black text-white px-4 py-2 rounded-md shadow-md hover:bg-primary/90 transition-colors"
//             >
//               <Download size={16} className="mr-2" />
//               Download PDF
//             </button>
//
//           </div>
//
//           <div className="text-center mb-8 border-b border-gray-300 pb-4">
//             <h1 className="text-3xl font-bold text-gray-900 mb-1">{stockData.company_info.name || "Loading..."}</h1>
//             <p className="text-gray-600">
//               {stockData.ticker} • {stockData.company_info.sector || "Loading..."}
//             </p>
//             <div className="flex justify-center items-center mt-4">
//               <span className="text-3xl font-bold mr-3">
//                 {loadingStates.keyMetrics ? "₹--" : `₹${stockData.price_data.current_price}`}
//               </span>
//               {!loadingStates.keyMetrics && (
//                 <div
//                   className={`flex items-center ${stockData.price_data.price_change > 0 ? "text-green-700" : "text-red-700"}`}
//                 >
//                   {stockData.price_data.price_change > 0 ? <ArrowUp size={20} /> : <ArrowDown size={20} />}
//                   <span className="font-semibold ml-1">
//                     {stockData.price_data.price_change > 0 ? "+" : ""}
//                     {stockData.price_data.price_change.toFixed(2)} (
//                     {stockData.price_data.price_change_percent.toFixed(2)}%)
//                   </span>
//                 </div>
//               )}
//             </div>
//           </div>
//
//           <div className="mb-8">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-2xl font-bold text-gray-900 border-b border-gray-300 pb-2">Executive Summary</h2>
//               {!loadingStates.summary && !isEditing ? (
//                 <button onClick={handleEditToggle} className="flex items-center text-gray-600 hover:text-gray-900">
//                   <Edit size={16} className="mr-1" />
//                   Edit
//                 </button>
//               ) : isEditing ? (
//                 <div className="flex space-x-2">
//                   <button onClick={handleSaveSummary} className="flex items-center text-green-600 hover:text-green-800">
//                     <Save size={16} className="mr-1" />
//                     Save
//                   </button>
//                   <button onClick={handleCancelEdit} className="flex items-center text-red-600 hover:text-red-800">
//                     <X size={16} className="mr-1" />
//                     Cancel
//                   </button>
//                 </div>
//               ) : null}
//             </div>
//
//             {loadingStates.summary ? (
//               <LoadingIndicator />
//             ) : error ? (
//               <div className="text-red-500">{error}</div>
//             ) : isEditing ? (
//               <textarea
//                 ref={textareaRef}
//                 value={summaryText}
//                 onChange={(e) => setSummaryText(e.target.value)}
//                 className="w-full p-3 border border-gray-300 rounded-md min-h-[150px] focus:ring-2 focus:ring-primary focus:border-transparent"
//               />
//             ) : (
//               <p className="text-gray-700 leading-relaxed">
//                 {summaryText || "No executive summary available for this stock."}
//               </p>
//             )}
//           </div>
//
//           <div className="mb-8">
//             <h2 className="text-2xl font-bold mb-4 text-gray-900 border-b border-gray-300 pb-2">Price Performance</h2>
//             {loadingStates.priceChart ? (
//               <LoadingIndicator />
//             ) : (
//               <div className="bg-white p-4 border border-gray-300">
//                 <Line options={chartOptions} data={chartData} height={80} />
//               </div>
//             )}
//           </div>
//
//           <div className="mb-8">
//             <h2 className="text-2xl font-bold mb-4 text-gray-900 border-b border-gray-300 pb-2">Key Metrics</h2>
//             {loadingStates.keyMetrics ? (
//               <LoadingIndicator />
//             ) : (
//               <>
//                 <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
//                   <div className="border border-gray-300 p-4">
//                     <p className="text-gray-600 text-sm">Market Cap</p>
//                     <p className="font-bold text-xl">₹{formatMarketCap(stockData.key_metrics.market_cap)}</p>
//                   </div>
//                   <div className="border border-gray-300 p-4">
//                     <p className="text-gray-600 text-sm">P/E Ratio</p>
//                     <p className="font-bold text-xl">{stockData.key_metrics.pe_ratio.toFixed(2)}</p>
//                   </div>
//                   <div className="border border-gray-300 p-4">
//                     <p className="text-gray-600 text-sm">Dividend Yield</p>
//                     <p className="font-bold text-xl">{stockData.key_metrics.dividend_yield.toFixed(2)}%</p>
//                   </div>
//                   <div className="border border-gray-300 p-4">
//                     <p className="text-gray-600 text-sm">ROE</p>
//                     <p className="font-bold text-xl">{stockData.key_metrics.return_on_equity.toFixed(2)}%</p>
//                   </div>
//                 </div>
//
//                 {/* Additional metrics from the API */}
//                 <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
//                   <div className="border border-gray-300 p-4">
//                     <p className="text-gray-600 text-sm">ROA</p>
//                     <p className="font-bold text-xl">{stockData.key_metrics.return_on_assets.toFixed(2)}%</p>
//                   </div>
//                   <div className="border border-gray-300 p-4">
//                     <p className="text-gray-600 text-sm">Profit Margin</p>
//                     <p className="font-bold text-xl">{stockData.key_metrics.profit_margin.toFixed(2)}%</p>
//                   </div>
//                   <div className="border border-gray-300 p-4">
//                     <p className="text-gray-600 text-sm">Market Cap (Cr)</p>
//                     <p className="font-bold text-xl">₹{stockData.key_metrics.market_cap_in_crores.toLocaleString()}</p>
//                   </div>
//                   <div className="border border-gray-300 p-4">
//                     <p className="text-gray-600 text-sm">Current Price</p>
//                     <p className="font-bold text-xl">₹{stockData.price_data.current_price.toFixed(2)}</p>
//                   </div>
//                 </div>
//               </>
//             )}
//           </div>
//
//           <div className="mb-8">
//             <h2 className="text-2xl font-bold mb-4 text-gray-900 border-b border-gray-300 pb-2">Investment Thesis</h2>
//             {loadingStates.investmentThesis ? (
//               <LoadingIndicator />
//             ) : (
//               <div className="border border-gray-300 p-6">
//                 {stockData.insights && stockData.insights.investment_thesis ? (
//                   <div className="text-gray-700 whitespace-pre-line">{stockData.insights.investment_thesis}</div>
//                 ) : (
//                   <div className="flex items-start mb-4">
//                     <Target className="mr-3 text-gray-700 mt-1" size={20} />
//                     <div>
//                       <h3 className="font-bold text-lg mb-2">Strong Market Position</h3>
//                       <p className="text-gray-700">
//                         {stockData.company_info.name} maintains its position as a leading company in the{" "}
//                         {stockData.company_info.industry} industry with robust performance and consistent growth.
//                       </p>
//                     </div>
//                   </div>
//                 )}
//
//                 {!stockData.insights || !stockData.insights.investment_thesis ? (
//                   <>
//                     <div className="flex items-start mb-4">
//                       <Landmark className="mr-3 text-gray-700 mt-1" size={20} />
//                       <div>
//                         <h3 className="font-bold text-lg mb-2">Financial Performance</h3>
//                         <p className="text-gray-700">
//                           The company continues to deliver industry-leading return ratios with a return on assets (ROA)
//                           of {stockData.key_metrics.return_on_assets.toFixed(2)}% and return on equity (ROE) of{" "}
//                           {stockData.key_metrics.return_on_equity.toFixed(2)}%.
//                         </p>
//                       </div>
//                     </div>
//                     <div className="flex items-start">
//                       <BarChart2 className="mr-3 text-gray-700 mt-1" size={20} />
//                       <div>
//                         <h3 className="font-bold text-lg mb-2">Growth Outlook</h3>
//                         <p className="text-gray-700">
//                           We expect {stockData.company_info.name} to continue its growth trajectory, driven by its
//                           strong market position and industry trends. The company's initiatives provide significant
//                           growth opportunities.
//                         </p>
//                       </div>
//                     </div>
//                   </>
//                 ) : null}
//               </div>
//             )}
//           </div>
//
//           {/* Risk Factors */}
//           <div className="mb-8">
//             <h2 className="text-2xl font-bold mb-4 text-gray-900 border-b border-gray-300 pb-2">Risk Factors</h2>
//             {loadingStates.riskFactors ? (
//               <LoadingIndicator />
//             ) : (
//               <div className="border border-gray-300 p-6">
//                 {renderRiskAnalysis() || (
//                   <ul className="list-disc list-inside text-gray-700 space-y-3">
//                     <li>
//                       <span className="font-semibold">Market Risk:</span> Exposure to market volatility and economic
//                       cycles.
//                     </li>
//                     <li>
//                       <span className="font-semibold">Regulatory Risk:</span> Changes in regulations affecting the{" "}
//                       {stockData.company_info.industry} industry.
//                     </li>
//                     <li>
//                       <span className="font-semibold">Competition:</span> Increasing competition from other players in
//                       the {stockData.company_info.sector} sector.
//                     </li>
//                     <li>
//                       <span className="font-semibold">Operational Risk:</span> Potential challenges in maintaining
//                       operational efficiency.
//                     </li>
//                     <li>
//                       <span className="font-semibold">Interest Rate Risk:</span> Impact of interest rate fluctuations on
//                       business performance.
//                     </li>
//                   </ul>
//                 )}
//               </div>
//             )}
//           </div>
//
//           {/* Analyst Consensus */}
//           <div className="mb-8">
//             <h2 className="text-2xl font-bold mb-4 text-gray-900 border-b border-gray-300 pb-2">Analyst Consensus</h2>
//             {loadingStates.analystConsensus ? (
//               <LoadingIndicator />
//             ) : (
//               <div className="border border-gray-300 p-6">
//                 <div className="flex items-center mb-4">
//                   <div className="w-full bg-gray-200 rounded-full h-2.5">
//                     <div
//                       className="bg-green-700 h-2.5 rounded-full"
//                       style={{ width: `${stockData.analyst_recommendations.buy}%` }}
//                     ></div>
//                   </div>
//                   <div className="ml-4 min-w-[100px] text-right">
//                     <span className="font-semibold">{stockData.analyst_recommendations.buy}%</span> Buy
//                   </div>
//                 </div>
//                 <div className="flex items-center mb-4">
//                   <div className="w-full bg-gray-200 rounded-full h-2.5">
//                     <div
//                       className="bg-yellow-600 h-2.5 rounded-full"
//                       style={{ width: `${stockData.analyst_recommendations.hold}%` }}
//                     ></div>
//                   </div>
//                   <div className="ml-4 min-w-[100px] text-right">
//                     <span className="font-semibold">{stockData.analyst_recommendations.hold}%</span> Hold
//                   </div>
//                 </div>
//                 <div className="flex items-center">
//                   <div className="w-full bg-gray-200 rounded-full h-2.5">
//                     <div
//                       className="bg-red-700 h-2.5 rounded-full"
//                       style={{ width: `${stockData.analyst_recommendations.sell}%` }}
//                     ></div>
//                   </div>
//                   <div className="ml-4 min-w-[100px] text-right">
//                     <span className="font-semibold">{stockData.analyst_recommendations.sell}%</span> Sell
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//
//           {/* Company Overview */}
//           <div className="mb-8">
//             <h2 className="text-2xl font-bold mb-4 text-gray-900 border-b border-gray-300 pb-2">Company Overview</h2>
//             {loadingStates.companyOverview ? (
//               <LoadingIndicator />
//             ) : (
//               <div className="border border-gray-300 p-6">
//                 <p className="text-gray-700 mb-4">
//                   {stockData.company_info.name} is a leading company in the {stockData.company_info.industry} industry,
//                   offering a comprehensive range of products and services in the {stockData.company_info.sector} sector.
//                 </p>
//                 <div className="grid grid-cols-2 gap-4 mt-4">
//                   <div>
//                     <p className="text-gray-600 text-sm">Founded</p>
//                     <p className="font-semibold">{stockData.company_info.founded}</p>
//                   </div>
//                   <div>
//                     <p className="text-gray-600 text-sm">Country</p>
//                     <p className="font-semibold">{stockData.company_info.country}</p>
//                   </div>
//                   <div>
//                     <p className="text-gray-600 text-sm">Employees</p>
//                     <p className="font-semibold">{stockData.company_info.employees.toLocaleString()}</p>
//                   </div>
//                   <div>
//                     <p className="text-gray-600 text-sm">Website</p>
//                     <p className="font-semibold text-blue-700">{stockData.company_info.website}</p>
//                   </div>
//                   <div>
//                     <p className="text-gray-600 text-sm">Industry</p>
//                     <p className="font-semibold">{stockData.company_info.industry}</p>
//                   </div>
//                   <div>
//                     <p className="text-gray-600 text-sm">Sector</p>
//                     <p className="font-semibold">{stockData.company_info.sector}</p>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//
//           {/* Footer */}
//           <div className="mt-8 pt-4 border-t border-gray-300 text-sm text-gray-500">
//             <div className="flex items-center justify-between mb-2">
//               <div className="flex items-center">
//                 <FileText size={14} className="mr-1" />
//                 <span>
//                   Report ID: {stockData.ticker}-{new Date().getFullYear()}-Q4-001
//                 </span>
//               </div>
//               <div className="flex items-center">
//                 <Clock size={14} className="mr-1" />
//                 <span>Report Date: {new Date().toLocaleDateString("en-IN")}</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   )
// }
//
