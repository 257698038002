import React, { useEffect, useState } from "react";
import { XIcon, ChevronDown, ChevronUp } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

import server from "../../utils/serverConfig";
import TelegramSteps from "./TelegramSteps";
import { CountryCode } from "../../utils/CountryCode";
import { useMediaQuery } from "../../hooks/use-media-query";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Drawer, DrawerContent } from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  inputBox:
    "w-full px-4 py-3 bg-white text-base peer text-gray-900 placeholder-transparent font-medium rounded-lg mt-3 ring-1 hover:ring-2 ring-gray-200 hover:ring-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 transition-all duration-200",
  labelFloat:
    "absolute px-1.5 top-0.5 left-3.5 text-gray-500 bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-6 peer-placeholder-shown:text-gray-400 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs",
  inputStartDiv: "relative w-full",
};

const calculateProfileCompletion = (
  email,
  name,
  phone,
  telegram = false,
  telegramId = ""
) => {
  let completedFields = 0;
  let totalFields = 3; // email, name, phone are required

  if (email) completedFields++;
  if (name) completedFields++;
  if (phone) completedFields++;
  if (telegram && telegramId) completedFields++;
  if (telegram) totalFields++;

  return Math.round((completedFields / totalFields) * 100);
};

const UserProfileModal = ({
  showUserProfileModal,
  setShowUserProfileModal,
  getUserDeatils,
  userDetails,
}) => {
  const showTelegram = process.env.REACT_APP_TELEGRAM === "1";
  const advisorName = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userTelegram, setUserTelegram] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const circumference = 2 * Math.PI * 35;

  useEffect(() => {
    if (userDetails) {
      setUserEmail(userDetails.email || "");
      setUserName(userDetails.name || "");
      setUserPhoneNumber(userDetails?.phone_number?.toString() || "");
      setCountryCode(userDetails?.country_code || "+91");
      if (showTelegram) {
        setUserTelegram(userDetails.telegram_id || "");
      }
    }
  }, [userDetails, showTelegram]);

  const handleUserProfile = async () => {
    if (!userPhoneNumber.trim()) {
      toast.error("Please enter a phone number.");
      return;
    }
    if (userPhoneNumber.length !== 9 && userPhoneNumber.length !== 10 && userPhoneNumber.length !== 11) {
      toast.error("Phone number must be between 9 and 11 numbers.");
      return;
    }

    setLoading(true);
    try {
      const phoneNumber = userPhoneNumber;
      const profileCompletion = calculateProfileCompletion(
        userEmail,
        userName,
        phoneNumber,
        showTelegram,
        showTelegram ? userTelegram : ""
      );

      const response = await axios.put(
        `${server.server.baseUrl}api/user/update-profile`,
        {
          email: userEmail,
          advisorName,
          phoneNumber,
          countryCode,
          telegramId: showTelegram ? userTelegram : "",
          userName,
          profileCompletion,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        getUserDeatils();
        toast.success(response.data.message);
        setShowSuccessMsg(true);
      } else {
        toast.error(response.data.message || "Failed to update profile.");
      }
    } catch (error) {
      console.error("Profile update error:", error);
      toast.error(error.response?.data?.message || "Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showSuccessMsg) {
      const timer = setTimeout(() => {
        setShowSuccessMsg(false);
        setShowUserProfileModal(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMsg, setShowUserProfileModal]);

  const initials =
    userDetails?.name
      ?.split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase() || userDetails?.email?.slice(0, 2).toUpperCase();


    useEffect(() => {
      if (selectedCountry) {
        setCountryCode(selectedCountry.value)
      }
    }, [selectedCountry])


  const filteredCountryCodes = CountryCode.filter((code) =>
    `${code.value} ${code.label}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const currentCompletion = calculateProfileCompletion(
    userEmail,
    userName,
    userPhoneNumber,
    showTelegram,
    showTelegram ? userTelegram : ""
  );

  const strokeDashoffset =
    circumference - (currentCompletion / 100) * circumference;

  const Content = (
    <div className="space-y-6">
      <div className="flex items-center space-x-4">
        <div className="relative">
          <svg className="transform -rotate-90 w-20 h-20">
            <circle
              cx="40"
              cy="40"
              r="35"
              stroke="currentColor"
              strokeWidth="4"
              fill="transparent"
              className="text-gray-100"
            />
            <circle
              cx="40"
              cy="40"
              r="35"
              stroke="currentColor"
              strokeWidth="4"
              fill="transparent"
              className="text-emerald-500"
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
            />
          </svg>
          <div className="absolute inset-0 flex items-center justify-center">
            {userDetails?.image_url ? (
              <img
                src={userDetails.image_url}
                alt="Profile"
                className="w-12 h-12 rounded-full"
              />
            ) : (
              <span className="text-xl font-semibold">{initials}</span>
            )}
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-900">
            {currentCompletion}% Complete
          </h3>
          <p className="text-sm text-gray-500">
            Complete your profile for better support
          </p>
        </div>
      </div>

      <div className="space-y-4">
        <div className={style.inputStartDiv}>
          <input
            id="userEmail"
            type="email"
            placeholder="Email"
            className={style.inputBox}
            value={userEmail}
            disabled
          />
          <label htmlFor="userEmail" className={style.labelFloat}>
            Email Address
          </label>
        </div>

        <div className={style.inputStartDiv}>
          <input
            id="userName"
            type="text"
            placeholder="Name"
            className={style.inputBox}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <label htmlFor="userName" className={style.labelFloat}>
            Full Name
          </label>
        </div>

        <div className="flex space-x-2">
          <div className="w-1/4 relative">
            <button
              onClick={() => setShowCountryCode(!showCountryCode)}
              className="w-full px-3 py-3 mt-3 text-gray-700 bg-white rounded-lg ring-1 ring-gray-200 hover:ring-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 transition-all duration-200"
            >
              <div className="flex items-center justify-between">
                <span>{countryCode}</span>
                {showCountryCode ? (
                  <ChevronUp size={16} />
                ) : (
                  <ChevronDown size={16} />
                )}
              </div>
            </button>
            {showCountryCode && (
              <div className="absolute z-10 w-48 mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-60 overflow-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  className="w-full px-4 py-2 border-b"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {filteredCountryCodes.map((code) => (
                  <button
                    key={code.value}
                    className="w-full px-4 py-2 text-left hover:bg-emerald-50"
                    onClick={() => {
                      setCountryCode(code.value);
                      setShowCountryCode(false);
                    }}
                  >
                    {code.value} {code.label}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="w-3/4">
            <div className={style.inputStartDiv}>
              <input
                id="phone"
                type="tel"
                placeholder="Phone"
                className={style.inputBox}
                value={userPhoneNumber}
                onChange={(e) => setUserPhoneNumber(e.target.value)}
              />
              <label htmlFor="phone" className={style.labelFloat}>
                Phone Number
              </label>
            </div>
          </div>
        </div>

        {showTelegram && (
          <div className={style.inputStartDiv}>
            <input
              id="telegram"
              type="text"
              placeholder="Telegram"
              className={style.inputBox}
              value={userTelegram}
              onChange={(e) => setUserTelegram(e.target.value)}
            />
            <label htmlFor="telegram" className={style.labelFloat}>
              Telegram Username (Optional)
            </label>
          </div>
        )}
      </div>

      <button
        onClick={handleUserProfile}
        disabled={loading || !userPhoneNumber}
        className="w-full mt-6 px-6 py-3 text-white bg-emerald-600 rounded-lg font-medium hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
      >
        {loading ? <LoadingSpinner /> : "Save Profile"}
      </button>
    </div>
  );

  if (isDesktop) {
    return (
      <Dialog
        open={showUserProfileModal}
        onOpenChange={setShowUserProfileModal}
      >
        <DialogContent className="sm:max-w-[600px]">
          <div className="grid grid-cols-1 gap-6 p-6">
            {showTelegram && <TelegramSteps />}
            {Content}
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={showUserProfileModal} onOpenChange={setShowUserProfileModal}>
      <DrawerContent>
        <div className="px-4 py-6">
          {showTelegram && <TelegramSteps />}
          {Content}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default UserProfileModal;
