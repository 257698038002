import React from "react";
import { MinusCircle as CircleMinus, ChevronUp, ChevronDown } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Input } from "antd";
import { Card } from "../../components/ui/card";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

const NewPushStockRebalanceCard = ({
  handleRemoveAdviceEntry,
  handleInputChange,
  handleSymbolSelect,
  handleAdviceChange,
  handleIncrement,
  handleDecrement,
  entry,
  index,
  minimumPortfolioAmount,
}) => {
  return (
    <div className="grid grid-cols-8 lg:grid-cols-10 gap-2 items-center bg-white rounded-lg border border-gray-200 p-3">
      {/* Remove Button */}
      <div className="col-span-1 flex justify-center">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => handleRemoveAdviceEntry(index)}
          className="h-8 w-8 text-red-500 hover:text-red-600 hover:bg-red-50"
        >
          <CircleMinus className="h-4 w-4" />
        </Button>
      </div>

      {/* Stock Symbol Input */}
      <div className="col-span-3 lg:col-span-4 relative">
        <Input
          type="text"
          placeholder="Enter symbol"
          value={entry.symbol || entry.inputValue}
          onChange={(e) => handleInputChange(index, e.target.value)}
          className="w-full bg-gray-50 border-gray-200 focus:border-blue-500 focus:ring-blue-500"
        />

        {/* Symbol Dropdown */}
        {entry?.symbols?.length > 0 && (
          <Card className="absolute left-0 right-0 top-full mt-1 z-50 max-h-48 overflow-y-auto">
            <div className="p-1">
              {entry.symbols.map((ele, idx) => (
                <button
                  key={idx}
                  onClick={() => handleSymbolSelect(index, ele.symbol, ele.segment)}
                  className="w-full px-3 py-2 text-sm text-left hover:bg-gray-50 rounded-md flex justify-between items-center"
                >
                  <span className="font-medium">{ele.symbol}</span>
                  <span className="text-gray-500 text-xs">{ele.segment}</span>
                </button>
              ))}
            </div>
          </Card>
        )}
      </div>

      {/* Allocation Input */}
      <div className="col-span-2 flex items-center space-x-1">
        <div className="flex-1 flex items-center bg-gray-50 rounded-lg">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => handleDecrement(index)}
            className="h-8 w-8 rounded-l-lg"
          >
            <ChevronDown className="h-4 w-4" />
          </Button>

          <Input
            type="number"
            className="flex-1 text-center border-0 bg-transparent h-8 px-1 focus:ring-0"
            value={
              entry?.value > 0
                ? entry?.isNewAdded
                  ? entry?.value
                  : Math.round(entry?.value * 100)
                : 0
            }
            onChange={(e) =>
              handleAdviceChange(index, "value", parseInt(e.target.value))
            }
          />

          <Button
            variant="ghost"
            size="icon"
            onClick={() => handleIncrement(index)}
            className="h-8 w-8 rounded-r-lg"
          >
            <ChevronUp className="h-4 w-4" />
          </Button>
        </div>
      </div>

      {/* Investment Value */}
      <div className="col-span-2 lg:col-span-3 flex items-center justify-end px-4">
        <span className="text-sm font-medium">
          ₹
          {entry?.symbol && minimumPortfolioAmount?.min_investment_value[entry.symbol]
            ? parseFloat(
                minimumPortfolioAmount?.min_investment_value[entry.symbol]
              ).toFixed(2)
            : "0.00"}
        </span>
      </div>
    </div>
  );
};

export default NewPushStockRebalanceCard;