import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { TableRow } from "./TableRow";
import { TableHeader } from "./TableHeader";
import SendNotificationModal from "./SendNotificationModal";
import server from "../../utils/serverConfig";

const AdminAdviceDetails = ({ advisorSpecifier, adminEmail }) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const [notificationModal, setNotificationModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState([]);

  const getAllHistoryData = async () => {
    try {
      const response = await axios.post(
        `https://ccxt.alphaquark.in/comms/send-reco-history`,
        {
          advisorName: advisorTag,
        }
      );

      console.log("res", response);
      const mappedData = Object.entries(response?.data?.metrics)
        .filter(([adviseId, metrics]) => {
          // Check if metrics array exists and has at least one element
          return (
            metrics && metrics.length > 0 && response?.data?.symbols[adviseId]
          );
        })
        .map(([adviseId, metrics]) => {
          const metric = metrics[0];
          const symbolData = response?.data?.symbols[adviseId];

          // Handle cases where there might be multiple symbols
          const symbols = Array.isArray(symbolData) ? symbolData : [symbolData];

          // Map each symbol to a separate entry
          return symbols.map((symbol) => {
            // Create a formatted symbol object with the new structure
            const formattedSymbol = {
              advisedRangeHigher: symbol.Advised_Range_Higher,
              advisedRangeLower: symbol.Advised_Range_Lower,
              exchange: symbol.Exchange,
              orderType: symbol.OrderType,
              price: symbol.Price,
              quantity: symbol.Quantity,
              segment: symbol.Segment,
              productType: symbol.ProductType,
              symbol: symbol.Symbol,
              type: symbol.Type,
              rationale: symbol.rationale,
              trade_given_by: symbol.trade_given_by,
              adviceRecoId: symbol.advice_reco_id,
              price_when_send_advice: symbol.price_when_send_advice,
              comments: symbol?.comments ? symbol?.comments : "",
              comment2: symbol?.comment2 ? symbol?.comment2 : "",
            };

            // Calculate progress for each metric
            const whatsappProgress =
              (metric.sent_whatsapp_count / metric.reco_save_count) * 100;
            const emailProgress =
              (metric.total_sent_mails / metric.reco_save_count) * 100;
            const platformProgress =
              (metric.reco_save_count / metric.reco_save_count) * 100;

            // Determine if all advice is sent
            const isComplete =
              metric.unsent_whatsapp_count === 0 &&
              metric.unsupported_email_count === 0;

            return {
              date: new Date(metric.created_datetime),
              Symbol: formattedSymbol.symbol,
              action: isComplete,
              progress: whatsappProgress,
              emailProgress: emailProgress,
              platformProgress: platformProgress,
              unsentEmails: metric.unsent_whatsapp_emails || [],
              adviseId: adviseId,
              metricDetails: metric,
              symbolDetails: formattedSymbol,
            };
          });
        })
        .flat(); // Flatten the array since we might have multiple symbols per adviseId

      setTableData(mappedData);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    getAllHistoryData();
  }, []);

  const [loadingAdvice, setLoadingAdvice] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingAdvice(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [loading, setLoading] = useState(false);
  const [loadingAdviceId, setLoadingAdviceId] = useState(null);
  const handleResendAdvice = (unsentEmails, symbolDetails, metricDetails) => {
    console.log("unsentEmails", unsentEmails);
    console.log("symbolDetails", symbolDetails);
    console.log("metricDetails", metricDetails);
    setLoading(true);
    setLoadingAdviceId(metricDetails?.advise_id);
    // Create advice data directly from unsent emails since we have the email list
    const adviceData = unsentEmails.map((email) => ({
      email: email,
      userName: metricDetails?.name ? metricDetails?.name : "",
      phoneNumber: metricDetails?.phone,
      country_code: metricDetails?.country_code,
      advisor_name: metricDetails?.advisor_name,
      Symbol: symbolDetails.symbol,
      Exchange: symbolDetails.exchange,
      Type: symbolDetails.type,
      OrderType: symbolDetails.orderType,
      ProductType: symbolDetails.productType,
      Segment: symbolDetails.segment,
      Price: symbolDetails.orderType === "MARKET" ? 0 : symbolDetails.price,
      date: metricDetails.created_datetime,
      Quantity: symbolDetails.quantity,
      Advised_Range_Lower: symbolDetails.advisedRangeLower,
      Advised_Range_Higher: symbolDetails.advisedRangeHigher,
      rationale: symbolDetails?.rationale,
      comments: symbolDetails?.comments,
      comment2: symbolDetails?.comment2,
      trade_given_by: symbolDetails?.trade_given_by,
      advisorType: advisorSpecifier,
      advise_id: symbolDetails?.adviceRecoId,
    }));

    console.log("adviceDara", adviceData);
    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}comms/send-reco`,
      headers: {
        "Content-Type": "application/json",
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        // if (response.status === 0) {
        setLoading(false);
        setLoadingAdviceId(null);
        toast.success("Advice has been resent successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        getAllHistoryData();
        setNotificationModal(false);
        // }
      })
      .catch((error) => {
        console.log(error);
        setLoadingAdviceId(null);
        toast.error("Error in resending Advice!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  // Add these new helper functions
  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return tableData.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages + 4) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1); // Always show first page

      if (currentPage <= 3) {
        // Near the start
        for (let i = 2; i <= 5; i++) pages.push(i);
        pages.push("...");
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Near the end
        pages.push("...");
        for (let i = totalPages - 4; i <= totalPages; i++) pages.push(i);
      } else {
        // Middle
        pages.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) pages.push(i);
        pages.push("...");
        pages.push(totalPages);
      }
    }
    return pages;
  };

  return (
    <div className="min-h-screen w-full items-center justify-center bg-[#F9F9F9]">
      <div className="px-4 sm:px-8 py-3 md:py-3 justify-between items-center">
        <div className="flex items-center text-[18px] text-balck md:text-[22px] md:leading-[30px] font-sans font-bold">
          Advice Sent Status
        </div>
        <div className="flex items-center text-sm text-[#000000]/40">
          Get instant insight into your investment portfolio overview.
        </div>
      </div>
      <div className="mt-8 px-8 overflow-x-auto rounded-lg shadow-sm">
        {loadingAdvice ? (
          <span className="h-full md:p-12 p-4 text-2xl font-semibold pointer-events-auto w-full flex justify-center items-center">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        ) : tableData?.length !== 0 ? (
          <div className="bg-white px-8 overflow-y-auto rounded-lg shadow-sm">
            <table className="min-w-full divide-y divide-gray-200">
              <TableHeader />
              <tbody className="bg-white divide-y divide-gray-200">
                {getPaginatedData()?.map((item, i) => (
                  <TableRow
                    key={i}
                    item={item}
                    setNotificationModal={setNotificationModal}
                    setSelectedItem={setSelectedItem}
                    handleResendAdvice={handleResendAdvice}
                    loading={loading}
                    loadingAdviceId={loadingAdviceId}
                  />
                ))}
              </tbody>
              {totalPages > 1 && (
                <div className="flex items-center justify-center py-4 space-x-2">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className={`px-3 py-1 rounded ${
                      currentPage === 1
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-gray-700 hover:bg-gray-50"
                    }`}
                  >
                    Previous
                  </button>

                  {getPageNumbers().map((pageNum, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        if (typeof pageNum === "number") {
                          setCurrentPage(pageNum);
                        }
                      }}
                      className={`px-3 py-1 rounded ${
                        pageNum === currentPage
                          ? "bg-blue-500 text-white"
                          : pageNum === "..."
                          ? "cursor-default"
                          : "bg-white text-gray-700 hover:bg-gray-50"
                      }`}
                    >
                      {pageNum}
                    </button>
                  ))}

                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1 rounded ${
                      currentPage === totalPages
                        ? "bg-gray-100 text-gray-400"
                        : "bg-white text-gray-700 hover:bg-gray-50"
                    }`}
                  >
                    Next
                  </button>
                </div>
              )}
            </table>
          </div>
        ) : (
          <div className="h-full text-2xl font-semibold  w-full flex flex-col justify-center items-center">
            <div className="text-xl lg:text-3xl font-semibold font-sans flex items-center justify-center">
              No Recommended Stocks
            </div>

            <p className="text-[14px] lg:text-lg font-normal text-[#000000]/60 font-sans flex items-center justify-center">
              You haven't recommended any stocks yet. Explore and send stock
              recommendations to populate this list.
            </p>
          </div>
        )}
      </div>
      {notificationModal && (
        <SendNotificationModal
          setNotificationModal={setNotificationModal}
          selectedItem={selectedItem}
          handleResendAdvice={handleResendAdvice}
          loading={loading}
        />
      )}
    </div>
  );
};

export default AdminAdviceDetails;
