import React from 'react';
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const apiKey = "test_api_key";
const apiSecret = "test_api_secret";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

const ProgressCircle = ({ 
  percentage = 0,
  size = 'md',
  activeColor = '#0EA5E9', // Sky blue for a professional look
  inactiveColor = '#E2E8F0', // Subtle gray
  thickness = 2,
}) => {
  // Size variants
  const sizeClasses = {
    sm: 'w-3 h-3',
    md: 'w-4 h-4',
    lg: 'w-6 h-6',
    xl: 'w-8 h-8'
  };

  const rotationDegree = (Math.min(100, Math.max(0, percentage)) / 100) * 360;

  return (
    <div className={`relative flex items-center justify-center ${sizeClasses[size]}`}>
      {/* Background circle */}
      <div 
        className="absolute inset-0 rounded-full"
        style={{ 
          background: inactiveColor,
          opacity: 0.2 
        }} 
      />
      
      {/* Progress circle */}
      <div
        className="absolute inset-0 rounded-full"
        style={{
          border: `${thickness}px solid transparent`,
          background: `conic-gradient(
            ${activeColor} ${rotationDegree}deg,
            ${inactiveColor} ${rotationDegree}deg 360deg
          )`,
          WebkitMask: `radial-gradient(farthest-side, transparent calc(100% - ${thickness}px), black 0)`,
          mask: `radial-gradient(farthest-side, transparent calc(100% - ${thickness}px), black 0)`,
        }}
      />
    </div>
  );
};

export default ProgressCircle;