import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { XIcon, AlertCircle } from "lucide-react";
import axios from "axios";

import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";
import server from "../../utils/serverConfig";

const style = {
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200",
  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  inputStartDiv: "relative w-full flex flex-row items-center ",
};

export function ModifyInvestmentModal({
  modifyInvestmentModal,
  setModifyInvestmentModal,
  strategyDetails,
  userEmail,
  getStrategyDetails,
  amount,
  latestRebalance,
  userBroker
}) {
  const isDesktop = useMediaQuery("(min-width: 830px)");

  const [portfolioAmount, setPortfolioAmount] = useState("");
  const [subscriptionAmountLoading, setSubscriptionAmountLoading] =
    useState(false);

  useEffect(() => {
    setPortfolioAmount(amount);
    console.log("User Broker:", strategyDetails);
  }, [amount,]);

  const modifyInvestmentModalPortfolio = () => {
    setSubscriptionAmountLoading(true);
    let data2 = JSON.stringify({
      userEmail: userEmail,
      model: strategyDetails?.model_name,
      advisor: strategyDetails?.advisor,
      model_id: latestRebalance.model_Id,
      userBroker: userBroker,
      subscriptionAmountRaw: [
        {
          amount: portfolioAmount,
          dateTime: new Date(),
        },
      ],
    });

    let config2 = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data2,
    };

    axios
      .request(config2)
      .then((response) => {
        getStrategyDetails();
        setSubscriptionAmountLoading(false);
        setModifyInvestmentModal(false);
        toast.success("You have been successfully updated invested amount.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
            fontFamily: "Poppins"
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setSubscriptionAmountLoading(false);
        setModifyInvestmentModal(false);
      });
  };


  const isConfirmDisabled = () => {
    const inputAmount = Number(portfolioAmount);
    return (
      inputAmount < strategyDetails.minInvestment ||
      inputAmount > strategyDetails.maxNetWorth ||
      !portfolioAmount
    );
  };

  useEffect(() => {
    if (modifyInvestmentModal) {
      setPortfolioAmount("");
    }
  }, [modifyInvestmentModal]);



  if (isDesktop) {
    return (
      <Dialog
        open={modifyInvestmentModal}
        onOpenChange={setModifyInvestmentModal}
      >
        <DialogContent className=" max-w-[520px] px-[40px] animate-modal">
          <div className="flex flex-col  items-center py-4">
            <div className="text-[22px] font-medium font-poppins">
              Modify your investment Allocation
            </div>
            <div className="text-base text-[#000000]/50 font-normal font-poppins mt-2 ">
              You currently have <strong>₹{amount}</strong> invested in {" "}
              <strong>{strategyDetails?.model_name}</strong> Model Portfolio. Use the field below
              to increase or reduce your allocation.

              {portfolioAmount && isConfirmDisabled() && (
                <div className="flex  space-x-2">
                  <AlertCircle className="w-4 h-4 text-red-500 mt-2.5" />
                  <p className="text-sm font-poppins text-red-500 mt-2">
                    The min. investment is ₹{strategyDetails.minInvestment} and the max. investment is ₹{strategyDetails.maxNetWorth}.
                  </p>
                </div>

              )}
            </div>
            {!portfolioAmount && (
              <div className="flex space-x-2">
                <AlertCircle className="w-4 h-4 text-black mt-2.5" />
                <p className="text-sm font-poppins text-black mt-2">
                  The min. investment is ₹{strategyDetails.minInvestment} and the max. investment is ₹{strategyDetails.maxNetWorth}.
                </p>
              </div>
            )}
            <div className={`mt-3 ${style.inputStartDiv}`}>
              <div className="absolute mt-3 text-base text-gray-900 font-poppins  items-center  px-2">
                ₹
              </div>
              <input
                id="clientCode"
                placeholder="Enter amount"
                type="text"
                value={portfolioAmount}
                className={`${style.inputBox}`}
                onChange={(e) => {
                  const value = e.target.value;


                  if (value === '' || /^[0-9]+$/.test(value)) {
                    setPortfolioAmount(value);
                  }
                }}
              />
            </div>
          </div>
          <DialogFooter className="flex">
            <div
              onClick={() => setModifyInvestmentModal(false)}
              className="w-full bg-[#ffffff] flex items-center justify-center  text-black text-[18px] font-medium font-poppins border-2 border-[#000000]/20 rounded-md py-3 cursor-pointer  transition-all duration-150 ease-linear"
            >
              Cancel
            </div>

            <button
              onClick={modifyInvestmentModalPortfolio}
              className={`w-full flex items-center justify-center bg-black text-white text-[18px] font-medium font-poppins rounded-md py-3 transition-all duration-150 ease-linear ${isConfirmDisabled() ? 'opacity-50 cursor-not-allowed' : 'hover:scale-[1.04]'}`}
              disabled={isConfirmDisabled()}
            >
              {subscriptionAmountLoading === true ? (
                <LoadingSpinner />
              ) : (
                "Confirm Allocation"
              )}
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer
      open={modifyInvestmentModal}
      onOpenChange={setModifyInvestmentModal}
    >
      <DrawerContent className="focus:outline-none max-h-[500px] md:min-h-[300px]">
        <div
          onClick={() => setModifyInvestmentModal(false)}
          className="absolute top-4 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>

        <div className="p-4 h-full overflow-y-auto">
          <div className="flex flex-col  items-center mt-4">
            <div className="text-[20px] font-medium font-poppins text-center">
              Modify your investment Allocation
            </div>
            <div className="text-sm font-light font-poppins mt-2 ">
              You currently have <strong>₹{amount}</strong> invested in {" "}
              <strong>{strategyDetails?.model_name}</strong> Model Portfolio. Use the field below
              to increase or reduce your allocation.

              {portfolioAmount && isConfirmDisabled() && (
                <div className="flex  space-x-2">
                  <AlertCircle className="w-5 h-5 text-red-500 mt-2.5" />
                  <p className="text-sm font-poppins text-red-500 mt-2">
                    The min. investment is ₹{strategyDetails.minInvestment} and the max. investment is ₹{strategyDetails.maxNetWorth}.
                  </p>
                </div>

              )}
            </div>
            {!portfolioAmount && (
              <div className="flex space-x-2">
                <AlertCircle className="w-5 h-5 text-black mt-2.5" />
                <p className="text-sm font-poppins text-black mt-2">
                  The min. investment is ₹{strategyDetails.minInvestment} and the max. investment is ₹{strategyDetails.maxNetWorth}.
                </p>
              </div>
            )}
            <div className={`mt-3 ${style.inputStartDiv}`}>
              <div className="absolute mt-3 text-base text-gray-900 font-poppins  items-center  px-2">
                ₹
              </div>
              <input
                id="clientCode"
                placeholder="Enter amount"
                type="text"
                value={portfolioAmount}
                className={`${style.inputBox}`}
                onChange={(e) => {
                  const value = e.target.value;


                  if (value === '' || /^[0-9]+$/.test(value)) {
                    setPortfolioAmount(value);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <DrawerFooter className="pt-2 flex flex-col  w-full">
          <button
            onClick={modifyInvestmentModalPortfolio}
            className={`w-full flex items-center justify-center bg-black text-white text-[18px] font-medium font-poppins rounded-md py-3 transition-all duration-150 ease-linear ${isConfirmDisabled() ? 'opacity-50 cursor-not-allowed' : 'hover:scale-[1.04]'}`}
            disabled={isConfirmDisabled()}
          >
            {subscriptionAmountLoading === true ? (
              <LoadingSpinner />
            ) : (
              "Confirm Allocation"
            )}
          </button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
