import axios from "axios";
import server from "../../utils/serverConfig";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

export const getAdminDetails = async (adminEmail) => {
  try {
    const response = await axios.get(
      `${server.server.baseUrl}api/admin/${adminEmail}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};
