import React from 'react';
import { CircleMinusIcon } from 'lucide-react';
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox: "w-full py-2 px-2 bg-white text-gray-900 placeholder:text-gray-400 placeholder:font-normal font-medium rounded-md mt-1 ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9]",
  selectDiv: "flex flex-row justify-between items-center mx-2 px-2 py-2 rounded-lg md-3 text-gray-900 hover:bg-[#D9D9D9] hover:text-black transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full",
};

const StockRebalanceCard = ({
  handleRemoveAdviceEntry,
  handleInputChange,
  handleSymbolSelect,
  handleAdviceChange,
  handleIncrement,
  handleDecrement,
  entry,
  index,
  minimumPortfolioAmount,
}) => {
  return (
    <div className="w-full grid grid-cols-8 lg:grid-cols-10 min-h-[60px] bg-white shadow-sm hover:shadow-md transition-shadow duration-200 border border-gray-200 rounded-lg lg:px-2 py-3">
      <div className="flex items-center justify-center col-span-1">
        <div className="p-1.5 hover:bg-red-50 rounded-full transition-colors duration-200">
          <CircleMinusIcon
            className="w-4 h-4 lg:w-6 lg:h-6 text-red-500 hover:text-red-600 cursor-pointer transition-colors duration-200"
            onClick={() => handleRemoveAdviceEntry(index)}
          />
        </div>
      </div>

      <div className="w-full relative flex items-center bg-gray-50 hover:bg-gray-100 transition-colors duration-200 rounded-lg text-[12px] lg:text-[15px] text-gray-800 font-poppins font-medium col-span-3 lg:col-span-5 px-4 lg:mr-4">
        <input
          type="text"
          placeholder="Enter Symbol"
          value={entry.inputValue}
          onChange={(e) => handleInputChange(index, e.target.value)}
          autoComplete="off"
          className="bg-transparent w-full py-2 focus:outline-none placeholder:text-gray-400"
        />
        {entry?.symbols?.length > 0 && (
          <div className="w-full absolute left-0 right-0 top-10 mt-1 bg-white flex flex-col rounded-lg py-2 z-10 shadow-lg border border-gray-200 max-h-48 overflow-y-auto">
            {entry?.symbols?.map((ele, idx) => (
              <div
                key={idx}
                onClick={() => handleSymbolSelect(index, ele.symbol, ele.segment)}
                className="flex justify-between items-center mx-2 px-3 py-2 rounded-md hover:bg-gray-50 cursor-pointer"
              >
                <span className="font-medium">{ele.symbol}</span>
                <span className="text-gray-600 text-sm">{ele.segment}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="flex items-center justify-center bg-gray-50 hover:bg-gray-100 transition-colors duration-200 rounded-lg text-[15px] text-gray-800 font-poppins font-medium col-span-2 lg:col-span-2 mx-2">
        <div className="flex flex-row items-center justify-between space-x-1 text-[12px] text-gray-700 font-poppins font-semibold px-1 lg:px-2">
          <button
            className="w-6 h-6 flex items-center justify-center bg-white hover:bg-gray-800 text-gray-800 hover:text-white rounded transition-colors duration-200 shadow-sm"
            onClick={() => handleDecrement(index)}
          >
            -
          </button>

          <input
            type="number"
            className="flex-1 bg-transparent w-full lg:w-[45px] h-[20px] font-poppins text-[12px] lg:text-[15px] text-center rounded focus:outline-none"
            value={entry?.value > 0 ? entry?.value : 0}
            onChange={(e) => handleAdviceChange(index, "value", parseInt(e.target.value))}
            placeholder="Value"
          />

          <button
            className="w-6 h-6 flex items-center justify-center bg-white hover:bg-gray-800 text-gray-800 hover:text-white rounded transition-colors duration-200 shadow-sm"
            onClick={() => handleIncrement(index)}
          >
            +
          </button>
        </div>
      </div>

      <div className="flex items-center justify-center bg-gray-50 hover:bg-gray-100 transition-colors duration-200 rounded-lg text-[12px] lg:text-[15px] text-gray-800 font-poppins font-medium col-span-2 lg:col-span-2 mx-2">
        <span className="text-gray-900">₹</span>
        <span className="ml-1">
          {entry?.symbol && minimumPortfolioAmount?.min_investment_value[entry.symbol]
            ? parseFloat(minimumPortfolioAmount?.min_investment_value[entry.symbol]).toFixed(2)
            : '0.00'}
        </span>
      </div>
    </div>
  );
};

export default StockRebalanceCard;