import React from "react";
import { useNavigate } from "react-router-dom";
import { Info } from "lucide-react";

import Alpha100 from "../../assests/alpha-100.png";
import Meter from "../../assests/Icon/meter.svg";
import server from "../../utils/serverConfig";
import formatCurrency from "../../utils/formatCurrency";

const PlanCard = ({ fileName, overView, ele, onEdit }) => {
  const navigate = useNavigate();

  const handleViewStrategy = () => {
    navigate(`/admin/admin-plan/${ele._id}`);
  };

  return (
    <div
      className="relative font-poppins w-full rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-2 cursor-pointer"
      onClick={handleViewStrategy}
    >
      <div className="flex items-start min-h-[93px] gap-x-3 p-4">
        <div className="overflow-hidden rounded-[4px] flex-shrink-0">
          <img
            src={ele?.image ? `${server.server.baseUrl}${ele.image}` : Alpha100}
            alt={ele?.name}
            className="object-cover w-[49.18px] h-[48px]"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-[#000000]">{ele?.name}</h2>
          <p className="mt-1 text-[10px] md:text-[10px] text-[#95989C] font-medium font-poppins text-left">
            {ele?.description.split(" ").slice(0, 10).join(" ")}
          </p>
        </div>
      </div>

      <div className="flex w-full mt-[6px] border-t-[1px] border-b-[1px] border-[#000000]/10">
        <div className="flex flex-col justify-center items-center w-2/4 py-1.5  relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10"></div>
          <div>
            <div className="text-xs text-left text-[#000000]/70 font-normal font-poppins capitalize">
              CAGR
            </div>
            <p className="text-[13px]  lg:text-[16px] text-[#16A085] font-poppins font-bold">
              +75.2%
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center  w-2/4 py-1.5 relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10"></div>{" "}
          <div>
            <div className="text-xs  text-left text-[#000000]/70 font-normal font-poppins">
              2Y Returns
            </div>
            <p className="text-[13px]   lg:text-[16px] text-[#16A085] font-poppins font-bold">
              +775.2%
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center space-y-1 w-2/4   py-1.5 lg:px-1 rounded-md">
          <div>
            <div className="text-xs   text-left text-[#000000]/70 font-normal font-poppins">
              Min. investment
            </div>
            <p className="text-[13px]   lg:text-[16px] text-[#000000] font-poppins font-medium">
              ₹{formatCurrency(ele.minInvestment)}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start justify-between px-4 mt-4">
        <div className="w-full group relative flex items-start justify-between text-base text-[#000000]/80 font-poppins font-semibold">
          <div className="flex items-center cursor-pointer">
            <Info size={12} />
            <p className="text-[12px] lg:text-[12px] px-2 lg:px-2 py-0 font-medium">
              Monthly Fees: ₹250
            </p>
          </div>

          <div className="flex items-center  py-[1px]  rounded-md">
            <img src={Meter} size={16} alt="Meter" />
            <span className="text-[12px] lg:text-[12px] px-2 py-0 font-medium">
              Subject to market risk
            </span>
          </div>
        </div>
      </div>
      <div className="p-3 px-4">
        <div className="flex space-x-1 sm:space-x-2 md:space-x-1.5 xxl:space-x-2 w-full">
          <button className="w-full py-2.5 px-1 font-poppins font-medium  rounded-md bg-white border-[#000000]/20 border-[2px] text-black text-[13px]  md:text-[13px] lg:text-[13px] xxl:text-[15px] ">
            {/* Performance */}
            View Details
          </button>

          <button className="w-full flex items-center justify-center py-2 px-1 rounded-md bg-black text-white text-xs sm:text-[15px] lg:text-xs xxl:text-[15px] font-poppins font-medium capitalize">
            {ele?.type}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
