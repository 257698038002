import React, { useEffect, useState } from "react";
import { XIcon, ChevronRight, ChevronDown, ChevronUp } from "lucide-react"; // Updated this line
import Checked from "../assests/checked.svg";
import { useMediaQuery } from "../hooks/use-media-query";
import { Dialog, DialogContent } from "../components/ui/dialog";
import { Drawer, DrawerContent } from "../components/ui/drawer";
import LoadingSpinner from "../components/LoadingSpinner";
import { CountryCode } from "../../src/utils/CountryCode";

const style = {
  selectDiv2:
    "flex items-center px-2 py-2 rounded-lg mx-3 md-3 text-black-500 hover:bg-black/10 hover:text-black-500 ease-linear duration-150 cursor-pointer",
};

const PlanSubscribeModal = ({
  userEmail,
  strategyDetails,
  paymentModal,
  setPaymentModal,
  handleCardClick,
  selectedCard,
  handleOk,
  loading,
  setInvestAmount,
  invetAmount,
  setEmail,
  name,
  setName,
  panNumber,
  setPanNumber,
  mobileNumber,
  setMobileNumber,
  setCountryCode = () => {}, // Add this prop
  countryCode = "+91", // Add this prop
}) => {
  const isDesktop = useMediaQuery("(min-width: 830px)");

  const [isMobileNumberComplete, setIsMobileNumberComplete] = useState(false);
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [mobileNumberError, setMobileNumberError] = useState("")

  // Add this useEffect
  useEffect(() => {
    const defaultCountry = CountryCode.find(
      (country) => country.value === "+91"
    );
    if (defaultCountry && !countryCode) {
      setSelectedCountry(defaultCountry);
      setCountryCode(defaultCountry.value);
    }
  }, []);

  const handleMobileNumberChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    if (value.length === 12) {
      value = value.slice(2);
    }
    value = value.slice(0, 10);
    setMobileNumber(value);
    setIsMobileNumberComplete(value.length === 10);

    if (value.length > 0 && value.length !== 10) {
      setMobileNumberError("Mobile number should be 10 digits")
    } else {
      setMobileNumberError("")
    }
  };

  const filteredCountryCodes = CountryCode.filter((country) =>
    `${country.value} ${country.label}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setCountryCode(country.value);
    setShowCountryCode(false);
  };
  const handleMobileNumberBlur = () => {
    if (mobileNumber) {
      setIsMobileNumberComplete(mobileNumber.length === 10);
    } else {
      setIsMobileNumberComplete(false);
    }
  };
  const renderContent = () => (
    <div className="flex flex-col space-y-4  py-6 w-full min-h-[420px] bg-white">
      <div className="capitalize flex w-full   pb-4 text-[22px] text-[#000000] font-sans font-bold px-6 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]">
        <div className="text-black leading-[40px]">
          Invest in {strategyDetails?.name}
        </div>
      </div>

      <div className="px-8 pb-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-sm text-gray-700 font-semibold font-poppins">
              Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your Name"
              className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-normal text-gray-700">
              <div className="flex items-center font-semibold font-poppins">
                Email<span className="text-red-500">*</span>
              </div>
            </label>
            <input
              type="email"
              value={userEmail}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your Email"
              className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-normal text-gray-700">
              <div className="flex items-center font-semibold font-poppins">
                <span className="mr-2">
                  Phone Number<span className="text-red-500">*</span>
                </span>
                <span className="text-xs font-normal text-gray-500">
                  (WhatsApp enabled)
                </span>
              </div>
            </label>

            <div className="relative flex flex-row items-center">
              <div
                className={`h-10 mr-2 w-[20%] text-[#000000]/80 flex flex-row items-center justify-center relative cursor-pointer ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] rounded-[6px] py-2.5`}
                onClick={() => setShowCountryCode(!showCountryCode)}
              >
                <div className="text-[#000000]/80">
                  {selectedCountry ? selectedCountry.value : "+91"}
                </div>
                <div
                  className={`pl-1 ease-linear duration-150 text-[#000000]/25`}
                >
                  {showCountryCode ? (
                    <ChevronUp size={20} />
                  ) : (
                    <ChevronDown size={20} />
                  )}
                </div>
              </div>

              {showCountryCode && (
                <div className="absolute right-0 top-14 bg-white flex flex-col rounded-lg w-[100%] pt-2 pb-2 z-10 box-border border-2 border-solid border-[rgba(185, 192, 187, 0.7)] max-h-72 overflow-y-auto">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="mx-3 px-3 py-2 mb-2 border rounded-md"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {filteredCountryCodes.length > 0 ? (
                    filteredCountryCodes.map((country, index) => (
                      <div
                        key={index}
                        className={`pl-3 font-medium ${style.selectDiv2}`}
                        onClick={() => handleCountrySelect(country)}
                      >
                        {country.value} {country.label}
                      </div>
                    ))
                  ) : (
                    <div className="pl-3 font-medium text-gray-500">
                      No results found
                    </div>
                  )}
                </div>
              )}

              <input
                type="number"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                onFocus={() => setIsMobileNumberComplete(false)}
                onBlur={handleMobileNumberBlur}
                // onBlur={() => setIsMobileNumberComplete(mobileNumber.length === 10)}
                placeholder="Enter your Mobile Number"
                maxLength={10}
                className={`w-full h-10 rounded-md border ${
                  isMobileNumberComplete
                    ? "border-green-500"
                    : "border-gray-300"
                } text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal`}
              />
            </div>
            {mobileNumberError && (
              <p className="text-red-500 text-xs mt-1 font-poppins">{mobileNumberError}</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="block text-sm  text-gray-700 font-semibold font-poppins">
              PAN<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={panNumber}
              onChange={(e) => setPanNumber(e.target.value)}
              placeholder="Enter your PAN"
              className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
            />
          </div>
          {strategyDetails?.type === "model portfolio" ? (
            <div className="space-y-2 ">
              <label className="block text-sm  text-gray-700 font-semibold font-poppins">
                Invest Amount (₹)<span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                value={invetAmount}
                onChange={(e) => setInvestAmount(e.target.value)}
                placeholder={`Amount must be ₹${strategyDetails?.minInvestment} or more`}
                className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="px-6">
        <div className="space-y-2">
          <label className="block text-sm  text-gray-700 font-semibold font-poppins">
            Plan Fees<span className="text-red-500">*</span>
          </label>
          <div className="grid grid-cols-2 gap-4 h-16 px-2">
            {strategyDetails?.frequency?.length !== 0 ? (
              strategyDetails?.frequency?.map((offer, i) => (
                <div
                  key={i}
                  className={`flex flex-col p-3 rounded-md cursor-pointer ${
                    selectedCard === offer
                      ? "bg-[#E8F5F3] border-[#6AC2B1]"
                      : "bg-white border-gray-200"
                  } border`}
                  onClick={() => handleCardClick(offer)}
                >
                  <div className="flex justify-between items-center mb-1">
                    <span className="text-xs">
                      {offer === "monthly"
                        ? "Monthly"
                        : offer === "quarterly"
                        ? "Quarterly"
                        : offer === "half-yearly"
                        ? "Half Yearly"
                        : "Yearly"}
                    </span>
                    <div
                      className={`w-4 h-4 rounded-full border flex items-center justify-center ${
                        selectedCard === offer
                          ? "border-[#6AC2B1] bg-[#6AC2B1]"
                          : "border-[#0000001A] bg-white"
                      }`}
                    >
                      {selectedCard === offer && (
                        <img src={Checked} className="w-3 h-3" alt="Checked" />
                      )}
                    </div>
                  </div>
                  <span className="text-sm font-medium">
                    ₹{strategyDetails?.pricing?.[offer]}
                  </span>
                </div>
              ))
            ) : (
              <div
                className={`flex flex-col p-3 rounded-md cursor-pointer ${"bg-[#E8F5F3] border-[#6AC2B1]"} border`}
              >
                <div className="flex justify-between items-center mb-1">
                  <span className="text-xs">One Time</span>
                  <div className="w-4 h-4 rounded-full border  bg-[#6AC2B1] flex items-center justify-center">
                    <img
                      src={Checked}
                      alt="Checked Icon"
                      className="w-4 h-4 md:w-6  "
                    />{" "}
                  </div>
                </div>
                <span className="text-sm font-medium">
                  ₹{strategyDetails?.amount}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="px-8 pt-4">
        <button
          disabled={
            (strategyDetails?.frequency?.length !== 0 &&
              selectedCard === null) ||
            (strategyDetails?.type.toLowerCase() !== "bespoke" &&
              !(invetAmount >= strategyDetails?.minInvestment)) ||
            !userEmail ||
            !name ||
            !panNumber ||
            !mobileNumber
          }
          onClick={handleOk}
          className="w-full h-[48px] rounded-[6px] bg-black text-white text-base font-medium hover:bg-black/90 disabled:bg-black/30 disabled:cursor-not-allowed transition-colors duration-150 ease-linear shadow-[0_2px_0_0_rgba(0,0,0,0.04)] flex items-center justify-center"
        >
          <div className="text-white font-medium text-sm">
            <div className="flex items-center justify-center">
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                <>
                  Continue
                  <ChevronRight className="ml-2" size={16} />
                </>
              )}
            </div>
          </div>
        </button>
      </div>
    </div>
  );

  if (isDesktop) {
    return (
      <Dialog open={paymentModal} onOpenChange={setPaymentModal}>
        <DialogContent className="max-w-[622px] w-full p-0">
          {renderContent()}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={paymentModal} onOpenChange={setPaymentModal}>
      <DrawerContent className="focus:outline-none">
        <div
          onClick={() => {
            setPaymentModal(false);
          }}
          className="absolute top-4 right-4 flex items-center justify-center"
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>
        {renderContent()}
      </DrawerContent>
    </Drawer>
  );
};

export default PlanSubscribeModal;
