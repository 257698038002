// InfoHoverWrapper.jsx
import React from 'react';
import { Info } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../components/ui/tooltip';

const InfoHoverWrapper = ({ children, infoText }) => (
  <TooltipProvider>
    <div className="relative w-full">
      <div
        className="flex items-center justify-between lg:mt-4 mt-2 p-4 rounded-md border border-gray-300"
        style={{
          background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="flex-grow">{children}</div>
        <Tooltip>
          <TooltipTrigger asChild>
            <button className="ml-4 p-1 rounded-full hover:bg-gray-200 transition-colors duration-200">
              <Info className="text-gray-600" size={20} />
              <span className="sr-only">More information</span>
            </button>
          </TooltipTrigger>
          <TooltipContent side="left" className="max-w-xs sm:w-64 break-words">
            <p className="text-sm">{infoText}</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </div>
  </TooltipProvider>
);

export default InfoHoverWrapper;

