import React from "react";
import Alpha100 from "../../assests/alpha-100.png";
import VolatilityIcon from "../../assests/volatility.svg";
import { useNavigate } from "react-router-dom";
import server from "../../utils/serverConfig";

import { Info, Gauge } from "lucide-react";

import formatCurrency from "../../utils/formatCurrency";

const StrategyCard = ({ fileName, overView, ele }) => {
  const navigate = useNavigate();

  const handleViewStrategy = () => {
    navigate(
      `/admin/admin-strategy/${fileName.toLowerCase().replace(/ /g, "_")}`
    );
    // navigate(`/clients-list/${fileName.toLowerCase()}`);
  };

  return (
    <div
      className="w-full  flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between border-b-[1px] lg:h-[100px] border-[#000000]/10 px-4 xxl:px-16  mt-4 py-2 cursor-pointer"
      onClick={handleViewStrategy}
    >
      <div className="flex items-start space-x-4 px-3 lg:px-0 lg:flex-row lg:space-x-8">
        {ele?.image ? (
          <img
            src={`${server.server.baseUrl}${ele.image}`}
            alt={ele.model_name}
            className="mt-1 lg:mt-0 h-[50px] w-[50px] lg:h-[70px] lg:w-[70px] rounded-md"
          />
        ) : (
          <img
            src={Alpha100}
            className="mt-1 lg:mt-0 h-[50px] w-[50px] lg:h-[70px] lg:w-[70px] rounded-md"
          />
        )}

        <div className="flex justify-center flex-col">
          <div className="flex items-center space-x-4">
            <h3
              onClick={handleViewStrategy}
              className="text-[18px] font-sans font-bold text-[#000000] cursor-pointer"
            >
              {fileName}
            </h3>
            <div className="text-xs font-poppins font-medium text-[#0E846D] bg-[#D5FFE7] rounded-[3px] px-2 py-1">
              New
            </div>
          </div>

          <p className="w-full lg:w-[300px] text-[12px] lg:text-[14px] font-poppins font-medium text-[#000000]/50">
            {overView.split(" ").slice(0, 10).join(" ")}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-x-0 lg:gap-x-12 ">
        <div className="flex flex-col space-y-2 items-center justify-center h-[80px] lg:px-4">
          <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
            CAGR
          </div>
          <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold ">
            +75.2%
          </p>
        </div>

        <div className="flex flex-col space-y-2 items-center justify-center h-[80px] lg:px-4">
          <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
            2Y Returns
          </div>
          <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold ">
            +775.2%
          </p>
        </div>

        <div className="flex flex-col space-y-2 items-center justify-center h-[80px] lg:px-4">
          <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
            Volatility
          </div>

          <div className="flex space-x-2">
            <div className="flex items-center justify-center">
              <img src={VolatilityIcon} className="h-[20px] w-[20px]" />
            </div>
            <p className="text-[14px] lg:text-[16px] text-[#000000] font-poppins font-semibold ">
              Low
            </p>
          </div>
        </div>

        <div className="flex flex-col space-y-2 items-center justify-center h-[80px] lg:px-4">
          <div className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-normal ">
            Score
          </div>
          <p className="flex items-center justify-center text-[10px] lg:text-[12px] rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[20px] w-[20px] lg:h-[24px] lg:w-[24px] ">
            10
          </p>
        </div>
      </div>
    </div>

    //           <div className="h-full overflow-auto custom-scrollbar md:overflow-auto w-full grid gap-y-4 pb-4 px-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:gap-y-6 md:gap-x-3 xl:grid-cols-3 lg:gap-4 lg:pt-6 lg:px-[50px] lg:pb-12">

    //   <div
    //     className="relative font-poppins w-full rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4"
    //     onClick={handleViewStrategy}
    //   >
    //     <div className="flex items-start gap-x-4 p-4">
    //       <div className="overflow-hidden rounded-lg">
    //         {ele?.image ? (
    //           <img
    //             src={`${server.server.baseUrl}${ele.image}`}
    //             alt={ele.model_name}
    //             className="mt-1 lg:mt-0 h-[50px] w-[60px] lg:h-[70px] lg:w-[80px] rounded-md"
    //           />
    //         ) : (
    //           <img
    //             src={Alpha100}
    //             className="mt-1 lg:mt-0 h-[50px] w-[60px] lg:h-[70px] lg:w-[80px] rounded-md"
    //           />
    //         )}
    //       </div>
    //       <div>
    //         <h2 className="text-lg font-semibold text-[#000000]">
    //           {fileName}
    //         </h2>
    //         <p className="mt-1 text-[10px] md:text-[10px] text-[#95989C] font-medium font-poppins text-left">
    //           {overView.split(" ").slice(0, 10).join(" ")}
    //         </p>
    //       </div>
    //     </div>

    //     <div className="flex w-full mt-[6px] border-t-[1px] border-b-[1px] border-[#000000]/10">
    //       <div className="flex items-center flex-col space-y-1 w-1/4 py-1.5 pl-5 lg:pl-5 border-r-[1px] border-[#000000]/10">
    //         <div className="text-[10px] text-[#000000]/70 font-normal font-poppins capitalize">
    //           CAGR
    //         </div>
    //         <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold">
    //           +75.2%
    //         </p>
    //       </div>

    //       <div className="flex flex-col space-y-1 w-1/4 items-center py-1.5 lg:px-2 border-r-[1px] border-[#000000]/10">
    //         <div className="text-[10px] text-[#000000]/70 font-normal font-poppins text-center">
    //           2Y Returns
    //         </div>
    //         <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold">
    //           +775.2%
    //         </p>
    //       </div>

    //       <div className="flex flex-col space-y-1 w-2/4 items-center py-1.5 lg:px-1 rounded-md">
    //         <div className="text-[10px] text-[#000000]/70 font-normal font-poppins">
    //           Minimum investment
    //         </div>
    //         <p className="text-[14px] lg:text-[16px] text-[#000000] font-poppins font-semibold">
    //           ₹ {formatCurrency(ele.minInvestment)}
    //         </p>
    //       </div>
    //     </div>

    //     <div className="flex flex-col items-start justify-between px-5 mt-4">
    //       <div className="w-full group relative flex items-start justify-between text-base text-[#000000]/80 font-poppins font-semibold">
    //         <div className="flex items-center cursor-pointer">
    //           <Info size={16} />
    //           <p className="text-[14px] lg:text-[13px] px-2 lg:px-2 py-0 font-medium">
    //             Monthly Fees: ₹2500
    //           </p>
    //         </div>

    //         <div className="flex items-center px-3 py-[1px] rounded-md">
    //           <Gauge size={16} className="text-red-500" />
    //           <span className="text-[14px] lg:text-[13px] px-2 lg:px-2 py-0 font-medium">
    //             Subject to market risk
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="p-4">
    //       <div className="flex space-x-2 sm:space-x-2 md:space-x-2 xxl:space-x-2 w-full">
    //         <button
    //           className="w-full py-2 px-1 rounded-md bg-white border-[#000000]/20 border-[2px] text-black text-xs sm:text-[15px] lg:text-xs xxl:text-[15px] font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
    //         >
    //           Performance
    //         </button>
    //         <button
    //           className="w-full flex items-center justify-center py-2 px-1 rounded-md bg-black text-white text-xs sm:text-[15px] lg:text-xs xxl:text-[15px] font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
    //         >
    //           View More
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default StrategyCard;
