import React, { useState, useEffect } from "react";
import VolatilityIcon from "../../assests/volatility.svg";
import Alpha100 from "../../assests/alpha-100.png";

import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Table, Card } from "antd";
import {
  CandlestickChartIcon,
  PencilIcon,
  Share2Icon,
  TimerIcon,
  TrendingUpIcon,
} from "lucide-react";
import moment from "moment";
import server from "../../utils/serverConfig";

const style = {
  selected:
    "flex items-center text-[16px] md:text-[18px]  font-sans text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[16px] md:text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const PlanDetails = ({ fileName }) => {
  const [selectedDataTab, setSelectedDataTab] = useState("performance");
  const [showRebalanceModal, setShowRebalanceModal] = useState(false);
  const [showRebalanceTimelineModal, setShowRebalanceTimelineModal] =
    useState(false);

  const openRebalanceModal = () => {
    setShowRebalanceModal(true);
  };

  const closeRebalanceModal = () => {
    setShowRebalanceModal(false);
  };

  const openRebalanceTimelineModal = () => {
    setShowRebalanceTimelineModal(true);
  };

  const closeRebalanceTimelineModal = () => {
    setShowRebalanceTimelineModal(false);
  };

  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);

  const getStrategyDetails = () => {
    if (fileName) {
      axios
        .get(
          `${server.server.baseUrl}api/admin/plan/detail/${fileName}/advisor`
        )
        .then((res) => {
          console.log("data", res);
          const portfolioData = res.data;
          setStrategyDetails(portfolioData.data);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  return (
    <div className="w-full flex flex-col max-h-[calc(100vh-60px)]  lg:min-h-screen  bg-[#F9F9F9] ">
      {/* Bottom Fixed Add New rebalance Button for Mobile View  */}
      <Toaster position="top-center" reverseOrder={true} />
      <div className="w-full grid grid-cols-12 h-[calc(100vh-120px)] overflow-y-auto lg:min-h-full">
        {/* Left  Side Strategy Details Part */}
        <div className="col-span-12 px-4 lg:col-span-9 lg:px-[60px]">
          <div className="flex flex-col space-y-6 py-10 ">
            <div className="flex space-x-6">
              {strategyDetails?.image ? (
                <div className="flex items-center justify-center">
                  <img
                    src={`${server.server.baseUrl}${strategyDetails.image}`}
                    alt={strategyDetails?.modal_name}
                    className="h-[50px] w-[50px] rounded-md"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src={Alpha100}
                    alt="Alpha 100 Strategy"
                    className="h-[50px] w-[50px] rounded-md"
                  />
                </div>
              )}

              <div className="flex justify-center flex-col">
                <div className="flex items-center space-x-4">
                  <h3 className="text-[18px] font-sans font-bold capitalize text-[#000000]">
                    {strategyDetails?.name}
                  </h3>
                </div>
              </div>
            </div>

            <p className="text-[14px] font-normal font-poppins text-[#000000]/60">
              {strategyDetails?.description}
            </p>
          </div>

          <div className="flex flex-col space-y-6 pb-8 lg:hidden">
            <div className="flex justify-evenly  divide-x-2 bg-[#E4FDD5]/50 ">
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  CAGR
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +186%
                </p>
              </div>

              <div className="flex flex-col space-y-1 items-center  justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  Returns (2 yrs)
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +400%
                </p>
              </div>
            </div>

            <div className="w-full flex flex-col ">
              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img src={VolatilityIcon} className="h-[26px] w-[26px]" />
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Volatility :
                  <p className="ml-2 text-[14px] text-[#000000] font-poppins font-medium ">
                    Low
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <div className="flex items-center justify-center text-[12px] pt-0.5 rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[24px] w-[24px] ">
                  10
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Strategy Score
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <TrendingUpIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Rebalance Frequency :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Weekly
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <TimerIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Duration :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Short Terms
                  </p>
                </div>
              </div>

              <div className="flex items-center border-none py-4 space-x-3">
                <CandlestickChartIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  No. of Trades :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    15K Trades
                  </p>
                </div>
              </div>
            </div>
          </div>

          <TableWithCard data={strategyDetails?.subscribed_by} />
        </div>

        <div className="hidden lg:block col-span-3">
          <div className="flex flex-col space-y-6 px-6 py-10">
            <div className="w-full flex flex-col py-6">
              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img src={VolatilityIcon} className="h-[26px] w-[26px]" />
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Plan Type :
                  <p className="ml-2 text-[14px] text-[#000000] font-poppins font-medium ">
                    {strategyDetails?.type}
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <TrendingUpIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Frequency :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    {strategyDetails?.frequency?.join(",")}
                  </p>
                </div>
              </div>

              {/* <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                                <TimerIcon className="h-5 w-5 text-[#000000] " />
                                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                                    Duration :
                                    <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                                        {strategyDetails?.duration}
                                    </p>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;

const columns = [
  {
    title: "User Email",
    dataIndex: "user_email",
    key: "age",
  },
  {
    title: "Amount",
    dataIndex: "user_email",
    render: (_, { amount }) => {
      return amount ? `Rs ${amount}` : 0;
    },
  },
  {
    title: "SIP Amount",
    dataIndex: "user_email",
    render: (_, { sip_amount }) => {
      return sip_amount ? `Rs ${sip_amount}` : 0;
    },
  },
  {
    title: "Start Date",
    dataIndex: "address",
    key: "address",
    render: (_, { start_date }) => {
      return moment(start_date).format("DD-MMM-YYYY");
    },
  },
  {
    title: "End Date",
    dataIndex: "address",
    key: "address",
    render: (_, { end_date }) => {
      return moment(end_date).format("DD-MMM-YYYY");
    },
  },
];

const TableWithCard = ({ data }) => {
  return (
    <Card title="Subscriptions" bordered={false} style={{ width: "100%" }}>
      <Table dataSource={data} columns={columns} pagination={false} />
    </Card>
  );
};
