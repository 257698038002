import React from "react";
import StockRecommendation from "./StockRecommendation";
import DashboardLayout from "../RootSection/DashboardLayout";

const UserStockRecommendation = ({ getAllTrades }) => {
  return (
    <DashboardLayout>
      <StockRecommendation getAllTradesUpdate={getAllTrades} />
    </DashboardLayout>
  );
};

export default UserStockRecommendation;
