import React from "react";
import { cn } from "../../lib/utils";
import ModalPFCard from "./ModalPFCard";

const ModalPFList = ({
  className,
  modelPortfolioStrategy,
  modelPortfolioRepairTrades,
}) => {
  return (
    <div className="md:mx-0 ml-[6px] mt-0 md:mt-4 lg:mt-4 md:bg-[#2A587C] lg:bg-[#2A587C]  rounded-[6px] shadow-[3px_5px_20px_0px_rgba(0,0,0,0.06)] pt-4 md:overflow-x-auto">
      <div className="w-full md:min-w-[1000px]">
        <div className="hidden md:block">
          <div
            className={cn(
              "bg-[#2A587C] w-full md:px-10 lg:px-10 py-3",
              className
            )}
          >
            <div className="grid grid-cols-7 gap-8">
              <div className="flex col-span-2 ml-10">
                <div className="text-white/70 font-normal font-poppins text-xs sm:text-sm">
                  Modal Portfolio Name
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-white/70 font-normal font-poppins text-xs sm:text-sm">
                  Invested Amount
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-white/70 font-normal font-poppins text-xs sm:text-sm">
                  Current Amount
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-white/70 font-normal font-poppins text-xs sm:text-sm">
                  Absolute Returns
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-white/70 font-normal font-poppins text-xs sm:text-sm">
                  Net Returns
                </div>
              </div>
              <div className="flex pl-12">
                <div className="text-white/70 font-normal font-poppins text-xs sm:text-sm">
                  Details
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col   md:bg-[#000000]/10 divide-[#ffffff]/10 divide-y-[1px] animate-slideDown">
            {modelPortfolioStrategy?.length !== 0 &&
              modelPortfolioStrategy?.map((ele, i) => {
                const allRebalances = ele?.model?.rebalanceHistory || [];

                const sortedRebalances = allRebalances?.sort(
                  (a, b) =>
                    new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
                );
                const latest = sortedRebalances[0];

                if (!latest) return null;

                const matchingFailedTrades = modelPortfolioRepairTrades?.find(
                  (trade) =>
                    trade.modelId === latest?.model_Id &&
                    trade.failedTrades.length !== 0
                );

                return (
                  <ModalPFCard
                    key={i}
                    modelName={ele?.model_name}
                    data={latest}
                    repair={matchingFailedTrades ? "repair" : null}
                  />
                );
              })}
          </div>
        </div>
      </div>

      {/* Mobile view (xs and sm screens) */}
      <div className="md:hidden bg-[#264F6F]   shadow-[3px_5px_20px_0px_rgba(0,0,0,0.06)]  ">
        <div className=" divide-[#ffffff]/10 divide-y-[1px]">
          {modelPortfolioStrategy?.length !== 0 &&
            modelPortfolioStrategy?.map((ele, i) => {
              const allRebalances = ele?.model?.rebalanceHistory || [];

              const sortedRebalances = allRebalances?.sort(
                (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
              );
              const latest = sortedRebalances[0];

              if (!latest) return null;

              const matchingFailedTrades = modelPortfolioRepairTrades?.find(
                (trade) =>
                  trade.modelId === latest?.model_Id &&
                  trade.failedTrades.length !== 0
              );

              return (
                <ModalPFCard
                  key={i}
                  modelName={ele?.model_name}
                  data={latest}
                  repair={matchingFailedTrades ? "repair" : null}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ModalPFList;
