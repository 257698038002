import React from "react";

const NestedTablePositions = ({
  details,
  setOpenRecommendationModel,
  setAllUserDeatils,
  setSelectEmail,
  price,
  setSelectSymbol,
}) => {
  return (
    <div className="overflow-auto">
      <table className="w-full">
        <thead className="bg-[#e6e6e6] text-sm text-left sticky top-0">
          <tr className="text-left border-b-[1px] border-[#000000]/10">
            <th className="text-[14px] max-w-[150px]  lg:w-auto  text-[#000000]/60  font-poppins font-medium px-2 lg:px-3 py-2 ">
              Client Email
            </th>
            <th className="text-[14px] min-w-[90px]  lg:w-auto  text-[#000000]/60  font-poppins text-center font-medium px-2 lg:px-3 py-2 ">
              Holding Qty
            </th>
            <th className="text-[14px] min-w-[120px] lg:w-auto  text-[#000000]/60  font-poppins text-center font-medium px-2 lg:px-3 py-2 ">
              Avg. Holding Price
            </th>
            <th className="text-[14px] min-w-[100px]  lg:w-auto  text-[#000000]/60  font-poppins text-center font-medium px-2 lg:px-3 py-2 ">
              Unrealized P&L
            </th>
            <th className="text-[14px] min-w-[100px]  lg:w-auto  text-[#000000]/60  font-poppins text-center font-medium px-2 lg:px-3 py-2 ">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {details.map((detail, i) => {
            const pnl = (price - detail?.buyPrice) * detail?.tradedQty;
            return (
              <tr key={i} className="border-t-[1px]  border-[#000000]/10">
                <td className="text-[15px] max-w-[150px] truncate text-[#000000] font-poppins font-medium  px-3 py-2">
                  {detail?._id.email}
                </td>
                <td className="text-[15px] text-[#000000] font-poppins text-center font-medium  px-3 py-2">
                  {detail?.tradedQty}
                </td>
                <td className="text-[15px] text-[#000000] font-poppins  text-center font-medium  px-3 py-2">
                  {detail?.buyPrice}
                </td>
                <td className="text-[15px] text-[#000000] font-poppins text-center font-medium  px-3 py-2">
                  {pnl > 0 ? (
                    <span className="text-[#16A085] font-semibold">
                      +₹{pnl.toFixed(2)}
                    </span>
                  ) : pnl < 0 ? (
                    <span className="text-[#E43D3D] font-semibold">
                      {/* -₹{Math.abs(pnl.toFixed(2))} */}
                      -₹{Math.abs(pnl).toFixed(2)}

                    </span>
                  ) : (
                    <span> 0</span>
                  )}
                </td>
                <td className="text-[15px] text-[#000000] font-poppins text-center font-medium  px-3 py-2">
                  <span
                    className="inline-block text-center w-[60px] mx-auto border-[1px] rounded-[4px] py-[2px] border-[#000000]/20 cursor-pointer"
                    onClick={() => {
                      setOpenRecommendationModel(true);
                      setSelectEmail(detail?._id.email);
                      setAllUserDeatils(details);
                      setSelectSymbol(detail?.Symbol);
                    }}
                  >
                    Sell
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NestedTablePositions;
