import axios from "axios";
import server from "../../utils/serverConfig";

export const getAdminDetails = async (adminEmail) => {
  try {
    const response = await axios.get(
      `${server.server.baseUrl}api/admin/${adminEmail}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};
