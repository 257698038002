"use client"

import { useState } from "react"
import { CalendarDays, ShoppingBasket, ChevronDown, ChevronUp } from "lucide-react"
import moment from "moment"
import BasketModal from "./BasketModal"
import LoadingSpinner from "../../../components/LoadingSpinner"

const BasketCard = ({ basketName, lastUpdated, description, basketId, onAccept, trades, funds, date }) => {
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div className="bg-gradient-to-b from-[#2F4F7D] to-[#1D3A5F] relative w-full rounded-xl shadow-lg py-3 flex flex-col min-h-[250px] border border-[#4A7AAF]/20 transition-all duration-300 hover:shadow-xl">
        <div className="flex-grow">
          {/* Header */}
          <div className="flex items-center justify-between mx-5 border-b border-[#ffffff]/20 pb-2">
            <div className="flex items-center space-x-2">
              <span className="px-3 py-1 bg-[#4A7AAF]/30 rounded-full text-[14px] text-white font-medium">
                Basket Order
              </span>
            </div>
          </div>

          {/* Basket Info */}
          <div className="px-6 pt-2">
            <div className="flex items-start gap-3 mb-2">
              <div className="bg-[#4A7AAF] p-2.5 rounded-full flex-shrink-0">
                <ShoppingBasket size={20} className="text-white" />
              </div>
              <div className="flex flex-col">
                <h5 className="text-xl font-semibold text-white capitalize">{basketName}</h5>
                <p className="text-[13px] text-[#ffffff]/80 line-clamp-2">{description}</p>
              </div>
            </div>
          </div>

          {/* Trades List */}
          <div className="px-6 mt-3">
            <div className="bg-[#1D3A5F]/80 rounded-lg p-3 border border-[#4A7AAF]/30">
              <div
                className={`space-y-2 ${expanded ? "max-h-40 overflow-y-auto custom-scroll pr-1" : "max-h-20 overflow-hidden"}`}
              >
                {trades.slice(0, expanded ? trades.length : 2).map((trade, index) => (
                  <div key={index} className="flex items-center space-x-2 bg-[#2F4F7D]/50 p-2 rounded-md">
                    <div className="w-2 h-2 bg-[#4A7AAF] rounded-full"></div>
                    <p className="text-[14px] font-medium text-[#ffffff]/90">
                      {trade.searchSymbol} | {trade.Strike} | {trade.OptionType}
                    </p>
                  </div>
                ))}
              </div>
              {trades.length > 2 && (
                <button
                  className="w-full text-[13px] text-[#4A7AAF] mt-3 flex items-center justify-center transition-colors duration-200 hover:text-white py-1 bg-[#1D3A5F]/50 rounded-md border border-[#4A7AAF]/30"
                  onClick={() => setExpanded(!expanded)}
                >
                  {expanded ? (
                    <>
                      View Less <ChevronUp size={16} className="ml-1" />
                    </>
                  ) : (
                    <>
                      View More <ChevronDown size={16} className="ml-1" />
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="mt-auto px-6 pb-3">
          <div className="flex items-center justify-between pb-3 border-b border-[#ffffff]/10 mb-2">
            <div className="text-white/80 text-xs font-medium flex items-center bg-[#1D3A5F]/50 px-3 py-2 rounded-md">
              <CalendarDays size={16} className="text-[#4A7AAF] mr-2" />
              <span>{moment(date).format("Do MMM YYYY")}</span>
              <span className="mx-2 text-[#4A7AAF]">•</span>
              <span>{moment(date).format("HH:mm A")}</span>
            </div>
          </div>
          <button
            className="w-full py-2 px-4 rounded-sm bg-white text-[#1D3A5F] text-[13px] font-semibold flex items-center justify-center transition-all duration-200 hover:bg-[#F0F0F0] hover:shadow-md"
            onClick={() =>
              onAccept({
                basketName,
                lastUpdated,
                description,
                basketId,
                trades,
              })
            }
          >
            <span className="w-full h-full flex items-center justify-center">
              {loading ? <LoadingSpinner /> : "Accept Basket"}
            </span>
          </button>
        </div>
      </div>

      <BasketModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        basketName={basketName}
        lastUpdated={lastUpdated}
        description={description}
        basketId={basketId}
        onAccept={onAccept}
        loading={loading}
        funds={funds}
      />
    </>
  )
}

export default BasketCard

