import React from "react";

const showQuantityColumn = process.env.REACT_APP_ADVISOR_SPECIFIER;

const style = {
  tableHead: "py-3 px-4 border-b text-left font-semibold text-gray-600 text-sm",
};
const TableHeader = ({
  isAllSelected,
  handleSelectAllChange,
  closePositionPopUp,
}) => {
  return (
    <thead className="bg-gray-100 sticky top-0 z-10">
      <tr>
        {closePositionPopUp === false ? null : (
          <th className="py-3 px-4 border-b text-left font-semibold text-gray-600 text-md">
            <input
              type="checkbox"
              checked={isAllSelected}
              onChange={handleSelectAllChange}
              className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
            />
          </th>
        )}
        {closePositionPopUp === false ? null : (
          <th className={style.tableHead}>Action</th>
        )}
        <th className={style.tableHead}>Symbol</th>
        {closePositionPopUp === false ? null : (
          <th className={style.tableHead}>Closure Status</th>
        )}
        <th className={style.tableHead}>Type</th>
        {closePositionPopUp === false ? null : (
          <th className={style.tableHead}>Limit Price</th>
        )}
        {showQuantityColumn === "RIA" && (
          <th className={style.tableHead}>Quantity</th>
        )}
        <th className={style.tableHead}>Exchange</th>
        <th className={style.tableHead}>Order Type</th>
        <th className={style.tableHead}>Product Type</th>
        <th className={style.tableHead}>Segment</th>
        {closePositionPopUp === false ? null : (
          <th className={style.tableHead}>Emails</th>
        )}
        {closePositionPopUp === false ? null : (
          <th className={style.tableHead}>Group</th>
        )}
        <th className={style.tableHead}>Rationale</th>
        <th className={style.tableHead}>Comment</th>
        <th className={style.tableHead}>Comment2</th>
      </tr>
    </thead>
  );
};

export default TableHeader;
