import React, { useState, useEffect } from "react";
import { X, Trash2, XIcon, MinusIcon, PlusIcon,RefreshCw  } from "lucide-react";

import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";
import axios from "axios";
import server from "../../utils/serverConfig";
const ZerodhaReviewModal = ({
  calculateTotalAmount,
  getLTPForSymbol,
  stockDetails,
  setStockDetails,
  setOpenZerodhaModel,
  checkZerodhaLoading,
  handleZerodhaRedirect,
  openZerodhaModel,
  getCartAllStocks,
}) => {
  const handleDecreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: Math.max(stock.quantity - 1, 0) }
        : stock
    );
    setStockDetails(newData);
  };

  const handleIncreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: stock.quantity + 1 }
        : stock
    );
    setStockDetails(newData);
  };

  const handleQuantityInputChange = (symbol, value, tradeId) => {
    const newQuantity = parseInt(value) || 0;
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: newQuantity }
        : stock
    );
    setStockDetails(newData);
  };

  const handleRemoveStock = (symbol, tradeId) => {
    setStockDetails(
      stockDetails.filter(
        (stock) => stock.tradingSymbol !== symbol || stock.tradeId !== tradeId
      )
    );
  };

  const isDesktop = useMediaQuery("(min-width: 1199px)");

  const hasZeroQuantity = stockDetails.some((stock) => stock.quantity === 0);


  const [selectedOption, setSelectedOption] = useState("");
  const [inputFixSizeValue, setInputFixValue] = useState("");

  const handleFixSize = () => {
    if (selectedOption === "fix-size" && inputFixSizeValue) {
      const fixedSize = parseFloat(inputFixSizeValue);
      const updatedStockDetails = stockDetails.map((stock) => {
        const currentPrice = parseFloat(getLTPForSymbol(stock.tradingSymbol));
        const newQuantity = Math.floor(fixedSize / currentPrice);
        return { ...stock, quantity: newQuantity };
      });
      setStockDetails(updatedStockDetails);
    }
  };

  const handleReset = () => {
    setSelectedOption("");
    setInputFixValue("");
  };



  if (isDesktop) {
    return (

// OLD DESIGN FOR TRADE ORDER ZERODHA

      // <Dialog open={openZerodhaModel} onOpenChange={setOpenZerodhaModel}>
      //   <DialogContent className="lg:max-w-[65vw] w-full p-0 animate-modal">
      //     {/* <div
      //       onClick={() => {
      //         setOpenZerodhaModel(false);
      //         getCartAllStocks();
      //       }}
      //       className="bg-white absolute top-4 right-3 flex items-center justify-center "
      //     >
      //       <XIcon className="h-5 w-5 text-gray-900" />
      //     </div> */}
      //     <div className="flex flex-col  w-full  rounded-[8px] pt-4  h-[630px] lg:h-auto bg-[#ffffff] ">
      //       <div className="px-6 flex flex-row justify-between items-center">
      //         <div className="text-xl text-black font-semibold leading-[40px] font-sans">
      //           Review Zerodha Trade Details
      //         </div>
      //       </div>
      //       {hasZeroQuantity ? (
      //         <div className="px-6 text-base text-rose-500 font-semibold leading-[40px] font-sans">
      //           Please select quantity to place order
      //         </div>
      //       ) : null}
      //       {/* Stock details table */}
      //       <div className="w-full h-full border-t-[1px] border-[#000000]/10  lg:h-[400px]  overflow-auto custom-scroll">
      //         <table className="w-full">
      //           {/* Table header */}
      //           <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
      //             <tr className="border-b-[1px]  border-[#000000]/10">
      //               <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
      //                 Stocks
      //               </th>
      //               <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
      //                 Current Price (₹)
      //               </th>
      //               <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
      //                 Order
      //               </th>
      //               <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
      //                 Quantity
      //               </th>
      //               <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
      //                 Order Type
      //               </th>
      //               <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
      //                 Delete
      //               </th>
      //             </tr>
      //           </thead>
      //           {/* Table body */}
      //           <tbody>
      //             {stockDetails.map((stock, i) => (
      //               <tr
      //                 key={i}
      //                 className="border-b-[1px] border-[#000000]/10 last-of-type:border-none"
      //               >
      //                 <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8">
      //                   {stock.tradingSymbol}
      //                 </td>
      //                 <td className="text-[15px] text-[#000000]/80 font-poppins font-normal text-center py-3 px-5">
      //                   {getLTPForSymbol(stock.tradingSymbol)}
      //                 </td>
      //                 <td
      //                   className={
      //                     stock?.transactionType?.toLowerCase() === "buy"
      //                       ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
      //                       : stock?.transactionType?.toLowerCase() === "sell"
      //                       ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
      //                       : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
      //                   }
      //                 >
      //                   {stock.transactionType?.toLowerCase()}
      //                 </td>
      //                 <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5">
      //                   <div className="flex flex-row items-center justify-between text-[12px] text-[#000000]/80 font-poppins">
      //                     <button
      //                       onClick={() =>
      //                         handleDecreaseStockQty(
      //                           stock.tradingSymbol,
      //                           stock.tradeId
      //                         )
      //                       }
      //                       disabled={stock.quantity <= 1}
      //                       className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
      //                     >
      //                       -
      //                     </button>
      //                     <input
      //                       type="text"
      //                       value={stock.quantity}
      //                       onChange={(e) =>
      //                         handleQuantityInputChange(
      //                           stock.tradingSymbol,
      //                           e.target.value,
      //                           stock.tradeId
      //                         )
      //                       }
      //                       className="flex flex-1 items-center justify-center w-[45px] h-[28px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
      //                     />
      //                     <button
      //                       onClick={() =>
      //                         handleIncreaseStockQty(
      //                           stock.tradingSymbol,
      //                           stock.tradeId
      //                         )
      //                       }
      //                       className="cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
      //                     >
      //                       +
      //                     </button>
      //                   </div>
      //                 </td>
      //                 <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5">
      //                   {stock.orderType}
      //                   {stock.orderType === "LIMIT" ||
      //                   stock.orderType === "STOP" ? (
      //                     <span>({stock.price})</span>
      //                   ) : null}
      //                 </td>
      //                 <td className="text-[15px] text-[#000000]/80 font-poppins font-medium flex flex-row justify-center text-center py-3 px-3 lg:py-4 lg:px-5">
      //                   <Trash2
      //                     size={18}
      //                     className="cursor-pointer hover:text-[#ff0000]"
      //                     onClick={() =>
      //                       handleRemoveStock(
      //                         stock.tradingSymbol,
      //                         stock.tradeId
      //                       )
      //                     }
      //                   />
      //                 </td>
      //               </tr>
      //             ))}
      //           </tbody>
      //         </table>
      //       </div>
      //     </div>
      //     <DialogFooter className="sm:justify-between flex px-4 pt-4 pb-12  shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
      //       <div className=" flex flex-row items-center">
      //         <div className=" text-[15px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[40px] font-sans">
      //           Confirm Total Amount :
      //         </div>
      //         <div className="px-4 py-1 ml-6 lg:py-1 lg:px-8 text-lg text-[#000000]/80 font-bold border border-[#000000]/20 rounded-md">
      //           ₹{calculateTotalAmount()}
      //         </div>
      //       </div>

      //       <button
      //         className={`w-[180px] px-8 py-3 ml-4 ${
      //           hasZeroQuantity
      //             ? "bg-gray-300 cursor-not-allowed"
      //             : "bg-black hover:bg-gray-800"
      //         } text-white text-base font-semibold rounded-md`}
      //         onClick={handleZerodhaRedirect}
      //         disabled={hasZeroQuantity}
      //       >
      //         {checkZerodhaLoading === true ? (
      //           <LoadingSpinner />
      //         ) : (
      //           <span className="text-[18px] font-medium text-[#ffffff] font-poopins ">
      //             Place Order
      //           </span>
      //         )}
      //       </button>
      //     </DialogFooter>
      //   </DialogContent>
      // </Dialog>


      <Dialog open={openZerodhaModel} onOpenChange={setOpenZerodhaModel}>
      <DialogContent className="lg:max-w-[40vw] w-full p-0 animate-modal">
      <div className=" pt-4 border-b border-gray-200">
        <h2 className=" px-4 sm:px-6 text-xl font-poppins font-semibold text-gray-900">Review Zerodha Trade Details</h2>

          {hasZeroQuantity && (
              <p className="pb-4  px-4 sm:px-6 mt-2 text-sm text-rose-500 font-semibold">
              Please select quantity to place order
            </p>
          )}
      <div className="flex-1 overflow-auto custom-scroll sm:min-w-[600px] sm:max-h-[400px] px-4 sm:px-6 ">
      {stockDetails.map((stock, i) => (
              <div key={i} className="flex items-center justify-between py-3 border-t-[1px]  border-gray-200 last:border-b-0">
               <div className="flex-1 mr-4 min-w-[40%]">
               <p className="text-[14px] sm:text-sm font-medium  text-[#000000]/80 font-poppins text-gray-900 truncate">                    {stock.tradingSymbol}
                  </p>
                  {/* <div className={`${
                    stock.transactionType?.toLowerCase() === "buy"
                      ? "bg-[#16A085]/10 text-[#16A085]"
                      : "bg-[#EA2D3F]/10 text-[#EA2D3F]"
                  } flex items-center px-2 py-[1px] rounded-[4px]`}>
                    <span className="text-[14px] lg:text-[12px] px-1 py-0 font-poppins font-medium capitalize">
                      {stock.transactionType?.toLowerCase()}
                    </span>
                  </div> */}


                  <span className={`inline-flex items-center px-1 py-0.5 rounded text-sm font-semibold ${
                    stock.transactionType?.toUpperCase() === "BUY"
                    ? "text-[#16A085] text-[14px] font-poppins font-medium"
                  : " text-red-800 text-[14px] font-poppins font-medium"
              }`}>
                      {stock.transactionType?.toUpperCase()}
                      </span>

                </div>

                <div className="flex items-center justify-center flex-1">
                <div className="flex items-center min-w-[10%] space-x-2">
                <button
                      onClick={() => handleDecreaseStockQty(stock.tradingSymbol, stock.tradeId)}
                      disabled={stock.quantity <= 1}
                      className="p-1 rounded-full hover:bg-gray-200 disabled:opacity-50"
                      >
                <MinusIcon className="h-2 w-2 sm:h-4 sm:w-4" />
                </button>
                    <input
                      type="text"
                      value={stock.quantity}
                      onChange={(e) => handleQuantityInputChange(stock.tradingSymbol, e.target.value, stock.tradeId)}
                      className="w-16 sm:w-20 text-center border border-gray-300 rounded-md"
                      />
                    <button
                      onClick={() => handleIncreaseStockQty(stock.tradingSymbol, stock.tradeId)}
                      className="p-1 rounded-full hover:bg-gray-200"
                      >
                <PlusIcon className="h-2 w-2 sm:h-4 sm:w-4" />
                </button>
                  </div>
                </div>

                <div className="flex-1 flex flex-col items-end space-y-[2px]">
                  <div className="text-sm font-medium  text-[#000000]/80 font-poppins text-gray-900">
                    ₹{getLTPForSymbol(stock.tradingSymbol)}
                  </div>
                  <div className="text-xs text-gray-500  font-poppins">
                    {stock.orderType?.toLowerCase()}
                    {(stock.orderType === "LIMIT" || stock.orderType === "STOP") && <span>({stock.price})</span>}
                  </div>
                </div>

                <div className="ml-2 sm:ml-4 p-2  text-[#000000]/80  text-[15px] cursor-pointer hover:text-red-500">
                  <Trash2
                    size={18}
                    className="h-4 w-4 sm:h-5 sm:w-5"
                    onClick={() => handleRemoveStock(stock.tradingSymbol, stock.tradeId)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>


        <div className="px-4 sm:px-6  border-gray-200">
        <h3 className="text-base font-medium font-poppins text-gray-900 mb-2">Scale Quantity by</h3>
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="fix-size"
              name="options"
              value="fix-size"
              checked={selectedOption === "fix-size"}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="h-4 w-4"
              style={{ accentColor: "#20A48A" }}
            />
            <label
              htmlFor="fix-size"
              className="text-sm font-medium text-gray-700"
            >
              Fix Size
            </label>
            {selectedOption === "fix-size" && (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={inputFixSizeValue}
                  onChange={(e) => setInputFixValue(e.target.value)}
                  className="w-24 px-2 py-1 text-sm border border-gray-300 rounded-md"
                  placeholder="Enter value"
                />
                <button
                  onClick={handleFixSize}
                  className={`px-3 py-1 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-black ${
                    !inputFixSizeValue ? 'bg-gray-400 cursor-not-allowed' : 'bg-black '
                  }`}                >
                  Update
                </button>
                <button
                  onClick={handleReset}
                  className="p-1 text-gray-600 hover:text-gray-900 focus:outline-none"
                >
                  <RefreshCw className="h-5 w-5" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>


        <DialogFooter className="sm:justify-between flex px-4 pt-4 pb-4 shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
          <div className="flex flex-col items-center">
          <p className="text-sm font-medium text-gray-700">Total Amount:</p>
            <p className="text-2xl font-semibold text-gray-900">
              ₹{calculateTotalAmount()}
            </p>
          </div>

          <button
               className={`px-6 text-[18px] text-base font-medium rounded-md font-poppins  ${
                hasZeroQuantity
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-black "
              } text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black`}
            onClick={handleZerodhaRedirect}
            disabled={hasZeroQuantity}
          >
            {checkZerodhaLoading ? (
              <LoadingSpinner />
            ) : (
              <span className=" text-[#ffffff] font-poppins">
                Place Order
              </span>
            )}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
    );
  }

  return (
    <Drawer open={openZerodhaModel} onOpenChange={setOpenZerodhaModel}>
      <DrawerContent className="focus:outline-none max-h-[550px] md:max-h-[690px]">
        <div
          onClick={() => {
            setOpenZerodhaModel(false);
            getCartAllStocks();
          }}
          className="absolute top-4 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>

        <div className="pt-4 h-full ">
          <div className="px-4  flex flex-row justify-between items-center">
            <div className="text-lg font-poppins font-semibold text-gray-900  ">
              Review Trade Details
            </div>
          </div>
          {hasZeroQuantity ? (
            <div className="px-4 mt-2 text-sm text-rose-500 font-semibold">
              Please select quantity to place order
            </div>
          ) : null}
          {/* <div className="px-6 flex flex-row items-center">
            <div className="text-[15px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[40px] font-sans">
              Confirm Total Amount :
            </div>
            <div className="px-4 py-1 ml-6 lg:py-2 lg:px-8 text-lg text-[#000000]/80 font-bold border border-[#000000]/20 rounded-md">
              ₹{calculateTotalAmount()}
            </div>
          </div> */}

          {/* Stock details table */}
          <div className="w-full border-t-[1px] overflow-y-auto border-[#000000]/10 min-h-[250px]  mt-8 ">
            {/* <table className="w-full">
              <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                <tr className="border-b-[1px] border-[#000000]/10">
                  <th className="text-[12px]  text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                    Stocks
                  </th>
                  <th className="text-[12px] min-w-[120px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Current Price (₹)
                  </th>
                  <th className="text-[12px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Order
                  </th>
                  <th className="text-[12px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Quantity
                  </th>
                  <th className="text-[12px] min-w-[90px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Order Type
                  </th>
                  <th className="text-[12px] min-w-[90px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {stockDetails.map((stock, i) => (
                  <tr
                    key={i}
                    className="border-b-[1px] border-[#000000]/10 last-of-type:border-none"
                  >
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8">
                      {stock.tradingSymbol}
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-normal text-center py-3 px-5">
                      {getLTPForSymbol(stock.tradingSymbol)}
                    </td>
                    <td
                      className={
                        stock?.transactionType?.toLowerCase() === "buy"
                          ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                          : stock?.transactionType?.toLowerCase() === "sell"
                          ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                          : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                      }
                    >
                      {stock.transactionType?.toLowerCase()}
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5">
                      <div className="flex flex-row items-center justify-between text-[12px] text-[#000000]/80 font-poppins">
                        <button
                          onClick={() =>
                            handleDecreaseStockQty(
                              stock.tradingSymbol,
                              stock.tradeId
                            )
                          }
                          disabled={stock.quantity <= 1}
                          className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
                        >
                          -
                        </button>
                        <input
                          type="text"
                          value={stock.quantity}
                          onChange={(e) =>
                            handleQuantityInputChange(
                              stock.tradingSymbol,
                              e.target.value,
                              stock.tradeId
                            )
                          }
                          className="flex flex-1 items-center justify-center w-[45px] h-[28px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                        />
                        <button
                          onClick={() =>
                            handleIncreaseStockQty(
                              stock.tradingSymbol,
                              stock.tradeId
                            )
                          }
                          className="cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5">
                      {stock.orderType}
                      {stock.orderType === "LIMIT" ||
                      stock.orderType === "STOP" ? (
                        <span>({stock.price})</span>
                      ) : null}
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium flex flex-row justify-center text-center py-3 px-3 lg:py-4 lg:px-5">
                      <Trash2
                        size={18}
                        className="cursor-pointer hover:text-[#ff0000]"
                        onClick={() =>
                          handleRemoveStock(stock.tradingSymbol, stock.tradeId)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}

            {stockDetails.map((ele) => (
              <div className="flex items-center   border-[#000000]/10  py-4 px-4 bg-white font-poppins">
                <div className="flex flex-1 items-start flex-col space-y-[6px] overflow-hidden">
                  <div className="text-xs truncate w-full   text-[#000000]/80 font-poppins font-semibold">
                    {ele.tradingSymbol}
                  </div>
                  <div
                   className={`inline-flex items-center py-0.5 rounded text-sm font-semibold ${
                      ele.transactionType?.toLowerCase() === "buy"
                 ? "text-[#16A085] text-[14px] font-poppins font-medium"
                  : " text-red-800 text-[14px] font-poppins font-medium"
                    } flex items-center  py-[1px] rounded-[4px]`}
                  >
                    <span className=" text-[14px] lg:text-[12px]  px-1 py-0  font-poppins font-medium capitalize">
                      {ele.transactionType?.toLowerCase()}
                    </span>
                  </div>
                </div>

                <div className="flex items-center justify-between  text-[12px] text-[#000000]/80 font-poppins">
                  <button
                    onClick={() =>
                      handleDecreaseStockQty(ele.tradingSymbol, ele.tradeId)
                    }
                    disabled={ele.quantity <= 1}
                    className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 py-[2px] rounded-[3px]"
                  >
                    <MinusIcon className=" h-3 w-3" />
                  </button>

                  <input
                    type="text"
                    value={ele.quantity}
                    onChange={(e) =>
                      handleQuantityInputChange(
                        ele.tradingSymbol,
                        e.target.value,
                        ele.tradeId
                      )
                    }
                    className="flex flex-1 items-center justify-center w-[52px] h-[22px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                  />

                  <button
                    onClick={() =>
                      handleIncreaseStockQty(ele.tradingSymbol, ele.tradeId)
                    }
                    className="cursor-pointer hover:bg-black hover:text-white px-1 py-[2px] rounded-[3px]"
                  >
                    <PlusIcon className=" h-3 w-3" />
                  </button>
                </div>

                <div className="ml-1 sm:ml-2 text-right min-w-[20%]">
                <p className="text-xs font-medium  text-[#000000]/80 font-poppins text-gray-900">
                 ₹{getLTPForSymbol(ele.tradingSymbol)}
                  </p>
                  <p className="text-xs text-gray-500  font-poppins">
                    {ele.orderType?.toLowerCase()}
                    {ele.orderType === "LIMIT" || ele.orderType === "STOP" ? (
                      <span>({ele.price})</span>
                    ) : null}
                  </p>

                </div>
                <div className="pl-3 text-[#000000]/80  text-[15px] cursor-pointer hover:text-red-500">
                  <Trash2
                    size={15}
                    onClick={() => handleRemoveStock(ele.tradingSymbol, ele.tradeId)}
                  />
                </div>
              </div>
            ))}


          </div>

        </div>

        <div className="px-4 sm:px-6  mb-4  border-t border-gray-200">
        <h3 className="text-base font-medium font-poppins text-gray-900 pt-3 mb-3">Scale Quantity by</h3>
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="fix-size"
              name="options"
              value="fix-size"
              checked={selectedOption === "fix-size"}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="h-4 w-4"
              style={{ accentColor: "#20A48A" }}
            />
            <label
              htmlFor="fix-size"
              className="text-sm font-medium text-gray-700"
            >
              Fix Size
            </label>
            {selectedOption === "fix-size" && (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={inputFixSizeValue}
                  onChange={(e) => setInputFixValue(e.target.value)}
                  className="w-24 px-1.5 py-1 text-center text-xs border border-gray-300 rounded-md"
                  placeholder="Enter value"
                />
                <button
                  onClick={handleFixSize}
                  className={`px-3 py-1 text-xs font-medium text-white rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-black ${
                    !inputFixSizeValue ? 'bg-gray-400 cursor-not-allowed' : 'bg-black '
                  }`}                >
                  Update
                </button>
                <button
                  onClick={handleReset}
                  className="p-1 text-gray-600 hover:text-gray-900 focus:outline-none"
                >
                  <RefreshCw className="h-4 w-4" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
        <DrawerFooter className="pt-2 flex flex-col  w-full shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
         <div className="flex items-center justify-between space-x-4">
      <div className="flex flex-col items-start">
      <p className="text-sm font-medium text-gray-700">Total Amount:</p>
      <div className="text-1xl font-semibold text-gray-900">
        ₹{calculateTotalAmount()}
        </div>

      </div>




            <button
          className={`px-6 py-2  text-sm font-medium text-[#ffffff]  rounded-md font-poppins  ${
            hasZeroQuantity
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-black hover:bg-gray-800"
              } text-white text-base font-semibold rounded-md`}
              onClick={handleZerodhaRedirect}
              disabled={hasZeroQuantity}
            >
              {checkZerodhaLoading === true ? (
                <LoadingSpinner />
              ) : (
                <span >
                  Place Order
                </span>
              )}
            </button>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ZerodhaReviewModal;
