import React from "react";
import {
  XIcon,
  CheckIcon,
  PauseIcon,
  BanIcon,
  CandlestickChartIcon,
} from "lucide-react";
import FormatDateTime from "../../utils/formatDateTime";
import { motion, AnimatePresence } from "framer-motion"

const PlaceOrders = ({ executedTrades }) => {
  const sortedTrades = [...executedTrades].sort((a, b) => {
    const dateA = new Date(a.exitDate || a.purchaseDate); // Use exitDate if available, otherwise use purchaseDate
    const dateB = new Date(b.exitDate || b.purchaseDate); // Use exitDate if available, otherwise use purchaseDate

   // Check if either date is invalid
   if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
   if (isNaN(dateA.getTime())) return 1;
   if (isNaN(dateB.getTime())) return -1;


    return dateB - dateA; // Sort in descending order (latest date first)
  });

  return (
    <div>
      {sortedTrades.length !== 0 ? (
          <AnimatePresence mode="wait">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1}}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            
            className=" relative w-full h-[calc(100vh-110px)]  lg:h-[calc(100vh-100px)] overflow-auto custom-scroll">
          <table className={` w-full`}>
            <thead className="bg-[#f5f5f5]  text-sm text-left sticky top-0 z-1 ">
              <tr className="border-b-[1px]   border-[#000000]/10">
                <th className=" text-[12px] min-w-[185px] lg:text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium text-left px-3 py-2 lg:py-3 lg:px-8">
                  Time of Purchase
                </th>
                {/* <th className=" text-[12px] min-w-[185px] lg:text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium text-center px-3 py-2 lg:py-3 lg:px-8">
                  Plan
                </th> */}
                <th className=" text-[12px] min-w-[150px] lg:text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium text-center px-3 py-2 lg:py-3 lg:px-5">
                  Stock Symbol
                </th>
                <th className=" text-[12px] min-w-[150px] lg:w-auto lg:text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 text-center font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                  <div className="flex flex-col ">Qty</div>
                </th>
                <th className=" text-[12px] min-w-[185px] lg:w-auto lg:text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 text-center font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                  <div className="flex flex-col ">Price</div>
                </th>
                <th className=" text-[12px] lg:text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins text-center font-medium px-3 py-2 lg:py-3 lg:px-5">
                  Type
                </th>

                <th className=" text-[12px] lg:text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins text-center font-medium px-3 py-2 lg:py-3 lg:px-5">
                  Status
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedTrades &&
                sortedTrades.map((ele, i) => {
                  return (
                    <tr
                      key={i}
                      className="border-b-[1px]  z-10 border-[#000000]/10"
                    >
                      <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-normal text-left px-3 py-2 lg:py-3 lg:px-8 ">
                        {ele?.Type === "BUY"
                          ? FormatDateTime(ele?.purchaseDate)
                          : ele?.exitDate
                          ? FormatDateTime(ele?.exitDate)
                          : FormatDateTime(ele?.purchaseDate)}
                      </td>
                      {/* <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-normal text-center pter px-3 py-2 lg:py-3 lg:px-5 ">
                        {ele?.Plan}
                      </td> */}
                      <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-normal text-center  px-3 py-2 lg:py-3 lg:px-5 ">
                        {ele?.Symbol}
                      </td>
                      <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-normal text-right   px-3 py-2 lg:py-3 lg:px-5 ">
                        {ele?.tradedQty === 0 ? "-" : ele?.tradedQty}
                      </td>
                      <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-normal text-right  px-3 py-2 lg:py-3 lg:px-5 ">
                        {ele?.Type === "SELL"
                          ? ele?.exitPrice === undefined ||
                            ele?.exitPrice === null ||
                            ele?.exitPrice === 0
                            ? "-"
                            : ele?.exitPrice
                          : ele?.Type === "BUY"
                          ? ele?.tradedPrice === undefined ||
                            ele?.tradedPrice === null ||
                            ele?.tradedPrice === 0
                            ? "-"
                            : ele?.tradedPrice
                          : "-"}{" "}
                      </td>

                      <td
                        className={`text-[15px] capitalize border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-center ter px-3 py-2 lg:py-3 lg:px-5  
                    ${
                      ele?.Type?.toLowerCase() === "buy"
                        ? "text-[#16A085]"
                        : ele?.Type?.toLowerCase() === "sell"
                        ? "text-[#E43D3D] "
                        : "text-[#000000]/80"
                    } 
                    `}
                      >
                        {ele?.Type?.toLowerCase()}
                      </td>

                      <td
                        className={`text-[14px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-center px-3 py-2 lg:py-3 lg:px-5 `}
                      >
                        <div
                          className={`w-[130px] mx-auto px-3 flex items-center justify-center rounded-md py-1.5 capitalize ${
                            ele?.trade_place_status?.toLowerCase() ===
                              "complete" ||
                            ele?.trade_place_status?.toLowerCase() ===
                              "executed" ||
                            ele?.trade_place_status?.toLowerCase() === "placed"
                              ? "text-[#16A085] bg-[#69D4441A] capitalize"
                              : ele?.trade_place_status?.toLowerCase() ===
                                  "open" ||
                                ele?.trade_place_status?.toLowerCase() ===
                                  "requested" ||
                                ele?.trade_place_status?.toLowerCase() ===
                                  "ordered"
                              ? "text-[#D49244] bg-[#F19C371A] capitalize"
                              : ele?.trade_place_status?.toLowerCase() ===
                                "cancelled"
                              ? "text-[#848080] bg-[#A5A3A31A] capitalize"
                              : ele?.trade_place_status?.toLowerCase() ===
                                "rejected"
                              ? "text-[#E43D3D] bg-[#E43D3D1A] capitalize"
                              : ""
                          } `}
                        >
                          {ele?.trade_place_status?.toLowerCase() ===
                            "complete" ||
                          ele?.trade_place_status?.toLowerCase() ===
                            "executed" ||
                          ele?.trade_place_status?.toLowerCase() ===
                            "placed" ? (
                            <CheckIcon className="text-[#16A085] w-4 h-4 mr-2" />
                          ) : ele?.trade_place_status?.toLowerCase() ===
                              "pending" ||
                            ele?.trade_place_status?.toLowerCase() === "open" ||
                            ele?.trade_place_status?.toLowerCase() ===
                              "requested" ||
                            ele?.trade_place_status?.toLowerCase() ===
                              "ordered" ? (
                            <PauseIcon className="text-[#D49244] w-4 h-4 mr-2" />
                          ) : ele?.trade_place_status?.toLowerCase() ===
                            "cancelled" ? (
                            <BanIcon className="text-[#848080] w-4 h-4 mr-2" />
                          ) : ele?.trade_place_status?.toLowerCase() ===
                            "rejected" ? (
                            <XIcon className="text-[#E43D3D] w-4 h-4 mr-2" />
                          ) : (
                            ""
                          )}
                          {/* 
                      <PlaceStockStatus
                        apiKey={apiKey}
                        jwtToken={jwtToken}
                        uniqueorderid={ele.uniqueorderid}
                      />
                    */}
                          <span className="capitalize">
                            {ele?.trade_place_status?.toLowerCase()}
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          </motion.div>
          </AnimatePresence>  
          
        ) : (
        <div className=" w-full h-[calc(100vh-110px)]   lg:h-full overflow-auto custom-scroll">
          <div className="flex flex-col space-y-4  lg:space-y-6 items-center justify-center h-[calc(100vh-180px)]  lg:h-[calc(100vh-120px)]">
            <div className="flex items-center justify-center  w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
              <CandlestickChartIcon className=" w-[50px] h-[50px] lg:w-[60px]   lg:h-[60px]" />
            </div>

            <div className="flex flex-col space-y-3 lg:space-y-3 items-center">
              <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                No Orders Data
              </div>
              <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                Orders that are placed will appear here
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaceOrders;
