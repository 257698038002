import React, { useState } from "react";
import axios from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Mail, Eye, EyeOff } from "lucide-react";
import server from "../../utils/serverConfig";
import { useNavigate } from "react-router-dom";
import { saveTokenInLocalStorage } from "../../services/AuthService";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const AdminSignInEmail = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const signInWithEmail = async () => {
    setLoading(true);
    const auth = getAuth();
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      const userDetails = response?._tokenResponse;
      const token = response.user?.accessToken;

      if (token) {
        try {
          const apiResponse = await axios.post(
            `${server.server.baseUrl}api/admin/allowed/login`,
            {
              email: userDetails.email,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-Advisor-Subdomain": process.env.REACT_APP_URL,
                "aq-encrypted-key": encryptApiKey(
                  process.env.REACT_APP_AQ_KEYS,
                  process.env.REACT_APP_AQ_SECRET
                ),
              },
            }
          );

          if (apiResponse.status === 200) {
            saveTokenInLocalStorage(userDetails, token);
            dispatch(loginConfirmedAction({ ...userDetails, token }));
            navigate("/admin");
          } else {
            throw new Error("Unexpected API response");
          }
        } catch (apiError) {
          setLoading(false);
          toast.error(
            `Login is restricted. For advisor onboarding, contact hello@alphaquark.in.`,
            {
              duration: 5000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "560px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
        }
      } else {
        throw new Error("No token received");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Credential is incorrect. Please check your credentials.`, {
        duration: 5000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "560px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-3 rounded-lg border border-gray-200 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
            placeholder="Enter your email"
          />
        </div>

        <div className="space-y-2">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-3 rounded-lg border border-gray-200 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 pr-12"
              placeholder="Enter your password"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
            >
              {showPassword ? (
                <EyeOff className="w-5 h-5" />
              ) : (
                <Eye className="w-5 h-5" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          onClick={() => props.setShowForgotPassord(true)}
          className="text-sm text-blue-600 hover:text-blue-700"
        >
          Forgot your password?
        </button>
      </div>

      <button
        onClick={signInWithEmail}
        disabled={!email || !password || loading}
        className="w-full flex items-center justify-center space-x-2 py-3 px-4 bg-[#2056DF] text-white rounded-lg hover:bg-black/90 focus:ring-2 focus:ring-offset-2 focus:ring-black transition-all duration-200 disabled:bg-gray-300 disabled:cursor-not-allowed shadow-sm hover:shadow"
      >
        {loading ? (
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          <>
            <Mail className="w-5 h-5" />
            <span>Sign in with Email</span>
          </>
        )}
      </button>
    </div>
  );
};

export default AdminSignInEmail;
