import React from "react";
import IgnoreTrades from "./IgnoreTrades";
import DashboardLayout from "../RootSection/DashboardLayout";

const IgnoreTradesPage = () => {
  return (
    <DashboardLayout>
      <IgnoreTrades />
    </DashboardLayout>
  );
};

export default IgnoreTradesPage;
