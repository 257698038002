import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import TradeFailureCard from "./TradeFailureCard";
import { Toaster } from "react-hot-toast";
import server from "../../utils/serverConfig";

import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import { getAdminDetails } from "../CommonApiCall/getAdminDetails";
import AdminEmptyState from "./AdminEmptyState";
import NoTradeFailureModal from "./NoTradeFailureModal";
import TradeInconsistencyCard from "./TradeInconsistencyCard";
import OpenPositionsTable from "./OpenPositionsTable";
import HoldingsTable from "./HoldingsTable";
import GroupListBlock from "./GroupListBlock";
import SendEmailTemplate from "./SendEmailTemplate";
import AdminDashboardLayout from "../AdminDashboardLayout";
import { motion, AnimatePresence } from "framer-motion";

const style = {
  selected: "text-[14px] lg:text-[16px] text-black font-bold border-b-[3px] border-black cursor-pointer",
  unselected: "font-medium text-[14px] lg:text-[16px] text-[#000000]/40 cursor-pointer",
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox: "w-full px-3 py-3 bg-white text-[16px] peer text-gray-900 placeholder:text-gray-400 placeholder:font-normal font-medium rounded-md mt-1.5 ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] transition ease-in duration-200",
  labelFloat: "absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  selectDiv: "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900 hover:bg-[#D9D9D9] hover:text-gray-100 transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full",
  selected_2: "flex items-center text-[16px] leading-[14px] lg:text-[18px] font-poppins text-black font-semibold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected_2: "flex items-center text-[16px] leading-[14px] font-medium font-poppins lg:text-[18px] text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const AdminHomeSection = () => {
  // All existing environment variables stay the same
  const ccxtUrl = process.env.REACT_APP_CCXT_SERVER_WEBSOCKET_URL;
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString ? JSON.parse(adminDetailsString) : null;
  const adminEmail = adminDetails ? adminDetails.email : null;

  // Move ALL useState declarations to the top, before any conditional returns
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAdminDetails, setIsLoadingAdminDetails] = useState(true);
  const [AdminDetails, setAdminDetails] = useState();
  const [show, setShow] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [tradeFailureModal, setTradeFailureModal] = useState(false);
  const [tradeInconsistencyModal, setTradeInconsistencyModal] = useState(false);
  const [allRecommendations, setAllRecommendations] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [userEmailCount, setUserEmailCount] = useState("");
  const [selectedTab, setSelectedTab] = useState("trade-failure");
  const [selectedPositionTab, setSelectedPositionTab] = useState("holdings");
  const [showFloatAdviceTableModal, setShowFloatAdviceTableModal] = useState(false);
  const [allCsvFileData, setAllCsvFileData] = useState();
  const [allGroupsData, setAllGroupsData] = useState([]);
  const [allClientsData, setAllClientsData] = useState([]);
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [loadingHoldingData, setLoadingHoldingData] = useState(false);
  const [positionsData, setPositionsData] = useState([]);
  const [positionsGroupedData, setPositionsGroupedData] = useState({});
  const [isEmptyState, setIsEmptyState] = useState(true);
  const [openEmailTemplate, setOpenEmailTemplate] = useState(false);

  // Define all derived state
  const userId = AdminDetails?._id;
  const advisorName = AdminDetails?.advisorName;
  const email_password = AdminDetails?.email_password;
  const adminRationale = AdminDetails?.rationale;
  const advisorSpecifier = AdminDetails?.advisorSpecifier;
  const disclaimerData = AdminDetails;  // Add this line
  const ShowTradeInconsistency = advisorSpecifier === "RIA";

  // Data fetching functions
  const getAllGroupsData = useCallback(async (userId) => {
    if (!userId) return;

    try {
      const response = await fetch(
        `${server.server.baseUrl}api/all-groups/${userId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch groups");

      const data = await response.json();
      setAllGroupsData(data.data || []);
    } catch (error) {
      console.error("Error fetching group names:", error);
      setAllGroupsData([]);
    }
  }, []);

  const getAllClientsData = useCallback(async (userId) => {
    if (!userId) return;

    try {
      const response = await fetch(
        `${server.server.baseUrl}api/all-clients/${userId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch client data");

      const data = await response.json();
      setAllClientsData(data.data || []);
    } catch (error) {
      console.error("Error fetching Client data:", error);
      setAllClientsData([]);
    }
  }, []);

  const getAllCsvData = useCallback(() => {
    if (!userId) return;

    axios
      .get(`${server.server.baseUrl}api/csv-upload/${userId}`)
      .then((res) => setAllCsvFileData(res.data.data))
      .catch((error) => {
        console.error("Error fetching CSV data:", error);
        setAllCsvFileData(null);
      });
  }, [userId]);

  const getAllRecommendations = useCallback(() => {
    if (!adminEmail) return;

    axios
      .get(`${server.server.baseUrl}api/trade-history/trade-history-by-trade-given-by?trade_given_by=${adminEmail}`)
      .then((response) => setAllRecommendations(response.data.trades || []))
      .catch((error) => {
        console.error("Error fetching recommendations:", error);
        setAllRecommendations([]);
      });
  }, [adminEmail]);

  const getAllHoldingsData = useCallback(() => {
    if (!adminEmail) return;

    setLoadingHoldingData(true);
    axios
      .get(`${server.server.baseUrl}api/portfolio_admin`, {
        params: { email: adminEmail },
      })
      .then((res) => setData(res.data.filteredData))
      .catch((error) => {
        console.error("Error fetching holdings:", error);
        setData([]);
      })
      .finally(() => setLoadingHoldingData(false));
  }, [adminEmail]);

  const getAllPositionsData = useCallback(() => {
    if (!adminEmail) return;

    axios
      .get(`${server.server.baseUrl}api/positions/admin/specific-admin?email=${adminEmail}`)
      .then((response) => setPositionsData(response.data.data || []))
      .catch((error) => {
        console.error("Error fetching positions:", error);
        setPositionsData([]);
      });
  }, [adminEmail]);

  // Filtered trade calculations
  const filteredInconsistencyTrades = allRecommendations?.filter((trade) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const tradeDate = new Date(trade.date);
    return (
      ((trade.trade_place_status === "Placed" && trade.Quantity !== trade.tradedQty) ||
        (trade.trade_place_status === "complete" && trade.Quantity !== trade.tradedQty) ||
        (trade.trade_place_status === "Executed" && trade.Quantity !== trade.tradedQty)) &&
      tradeDate >= sevenDaysAgo
    );
  });

  const filteredFailuresTrades = allRecommendations?.filter((trade) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const tradeDate = new Date(trade.date);
    return trade.trade_place_status === "rejected" && tradeDate >= sevenDaysAgo;
  });

  const filteredMissedTrades = allRecommendations?.filter((trade) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const tradeDate = new Date(trade.date);
    return trade.trade_place_status === "recommend" && tradeDate >= sevenDaysAgo;
  });

  const inconsistencyTradesEmailCounts = Array.from(
    new Set(filteredInconsistencyTrades?.map((trade) => trade.user_email))
  );

  const failuresTradesEmailCounts = Array.from(
    new Set(filteredFailuresTrades?.map((trade) => trade.user_email))
  );

  const missedTradesEmailCounts = Array.from(
    new Set(filteredMissedTrades?.map((trade) => trade.user_email))
  );

  // WebSocket integration
  const { getLTPForSymbol } = useWebSocketCurrentPrice(
    positionsData.length > 0 ? positionsData : null
  );

  // Effects
  useEffect(() => {
    if (adminEmail) {
      setIsLoadingAdminDetails(true);
      getAdminDetails(adminEmail)
        .then(setAdminDetails)
        .catch(console.error)
        .finally(() => setIsLoadingAdminDetails(false));
    }
  }, [adminEmail]);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsEmptyState(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showComponent) {
      const timer = setTimeout(() => {
        setShowComponent(false);
        setShow(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showComponent]);

  useEffect(() => {
    if (userId) {
      getAllCsvData();
      getAllGroupsData(userId);
      getAllClientsData(userId);
    }
  }, [userId, getAllCsvData, getAllGroupsData, getAllClientsData]);

  useEffect(() => {
    getAllRecommendations();
  }, [getAllRecommendations]);

  useEffect(() => {
    getAllHoldingsData();
  }, [getAllHoldingsData]);

  useEffect(() => {
    getAllPositionsData();
  }, [getAllPositionsData]);

  useEffect(() => {
    const grouped = data.reduce((acc, item) => {
      if (item.holding) {
        const symbol = item.holding.symbol;
        if (!acc[symbol]) acc[symbol] = [];
        acc[symbol].push(item);
      }
      return acc;
    }, {});
    setGroupedData(grouped);
  }, [data]);

  useEffect(() => {
    if (positionsData) {
      const grouped = positionsData.reduce((acc, item) => {
        if (item.Symbol) {
          const symbol = item.Symbol;
          if (!acc[symbol]) acc[symbol] = [];
          acc[symbol].push(item);
        }
        return acc;
      }, {});
      setPositionsGroupedData(grouped);
    }
  }, [positionsData]);

  // Update selected tab based on data
  useEffect(() => {
    if (filteredFailuresTrades?.length > 0) {
      setSelectedTab("trade-failure");
    } else if (filteredInconsistencyTrades?.length > 0) {
      setSelectedTab("trade-inconsistency");
    } else if (filteredMissedTrades?.length > 0) {
      setSelectedTab("missed-trades");
    }
  }, [filteredFailuresTrades, filteredInconsistencyTrades, filteredMissedTrades]);

  // Loading state check
  if (isLoading || isLoadingAdminDetails) {
    return (
      <AdminDashboardLayout>
        <div className="relative rounded-lg w-full min-h-screen bg-[#f9f9f9] py-24 text-3xl font-semibold font-sans flex items-center justify-center">
          <svg
            className="h-10 w-10 text-[#000000] animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </AdminDashboardLayout>
    );
  }


  return (
    <AdminDashboardLayout>
      {isLoading ? (
        <div className="relative rounded-lg  w-full min-h-screen  bg-[#f9f9f9] py-24 text-3xl font-semibold font-sans flex items-center justify-center">
          <svg
            className="h-10 w-10 text-[#000000] animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <>
          {allGroupsData.length !== 0 ? (
            <div className="flex max-h-screen overflow-scroll lg:overflow-hidden  lg:min-h-screen w-full items-center justify-center bg-neutral-200 pt-6 lg:px-10 ">
              <Toaster position="top-center" reverseOrder={true} />
              {isLoading ? (
                <div className="relative rounded-lg  w-full min-h-screen  bg-[#f9f9f9] py-24 text-3xl font-semibold font-sans flex items-center justify-center">
                  <svg
                    className="h-10 w-10 text-[#000000] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                allGroupsData.length > 0 && (
                  <AnimatePresence mode="wait">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="relative flex flex-col  w-full h-full "
                    >
                      {/* Send Advice and Client List  Blocks */}
                      <div className="flex flex-wrap lg:flex-nowrap space-y-4 lg:space-y-0 lg:space-x-4 h-auto lg:h-[330px] ">
                        {/* New Group Block  */}

                        <GroupListBlock
                          userId={userId}
                          allClientsData={allClientsData}
                          allGroupsData={allGroupsData}
                          adminEmail={adminEmail}
                          advisorName={advisorName}
                          email_password={email_password}
                          adminRationale={adminRationale}
                          advisorSpecifier={advisorSpecifier}
                        />

                        {/* Trade  Inconsistency Block  */}

                        {filteredFailuresTrades?.length === 0 &&
                        filteredInconsistencyTrades?.length === 0 &&
                        filteredMissedTrades?.length === 0 ? (
                          <NoTradeFailureModal />
                        ) : (
                          <div className="flex flex-col h-auto lg:h-[330px] w-full lg:w-[55%] bg-[#ffffff]   rounded-lg">
                            <div className="flex space-x-1 overflow-x-auto md:space-x-6 gap-y-3 gap-x-3 w-full border-b-[1px] border-[#000000]/10  h-[50px] xl:h-[50px] px-4  rounded-t-lg ">
                              {filteredFailuresTrades?.length !== 0 ? (
                                <div
                                  className={`${
                                    selectedTab === "trade-failure"
                                      ? style.selected
                                      : style.unselected
                                  } flex font-sans items-center justify-center min-w-[150px] `}
                                  onClick={() => {
                                    setSelectedTab("trade-failure");
                                  }}
                                >
                                  Trade Failures
                                  {filteredFailuresTrades &&
                                    failuresTradesEmailCounts.length > 0 && (
                                      <span
                                        className={`ml-2 flex items-center text-[14px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-6 h-6 rounded-full ${
                                          selectedTab !== "trade-failure"
                                            ? "bg-[#000000]/30"
                                            : "bg-[#E43D3D]"
                                        }`}
                                      >
                                        {filteredFailuresTrades
                                          ? failuresTradesEmailCounts.length
                                          : ""}
                                      </span>
                                    )}
                                </div>
                              ) : (
                                <div
                                  className={`flex font-sans items-center justify-center  font-medium  text-sm xl:text-[16px]  text-[#000000]/40  cursor-not-allowed  `}
                                >
                                  Trade Failures
                                </div>
                              )}

                              {ShowTradeInconsistency && (
                                <>
                                  {filteredInconsistencyTrades?.length !== 0 ? (
                                    <div
                                      className={`${
                                        selectedTab === "trade-inconsistency"
                                          ? ` ${style.selected}`
                                          : ` ${style.unselected}`
                                      } flex font-sans items-center justify-center min-w-[200px] `}
                                      onClick={() => {
                                        setSelectedTab("trade-inconsistency");
                                      }}
                                    >
                                      Trade Inconsistencies
                                      {filteredInconsistencyTrades &&
                                        inconsistencyTradesEmailCounts?.length >
                                          0 && (
                                          <span
                                            className={`ml-2 flex items-center text-[14px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-6 h-6 rounded-full ${
                                              selectedTab !==
                                              "trade-inconsistency"
                                                ? "bg-[#000000]/30"
                                                : "bg-[#E43D3D]"
                                            }`}
                                          >
                                            {filteredInconsistencyTrades
                                              ? inconsistencyTradesEmailCounts?.length
                                              : ""}
                                          </span>
                                        )}
                                    </div>
                                  ) : (
                                    <div
                                      className={`flex font-sans items-center justify-center  font-medium  text-sm xl:text-[16px]  text-[#000000]/40  cursor-not-allowed min-w-[160px]`}
                                    >
                                      Trade Inconsistencies
                                    </div>
                                  )}
                                </>
                              )}

                              {filteredMissedTrades?.length !== 0 ? (
                                <div
                                  className={`${
                                    selectedTab === "missed-trades"
                                      ? ` ${style.selected}`
                                      : ` ${style.unselected}`
                                  } flex font-sans items-center justify-center min-w-[150px]  `}
                                  onClick={() => {
                                    setSelectedTab("missed-trades");
                                  }}
                                >
                                  Missed Trades
                                  {filteredMissedTrades &&
                                    missedTradesEmailCounts?.length > 0 && (
                                      <span
                                        className={`ml-2 flex items-center text-[14px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-6 h-6 rounded-full ${
                                          selectedTab !== "missed-trades"
                                            ? "bg-[#000000]/30"
                                            : "bg-[#E43D3D]"
                                        }`}
                                      >
                                        {filteredMissedTrades
                                          ? missedTradesEmailCounts?.length
                                          : ""}
                                      </span>
                                    )}
                                </div>
                              ) : (
                                <div
                                  className={`flex font-sans items-center justify-center  font-medium  text-sm xl:text-[16px]  text-[#000000]/40  cursor-not-allowed`}
                                >
                                  Missed Trades
                                </div>
                              )}
                            </div>

                            <div className="w-full grid grid-cols-5 items-center px-4 h-[50px] border-b-[1px] border-[#000000]/10 ">
                              <div className="text-[#000000]/60 text-[14px] font-poppins font-medium col-span-1">
                                Issues
                              </div>
                              <div className=" text-[#000000]/60 text-[14px] font-poppins font-medium col-span-3">
                                Email Id
                              </div>
                              <div className=" text-[#000000]/60 text-[14px] font-poppins font-medium col-span-1">
                                Actions
                              </div>
                            </div>

                            {selectedTab === "trade-failure" && (
                              <div className="flex flex-col  h-[calc(330px-110px)] overflow-y-scroll custom-scroll  ">
                                {Array.from(
                                  new Set(
                                    filteredFailuresTrades?.map(
                                      (trade) => trade.user_email
                                    )
                                  )
                                ).map((email, i) => {
                                  const ele = filteredFailuresTrades?.find(
                                    (trade) => trade?.user_email === email
                                  );
                                  const emailCount =
                                    filteredFailuresTrades?.filter(
                                      (trade) => trade?.user_email === email
                                    ).length;
                                  return (
                                    <TradeFailureCard
                                      userEmail={ele?.user_email}
                                      emailCount={emailCount}
                                      tradeFailureModal={tradeFailureModal}
                                      setTradeFailureModal={
                                        setTradeFailureModal
                                      }
                                      selectedTab={selectedTab}
                                      setSelectedTab={setSelectedTab}
                                      key={i}
                                      filteredFailuresTrades={
                                        filteredFailuresTrades &&
                                        filteredFailuresTrades
                                      }
                                      filteredInconsistencyTrades={
                                        filteredInconsistencyTrades &&
                                        filteredInconsistencyTrades
                                      }
                                      filteredMissedTrades={
                                        filteredMissedTrades &&
                                        filteredMissedTrades
                                      }
                                      setSelectedUser={setSelectedUser}
                                      selectedUser={selectedUser}
                                      setUserEmailCount={setUserEmailCount}
                                      userEmailCount={userEmailCount}
                                      advisorSpecifier={advisorSpecifier}
                                      whiteLabelText={whiteLabelText}
                                      setOpenEmailTemplate={
                                        setOpenEmailTemplate
                                      }
                                    />
                                  );
                                })}
                              </div>
                            )}

                            {selectedTab === "trade-inconsistency" && (
                              <div className="flex flex-col  h-[calc(330px-110px)] overflow-y-scroll custom-scroll  ">
                                {Array.from(
                                  new Set(
                                    filteredInconsistencyTrades?.map(
                                      (trade) => trade?.user_email
                                    )
                                  )
                                ).map((email, i) => {
                                  const ele = filteredInconsistencyTrades?.find(
                                    (trade) => trade?.user_email === email
                                  );
                                  const emailCount =
                                    filteredInconsistencyTrades?.filter(
                                      (trade) => trade?.user_email === email
                                    ).length;
                                  return (
                                    <TradeInconsistencyCard
                                      userEmail={ele?.user_email}
                                      emailCount={emailCount}
                                      tradeInconsistencyModal={
                                        tradeInconsistencyModal
                                      }
                                      setTradeInconsistencyModal={
                                        setTradeInconsistencyModal
                                      }
                                      selectedTab={selectedTab}
                                      setSelectedTab={setSelectedTab}
                                      key={i}
                                      filteredFailuresTrades={
                                        filteredFailuresTrades &&
                                        filteredFailuresTrades
                                      }
                                      filteredInconsistencyTrades={
                                        filteredInconsistencyTrades &&
                                        filteredInconsistencyTrades
                                      }
                                      filteredMissedTrades={
                                        filteredMissedTrades &&
                                        filteredMissedTrades
                                      }
                                      setSelectedUser={setSelectedUser}
                                      selectedUser={selectedUser}
                                      userEmailCount={userEmailCount}
                                      setUserEmailCount={setUserEmailCount}
                                      advisorSpecifier={advisorSpecifier}
                                      whiteLabelText={whiteLabelText}
                                      setOpenEmailTemplate={
                                        setOpenEmailTemplate
                                      }
                                    />
                                  );
                                })}
                              </div>
                            )}

                            {selectedTab === "missed-trades" && (
                              <div className="flex flex-col  h-[calc(330px-110px)] overflow-y-scroll custom-scroll  ">
                                {Array.from(
                                  new Set(
                                    filteredMissedTrades?.map(
                                      (trade) => trade?.user_email
                                    )
                                  )
                                ).map((email, i) => {
                                  const ele = filteredMissedTrades?.find(
                                    (trade) => trade?.user_email === email
                                  );
                                  const emailCount =
                                    filteredMissedTrades?.filter(
                                      (trade) => trade?.user_email === email
                                    ).length;
                                  return (
                                    <TradeInconsistencyCard
                                      userName="User-1"
                                      userEmail={ele?.user_email}
                                      emailCount={emailCount}
                                      tradeInconsistencyModal={
                                        tradeFailureModal
                                      }
                                      setTradeInconsistencyModal={
                                        setTradeFailureModal
                                      }
                                      selectedTab={selectedTab}
                                      setSelectedTab={setSelectedTab}
                                      key={i}
                                      filteredFailuresTrades={
                                        filteredFailuresTrades &&
                                        filteredFailuresTrades
                                      }
                                      filteredInconsistencyTrades={
                                        filteredInconsistencyTrades &&
                                        filteredInconsistencyTrades
                                      }
                                      filteredMissedTrades={
                                        filteredMissedTrades &&
                                        filteredMissedTrades
                                      }
                                      setSelectedUser={setSelectedUser}
                                      selectedUser={selectedUser}
                                      userEmailCount={userEmailCount}
                                      setUserEmailCount={setUserEmailCount}
                                      whiteLabelText={whiteLabelText}
                                      setOpenEmailTemplate={
                                        setOpenEmailTemplate
                                      }
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      {/* Open Positions Table  */}

                      <div className="h-full bg-white flex flex-col justify-between mt-4 lg:h-[calc(100vh-370px)] lg:pt-2 lg:mt-4 rounded-lg">
                        <div className=" grid grid-cols-2  md:space-x-6 md:flex flex-row lg:px-8   border-b border-[#000000]/10  h-[50px] lg:h-[50px] font-poppins">
                          <button
                            className={`
                  ${
                    selectedPositionTab === "holdings"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                 px-4 lg:min-w-[120px] justify-center`}
                            onClick={() => {
                              setSelectedPositionTab("holdings");
                            }}
                          >
                            Holdings
                          </button>
                          <button
                            className={`
                  ${
                    selectedPositionTab === "positions"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                 px-4 lg:min-w-[120px] justify-center `}
                            onClick={() => {
                              setSelectedPositionTab("positions");
                            }}
                          >
                            Positions
                          </button>
                        </div>

                        {selectedPositionTab === "holdings" && (
                          <HoldingsTable
                            adminEmail={adminEmail}
                            advisorName={advisorName}
                            email_password={email_password}
                            groupedData={groupedData}
                            data={data}
                            loadingHoldingData={loadingHoldingData}
                            advisorSpecifier={advisorSpecifier}
                          />
                        )}

                        {selectedPositionTab === "positions" && (
                          <div className="flex items-center justify-center h-[calc(100vh-460px)] pb-2">
                            <OpenPositionsTable
                              adminEmail={adminEmail}
                              advisorName={advisorName}
                              email_password={email_password}
                              groupedData={positionsGroupedData}
                              data={positionsData}
                              getLTPForSymbol={getLTPForSymbol}
                              advisorSpecifier={advisorSpecifier}
                            />
                          </div>
                        )}
                      </div>
                    </motion.div>
                  </AnimatePresence>
                )
              )}

              {openEmailTemplate === true ? (
                <SendEmailTemplate
                  whiteLabelText={whiteLabelText}
                  setOpenEmailTemplate={setOpenEmailTemplate}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />
              ) : null}
            </div>
          ) : (
            <>
              {isEmptyState ? (
                <div className="relative rounded-lg  w-full min-h-screen  bg-[#f9f9f9] py-24 text-3xl font-semibold font-sans flex items-center justify-center">
                  <svg
                    className="h-10 w-10 text-[#000000] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <AdminEmptyState
                  adminEmail={adminEmail}
                  disclaimerData={disclaimerData}
                />
              )}
            </>
          )}
        </>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminHomeSection;
