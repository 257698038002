import React, { useState } from "react";
import { X, Plus, Send } from "lucide-react";
import server from "../../../../utils/serverConfig";
import toast from "react-hot-toast";
import ClosePositionPopup from "../EquityAdvice/ClosePositionPopUp";
import ClosureAdvicePopup from "../EquityAdvice/ClosureAdvicePopup";

import TableHeader from "../../TableComponent/TableHeader";
import TableRow from "../../TableComponent/TableRow";

import { encryptApiKey } from "../../../../utils/cryptoUtils";

const StickyFooter = ({
  selectedAdvices = new Set(),
  handleSendClosureAdvice,
}) => {
  return (
    <div className="px-6 py-4 w-full border-t bg-gray-50 sticky bottom-0 z-10 flex items-center">
      <div className="flex items-center space-x-4">
        <button
          onClick={handleSendClosureAdvice}
          className={`px-4 py-2 text-white rounded-md transition-colors flex items-center ${
            selectedAdvices.size === 0
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={selectedAdvices.size === 0}
        >
          <Send className="w-4 h-4 mr-2" />
          Send closure advice
        </button>
        <span className="text-md text-gray-600 font-medium">
          {selectedAdvices.size} advice{selectedAdvices.size !== 1 ? "s" : ""}{" "}
          selected
        </span>
      </div>
    </div>
  );
};

const DerivativeAdvice = ({
  onClose,
  combinedAdviceData,
  emailData,
  fetchAdviceData,
  isLoading,
  errorMessage,
  setCombinedAdviceData,
  exchange,
  getPageNumbers,
  goToPage,
  tabData,
}) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const advisorSpecifier = process.env.REACT_APP_ADVISOR_SPECIFIER;

  const [selectedAdvices, setSelectedAdvices] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [adviceTypes, setAdviceTypes] = useState({});
  const [adviceExchanges, setAdviceExchanges] = useState({});
  const [emailPopupOpen, setEmailPopupOpen] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [orderTypes, setOrderTypes] = useState({});
  const [productTypes, setProductTypes] = useState({});
  const [rationales, setRationales] = useState({});
  const [comments, setComments] = useState({});
  const [comments2, setComments2] = useState({});
  const [mergedData, setMergedData] = useState({});
  const [loading, setLoading] = useState(false);

  const [closePositionPopup, setClosePositionPopup] = useState({
    isOpen: false,
    type: null,
    advice: null,
  });
  const [isClosureAdvicePopupOpen, setIsClosureAdvicePopupOpen] =
    useState(false);
  const handleSendClosureAdvice = () => {
    setIsClosureAdvicePopupOpen(true);
  };

  const mergeEmailData = (emailData) => {
    const mergedData = {};

    Object.keys(emailData).forEach((adviceId) => {
      if (!mergedData[adviceId]) {
        mergedData[adviceId] = [];
      }
      mergedData[adviceId] = [...mergedData[adviceId], ...emailData[adviceId]];
    });

    return mergedData;
  };
  const mergedEmails = mergeEmailData(emailData);

  const handleCheckboxChange = (id, adviceIndex) => {
    setSelectedAdvices((prevSelected) => {
      const newSelected = new Set(prevSelected);
      const key = `${id}-${adviceIndex}`;

      // Find the related advice data
      const item = combinedAdviceData.find((item) => item.id === id);
      if (!item) return prevSelected;

      const advice = item.advices[adviceIndex];
      if (!advice || advice.closurestatus === "fullClose") {
        return prevSelected; // Skip selection
      }

      if (newSelected.has(key)) {
        newSelected.delete(key);
        // Remove merged data if the checkbox is unchecked
        setMergedData((prevMergedData) => {
          const newMergedData = { ...prevMergedData };
          delete newMergedData[key];
          return newMergedData;
        });
      } else {
        newSelected.add(key);

        const emailData = item?.emails?.find(
          (email) => email?.advice_reco_id === id
        );

        // Merge the advice data with the email data
        const mergedAdviceData = {
          ...advice,
          ...emailData,
          symbol: advice.symbol,
          type: adviceTypes[key] || advice.type,
          price: advice.price_when_send_advice,
          exchange: adviceExchanges[key] || advice.exchange,
          date: advice.date,
          quantity: quantities[key] || advice.quantity,
          order_type: orderTypes[key] || advice.order_type || advice.orderType,
          product_type:
            productTypes[key] || advice.product_type || advice.productType,
          rationale: rationales[key] || advice.rationale,
          comments: comments[key] || advice.comments,
          comment2: comments2[key] || advice.comment2,
        };

        // Remove old `orderType` and `productType` keys
        delete mergedAdviceData.orderType;
        delete mergedAdviceData.productType;

        // Store the merged data
        setMergedData((prevMergedData) => ({
          ...prevMergedData,
          [key]: mergedAdviceData,
        }));
      }

      setIsAllSelected(
        newSelected.size ===
          combinedAdviceData.reduce((sum, item) => sum + item.advices.length, 0)
      );

      return newSelected;
    });
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedAdvices(new Set());
    } else {
      const allKeys = new Set(
        combinedAdviceData.flatMap(
          (item) =>
            item.advices
              .map((advice, index) =>
                advice?.closurestatus !== "fullClose"
                  ? `${item?.id}-${index}`
                  : null
              )
              .filter(Boolean) // Remove null values (i.e., fullClose advices)
        )
      );
      setSelectedAdvices(allKeys);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleClosePosition = (id, adviceIndex, type) => {
    const key = `${id}-${adviceIndex}`;
    setSelectedAdvices((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (!newSelected.has(key)) {
        newSelected.add(key);
      }
      return newSelected;
    });

    // Find the advice data
    const item = combinedAdviceData.find((item) => item.id === id);
    const advice = item.advices[adviceIndex];

    // Merge the advice data
    const mergedAdviceData = {
      ...advice,
      symbol: advice.symbol,
      type: adviceTypes[key] || advice.type,
      price: advice.price_when_send_advice,
      exchange: adviceExchanges[key] || advice.exchange,
      date: advice.date,
      quantity: quantities[key] || advice.quantity,
      order_type: orderTypes[key] || advice.order_type || advice.orderType,
      product_type:
        productTypes[key] || advice.product_type || advice.productType,
      rationale: rationales[key] || advice.rationale,
      comments: comments[key] || advice.comments,
      comment2: comments2[key] || advice.comment2,
    };

    setMergedData((prevMergedData) => ({
      ...prevMergedData,
      [key]: mergedAdviceData,
    }));

    setClosePositionPopup({
      isOpen: true,
      type,
      selectedAdvices: [mergedAdviceData],
    });
  };

  const handleConfirmClosePosition = async (type, selectedAdvices) => {
    try {
      const formattedSelectedAdvices = selectedAdvices.map(
        (advice, index) => `${advice?.advice_reco_id}-${index}` // Ensure id-index format
      );
      await handleSendAdvices({
        closurestatus: type === "full" ? "fullClose" : "partialClose",
        selectedAdvices: formattedSelectedAdvices, // Ensure keys match mergedData
      });

      setClosePositionPopup({ isOpen: false, type: null, selectedAdvices: [] });
    } catch (error) {
      console.error("Error closing positions:", error);
    }
  };

  const handleSendAdvices = async (options = {}) => {
    setLoading(true);
    try {
      const selectedAdvicesArray = Array.from(
        options.selectedAdvices || selectedAdvices
      );

      console.log("");
      const uniqueAdviceIds = [
        ...new Set(
          selectedAdvicesArray
            .map((key) => mergedData[key]?.advice_reco_id)
            .filter(Boolean)
        ),
      ];

      const emailResponses = await Promise.all(
        uniqueAdviceIds.map(async (id) => {
          const response = await fetch(
            `${server.ccxtServer.baseUrl}comms/reco/sent-emails/${id}`,
            {
              headers: {
                "Content-Type": "application/json",
                "X-Advisor-Subdomain": process.env.REACT_APP_URL,
                "aq-encrypted-key": encryptApiKey(
                  process.env.REACT_APP_AQ_KEYS,
                  process.env.REACT_APP_AQ_SECRET
                ),
              },
            }
          );
          return { id, data: await response.json() };
        })
      );

      // Step 3: Convert the array of responses into a lookup object
      const emailDataMap = Object.fromEntries(
        emailResponses.map(({ id, data }) => [id, data.sentReco || []])
      );

      // Step 4: Process selected advices
      const selectedAdvicesData = selectedAdvicesArray
        .map((key) => {
          const advice = mergedData[key];
          if (!advice) {
            console.error(`Advice not found for ID: ${key}`);
            return null;
          }

          // Get the emails for this `advice_reco_id` from the lookup object
          const emails = emailDataMap[advice.advice_reco_id] || [];

          const baseAdvice = {
            email: "",
            Symbol: advice.symbol,
            Exchange: advice.exchange,
            Type: advice.type,
            OrderType: advice.order_type,
            ProductType: advice.product_type,
            Segment: advice.segment,
            Price: advice.price,
            date: advice.date,
            Quantity: advice.quantity,
            Advised_Range_Lower: advice.Advised_Range_Lower || 0,
            Advised_Range_Higher: advice.Advised_Range_Higher || 0,
            rationale: advice.rationale,
            comments: advice.comments,
            comment2: advice.comment2,
            advisorType: advisorSpecifier || "",
            price_when_send_advice: advice.price_when_send_advice,
            group: advice.group,
            advice_id: advice.advice_reco_id,
            closurestatus: options.closurestatus || null,
          };

          return emails.length === 0
            ? [baseAdvice]
            : emails.map((email) => ({
                ...baseAdvice,
                email: email?.email,
                userName: email?.clientName || "",
                phoneNumber: email?.phone || "",
                country_code: email?.country_code || "91",
                advisor_name: advisorTag,
              }));
        })
        .flat()
        .filter(Boolean); // Remove null values

      console.log("Final Payload:", selectedAdvicesData);

      // Step 5: Stop if there's no valid data
      if (selectedAdvicesData.length === 0) {
        setLoading(false);
        toast.error("No valid advices found to send!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        return;
      }

      // Step 6: Send the API request
      const config = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}comms/send-reco`,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
        data: selectedAdvicesData,
      };

      // await axios.request(config);
      setLoading(false);

      toast.success("Advice has been sent successfully", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#4CAF50",
          secondary: "#FFFAEE",
        },
      });

      setTimeout(() => {
        setIsClosureAdvicePopupOpen(false);
        setSelectedAdvices(new Set());
      }, 2000);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Error in sending Advice!", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      throw error;
    }
  };

  const selectedAdvicesData = Array.from(selectedAdvices).map((key) => {
    const [id, index] = key.split("-");
    return mergedData[key];
  });

  const handleOpenEmailPopup = (id) => {
    setEmailPopupOpen(id);
  };

  const handleCloseEmailPopup = () => {
    setEmailPopupOpen(null);
  };

  const handleAddNewEmail = (id, newEmailToAdd) => {
    if (newEmailToAdd.trim()) {
      setCombinedAdviceData((prevData) => {
        return prevData.map((item) => {
          if (item.id === id) {
            const emailExists = item.emails.some(
              (email) => email.trade_given_by === newEmailToAdd
            );
            if (!emailExists) {
              return {
                ...item,
                emails: [
                  ...item.emails,
                  {
                    trade_given_by: newEmailToAdd,
                    date: new Date().toISOString(),
                  },
                ],
              };
            }
          }
          return item;
        });
      });
    } else {
    }
  };

  const handleRemoveEmail = (id, emailToRemove) => {
    setCombinedAdviceData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            emails: item.emails.filter(
              (email) => email.trade_given_by !== emailToRemove
            ),
          };
        }
        return item;
      });
    });
  };

  const handleTypeChange = (id, index, newType) => {
    setAdviceTypes((prevTypes) => ({
      ...prevTypes,
      [`${id}-${index}`]: newType,
    }));
  };

  const handleExchangeChange = (id, index, newExchange) => {
    setAdviceExchanges((prevExchanges) => ({
      ...prevExchanges,
      [`${id}-${index}`]: newExchange,
    }));
  };

  const handleResetType = (id, index) => {
    setAdviceTypes((prevTypes) => {
      const newTypes = { ...prevTypes };
      delete newTypes[`${id}-${index}`];
      return newTypes;
    });
  };

  const handleResetExchange = (id, index) => {
    setAdviceExchanges((prevExchanges) => {
      const newExchanges = { ...prevExchanges };
      delete newExchanges[`${id}-${index}`];
      return newExchanges;
    });
  };

  const handleQuantityChange = (id, index, newQuantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [`${id}-${index}`]: newQuantity,
    }));
  };

  const handleResetQuantity = (id, index) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      delete newQuantities[`${id}-${index}`];
      return newQuantities;
    });
  };

  const handleOrderTypeChange = (id, index, newOrderType) => {
    setOrderTypes((prevOrderTypes) => ({
      ...prevOrderTypes,
      [`${id}-${index}`]: newOrderType,
    }));
  };

  const handleResetOrderType = (id, index) => {
    setOrderTypes((prevOrderTypes) => {
      const newOrderTypes = { ...prevOrderTypes };
      delete newOrderTypes[`${id}-${index}`];
      return newOrderTypes;
    });
  };

  const handleProductTypeChange = (id, index, newProductType) => {
    setProductTypes((prevProductTypes) => ({
      ...prevProductTypes,
      [`${id}-${index}`]: newProductType,
    }));
  };

  const handleResetProductType = (id, index) => {
    setProductTypes((prevProductTypes) => {
      const newProductTypes = { ...prevProductTypes };
      delete newProductTypes[`${id}-${index}`];
      return newProductTypes;
    });
  };

  const handleRationaleChange = (id, index, newRationale) => {
    setRationales((prevRationales) => ({
      ...prevRationales,
      [`${id}-${index}`]: newRationale,
    }));
  };

  const handleResetRationale = (id, index) => {
    setRationales((prevRationales) => {
      const newRationales = { ...prevRationales };
      delete newRationales[`${id}-${index}`];
      return newRationales;
    });
  };

  const handleCommentChange = (id, index, newComment) => {
    setComments((prevComments) => ({
      ...prevComments,
      [`${id}-${index}`]: newComment,
    }));
  };

  const handleResetComment = (id, index) => {
    setComments((prevComments) => {
      const newComments = { ...prevComments };
      delete newComments[`${id}-${index}`];
      return newComments;
    });
  };

  const handleComment2Change = (id, index, newComment2) => {
    setComments2((prevComments2) => ({
      ...prevComments2,
      [`${id}-${index}`]: newComment2,
    }));
  };

  const handleResetComment2 = (id, index) => {
    setComments2((prevComments2) => {
      const newComments2 = { ...prevComments2 };
      delete newComments2[`${id}-${index}`];
      return newComments2;
    });
  };

  const getEmailsByAdviceId = (adviceId) => {
    return mergedEmails[adviceId] || []; // Return emails for the given ID, or an empty array if not found
  };

  const EmailPopup = ({
    isOpen,
    onClose,
    emails,
    onAddEmail,
    onRemoveEmail,
  }) => {
    const [newPopupEmail, setNewPopupEmail] = useState("");

    const handleAddEmail = (e) => {
      e.preventDefault();
      if (newPopupEmail.trim()) {
        onAddEmail(newPopupEmail.trim());
        setNewPopupEmail("");
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg w-96">
          <h2 className="text-xl font-bold mb-4">Manage Emails</h2>
          <div className="space-y-4">
            {emails.map((email, index) => (
              <div key={index} className="flex items-center justify-between">
                <span>
                  {email.trade_given_by}
                  {email.count > 1 && ` (${email.count})`}
                </span>
                <button
                  onClick={() => onRemoveEmail(email.trade_given_by)}
                  className="text-red-500 hover:text-red-700"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            ))}
            <form
              onSubmit={handleAddEmail}
              className="flex items-center space-x-2"
            >
              <input
                type="email"
                value={newPopupEmail}
                onChange={(e) => setNewPopupEmail(e.target.value)}
                placeholder="Add new email"
                className="flex-grow px-2 py-1 border rounded"
                required
              />
              <button
                type="submit"
                className="bg-black text-white p-1 rounded hover:bg-gray-800"
              >
                <Plus className="h-4 w-4" />
              </button>
            </form>
          </div>
          <button
            onClick={onClose}
            className="mt-4 w-full bg-gray-200 text-gray-800 py-2 rounded hover:bg-gray-300"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-screen bg-white rounded-xl shadow-xl overflow-hidden flex flex-col font-sans">
      <div className="flex items-center justify-between px-6 py-4 border-b bg-gray-50 sticky top-0 z-10">
        <h2 className="text-xl font-bold text-gray-400 font-poppins">
          Close any of your open equity advices
        </h2>
        <button
          onClick={onClose}
          className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-200 rounded-full transition-colors font-poppins"
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      <div className="flex-1 overflow-auto">
        {isLoading ? (
          <div className="w-full flex text-center justify-center py-12">
            <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
          </div>
        ) : combinedAdviceData?.length === 0 ||
          !combinedAdviceData.some((item) =>
            item?.advices?.some((advice) => exchange.includes(advice?.exchange))
          ) ? (
          <div className="text-center font-bold text-xl py-12">
            No Data Available
          </div>
        ) : (
          <table className="w-full">
            <TableHeader
              isAllSelected={isAllSelected}
              handleSelectAllChange={handleSelectAllChange}
            />
            <TableRow
              combinedAdviceData={combinedAdviceData}
              handleCheckboxChange={handleCheckboxChange}
              selectedAdvices={selectedAdvices}
              handleClosePosition={handleClosePosition}
              adviceTypes={adviceTypes}
              handleTypeChange={handleTypeChange}
              quantities={quantities}
              handleQuantityChange={handleQuantityChange}
              orderTypes={orderTypes}
              handleOrderTypeChange={handleOrderTypeChange}
              handleResetOrderType={handleResetOrderType}
              EmailPopup={EmailPopup}
              emailPopupOpen={emailPopupOpen}
              handleCloseEmailPopup={handleCloseEmailPopup}
              handleAddNewEmail={handleAddNewEmail}
              handleRemoveEmail={handleRemoveEmail}
              handleOpenEmailPopup={handleOpenEmailPopup}
              handleResetQuantity={handleResetQuantity}
              handleResetType={handleResetType}
              exchange={["NFO", "BFO"]}
              getEmailsByAdviceId={getEmailsByAdviceId}
            />
          </table>
        )}

        {errorMessage && (
          <div className="flex items-center justify-center h-full font-poppins text-3xl text-gray-400">
            {errorMessage}
          </div>
        )}
      </div>
      <div className="flex justify-center mt-2 space-x-2 pb-3">
        <button
          onClick={() => goToPage(tabData.currentPage - 1)}
          disabled={tabData.currentPage === 1}
          className="px-3 py-1 border rounded disabled:opacity-50"
        >
          Previous
        </button>

        {getPageNumbers().map((page, index) =>
          page === "..." ? (
            <span key={index} className="px-3 py-1">
              ...
            </span>
          ) : (
            <button
              key={index}
              onClick={() => goToPage(page)}
              className={`px-3 py-1 border rounded ${
                tabData.currentPage === page ? "bg-blue-500 text-white" : ""
              }`}
            >
              {page}
            </button>
          )
        )}

        <button
          onClick={() => goToPage(tabData.currentPage + 1)}
          disabled={tabData.currentPage === tabData.totalPages}
          className="px-3 py-1 border rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
      <ClosePositionPopup
        isOpen={closePositionPopup.isOpen}
        onClose={() =>
          setClosePositionPopup({
            isOpen: false,
            type: null,
            selectedAdvices: [],
          })
        }
        onConfirm={handleConfirmClosePosition}
        type={closePositionPopup.type}
        selectedAdvices={closePositionPopup.selectedAdvices}
        loading={loading}
      />
      <ClosureAdvicePopup
        isOpen={isClosureAdvicePopupOpen}
        onClose={() => setIsClosureAdvicePopupOpen(false)}
        selectedAdvices={selectedAdvicesData}
        onSend={handleSendAdvices}
      />
      <StickyFooter
        selectedAdvices={selectedAdvices}
        onSendAdvices={() => handleSendAdvices()}
        handleSendClosureAdvice={handleSendClosureAdvice}
      />
    </div>
  );
};

export default DerivativeAdvice;
