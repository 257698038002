"use client";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  LogOutIcon,
  HistoryIcon,
  LightbulbIcon,
  FileBarChartIcon,
  ChevronRightIcon,
  CircleAlertIcon,
  BanIcon,
  XIcon,
  MenuIcon,
  CandlestickChartIcon,
  UserCog2Icon,
  GitForkIcon,
  ChevronLeftIcon,
  SettingsIcon,
  Wallet2Icon,
  HandCoins,
  FolderClock,
} from "lucide-react";
import CryptoJS from "crypto-js";

import Logo from "../../assests/Logo.jpg";
import { cn } from "../../utils/utils";
import { auth } from "../../firebase";
import formatCurrency from "../../utils/formatCurrency";
import { fetchFunds } from "../../FunctionCall/fetchFunds";

const Icons = {
  home: <FileBarChartIcon className="mr-5 h-[18px] w-[18px]" />,
  home2: <FileBarChartIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  advice: <LightbulbIcon className="mr-5 h-[18px] w-[18px]" />,
  advice2: <LightbulbIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  history: <HistoryIcon className="mr-5 h-[18px] w-[18px]" />,
  history2: <HistoryIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  sub: <SettingsIcon className="mr-5 h-[18px] w-[18px]" />,
  sub2: <SettingsIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  ignore: <BanIcon className="mr-5 h-[18px] w-[18px]" />,
  ignore2: <BanIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  userRound: <UserCog2Icon className="mr-5 h-[18px] w-[18px]" />,
  userRound2: <UserCog2Icon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  order: <CandlestickChartIcon className="mr-5 h-[18px] w-[18px]" />,
  order2: <CandlestickChartIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  strategy: <GitForkIcon className="mr-5 h-[18px] w-[18px] -rotate-90" />,
  strategy2: <GitForkIcon className="mr-0 ml-1 h-[24px] w-[24px] -rotate-90" />,
  pricing: <HandCoins className="mr-5 h-[18px] w-[18px] " />,
  pricing2: <HandCoins className="mr-0 ml-1 h-[24px] w-[24px] " />,

  previousOrders: <FolderClock className="mr-5 h-[16px] w-[16px] " />,
  previousOrders2: <FolderClock className="mr-5 h-[16px] w-[16px] " />,
};

const sideBarNavItems = [
  {
    title: "Live Portfolio",
    href: "/user",
    path: "/user",
    icon: "home",
    icon2: "home2",
    tooltip: "Portfolio",
  },
  {
    title: "Advice",
    href: "/stock-recommendation",
    path: "stock-recommendation",
    icon: "advice",
    icon2: "advice2",
    tooltip: "Advice",
  },
  {
    title: "Order Details",
    href: "/order-details",
    path: "order-details",
    icon: "order",
    icon2: "order2",
    tooltip: "Order Details",
  },
  {
    title: "Exited Trade History",
    href: "/trade-history",
    path: "trade-history",
    icon: "history",
    icon2: "history2",
    tooltip: "Trade History",
  },
  {
    title: "Broker Settings",
    href: "/subscriptions",
    path: "subscriptions",
    icon: "sub",
    icon2: "sub2",
    tooltip: "Broker Settings",
  },
  {
    title: "Model Portfolio",
    href: "/model-portfolio",
    path: "model-portfolio",
    icon: "strategy",
    icon2: "strategy2",
    tooltip: "Model Portfolio",
  },
  {
    title: "Pricing",
    href: "/pricing",
    path: "pricing",
    icon: "pricing",
    icon2: "pricing2",
    tooltip: "Advisor",
  },

  {
    title: "My Invoices",
    href: "/previous-orders",
    path: "previous-orders",
    icon: "previousOrders",
    icon2: "previousOrders2",
    tooltip: "Previous Orders",
  },

  {
    title: "Contact RA",
    href: "/contact-advisor",
    path: "contact-advisor",
    icon: "userRound",
    icon2: "userRound2",
    tooltip: "Advisor",
  },
  // {
  //   title: "Ignored Trades",
  //   href: "/ignore-trades",
  //   path: "ignore-trades",
  //   icon: "ignore",
  //   icon2: "ignore2",
  //   tooltip: "Ignore Trades",
  // },
];

const DashboardNav = ({
  openUserProfileModal,
  openStepGuideModal,
  userDetails,
  adviceTradeData,
  ignoreTradesData,
  isExpanded,
  toggleExpand,
}) => {
  const [showLogoutModel, setShowLogoutModel] = useState(false);
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const path = window.location.pathname;

  var initials;
  var fullName = userDetails && userDetails.name;
  initials =
    fullName &&
    fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

  // const filteredNavItems = sideBarNavItems.filter((item) => {
  //   if (userDetails && userDetails.user_broker === "Zerodha") {
  //     return item.title !== "Exited Trade History";
  //   }
  //   return true;
  // });

  const circumference = 2 * Math.PI * 20; // Adjust radius as needed

  const profileCompletion =
    userDetails && typeof userDetails.profile_completion === "number"
      ? userDetails.profile_completion
      : 0;

  const strokeDashoffset =
    circumference - (profileCompletion / 100) * circumference;

  const clientCode = userDetails && userDetails && userDetails.clientCode;
  const apiKey = userDetails && userDetails?.apiKey;
  const broker = userDetails && userDetails?.user_broker;
  const jwtToken = userDetails && userDetails?.jwtToken;
  const sid = userDetails && userDetails?.sid;
  const serverId = userDetails && userDetails?.serverId;
  const secretKey = userDetails && userDetails.secretKey;

  const checkValidApiAnSecret = (details) => {
    try {
      const bytesKey = CryptoJS.AES.decrypt(details, "ApiKeySecret");
      const Key = bytesKey.toString(CryptoJS.enc.Utf8); // Convert to UTF-8 string

      if (Key) {
        return Key;
      } else {
        throw new Error("Decryption failed or invalid key.");
      }
    } catch (error) {
      console.error("Error during decryption:", error.message);
      return null;
    }
  };

  const [funds, setFunds] = useState(null);

  const getAllFunds = async () => {
    const fetchedFunds = await fetchFunds(
      broker,
      clientCode,
      apiKey,
      jwtToken,
      secretKey,
      sid,
      serverId
    );

    if (fetchedFunds) {
      setFunds(fetchedFunds);
    } else {
      console.error("Failed to fetch funds.");
    }
  };
  useEffect(() => {
    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getAllFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);
  const [isWrapped, setIsWrapped] = useState(false);

  useEffect(() => {
    const checkWrapping = () => {
      const container = document.getElementById("cash-balance-container");
      const label = document.getElementById("cash-balance-label");
      const value = document.getElementById("cash-balance-value");

      if (container && label && value) {
        setIsWrapped(container.offsetHeight > label.offsetHeight);
      }
    };

    checkWrapping();
    window.addEventListener("resize", checkWrapping);
    return () => window.removeEventListener("resize", checkWrapping);
  }, []);

  return (
    <div
      className={`sticky top-0 hidden lg:flex h-screen bg-black ${
        isExpanded ? "w-[300px]" : "w-[65px]"
      } transition-width duration-300 ease-in-out`}
    >
      <aside className="max-h-screen w-full flex flex-col lg:flex bg-black border-r-[1px] border-[#2056DF]/30">
        <div
          className={`w-full flex flex-row items-center    ${
            isExpanded ? "px-6 py-3" : "pl-[10px] py-6"
          } transition-all duration-300 ease-in-out`}
        >
          {isExpanded ? (
            <div className="w-full flex items-center justify-between">
              <div className="flex space-x-2">
                <img
                  onClick={toggleExpand}
                  src={Logo || "/placeholder.svg"}
                  alt="Logo"
                  className="rounded-md w-[40px] h-[40px]"
                />
                <div
                  className="text-lg font-medium  text-[#f2f2f2] leading-[40px] transition-all duration-500 ease-in-out"
                  style={{
                    maxWidth: isExpanded ? "200px" : "0",
                    opacity: isExpanded ? 1 : 0,
                    transform: `translateX(${isExpanded ? "0" : "-20px"})`,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {" "}
                  {whiteLabelText}
                </div>
              </div>
              <div
                onClick={toggleExpand}
                className=" flex items-center text-white  cursor-pointer"
              >
                <ChevronLeftIcon
                  strokeWidth={3}
                  className="h-6 w-6 text-white/80 "
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-3">
              <div
                onClick={toggleExpand}
                className=" flex items-center text-white  cursor-pointer"
              >
                <MenuIcon className="h-[30px] w-[30px] text-white/80 " />
              </div>
              <img
                src={Logo || "/placeholder.svg"}
                alt="Logo"
                className="mx-auto rounded-md w-[36px] h-[36px]"
              />
            </div>
          )}
        </div>
        <nav
          className={`bg-black grid items-start max-h-[500px] h-auto pb-[110px] custom-scroll-sidebar ${
            isExpanded ? "gap-y-0 overflow-y-scroll" : "gap-y-1"
          }`}
        >
          {sideBarNavItems.map((item, index) => {
            const Icon = Icons[item.icon];
            const Icon2 = Icons[item.icon2];

            return (
              item.href && (
                <div
                  key={index}
                  className="transition-all duration-300 ease-in-out"
                  style={{
                    opacity: isExpanded ? 1 : 0.7,
                    transform: isExpanded
                      ? "translateY(0) translateX(0)"
                      : "translateY(10px) translateX(-10px)",
                  }}
                >
                  {" "}
                  <Link
                    className=""
                    key={index}
                    to={item.disabled ? "/" : item.href}
                  >
                    <div
                      className={cn(
                        "group relative text-sm border-l-4 hover:bg-[#2056DF]/10 hover:text-white text-[15px] text-zinc-400 font-normal  leading-[22px] flex items-center ",
                        path === item.href || path.startsWith(`/${item.path}`)
                          ? "bg-[#2056DF]/10 border-l-4 border-[#2056DF] text-white"
                          : "border-l-4 border-transparent bg-transparent",
                        item.disabled && "cursor-not-allowed opacity-80",
                        isExpanded
                          ? "px-[24px] py-[10px]"
                          : "pl-[20px] py-[10px]"
                      )}
                    >
                      {isExpanded ? Icon : Icon2}
                      <span
                        className={`ml-2 transition-all duration-300  ease-in-out ${
                          isExpanded
                            ? "opacity-100 translate-x-0"
                            : "opacity-0 -translate-x-2 absolute"
                        }`}
                      >
                        {isExpanded ? item.title : ""}
                      </span>

                      {isExpanded &&
                        item.icon === "advice" &&
                        adviceTradeData &&
                        adviceTradeData.length >= 1 && (
                          <span className="ml-2 px-1.5 py-0.5 text-xs font-semibold rounded-full bg-blue-600 text-white">
                            {adviceTradeData && adviceTradeData.length}
                          </span>
                        )}

                      {isExpanded &&
                        item.icon === "ignore" &&
                        ignoreTradesData &&
                        ignoreTradesData.length >= 1 && (
                          <span className="ml-3 text-[#2056DF] font-medium">
                            ({ignoreTradesData && ignoreTradesData.length})
                          </span>
                        )}
                      {!isExpanded && (
                        <div className="absolute hidden group-hover:flex min-w-[90px] left-[0px] -top-[22px] items-center justify-center px-2 py-1 rounded-md text-[10px] font-medium bg-[#2056DF] text-white">
                          {item.tooltip}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )
            );
          })}

          <div
            onClick={() => setShowLogoutModel(true)}
            className={cn(
              "w-full group relative cursor-pointer text-sm border-l-4 border-transparent bg-transparent hover:bg-[#2056DF]/10 hover:text-white text-[15px] text-zinc-400 font-normal  leading-[22px] flex items-center ",
              isExpanded ? "px-[24px] py-[10px]" : "pl-[12px] py-[22px]"
            )}
          >
            <LogOutIcon
              className={`${
                isExpanded
                  ? "h-[18px] w-[18px] mr-7"
                  : "h-[24px] w-[24px] mr-0 ml-1"
              } text-zinc-400 group-hover:text-white`}
            />
            <span>{isExpanded ? "Logout" : ""}</span>
            {!isExpanded && (
              <div className="absolute hidden group-hover:flex min-w-[90px] left-[0px] -top-[22px] items-center justify-center px-2 py-1 rounded-md text-[10px] font-medium bg-[#2056DF] text-white">
                Logout
              </div>
            )}
          </div>
        </nav>

        {isExpanded &&
          userDetails &&
          userDetails.profile_completion !== 100 && (
            <div className="absolute bottom-[46px]  flex flex-col space-y-[10px] border-t-[1px] border-[#333]/50 w-full px-4 py-[8px] bg-black">
              {broker && broker !== "" && (
                <div
                  id="cash-balance-container"
                  className="flex flex-wrap items-start  bg-[#111] py-2.5  px-4"
                  style={{
                    opacity: isExpanded ? 1 : 0,
                    transform: isExpanded
                      ? "translateY(0)"
                      : "translateY(-10px)",
                    maxHeight: isExpanded ? "100px" : "0",
                    overflow: "hidden",
                  }}
                >
                  <div
                    id="cash-balance-label"
                    className="flex items-center text-[14px] font-medium text-white  w-[120px] flex-shrink-0 mr-4 mb-1"
                  >
                    <div className="flex flex-row items-center min-w-max text-[14px] font-medium text-white ">
                      <Wallet2Icon className="text-zinc-400 h-5 w-5 mr-2 flex-shrink-0" />
                      <span className="whitespace-nowrap">Cash Balance</span>
                    </div>


                  


                  </div>
                  <div
                    className={`flex-1 flex ${
                      isWrapped ? "ml-1 justify-start" : " justify-end"
                    } items-start w-full sm:w-auto`}
                  >
                    <span
                      id="cash-balance-value"
                      className={`
              ${
                funds?.data?.availablecash > 0
                  ? " text-[#16A085] "
                  : funds?.data?.availablecash < 0
                  ? " text-[#EA2D3F]"
                  : "text-white"
              }
                flex items-center text-[14px] font-medium `}
                    >
                      {funds?.data?.availablecash &&
                      !isNaN(funds?.data?.availablecash) ? (
                        <>
                          ₹
                          {formatCurrency(
                            Number.parseFloat(
                              funds?.data?.availablecash
                            ).toFixed(2)
                          )}
                        </>
                      ) : (
                        "NaN"
                      )}
                    </span>
                  </div>

                  <div className="flex flex-row ml-7 items-center min-w-max text-[14px] font-medium text-white">
                      <span className="  text-zinc-300 font-poppins text-[11px] font-bold "> ({broker})</span>
                    </div>
                </div>
              )}
                  
                  
              <div className="flex justify-between items-center w-full hover:cursor-pointer">
                <div className="flex space-x-3 items-center text-white text-center">
                  <div
                    onClick={openUserProfileModal}
                    className="relative flex items-center justify-center rounded-full"
                  >
                    <svg className="transform -rotate-90 w-[50px] h-[50px]">
                      <circle
                        cx="25"
                        cy="25"
                        r="20"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="transparent"
                        className="text-[#333]"
                      />
                      <circle
                        cx="25"
                        cy="25"
                        r="20"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="transparent"
                        className="text-[#2056DF]"
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                      />
                    </svg>
                    <div className="absolute flex items-center justify-center rounded-full">
                      {userDetails &&
                      userDetails?.image_url &&
                      userDetails?.image_url !== "" &&
                      userDetails?.image_url !== null ? (
                        <div className="flex flex-row justify-center w-full">
                          <img
                            src={userDetails?.image_url || "/placeholder.svg"}
                            alt="Logo"
                            className="rounded-full w-[35px] h-[35px]"
                          />
                        </div>
                      ) : (
                        <div className="flex font-medium  text-[20px] items-center justify-center rounded-full text-white">
                          {initials ||
                            userDetails?.email.slice(0, 2).toUpperCase()}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-start max-w-[200px]">
                    <span className="text-sm text-[#F2F2F2] font-medium truncate w-full text-left">
                      {userDetails && userDetails.name
                        ? userDetails.name
                        : userDetails?.email.split("@")[0]}
                    </span>
                    <span className="text-xs text-white font-normal  truncate w-full">
                      {userDetails && userDetails.email}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-1 flex flex-col  space-y-3 w-full px-2">
                <button
                  onClick={openUserProfileModal}
                  className="w-[180px] flex items-center justify-center py-1 px-4 rounded-[4px] text-white font-medium text-[14px]  bg-black border border-[#2056DF] hover:bg-[#2056DF]/90 transition-colors"
                >
                  Complete Profile
                  <ChevronRightIcon className="h-5 w-5 text-white ml-1" />
                </button>
              </div>
            </div>
          )}

        {!isExpanded &&
          userDetails &&
          userDetails.profile_completion !== 100 && (
            <div className="absolute bottom-2  flex  items-center pl-2  text-white text-center mb-2">
              <div
                onClick={openUserProfileModal}
                className="relative flex items-center   justify-center rounded-full  "
              >
                <svg className="transform -rotate-90 w-[50px] h-[50px] ">
                  <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="transparent"
                    className="text-gray-200"
                  />
                  <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="transparent"
                    className="text-[#16A085]"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                  />
                </svg>
                <div className="absolute flex items-center justify-center rounded-full ">
                  {userDetails &&
                  userDetails?.image_url &&
                  userDetails?.image_url !== "" &&
                  userDetails?.image_url !== null ? (
                    <div className=" flex flex-row justify-center w-full">
                      <img
                        src={userDetails?.image_url || "/placeholder.svg"}
                        alt="Logo"
                        className="rounded-full w-[35px] h-[35px]"
                      />
                    </div>
                  ) : (
                    <div className="flex font-medium  text-[20px] items-center  justify-center rounded-full  text-white">
                      {initials || userDetails?.email.slice(0, 2).toUpperCase()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

        {isExpanded &&
          userDetails &&
          userDetails.profile_completion === 100 && (
            <div className="absolute bottom-[46px] flex flex-col space-y-3 w-full border-t-[1px] border-[#333]/50 py-3 px-6 bg-black">
              {broker && broker !== "" && (
                <div className="flex items-center justify-between bg-[#111] py-2.5 rounded-md space-x-4 px-4">
                  <div className="flex flex-row items-center min-w-max text-[14px] font-medium text-white ">
                    <Wallet2Icon className="text-[#2056DF] h-5 w-5 mr-2 flex-shrink-0" />
                    <span className="whitespace-nowrap">Cash Balance</span>
                  </div>
                  <span
                    className={`
              ${
                funds?.data?.availablecash > 0
                  ? " text-[#16A085] "
                  : funds?.data?.availablecash < 0
                  ? " text-[#EA2D3F]"
                  : "text-white"
              }
                flex items-center text-[14px] font-medium `}
                  >
                    ₹
                    {funds?.data?.availablecash
                      ? formatCurrency(
                          Number.parseFloat(funds?.data?.availablecash).toFixed(
                            2
                          )
                        )
                      : 0}
                  </span>
                </div>
              )}
              <div className="flex justify-between items-center w-full hover:cursor-pointer">
                <div className="flex space-x-3 items-center text-white text-center">
                  {userDetails &&
                  userDetails?.image_url &&
                  userDetails?.image_url !== "" &&
                  userDetails?.image_url !== null ? (
                    <div
                      className="flex items-center justify-center rounded-full ring-[3px] ring-[#2056DF] w-[40px] h-[40px] cursor-pointer overflow-hidden flex-shrink-0"
                      onClick={openUserProfileModal}
                    >
                      <img
                        src={userDetails?.image_url || "/placeholder.svg"}
                        alt="Profile Image"
                        className="rounded-full w-full h-full object-cover"
                      />
                    </div>
                  ) : (
                    <div
                      className="flex font-medium  text-[20px] items-center justify-center rounded-full border-[2px] border-[#2056DF] w-[40px] h-[40px] cursor-pointer flex-shrink-0"
                      onClick={openUserProfileModal}
                    >
                      {initials || userDetails?.email.slice(0, 2).toUpperCase()}
                    </div>
                  )}
                  <div className="flex flex-col items-start max-w-[200px]">
                    <span className="text-[15px] text-[#F2F2F2]  font-medium">
                      {userDetails && userDetails.name
                        ? userDetails.name
                        : userDetails?.email.split("@")[0]}
                    </span>
                    <span className="text-[13px] text-[#D9D9D9]/60  font-normal">
                      {userDetails && userDetails.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

        {!isExpanded &&
          userDetails &&
          userDetails.profile_completion === 100 && (
            <div className="w-full absolute bottom-2  flex  items-center justify-center   text-white text-center mb-2">
              {userDetails &&
              userDetails?.image_url &&
              userDetails?.image_url !== "" &&
              userDetails?.image_url !== null ? (
                <div
                  onClick={openUserProfileModal}
                  className=" cursor-pointer  flex items-center justify-center rounded-full ring-[3px] ring-[#16A085]  w-[40px] h-[40px] p-1 "
                >
                  <img
                    src={userDetails?.image_url || "/placeholder.svg"}
                    alt="Profile Image"
                    width={40}
                    className="rounded-full"
                  />
                </div>
              ) : (
                <div
                  onClick={openUserProfileModal}
                  className="cursor-pointer flex font-medium  text-[20px] items-center  justify-center rounded-full border-[2px] border-[#16A085] w-[40px] h-[40px] p-1"
                >
                  {initials || userDetails?.email.slice(0, 2).toUpperCase()}
                </div>
              )}
            </div>
          )}

        <div
          className={`
    absolute 
    ${
      userDetails && userDetails.profile_completion === 100
        ? "bottom-[8px]"
        : "bottom-2"
    }
    w-full bg-black border-t-[1px] border-[#333]/50 
    overflow-hidden transition-all duration-300 ease-in-out
  `}
          style={{
            maxHeight: isExpanded ? "40px" : "0",
            opacity: isExpanded ? 1 : 0,
            padding: isExpanded ? "8px 16px" : "0 16px",
          }}
        >
          <div
            className={`flex items-center text-[#ffffff]/70 text-[11px]  leading-[22px] font-normal
             transition-all duration-300 ease-in-out
      `}
            style={{
              transform: `translateY(${isExpanded ? "0" : "10px"})`,
              opacity: isExpanded ? 1 : 0,
            }}
          >
            {" "}
            <CircleAlertIcon className="text-[#2056DF] h-4 mr-1" />
            How to use {whiteLabelText}
            <Link
              onClick={openStepGuideModal}
              className="ml-2 text-[#2056DF] text-[11px] font-normal "
            >
              Click here
            </Link>
          </div>
        </div>

        {showLogoutModel && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <div className="relative">
              <div
                className="absolute right-2 top-4 text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer"
                onClick={() => setShowLogoutModel(false)}
              >
                <XIcon className="w-5 h-5 mr-2" />
              </div>
              <div className="w-[480px] px-12 py-8 bg-white border-[#000000]/20 rounded-md shadow-lg">
                <div className="text-[20px] text-[#000000] font-medium  text-center">
                  Are you sure you want to logout?
                </div>
                <div className="mt-8 text-[18px] font-normal  text-center">
                  <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                    <div
                      onClick={() => setShowLogoutModel(false)}
                      className="flex items-center justify-center bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium  rounded-md px-8 py-2 cursor-pointer hover:bg-gray-100 transition-colors"
                    >
                      Cancel
                    </div>
                    <div
                      onClick={() => auth.signOut()}
                      className="flex items-center justify-center bg-[#2056DF] text-white text-[18px] font-medium  rounded-md px-8 py-2 cursor-pointer hover:bg-[#2056DF]/90 transition-all duration-150 ease-linear"
                    >
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </aside>
    </div>
  );
};

export default DashboardNav;
