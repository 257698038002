const ProgressCircle = ({ percentage }) => {
  const rotationDegree = (percentage / 100) * 360; // Calculate rotation based on percentage

  return (
    <div className="relative w-4 h-4 flex items-center justify-center mr-2">
      <div
        className="absolute rounded-full w-4 h-4"
        style={{
          border: "2px solid transparent",
          borderRadius: "50%",
          background: `conic-gradient(
            #4CAF50 ${rotationDegree}deg,
            #D1D5DB ${rotationDegree}deg 360deg
          )`,
          WebkitMask:
            "radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0)",
          mask: "radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0)",
        }}
      />
    </div>
  );
};

export default ProgressCircle;
