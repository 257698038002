import React from "react";
import DashboardLayout from "../RootSection/DashboardLayout";
import OrderBookDetails from "./OrderBookDetails";

const OrderBookDetailsPage = ({ getAllTrades }) => {
  return (
    <DashboardLayout>
      <OrderBookDetails getAllTradesUpdate={getAllTrades} />
    </DashboardLayout>
  );
};

export default OrderBookDetailsPage;
