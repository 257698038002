import React from "react";
import { X as XIcon, Circle as CircleIcon, CheckCircle } from "lucide-react";
import moment from "moment";
import { useLocation } from "react-router-dom";

const RebalanceTimeLineModal = ({
  closeRebalanceTimelineModal,
  strategyDetails,
  setResearchReportModal,
  setStoreModelId,
}) => {
  const location = useLocation();
  const isAdminStrategyPage = location.pathname.includes(
    "admin/admin-strategy"
  );

  const rebalanceTimelineData = strategyDetails?.model?.rebalanceHistory
    .sort((a, b) => new Date(a.rebalanceDate) - new Date(b.rebalanceDate))
    .reverse()
    .map((rebalance, index, array) => {
      const date = moment(rebalance.rebalanceDate).format("MMM D, YYYY");
      const reversedIndex = array.length - index;
      return {
        text: `Rebalance ${reversedIndex}`,
        date: date,
        modelId: rebalance.model_Id,
        name: rebalance?.updatedModelName,
        link: rebalance?.rr_link_mpf,
        complete: true,
      };
    });

  rebalanceTimelineData?.unshift({
    text: "Next Rebalance",
    date:
      strategyDetails?.frequency === "Need Basis"
        ? "Need Basis"
        : moment(strategyDetails?.nextRebalanceDate).format("MMM D, YYYY"),
    complete: false,
    name: "",
    modelId: null,
  });

  rebalanceTimelineData?.push({
    text: "Strategy Went Live",
    date: moment(strategyDetails?.created_at).format("MMM D, YYYY"),
    complete: true,
    name: "",
    modelId: null,
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/60 backdrop-blur-sm z-50 p-4">
      <div className="relative w-full max-w-2xl bg-white rounded-xl shadow-xl">
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b border-gray-100">
          <h2 className="text-xl font-semibold text-gray-900">
            Rebalance Timeline
          </h2>
          <button
            onClick={closeRebalanceTimelineModal}
            className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100 transition-colors"
          >
            <XIcon className="w-5 h-5" />
          </button>
        </div>

        {/* Timeline Content */}
        <div className="relative p-6 max-h-[70vh] overflow-y-auto">
          <div className="space-y-6">
            {rebalanceTimelineData.map((item, index) => {
              const isLatestTwo =
                index > 0 && index < rebalanceTimelineData.length - 1;
              const researchLink = item.link
                ? item.link.startsWith("http")
                  ? item.link
                  : `https://${item.link}`
                : null;
              return (
                <div
                  key={index}
                  className="relative flex group items-start gap-4 pb-6 last:pb-0"
                >
                  {/* Vertical Line */}
                  {index !== rebalanceTimelineData.length - 1 && (
                    <div className="absolute left-[11px] top-6 bottom-0 w-px bg-gray-200 group-last:hidden" />
                  )}

                  {/* Status Icon */}
                  <div className="relative flex-shrink-0 mt-1">
                    {item.complete ? (
                      <div className="rounded-full bg-emerald-500 text-white p-0.5">
                        <CheckCircle className="w-5 h-5" />
                      </div>
                    ) : (
                      <div className="rounded-full border-2 border-gray-300 p-0.5">
                        <CircleIcon className="w-5 h-5 text-gray-300" />
                      </div>
                    )}
                  </div>

                  {/* Content */}
                  <div className="flex-1 flex items-center justify-between min-h-[2rem]">
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-gray-900">
                        {item.text}
                      </span>
                      <span className="text-sm text-gray-500 mt-0.5">
                        {item.date}
                      </span>
                    </div>

                    {item.complete && (
                      <div className="flex flex-col">
                        <div className="flex flex-row justify-center items-center text-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800">
                          Complete
                        </div>
                        {isAdminStrategyPage && isLatestTwo && (
                          <div>
                            {item.link ? (
                              <a
                                href={researchLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mt-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-600 text-white cursor-pointer"
                              >
                                View Link
                              </a>
                            ) : null}

                            <div
                              className="ml-2 mt-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gradient-to-r from-blue-600 to-blue-700 text-white cursor-pointer"
                              onClick={() => {
                                setResearchReportModal(true);
                                setStoreModelId({
                                  modelId: item?.modelId,
                                  name: item?.name,
                                });
                              }}
                            >
                              Update Research Report
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {!item.complete && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        Upcoming
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RebalanceTimeLineModal;
