import React, { useState, useEffect, useMemo } from "react";
import VolatilityIcon from "../../assests/volatility.svg";
import Alpha100 from "../../assests/alpha-100.png";
import { Pie, PieChart } from "recharts";
import {
  CandlestickChart,
  Share2,
  Timer,
  TrendingUp,
  ChevronRight,
  BarChart3,
  History,
} from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";
import { Card, CardContent } from "../../components/ui/card";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import moment from "moment";
import RebalanceModal from "./RebalanceModal";
import RebalanceTimeLineModal from "./RebalanceTimeLineModal";
import Breadcrumb from "../../components/Breadcrumbs/Breadcrumbs";
import server from "../../utils/serverConfig";
import { components } from "react-select";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";
import ResearchReportModal from "./ResearchReportModal";

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;

const StrategyDetails = ({ fileName, ele }) => {
  const [selectedDataTab, setSelectedDataTab] = useState("distribution");
  const [showRebalanceModal, setShowRebalanceModal] = useState(false);
  const [showRebalanceTimelineModal, setShowRebalanceTimelineModal] =
    useState(false);
  const [yearPerformace, setYearPerformance] = useState();
  const [cagrCalculate, setCagrCalculate] = useState();
  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);

  const openRebalanceModal = () => setShowRebalanceModal(true);
  const closeRebalanceModal = () => setShowRebalanceModal(false);
  const openRebalanceTimelineModal = () => setShowRebalanceTimelineModal(true);
  const closeRebalanceTimelineModal = () =>
    setShowRebalanceTimelineModal(false);

  const getStrategyDetails = () => {
    if (fileName) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${fileName?.replaceAll(
            /_/g,
            " "
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        )
        .then((res) => {
          const portfolioData = res.data[0].originalData;
          setStrategyDetails(portfolioData);
          if (
            portfolioData &&
            portfolioData.model &&
            portfolioData.model.rebalanceHistory.length > 0
          ) {
            const latest = portfolioData.model.rebalanceHistory.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            )[0];
            setLatestRebalance(latest);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  const getCagrValue = async () => {
    const data = { advisor: advisorTag, modelName: ele?.name };
    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}rebalance/performance/cagr`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      setCagrCalculate(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getPerformaceValue = async () => {
    const data = { advisor: advisorTag, modelName: ele?.name };
    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}rebalance/performance/2y_returns`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      setYearPerformance(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCagrValue();
    getPerformaceValue();
  }, []);

  const colorPalette = [
    "#EAE7DC",
    "#F5F3F4",
    "#D4ECDD",
    "#FFDDC1",
    "#F8E9A1",
    "#B2C9AB",
    "#FFC8A2",
    "#F6BD60",
    "#CB997E",
    "#A5A58D",
    "#B7CADB",
    "#E2F0CB",
    "#C1D37F",
    "#FFEBBB",
    "#D3C4C4",
    "#D4A5A5",
    "#FFF3E2",
    "#F7B7A3",
    "#EFD6AC",
    "#FAE3D9",
    "#BCD4DE",
    "#B7B6C1",
    "#F9F3DF",
    "#E5D9B6",
    "#D5C6E0",
    "#FFE5D9",
    "#A5C4D4",
    "#F8EAD1",
    "#FDE8D7",
    "#DFD3C3",
  ];

  const { chartData, chartConfig, colorMap } = useMemo(() => {
    const colorMap = {};
    const data =
      latestRebalance?.adviceEntries?.map((entry, index) => {
        const color = colorPalette[index % colorPalette.length];
        colorMap[entry.symbol] = color;
        return {
          shares: entry.symbol,
          value: entry.value * 100,
          fill: color,
        };
      }) || [];

    const config = {
      value: { label: "Equity Distribution" },
      ...data.reduce((acc, entry) => {
        acc[entry.shares] = {
          label: entry.shares,
          color: entry.fill,
        };
        return acc;
      }, {}),
    };

    return { chartData: data, chartConfig: config, colorMap };
  }, [latestRebalance]);

  const coinBreadcrumbsList = [
    { title: "All Strategy", link: "/admin/admin-strategy" },
    { title: fileName, link: "" },
  ];

  const MetricCard = ({ icon: Icon, label, value, className = "" }) => (
    <div
      className={`flex items-center space-x-3 p-4 rounded-lg bg-white border border-gray-100 ${className}`}
    >
      <div className="p-2 rounded-full bg-blue-50">
        <Icon className="h-5 w-5 text-blue-600" />
      </div>
      <div>
        <p className="text-sm text-gray-600">{label}</p>
        <p className="text-base font-semibold text-gray-900">{value}</p>
      </div>
    </div>
  );

  const StatsCard = ({ label, value, isPositive = true }) => (
    <div className="flex flex-col items-center justify-center p-4 bg-gradient-to-br from-emerald-50 to-white rounded-lg border border-emerald-100">
      <p className="text-sm text-gray-600 font-medium">{label}</p>
      <p
        className={`text-xl font-bold ${
          isPositive ? "text-emerald-600" : "text-gray-900"
        }`}
      >
        {value}
      </p>
    </div>
  );

  const [openResearchReportModal, setResearchReportModal] = useState(false);
  const [researchReport, setResearchReport] = useState("");
  const [researchLoading, setResearchLoading] = useState(false);
  const [storeModelId, setStoreModelId] = useState({});

  const updateResearchReportLink = () => {
    if (storeModelId?.modelId) {
      setResearchLoading(true);
      const researchReportPayload = {
        link: researchReport,
        advisor: advisorTag,
        model_name: storeModelId?.name,
      };
      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}comms/mpf-research-report/${storeModelId?.modelId}`,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
        data: researchReportPayload, // Send the JSON object directly
      };
      axios
        .request(config2)
        .then((response) => {
          console.log("res", response);
          setResearchLoading(false);
          setResearchReportModal(false);
        })
        .catch((err) => {
          console.log("err", err);
          setResearchLoading(false);
        });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white w-full">
      <Toaster position="top-center" reverseOrder={true} />

      <div className="w-full max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <Breadcrumb breadcrumbsList={coinBreadcrumbsList} />

        <div className="grid grid-cols-12 gap-6 mt-6">
          {/* Main Content */}
          <div className="col-span-12 lg:col-span-8 xl:col-span-9 space-y-6">
            {/* Header */}
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="flex items-center space-x-4">
                <img
                  src={
                    strategyDetails?.image
                      ? `${server.server.baseUrl}${strategyDetails.image}`
                      : Alpha100
                  }
                  alt={fileName}
                  className="h-16 w-16 rounded-lg object-cover"
                />
                <div className="flex-1">
                  <h1 className="text-2xl font-bold text-gray-900">
                    {fileName?.replaceAll(/_/g, " ")}
                  </h1>
                  <p className="text-gray-600 mt-1">
                    {strategyDetails?.overView}
                  </p>
                </div>
                <button className="p-2 hover:bg-gray-100 rounded-full">
                  <Share2 className="h-5 w-5 text-gray-600" />
                </button>
              </div>
            </div>

            {/* Tabs and Content */}
            <div className="bg-white rounded-xl shadow-sm">
              <div className="border-b border-gray-200">
                <div className="flex space-x-6 px-6">
                  {[
                    { id: "distribution", label: "Distribution" },
                    { id: "whyStrategy", label: "Why this Strategy?" },
                    { id: "methodology", label: "Methodology" },
                  ].map((tab) => (
                    <button
                      key={tab.id}
                      className={`py-4 px-2 relative ${
                        selectedDataTab === tab.id
                          ? "text-blue-600 font-semibold"
                          : "text-gray-500 hover:text-gray-700"
                      }`}
                      onClick={() => setSelectedDataTab(tab.id)}
                    >
                      {tab.label}
                      {selectedDataTab === tab.id && (
                        <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600" />
                      )}
                    </button>
                  ))}
                </div>
              </div>

              <div className="p-6">
                {selectedDataTab === "distribution" && (
                  <div className="space-y-6">
                    <div className="flex justify-between items-center">
                      <p className="text-gray-600">
                        Check Latest Rebalance Updates issued by the Manager.
                        <button
                          onClick={openRebalanceTimelineModal}
                          className="ml-2 text-blue-600 hover:underline"
                        >
                          View Rebalance History
                        </button>
                      </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <MetricCard
                        icon={History}
                        label="Last Rebalance"
                        value={moment(strategyDetails?.last_updated).format(
                          "MMM DD, YYYY"
                        )}
                      />
                      <MetricCard
                        icon={Timer}
                        label="Next Rebalance"
                        value={moment(
                          strategyDetails?.nextRebalanceDate
                        ).format("MMM DD, YYYY")}
                      />
                      <MetricCard
                        icon={BarChart3}
                        label="Rebalance Frequency"
                        value={strategyDetails?.frequency}
                      />
                    </div>

                    <div className="grid lg:grid-cols-2 gap-6">
                      <div className="bg-white rounded-lg shadow-sm p-4">
                        <h3 className="text-lg font-semibold mb-4">
                          Portfolio Distribution
                        </h3>
                        <div className="space-y-2">
                          {latestRebalance?.adviceEntries?.map(
                            (entry, index) => (
                              <div
                                key={index}
                                className="flex items-center justify-between p-3 rounded-lg"
                                style={{
                                  backgroundColor: `${
                                    colorMap[entry.symbol]
                                  }20`,
                                }}
                              >
                                <div className="flex items-center space-x-3">
                                  <div
                                    className="w-3 h-3 rounded-full"
                                    style={{
                                      backgroundColor: colorMap[entry.symbol],
                                    }}
                                  />
                                  <span className="font-medium">
                                    {entry.symbol}
                                  </span>
                                </div>
                                <span className="font-semibold">
                                  {parseFloat(entry.value * 100).toFixed(2)}%
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      <ChartContainer
                        config={chartConfig}
                        className="aspect-square bg-white rounded-lg shadow-sm p-4"
                      >
                        <PieChart>
                          <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent hideLabel />}
                          />
                          <Pie
                            data={chartData}
                            dataKey="value"
                            nameKey="shares"
                            innerRadius="60%"
                            outerRadius="80%"
                          />
                        </PieChart>
                      </ChartContainer>
                    </div>
                  </div>
                )}

                {selectedDataTab === "whyStrategy" && (
                  <div className="prose max-w-none">
                    <p className="text-gray-600 leading-relaxed">
                      {strategyDetails?.whyThisStrategy}
                    </p>
                  </div>
                )}

                {selectedDataTab === "methodology" && (
                  <div className="space-y-8">
                    {[
                      {
                        title: "Defining the universe",
                        content: strategyDetails?.definingUniverse,
                      },
                      {
                        title: "Research",
                        content: strategyDetails?.researchOverView,
                      },
                      {
                        title: "Constituent Screening",
                        content: strategyDetails?.constituentScreening,
                      },
                      {
                        title: "Weighting",
                        content: strategyDetails?.weighting,
                      },
                      {
                        title: "Rebalance",
                        content: strategyDetails?.rebalanceMethodologyText,
                      },
                      ...(strategyDetails?.assetAllocationText
                        ? [
                            {
                              title: "Asset Allocation",
                              content: strategyDetails?.assetAllocationText,
                            },
                          ]
                        : []),
                    ].map((section, index) => (
                      <div key={index} className="space-y-2">
                        <h3 className="text-xl font-semibold text-gray-900">
                          {section.title}
                        </h3>
                        <p className="text-gray-600 leading-relaxed">
                          {section.content}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Sidebar */}
          <div className="hidden lg:block lg:col-span-4 xl:col-span-3">
            <Card className="sticky top-6 w-full">
              <CardContent className="p-6 space-y-6">
                <button
                  onClick={openRebalanceModal}
                  className="w-full py-3 px-4 bg-gradient-to-r from-blue-600 to-blue-700
                           text-white rounded-lg font-medium shadow-sm hover:from-blue-700
                           hover:to-blue-800 transition-all duration-200"
                >
                  Add New Rebalance
                </button>

                <div className="grid grid-cols-2 gap-4">
                  <StatsCard
                    label="CAGR"
                    value={
                      cagrCalculate?.cagr ? `+${cagrCalculate.cagr}%` : "TBC"
                    }
                  />
                  <StatsCard
                    label="Sharpe Ratio"
                    value={
                      yearPerformace?.performace
                        ? `+${yearPerformace.performace}%`
                        : "TBC"
                    }
                  />
                </div>

                <div className="space-y-4">
                  <MetricCard
                    icon={TrendingUp}
                    label="Volatility"
                    value="TBC"
                  />
                  <MetricCard
                    icon={CandlestickChart}
                    label="Strategy Score"
                    value="TBC"
                  />
                  <MetricCard
                    icon={Timer}
                    label="Duration"
                    value={`${
                      strategyDetails?.frequency === "Every Week" ||
                      strategyDetails?.frequency === "Need Basis" ||
                      strategyDetails?.frequency === "Every Day"
                        ? "Short"
                        : strategyDetails?.frequency === "Every Month"
                        ? "Medium"
                        : "Long"
                    } Terms`}
                  />
                  <MetricCard
                    icon={History}
                    label="No. of Rebalances"
                    value={
                      strategyDetails?.model?.rebalanceHistory?.length || 0
                    }
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {/* Mobile Action Button */}
      <div className="lg:hidden fixed bottom-0 left-0 right-0 p-4 bg-white shadow-lg">
        <button
          onClick={openRebalanceModal}
          className="w-full py-3 bg-gradient-to-r from-blue-600 to-blue-700
                   text-white rounded-lg font-medium shadow-sm"
        >
          Add New Rebalance
        </button>
      </div>

      {/* Modals */}
      {showRebalanceModal && (
        <RebalanceModal
          closeRebalanceModal={closeRebalanceModal}
          fileName={fileName}
          data={latestRebalance?.adviceEntries}
          strategyDetails={strategyDetails}
          server={server}
          getStrategyDetails={getStrategyDetails}
        />
      )}
      {showRebalanceTimelineModal && (
        <RebalanceTimeLineModal
          closeRebalanceTimelineModal={closeRebalanceTimelineModal}
          strategyDetails={strategyDetails}
          openResearchReportModal={openResearchReportModal}
          setResearchReportModal={setResearchReportModal}
          researchReport={researchReport}
          setResearchReport={setResearchReport}
          setStoreModelId={setStoreModelId}
        />
      )}
      {openResearchReportModal && (
        <ResearchReportModal
          researchReport={researchReport}
          setResearchReport={setResearchReport}
          setResearchReportModal={setResearchReportModal}
          researchLoading={researchLoading}
          updateResearchReportLink={updateResearchReportLink}
          storeModelId={storeModelId}
        />
      )}
    </div>
  );
};

export default StrategyDetails;
