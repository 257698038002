import React, { useEffect, useState } from "react";
import { GitForkIcon, HandCoins } from "lucide-react";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import moment from "moment";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";
import { Loader2 } from "lucide-react";
import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import PlanSubscribeModal from "./PlanSubscribeModal";
import PaymentSuccessModal from "./PaymentSuccessModal";
import UserStrategySubscribeModal from "../Strategy/UserStrategySubscribeModal";
import RecommendationSuccessModal from "../StockRecommendation/RecommendationSuccessModal";
import ConnectBroker from "../LivePortfolioSection/connectBroker";
import TokenExpireBrokarModal from "../RootSection/TokenExpireBrokarModal";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import PortfolioCard from "./PortfolioCard"; // Adjust the import path as needed
import BespokeCard from "./BespokeCard";
import ModelPFCardDetailsSection from "../ModelPortfolioSection/ModelPFCardDetailsSection";
import DigioModal from "../../components/DigioModel";
// import clientResponse from "assert"; // Adjust the path as needed
import { encryptApiKey } from "../../utils/cryptoUtils";

const rootUrl = process.env.REACT_APP_URL;
const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
const razorPayKey = process.env.REACT_APP_RAZORPAY_LIVE_API_KEY;
const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
const digioCheck = process.env.REACT_APP_DIGIO_CHECK;
const advisorName = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;

const PricingPage = () => {
  const navigate = useNavigate(); // Added this near the top of the component

  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState();

  const [showAfterPlaceOrderDdpiModal, setShowAfterPlaceOrderDdpiModal] =
    useState(false);
  // Implement handleViewMore for performance view

  function handleViewMore(name) {
    if (!name) return;
    navigate(`/model-portfolio/${name.toLowerCase().replace(/ /g, "_")}`);
  }

  // Add this near your other handler functions at the top
  const handlePerformanceClick = (name, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!name) return;
    navigate(`/model-portfolio/${name.toLowerCase().replace(/ /g, "_")}`);
  };

  const getUserDetails = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      })
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserDetails();
  }, [userEmail, server.server.baseUrl]);

  const [broker, setBroker] = useState("");

  const clientCode = userDetails && userDetails?.clientCode;
  const apiKey = userDetails && userDetails?.apiKey;
  const jwtToken = userDetails && userDetails?.jwtToken;
  const my2pin = userDetails && userDetails?.my2Pin;
  const secretKey = userDetails && userDetails?.secretKey;
  const userId = userDetails && userDetails?._id;

  const [name, setName] = useState(userDetails ? userDetails?.name : null);
  const [mobileNumber, setMobileNumber] = useState(
    userDetails ? userDetails?.phone_number : null
  );
  const [panNumber, setPanNumber] = useState(
    userDetails ? userDetails?.panNumber : null
  );

  const [birthDate, setBirthDate] = useState(
    userDetails ? userDetails?.DateofBirth : null
  );
  const [telegramId, setTelegramId] = useState(
    userDetails ? userDetails?.telegram_id : ""
  );
  const dateString = userDetails && userDetails.token_expire;
  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");
  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
      setMobileNumber(userDetails?.phone_number);
      setPanNumber(userDetails?.panNumber);
      setName(userDetails?.name);
      setBirthDate(userDetails?.DateofBirth);
      setTelegramId(userDetails?.telegram_id);
    }
  }, [userDetails]);

  const [brokerModel, setBrokerModel] = useState(false);
  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();
  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(null);

  const checkValidApiAnSecret = (details) => {
    try {
      const bytesKey = CryptoJS.AES.decrypt(details, "ApiKeySecret");
      const Key = bytesKey.toString(CryptoJS.enc.Utf8); // Convert to UTF-8 string

      if (Key) {
        return Key;
      } else {
        throw new Error("Decryption failed or invalid key.");
      }
    } catch (error) {
      console.error("Error during decryption:", error.message);
      return null;
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const [openSubscribeModel, setOpenSubscribeModel] = useState(false);
  const [allPricingPlan, setAllPricingPlan] = useState([]);

  const getAllPlans = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/admin/plan/${advisorTag}/bespoke/${userEmail}`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setAllPricingPlan(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const [modelPortfolioPricingPlan, setModelPortfolioPricingPlan] = useState(
    []
  );

  const getAllPortfolioPlans = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/admin/plan/${advisorTag}/model portfolio/${userEmail}`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("---", response);
        setModelPortfolioPricingPlan(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllPortfolioPlans();
  }, []);

  const [isLoadingPlan, setIsLoadingPlan] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingPlan(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [paymentModal, setPaymentModal] = useState(false);
  const [specificPlan, setSpecificPlan] = useState();
  const [modelSpecificStrategy, setModelSpecificStrategy] = useState(null);

  // Update handlePricingCardClick to handle both new subscriptions and renewals
  const handlePricingCardClick = (modelName) => {
    setPaymentModal(true);
    setSpecificPlan(modelName);
    setModelSpecificStrategy(modelName?.name);
  };

  const [isPostPaymentProcessing, setIsPostPaymentProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedCard, setSelectedCard] = useState(null); // To track the selected card
  const [inputValue, setInputValue] = useState(""); // To track input field value
  const [strategyDetails, setStrategyDetails] = useState(null);

  const getStrategyDetails = () => {
    if (specificPlan) {
      axios
        .get(
          `${server.server.baseUrl}api/admin/plan/detail/specific/${specificPlan?._id}/${userEmail}`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        )
        .then((res) => {
          setStrategyDetails(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (specificPlan) {
      getStrategyDetails();
    }
  }, [specificPlan]);

  const formattedName = specificPlan?.name
    ? specificPlan.name.includes(" ") // Check if there are spaces
      ? specificPlan.name.toLowerCase().replace(/\s+/g, "_") // If spaces, replace them
      : specificPlan.name.toLowerCase() // If no spaces, just lowercase
    : "";

  const handleCardClick = (name, e) => {
    setSelectedCard(name);
  };

  // digio configuration
  const [razorpayLoader, setRazorpayLoader] = useState(false);
  const [digioModalOpen, setDigioModalOpen] = useState(false);
  const [digioUnsucessModal, setDigioUnsuccessModal] = useState(false);
  const [storeDigioData, setStoreDigioData] = useState("");
  const [authUrl, setAuthUrl] = useState("");

  const openDigioModal = async () => {
    setLoading(true);
    const response = await fetch("/digio.pdf");
    const blob = await response.blob();
    const file = new File([blob], "digio.pdf", { type: "application/pdf" });

    const formData = new FormData();
    formData.append("file", file);

    await axios
      .post(
        `${server.ccxtServer.baseUrl}misc/digio/upload/pdf/${mobileNumber}/${advisorTag}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      )
      .then((res) => {
        const url = res?.data?.result?.signing_parties?.[0]?.authentication_url;
        if (url) {
          setAuthUrl(url);
        }
        setPaymentModal(false);
        setStoreDigioData(res?.data?.result);
        setDigioModalOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleOk = async () => {
    if (digioCheck === "beforePayment") {
      openDigioModal();
    } else {
      handleSubmit();
    }
  };

  const closeDigioModal = async () => {
    if (storeDigioData?.id) {
      let config = {
        method: "get",
        url: `${server.ccxtServer.baseUrl}misc/digio/doc-detail/${storeDigioData?.id}/${advisorTag}`,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };

      await axios
        .request(config)
        .then(async (response) => {
          console.log(JSON.stringify(response.data));
          if (response?.data?.result?.agreement_status === "completed") {
            try {
              const response = await axios.get(
                `${server.ccxtServer.baseUrl}misc/digio/download/signed-doc/${storeDigioData?.id}/${advisorTag}`,

                {
                  headers: {
                    "Content-Type": "application/json",
                    "X-Advisor-Subdomain": process.env.REACT_APP_URL,
                    "aq-encrypted-key": encryptApiKey(
                      process.env.REACT_APP_AQ_KEYS,
                      process.env.REACT_APP_AQ_SECRET
                    ),
                  },
                },
                {
                  responseType: "blob",
                }
              );

              // Create a URL for the blob
              const pdfBlob = new Blob([response.data], {
                type: "application/pdf",
              });
              const pdfUrl = URL.createObjectURL(pdfBlob);
              // Create a temporary link and trigger the download
              const link = document.createElement("a");
              link.href = pdfUrl;
              link.setAttribute("download", "signed-document.pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(pdfUrl);
              setDigioModalOpen(false);
              setRazorpayLoader(true);
              handleSubmit();
            } catch (error) {
              console.error("Error downloading PDF:", error);
            }
          } else {
            setDigioUnsuccessModal(true);
            setDigioModalOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setDigioUnsuccessModal(true);
          setDigioModalOpen(false);
        });
    }
  };

  const handleSubmit = async () => {
    try {
      // Generate a client ID if not already present
      const clientId = userDetails?.clientId || "";

      // First update the user profile
      const profileData = {
        uid: userDetails && userDetails._id,
        email: userEmail,
        phoneNumber: mobileNumber,
        countryCode: countryCode,
        userName: name,
        panNumber: panNumber,
        profileCompletion: 75,
        advisorName: advisorName,
        clientId: clientId, // Add clientId to the request
      };

      await axios.put(
        `${server.server.baseUrl}api/user/update/user-details`,
        profileData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      // Then proceed with subscription/payment
      if (strategyDetails?.frequency?.length !== 0) {
        await subscribeToPlan(selectedCard, inputValue);
      } else {
        await handleSinglePayment(strategyDetails?.amount);
      }
    } catch (error) {
      console.error("Error updating profile or processing payment:", error);
      toast.error(error.response?.data?.message || "Failed to process request");
    }
  };

  // Email notification function
  const sendEmailNotification = async (
    email,
    planDetails,
    userName,
    panNumber,
    advisorName,
    tradingPlatform,
    data
  ) => {
    try {
      const latestPayment = data?.subscription?.payment_history.sort(
        (a, b) => new Date(b?.payment_date) - new Date(a?.payment_date)
      )[0];

      const emailData = [
        {
          template_name: "new_plan2",
          template_body_values: [
            userName || email,
            `${
              latestPayment?.payment_type === "extension" &&
              latestPayment?.previous_end_date
                ? latestPayment?.previous_end_date
                : latestPayment?.payment_date
            }`,
            `${latestPayment?.new_end_date}`,
          ],
          trade_given_by: advisorName,
          recipient_email: email,
          plan_name: planDetails.name,
          pan: panNumber,
          amount: planDetails?.amount,
          payment_frequency: data?.payment_frequency || "oneTime",
          duration: planDetails?.duration,
        },
      ];

      const emailResponse = await axios.post(
        `${server.ccxtServer.baseUrl}comms/email/send-template-messages/supported-broker`,
        emailData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      if (emailResponse.data.status !== 0) {
        console.error("Email sending failed:", emailResponse.data);
      }

      return emailResponse;
    } catch (error) {
      console.error("Email notification error:", {
        message: error.message,
        response: error.response?.data,
        statusCode: error.response?.status,
        email: email,
      });
      // Don't throw error to prevent disrupting the main flow
    }
  };

  // Function to add days to a date and return ISO string
  const addDaysToDate = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString();
  };

  // Example usage in your WhatsApp notification function
  const sendWhatsAppNotification = async (
    phoneNumber,
    countryCode,
    planDetails,
    userName,
    email,
    advisorName,
    data
  ) => {
    try {
      const latestPayment = data?.subscription?.payment_history.sort(
        (a, b) => new Date(b?.payment_date) - new Date(a?.payment_date)
      )[0];
      // Format phone number - remove any non-digit characters
      let formattedPhone: *;
      formattedPhone = phoneNumber.toString().replace(/\D/g, "");
      let formattedCountryCode = countryCode; //.toString().replace(/\D/g, '');
      // Then add '+' prefix if it doesn't exist
      formattedCountryCode = formattedCountryCode.startsWith("+")
        ? formattedCountryCode
        : `+${formattedCountryCode}`;

      const getAdvisorCodomain = (advisor) => {
        if (advisor === "AlphaQuark") return "prod";
        if (advisor === "AlphaQuarkTest") return "test";
        return advisor.toLowerCase();
      };

      if (latestPayment?.payment_type === "extension") {
        // console.log("calling", planDetails.isRenewal);
        await axios.post(
          `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
          {
            phone_number: formattedPhone,
            template_name: "new_plan2",
            template_body_values: [
              userName || email,
              latestPayment?.payment_type === "extension"
                ? "renewed"
                : "subscribed",
              `${
                latestPayment?.payment_type === "extension" &&
                latestPayment?.previous_end_date
                  ? latestPayment?.previous_end_date
                  : latestPayment?.payment_date
              }`,
              `${latestPayment?.new_end_date}`,
              advisorName,
            ],
            template_button_values: [getAdvisorCodomain(advisorName)],
            template_header_values: [
              latestPayment?.payment_type === "extension"
                ? "Renewed"
                : "Subscribed",
            ],
            country_code: countryCode,
            callback_data: "Standard Callback",
            language_code: "en",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );
      } else {
        const trackUserResponse = await axios.post(
          `${server.ccxtServer.baseUrl}comms/whatsapp/track-user`,
          {
            phone_number: formattedPhone,
            country_code: formattedCountryCode,
            user_traits: {
              name: userName || email,
              email: email,
              advisor: advisorName,
              advisor_codomain: rootUrl,
              whatsapp_opted_in: true,
            },
            tags: [advisorName, "internal_team"],
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );

        if (trackUserResponse.data.result.result === true) {
          // Then send template message
          await axios.post(
            `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
            {
              phone_number: formattedPhone,
              template_name: "new_plan2",
              template_body_values: [
                userName || email,
                latestPayment?.payment_type === "extension"
                  ? "renewed"
                  : "subscribed",
                `${
                  latestPayment?.payment_type === "extension" &&
                  latestPayment?.previous_end_date
                    ? latestPayment?.previous_end_date
                    : latestPayment?.payment_date
                }`,
                `${latestPayment?.new_end_date}`,
                advisorName,
              ],
              template_button_values: [getAdvisorCodomain(advisorName)],
              template_header_values: [
                latestPayment?.payment_type === "extension"
                  ? "Renewed"
                  : "Subscribed",
              ],
              country_code: countryCode,
              callback_data: "Standard Callback",
              language_code: "en",
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-Advisor-Subdomain": process.env.REACT_APP_URL,
                "aq-encrypted-key": encryptApiKey(
                  process.env.REACT_APP_AQ_KEYS,
                  process.env.REACT_APP_AQ_SECRET
                ),
              },
            }
          );
        }
      }
      // First track the user
    } catch (error) {
      console.error("WhatsApp notification error:", error);
      // Don't throw error to prevent disrupting the main flow
    }
  };

  const sendNotifications = async (notificationData) => {
    const {
      email,
      phoneNumber,
      countryCode,
      planDetails,
      panNumber,
      userName,
      advisorName,
      tradingPlatform,
      data,
    } = notificationData;

    try {
      // Send email first
      await sendEmailNotification(
        email,
        planDetails,
        userName,
        panNumber,
        advisorName,
        tradingPlatform,
        data
      );

      // Then send WhatsApp notification
      await sendWhatsAppNotification(
        phoneNumber,
        countryCode,
        planDetails,
        userName,
        email,
        advisorName,
        data
      );
    } catch (error) {
      console.error("Notification error:", error);
    }
  };

  function calculateNewExpiryDate(currentExpiry, plan) {
    const newExpiry = new Date(currentExpiry);

    if (plan.frequency) {
      // For recurring subscriptions
      switch (plan.frequency) {
        case "monthly":
          newExpiry.setMonth(newExpiry.getMonth() + 1);
          break;
        case "quarterly":
          newExpiry.setMonth(newExpiry.getMonth() + 3);
          break;
        case "yearly":
          newExpiry.setFullYear(newExpiry.getFullYear() + 1);
          break;
        default:
          newExpiry.setMonth(newExpiry.getMonth() + 1); // Default to monthly
      }
    } else {
      // For one-time payments
      // Add the plan duration (assuming it's in days)
      newExpiry.setDate(newExpiry.getDate() + (plan.duration || 30)); // Default to 30 days if not specified
    }

    return newExpiry;
  }

  async function subscribeToPlan(frequency, sip_amount) {
    try {
      setLoading(true);
      // Fetch subscription details from the backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription`,
        {
          plan_id: specificPlan?._id,
          frequency,
          user_email: userEmail,
          sip_amount,
          advisor: advisorTag,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
      setLoading(false);
      setPaymentModal(false);
      const subscriptionData = response.data.data;
      setRazorpayLoader(false);
      // console.log(subscriptionData, "subscriptionData");

      if (subscriptionData.razorpay_subscription_id) {
        // Initialize Razorpay with the subscription details
        const options = {
          key: razorPayKey, // Your Razorpay Key ID
          subscription_id: subscriptionData.razorpay_subscription_id, // The subscription ID from Razorpay
          name: subscriptionData.plan_id.name, // Plan or product name
          description: subscriptionData?.razorpay_subscription_id, // Description of the plan
          amount: subscriptionData.amount, // Amount in smallest unit (paise for INR)
          currency: "INR", // Currency (e.g., INR)
          handler: function (response) {
            // This will execute after successful payment
            // console.log("Razorpay Payment ID:", response.razorpay_payment_id);
            // console.log(
            //   "Razorpay Subscription ID:",
            //   response.razorpay_subscription_id
            // );
            // console.log("Razorpay Signature:", response.razorpay_signature);

            // Now, you can call the backend to save the subscription status and other details
            if (userId) {
              setIsPostPaymentProcessing(true);
              completeSubscription(response);
            }
          },
          modal: {
            ondismiss: function () {
              alert("Payment was not completed. Please try again.");
            },
          },
          prefill: {
            name: "", // User's name
            email: userEmail, // User's email
          },
          theme: {
            color: "#F37254",
          },
        };

        // Initialize and open the Razorpay Checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching subscription data");
        setLoading(true);
      }
    } catch (error) {
      console.error("Error subscribing to plan:", error);
      setLoading(true);
    }
  }

  const logPayment = async (type, data) => {
    try {
      await axios.post(
        `${server.server.baseUrl}api/log-payment`,
        {
          type,
          data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
    } catch (error) {
      console.error("Failed to log payment:", error);
    }
  };
  // Function to handle the backend call after successful payment
  async function completeSubscription(paymentDetails) {
    try {
      // Send payment details to the backend to finalize the subscription
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/complete-payment`,
        paymentDetails,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      const data = response.data;
      setIsPostPaymentProcessing(false);
      await logPayment("SUBSCRIPTION_PAYMENT_SUCCESS", {
        subscriptionId: data.subscription.razorpay_subscription_id,
        amount: specificPlan?.amount,
        clientName: name,
        email: userEmail,
        plan: formattedName,
        planType: specificPlan?.frequency,
        duration:
          specificPlan?.frequency === "monthly"
            ? "30"
            : specificPlan?.frequency === "quarterly"
            ? "90"
            : "365",
      });
      setPaymentSuccess(true);
      setRefresh((prev) => !prev);

      // Send notifications (email and WhatsApp)
      try {
        await sendNotifications({
          email: userEmail,
          phoneNumber: mobileNumber,
          countryCode: countryCode,
          panNumber: panNumber,
          planDetails: {
            isRenewal: false,
            duration:
              specificPlan?.frequency === "monthly"
                ? "30"
                : specificPlan?.frequency === "quarterly"
                ? "90"
                : "365",
            name: specificPlan?.name,
            amount: specificPlan?.amount,
            frequency: specificPlan?.frequency,
          },
          userName: name,
          advisorName: whiteLabelText, // Change from advisorName to whiteLabelText
          tradingPlatform: "supported-broker", // Add this parameter
          data: data,
        });
      } catch (notificationError) {
        console.error("Notifications failed:", notificationError);
        // Continue execution - notification failure shouldn't stop the process
      }

      const newSubscription = {
        startDate: new Date(),
        plan: formattedName || "", // Assuming the response contains a plan
        capital: data.subscription.capital || 0, // Assuming the response contains capital
        charges: data.subscription.amount || 0, // Assuming the response contains charges
        invoice: data.subscription.razorpay_subscription_id || "", // Assuming the response contains invoice
        expiry: new Date(data.expiry), // Assuming the response contains expiry date
      };

      const newClientData = {
        clientName: name || "", // Assuming the response contains a client name
        email: data?.subscription?.user_email || "", // Assuming the response contains an email
        phone: mobileNumber || "", // Assuming the response contains a phone number
        groups: [`All Client`, formattedName], // Add formatted name dynamically
        location: data.location || "", // Assuming the response contains a location
        telegram: telegramId || "", // Assuming the response contains a Telegram ID
        pan: panNumber || "",
        comments: data.comments || "", // Assuming the response contains comments
        DateofBirth: birthDate || "",
        advisorName: advisorTag,
        subscriptions: [
          {
            ...newSubscription, // Attach the new subscription here
          },
        ],
      };

      try {
        // Send a POST request to add the new client
        const response = await fetch(
          `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
          {
            method: "POST",
            body: JSON.stringify({
              userId: specificPlan?.adminId,
              DateofBirth: birthDate || "",
              advisorName: advisorTag,
              clientData: newClientData,
            }),
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );

        const result = await response.json();

        await logPayment("SUBSCRIPTION_CLIENT_ADDED", {
          clientId: newClientData.clientId,
          clientName: newClientData.clientName,
          plan: formattedName,
          subscriptionId: newSubscription.subId,
          subscriptionDetails: {
            startDate: newSubscription.startDate,
            expiry: newSubscription.expiry,
            amount: newSubscription.charges,
          },
        });
      } catch (error) {
        console.error("Error adding client:", error);
        await logPayment("SUBSCRIPTION_CLIENT_ADD_ERROR", {
          error: error.message,
          clientName: data.subscription.name,
          email: data?.subscription?.user_email,
        });
      }

      let payloadData = JSON.stringify({
        email: userEmail,
        action: "subscribe",
      });

      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/model-portfolio/subscribe-strategy/${singleStrategyDetails?._id}`,
        data: payloadData,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };

      axios
        .request(config)
        .then((response) => {
          getStrategyDetails();
        })
        .catch((error) => {
          console.log(error);
        });

      let data2 = JSON.stringify({
        userEmail: userEmail,
        model: singleStrategyDetails?.model_name,
        advisor: singleStrategyDetails?.advisor,
        model_id: singleStrategyDetails?.model_Id,
        userBroker: broker ? broker : "",
        subscriptionAmountRaw: [
          {
            amount: invetAmount,
            dateTime: new Date(),
          },
        ],
      });

      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
        data: data2,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };

      axios
        .request(config2)
        .then(async (response) => {
          getStrategyDetails();
          getAllPlans();
          getAllPortfolioPlans();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      await logPayment("SUBSCRIPTION_PAYMENT_FAILURE", {
        error: error.message,
        clientName: name,
        email: userEmail,
        amount: specificPlan?.amount,
        plan: formattedName,
        planType: specificPlan?.frequency,
      });
      console.error("Error completing subscription:", error);
      toast.error(`Error completing subscription: ${error.message}`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#16a085",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "16px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#16a085",
          secondary: "#FFFAEE",
        },
      });
      throw error;
    }
  }

  const [invetAmount, setInvestAmount] = useState();

  const [singleStrategyDetails, setSingleStrategyDetails] = useState();
  const [latestRebalance, setLatestRebalance] = useState(null);
  const getSingleStrategyDetails = () => {
    if (modelSpecificStrategy !== null) {
      const normalizedStrategyName = modelSpecificStrategy
        .replaceAll("_", " ")
        .toLowerCase();
      axios
        .get(
          `${server.server.baseUrl}api/model-portfolio/portfolios/strategy/${normalizedStrategyName}`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        )
        .then((res) => {
          const portfolioData = res.data[0].originalData;
          setSingleStrategyDetails(portfolioData);
          if (
            portfolioData &&
            portfolioData.model &&
            portfolioData.model.rebalanceHistory.length > 0
          ) {
            const latest = portfolioData.model.rebalanceHistory.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            )[0];
            setLatestRebalance(latest);
          }
          setLoading(null);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getSingleStrategyDetails();
  }, [modelSpecificStrategy]);

  async function handleSinglePayment(amount) {
    console.log("amount", amount);
    try {
      setLoading(true);
      // Fetch one-time payment details from the backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription`,
        {
          plan_id: specificPlan?._id,
          user_email: userEmail,
          amount,
          advisor: advisorTag,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );
      setLoading(false);
      setPaymentModal(false);
      const paymentData = response.data.data;
      setRazorpayLoader(false);
      // console.log(response.data, "paymentData");

      if (paymentData?.razorpay_order_id) {
        // Initialize Razorpay with the order details
        const options = {
          key: razorPayKey,
          order_id: paymentData?.razorpay_order_id,
          name: paymentData.plan_id.name,
          description: paymentData?.razorpay_order_id,
          amount: paymentData.amount,
          currency: "INR",
          handler: function (response) {
            // console.log("Razorpay Payment ID:", response.razorpay_payment_id);
            // console.log("Razorpay Order ID:", response.razorpay_order_id);
            // console.log("Razorpay Signature:", response.razorpay_signature);

            if (userId) {
              setIsPostPaymentProcessing(true);
              completeSinglePayment(response);
            }
          },
          modal: {
            ondismiss: function () {
              alert("Payment was not completed. Please try again.");
            },
          },
          prefill: {
            name: "",
            email: userEmail,
          },
          theme: {
            color: "#F37254",
          },
        };

        // Initialize and open the Razorpay Checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching one-time payment data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating one-time payment:", error);
      setLoading(false);
    }
  }

  async function completeSinglePayment(paymentDetails) {
    try {
      // First, check for existing payment and plan
      const existingPayment = await axios.post(
        `${server.server.baseUrl}api/subscription-check/check-payment-status`,
        {
          razorpay_order_id: paymentDetails?.razorpay_order_id,
          plan_id: specificPlan?._id,
          user_email: userEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      if (existingPayment.data.orderExists) {
        throw new Error("This payment has already been processed");
      }

      let expiryDate;
      let isSubscriptionExtension = false;

      // Check for existing subscription with same plan
      const existingSubscription = await axios.get(
        `${server.server.baseUrl}api/subscription-check/user/${userEmail}/plan/${specificPlan?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      if (existingSubscription.data.subscription) {
        isSubscriptionExtension = true;
        // Calculate new expiry based on existing subscription
        expiryDate = calculateNewExpiryDate(
          existingSubscription.data?.subscription?.end_date,
          specificPlan
        );
      }

      // Complete payment with backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription/complete-one-time-payment`,
        {
          ...paymentDetails,
          user_email: userEmail,
          advisor_email: specificPlan?.advisor_email,
          plan_id: specificPlan?._id,
          amount: specificPlan?.amount,
          end_date:
            expiryDate ||
            new Date(
              new Date().setDate(
                new Date().getDate() + (specificPlan?.duration || 30)
              )
            ),
          newExpiryDate: expiryDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      const data = response.data;
      setIsPostPaymentProcessing(false);
      await logPayment("PAYMENT_SUCCESS", {
        orderId: data?.subscription?.razorpay_order_id,
        amount: specificPlan?.amount,
        clientName: name,
        email: userEmail,
        plan: formattedName,
      });
      setPaymentSuccess(true);
      if (specificPlan?.name === "priorRecommendationPlan") {
        const adviceData = {
          email: userDetails?.email,
        };
        let config = {
          method: "post",
          url: `${server.server.baseUrl}api/stocks-list/update-user/previous_advice_purchased`,
          data: adviceData,
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        };

        axios
          .request(config)
          .then((response) => {
            getUserDetails();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // Send WhatsApp notification first
      try {
        await sendNotifications({
          email: userEmail,
          phoneNumber: mobileNumber,
          countryCode: countryCode,
          panNumber: panNumber,
          planDetails: {
            isRenewal: isSubscriptionExtension,
            duration: specificPlan?.duration || "30",
            name: specificPlan?.name,
            amount: specificPlan?.amount,
          },
          userName: name,
          advisorName: whiteLabelText,
          tradingPlatform: "supported-broker", // or 'unsupported-broker' based on your needs
          data: data,
        });
      } catch (notificationError) {
        console.error("Notifications failed:", notificationError);
        // Continue execution - notification failure shouldn't stop the process
      }

      // Handle strategy details if present
      if (singleStrategyDetails) {
        let data2 = JSON.stringify({
          userEmail: userEmail,
          model: singleStrategyDetails?.model_name,
          advisor: singleStrategyDetails?.advisor,
          model_id: latestRebalance.model_Id,
          userBroker: broker ? broker : "",
          subscriptionAmountRaw: [
            {
              amount: invetAmount,
              dateTime: new Date(),
            },
          ],
        });

        let config2 = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
          data: data2,
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        };

        axios
          .request(config2)
          .then((response) => {
            getStrategyDetails();
          })
          .catch((error) => {
            console.log(error);
          });
        updateStrategySubscription();
      }

      // Create new subscription record
      const newSubscription = {
        startDate: new Date(),
        plan: formattedName || "",
        capital: invetAmount || 0,
        charges: specificPlan?.amount || 0,
        invoice: paymentDetails?.razorpay_order_id || "",
        expiry: expiryDate || data?.subscription?.end_date,
      };

      // Update client data in same way as before
      const clientResponse = await handleClientUpdate(
        isSubscriptionExtension,
        newSubscription,
        specificPlan?.adminId,
        data.subscription
      );
    } catch (error) {
      await logPayment("PAYMENT_FAILURE", {
        error: error.message,
        clientName: name,
        email: userEmail,
        amount: specificPlan?.amount,
      });
      toast.error(`Error processing payment: ${error.message}`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      throw error;
    }
  }

  // Helper function to handle client data updates
  async function handleClientUpdate(
    isExtension,
    newSubscription,
    adminId,
    subscriptionData
  ) {
    // Generate clientId if not already present
    const clientId = userDetails?.clientId || "";
    const clientData = {
      clientName: name || "",
      email: subscriptionData?.user_email || "",
      phone: mobileNumber || "",
      groups: [`All Client`, formattedName],
      location: "",
      telegram: telegramId || "",
      pan: panNumber || "",
      creationDate: new Date(),
      subscriptions: [newSubscription],
    };

    try {
      const checkClientResponse = await fetch(
        `${server.server.baseUrl}api/add-subscriptions/check-client`,
        {
          method: "POST",
          body: JSON.stringify({
            userId: adminId,
            email: clientData.email,
            clientId: clientId, // Include clientId in check
          }),
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      const checkClientResult = await checkClientResponse.json();
      await logPayment("CLIENT_ADDED", {
        clientId: clientData.clientId,
        clientName: name,
        plan: formattedName,
        subscriptionDetails: newSubscription,
      });
      if (checkClientResult.clientExists) {
        return fetch(
          `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
          {
            method: "POST",
            body: JSON.stringify({
              userId: adminId,
              DateofBirth: birthDate || "",
              advisorName: advisorTag,
              clientData: clientData,
            }),
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );
      } else {
        return fetch(
          `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
          {
            method: "POST",
            body: JSON.stringify({
              userId: adminId,
              DateofBirth: birthDate || "",
              advisorName: advisorTag,
              clientData: clientData,
            }),
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );
      }
    } catch (error) {
      console.error("Error updating client data:", error);
      throw error;
    }
  }

  // Existing subscribeToPlan and completeSubscription functions remain unchanged

  async function updateStrategySubscription() {
    try {
      let payloadData = JSON.stringify({
        email: userEmail,
        action: "subscribe",
      });

      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/model-portfolio/subscribe-strategy/${singleStrategyDetails?._id}`,
        data: payloadData,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };

      await axios.request(config);
      getStrategyDetails();
      getStrategyDetails();
      getAllPlans();
      getAllPortfolioPlans();
      let data2 = JSON.stringify({
        userEmail: userEmail,
        model: singleStrategyDetails?.model_name,
        advisor: singleStrategyDetails?.advisor,
        model_id: singleStrategyDetails?.model_Id,
        userBroker: broker ? broker : "",
        subscriptionAmountRaw: [
          {
            amount: invetAmount,
            dateTime: new Date(),
          },
        ],
      });

      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
        data: data2,
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };

      await axios.request(config2);

      getStrategyDetails();
    } catch (error) {
      console.error("Error updating strategy subscription:", error);
    }
  }

  function handleViewMore(name) {}
  const [countryCode, setCountryCode] = useState("+91");

  const [isBlurred, setIsBlurred] = useState(true);

  const handleConsentAccept = () => {
    setIsBlurred(false);
  };

  return (
    <div className="flex flex-col relative w-full min-h-screen bg-[#f9f9f9]">
      <div className="px-5 lg:px-14 flex flex-row justify-center border-b border-[#000000]/20 h-[50px] lg:h-[60px] font-poppins ">
        <div className="flex flex-row space-x-6 lg:space-x-8">
          <button className="text-center font-sans text-xs sm:text-base font-semibold pb-2 border-b-2 border-transparent hover:border-primary hover:text-primary transition-colors duration-200 whitespace-nowrap">
            Launching
          </button>
          <button className="text-center font-sans text-xs sm:text-base font-semibold pb-2 border-b-2 border-transparent hover:border-primary hover:text-primary transition-colors duration-200 whitespace-nowrap">
            Sleeker
          </button>
          <button className="text-center font-sans text-xs sm:text-base font-semibold pb-2 border-b-2 border-transparent hover:border-primary hover:text-primary transition-colors duration-200 whitespace-nowrap">
            Model portfolio options
          </button>
          <button className="text-center font-sans text-xs sm:text-base font-semibold pb-2 border-b-2 border-transparent hover:border-primary hover:text-primary transition-colors duration-200 whitespace-nowrap">
            Soon!
          </button>
        </div>
      </div>
      {process.env.REACT_APP_URL === "magnus" ? (
        <div className="sticky top-[60px] z-50 flex justify-center w-full">
          <div className="w-[90%] bg-red-600 text-white py-2 px-4 text-center shadow-md rounded-b-lg border-b-2 border-white/30">
            <p className="text-xs font-semibold font-poppins">
              PLEASE NOTE : If you have purchased two plans, the pending days of
              the first plan will be added to the duration of the second plan.
              For example, if you have a 3 month plan and a 12 month plan, you
              will get service for 3+12 = 15 months{" "}
            </p>
          </div>
        </div>
      ) : null}

      {/* Main Content Section */}
      {allPricingPlan?.length !== 0 ? (
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <div className="text-center mb-3 mt-8">
              <h1 className="text-2xl font-bold text-black">
                Bespoke Pricing Plans
              </h1>
            </div>
            <div className="grid grid-rows-1 grid-cols-1 gap-y-3 justify-items-center sm:grid-cols-1 md:grid-cols-3 pt-4 pb-8 sm:pb-14 sm:pl-4 md:pl-12 md:pr-10">
              {allPricingPlan
                ?.filter((plan) => {
                  if (
                    plan?.name === "priorRecommendationPlan" &&
                    userDetails?.previous_stocks_advice_purchased === true
                  ) {
                    return false;
                  }
                  return true;
                })
                .sort((p1, p2) => {
                  if (p1.name === "priorRecommendationPlan") return 1;
                  if (p2.name === "priorRecommendationPlan") return -1;
                  return (
                    (p1?.subscription == null) - (p2?.subscription == null)
                  );
                })
                .map((ele, i) => (
                  <BespokeCard
                    key={i}
                    ele={ele}
                    handlePricingCardClick={handlePricingCardClick}
                    allPricingPlan={allPricingPlan}
                  />
                ))}
            </div>
          </motion.div>
        </AnimatePresence>
      ) : null}

      {/* Model Portfolio Pricing Plans Section */}
      {modelPortfolioPricingPlan?.length !== 0 ? (
        <div className="flex items-center justify-center my-3">
          <div className="w-[25%] bg-gray-200 h-[1px]"></div>
          <h1 className="mx-7 text-2xl font-bold text-black">
            Model Portfolio Pricing Plans
          </h1>
          <div className="w-[25%] bg-gray-200 h-[1px]"></div>
        </div>
      ) : null}

      {modelPortfolioPricingPlan?.length !== 0 ? (
        <div className="grid grid-rows-1 overflow-hidden sm:overflow-hidden md:overflow-hidden lg:overflow-hidden">
          <div className="max-h-[calc(100vh-60px)] md:max-h-[calc(100vh-100px)] lg:min-h-screen w-full items-center justify-center overflow-scroll custom-scroll bg-[#F9F9F9]">
            {isLoading ? (
              <div className="flex flex-col relative w-full min-h-screen bg-[#f9f9f9]">
                <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center h-[calc(100vh-60px)]">
                  <svg
                    className="h-10 w-10 text-[#000000] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              </div>
            ) : (
              <ModelPFCardDetailsSection
                handlePricingCardClick={handlePricingCardClick}
                allPricingPlan={modelPortfolioPricingPlan}
              />
            )}
          </div>
        </div>
      ) : null}

      {/* No Plans Available Section */}
      {allPricingPlan?.length === 0 &&
        modelPortfolioPricingPlan?.length === 0 && (
          <>
            {isLoadingPlan ? (
              <div className="flex flex-col relative w-full min-h-screen bg-[#f9f9f9]">
                <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center h-[calc(100vh-60px)]">
                  <svg
                    className="h-10 w-10 text-[#000000] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              </div>
            ) : (
              <div className="col-span-full flex flex-col items-center justify-center space-y-6 -mt-20 h-full">
                <div className="flex items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70">
                  <HandCoins size={50} className="w-[60px] lg:w-[80px]" />
                </div>
                <div className="flex flex-col justify-center space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
                  <div className="text-black text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                    No Plans Available Now
                  </div>
                  <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                    As we work on creation of a tailored and thematic portfolio,
                    such portfolios will become visible here.
                  </div>
                </div>
              </div>
            )}
          </>
        )}

      {/* Modals */}
      {paymentModal && (
        <PlanSubscribeModal
          userEmail={userEmail}
          strategyDetails={strategyDetails}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          handleCardClick={handleCardClick}
          selectedCard={selectedCard}
          handleOk={handleOk}
          loading={loading}
          invetAmount={invetAmount}
          setInvestAmount={setInvestAmount}
          setName={setName}
          name={name}
          setMobileNumber={setMobileNumber}
          mobileNumber={mobileNumber}
          setPanNumber={setPanNumber}
          panNumber={panNumber}
          birthDate={birthDate}
          setBirthDate={setBirthDate}
          telegramId={telegramId}
          setTelegramId={setTelegramId}
        />
      )}

      {paymentSuccess && (
        <PaymentSuccessModal
          specificPlanDetails={specificPlan}
          setPaymentSuccess={setPaymentSuccess}
          setSelectedCard={setSelectedCard}
          setOpenSubscribeModel={setOpenSubscribeModel}
          setDigioModalOpen={setDigioModalOpen}
          openDigioModal={openDigioModal}
        />
      )}

      {openSubscribeModel && latestRebalance !== null && (
        <UserStrategySubscribeModal
          setOpenSubscribeModel={setOpenSubscribeModel}
          setOpenTokenExpireModel={setOpenTokenExpireModel}
          userEmail={userEmail}
          getStrategyDetails={getStrategyDetails}
          strategyDetails={singleStrategyDetails}
          fileName={modelSpecificStrategy}
          latestRebalance={latestRebalance}
          userDetails={userDetails}
          setOpenSucessModal={setOpenSucessModal}
          setOrderPlacementResponse={setOrderPlacementResponse}
          setBrokerModel={setBrokerModel}
          clientCode={clientCode}
          apiKey={apiKey}
          secretKey={secretKey}
          jwtToken={jwtToken}
          broker={broker}
          todayDate={todayDate}
          expireTokenDate={expireTokenDate}
        />
      )}

      {isPostPaymentProcessing && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl flex flex-col items-center space-y-4">
            <Loader2 className="h-20 w-20 text-blue-500 animate-spin" />

            <p className="text-sm text-gray-500">
              Please wait while we confirm your subscription...
            </p>
          </div>
        </div>
      )}
      {openSuccessModal && (
        <RecommendationSuccessModal
          setOpenSucessModal={setOpenSucessModal}
          orderPlacementResponse={orderPlacementResponse}
          setStockDetails={setStrategyDetails}
          openSuccessModal={openSuccessModal}
          userDetails={userDetails}
          setShowAfterPlaceOrderDdpiModal={setShowAfterPlaceOrderDdpiModal}
        />
      )}

      {brokerModel && (
        <ConnectBroker
          uid={userDetails?._id}
          userDetails={userDetails}
          setBrokerModel={setBrokerModel}
          getUserDetails={getUserDetails}
          broker={broker}
          setBroker={setBroker}
          brokerModel={brokerModel}
        />
      )}

      {openTokenExpireModel && (
        <TokenExpireBrokarModal
          openTokenExpireModel={openTokenExpireModel}
          setOpenTokenExpireModel={setOpenTokenExpireModel}
          userId={userId}
          apiKey={apiKey}
          secretKey={secretKey}
          checkValidApiAnSecret={checkValidApiAnSecret}
          clientCode={clientCode}
          my2pin={my2pin}
          panNumber={panNumber}
          mobileNumber={mobileNumber}
          broker={broker}
          getUserDetails={getUserDetails}
        />
      )}
      {razorpayLoader && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl flex flex-col items-center space-y-4">
            <Loader2 className="h-20 w-20 text-blue-500 animate-spin" />

            <p className="text-sm text-gray-500">
              Please wait while we intialize razorpay ...
            </p>
          </div>
        </div>
      )}
      {digioModalOpen === true ? (
        <DigioModal
          authenticationUrl={authUrl}
          digioModalOpen={digioModalOpen}
          onClose={closeDigioModal}
        />
      ) : null}
      {digioUnsucessModal === true ? (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="relative w-1/3 h-24 max-w-6xl bg-white rounded-lg overflow-hidden">
            <button
              onClick={() => setDigioUnsuccessModal(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 z-10"
              aria-label="Close"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="flex flex-row justify-center text-center my-12">
              Your Authentication Has been Failed retry again
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PricingPage;
