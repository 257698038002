import React, { useState } from "react";
import { Eye, EyeOff, Mail } from "lucide-react";
import Logo from "../../assests/Logo.jpg";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import toast, { Toaster } from "react-hot-toast";
import server from "../../utils/serverConfig";
import { saveTokenInLocalStorage } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import AdminSignInEmail from "./adminSignInEmail";
import AdminSignUpEmail from "./adminSignUpEmail";
import ForgotPassword from "../../Login/forgotPassword";
import { motion, AnimatePresence } from "framer-motion";

const AdminSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showforgotPassword, setShowForgotPassord] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;

  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const signIn = async () => {
    try {
      const response = await signInWithPopup(auth, provider);
      const userDetails = response.user;
      const token = await response.user.getIdToken();

      if (!token) {
        throw new Error("No token received");
      }

      try {
        const apiResponse = await axios.post(
          `${server.server.baseUrl}api/admin/allowed/login`,
          { email: userDetails.email }
        );

        if (apiResponse.status === 200) {
          saveTokenInLocalStorage(userDetails, token);
          dispatch(loginConfirmedAction({ ...userDetails, token }));
          navigate("/admin");
        }
      } catch (apiError) {
        toast.error(
          `Login is restricted. For advisor onboarding, contact hello@alphaquark.in.`,
          {
            duration: 5000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "560px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      }
    } catch (err) {
      if (err.code === 'auth/popup-closed-by-user') {
        // User closed the popup, don't show error
        return;
      }
      toast.error(
        `Authentication failed. Please try again.`,
        {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "560px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
  };

  return (
    <div className="min-h-screen flex">
      {/* Left Welcome Panel */}
      <div className="hidden lg:flex w-1/2 bg-black text-white">
        <div className="max-w-2xl mx-auto px-8 py-12 flex flex-col justify-center">
          <h1 className="text-4xl font-bold mb-6">
            Welcome to your Admin Dashboard
          </h1>
          <p className="text-lg mb-8 text-gray-300">
            Track your investments and provide research recommendations to clients
            with our comprehensive advisory platform.
          </p>
          <div className="bg-white/10 p-6 rounded-xl backdrop-blur-sm shadow-xl shadow-black/10">
            <p className="text-gray-300">
              This platform is exclusively for SEBI registered advisors. For
              onboarding inquiries, please contact us at:
            </p>
            <a
              href="mailto:admin@alphaquark.in"
              className="text-blue-400 hover:text-blue-300 mt-2 block"
            >
              admin@alphaquark.in
            </a>
          </div>
        </div>
      </div>

      {/* Right Auth Panel */}
      <div className="w-full lg:w-1/2 min-h-screen bg-gradient-to-b from-gray-50 to-white flex items-center justify-center p-6">
        <div className="w-full max-w-xl mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={showSignUp ? "signup" : showforgotPassword ? "forgot" : "signin"}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] p-8 lg:p-12"
            >
              {showSignUp ? (
                <div className="space-y-8">
                  <div className="flex flex-col items-center space-y-4">
                    <div className="w-28 h-28 rounded-full overflow-hidden ring-4 ring-gray-100 shadow-lg">
                      <img
                        src={Logo}
                        alt={`${whiteLabelText} Logo`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h2 className="text-3xl font-bold text-gray-900 text-center">
                      {whiteLabelText} Dashboard
                    </h2>
                  </div>
                  <AdminSignUpEmail
                    showSignUp={showSignUp}
                    setShowSignUp={setShowSignUp}
                  />
                </div>
              ) : showforgotPassword ? (
                <div className="space-y-8">
                  <div className="flex flex-col items-center space-y-4">
                    <div className="w-28 h-28 rounded-full overflow-hidden ring-4 ring-gray-100 shadow-lg">
                      <img
                        src={Logo}
                        alt={`${whiteLabelText} Logo`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h2 className="text-3xl font-bold text-gray-900 text-center">
                      {whiteLabelText} Dashboard
                    </h2>
                  </div>
                  <ForgotPassword
                    setShowForgotPassord={setShowForgotPassord}
                    showforgotPassword={showforgotPassword}
                  />
                </div>
              ) : (
                <div className="space-y-8">
                  <div className="flex flex-col items-center space-y-4">
                    <div className="w-28 h-28 rounded-full overflow-hidden ring-4 ring-gray-100 shadow-lg">
                      <img
                        src={Logo}
                        alt={`${whiteLabelText} Logo`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="text-center space-y-2">
                      <h1 className="text-3xl font-bold text-gray-900">
                        {whiteLabelText}
                      </h1>
                      <p className="text-lg text-gray-600">
                        Sign in to access your admin dashboard
                      </p>
                    </div>
                  </div>

                  <button
                    onClick={signIn}
                    className="w-full flex items-center justify-center space-x-3 py-4 px-6 bg-white border border-gray-200 rounded-xl hover:bg-gray-50 transition-colors duration-200 shadow-sm hover:shadow group"
                  >
                    <FcGoogle className="w-7 h-7" />
                    <span className="text-gray-700 font-medium text-lg group-hover:text-gray-900">
                      Continue with Google
                    </span>
                  </button>

                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-base">
                      <span className="px-4 text-gray-500 bg-white">
                        or continue with email
                      </span>
                    </div>
                  </div>

                  <AdminSignInEmail
                    setShowForgotPassord={setShowForgotPassord}
                    showforgotPassword={showforgotPassword}
                  />

                  <p className="text-center text-gray-600 text-lg">
                    No account?{" "}
                    <button
                      onClick={() => setShowSignUp(true)}
                      className="text-blue-600 hover:text-blue-700 font-medium"
                    >
                      Sign up here
                    </button>
                  </p>
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default AdminSignIn;