import * as React from "react";
import { Calendar as CalendarIcon } from "lucide-react";
import moment from "moment";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export function DatePicker({ date, setDate, placeholder, className }) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const handleOnSelect = (date) => {
    setDate(date);
    setIsPopoverOpen(false);
  };

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start  text-black font-medium font-poppins   bg-[#F9F9F9] text-left  ring-1 hover:ring-1 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-1 focus:ring-[#D9D9D9]  transition ease-in duration-200",
            !date && "text-gray-400 ",
            className
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? (
            moment(date).format("Do MMM YYYY")
          ) : (
            <span className="font-normal text-[12px] md:text-[16px] font-poppins">
              {placeholder}
            </span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleOnSelect}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
