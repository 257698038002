import React from "react";
import {
  ChevronDown,
  MessageSquareText,
  Edit2Icon,
  Trash2Icon,
  PlusIcon,
} from "lucide-react";
import { AiFillCaretRight } from "react-icons/ai";

import { FormatDate } from "../../../utils/formatDateTime";

const style = {
  tableTd: "px-3 py-4 font-normal text-[13px]  text-[#000000]/80",
  inputBox: "w-full bg-white border border-gray-300 rounded py-1 px-2",
};

const ClientListDetailsTableBody = ({
  dropdownOpen,
  filteredData,
  handleCancelSubRow,
  handleDeleteSubscriptionModal,
  handleEditSubscriptionModal,
  newSubscription,
  handleAddPlan,
  handleSubscriptionChange,
  newSubRow,
  expandedRows,
  handleDeleteModal,
  handleAddSubRow,
  toggleRow,
  paginatedData,
  handleCancel,
  handleAddClient,
  handleInputChange,
  newClient,
  handleGroupChange,
  allGroupsName,
  toggleDropdown,
  dropdownRef,
  newClientVisible,
}) => {
  return (
    <tbody>
      {newClientVisible && (
        <tr className="">
          <td className="px-3 py-4 max-w-[70px]">
            {/* Empty cell for spacing */}
          </td>
          <td className="min-w-[100px] px-4 py-4 font-normal text-[13px]  text-[#000000]/80">
            <input
              type="text"
              name="clientId"
              className={style.inputBox}
              value={newClient.clientId}
              onChange={handleInputChange}
              placeholder="Client Id"
              readOnly
            />
          </td>

          <td className={style.tableTd}>
            <input
              type="text"
              name="clientName"
              className={style.inputBox}
              value={newClient.clientName}
              onChange={handleInputChange}
              placeholder="Client Name"
            />
          </td>
          <td className={style.tableTd}>
            <input
              type="email"
              name="email"
              className={style.inputBox}
              value={newClient.email}
              onChange={handleInputChange}
              placeholder="Email Id"
            />
          </td>

          <td className="min-w-[160px] px-3 py-4 font-normal text-[13px]  text-[#000000]/80">
            <div
              className="relative flex items-start justify-between"
              ref={dropdownRef}
            >
              <div className="flex flex-wrap gap-[4px]">
                {newClient.groups.length > 0 ? (
                  newClient.groups.map((group, index) => (
                    <button
                      key={index}
                      className="bg-white font-normal px-2 py-0.5 rounded-full text-[10px] border-[1px] border-[#000000]/10 text-[#000000]/80"
                    >
                      {group}
                    </button>
                  ))
                ) : (
                  <span className="text-gray-400">Group Name(s)</span>
                )}
              </div>
              <button
                onClick={() => toggleDropdown("newClient")}
                className="flex items-center justify-center"
              >
                <ChevronDown className="text-[#000000]/80 w-6 h-6" />
              </button>
              {dropdownOpen["newClient"] && (
                <div
                  className={`absolute z-20 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg h-[180px] overflow-y-auto`}
                >
                  {allGroupsName && allGroupsName.length > 0 ? (
                    allGroupsName?.map((group) => (
                      <label
                        key={group}
                        className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border-b-[1px] last-of-type:border-[0px] border-[#000000]/20 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value={group}
                          checked={newClient.groups.includes(group)}
                          onChange={() => handleGroupChange(group)}
                          className="mr-2 accent-black"
                        />
                        {group}
                      </label>
                    ))
                  ) : (
                    <div className="px-4 py-2 text-sm text-gray-500">
                      No groups available
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>

          <td className={style.tableTd}>
            <input
              type="text"
              name="phone"
              className={style.inputBox}
              value={newClient.phone}
              onChange={handleInputChange}
              placeholder="Phone No."
            />
          </td>
          <td className={style.tableTd}>
            <input
              type="text"
              name="location"
              className={style.inputBox}
              value={newClient.location}
              onChange={handleInputChange}
              placeholder="Location"
            />
          </td>
          <td className={style.tableTd}>
            <input
              type="text"
              name="telegram"
              className={style.inputBox}
              value={newClient.telegram}
              onChange={handleInputChange}
              placeholder="Telegram Id"
            />
          </td>
          <td className={style.tableTd}>
            <input
              type="text"
              name="pan"
              className={style.inputBox}
              value={newClient.pan}
              onChange={handleInputChange}
              placeholder="KYC"
            />
          </td>
          <td className={style.tableTd}>
            <input
              type="text"
              name="creationDate"
              className={style.inputBox}
              value={newClient.creationDate}
              placeholder="Creation date"
              readOnly
            />
          </td>

          <td
            colSpan={2}
            className="px-2 py-4 font-normal text-[13px]  text-[#000000]/80"
          >
            <div className="flex justify-start ">
              {/* <div
          onClick={() => handleAddEmptySubRow()}
          className="flex items-center justify-center  text-blue-500  text-[11px]  font-medium  rounded-[4px] px-[6px] py-[4px] cursor-pointer"
        >
          Add Plan
        </div> */}
              <button
                disabled={
                  !newClient.clientId ||
                  !newClient.email ||
                  !newClient.clientName ||
                  !newClient.creationDate ||
                  !newClient.phone
                }
                onClick={handleAddClient}
                className="px-3 py-1 disabled:bg-opacity-50 disabled:cursor-not-allowed bg-[#000000] text-white font-medium text-[12px] rounded mr-2"
              >
                Save
              </button>

              <button
                onClick={handleCancel}
                className="px-2 py-1  text-black font-medium text-[12px] rounded"
              >
                <Trash2Icon className="h-4 w-4" />
              </button>
            </div>
          </td>
        </tr>
      )}

      {paginatedData &&
        paginatedData?.map((item, index) => (
          <React.Fragment key={item.clientId}>
            {" "}
            {/* Changed key to use clientId */}
            <tr
              className={`hover:bg-gray-50 transition-colors duration-150 ${
                expandedRows[index] ? "border-b-0" : "border-b border-gray-200"
              }`}
            >
              <td className="px-3 py-4 font-normal text-[13px]  text-[#000000]/80 ">
                <div className="flex items-center justify-center">
                  {item.comments && (
                    <div className="relative group w-full">
                      <MessageSquareText className="h-5 w-5 text-gray-600 cursor-pointer " />
                      <div className="z-20 mt-1 absolute top-full min-w-[200px] mb-2 hidden group-hover:inline-block   bg-gray-800 text-white text-[11px] rounded-[3px] p-2">
                        {item.comments}
                        <div className="absolute -top-1 left-[12px] transform -translate-x-1/2 w-2 h-2 bg-black rotate-45"></div>
                      </div>
                    </div>
                  )}
                </div>
              </td>

              <td className="px-3 py-4 font-normal text-[13px]  text-[#000000]/80 ">
                <div className="flex items-center justify-between">
                  <span>
                    {item.subscriptions?.length > 0 && (
                      <AiFillCaretRight
                        className={`cursor-pointer h-5 w-5 text-black/80 transform ${
                          expandedRows[index] ? "rotate-90" : ""
                        }`}
                        onClick={() => toggleRow(index)}
                      />
                    )}
                  </span>
                  <span className="mr-[4px] text-[12px] font-medium ">
                    ({item.subscriptions?.length})
                  </span>

                  {item.clientName}
                </div>
              </td>
              <td className="px-3 py-4 max-w-[180px] truncate font-normal text-[13px]  text-[#000000]/80">
                {item.email}
              </td>

              {/* Updated group dropdown */}
              <td className="min-w-[200px] px-3 py-4">
                <div className="relative flex items-start justify-between">
                  <div className="flex flex-wrap gap-1">
                    {item.groups?.map((group, groupIndex) => (
                      <span
                        key={groupIndex}
                        className="bg-white font-normal px-2 py-0.5 rounded-full text-xs border border-gray-200 text-gray-700"
                      >
                        {group?.replace(/_/g, " ")}
                      </span>
                    ))}
                  </div>
                </div>
              </td>
              <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px]  text-[#000000]/80 text-right">
                {item?.country_code}
              </td>
              <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px]  text-[#000000]/80">
                {item.phone}
              </td>
              <td className="px-3 py-4 max-w-[120px] text-center truncate font-normal text-[13px]  text-[#000000]/80">
                {item?.DateofBirth ? FormatDate(item?.DateofBirth) : "-"}
              </td>
              <td className={style.tableTd}>{item.location}</td>
              <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px]  text-[#000000]/80">
                {item.telegram?.includes("@") ? (
                  item.telegram
                ) : item.telegram?.length > 0 ? (
                  <>@{item.telegram}</>
                ) : (
                  ""
                )}
              </td>
              <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px]  text-[#000000]/80">
                {item.pan}
              </td>

              <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px]  text-[#000000]/80">
                TBD
              </td>

              <td className="px-3 py-4 max-w-[130px] font-normal text-[13px]  text-center text-[#000000]/80">
                {FormatDate(item.creationDate)}
              </td>

              {/* <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px]  text-[#000000]/80">
{item.subscriptions && item.subscriptions.length > 0 && item.subscriptions[0].expiry}                    </td> */}
              <td className="px-2 py-4 min-w-[80px]  font-normal text-[12px]  text-[#000000]/80">
                <div
                  onClick={() => handleAddSubRow(index)}
                  className="flex items-center justify-center  text-blue-500  text-[11px]  font-medium  rounded-[4px] px-[6px] py-[4px] cursor-pointer"
                >
                  Add Plan
                </div>
              </td>

              <td className="px-2 py-4  font-normal text-[12px]  text-[#000000]/80">
                <div className="flex items-center justify-center space-x-2">
                  <Trash2Icon
                    className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                    onClick={() => handleDeleteModal(item)}
                  />
                  {/* 
                      <Edit2Icon
                      className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                      onClick={() => handleEditClientModal(item)}
                    />
                      <MessageSquarePlus
              className="text-[#000000]/80 h-4 w-4 cursor-pointer"
              onClick={() => handleCommentUpdateModal(item)}
            />*/}
                </div>
              </td>
            </tr>
            {expandedRows[index] && (
              <tr className="bg-white">
                <td
                  colSpan="11"
                  className="py-4 px-[130px] border-b-[1px] border-[#000000]/10 "
                >
                  <table className="w-full border-[1px] border-[#000000]/10">
                    <thead className="bg-[#F5F5F5]/80">
                      <tr>
                        <th className="px-4 py-2 font-normal text-[11px]  text-left text-[#000000]/60">
                          <div className="flex items-center space-x-2">
                            <div
                              onClick={() => handleAddSubRow(index)}
                              className="w-4 h-4 bg-[#000000] rounded-[2px] flex items-center justify-center cursor-pointer"
                            >
                              <PlusIcon
                                strokeWidth={4}
                                className="text-[#ffffff] w-[12px] h-[12px]"
                              />
                            </div>
                            <div className="font-normal text-[11px]  text-left text-[#000000]/60">
                              Start Date
                              <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                            </div>
                          </div>
                        </th>
                        <th className="px-4 py-2 font-normal text-[11px]  text-left text-[#000000]/60">
                          Subscription Plan
                          <span className="ml-1 text-[#E6626F] text-[14px]">
                            *
                          </span>
                        </th>
                        <th className="px-4 py-2 font-normal text-[11px]  text-center text-[#000000]/60">
                          Capital Deployed
                        </th>
                        <th className="px-4 py-2 font-normal text-[11px]  text-center text-[#000000]/60">
                          Charges
                        </th>
                        <th className="px-4 py-2 font-normal text-[11px]  text-center text-[#000000]/60">
                          Invoice No.
                        </th>
                        <th className="px-4 py-2 font-normal text-[11px]  text-center text-[#000000]/60">
                          Expiry Date
                          <span className="ml-1 text-[#E6626F] text-[14px]">
                            *
                          </span>
                        </th>
                        <th className="px-4 py-2 font-normal text-[11px]  text-center text-[#000000]/60">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {newSubRow && (
                        <tr className="border-b-[1px] border-[#000000]/10">
                          <td className="px-4 py-2 font-normal text-[12px]  text-left text-[#000000]/80">
                            <input
                              type="text"
                              name="startDate"
                              value={newSubscription.startDate}
                              onChange={(e) => handleSubscriptionChange(e)}
                              className="w-full p-2 border rounded"
                              placeholder="Start Date"
                            />
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-left text-[#000000]/80">
                            <input
                              type="text"
                              name="plan"
                              value={newSubscription.plan}
                              readOnly
                              disabled
                              className="w-full p-2 border rounded"
                              placeholder="Plan"
                            />
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-left text-[#000000]/80">
                            <div className="flex relative">
                              <input
                                type="number"
                                name="capital"
                                value={newSubscription.capital}
                                onChange={(e) => handleSubscriptionChange(e)}
                                className="w-full p-2 border rounded pl-[24px]"
                                placeholder="Capital"
                              />
                              <div className="absolute left-[1px] rounded-l-[4px] text-[14px] text-black  py-[7px]  px-2">
                                ₹
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-left text-[#000000]/80">
                            <div className="flex relative">
                              <input
                                type="number"
                                name="charges"
                                value={newSubscription.charges}
                                onChange={(e) => handleSubscriptionChange(e)}
                                className="w-full p-2 border rounded pl-[24px]"
                                placeholder="Charges"
                              />
                              <div className="absolute left-[1px] rounded-l-[4px] text-[14px] text-black  py-[7px]  px-2">
                                ₹
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-left text-[#000000]/80">
                            <input
                              type="number"
                              name="invoice"
                              value={newSubscription.invoice}
                              onChange={(e) => handleSubscriptionChange(e)}
                              className="w-full p-2 border rounded"
                              placeholder="Invoice"
                            />
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-left text-[#000000]/80">
                            <input
                              type="text"
                              name="expiry"
                              value={newSubscription.expiry}
                              onChange={(e) => handleSubscriptionChange(e)}
                              className="w-full p-2 border rounded"
                              placeholder="Expiry Date"
                            />
                          </td>
                          <td className="px-4 py-4 font-normal text-[13px]  text-[#000000]/80">
                            <div className="flex space-x-0">
                              <button
                                disabled={
                                  !newSubscription.startDate ||
                                  !newSubscription.plan ||
                                  !newSubscription.capital ||
                                  !newSubscription.charges ||
                                  !newSubscription.invoice ||
                                  !newSubscription.expiry
                                }
                                onClick={() =>
                                  handleAddPlan(index, item.clientId)
                                }
                                className="disabled:cursor-not-allowed disabled:bg-opacity-50 px-4 py-1 bg-[#000000] text-white font-medium text-[12px] rounded mr-2"
                              >
                                Save
                              </button>
                              <button
                                onClick={() => handleCancelSubRow(index)}
                                className="px-4 py-1  text-black font-medium text-[12px] rounded"
                              >
                                <Trash2Icon className="h-4 w-4" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                      {item.subscriptions?.map((sub, subIndex) => (
                        <tr
                          key={subIndex}
                          className="border-b-[1px] border-[#000000]/10"
                        >
                          <td className="px-4 py-2 font-normal text-[12px]  text-left text-[#000000]/80">
                            {FormatDate(sub.startDate)}
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-left text-[#000000]/80">
                            {sub.plan}
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-center text-[#000000]/80">
                            {sub.capital}
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-center text-[#000000]/80">
                            {sub.charges}
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-center text-[#000000]/80">
                            {sub.invoice}
                          </td>
                          <td className="px-4 py-2 font-normal text-[12px]  text-center text-[#000000]/80">
                            {FormatDate(sub.expiry)}
                          </td>
                          <td className="px-4 py-4 font-normal text-[12px]  text-[#000000]/80">
                            <div className="flex items-center justify-center space-x-2">
                              <Edit2Icon
                                onClick={() =>
                                  handleEditSubscriptionModal(item, sub?.plan)
                                }
                                className="text-[#000000]/80 h-[14px] w-[14px] cursor-pointer"
                              />
                              <Trash2Icon
                                onClick={() =>
                                  handleDeleteSubscriptionModal(
                                    index,
                                    item,
                                    sub.subId
                                  )
                                }
                                className="text-[#000000]/80 h-[14px] w-[14px] cursor-pointer"
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            {index === filteredData.length - 1 && (
              <tr className="h-32 ">
                <td
                  colSpan={12}
                  className="px-3 py-4 font-normal text-[13px]  text-center text-gray-500"
                >
                  End of client list
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
    </tbody>
  );
};

export default ClientListDetailsTableBody;
