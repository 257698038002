import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import { CalendarDays } from "lucide-react";
import CryptoJS from "crypto-js";

import MPF_1 from "../../assests/mpf_1.png";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import IsMarketHours from "../../utils/isMarketHours";
import ConnectBroker from "../LivePortfolioSection/connectBroker";
import SkeletonCard from "../../components/SkeletonCard";
import { encryptApiKey } from "../../utils/cryptoUtils";

const RebalanceCard = ({
  userDetails,
  data,
  frequency,
  setOpenRebalanceModal,
  modelName,
  userEmail,
  apiKey,
  jwtToken,
  secretKey,
  clientCode,
  viewToken,
  sid,
  serverId,
  setCaluculatedPortfolioData,
  repair,
  advisorName,
  setModelPortfolioModelId,
  setStoreModalName,
  setOpenTokenExpireModel,
  broker,
  funds,
  overView,
  getUserDetails,
  setBroker,
  setSelectNonBroker,
  withoutBrokerModal,
  setWithoutBrokerModal,
  setStockTypeAndSymbol,
  matchingFailedTrades,
  todayDate,
  expireTokenDate,
  getRepairLoading,
}) => {
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const angelOneApiKey = process.env.REACT_APP_ANGEL_ONE_API_KEY;
  const checkValidApiAnSecret = (details) => {
    try {
      const bytesKey = CryptoJS.AES.decrypt(details, "ApiKeySecret");
      const Key = bytesKey.toString(CryptoJS.enc.Utf8); // Convert to UTF-8 string

      if (Key) {
        return Key;
      } else {
        throw new Error("Decryption failed or invalid key.");
      }
    } catch (error) {
      console.error("Error during decryption:", error.message);
      return null;
    }
  };

  // console.log("matchingFailedTrades", matchingFailedTrades);
  const [loading, setLoading] = useState(false);
  const [withoutBroker, setWithoutBroker] = useState(false);
  const handleAcceptRebalance = () => {
    const isMarketHours = IsMarketHours();
    setLoading(true);
    if (broker === undefined && withoutBroker === false) {
      setWithoutBrokerModal(true);
      setLoading(false);
    } else if (funds?.status === 1 || funds?.status === 2 || funds === null) {
      setOpenTokenExpireModel(true);
      setLoading(false);
    }
    // else if (!isMarketHours) {
    //   setLoading(false);
    //   toast.error("Orders cannot be placed outside Market hours.", {
    //     duration: 3000,
    //     style: {
    //       background: "white",
    //       color: "#1e293b",
    //       maxWidth: "500px",
    //       fontWeight: 600,
    //       fontSize: "13px",
    //       padding: "10px 20px",
    //     },
    //     iconTheme: {
    //       primary: "#e43d3d",
    //       secondary: "#FFFAEE",
    //     },
    //   });
    //   return;
    // }
    else {
      if (repair) {
        if (matchingFailedTrades !== undefined) {
          const { failedTrades } = matchingFailedTrades;
          const updatedStockTypeAndSymbol = failedTrades?.map((trade) => ({
            Symbol: trade.advSymbol,
            Type: trade.transactionType, // Already either 'BUY' or 'SELL'
            Exchange: trade.advExchange,
            Quantity: trade.advQTY,
          }));

          // Update the state
          setStockTypeAndSymbol(updatedStockTypeAndSymbol);
        }

        setOpenRebalanceModal(true);
        setModelPortfolioModelId(data.model_Id);
        setStoreModalName(modelName);
        setLoading(false);
      } else {
        let payload = {
          userEmail: userEmail,
          userBroker: withoutBroker === true ? "DummyBroker" : broker,
          modelName: modelName,
          advisor: advisorName,
          model_id: data.model_Id,
          userFund: funds?.data?.availablecash
            ? funds?.data?.availablecash
            : "0",
        };
        if (broker === "IIFL Securities") {
          payload = {
            ...payload,
            clientCode: clientCode,
          };
        } else if (broker === "ICICI Direct") {
          payload = {
            ...payload,
            apiKey: checkValidApiAnSecret(apiKey),
            secretKey: checkValidApiAnSecret(secretKey),
            accessToken: jwtToken,
          };
        } else if (broker === "Upstox") {
          payload = {
            ...payload,
            apiKey: checkValidApiAnSecret(apiKey),
            apiSecret: checkValidApiAnSecret(secretKey),
            accessToken: jwtToken,
          };
        } else if (broker === "Angel One") {
          payload = {
            ...payload,
            apiKey: angelOneApiKey,
            jwtToken: jwtToken,
          };
        } else if (broker === "Zerodha") {
          payload = {
            ...payload,
            apiKey: zerodhaApiKey,
            accessToken: jwtToken,
          };
        } else if (broker === "Dhan") {
          payload = {
            ...payload,
            clientId: clientCode,
            accessToken: jwtToken,
          };
        } else if (broker === "Hdfc Securities") {
          payload = {
            ...payload,
            apiKey: checkValidApiAnSecret(apiKey),
            accessToken: jwtToken,
          };
        } else if (broker === "Kotak") {
          payload = {
            ...payload,
            consumerKey: checkValidApiAnSecret(apiKey),
            consumerSecret: checkValidApiAnSecret(secretKey),
            accessToken: jwtToken,
            viewToken: viewToken,
            sid: sid,
            serverId: serverId,
          };
        } else if (broker === "AliceBlue") {
          payload = {
            ...payload,
            clientId: clientCode,
            accessToken: jwtToken,
            apiKey: apiKey,
          };
        } else if (broker === "Fyers") {
          payload = {
            ...payload,
            clientId: clientCode,
            accessToken: jwtToken,
          };
        }
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/calculate`,
          data: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        };
        axios
          .request(config)
          .then((response) => {
            const { buy, sell } = response.data;

            const updatedStockTypeAndSymbol = [
              ...buy.map((item) => ({
                Symbol: item.symbol,
                Type: "BUY",
                Exchange: item.exchange,
                Quantity: item.quantity,
              })),
              ...sell.map((item) => ({
                Symbol: item.symbol,
                Type: "SELL",
                Exchange: item.exchange,
                Quantity: item.quantity,
              })),
            ];

            setStockTypeAndSymbol(updatedStockTypeAndSymbol);
            setLoading(false);
            setCaluculatedPortfolioData(response.data);
            setOpenRebalanceModal(true);
            setModelPortfolioModelId(data.model_Id);
            setStoreModalName(modelName);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  };
  const [withoutBrokerLoading, setWithoutBrokerLoading] = useState(false);
  const handleAcceptRebalanceWithoutBroker = () => {
    setWithoutBrokerLoading(true);
    if (repair) {
      setOpenRebalanceModal(true);
      setModelPortfolioModelId(data.model_Id);
      setStoreModalName(modelName);
      setLoading(false);
    } else {
      let payload = {
        userEmail: userEmail,
        userBroker: "DummyBroker",
        modelName: modelName,
        advisor: advisorName,
        model_id: data.model_Id,
        userFund: funds?.data?.availablecash ? funds?.data?.availablecash : "0",
      };

      let config = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/calculate`,
        data: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      };
      axios
        .request(config)
        .then((response) => {
          setWithoutBrokerLoading(false);
          setCaluculatedPortfolioData(response.data);
          setOpenRebalanceModal(true);
          setModelPortfolioModelId(data.model_Id);
          setStoreModalName(modelName);
        })
        .catch((error) => {
          console.log(error);
          setWithoutBrokerLoading(false);
        });
    }
  };

  return (
    <>
      <div
        className={`${
          repair ? "bg-[#DE8846]" : "bg-[#2A587C]"
        } relative  w-full rounded-xl  shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]  py-4`}
      >
        <div
          className={`flex flex-col  items-start justify-between  mx-5 border-b border-[#ffffff]/20`}
        >
          <div className="flex space-x-2 py-2">
            <p className=" text-[13px] font-medium  text-[#ffffff]/80  capitalize">
              {" "}
              Rebalance Update : {frequency}{" "}
            </p>
          </div>
        </div>
        <div className={`flex flex-col  items-start justify-between   px-6`}>
          <div className="flex flex-row space-x-6 max-h-[85px]   py-4">
            <img src={MPF_1} alt="Portfolio" />
            <div className="flex flex-col ">
              <p className=" text-xl font-medium  text-[#ffffff] capitalize">
                {" "}
                {data?.updatedModelName}{" "}
              </p>
              <span className="ml-[2px] pt-[8px] text-[12px] text-[#ffffff]/80 font-light">
                {overView?.split(" ").slice(0, 6).join(" ")}
              </span>
            </div>
          </div>
        </div>

        <div
          className={` flex flex-row items-center justify-between mt-8  pb-1 px-6`}
        >
          <div className="text-[#ffffff] text-xs  font-medium flex flex-row items-center">
            <CalendarDays size={16} className="" />
            <span className="ml-2">
              {moment(data.rebalanceDate).format("Do MMM YYYY")}
            </span>
            <span className="mx-2">|</span>{" "}
            <span>{moment(data.rebalanceDate).format("HH:mm A")}</span>
          </div>
        </div>

        <div className={` flex mt-[10px] px-6 pb-2`}>
          <button
            className="w-full h-10 px-4 rounded-[4px] bg-white text-[#1D1D1F] text-xs sm:text-[13px] lg:text-[13px]  font-semibold  flex items-center justify-center"
            // hover:scale-[1.03] transition-all duration-150 ease-linear
            onClick={handleAcceptRebalance}
          >
            <span className="w-full h-full flex items-center justify-center">
              {loading === true ? (
                <LoadingSpinner />
              ) : repair ? (
                "Repair Portfolio"
              ) : (
                "Accept Rebalance"
              )}
            </span>
          </button>
        </div>
      </div>

      {withoutBrokerModal === true ? (
        <ConnectBroker
          uid={userDetails && userDetails._id}
          userDetails={userDetails && userDetails}
          setBrokerModel={setWithoutBrokerModal}
          getUserDetails={getUserDetails}
          broker={broker}
          setBroker={setBroker}
          brokerModel={withoutBrokerModal}
          withoutBrokerModal={withoutBrokerModal}
          withoutBroker={withoutBroker}
          setWithoutBroker={setWithoutBroker}
          handleAcceptRebalance={handleAcceptRebalanceWithoutBroker}
          withoutBrokerLoading={withoutBrokerLoading}
          setSelectNonBroker={setSelectNonBroker}
        />
      ) : null}
    </>
  );
};

export default RebalanceCard;
