import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { EyeIcon, EyeOffIcon, ArrowLeft } from "lucide-react";
import YouTube from "react-youtube";
import { ChevronDown, ChevronUp, ClipboardList } from "lucide-react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import server from "../../../utils/serverConfig";
import { encryptApiKey } from "../../../utils/cryptoUtils";

const KotakConnection = ({
  style,
  setState,
  state,
  uid,
  setBrokerModel,
  checkValidApiAnSecret,
  getUserDetails,
  handleCopy,
  toggleOpen,
  opts,
  handlePlayerReady,
  playerRef,
}) => {
  const connectKotak = () => {
    setState((prev) => ({ ...prev, loading: true, error: null }));

    // Input validation
    if (!/^\d{10}$/.test(state.mobileNumber)) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: "Please enter a valid 10-digit mobile number",
      }));
      return;
    }

    if (!/^\d{6}$/.test(state.mpin)) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: "MPIN should be a 6-digit number",
      }));
      return;
    }

    if (!/^\d{6}$/.test(state.totp)) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: "TOTP should be a 6-digit number",
      }));
      return;
    }

    let data = {
      uid: uid,
      apiKey: checkValidApiAnSecret(state.apiKey),
      secretKey: checkValidApiAnSecret(state.secretKey),
      mobileNumber: "+91" + state.mobileNumber,
      mpin: state.mpin,
      ucc: state.clientCode,
      totp: state.totp,
    };

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/kotak/connect-broker`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        setState((prev) => ({ ...prev, loading: false, error: null }));
        toast.success("You have been successfully logged in to your broker.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        // setBrokerModel
        getUserDetails();
        setBrokerModel(false);
      })
      .catch((error) => {
        console.log("Connection error:", error);

        // Extract specific error message if available
        const errorMessage =
          error.response?.data?.message ||
          error.response?.data?.details ||
          "Incorrect credentials. Please try again";

        setState((prev) => ({
          ...prev,
          loading: false,
          error: errorMessage,
        }));

        toast.error(errorMessage, {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };
  return (
    <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-6 no-scrollbar">
      <div
        className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
        onClick={() => {
          setState((prev) => ({ ...prev, broker: "" }));
        }}
      >
        <div>
          <ArrowLeft size={25} />
        </div>
        <div className="hidden md:flex ">Back</div>
      </div>

      <div className="mt-6 px-0 sm:px-[28px] text-base sm:text-lg  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20 overflow-auto">
        {/*
      <div className="mb-8 rounded-xl ">
          <YouTube
            videoId={`JXwnwaxM88k`}
            className={`videoIframe rounded-xl `}
            opts={opts}
            onReady={handlePlayerReady}
            ref={playerRef}
          />
        </div>
        */}

        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold">
            Steps to obtain the Consumer Key and Consumer Secret for{" "}
            {state.broker}:
          </h2>
          <button
            onClick={toggleOpen}
            className="focus:outline-none lg:hidden "
          >
            <div className="mr-4 mb-5">
              {state.isOpen ? (
                <ChevronUp size={20} strokeWidth={3} />
              ) : (
                <ChevronDown size={20} strokeWidth={3} />
              )}
            </div>
          </button>
        </div>
        <div className={`lg:block ${state.isOpen ? "block" : "hidden"}`}>
          <ol className="list-decimal pl-6 text-black ">
            <li className="mb-2">
              <span className=" font-semibold ">
                Obtain Neo TradeAPI Credentials (If you don’t have your client
                ID and password):
              </span>
              <br />- Visit Kotak Neo Trading API platform :{" "}
              {/* <div className="inline-flex items-center flex-wrap">
            <a
              href="https://napi.kotaksecurities.com/devportal/apis"
              className="text-blue-500 "
              target="_blank"
              rel="noopener noreferrer"
            >
              https://napi.kotaksecurities.com/devportal/apis

            </a>
            <img
              src={Copy}
              alt="Copy"
              className="w-4 h-4 ml-2 cursor-pointer"
              onClick={() =>
                handleCopy(
                  ` https://napi.kotaksecurities.com/devportal/apis`
                )
              }
            />
          </div> */}
            </li>
            <div className="inline-flex flex-col items-start text-white font-normal ">
              <div className="flex items-center border  rounded-lg bg-blue-100 max-w-full">
                <div className="pl-3 px-1 py-0.5 rounded-md text-sm overflow-hidden">
                  <a
                    href="http://kotaksecurities.com/trading-tools/kotak-neo-trading-platform/trading-api/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 break-all mb2"
                  >
                    http://kotaksecurities.com/trading-tools/kotak-neo-trading-platform/trading-api/
                  </a>
                </div>
                <div
                  className="p-1 pl-2 border-gray-700 flex-shrink-0"
                  data-tooltip-id="copy-tooltip"
                  data-tooltip-content="COPY"
                >
                  <ClipboardList
                    className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                    onClick={() =>
                      handleCopy(
                        "   http://kotaksecurities.com/trading-tools/kotak-neo-trading-platform/trading-api/"
                      )
                    }
                  />
                </div>
              </div>
              <Tooltip id="copy-tooltip" />
            </div>
            <span className="pb-2 mt-2  ">
              Find your Client ID (unique client code) in your Kotak Securities
              Neo account under account details. Enter your Client ID and click
              on "Register for Neo TradeAPI". Fill in your Client ID, email, and
              contact number, then click "Submit." You will receive your Client
              ID and password via email within 30 minutes.
            </span>
            <li className="mb-2 ">
              <span className=" font-semibold ">
                Log In to the Kotak API Portal:
              </span>{" "}
              <br />- Visit Kotak API Portal :{""}
              <div className="inline-flex flex-col items-start text-white font-normal ">
                <div className="flex items-center border  rounded-lg bg-blue-100 max-w-full">
                  <div className="pl-3 px-1 py-0.5 rounded-md text-sm overflow-hidden">
                    <a
                      href="https://napi.kotaksecurities.com/devportal/apis
"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 break-all"
                    >
                      napi.kotaksecurities.com/devportal/apis
                    </a>
                  </div>
                  <div
                    className="p-1 pl-2 border-gray-700 flex-shrink-0"
                    data-tooltip-id="copy-tooltip"
                    data-tooltip-content="COPY"
                  >
                    <ClipboardList
                      className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                      onClick={() =>
                        handleCopy("napi.kotaksecurities.com/devportal/apis")
                      }
                    />
                  </div>
                </div>
                <Tooltip id="copy-tooltip" />
              </div>
              <br />
              <span classname="py-1 ">
              Please login using the details found in the email referred above.
                            </span>
            </li>
            <li className="mb-2  ">
              <span className=" font-semibold ">Create an Application: </span>
              <br />- Navigate to the "Applications" section, click on "Add New
              Application," and save the details.
            </li>
            <li className="mb-2 ">
              <span className=" font-semibold ">Subscribe to APIs:</span>
              <br />- In the "Subscriptions" tab, subscribe to all the available
              APIs.
            </li>
            {/* <li>
              <span className=" font-semibold ">
                Generate API & Secret Keys:
              </span>
              <br />- Go to the "Production Keys" section and click "Generate
              Keys" to obtain your API Key and Secret Key.
            </li> */}
             <li>
              <span className=" font-semibold ">Copy and Enter the Keys:</span>
              <br />- Copy your Consumer Key and Consumer Secret, and input them
              in the required fields on the screen.
            </li>
            <li className="mb-2 ">
              <span className=" font-semibold ">Totp Registration:</span> <br />
              - go to :{""}
              <div className="inline-flex flex-col items-start text-white font-normal ">
                <div className="flex items-center border  rounded-lg bg-blue-100 max-w-full">
                  <div className="pl-3 px-1 py-0.5 rounded-md text-sm overflow-hidden">
                    <a
                      href="https://www.kotaksecurities.com/platform/kotak-neo-trade-api/
"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 break-all"
                    >
                      https://www.kotaksecurities.com/platform/kotak-neo-trade-api/
                    </a>
                  </div>
                  <div
                    className="p-1 pl-2 border-gray-700 flex-shrink-0"
                    data-tooltip-id="copy-tooltip"
                    data-tooltip-content="COPY"
                  >
                    <ClipboardList
                      className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                      onClick={() =>
                        handleCopy(
                          "https://www.kotaksecurities.com/platform/kotak-neo-trade-api/ "
                        )
                      }
                    />
                  </div>
                </div>
                <Tooltip id="copy-tooltip" />
              </div>
              <br />
              <span classname="py-1 ">
                select Register for Totp Totp registration is a one time step
                where you can register for totp on your mobile and start
                receiving totps
              </span>
            </li>
            <li>Verify your mobile no with OTP</li>
            <li>Select account, for which you want to register for totp</li>
            <li>Select option to register for totp</li>
            <li>You will receive a QR code, which is valid for 5mins</li>
            <li>Open any authenticator app, and scan the QR code</li>
            <li>
              You will start receiving the Totps on the authenticator apps
            </li>
            <li>
              Submit the totp on the QR code page to complete the Totp
              registration
            </li>
            {/* <li>
              <span className=" font-semibold ">Copy and Enter the Keys:</span>
              <br />- Copy your Consumer Key and Consumer Secret, and input them
              in the required fields on the screen.
            </li> */}
          </ol>
        </div>
      </div>

      <div className="mt-6 md:flex flex-col space-y-4 px-1 sm:px-12 ">
        <div className="text-2xl sm:text-3xl font-bold">
          Connect {state.broker}
        </div>
        <div className="text-base sm:text-base text-rose">
          We will not store your login credentials, you will need to enter it
          while accepting trades
        </div>
        <div className="flex md:flex-row flex-col items-center">
          <div className="w-full md:w-[48%]  text-base sm:text-lg  font-semibold ">
          Unique client code :{" "}
          </div>
          <div className={`relative ${style.inputStartDiv} `}>
            <input
              placeholder="Ucc Code"
              type="text"
              value={state.clientCode}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  clientCode: e.target.value,
                }))
              }
              className={`${style.inputBox}`}
            />
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center">
          <div className="w-full md:w-[48%] text-base sm:text-lg font-semibold ">
            Consumer Key :{" "}
          </div>
          <div className={`relative ${style.inputStartDiv} `}>
            <input
              placeholder="Api Key"
              type={state.showApiKey ? "text" : "password"}
              value={state.apiKey}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  apiKey: e.target.value,
                }))
              }
              className={`${style.inputBox}`}
            />
            {state.showApiKey ? (
              <EyeIcon
                onClick={() =>
                  setState((prev) => ({ ...prev, showApiKey: false }))
                }
                className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
              />
            ) : (
              <EyeOffIcon
                onClick={() =>
                  setState((prev) => ({ ...prev, showApiKey: true }))
                }
                className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
              />
            )}
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-center">
          <div className="w-full md:w-[48%]  text-base sm:text-lg  font-semibold ">
            Consumer Secret :{" "}
          </div>
          <div className={`relative ${style.inputStartDiv} `}>
            <input
              placeholder="Secret Key"
              type={state.showSecretKey ? "text" : "password"}
              value={state.secretKey}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  secretKey: e.target.value,
                }))
              }
              className={`${style.inputBox}`}
            />
            {state.showSecretKey ? (
              <EyeIcon
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    showSecretKey: false,
                  }))
                }
                className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
              />
            ) : (
              <EyeOffIcon
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    showSecretKey: true,
                  }))
                }
                className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
              />
            )}
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-center">
          <div className="w-full md:w-[48%]  text-base sm:text-lg  font-semibold ">
            Mobile Number :{" "}
          </div>
          <div className={`relative ${style.inputStartDiv} `}>
            <input
              placeholder="Mobile Number"
              type="number"
              value={state.mobileNumber}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  mobileNumber: e.target.value,
                }))
              }
              className={`${style.inputBox}`}
            />
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-center">
          <div className="w-full md:w-[48%]  text-base sm:text-lg  font-semibold ">
            Mpin :{" "}
          </div>
          <div className={`relative ${style.inputStartDiv} `}>
            <input
              placeholder="Mpin"
              type="text"
              value={state.mpin}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  mpin: e.target.value,
                }))
              }
              className={`${style.inputBox}`}
            />
          </div>
        </div>
   
        <div className="flex md:flex-row flex-col items-center">
          <div className="w-full md:w-[48%]  text-base sm:text-lg  font-semibold ">
            Totp :{" "}
          </div>
          <div className={`relative ${style.inputStartDiv} `}>
            <input
              placeholder="Totp"
              type="number"
              value={state.totp}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  totp: e.target.value,
                }))
              }
              className={`${style.inputBox}`}
            />
          </div>
        </div>

        <div className="">
          <button
            className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
            disabled={
              !state.apiKey ||
              !state.secretKey ||
              !state.mobileNumber ||
              !state.mpin ||
              !state.clientCode ||
              !state.totp
            }
            onClick={connectKotak}
          >
            {state.loading === true ? (
              <span className="flex flex-row justify-center ">
                <svg
                  className="h-7 w-7 text-[#ffffff] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            ) : (
              `Connect Kotak`
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default KotakConnection;
