import React, { useState } from "react";
import { XIcon, AlertTriangleIcon } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200",
  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  inputStartDiv: "relative w-full  ",
};

const DeleteModal = ({
  client,
  onClose,
  onDelete,
  deleteUserReason,
  setDeleteUserReason,
  deleteLoading,
}) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const location = useLocation();

  const handleGroupSelect = (group) => {
    setSelectedGroup(group === selectedGroup ? null : group); // Toggle selection
  };

  return (
    <AnimatePresence>
      <div
        className="fixed inset-0 flex items-center justify-center bg-black/75 p-4 z-50"
        onClick={(e) => e.target === e.currentTarget && onClose()}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.2 }}
          className="relative w-full max-w-md"
        >
          <div className="relative bg-white rounded-xl shadow-xl overflow-hidden">
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
              aria-label="Close modal"
            >
              <XIcon className="w-5 h-5 text-gray-500" />
            </button>

            {/* Modal Content */}
            <div className="p-6">
              <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 bg-red-100 rounded-full">
                <AlertTriangleIcon className="w-6 h-6 text-red-600" />
              </div>

              <h3 className="text-lg font-semibold text-gray-900 text-center mb-2">
                Confirm Delete
              </h3>

              <p className="text-center text-gray-600 mb-3">
                Are you sure you want to delete client{" "}
                <span className="font-semibold text-gray-900">
                  {client.clientName}
                </span>
                ? This action cannot be undone.
              </p>

              {location?.pathname === "/admin/client-list" ? (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Group
                  </label>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {client.groups
                      .filter((group) => group !== "All Client") // Exclude "All Client"
                      .map((group, index) => (
                        <button
                          key={index}
                          onClick={() => handleGroupSelect(group)}
                          className={`px-3 py-1 text-sm rounded-full transition border ${
                            selectedGroup === group
                              ? "bg-[#2056DF] text-white border-[#2056DF]"
                              : "bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200"
                          }`}
                        >
                          {group}
                        </button>
                      ))}
                  </div>
                </div>
              ) : null}

              <div className={`my-3 w-full ${style.inputStartDiv}`}>
                <input
                  id="clientCode"
                  placeholder="Reason for Delete (Optional)"
                  type="text"
                  value={deleteUserReason}
                  className={`w-full ${style.inputBox}`}
                  onChange={(e) => setDeleteUserReason(e.target.value)}
                />
                <label
                  htmlFor="clientCode"
                  className={`${style.firstHeading} ${style.labelFloat}`}
                >
                  Reason for Delete (Optional)
                </label>
              </div>

              <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 mt-3">
                <button
                  onClick={onClose}
                  className="flex-1 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (location?.pathname === "/admin/client-list") {
                      onDelete(client, selectedGroup);
                    } else {
                      onDelete(client);
                    }
                  }}
                  disabled={
                    location?.pathname === "/admin/client-list" &&
                    !selectedGroup
                  }
                  className={`flex-1 px-4 py-2 text-sm font-medium text-white rounded-lg transition ${
                    location?.pathname === "/admin/client-list" &&
                    !selectedGroup
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-red-600 hover:bg-red-700"
                  }`}
                >
                  {deleteLoading ? <LoadingSpinner /> : "Delete"}
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default DeleteModal;
