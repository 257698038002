import React, { useState, useEffect, useRef } from "react";
import {
  ChevronRight,
  ChevronDown,
  ChevronUp,
  XIcon,
  Loader2,
} from "lucide-react";
import Checked from "../../assests/checked.svg";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import LoadingSpinner from "../../components/LoadingSpinner";
import Tooltip from "../../components/Tooltip";
import { CountryCode } from "../../utils/CountryCode";

const url = process.env.REACT_APP_URL;

const style = {
  selectDiv2:
    "flex items-center px-2 py-2  rounded-lg mx-3 md-3 text-black-500  hover:bg-black/10 hover:text-black-500  ease-linear duration-150 cursor-pointer",
};

const PlanSubscribeModal = ({
  userEmail,
  name,
  setName,
  setMobileNumber,
  mobileNumber,
  setPanNumber,
  panNumber,
  strategyDetails,
  paymentModal,
  setPaymentModal,
  handleCardClick,
  selectedCard,
  handleOk,
  loading,
  setInvestAmount,
  invetAmount,
  isSubscribed,
  setCountryCode = () => {},
  countryCode = "+91",
  birthDate,
  setBirthDate,
  telegramId,
  setTelegramId,
}) => {
  const [isMobileNumberComplete, setIsMobileNumberComplete] = useState(false);
  const [isNameComplete, setIsNameComplete] = useState(false);
  const [isPanComplete, setIsPanComplete] = useState(false);
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [panError, setPanError] = useState("");
  const [consentChecked, setConsentChecked] = useState(false);
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const [linkContent, setLinkContent] = useState({ title: "", url: "" });
  const [isLinkOpen, setIsLinkOpen] = useState(false);

  const iframeRef = useRef(null);

  // Set default country code on component mount
  useEffect(() => {
    const defaultCountry = CountryCode.find(
      (country) => country.value === "+91"
    ); // India as default
    if (defaultCountry && !countryCode) {
      setSelectedCountry(defaultCountry);
      setCountryCode(defaultCountry.value);
    }
  }, []);

  // const handleNameChange = (e) => {
  //   const value = e.target.value;
  //   setName(value);
  //   setIsNameComplete(value&&value.trim().split(/\s+/).length >= 2);
  // };
  const handleNameChange = (e) => {
    const value = e.target.value;
    if (typeof setName === "function") {
      setName(value);
    }
    setIsNameComplete(value && value.trim().split(/\s+/).length >= 2);
  };

  // const handlePanChange = (e) => {
  //   const value = e.target.value.toUpperCase();
  //   const sanitizedValue = value.replace(/[^A-Z0-9]/g, "").slice(0, 10);
  //   setPanNumber(sanitizedValue);
  //   setIsPanComplete(sanitizedValue.length === 10);
  // };

  const handlePanChange = (e) => {
    const value = e.target.value.toUpperCase();
    const sanitizedValue = value.replace(/[^A-Z0-9]/g, "").slice(0, 10);
    if (typeof setPanNumber === "function") {
      setPanNumber(sanitizedValue);
    }
    setIsPanComplete(sanitizedValue.length === 10);
  };

  const handleMobileNumberChange = (e) => {
    let value = e.target.value;

    // Remove non-numeric characters
    value = value.replace(/\D/g, "");

    // Limit the length based on country
    const maxLength = selectedCountry?.code === "IN" ? 10 : 15;
    value = value.slice(0, maxLength);

    // Update the input value immediately for smooth typing
    setMobileNumber(value);

    // Validate the number
    try {
      const countryCodeWithoutPlus = countryCode.replace("+", "");
      const fullNumber = `+${countryCodeWithoutPlus}${value}`;
      const phoneNumber = parsePhoneNumberFromString(
        fullNumber,
        selectedCountry?.code
      );

      if (phoneNumber && phoneNumber.isValid()) {
        setIsMobileNumberComplete(true);
        setMobileNumberError("");
      } else {
        setIsMobileNumberComplete(false);
        if (value.length > 0) {
          setMobileNumberError("Please enter a valid mobile number");
        } else {
          setMobileNumberError("");
        }
      }
    } catch (error) {
      console.log("Phone number validation error:", error);
      setIsMobileNumberComplete(false);
      if (value.length > 0) {
        setMobileNumberError("Please enter a valid mobile number");
      } else {
        setMobileNumberError("");
      }
    }
  };

  const handleBirthDateChange = (e) => {
    setBirthDate(e.target.value);
  };

  const filteredCountryCodes = CountryCode.filter((country) =>
    `${country.value} ${country.label}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setCountryCode(country.value);
    setShowCountryCode(false);
    // Reset mobile number and validation when country changes
    setMobileNumber("");
    setMobileNumberError("");
    setIsMobileNumberComplete(false);
  };

  const validatePan = (pan) => {
    if (pan.length !== 10) return false;
    const firstFive = pan.slice(0, 5);
    const nextFour = pan.slice(5, 9);
    const lastOne = pan.slice(9);
    return (
      /^[A-Z]{5}$/.test(firstFive) &&
      /^\d{4}$/.test(nextFour) &&
      /^[A-Z]$/.test(lastOne)
    );
  };

  const openLinkInIframe = (title, path) => {
    setLinkContent({
      title,
      url: `https://${url}.alphaquark.in${path}`,
    });
    setIsLinkOpen(true);
    setIsIframeLoading(true);
  };

  const handleContinue = () => {
    handleOk();
  };
  useEffect(() => {
    // Select the first plan by default, but ONLY when the component mounts
    // or when strategyDetails changes (like when a new set of plans loads)
    if (
      strategyDetails?.frequency &&
      strategyDetails.frequency.length > 0 &&
      !selectedCard
    ) {
      const firstOffer = strategyDetails.frequency[0];
      handleCardClick(firstOffer);
    }
  }, [strategyDetails, handleCardClick, selectedCard]);
  const renderContent = () => (
    <div className="flex flex-col space-y-4  w-full sm:min-h-[400px] bg-white overflow-auto">
      <div className="capitalize flex w-full   text-[22px] text-[#000000] font-sans font-bold px-6 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]">
        <div className="text-black leading-[40px]">
          {isSubscribed
            ? `Renew ${strategyDetails?.name} Subscription`
            : `Invest in ${strategyDetails?.name}`}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-3  max-h-72 sm:max-h-[640px] overflow-auto sm:overflow-visible ">
        <div className="space-y-2">
          <label className="block text-sm  text-gray-700 font-semibold font-poppins">
            Name<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            onFocus={() => setIsNameComplete(false)}
            // onBlur={() =>
            //   setIsNameComplete(name.trim().split(/\s+/).length >= 2)
            // }
            onBlur={() => {
              if (name) {
                setIsNameComplete(name.trim().split(/\s+/).length >= 2);
              } else {
                setIsNameComplete(false);
              }
            }}
            placeholder="Enter your Name"
            className={`w-full h-10 rounded-md ${
              isNameComplete ? "border-green-500" : "border-gray-300"
            } border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal`}
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-normal text-gray-700">
            <div className="flex items-center font-semibold font-poppins">
              Email<span className="text-red-500">*</span>
            </div>
          </label>
          <input
            type="text"
            value={userEmail}
            disabled
            placeholder="Enter your Name"
            className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-normal text-gray-700">
            <div className="flex items-center font-semibold font-poppins">
              <span className="mr-2">
                Phone Number<span className="text-red-500">*</span>
              </span>
              <span className="text-xs font-normal text-gray-500">
                (WhatsApp enabled)
              </span>
            </div>
          </label>

          <div className="relative flex flex-row items-center">
            <div
              className={`h-10 mr-2 w-[20%] text-[#000000]/80 flex flex-row items-center justify-center relative cursor-pointer ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] rounded-[6px] py-2.5`}
              onClick={() => setShowCountryCode(!showCountryCode)}
            >
              <div className="text-[#000000]/80">
                {selectedCountry ? selectedCountry.value : "+91"}
              </div>
              <div
                className={`pl-1 ease-linear duration-150 text-[#000000]/25`}
              >
                {showCountryCode ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </div>
            </div>

            {showCountryCode && (
              <div className="absolute right-0 top-14 bg-white flex flex-col rounded-lg w-[100%] pt-2 pb-2 z-10 box-border border-2 border-solid border-[rgba(185, 192, 187, 0.7)] max-h-72 overflow-y-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  className="mx-3 px-3 py-2 mb-2 border rounded-md"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {filteredCountryCodes.length > 0 ? (
                  filteredCountryCodes.map((country, index) => (
                    <div
                      key={index}
                      className={`pl-3 font-medium ${style.selectDiv2}`}
                      onClick={() => handleCountrySelect(country)}
                    >
                      {country.value} {country.label}
                    </div>
                  ))
                ) : (
                  <div className="pl-3 font-medium text-gray-500">
                    No results found
                  </div>
                )}
              </div>
            )}

            <input
              type="tel"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              onFocus={() => setIsMobileNumberComplete(false)}
              onBlur={() =>
                setIsMobileNumberComplete(mobileNumber?.length === 10)
              }
              placeholder="Enter your Mobile Number"
              className={`w-full h-10 rounded-md border ${
                isMobileNumberComplete ? "border-green-500" : "border-gray-300"
              } text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal`}
            />
          </div>
          {mobileNumberError && (
            <p className="text-red-500 text-xs mt-1 font-poppins">
              {mobileNumberError}
            </p>
          )}
        </div>

        <div className="space-y-2">
          <label className="block text-sm text-gray-700 font-semibold font-poppins">
            Date of Birth<span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm  text-gray-700 font-semibold font-poppins">
            Pan Number<span className="text-red-500">*</span>
          </label>
          <Tooltip content="PAN Card number is compulsory as per SEBI Regulations. It is mandatory for us to upload it on SEBI Portal.">
            <input
              type="text"
              value={panNumber}
              onChange={handlePanChange}
              // onFocus={() => setIsPanComplete(false)

              // }
              onFocus={() => {
                setIsPanComplete(false);
                setPanError("");
              }}
              // onBlur={() => setIsPanComplete(panNumber?.length === 10)}

              onBlur={() => {
                const isValid = validatePan(panNumber);
                setIsPanComplete(isValid);
                if (!isValid && panNumber.length > 0) {
                  setPanError("Invalid PAN format. It should be AAAAA1234A");
                }
              }}
              maxLength={10}
              placeholder="Enter your PAN Number for KYC Check"
              className={`w-full h-10 rounded-md border ${
                isPanComplete
                  ? "border-green-500"
                  : panError
                  ? "border-red-500"
                  : "border-gray-300"
              } text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal`}
            />
          </Tooltip>
          {panError && (
            <p className="text-red-500 text-xs mt-1 font-poppins">{panError}</p>
          )}
        </div>

        {process.env.REACT_APP_TELEGRAM_REQUIRED === "true" ? (
          <div className="space-y-2">
            <label className="block text-sm font-normal text-gray-700">
              <div className="flex items-center font-semibold font-poppins">
                Telegram ID<span className="text-red-500">*</span>
              </div>
            </label>
            <Tooltip
              content={
                <ul className="list-disc pl-4">
                  <li>
                    Open the Telegram app and go to the search icon on the top
                    right.
                  </li>
                  <li>Type "@userinfobot" in the search bar.</li>
                  <li>
                    Click or tap on the "@userinfobot" profile that appears in
                    the search results.
                  </li>
                  <li>
                    In the chat with @userinfobot, select "Start" at the bottom
                    of the chat.
                  </li>
                  <li>The bot will then display your User ID.</li>
                </ul>
              }
            >
              <input
                type="text"
                value={telegramId}
                onChange={(e) => setTelegramId(e.target.value)}
                placeholder="Enter Telegram Id"
                className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
              />
            </Tooltip>
          </div>
        ) : null}
        {strategyDetails?.type === "model portfolio" ? (
          <div className="space-y-2 ">
            <label className="block text-sm  text-gray-700 font-semibold font-poppins">
              Invest Amount (₹)<span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              value={invetAmount}
              onChange={(e) => setInvestAmount(e.target.value)}
              placeholder={`Amount must be ₹${strategyDetails?.minInvestment} or more`}
              className="w-full h-10 rounded-md border border-gray-300 text-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent text-left font-normal"
            />
          </div>
        ) : null}
      </div>

      <div className="border-t border-b">
        <div className="space-y-2 py-4">
          <label className="block text-sm  text-gray-700 font-semibold font-poppins">
            Plan Fees<span className="text-red-500">*</span>
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 h-[66px] sm:h-[72px] px-2 overflow-auto pb-2">
            {strategyDetails?.frequency?.length !== 0 ? (
              strategyDetails?.frequency?.map((offer, i) => (
                <div
                  key={i}
                  className={`flex flex-col p-2 sm:p-3 rounded-md cursor-pointer ${
                    selectedCard === offer
                      ? "bg-[#E8F5F3] border-[#6AC2B1] border"
                      : "bg-white border-gray-200 border"
                  }`}
                  onClick={() => handleCardClick(offer)}
                >
                  <div className="flex justify-between items-center mb-1">
                    <span className="text-[10px] sm:text-xs">
                      {offer === "monthly"
                        ? "Monthly"
                        : offer === "quarterly"
                        ? "Quarterly"
                        : offer === "half-yearly"
                        ? "Half Yearly"
                        : "Yearly"}
                    </span>
                    <div
                      className={`w-3 h-3 rounded-full border flex items-center justify-center ${
                        selectedCard === offer
                          ? "border-[#6AC2B1] bg-[#6AC2B1]"
                          : "border-gray-300 bg-white"
                      }`}
                    >
                      {selectedCard === offer && (
                        <img src={Checked} className="w-2 h-2" alt="Checked" />
                      )}
                    </div>
                  </div>
                  <span className="font-medium">
                    ₹{strategyDetails?.pricing?.[offer]}
                  </span>
                </div>
              ))
            ) : (
              <div
                className={`flex flex-col p-3 rounded-md cursor-pointer bg-[#E8F5F3] border-[#6AC2B1] border`}
              >
                <div className="flex justify-between items-center mb-1">
                  <span className="text-xs">One Time</span>
                  <div className="w-4 h-4 rounded-full border bg-[#6AC2B1] flex items-center justify-center">
                    <img src={Checked} className="w-3 h-3" alt="Checked" />
                  </div>
                </div>
                <span className="text-sm font-medium">
                  ₹{strategyDetails?.amount}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="px-4">
        <div className="flex items-start max-h-10 space-x-2 overflow-auto">
          <input
            type="checkbox"
            id="consent"
            checked={consentChecked}
            onChange={(e) => setConsentChecked(e.target.checked)}
            className="mt-1"
          />
          <label htmlFor="consent" className="text-sm font-poppins">
            I have gone through the Disclaimers mentioned in the website. I'm
            purchasing this plan with understanding of disclaimers
          </label>
        </div>
        <div className="flex px-0 sm:px-5 items-center">
          <button
            onClick={() => openLinkInIframe("Disclaimer", "/disclaimer")}
            className="text-blue-500 underline text-sm font-poppins mr-2"
          >
            Disclaimer
          </button>
        </div>
        {isLinkOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-full h-full max-w-3xl flex flex-col">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-bold">{linkContent.title}</h2>
                <button
                  onClick={() => setIsLinkOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <XIcon size={24} />
                </button>
              </div>
              <div className="relative w-full h-full">
                {isIframeLoading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white">
                    <Loader2 className="w-12 h-12 animate-spin font-bold text-black" />
                  </div>
                )}
                <iframe
                  ref={iframeRef}
                  src={linkContent.url}
                  title={linkContent.title}
                  className="w-full h-full border-0"
                  onLoad={() => setIsIframeLoading(false)}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="px-3 pt-4">
        <button
          disabled={
            (strategyDetails?.frequency?.length !== 0 &&
              selectedCard === null) ||
            (strategyDetails?.type !== "bespoke" &&
              !(invetAmount >= strategyDetails?.minInvestment)) || // Only check invetAmount when type is not "bespoke"
            !userEmail ||
            !name ||
            !panNumber ||
            !mobileNumber ||
            !birthDate ||
            !consentChecked
          }
          onClick={handleOk}
          className="w-full p-3 rounded-lg bg-black text-white font-medium hover:bg-black/90 disabled:bg-black/30 disabled:cursor-not-allowed transition-colors duration-150 flex items-center justify-center"
        >
          <div className="text-white font-medium text-sm">
            <div className="flex items-center justify-center">
              {loading ? (
                <LoadingSpinner size={16} />
              ) : (
                <>
                  <span className="mr-2">
                    {isSubscribed ? "Renew now" : "Subscribe now"}
                  </span>
                  <ChevronRight size={16} />
                </>
              )}
            </div>
          </div>
        </button>
      </div>
    </div>
  );

  // if (isDesktop) {
  return (
    <Dialog open={paymentModal} onOpenChange={setPaymentModal}>
      <DialogContent className="max-w-2xl">{renderContent()}</DialogContent>
    </Dialog>
  );
};

// return (
//   <Drawer open={paymentModal} onOpenChange={setPaymentModal}>
//     <DrawerContent className="focus:outline-none">
//       <div className="relative">
//         <button
//           onClick={() => setPaymentModal(false)}
//           className="absolute top-4 right-4"
//         >
//           <XIcon className="h-6 w-6" />
//         </button>
//         {renderContent()}
//       </div>
//     </DrawerContent>
//   </Drawer>
//   );
// };

export default PlanSubscribeModal;
