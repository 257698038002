import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import AdminDashboardLayout from "../AdminDashboardLayout";
import PlanDetails from "./PlanDetails";
import { getAdminDetails } from "../CommonApiCall/getAdminDetails";

const PlanDetailsPage = () => {
  const { fileName } = useParams();
  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString
    ? JSON.parse(adminDetailsString)
    : null;
  const adminEmail = adminDetails ? adminDetails.email : null;

  const [data, setData] = useState();

  useEffect(() => {
    if (adminEmail) {
      getAdminDetails(adminEmail)
        .then((fetchedData) => {
          setData(fetchedData);
        })
        .catch((err) => console.log(err));
    }
  }, [adminEmail]);

  const userId = data && data?._id;
  const advisorName = data && data?.advisorName;

  return (
    <AdminDashboardLayout>
      <PlanDetails
        fileName={fileName}
        userId={userId && userId}
        adminEmail={adminEmail && adminEmail}
        advisorName={advisorName && advisorName}
      />
    </AdminDashboardLayout>
  );
};

export default PlanDetailsPage;
