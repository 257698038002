import React from "react";
import { RotateCw, Eye } from "lucide-react";
import FormatDateTime from "../../utils/formatDateTime";
import Tick from "../../assests/checked.svg";
import ProgressCircle from "./ProgressCircle";
import LoadingSpinner from "../../components/LoadingSpinner";

export const TableRow = ({
  item,
  setNotificationModal,
  setSelectedItem,
  handleResendAdvice,
  loadingAdviceId,
}) => {
  const isLoading = loadingAdviceId === item.adviseId;
  return (
    <tr className="hover:bg-gray-50 transition-colors">
      <td className="px-4 py-3 whitespace-nowrap text-sm text-left font-medium text-gray-900">
        {FormatDateTime(item.date)}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-left text-gray-900">
        {item.action === true ? (
          <span className="flex flex-row items-center">{item.Symbol}</span>
        ) : (
          <span className="flex flex-row items-center">
            {item.Symbol}{" "}
            <Eye
              className="h-4 w-4 ml-2 cursor-pointer"
              onClick={() => {
                setSelectedItem(item);
                setNotificationModal(true);
              }}
            />
          </span>
        )}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-left text-gray-900">
        {item.action === true ? (
          <span className="w-32 py-2 flex justify-center rounded-full border-2 border-gray-400 text-center items-center">
            <img src={Tick} alt="Tick" className="h-4 w-4 mr-2" />{" "}
            {item?.metricDetails?.reco_save_count}/
            {item?.metricDetails?.reco_save_count}{" "}
          </span>
        ) : (
          <div className="w-32 py-2 flex justify-center rounded-full border-2 border-gray-400 text-center items-center">
            <ProgressCircle percentage={item?.platformProgress} />
            <span className="text-sm text-gray-800">
              {item?.metricDetails?.reco_save_count}/
              {item?.metricDetails?.reco_save_count}{" "}
            </span>
          </div>
        )}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-left text-gray-900">
        {item.action === true ? (
          <span className="w-32 py-2 flex justify-center rounded-full border-2 border-gray-400 text-center items-center">
            <img src={Tick} alt="Tick" className="h-4 w-4 mr-2" />{" "}
            {item?.metricDetails?.sent_whatsapp_count}/
            {item?.metricDetails?.reco_save_count}{" "}
          </span>
        ) : (
          <div className="w-32 py-2 flex justify-center rounded-full border-2 border-gray-400 text-center items-center">
            <ProgressCircle percentage={item?.progress} />
            <span className="text-sm text-gray-800">
              {item?.metricDetails?.sent_whatsapp_count}/
              {item?.metricDetails?.reco_save_count}{" "}
            </span>
          </div>
        )}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-left text-gray-900">
        {item.action === true ? (
          <span className="w-32 py-2 flex justify-center rounded-full border-2 border-gray-400 text-center items-center">
            <img src={Tick} alt="Tick" className="h-4 w-4 mr-2" />{" "}
            {item?.metricDetails?.total_sent_mails}/
            {item?.metricDetails?.reco_save_count}{" "}
          </span>
        ) : (
          <div className="w-32 py-2 flex justify-center rounded-full border-2 border-gray-400 text-center items-center">
            <ProgressCircle percentage={item?.emailProgress} />
            <span className="text-sm text-gray-800">
              {item?.metricDetails?.total_sent_mails}/
              {item?.metricDetails?.reco_save_count}{" "}
            </span>
          </div>
        )}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-center text-sm text-gray-900">
        {item.action === true ? (
          <span className="flex flex-row justify-center items-center">
            <img src={Tick} alt="Tick" className="h-4 w-4 mr-2" /> All Advice
            Sent
          </span>
        ) : (
          <div className="flex justify-center items-center">
            <button
              className="py-2 px-4 flex flex-row justify-center items-center bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
              onClick={() =>
                handleResendAdvice(
                  item.unsentEmails,
                  item.symbolDetails,
                  item.metricDetails
                )
              }
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <span className="flex flex-row items-center">
                  {" "}
                  <RotateCw className="h-4 w-4 mr-2" />
                  Send Again
                </span>
              )}
            </button>
          </div>
        )}
      </td>
    </tr>
  );
};
