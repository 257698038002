import React, { useState, useEffect } from "react";
import { Edit2Icon, Trash2Icon } from "lucide-react";
import moment from "moment";

import server from "../../utils/serverConfig";
import { encryptApiKey } from "../../utils/cryptoUtils";

const ACCEPTABLE_DATE_FORMATS = [
  "D MMM YYYY, HH:mm:ss",
  "YYYY-MM-DDTHH:mm:ss.SSSZ",
];

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
export const ClientTableRow = ({
  groupName,
  item,
  isSelected,
  onCheckboxChange,
  onEdit,
  onDelete,
  isSubscriptionValid,
}) => {
  const [kycStatus, setKycStatus] = useState("Checking...");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllUserKyc = async () => {
      try {
        const response = await fetch(
          `${server.ccxtServer.baseUrl}misc/kyc/kra/status/${advisorTag}/${item.email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "X-Advisor-Subdomain": process.env.REACT_APP_URL,
              "aq-encrypted-key": encryptApiKey(
                process.env.REACT_APP_AQ_KEYS,
                process.env.REACT_APP_AQ_SECRET
              ),
            },
          }
        );

        const data = await response.json();
        console.log("data", data);
        setKycStatus(data?.kycStatus || "Not Available");
      } catch (error) {
        console.error("Error fetching KYC status:", error);
        setKycStatus("Error");
      }
    };

    getAllUserKyc();
  }, [item.email]);

  const handleVerifyPan = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}misc/kyc/kra/verify-pan/${advisorTag}/${item.email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Advisor-Subdomain": process.env.REACT_APP_URL,
            "aq-encrypted-key": encryptApiKey(
              process.env.REACT_APP_AQ_KEYS,
              process.env.REACT_APP_AQ_SECRET
            ),
          },
        }
      );

      const data = await response.json();
      console.log("Verify PAN response:", data);

      // Update the KYC status based on API response
      setKycStatus(data?.kycStatus || "Not Available");
    } catch (error) {
      console.error("Error verifying PAN:", error);
      setKycStatus("Verification Failed");
    }
    setLoading(false);
  };

  const matchingPlanSubs = item?.subscriptions?.filter(
    (sub) => sub?.plan === groupName
  );
  const validSubscriptions = matchingPlanSubs?.filter((sub) =>
    sub.expiry
      ? moment(sub?.expiry, ACCEPTABLE_DATE_FORMATS, true).isValid()
      : false
  );
  if (validSubscriptions?.length === 0) return false;
  const latestSub = validSubscriptions?.sort(
    (a, b) =>
      moment(b?.expiry, ACCEPTABLE_DATE_FORMATS) -
      moment(a?.expiry, ACCEPTABLE_DATE_FORMATS)
  )[0];
  const expiryDate = moment(latestSub?.expiry, ACCEPTABLE_DATE_FORMATS);

  return (
    <tr
      className={
        isSubscriptionValid(item?.subscriptions, groupName) === false
          ? "bg-gray-300 transition-colors"
          : "hover:bg-gray-50 transition-colors"
      }
    >
      <td className="px-4 py-3 whitespace-nowrap">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={(e) => onCheckboxChange(item, e.target.checked)}
          disabled={!isSubscriptionValid(item?.subscriptions, groupName)}
          className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.email}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.clientName}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item?.country_code}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.phone}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item?.DateofBirth
          ? moment(item?.DateofBirth).format("DD MMM YYYY")
          : "-"}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.location}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.telegram || "-"}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {item.pan || "-"}
      </td>

      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900">
        {kycStatus !== "Valid" ? (
          <div className="flex items-center space-x-2">
            <button
              onClick={handleVerifyPan}
              className="text-blue-600 hover:text-blue-800 transition-colors"
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify PAN"}
            </button>
          </div>
        ) : (
          "Valid"
        )}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {isSubscriptionValid(item?.subscriptions, groupName) === true
          ? "Active"
          : latestSub?.status === "deleted"
          ? "Deleted"
          : "Expired"}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        {moment(expiryDate).format("DD MMM YYYY")}
      </td>
      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
        <div className="flex items-center space-x-3">
          <button
            onClick={() => onEdit(item)}
            className="text-gray-600 hover:text-gray-900 transition-colors"
            aria-label="Edit client"
          >
            <Edit2Icon className="h-4 w-4" />
          </button>
          <button
            onClick={() => onDelete(item)}
            className="text-gray-600 hover:text-red-600 transition-colors"
            aria-label="Delete client"
          >
            <Trash2Icon className="h-4 w-4" />
          </button>
        </div>
      </td>
    </tr>
  );
};
