import React, { useState } from "react";
import { Plus, XIcon } from "lucide-react";
import { PreviousBasketAdvices } from "../previousAdvices/previousBasketAdvices";

const BasketDerivativeAdviceTable = ({
  adviceBasketEntries,
  setAdviceBasketEntries,
  handleAddBasketEntry,
  handleBasketSymbolSelect,
  handleCommonFieldChange,
  showQuantityColumn,
  handleBasketChange,
  handleRemoveBasketEntry,
  setShowPreviousBasketAdvices,
}) => {
  return (
    <div className="mt-4 p-4 border border-gray-200 rounded-lg">
      <div className="mb-4 flex flex-row items-center space-x-4 border-gray-200">
        <button
          onClick={handleAddBasketEntry}
          className="inline-flex  items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add
        </button>
        <button
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
          onClick={() => setShowPreviousBasketAdvices(true)}
        >
          Send Previous Basket
        </button>
      </div>
      <span className="text-sm text-gray-500">
        {adviceBasketEntries.length} entr
        {adviceBasketEntries.length === 1 ? "y" : "ies"}
      </span>
      <div className="overflow-x-auto custom-scroll rounded-lg border border-gray-200 shadow-sm">
        <table className="min-w-full divide-y divide-gray-200">
          <colgroup>
            <col className="w-[200px]" /> {/* Segment */}
            <col className="w-[250px]" /> {/* Symbol */}
            <col className="w-[180px]" /> {/* Strike */}
            <col className="w-[200px]" /> {/* Lots */}
            <col className="w-[250px]" /> {/* Order Type */}
            <col className="w-[250px]" /> {/* Product Type */}
            <col className="w-[250px]" /> {/* Option Type */}
            <col className="w-[100px]" /> {/* price */}
            <col className="w-[150px]" /> {/* action */}
            <col className="w-[50px]" /> {/* Priority */}
          </colgroup>

          <thead className="bg-gray-50">
            <tr>
              <td colSpan={7} className="px-4 py-4 border border-b">
                <div className="grid grid-cols-4 gap-4">
                  <div>
                    <h4 className="text-black font-semibold font-poppins mb-2">
                      Basket Name
                    </h4>
                    <div className="mb-2">
                      <textarea
                        type="text"
                        value={adviceBasketEntries[0].commonBasketName}
                        onChange={(e) =>
                          handleCommonFieldChange(
                            "commonBasketName",
                            e.target.value
                          )
                        }
                        className="w-full px-3 py-1.5 border text-black border-gray-300 rounded-md"
                        rows="3"
                        placeholder="Enter basket name..."
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-black font-semibold font-poppins mb-2">
                      Rationale
                      <span className="text-red-500">*</span>
                    </h4>

                    <div className="mb-2">
                      <textarea
                        value={adviceBasketEntries[0].commonRationale}
                        onChange={(e) =>
                          handleCommonFieldChange(
                            "commonRationale",
                            e.target.value
                          )
                        }
                        className="w-full px-3 py-1.5 border text-black border-gray-300 rounded-md"
                        rows="3"
                        placeholder="Enter rationale..."
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-black font-semibold font-poppins  mb-2">
                      Comments{" "}
                    </h4>
                    <div className="mb-2">
                      <textarea
                        value={adviceBasketEntries[0].commonComment}
                        onChange={(e) =>
                          handleCommonFieldChange(
                            "commonComment",
                            e.target.value
                          )
                        }
                        className="w-full px-3 py-1.5 border text-black border-gray-300 rounded-md"
                        rows="3"
                        placeholder="Enter comments..."
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-black font-semibold font-poppins mb-2">
                      Extended Comments
                    </h4>
                    <div className="mb-2">
                      <textarea
                        value={adviceBasketEntries[0].commonExtendedComment}
                        onChange={(e) =>
                          handleCommonFieldChange(
                            "commonExtendedComment",
                            e.target.value
                          )
                        }
                        className="w-full px-3 py-1.5 border text-black border-gray-300 rounded-md"
                        rows="3"
                        placeholder="Enter extended comment..."
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="h-16">
              <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Segment <span className="text-red-500">*</span>
              </th>
              <th className="px-3 py-2 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Symbol <span className="text-red-500">*</span>
              </th>
              {adviceBasketEntries.some(
                (entry) => entry.foType === "OPTIONS"
              ) && (
                <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Strike  <span className="text-red-500">*</span>
                </th>
              )}

              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Lots <span className="text-red-500">*</span>
              </th>

              <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Option Type <span className="text-red-500">*</span>
              </th>

              <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Stop Loss
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Profit Target
              </th>
              <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Order Type
              </th>
              <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Product Type{" "}
              </th>

              {/* <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Price <span className="text-red-500">*</span>
          </th> */}
              <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Priority
              </th>
              <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {adviceBasketEntries.map((entry, index) => (
              <tr
                key={entry.id}
                className="hover:bg-gray-50 transition-colors min-h-[120px]"
              >
                {/* Type */}
                <td className="px-4 py-4">
                  <select
                    value={entry.foType || "OPTIONS"}
                    onChange={(e) =>
                      handleBasketChange(index, "foType", e.target.value)
                    }
                    className="w-full  min-w-[180px] font-poppins text-base px-3 py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select Type</option>
                    {["OPTIONS", "FUTURES"].map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>

                {/* Symbol */}
                <td className="px-3 py-2">
                  <div className="relative">
                    <input
                      type="text"
                      value={entry.searchSymbol || ""}
                      onChange={(e) =>
                        handleBasketChange(
                          index,
                          "searchSymbol",
                          e.target.value
                        )
                      }
                      className="w-[250px] font-poppins px-3 py-1.5 border text-black border-gray-300 rounded-md"
                      placeholder="Enter symbol"
                    />

                    {entry.symbols &&
                      entry.symbols.length > 0 &&
                      entry.showBasketDropdown && (
                        <div className="absolute font-poppins text-black z-10 w-full mt-1 bg-white rounded-md shadow-lg">
                          {Array.from(
                            new Set(
                              entry.symbols.map((symbol) => symbol.searchSymbol)
                            )
                          ).map((uniqueSymbol, idx) => {
                            const symbol = entry.symbols.find(
                              (s) => s.searchSymbol === uniqueSymbol
                            );
                            return (
                              <div
                                key={idx}
                                onClick={() =>
                                  handleBasketSymbolSelect(
                                    index,
                                    symbol,
                                    symbol.lotsize,
                                    symbol.exchange,
                                    symbol.strikes,
                                    symbol.optionTypes
                                  )
                                }
                                className="px-4 py-2.5 font-poppins hover:bg-gray-100 cursor-pointer"
                              >
                                {symbol.searchSymbol}
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                </td>

                {/* Strike (CE/PE) */}
                {entry?.foType === "OPTIONS" && (
                  <td className="px-4 py-4">
                    <div className="flex items-center">
                      {/* <input
                    type="text"
                    value={`${entry.strike || 'OPTIONS'}`}
                    onChange={(e) => handleBasketChange(index, 'strike', e.target.value)}
                    className="w-full min-w-[120px] px-3 py-1.5 border text-black border-gray-300 rounded-md text-center"
                    placeholder="Strike"
                  /> */}
                      <select
                        value={entry?.strike}
                        onChange={(e) =>
                          handleBasketChange(index, "strike", e.target.value)
                        }
                        className="ml-2 font-poppins px-3 w-full min-w-[120px] py-1.5 border text-black border-gray-300 rounded-md"
                      >
                        <option value="">Select</option>
                        {entry?.strikes?.map((strike, idx) => (
                          <option key={idx} value={strike}>
                            {strike}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                )}
                <td className="px-5 py-4">
                  <div className="flex flex-row items-center space-x-2">
                    {/* Lot Multiplier Input */}
                    <input
                      type="number"
                      value={entry.lotMultiplier} // Controlled input
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        const validatedValue =
                          isNaN(value) || value <= 0 ? "" : value;
                        handleBasketChange(
                          index,
                          "lotMultiplier",
                          validatedValue
                        );
                      }}
                      className="w-[60px] font-poppins text-center text-base px-1 py-1 border text-black border-gray-300 rounded-md"
                      min="1"
                    />
                    <span className="text-sm text-gray-500">lot</span>
                    <span className="text-sm text-gray-500">=</span>
                    {/* Calculated Lots Input */}
                    <input
                      type="number"
                      value={entry.lotMultiplier * entry.lots}
                      readOnly
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        const validatedValue =
                          isNaN(value) || value < 0 ? 0 : value;
                        const newLots = Math.max(
                          Math.floor(validatedValue / entry.lotMultiplier),
                          0
                        );
                        setAdviceBasketEntries((prevEntries) =>
                          prevEntries.map((item, i) =>
                            i === index ? { ...item, lots: newLots } : item
                          )
                        );
                      }}
                      className="w-[80px] font-poppins text-base px-2 py-1 border text-black border-gray-300 rounded-md"
                      min="0"
                    />
                    <span className="text-sm text-gray-500">qty</span>
                  </div>
                </td>

                {/* Option Type */}
                <td>
                  <select
                    value={entry.optionType}
                    onChange={(e) =>
                      handleBasketChange(index, "optionType", e.target.value)
                    }
                    className="ml-2  px-3 w-full min-w-[120px] py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select</option>
                    {entry.optionTypes.map((type, idx) => (
                      <option key={idx} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>

                {/* TYPE */}
                <td className="px-4 py-4">
                  <select
                    value={entry.orderType}
                    onChange={(e) =>
                      handleBasketChange(index, "orderType", e.target.value)
                    }
                    className="w-[150px] font-poppins text-base px-3 py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select Type</option>
                    {["BUY", "SELL"].map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>


 {/* stop loss*/}
 <td className="px-4 py-4 min-w-[150px]">
                  <input
                    type="number"
                    value={entry.stopLoss || ""}
                    onChange={(e) =>
                      handleBasketChange(index, "stopLoss", e.target.value)
                    }
                    className="w-full min-w-[10px] px-3 py-2.5 border text-black border-gray-300 rounded-md"
                    step="0.01"
                  />
                </td>
                {/* profit target*/}
                <td className="px-4 py-4 min-w-[150px]">
                  {" "}
                  <input
                    type="number"
                    value={entry.profitTarget || ""}
                    onChange={(e) =>
                      handleBasketChange(index, "profitTarget", e.target.value)
                    }
                    className="w-full min-w-[10px] px-3 py-2.5 border text-black border-gray-300 rounded-md"
                    step="0.01"
                  />
                </td>


                {/* Order Type */}
                <td className="px-4 py-4">
                  <select
                    value={entry.order || "MARKET"}
                    onChange={(e) =>
                      handleBasketChange(index, "order", e.target.value)
                    }
                    className="w-full font-poppins min-w-[120px] px-3 py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select</option>
                    {["MARKET", "LIMIT"].map((order, idx) => (
                      <option key={idx} value={order}>
                        {order}
                      </option>
                    ))}
                  </select>
                </td>

                {/* Product Type */}
                <td className="px-4 py-4">
                  <select
                    value={entry.productType || "CARRYFORWARD"}
                    onChange={(e) =>
                      handleBasketChange(index, "productType", e.target.value)
                    }
                    className="ml-2 px-2 font-poppins max-w-[180px]  py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select</option>
                    {["CARRYFORWARD", "INTRADAY"].map((productType, idx) => (
                      <option key={idx} value={productType}>
                        {productType}
                      </option>
                    ))}
                  </select>
                </td>

                {/* Price */}
                {/* <td className="px-4 py-4 min-w-[150px]">
              <input
                type="number"
                value={entry.symbol ? (getLTPForSymbol2(entry.symbol) || "No data") : ""}
                onChange={(e) => handleBasketChange(index, 'price', e.target.value)}
                className="w-full min-w-[10px] px-3 py-1.5 border text-black border-gray-300 rounded-md"
                step="0.01"
              />
            </td> */}

                {/* Priority */}
                <td className="px-4 py-4">
                  <input
                    type="number"
                    value={entry.priority}
                    onChange={(e) =>
                      handleBasketChange(index, "priority", e.target.value)
                    }
                    className="w-[100px] font-poppins text-base px-3 py-1.5 border text-black border-gray-300 rounded-md"
                  />
                </td>

                {/* Actions */}
                <td className="px-4 py-4">
                  {adviceBasketEntries.length > 1 && (
                    <button
                      onClick={() => handleRemoveBasketEntry(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <XIcon className="h-5 w-5" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BasketDerivativeAdviceTable;
