import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { X, Loader2 } from "lucide-react";
import moment from "moment";

import server from "../utils/serverConfig";
import Footer from "./Footer";
import Logo from "./assests/Logo.jpg";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import Advisor from "./assests/About/advisor.jpg";
import { encryptApiKey } from "../utils/cryptoUtils";
const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
const WebinarLandingPage = () => {
  // const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClick = () => {
    navigate("/user");
  };

  const handleRegister = () => {
    setShowModal(true);
  };

  const [razorpayLoader, setRazorpayLoader] = useState(false);
  const [isPostPaymentProcessing, setIsPostPaymentProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleSubmit = async () => {
    if (!name || !email || !phone) {
      setError("Please fill all fields");
      return; // Stop execution if validation fails
    }

    try {
      // await initiateSinglePayment(
      //   upcomingWebinar,
      //   email,
      //   name,
      //   phone,
      //   countryCode,
      //   setLoading,
      //   setShowModal,
      //   setRazorpayLoader,
      //   setIsPostPaymentProcessing,
      //   setPaymentSuccess
      // );
    } catch (error) {
      console.error("Payment initiation failed:", error);
      setError("Payment failed. Please try again.");
    }
  };

  const [upcomingWebinar, setUpcomingWebinar] = useState(null);
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const getAllWebinar = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/all-plans/specific-advisor/plan/webinar/${advisorTag}`,
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const now = moment(); // Get current time

          // Ensure all webinars have `startDate` as a moment object and are sorted correctly
          const sortedWebinars = response?.data
            ?.map((webinar) => ({
              ...webinar,
              startDate: moment(webinar.start_date), // Ensure moment object
            }))
            .filter((webinar) => webinar.startDate.isValid()) // Filter out invalid dates
            .sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf()); // Sort by date

          // Find the closest upcoming webinar
          const closestWebinar = sortedWebinars.find((webinar) =>
            now.isBefore(webinar.start_date)
          );

          // Update state
          setUpcomingWebinar(closestWebinar || null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllWebinar();
  }, []);

  useEffect(() => {
    if (!upcomingWebinar) return;

    const updateCountdown = () => {
      const now = moment();
      const startTime = moment(upcomingWebinar.start_date);
      const diff = moment.duration(startTime.diff(now));

      if (diff.asSeconds() <= 0) {
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      setTimeLeft({
        hours: Math.floor(diff.asHours()),
        minutes: diff.minutes(),
        seconds: diff.seconds(),
      });
    };

    updateCountdown(); // Initial call
    const timerInterval = setInterval(updateCountdown, 1000);

    return () => clearInterval(timerInterval);
  }, [upcomingWebinar]);

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 border-b bg-white">
        <div className="max-w-[1400px] mx-auto px-6 lg:px-8">
          <div className="flex items-center justify-between py-4">
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img
                src={Logo}
                alt="Master The Market"
                className="h-12 w-12 rounded-md"
              />
              <span className="text-2xl font-neo-sans text-black">
                {process.env.REACT_APP_WHITE_LABEL_TEXT}
              </span>
            </div>

            <div className="hidden md:flex items-center space-x-12">
              <button
                className="bg-[#4682B4] text-[#ffffff] px-6 py-2 rounded-md hover:bg-[#3a6d94] duration-300 font-medium border-none"
                onClick={handleClick}
              >
                {userEmail ? "Dashboard" : "How to Invest"}
              </button>
            </div>

            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-black focus:outline-none"
                aria-label="toggle menu"
              >
                {!isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>

          {isOpen && (
            <div className="md:hidden py-4 bg-[#0C243C]">
              <div className="flex flex-col space-y-4">
                <div className="px-4">
                  <button
                    className="w-full bg-[#5CD6C0] text-[#0F0F0F] px-6 py-2 rounded-md hover:bg-[#4bc0ac] transition-colors duration-300 font-medium"
                    onClick={() => {
                      handleClick();
                      toggleMenu();
                    }}
                  >
                    {userEmail ? "Dashboard" : "How to Invest"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>

      <div className="py-8 bg-gradient-to-b from-white to-teal-50 min-h-screen text-gray-800">
        {/* Hero Section */}
        <div className="bg-gradient-to-b from-white to-teal-50 text-gray-800 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row items-center">
              {/* Left content - Video/Image placeholder */}
              <div className="md:w-1/2 mb-8 md:mb-0 flex justify-center">
                <div className="relative w-full max-w-md aspect-video bg-gray-200 rounded-lg overflow-hidden shadow-xl">
                  {/* Video thumbnail placeholder with play button */}
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="bg-[#4682B4] text-white rounded-full w-16 h-16 flex items-center justify-center cursor-pointer hover:bg-[#3a6d94] transition-colors">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* Placeholder for video thumbnail */}
                  <div className="w-full h-full bg-gray-300"></div>
                </div>
              </div>

              {/* Right content - Webinar info */}
              <div className="md:w-1/2 md:pl-12 space-y-6">
                <div className="inline-block bg-teal-50 text-teal-600 px-4 py-2 rounded-md text-sm font-semibold">
                  EXCLUSIVE WEBINAR
                </div>

                <div className="flex flex-col gap-2">
                  <h2 className="text-2xl font-bold leading-tight text-gray-900">
                    Stock Market
                  </h2>
                  <h1 className="text-5xl font-bold leading-tight text-[#4682B4]">
                    {upcomingWebinar?.name}
                  </h1>
                  <p className="text-2xl text-[#4682B4]">
                    Gain clarity, confidence and control
                  </p>
                </div>

                <p className="text-xl text-gray-700">
                  {upcomingWebinar?.description}
                </p>

                <div className="flex flex-col sm:flex-row gap-4 pt-4">
                  <div className="flex items-center gap-3">
                    <div className="text-[#4682B4]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-700 font-medium">
                      {moment(upcomingWebinar?.start_date).format(
                        "dddd, MMMM D, YYYY"
                      )}
                    </span>
                  </div>

                  <div className="flex items-center gap-3">
                    <div className="text-[#4682B4]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <span className="text-gray-700 font-medium">
                      {moment(
                        upcomingWebinar?.timeSlot?.startTime,
                        "HH:mm"
                      ).format("h:mm A")}{" "}
                      to{" "}
                      {moment(
                        upcomingWebinar?.timeSlot?.endTime,
                        "HH:mm"
                      ).format("h:mm A")}{" "}
                      IST
                    </span>
                  </div>
                </div>

                <button
                  className="mt-6 bg-[#4682B4] text-white px-8 py-4 rounded-md hover:bg-[#3a6d94] transition-colors duration-300 font-bold text-lg shadow-lg"
                  onClick={handleRegister}
                >
                  Register Now For
                  {upcomingWebinar?.amountWithOffer !== null ? (
                    <span className="relative z-1 line-through decoration-2">
                      {" "}
                      ₹{upcomingWebinar?.amountWithOffer}{" "}
                    </span>
                  ) : null}
                  <span className="ml-1">₹{upcomingWebinar?.amount}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Timer Section */}
        <div className="py-12 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <div className="mb-4 text-2xl md:text-3xl font-bold text-blue-900">
              Limited Seats Available
            </div>

            <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
              Join 5,000+ traders and investors who have transformed their
              market approach with our expert guidance
            </p>

            <div className="flex justify-center items-center gap-4 mb-6">
              <div className="text-center">
                <div className="text-5xl font-bold text-blue-900">
                  {timeLeft.hours}
                </div>
                <div className="text-sm text-gray-500">HOURS</div>
              </div>
              <div className="text-4xl font-bold text-blue-900">:</div>
              <div className="text-center">
                <div className="text-5xl font-bold text-blue-900">
                  {timeLeft.minutes.toString().padStart(2, "0")}
                </div>
                <div className="text-sm text-gray-500">MINUTES</div>
              </div>
              <div className="text-4xl font-bold text-blue-900">:</div>
              <div className="text-center">
                <div className="text-5xl font-bold text-blue-900">
                  {timeLeft.seconds.toString().padStart(2, "0")}
                </div>
                <div className="text-sm text-gray-500">SECONDS</div>
              </div>
            </div>

            <div className="text-2xl font-bold text-blue-900 mb-8">
              Reserve Your Seat Before The Timer Ends
            </div>

            <button
              className="bg-[#4682B4] text-white px-8 py-4 rounded-md hover:bg-[#3a6d94] transition-colors duration-300 font-bold text-lg shadow-lg"
              onClick={handleRegister}
            >
              Register Now For
              {upcomingWebinar?.amountWithOffer !== null ? (
                <span className="relative z-1 line-through decoration-2">
                  {" "}
                  ₹{upcomingWebinar?.amountWithOffer}{" "}
                </span>
              ) : null}
              <span className="ml-1">₹{upcomingWebinar?.amount}</span>
            </button>
          </div>
        </div>

        {/* What You'll Learn Section */}
        <div className="py-16 bg-gradient-to-b from-teal-50 to-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                What You Will Learn in This Webinar
              </h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                Practical strategies and insights to help you succeed in the
                stock market
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Learning point 1 - Fundamental Analysis */}
              <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
                <div className="text-[#4682B4] mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  Fundamental Analysis
                </h3>
                <p className="text-gray-600">
                  Learn how to evaluate a company's financial health, business
                  model, and growth prospects
                </p>
              </div>

              {/* Learning point 2 - Technical Analysis */}
              <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
                <div className="text-[#4682B4] mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  Technical Analysis
                </h3>
                <p className="text-gray-600">
                  Master chart patterns, indicators, and price action to time
                  your entries and exits
                </p>
              </div>

              {/* Learning point 3 - Risk Management */}
              <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
                <div className="text-[#4682B4] mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  Risk Management
                </h3>
                <p className="text-gray-600">
                  Strategies to protect your capital and maximize returns
                  through proper position sizing and stop-loss techniques
                </p>
              </div>

              {/* Learning point 4 - Stock Selection */}
              <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
                <div className="text-[#4682B4] mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  Stock Selection
                </h3>
                <p className="text-gray-600">
                  A step-by-step framework to identify high-potential stocks for
                  consistent returns
                </p>
              </div>

              {/* Learning point 5 - Live Q&A */}
              <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
                <div className="text-[#4682B4] mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  Live Q&A with Anant Agrawal
                </h3>
                <p className="text-gray-600">
                  Get expert insights and clear all your doubts in a direct
                  interaction
                </p>
              </div>

              {/* Learning point 6 - Avoiding Costly Mistakes 
              <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
                <div className="text-[#4682B4] mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  Avoiding Costly Mistakes
                </h3>
                <p className="text-gray-600">
                  Common pitfalls that most traders & investors fall into and
                  how to avoid them
                </p>
              </div>
              */}
            </div>
          </div>
        </div>

        {/* Who Should Attend Section */}
        <div className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row gap-12">
              {/* Left side - Image */}
              <div className="md:w-1/2 flex justify-center items-center">
                <div className="relative rounded-lg overflow-hidden w-full max-w-md aspect-[3/4] shadow-xl bg-gray-300">
                  <img
                    src={Advisor}
                    alt="Anant Agrawal"
                    className="w-full h-full object-cover rounded-lg shadow-xl relative z-10"
                  />
                </div>
              </div>

              {/* Right side - Content */}
              <div className="md:w-1/2">
                <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-8">
                  Who Should Attend?
                </h2>

                <div className="space-y-6">
                  <div className="flex items-start gap-4">
                    <div className="text-[#4682B4] pt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-gray-900">
                        Beginners
                      </h3>
                      <p className="text-gray-600">
                        Kickstart your stock market journey with confidence and
                        clarity
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="text-[#4682B4] pt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-gray-900">
                        Traders
                      </h3>
                      <p className="text-gray-600">
                        Improve strategies & increase profitability with proven
                        techniques
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="text-[#4682B4] pt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-gray-900">
                        Investors
                      </h3>
                      <p className="text-gray-600">
                        Learn to make informed, profitable stock selections for
                        long-term wealth
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-12">
                  <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-8">
                    Why You Should Attend?
                  </h2>

                  <div className="space-y-6">
                    <div className="flex items-start gap-4">
                      <div className="text-[#4682B4] pt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <div>
                        <h3 className="text-xl font-bold text-gray-900">
                          Simple & Actionable Strategies
                        </h3>
                        <p className="text-gray-600">
                          No complex jargon or unnecessary theories, just
                          practical wisdom
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start gap-4">
                      <div className="text-[#4682B4] pt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <div>
                        <h3 className="text-xl font-bold text-gray-900">
                          Gain Clarity & Confidence
                        </h3>
                        <p className="text-gray-600">
                          Learn to analyze stocks the right way and make
                          decisions with confidence
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start gap-4">
                      <div className="text-[#4682B4] pt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <div>
                        <h3 className="text-xl font-bold text-gray-900">
                          Proven Framework
                        </h3>
                        <p className="text-gray-600">
                          Learn from an expert with real market experience and a
                          track record of success
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="py-16 bg-[#0C243C] text-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              Limited Seats Available - Reserve Yours Now
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
              Don't miss this opportunity to transform your stock market
              approach with expert guidance
            </p>

            <div className="inline-block bg-teal-500/20 text-teal-300 px-4 py-2 rounded-md font-semibold mb-8">
              {moment(upcomingWebinar?.start_date).format("dddd, MMMM D, YYYY")}{" "}
              •{" "}
              {moment(upcomingWebinar?.timeSlot?.startTime, "HH:mm").format(
                "h:mm A"
              )}{" "}
              to{" "}
              {moment(upcomingWebinar?.timeSlot?.endTime, "HH:mm").format(
                "h:mm A"
              )}{" "}
              IST
            </div>

            <div className="flex justify-center">
              <button
                className="bg-[#4682B4] text-white px-10 py-4 rounded-md hover:bg-[#3a6d94] transition-colors duration-300 font-bold text-xl shadow-lg"
                onClick={handleRegister}
              >
                Register Now For
                {upcomingWebinar?.amountWithOffer !== null ? (
                  <span className="relative z-1 line-through decoration-2">
                    {" "}
                    ₹{upcomingWebinar?.amountWithOffer}{" "}
                  </span>
                ) : null}
                <span className="ml-1">₹{upcomingWebinar?.amount}</span>
              </button>
            </div>
          </div>
        </div>

        {/* Registration Modal */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg shadow-xl max-w-md w-full overflow-hidden">
              {/* Modal Header */}
              <div className="bg-gradient-to-r from-blue-900 to-blue-800 text-white px-6 py-4 flex justify-between items-center">
                <h3 className="text-xl font-bold">Register for the Webinar</h3>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-white hover:text-gray-200 transition-colors"
                >
                  <X size={24} />
                </button>
              </div>

              {/* Modal Body */}
              <div className="p-6">
                <>
                  <p className="text-gray-600 mb-4">
                    Fill in your details below to secure your spot for the
                    webinar on{" "}
                    {moment(upcomingWebinar?.start_date).format(
                      "dddd, MMMM D, YYYY"
                    )}
                    .
                  </p>

                  {error && (
                    <div className="bg-red-50 text-red-600 p-3 rounded-md mb-4">
                      {error}
                    </div>
                  )}

                  <div className="space-y-3 md:space-y-4 flex-1">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-xs md:text-sm font-medium text-gray-700 mb-1"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full px-3 md:px-4 py-2 border border-gray-300 rounded-md focus:ring-[#4682B4] focus:border-[#4682B4] outline-none text-sm"
                        placeholder="Enter your full name"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-xs md:text-sm font-medium text-gray-700 mb-1"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 md:px-4 py-2 border border-gray-300 rounded-md focus:ring-[#4682B4] focus:border-[#4682B4] outline-none text-sm"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-xs md:text-sm font-medium text-gray-700 mb-1"
                      >
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="w-full px-3 md:px-4 py-2 border border-gray-300 rounded-md focus:ring-[#4682B4] focus:border-[#4682B4] outline-none text-sm"
                        placeholder="Enter your phone number"
                        required
                      />
                    </div>
                    <div className="pt-2 md:pt-4">
                      <button
                        disabled={!email || !phone || !name}
                        className="w-full bg-[#4682B4] hover:bg-[#3a6d94] text-white font-medium py-2 md:py-3 px-4 rounded-md transition-colors duration-200 text-sm md:text-base disabled:bg-gray-400"
                        onClick={handleSubmit}
                      >
                        {loading === true
                          ? "Processing..."
                          : "Secure Your Seat"}
                      </button>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>

      {isPostPaymentProcessing && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl flex flex-col items-center space-y-4">
            <Loader2 className="h-20 w-20 text-blue-500 animate-spin" />

            <p className="text-sm text-gray-500">
              Please wait while we confirm your subscription...
            </p>
          </div>
        </div>
      )}
      {razorpayLoader && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl flex flex-col items-center space-y-4">
            <Loader2 className="h-20 w-20 text-blue-500 animate-spin" />

            <p className="text-sm text-gray-500">
              Please wait while we intialize razorpay ...
            </p>
          </div>
        </div>
      )}
      {paymentSuccess && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl flex flex-col items-center space-y-4">
            <div className="text-center py-8">
              <div className="text-[#4682B4] mx-auto mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-16 w-16 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <h4 className="text-xl font-bold text-gray-900 mb-2">
                Registration Successful!
              </h4>
              <p className="text-gray-600 mb-6">
                Thank you for registering. We've sent the webinar details to
                your email.
              </p>
              <button
                onClick={() => setPaymentSuccess(false)}
                className="bg-[#4682B4] hover:bg-[#3a6d94] text-white font-medium py-2 px-6 rounded-md transition-colors duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WebinarLandingPage;
