import React, { useState } from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon, ChevronDown } from "lucide-react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { encryptApiKey, decryptApiKey } from "../../utils/cryptoUtils";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

export default function DatePicker({
  expirySet = false,
  date,
  setDate,
  placeholder = "Pick a date",
  className = "",
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [month, setMonth] = useState(date || new Date());
  const [showYearPicker, setShowYearPicker] = useState(false);
  const [showMonthPicker, setShowMonthPicker] = useState(false);

  const containerRef = React.useRef(null);

  const currentYear = new Date().getFullYear();
  const futureYears = 10; // Number of future years to include
  // const years = Array.from(
  //   { length: currentYear - 1900 + 1 + futureYears },
  //   (_, i) => 1950 + i
  // );
  const years = Array.from(
    { length: futureYears + (currentYear - 1950 + 1) },
    (_, i) => 1950 + i
  );

  const filteredYears = expirySet
    ? years.filter((year) => year >= currentYear)
    : years;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleSelect = (selectedDate) => {
    if (selectedDate) {
      setDate(selectedDate);
      setIsOpen(false);
    }
  };

  const handleYearSelect = (year) => {
    const newDate = new Date(month);
    newDate.setFullYear(year);
    setMonth(newDate);
    setShowYearPicker(false);
  };

  const handleMonthSelect = (monthIndex) => {
    const newDate = new Date(month); // Ensure month is a Date object
    newDate.setMonth(monthIndex);
    setMonth(newDate);
    setShowMonthPicker(false);
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        setShowYearPicker(false);
        setShowMonthPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const renderYearPicker = () => (
    <div className="absolute top-8 left-2 z-50 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-y-auto w-20">
      {filteredYears?.map((year) => (
        <div
          key={year}
          onClick={() => handleYearSelect(year)}
          className="text-base font-semibold px-4 py-1 cursor-pointer hover:bg-gray-100"
        >
          {year}
        </div>
      ))}
    </div>
  );

  const renderMonthPicker = () => (
    <div className="absolute top-8 left-0 z-50 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-y-auto w-40">
      {months.map((month, index) => (
        <div
          key={month}
          onClick={() => handleMonthSelect(index)}
          className="text-base font-semibold px-4 py-1 cursor-pointer hover:bg-gray-100"
        >
          {month}
        </div>
      ))}
    </div>
  );

  const CustomCaption = ({ displayMonth }) => (
    <div className="text-base font-semibold flex justify-center space-x-2">
      <span
        onClick={() => setShowMonthPicker(!showMonthPicker)}
        className="flex flex-row items-center justify-between cursor-pointer px-4 py-1 relative"
      >
        {months[displayMonth.getMonth()]}
        {showMonthPicker && renderMonthPicker()}
        <ChevronDown className="ml-3 " />
      </span>
      <span
        onClick={() => setShowYearPicker(!showYearPicker)}
        className="flex flex-row items-center justify-between cursor-pointer px-4 py-1 relative"
      >
        {displayMonth.getFullYear()}
        {showYearPicker && renderYearPicker()} <ChevronDown className="ml-3" />
      </span>
    </div>
  );

  return (
    <div className="relative w-full" ref={containerRef}>
      <div
        className={`relative flex items-center cursor-pointer ${className}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <input
          type="text"
          placeholder={placeholder}
          value={date ? format(date, "do MMM yyyy") : ""}
          readOnly
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm cursor-pointer"
        />
        <CalendarIcon className="absolute right-3 h-5 w-5 text-gray-400" />
      </div>

      {isOpen && (
        <div className="absolute left-0 z-50 bg-white rounded-lg shadow-lg border border-gray-200">
          <DayPicker
            mode="single"
            selected={date}
            onSelect={handleSelect}
            month={month}
            onMonthChange={setMonth}
            initialFocus={true}
            className="p-2 text-black"
            components={{ Caption: CustomCaption }}
            modifiers={{ today: new Date() }}
            modifiersStyles={{
              today: {
                fontWeight: "bold",
                color: "#0066ff",
              },
            }}
            styles={{
              caption: { margin: "0" },
            }}
          />
        </div>
      )}
    </div>
  );
}
