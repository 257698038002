import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { UserRound } from "lucide-react";
import server from "../../utils/serverConfig";
import { encryptApiKey } from "../../utils/cryptoUtils";

const style = {
  tableHeader:
    "px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
};

const AllUsers = () => {
  const [allUsersData, setAllUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState({ currentPage: 1, totalPages: 1 });

  const getAllUsersData = (page = 1) => {
    setLoading(true);
    axios
      .get(`${server.server.baseUrl}api/all-users/getAllUsers?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Advisor-Subdomain": process.env.REACT_APP_URL,
          "aq-encrypted-key": encryptApiKey(
            process.env.REACT_APP_AQ_KEYS,
            process.env.REACT_APP_AQ_SECRET
          ),
        },
      })
      .then((response) => {
        setAllUsersData(response?.data?.users);
        setTabData({
          currentPage: response?.data?.currentPage || 1,
          totalPages: response?.data?.totalPages || 1,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching users", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllUsersData();
  }, []);

  const getPageNumbers = () => {
    const pages = [];
    const { currentPage, totalPages } = tabData;
    const totalVisible = 10;

    if (totalPages <= totalVisible) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pages.push(1, "...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) pages.push("...", totalPages);
    }

    return pages;
  };

  const goToPage = (page) => {
    if (page > 0 && page <= tabData.totalPages) {
      getAllUsersData(page);
    }
  };

  return (
    <div className="flex flex-col w-full min-h-screen bg-white">
      <div className="flex flex-row bg-[#131A29] text-white px-6 py-4 w-full justify-between">
        <div>
          <h2 className="text-xl font-semibold mb-2">All Users Details</h2>
          <p className="text-blue-100 text-sm">Track and manage users</p>
        </div>
      </div>
      {loading ? (
        <div className="min-h-screen flex items-center justify-center">
          <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
        </div>
      ) : allUsersData.length !== 0 ? (
        <div className="overflow-auto custom-scroll rounded-lg border border-gray-200 shadow-sm">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className={style.tableHeader}>Joining Date</th>
                <th className={style.tableHeader}>Name</th>
                <th className={style.tableHeader}>Email</th>
                <th className={style.tableHeader}>Country Code</th>
                <th className={style.tableHeader}>Mobile Number</th>
                <th className={style.tableHeader}>Telegram Id</th>
                <th className={style.tableHeader}>Date of Birth</th>
                <th className={style.tableHeader}>Pan Number</th>
                <th className={style.tableHeader}>KYC Validated</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {allUsersData.map((ele, i) => (
                <tr key={i} className="hover:bg-gray-50 transition-colors">
                  <td className="px-4 py-4">
                    {ele?.created_at
                      ? moment(ele.created_at).format("Do MMM YYYY")
                      : "-"}
                  </td>
                  <td className="px-4 py-4">{ele?.name || "-"}</td>
                  <td className="px-4 py-4">{ele?.email || "-"}</td>
                  <td className="px-4 py-4">{ele?.country_code || "-"}</td>
                  <td className="px-4 py-4">{ele?.phone_number || "-"}</td>
                  <td className="px-4 py-4">{ele?.telegram_id || "-"}</td>
                  <td className="px-4 py-4">
                    {ele?.DateofBirth
                      ? moment(ele?.DateofBirth).format("Do MMM YYYY")
                      : "-"}
                  </td>
                  <td className="px-4 py-4">{ele?.panNumber || "-"}</td>
                  <td className="px-4 py-4">
                    {ele?.digio_verification || "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {tabData.totalPages > 1 && (
            <div className="flex justify-center items-center pb-3 space-x-1">
              <button
                onClick={() => goToPage(tabData.currentPage - 1)}
                disabled={tabData.currentPage === 1}
                className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
              >
                Previous
              </button>
              {getPageNumbers().map((page, index) =>
                page === "..." ? (
                  <span key={index} className="px-3 py-1 text-gray-600">
                    ...
                  </span>
                ) : (
                  <button
                    key={index}
                    onClick={() => goToPage(page)}
                    className={`px-3 py-1 text-sm rounded ${
                      page === tabData.currentPage
                        ? "bg-blue-500 text-white"
                        : "bg-gray-300"
                    }`}
                  >
                    {page}
                  </button>
                )
              )}
              <button
                onClick={() => goToPage(tabData.currentPage + 1)}
                disabled={tabData.currentPage === tabData.totalPages}
                className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
              >
                Next
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          No Users Available
        </div>
      )}
    </div>
  );
};

export default AllUsers;
