import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { encryptApiKey, decryptApiKey } from "../../../utils/cryptoUtils";

const encryptedKey = encryptApiKey(process.env.REACT_APP_AQ_KEYS,
    process.env.REACT_APP_AQ_SECRET);

const QuantityUpdate = ({ data, onClose, adviceEntries, setAdviceEntries }) => {
  // Initialize quantities state with current values from adviceEntries
  const [quantities, setQuantities] = useState({});
  const [selectedEmails, setSelectedEmails] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);

  // Initialize quantities from existing adviceEntries
  useEffect(() => {
    const initialQuantities = {};
    data.forEach((user) => {
      initialQuantities[user.email] = "1"; // Default quantity
    });
    setQuantities(initialQuantities);
  }, [data]);

  const handleQuantityChange = (email, value) => {
    setQuantities((prev) => ({
      ...prev,
      [email]: value === "" ? "" : parseInt(value) || 1,
    }));
  };

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(email)) {
        newSelected.delete(email);
      } else {
        newSelected.add(email);
      }
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allEmails = new Set(data.map((user) => user.email));
      setSelectedEmails(allEmails);
    } else {
      setSelectedEmails(new Set());
    }
  };

  const handleUpdateQuantities = () => {
    // Create new entries array with updated quantities
    const updatedEntries = adviceEntries.map((entry) => {
      return {
        ...entry,
        quantities: Object.fromEntries(
          Array.from(selectedEmails).map((email) => [
            email,
            quantities[email] || 1,
          ])
        ),
      };
    });

    setAdviceEntries(updatedEntries);
    onClose();
  };

  return (
    <div className="relative w-full max-w-[75rem] h-[88vh] bg-white rounded-xl shadow-xl overflow-hidden font-poppins">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Update Quantities</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="h-[68vh] overflow-y-auto">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="rounded border-gray-300"
                    />
                  </th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-4 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {data.map((user) => (
                  <tr key={user.email} className="hover:bg-gray-50">
                    <td className="px-4 py-3">
                      <input
                        type="checkbox"
                        checked={selectedEmails.has(user.email)}
                        onChange={() => handleCheckboxChange(user.email)}
                        className="rounded border-gray-300"
                      />
                    </td>
                    <td className="px-4 py-3 text-left tracking-wider">
                      {user.email}
                    </td>
                    <td className="px-4 py-3 text-center tracking-wider">
                      <input
                        type="number"
                        value={quantities[user.email] || ""}
                        onChange={(e) =>
                          handleQuantityChange(user.email, e.target.value)
                        }
                        className="w-24 px-3 py-2 border border-gray-300 rounded-md"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gray-50 border-t border-gray-200">
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdateQuantities}
            disabled={selectedEmails.size === 0}
            className="px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            Update Quantities
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuantityUpdate;
