import React, { useState, useEffect } from "react";
import { X, RotateCcw } from "lucide-react";
import TableHeader from "../../TableComponent/TableHeader";

const ClosureAdvicePopup = ({
  isOpen,
  onClose,
  selectedAdvices,
  onSend,
  getLTPForSymbol,
}) => {
  // State to track the editable version of the selected advices
  const [updatedAdvices, setUpdatedAdvices] = useState([]);

  // Initialize updatedAdvices when the component receives new selectedAdvices
  useEffect(() => {
    if (selectedAdvices?.length > 0) {
      // Create deep copies to prevent reference issues
      setUpdatedAdvices(
        selectedAdvices.map((advice) => ({
          ...advice,
          id: advice.advice_reco_id || advice.id,
          order_type: advice.order_type || "MARKET",
          type: advice.type === "BUY" ? "SELL" : "BUY", // Default to opposite type
          price: advice.price || "",
          comments: advice.comments || "",
          comment2: advice.comment2 || "",
          rationale: advice.rationale || "",
        }))
      );
    }
  }, [selectedAdvices]);

  // Generic field update function
  const handleFieldUpdate = (id, field, value) => {
    setUpdatedAdvices((prev) =>
      prev.map((advice) => {
        if (advice.id === id || advice.advice_reco_id === id) {
          const updatedAdvice = { ...advice, [field]: value };

          // Special handling for LIMIT order type
          if (field === "order_type") {
            if (value === "LIMIT" && !updatedAdvice.price) {
              updatedAdvice.price = getLTPForSymbol
                ? getLTPForSymbol(advice.symbol)
                : "";
            } else if (value === "MARKET") {
              updatedAdvice.price = "0.00";
            }
          }

          return updatedAdvice;
        }
        return advice;
      })
    );
  };

  // Reset field to original value
  const handleFieldReset = (id, field) => {
    const originalAdvice = selectedAdvices.find(
      (a) => a.id === id || a.advice_reco_id === id
    );
    if (originalAdvice) {
      handleFieldUpdate(id, field, originalAdvice[field] || "");
    }
  };

  // Handle sending the updated advices
  const handleSendAdvices = () => {
    // Ensure all fields are properly set
    const finalizedAdvices = updatedAdvices.map((advice) => ({
      ...advice,
      OrderType: advice.order_type,
      order_type: advice.order_type,
      price: advice.order_type === "LIMIT" ? advice.price : "0.00",
      comments: advice.comments || "",
      comment2: advice.comment2 || "",
      rationale: advice.rationale || "",
    }));

    // Call the parent's onSend with the updated advices
    onSend({
      updatedMergedData: finalizedAdvices.reduce((acc, advice) => {
        const key = `${advice.advice_reco_id || advice.id}-0`;
        acc[key] = advice;
        return acc;
      }, {}),
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-7xl max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Send Closure Advice</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="flex-grow overflow-auto">
          <table className="w-full mb-4">
            <TableHeader closePositionPopUp={false} />
            <tbody>
              {updatedAdvices.map((advice, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 font-poppins">{advice.symbol}</td>

                  {/* Type */}
                  <td className="py-2 px-4 font-poppins">
                    <div className="flex items-center space-x-2">
                      <select
                        value={advice.type}
                        onChange={(e) =>
                          handleFieldUpdate(advice.id, "type", e.target.value)
                        }
                        className="border rounded px-2 py-1 font-poppins text-md w-24"
                      >
                        <option value="BUY">BUY</option>
                        <option value="SELL">SELL</option>
                      </select>
                      <button
                        onClick={() => handleFieldReset(advice.id, "type")}
                        className="p-1 text-gray-500 hover:text-gray-700"
                      >
                        <RotateCcw className="h-4 w-4" />
                      </button>
                    </div>
                  </td>

                  {process.env.REACT_APP_ADVISOR_SPECIFIER === "RIA" && (
                    <td className="py-2 px-4 font-poppins">
                      <input
                        type="number"
                        value={advice.quantity}
                        onChange={(e) =>
                          handleFieldUpdate(
                            advice.id,
                            "quantity",
                            e.target.value
                          )
                        }
                        className="border rounded px-2 py-1 w-20"
                        max={advice.quantity}
                      />
                    </td>
                  )}

                  {/* Exchange */}
                  <td className="py-2 px-4 font-poppins">{advice.exchange}</td>

                  {/* Order Type */}
                  <td className="py-2 px-4 font-poppins">
                    <div className="flex items-center space-x-2">
                      <select
                        value={advice.order_type}
                        onChange={(e) =>
                          handleFieldUpdate(
                            advice.id,
                            "order_type",
                            e.target.value
                          )
                        }
                        className="border rounded px-2 py-1 font-poppins text-md w-32"
                      >
                        <option value="MARKET">MARKET</option>
                        <option value="LIMIT">LIMIT</option>
                        <option value="STOP">STOP</option>
                      </select>
                      <button
                        onClick={() =>
                          handleFieldReset(advice.id, "order_type")
                        }
                        className="p-1 text-gray-500 hover:text-gray-700"
                      >
                        <RotateCcw className="h-4 w-4" />
                      </button>
                    </div>
                  </td>

                  {/* Current Price */}
                  <td className="py-2 px-4 font-poppins">
                    {getLTPForSymbol(advice.symbol)}
                  </td>

                  {/* Limit Price */}
                  <td className="py-2 px-4 font-poppins">
                    {advice.order_type === "LIMIT" ||
                    advice.order_type === "STOP" ? (
                      <div className="flex items-center space-x-2">
                        <input
                          type="number"
                          value={advice.price || ""}
                          onChange={(e) =>
                            handleFieldUpdate(
                              advice.id,
                              "price",
                              e.target.value
                            )
                          }
                          placeholder="Enter price"
                          className="border rounded px-2 py-1 font-poppins text-md w-32"
                        />
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>

                  {/* Product Type */}
                  <td className="py-2 px-4 font-poppins">
                    {advice.product_type}
                  </td>

                  {/* Segment */}
                  <td className="py-2 px-4 font-poppins">{advice.segment}</td>

                  {/* Rationale */}
                  <td className="py-2 px-4 w-96 h-24">
                    <div className="flex items-center space-x-2">
                      <textarea
                        value={advice.rationale || ""}
                        onChange={(e) =>
                          handleFieldUpdate(
                            advice.id,
                            "rationale",
                            e.target.value
                          )
                        }
                        className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                      />
                      <button
                        onClick={() => handleFieldReset(advice.id, "rationale")}
                        className="p-1 text-gray-500 hover:text-gray-700"
                      >
                        <RotateCcw className="h-4 w-4" />
                      </button>
                    </div>
                  </td>

                  {/* Comments */}
                  <td className="py-2 px-4 w-96 h-24">
                    <div className="flex items-center space-x-2">
                      <textarea
                        value={advice.comments || ""}
                        onChange={(e) =>
                          handleFieldUpdate(
                            advice.id,
                            "comments",
                            e.target.value
                          )
                        }
                        className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                      />
                      <button
                        onClick={() => handleFieldReset(advice.id, "comments")}
                        className="p-1 text-gray-500 hover:text-gray-700"
                      >
                        <RotateCcw className="h-4 w-4" />
                      </button>
                    </div>
                  </td>

                  {/* Comment2 */}
                  <td className="py-2 px-4 w-96 h-24">
                    <div className="flex items-center space-x-2">
                      <textarea
                        value={advice.comment2 || ""}
                        onChange={(e) =>
                          handleFieldUpdate(
                            advice.id,
                            "comment2",
                            e.target.value
                          )
                        }
                        className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                      />
                      <button
                        onClick={() => handleFieldReset(advice.id, "comment2")}
                        className="p-1 text-gray-500 hover:text-gray-700"
                      >
                        <RotateCcw className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end space-x-2 mt-4 pt-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={handleSendAdvices}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClosureAdvicePopup;
