import React from "react";

import DashboardLayout from "../RootSection/DashboardLayout";
import StrategyDetails from "./StrategyDetails";

const SingleStrategyDetailsPage = () => {
  return (
    <DashboardLayout>
      <StrategyDetails />
    </DashboardLayout>
  );
};

export default SingleStrategyDetailsPage;
