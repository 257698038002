import React from "react";
import Logo from "../../assests/Logo.jpg";
import Whatsapp from "../../assests/whatsapp.png";
import Mail from "../../assests/Email.svg";
export const TableHeader = () => (
  <thead className="sticky top-0 z-20">
    <tr>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-[#000000]/10">
        Date
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80 text-left   border-[#000000]/10">
        Symbol
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left  border-[#000000]/10">
        <span className="flex flex-row items-center">
          <img src={Logo} alt="Mail" className="w-8 h-8 rounded-md mr-2" />
          Platform
        </span>
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left  border-[#000000]/10">
        <span className="flex flex-row items-center">
          <img src={Whatsapp} alt="Mail" className="w-10 h-8 rounded-md mr-2" />
          Whatsapp
        </span>
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left  border-[#000000]/10">
        <span className="flex flex-row items-center">
          <img src={Mail} alt="Mail" className="w-8 h-8 rounded-md mr-2" />
          Email
        </span>
      </th>
      <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-[#000000]/10">
        Action
      </th>
    </tr>
  </thead>
);
