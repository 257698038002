import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";

import { auth } from "./firebase";
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";

import LandingPage from "./LandingPage/LandingPage";
// admin section
import AdminSignIn from "./AdminDashboard/LoginSection/adminSignin";
import AdminHomeSection from "./AdminDashboard/AdminHomeSection/AdminHomeSection";
import GroupClientListSection from "./AdminDashboard/GroupClientListSection/GroupClientListSection";
import ClientListDetailsSectionPage from "./AdminDashboard/ClientListDetailsSection/ClientListDetailsSectionPage";
import RecommendationStatusHomePage from "./AdminDashboard/RecommendationStatusSection/RecomenationStatusHomepage";
import StrategyListHomePage from "./AdminDashboard/StrategySection/StrategyListHomePage";
import StrategyDetailsPage from "./AdminDashboard/StrategyDetailsPageSection/StrategyDetailsPage";
import PlanListHomePage from "./AdminDashboard/PlanSection/PlanListHomePage";
import PlanDetailsPage from "./AdminDashboard/PlanSection/PlanDetailsPage";
import AdminSettingsPage from "./AdminDashboard/AdminSettings/AdminSettingsPage";
// user section
import WithoutLogin from "./Login/WithoutLogin";
import LivePortfolioSection from "./Home/LivePortfolioSection/LivePortfolioSection";
import AllStrategyListHomePage from "./Home/Strategy/AllStrategyListHomePage";
import SingleStrategyDetailsPage from "./Home/Strategy/SingleStrategyDetailsPage";
import SubscribedStrategyDetailsPage from "./Home/Strategy/SubscrinedStrategyDetailsPage";
import UserStockRecommendation from "./Home/StockRecommendation/UserStockRecommendation";
import OrderBookDetailsPage from "./Home/OrderDetailsSection/OrderBookDetailsPage";
import TradeHistoryHomePage from "./Home/TradeHistorySection/TradeHistoryHomepage";
import SubscriptionPage from "./Home/Subscriptions/SubscriptionPage";
import Pricing from "./Home/PricingSection/Pricing";
import ContactAdvisorPage from "./Home/ContactAdvisorSection/ContactAdvisorPage";
import IgnoreTradesPage from "./Home/IgnoreTrades/IgnoreTradesPage";
import ContactUs from "./TermsAndConditions/ContactUs";
import PrivacyPolicy from "./TermsAndConditions/PrivacyPolicy";
import DisclaimerPage from "./TermsAndConditions/DisclaimerPage";
import TermsAndConditionsByAdvisor from "./TermsAndConditions/TermsAndConditionsByAdvisor";
import TermsAndConditionsByAlpha from "./TermsAndConditions/TermsAndConditionsByAlpha";
import AdminAdviceStatusPage from "./AdminDashboard/AdminAdviceStatus/AdminAdviceStatusPage";
import PreviousOrders from "./Home/PreviousOrders/previousOrders";
import PreviousStocksForPurchase from "./AdminDashboard/PreviousStocksForPurchase/PreviousStocksForPurchase";
import Report from "./components/Report";
// import Derivatives from "./Home/Derivatives";
import Test from "./Home/Test";
import StopLossProfitTargetPage from "./AdminDashboard/StopLossProfitTarget/StopLossProfitTargetPage";
import BillDeskPayment from "./Test/test";
import AllUsersPage from "./AdminDashboard/AllUserrs/AllUsersPage";
import AdminWebinarPage from "./AdminDashboard/AdminWebinar/AdminWebinarPage";
import WebinarLandingPage from "./LandingPage/Webinarpage";
// import { ReceiptPoundSterling } from "lucide-react";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);

  const [user] = useAuthState(auth);

  const isAuth = useSelector(isAuthenticated);
  return (
    <div className="select-none">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* User Routes*/}
        <Route path="/test-2" element={<BillDeskPayment />} />
        <Route path="/webinar-page" element={<WebinarLandingPage />} />
        <Route
          path="/user"
          element={user ? <LivePortfolioSection /> : <WithoutLogin />}
        />
        <Route
          path="/stock-recommendation"
          element={user ? <UserStockRecommendation /> : <WithoutLogin />}
        />
        <Route
          path="/order-details"
          element={user ? <OrderBookDetailsPage /> : <WithoutLogin />}
        />
        <Route
          path="/trade-history"
          element={user ? <TradeHistoryHomePage /> : <WithoutLogin />}
        />

        <Route
          path="/subscriptions"
          element={user ? <SubscriptionPage /> : <WithoutLogin />}
        />
        <Route
          path="/model-portfolio"
          element={user ? <AllStrategyListHomePage /> : <WithoutLogin />}
        />
        <Route
          path="/model-portfolio/:fileName"
          element={user ? <SingleStrategyDetailsPage /> : <WithoutLogin />}
        />
        <Route
          path="/model-portfolio/subscribed/:fileName"
          element={user ? <SubscribedStrategyDetailsPage /> : <WithoutLogin />}
        />
        <Route
          path="/pricing"
          element={user ? <Pricing /> : <WithoutLogin />}
        />
        <Route
          path="/contact-advisor"
          element={user ? <ContactAdvisorPage /> : <WithoutLogin />}
        />
        <Route
          path="/ignore-trades"
          element={user ? <IgnoreTradesPage /> : <WithoutLogin />}
        />
        <Route
          path="/previous-orders"
          element={user ? <PreviousOrders /> : <WithoutLogin />}
        />

        <Route path="/test" element={<Test />} />
    <Route path="/report" element={<Report />} />
        {/* <Route path="/derivatives" element={<Derivatives />} /> */}

        {/* Admin Route*/}
        <Route
          path="/admin"
          element={isAuth ? <AdminHomeSection /> : <AdminSignIn />}
        />
        <Route
          path="/admin/client-list"
          element={isAuth ? <GroupClientListSection /> : <AdminSignIn />}
        />
        <Route
          path="/admin/client-list/:groupName"
          element={isAuth ? <ClientListDetailsSectionPage /> : <AdminSignIn />}
        />
        <Route
          path="/admin/advice-status"
          element={isAuth ? <AdminAdviceStatusPage /> : <AdminSignIn />}
        />
        <Route
          path="/admin/send-recommendation-status"
          element={isAuth ? <RecommendationStatusHomePage /> : <AdminSignIn />}
        />
        <Route
          path="/admin/admin-strategy"
          element={isAuth ? <StrategyListHomePage /> : <AdminSignIn />}
        />

        <Route
          path="/admin/admin-strategy/:fileName"
          element={isAuth ? <StrategyDetailsPage /> : <AdminSignIn />}
        />

        <Route
          path="/admin/admin-plans"
          element={isAuth ? <PlanListHomePage /> : <AdminSignIn />}
        />
        <Route
          path="/admin/admin-plan/:fileName"
          element={isAuth ? <PlanDetailsPage /> : <AdminSignIn />}
        />
        <Route
          path="/admin/admin-settings"
          element={isAuth ? <AdminSettingsPage /> : <AdminSignIn />}
        />
        <Route
          path="/admin/prior-advice"
          element={isAuth ? <PreviousStocksForPurchase /> : <AdminSignIn />}
        />
        <Route
          path="/admin/stop-loss-profit-target"
          element={isAuth ? <StopLossProfitTargetPage /> : <AdminSignIn />}
        />
        <Route
          path="/admin/admin-advice-status"
          element={isAuth ? <AdminAdviceStatusPage /> : <AdminSignIn />}
        />
        <Route
          path="/admin/admin-all-users"
          element={isAuth ? <AllUsersPage /> : <AdminSignIn />}
        />
        <Route
          path="/admin/admin-all-webinar"
          element={isAuth ? <AdminWebinarPage /> : <AdminSignIn />}
        />
        {/* 
          
          other routes
         
          */}
        <Route
          path="/terms-and-conditions-by-advisor"
          element={<TermsAndConditionsByAdvisor />}
        />
        <Route
          path="/terms-and-conditions-by-alphaquark"
          element={<TermsAndConditionsByAlpha />}
        />
        <Route path="/disclaimer" element={<DisclaimerPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
