import React from "react";
import DashboardLayout from "../RootSection/DashboardLayout";
import StrategyDetailsWithPortfolioData from "./StrategyDetailsWithPortfolioData";

const SubscribedStrategyDetailsPage = () => {
  return (
    <DashboardLayout>
      <StrategyDetailsWithPortfolioData />
    </DashboardLayout>
  );
};

export default SubscribedStrategyDetailsPage;
