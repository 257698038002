import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonCard = () => (
  <div className="relative font-poppins w-full rounded-xl  shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]  py-4">
    <div
      className={`flex flex-col  items-start justify-between  mx-5 border-b border-[#ffffff]/20`}
    >
      <div className="flex space-x-2 py-2">
        <p className=" text-[12px] font-poppins text-[#ffffff]/80 font-light capitalize">
          <Skeleton width={128} height={25} />
        </p>
      </div>
    </div>
    <div className={`flex flex-col  items-start justify-between   px-6`}>
      <div className="flex flex-row space-x-6 max-h-[85px]   py-4">
        <div className="flex flex-col ">
          <p className=" text-xl font-medium font-poppins text-[#ffffff] capitalize">
            {" "}
            <Skeleton width={128} height={25} />
          </p>
          <span className="ml-[2px] pt-[8px] text-[12px] text-[#ffffff]/80 font-light">
            <Skeleton width={128} height={25} />
          </span>
        </div>
      </div>
    </div>

    <div
      className={` flex flex-row items-center justify-between mt-8  pb-1 px-6`}
    >
      <div className="text-[#ffffff] text-xs  font-medium flex flex-row items-center">
        <Skeleton width={128} height={25} />
      </div>
    </div>

    <div className={` flex mt-[10px] px-6 pb-2`}>
      <button className="w-full h-10 px-4 rounded-[4px] bg-white text-[#1D1D1F] text-xs sm:text-[13px] lg:text-[13px] font-poppins font-semibold  flex items-center justify-center">
        <span className="w-full h-full flex items-center justify-center">
          <Skeleton width={128} height={25} />
        </span>
      </button>
    </div>
  </div>
);

export default SkeletonCard;
