import React, { useState, useEffect } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import axios from "axios";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import server from "../utils/serverConfig";
import { useNavigate } from "react-router-dom";
import { Mail, Eye, EyeOff } from "lucide-react";

import { encryptApiKey } from "../utils/cryptoUtils";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5 bg-white text-lg peer text-gray-900 placeholder-transparent font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] transition ease-in duration-200",
  labelFloat:
    "absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  inputStartDiv: "relative w-full",
};

const SignUpEmail = (props) => {
  const navigate = useNavigate();
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [error, setError] = useState("");
  const [showComponent, setShowComponent] = useState(false);
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const signUpWithEmail = () => {
    setLoading(true);
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        if (response) {
          axios
            .post(
              `${server.server.baseUrl}api/user/`,
              {
                email: response.user.email,
                name: response.user.name
                  ? response.user.name
                  : response.user.email.split("@")[0],
                firebaseId: response.user.uid,
                phoneNumber: 0,
                telegramId: "",
                profileCompletion: 50,
                user_onBoard_from: whiteLabelText,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "X-Advisor-Subdomain": process.env.REACT_APP_URL,
                  "aq-encrypted-key": encryptApiKey(
                    process.env.REACT_APP_AQ_KEYS,
                    process.env.REACT_APP_AQ_SECRET
                  ),
                },
              }
            )
            .then((result) => {
              const endLinks = ["/pricing", "/subscriptions"];
              const redirectPath = endLinks.includes(window.location.pathname)
                ? window.location.pathname
                : "/stock-recommendation";
              navigate(redirectPath);
              setLoading(false);
              setShowComponent(true);
              setSuccess(
                "Your account has been successfully created. Please login!"
              );
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        setErrorShow(true);
        setError("The email address is already in use by another account");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showComponent) {
      const toRef = setTimeout(() => {
        setShowComponent(false);
        setSuccess("");
        props.setShowSignUp(false);
        clearTimeout(toRef);
      }, 3000);
    }
  }, [showComponent, props]);

  useEffect(() => {
    if (errorShow) {
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 8000);
    }
  }, [errorShow]);

  return (
    <div className="w-full bg-white relative rounded-lg font-Poppins">
      <button
        onClick={() => props.setShowSignUp(false)}
        className="flex flex-row justify-center items-center rounded-full text-black/80 absolute top-0 left-0 md:left-0 m-1 font-bold hover:text-neutral-400 ease-linear duration-150 cursor-pointer"
      >
        <div>
          <MdOutlineArrowBack className="h-[13px] sm:h-[20px] mr-[2px]" />
        </div>
        <div className="flex text-[10px] sm:text-[14px]">Back</div>
      </button>

      <div className="pt-8">
        <div className={style.inputStartDiv}>
          <input
            id="email"
            type="email"
            placeholder="Enter your email"
            className={`${style.inputBox}`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label
            htmlFor="email"
            className={`${style.firstHeading} ${style.labelFloat}`}
          >
            Enter your email
          </label>
        </div>
        <div className={style.inputStartDiv}>
          <input
            id="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            className={`${style.inputBox} pr-12`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label
            htmlFor="password"
            className={`${style.firstHeading} ${style.labelFloat}`}
          >
            Enter your password
          </label>
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-4 top-[26px] text-gray-500 hover:text-gray-700"
          >
            {showPassword ? (
              <EyeOff className="w-5 h-5" />
            ) : (
              <Eye className="w-5 h-5" />
            )}
          </button>
        </div>

        {showComponent && (
          <div className="py-4 text-[#16A085] text-base font-bold text-center">
            {success}
          </div>
        )}

        <div className="mt-8">
          <button
            onClick={signUpWithEmail}
            disabled={!email || !password || loading}
            className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed flex flex-row items-center justify-center py-3 w-full mb-3 rounded-lg bg-black/100 text-white font-medium hover:bg-black/90 ease-linear duration-150"
          >
            <div className="text-white font-medium text-lg font-poppins">
              {loading ? (
                <span className="flex flex-row justify-center">
                  <svg
                    className="h-10 w-10 text-[#ffffff] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              ) : (
                <div className="flex flex-row items-center justify-center">
                  <Mail size={25} className="mr-2" />
                  Sign up with Email
                </div>
              )}
            </div>
          </button>
        </div>
      </div>

      {errorShow && (
        <div className="bg-white flex p-2 mt-2">
          <div className="text-[#D1433A] text-base font-bold ml-2 -mt-0.5">
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUpEmail;
