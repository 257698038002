import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import React, { useState } from "react";

const NestedTable = ({
  details,
  setOpenRecommendationModel,
  setAllUserDeatils,
  setSelectEmail,
  symbol,
  setSelectSymbol,
}) => {
  // console.log("details", details);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // Calculate the range of rows to display based on the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = details.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(details.length / rowsPerPage);

  // Handle page change
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  return (
    <div className="overflow-auto">
      <table className="w-full">
        <thead className="bg-[#e6e6e6] text-sm text-left sticky top-0">
          <tr className="text-left border-b-[1px] border-[#000000]/10">
            <th className="text-[14px] max-w-[150px]  lg:w-auto  text-[#000000]/60  font-poppins font-medium px-2 lg:px-3 py-2 ">
              Client Email
            </th>
            <th className="text-[14px] min-w-[90px]  lg:w-auto  text-[#000000]/60  font-poppins text-center font-medium px-2 lg:px-3 py-2 ">
              Holding Qty
            </th>
            <th className="text-[14px] min-w-[120px] lg:w-auto  text-[#000000]/60  font-poppins text-center font-medium px-2 lg:px-3 py-2 ">
              Avg. Holding Price
            </th>
            <th className="text-[14px] min-w-[100px]  lg:w-auto  text-[#000000]/60  font-poppins text-center font-medium px-2 lg:px-3 py-2 ">
              Unrealized P&L
            </th>
            <th className="text-[14px] min-w-[100px]  lg:w-auto  text-[#000000]/60  font-poppins text-center font-medium px-2 lg:px-3 py-2 ">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((detail, i) => {
            return (
              <tr key={i} className="border-t-[1px]  border-[#000000]/10">
                <td className="text-[15px] max-w-[150px] truncate text-[#000000] font-poppins font-medium  px-3 py-2">
                  {detail?._id.email}
                </td>
                <td className="text-[15px] text-[#000000] font-poppins text-center font-medium  px-3 py-2">
                  {detail?.holding.quantity}
                </td>
                <td className="text-[15px] text-[#000000] font-poppins  text-center font-medium  px-3 py-2">
                  {detail?.holding.avgPrice}
                </td>
                <td className="text-[15px] text-[#000000] font-poppins text-center font-medium  px-3 py-2">
                  {detail?.holding.pnl > 0 ? (
                    <span className="text-[#16A085] font-semibold">
                      +₹{detail?.holding.pnl}
                    </span>
                  ) : detail?.holding.pnl < 0 ? (
                    <span className="text-[#E43D3D] font-semibold">
                      -₹{Math.abs(detail?.holding.pnl)}
                    </span>
                  ) : (
                    <span>0</span>
                  )}
                </td>
                <td className="text-[15px] text-[#000000] font-poppins text-center font-medium  px-3 py-2">
                  <span
                    className="inline-block text-center w-[60px] mx-auto border-[1px] rounded-[4px] py-[2px] border-[#000000]/20 cursor-pointer"
                    onClick={() => {
                      setOpenRecommendationModel(true);
                      setSelectEmail(detail?._id.email);
                      setAllUserDeatils(details);
                      setSelectSymbol(symbol);
                    }}
                  >
                    Sell
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination Controls */}
      {details.length > 5 && (
        <div className="flex items-center justify-end space-x-4 lg:px-12 mt-2">
          <div className="flex items-center justify-center">
            <button
              className={`p-2 ${
                currentPage === 1 ? "text-gray-400" : "text-black"
              } cursor-pointer`}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <ChevronLeftIcon />
            </button>
            <span className="text-sm">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className={`p-2 ${
                currentPage === totalPages ? "text-gray-400" : "text-black"
              } cursor-pointer`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <ChevronRightIcon />
            </button>
          </div>
        </div>
      )}
      
    </div>
  );
};

export default NestedTable;
