import React from "react";
import Step1 from "../../assests/step-1.svg";
import Step2 from "../../assests/step-2.svg";
import Step3 from "../../assests/step-3.svg";
import Step4 from "../../assests/step-4.svg";
import Step5 from "../../assests/step-5.svg";
import Arrow from "../../assests/arrow.svg";
import { XIcon } from "lucide-react";

import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";

const StepGuideModal = ({ showStepGuideModal, setShowStepGuideModal }) => {
  const isDesktop = useMediaQuery("(min-width: 830px)");

  if (isDesktop) {
    return (
      <Dialog open={showStepGuideModal} onOpenChange={setShowStepGuideModal}>
        <DialogContent className=" max-w-[1050px] max-h-[650px] lg:px-[40px] lg:py-12">
          <div className="flex flex-col space-y-6 overflow-auto  bg-[#ffffff]">
            <p className="text-center lg:text-left text-[#000000]/80 text-[16px] font-medium font-poppins leading-[22px]">
              Maximize Your Investment Success. Steps to Follows :
            </p>

            <div className="flex flex-col  space-y-8 lg:space-y-0  lg:items-center  lg:flex-row lg:justify-evenly lg:space-x-4 ">
              {/* Card 1  */}
              <div className="flex flex-row space-x-6 mt-2 lg:space-x-0 lg:flex-col items-center justify-between lg:space-y-4 lg:w-[250px] ">
                <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                  <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    01
                  </div>
                  <img
                    src={Step1}
                    alt="Step 1"
                    className=" w-[60px] lg:w-[60px]"
                  />
                </div>

                <div className="flex flex-col lg:items-center justify-center space-y-2">
                  <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                    Review Buy Advice
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Assess each recommendation from your advisor.
                  </p>
                </div>
              </div>

              {/* Arrow  */}
              <img
                src={Arrow}
                alt="Arrow"
                width={40}
                className=" rotate-90 hidden lg:block lg:rotate-0"
              />

              <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />

              {/* Card 2  */}
              <div className="flex flex-row space-x-2 lg:space-x-0 lg:flex-col items-center justify-between  lg:w-[250px]   ">
                <div className="flex flex-col  pt-4 items-center ">
                  <div className="bg-gradient-to-b mb-2 from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    02
                  </div>
                  <img
                    src={Step2}
                    alt="Step 2"
                    className="w-[40px] lg:w-[60px]"
                  />
                </div>
                <div className="flex flex-col lg:items-center justify-center ">
                  <h3 className="text-[#16A085] text-[16px] ml-6  font-semibold font-poppins leading-[24px]">
                  Decide and Place Orders
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Choose which advices to accept and execute the buy orders.
                  </p>
                </div>
              </div>

              {/* Arrow  */}
              <img
                src={Arrow}
                alt="Arrow"
                width={40}
                className=" rotate-90 hidden lg:block lg:rotate-0"
              />

              <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />

              {/* Card 3  */}

              <div className="flex flex-row pb-1 space-x-6 lg:space-x-0 lg:flex-col items-center justify-between lg:space-y-3 lg:w-[250px]   ">
                <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                  <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    03
                  </div>
                  <img
                    src={Step3}
                    alt="Step 3"
                    className="w-[30px] lg:w-[50px]"
                  />
                </div>

                <div className="flex flex-col lg:items-center justify-center space-y-5">
                  <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                    Hold Stocks
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Keep the purchased stocks in your portfolio.
                  </p>
                </div>
              </div>

              <img
                src={Arrow}
                alt="Arrow"
                width={40}
                className=" rotate-90 hidden  lg:rotate-0 lg:hidden"
              />
              <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />
            </div>

            <div className="flex flex-col  space-y-8 lg:space-y-0  lg:items-center lg:flex-row lg:justify-evenly lg:space-x-4  lg:px-[60px]">
              {/* Card 4  */}
              <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between lg:space-y-4 lg:w-[250px]   ">
                <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                  <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    04
                  </div>
                  <img
                    src={Step4}
                    alt="Step 4"
                    className="w-[40px] lg:w-[60px]"
                  />
                </div>

                <div className="flex flex-col lg:items-center justify-center space-y-2">
                  <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                    Monitor for Sell Advice
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Wait for the sell advices from your advisor.
                  </p>
                </div>
              </div>

              {/* Arrow  */}
              <img
                src={Arrow}
                alt="Arrow"
                width={40}
                className="rotate-90 hidden lg:block lg:rotate-0"
              />
              <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />

              {/* Card 5  */}
              <div className="flex flex-row  space-x-6 lg:space-x-0 lg:flex-col items-center justify-between lg:space-y-4 lg:w-[250px]   ">
                <div className="flex flex-col mt-4 space-y-3 items-center lg:space-y-2">
                  <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    05
                  </div>
                  <img
                    src={Step5}
                    alt="Step 5"
                    className="w-[40px] lg:w-[50px]"
                  />
                </div>
                <div className="flex flex-col lg:items-center justify-center space-y-2">
                  <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                    Book Profit/Loss
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Sell stocks based on the advisor's recommendations and
                    current market conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={showStepGuideModal} onOpenChange={setShowStepGuideModal}>
      <DrawerContent className="focus:outline-none max-h-[500px] md:max-h-[950px] animate-modal">

      <div onClick={() => setShowStepGuideModal(false)} className="absolute top-4 right-3 flex items-center justify-center ">
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>
        <div className=" py-4 px-4 md:py-6 md:px-16 h-full overflow-y-auto ">
          <div className="flex flex-col space-y-6 overflow-auto  bg-[#ffffff]  px-2  rounded-lg ">
            <p className="text-center lg:text-left text-[#000000]/80 text-[16px] font-medium font-poppins leading-[22px]">
              Maximize Your Investment Success. Steps to Follows :
            </p>

            <div className="flex flex-col  space-y-8 lg:space-y-0  lg:items-center  lg:flex-row lg:justify-evenly lg:space-x-4 ">
              {/* Card 1  */}
              <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between md:justify-start lg:space-y-4 lg:w-[250px] ">
                <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                  <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    01
                  </div>
                  <img
                    src={Step1}
                    alt="Step 1"
                    className=" w-[60px] lg:w-[60px]"
                  />
                </div>

                <div className="flex flex-col lg:items-center justify-center space-y-2">
                  <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                    Review Buy Advice
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Assess each recommendation from your advisor.
                  </p>
                </div>
              </div>

              {/* Arrow  */}
              <img
                src={Arrow}
                alt="Arrow"
                width={40}
                className=" rotate-90 hidden lg:block lg:rotate-0"
              />

              <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />

              {/* Card 2  */}
              <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between md:justify-start lg:space-y-4 lg:w-[250px]   ">
                <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                  <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    02
                  </div>
                  <img
                    src={Step2}
                    alt="Step 2"
                    className="w-[40px] lg:w-[60px]"
                  />
                </div>
                <div className="flex flex-col lg:items-center justify-center space-y-2">
                  <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                    Decide and Place Orders
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Choose which advices to accept and execute the buy orders.
                  </p>
                </div>
              </div>

              {/* Arrow  */}
              <img
                src={Arrow}
                alt="Arrow"
                width={40}
                className=" rotate-90 hidden lg:block lg:rotate-0"
              />

              <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />

              {/* Card 3  */}

              <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between md:justify-start lg:space-y-4 lg:w-[250px]   ">
                <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                  <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    03
                  </div>
                  <img
                    src={Step3}
                    alt="Step 3"
                    className="w-[40px] lg:w-[60px]"
                  />
                </div>

                <div className="flex flex-col lg:items-center justify-center space-y-2">
                  <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                    Hold Stocks
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Keep the purchased stocks in your portfolio.
                  </p>
                </div>
              </div>

              <img
                src={Arrow}
                alt="Arrow"
                width={40}
                className=" rotate-90 hidden  lg:rotate-0 lg:hidden"
              />
              <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />
            </div>

            <div className="flex flex-col  space-y-8 lg:space-y-0  lg:items-center lg:flex-row lg:justify-evenly lg:space-x-4  lg:px-[60px]">
              {/* Card 4  */}
              <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between md:justify-start lg:space-y-4 lg:w-[250px]   ">
                <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                  <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    04
                  </div>
                  <img
                    src={Step4}
                    alt="Step 4"
                    className="w-[40px] lg:w-[60px]"
                  />
                </div>

                <div className="flex flex-col lg:items-center justify-center space-y-2">
                  <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                    Monitor for Sell Advice
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Wait for the sell advices from your advisor.
                  </p>
                </div>
              </div>

              {/* Arrow  */}
              <img
                src={Arrow}
                alt="Arrow"
                width={40}
                className="rotate-90 hidden lg:block lg:rotate-0"
              />
              <hr className="  bg-[#000000]/30 w-[46px]  rotate-90 h-[2px] lg:hidden " />

              {/* Card 5  */}
              <div className="flex flex-row space-x-6 lg:space-x-0 lg:flex-col items-center justify-between md:justify-start lg:space-y-4 lg:w-[250px]   ">
                <div className="flex flex-col space-y-3 items-center lg:space-y-2">
                  <div className="bg-gradient-to-b from-[#16A085] to-[#16A085]/5 bg-clip-text text-transparent text-[36px] font-semibold font-poppins leading-[44px]">
                    05
                  </div>
                  <img
                    src={Step5}
                    alt="Step 5"
                    className="w-[40px] lg:w-[50px]"
                  />
                </div>
                <div className="flex flex-col lg:items-center justify-center space-y-2">
                  <h3 className="text-[#16A085] text-[16px] font-semibold font-poppins leading-[24px]">
                    Book Profit/Loss
                  </h3>
                  <p className="lg:text-center text-[#000000] text-[13px] font-normal font-poppins leading-[24px]">
                    Sell stocks based on the advisor's recommendations and
                    current market conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </DrawerContent>
    </Drawer>
  );
};

export default StepGuideModal;
