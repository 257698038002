import React, { useState, useEffect } from "react";
import { ShoppingBasket, Plus, Minus } from "lucide-react";
import axios from "axios";

import { Dialog, DialogContent } from "../../../components/ui/dialog";
import LoadingSpinner from "../../../components/LoadingSpinner";
import server from "../../../utils/serverConfig";
import useWebSocketCurrentPrice from "../../../FunctionCall/useWebSocketCurrentPrice";
import { encryptApiKey } from "../../../utils/cryptoUtils";

const BasketModal = ({
  isOpen,
  onClose,
  basketName,
  funds,
  trades,
  allTrades,
  apiKey,
  jwtToken,
  secretKey,
  clientCode,
  broker,
  sid,
  viewToken,
  serverId,
  setOpenSucessModal,
  setOrderPlacementResponse,
  angelOneApiKey,
}) => {
  const [filteredTrades, setFilteredTrades] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [multiplier, setMultiplier] = useState("1");
  const [payloadTradeData, setPayloadTradeData] = useState([]);
  const [totalRequiredFund, setTotalRequiredFund] = useState(0);

  const { getLTPForSymbol } = useWebSocketCurrentPrice(allTrades);
  const [prices, setPrices] = useState({});

  useEffect(() => {
    if (filteredTrades.length > 0) {
      // Extract specific keys and save in payloadTradeData
      const extractedData = filteredTrades.map((trade) => ({
        user_email: trade.user_email,
        trade_given_by: trade.trade_given_by,
        tradingSymbol: trade.Symbol,
        transactionType: trade.Type,
        exchange: trade.Exchange,
        segment: trade.Segment,
        productType: trade.ProductType,
        quantity: trade.Quantity || "0",
        orderType: trade.OrderType,
        priority: trade.priority,
        tradeId: trade.tradeId,
        user_broker: trade.user_broker,
      }));

      setPayloadTradeData(extractedData);
    }
  }, [filteredTrades]);

  useEffect(() => {
    if (isOpen) {
      setLoadingData(true);
      setFilteredTrades(trades);
      fetchPrices(trades);
      setLoadingData(false);
    }
  }, [isOpen, trades]);

  const fetchPrices = async (trades) => {
    const prices = {};
    for (const trade of trades) {
      const price = await getLTPForSymbol(trade.Symbol);
      prices[trade.Symbol] = price;
    }
    setPrices(prices);
  };

  const calculateTotalRequiredFund = () => {
    let totalFund = 0;
    for (const trade of filteredTrades) {
      const price = prices[trade.Symbol] || 0;
      totalFund += price * (trade.Quantity ? trade.Quantity : 1) * multiplier;
    }
    setTotalRequiredFund(totalFund);
  };

  const incrementMultiplier = () => {
    const currentValue = multiplier === "" ? 0 : parseInt(multiplier);
    setMultiplier((currentValue + 1).toString());
  };

  const decrementMultiplier = () => {
    const currentValue = multiplier === "" ? 2 : parseInt(multiplier);
    if (currentValue > 1) {
      setMultiplier((currentValue - 1).toString());
    }
  };

  const handleMultiplierChange = (value) => {
    if (value === "" || parseInt(value) < 1) {
      setMultiplier("");
    } else {
      setMultiplier(value);
    }
  };

  const [loading, setLoading] = useState();

  const placeOrder = async () => {
    setLoading(true);
    const updatedTrades = payloadTradeData.map((trade) => ({
      ...trade,
      quantity: trade.quantity * parseInt(multiplier),
    }));
    console.log("updatedTrades", updatedTrades);
    const getOrderPayload = () => {
      const basePayload = {
        trades: updatedTrades,
        user_broker: broker,
      };
      switch (broker) {
        case "IIFL Securities":
          return {
            ...basePayload,
            clientCode,
          };
        case "ICICI Direct":
          return {
            ...basePayload,
            apiKey,
            secretKey,
            jwtToken,
          };
        case "Upstox":
          return {
            ...basePayload,
            apiKey,
            jwtToken,
            secretKey,
          };
        case "Kotak":
          return {
            ...basePayload,
            apiKey,
            secretKey,
            jwtToken,
            sid,
            serverId: serverId ? serverId : "",
          };
        case "Hdfc Securities":
          return {
            ...basePayload,
            apiKey,
            jwtToken,
          };
        case "Dhan":
          return {
            ...basePayload,
            clientCode,
            jwtToken,
          };
        case "AliceBlue":
          return {
            ...basePayload,
            clientCode,
            jwtToken,
            apiKey,
          };
        case "Fyers":
          return {
            ...basePayload,
            clientCode,
            jwtToken,
          };

        case "Angel One":
          return {
            ...basePayload,
            apiKey: angelOneApiKey,
            secretKey,
            jwtToken,
          };

        default:
          return {
            ...basePayload,
            apiKey,
            jwtToken,
          };
      }
    };

    const config = {
      method: "post",
      url: `${server.server.baseUrl}api/process-trades/order-place`,
      data: JSON.stringify(getOrderPayload()),
      headers: {
        "Content-Type": "application/json",
        "X-Advisor-Subdomain": process.env.REACT_APP_URL,
        "aq-encrypted-key": encryptApiKey(
          process.env.REACT_APP_AQ_KEYS,
          process.env.REACT_APP_AQ_SECRET
        ),
      },
    };

    try {
      const response = await axios.request(config);

      console.log("respine", response);
      setOrderPlacementResponse(response?.data?.response);
      setOpenSucessModal(true);
      handleClose();
    } catch (error) {
      console.log("13: Error in order placement", error);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setMultiplier("1");
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && handleClose()}>
      <DialogContent className="sm:max-w-[90vw] md:max-w-[75vw] lg:max-w-[52vw] xl:max-w-[38vw] w-full p-0">
        <div className="flex flex-col w-full rounded-lg bg-white">
          <div className="px-4 sm:px-6 py-4 flex items-center space-x-3 shadow-md">
            <div className="bg-[#4A7AAF] p-2 rounded-full">
              <ShoppingBasket size={24} className="text-white" />
            </div>
            <div className="text-[22px] text-black font-bold leading-[40px] font-poppins">
              {basketName}
            </div>
          </div>

          <div className="px-4 pb-4">
            <div className="bg-white rounded-md border border-gray-200 mt-4">
              <div className="h-[300px] overflow-y-auto custom-scroll">
                <table className="w-full">
                  <thead className="bg-[#f5f5f5] sticky top-0 z-20">
                    <tr className="border-b-[1px] border-[#000000]/10">
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                        Stocks
                      </th>
                      <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                        Current Price (₹)
                      </th>
                      <th className="text-[12px] w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-1 py-3 lg:py-3 lg:px-1">
                        Lot | Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingData ? (
                      <tr>
                        <td colSpan="3" className="text-center py-4">
                          Loading...
                        </td>
                      </tr>
                    ) : filteredTrades.length > 0 ? (
                      filteredTrades.map((trade, index) => (
                        <tr
                          key={index}
                          className="border-b-[1px] border-[#000000]/10 last:border-none"
                        >
                          <td className="text-[14px] sm:text-sm text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 flex flex-col">
                            <span className="block sm:hidden">
                              {trade.searchSymbol} <br /> {trade.Strike} |{" "}
                              {trade.OptionType}
                            </span>
                            <span className="hidden sm:block">
                              {trade.searchSymbol} | {trade.Strike} |{" "}
                              {trade.OptionType}
                            </span>

                            <span
                              className={`mt-1 ${
                                trade.Type === "SELL"
                                  ? "text-[#EA2D3F]"
                                  : "text-[#16A085]"
                              }`}
                            >
                              {trade.Type === "SELL" ? "SELL" : "BUY"}
                            </span>
                          </td>
                          <td className="text-[15px] text-[#000000]/80 font-poppins font-normal text-center py-3 px-5">
                            ₹ {prices[trade.Symbol] || "NaN"}
                          </td>
                          <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5">
                            {(trade.Quantity ? trade.Quantity : 1) * multiplier}{" "}
                            <span className="text-[17px]"> | </span>
                            {trade.Lots * multiplier}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="3"
                          className="text-center py-4 font-poppins"
                        >
                          No trades available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="px-6 py-4 border-t border-[#000000]/10">
            <div className="flex flex-col items-start space-y-2">
              <label
                htmlFor="multiplier"
                className="text-sm text-[#000000]/80 font-poppins"
              >
                Quantity Multiplier:
              </label>
              <div className="flex items-center space-x-2">
                <button
                  onClick={decrementMultiplier}
                  className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                >
                  <Minus size={16} />
                </button>
                <input
                  id="multiplier"
                  type="number"
                  min="1"
                  value={multiplier}
                  onChange={(e) => handleMultiplierChange(e.target.value)}
                  className="w-20 px-2 py-1 border border-gray-300 rounded-md text-sm font-poppins text-center"
                />
                <button
                  onClick={incrementMultiplier}
                  className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                >
                  <Plus size={16} />
                </button>
              </div>
              <p className="text-xs text-gray-500 font-poppins mt-1">
                Note: The multiplier adjusts all stock quantities
                proportionally. A value of 2 doubles all quantities, 3 triples
                them, and so on.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between px-6 py-4 border-t border-[#000000]/10">
            <div className="flex space-x-4">
              <div>
                <p className="text-sm text-[#000000]/80 font-poppins">
                  Required Fund
                </p>
                <p className="text-lg font-semibold font-poppins">
                  ₹ {totalRequiredFund.toFixed(2)}
                </p>
              </div>
              <div>
                <p className="text-sm text-[#000000]/80 font-poppins">
                  Available Fund
                </p>
                <p className="text-lg font-semibold font-poppins">
                  {" "}
                  ₹{" "}
                  {funds?.data?.net ? Number(funds.data.net).toFixed(2) : "NA"}
                </p>
              </div>
            </div>
            <button
              className="w-36 h-10 bg-black text-white text-sm font-semibold rounded-md hover:bg-[#1D3A5F] transition-colors duration-200 font-poppins"
              onClick={placeOrder}
            >
              {loading ? <LoadingSpinner /> : "Place Order"}
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BasketModal;
